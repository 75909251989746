define("shop/pods/components/layouts/pagination-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SWYKxb7G",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"pagination-bar__container\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@isRunning\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\\n    \"],[10,0],[15,0,[30,2]],[12],[1,\"\\n      \"],[10,\"nav\"],[14,0,\"pagination is-small\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"pagination-list\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,\"\\n\"],[41,[30,3,[\"isBtn\"]],[[[1,\"              \"],[11,3],[24,6,\"\"],[16,0,[30,3,[\"classNames\"]]],[4,[38,6],[[30,0],\"goToUrl\",[30,3]],null],[12],[1,\"\\n                \"],[1,[30,3,[\"page\"]]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],[[[1,\"                \"],[10,1],[14,0,\"pagination-ellipsis\"],[12],[1,\"…\"],[13],[1,\"\\n\"]],[]]],[1,\"            \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n\\n\"],[41,[33,7],[[[1,\"          \"],[11,3],[24,6,\"\"],[24,0,\"pagination-previous\"],[4,[38,6],[[30,0],\"goToPrevious\"],null],[12],[1,\"\\n            \"],[1,[28,[35,8],[\"previous page\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,9],[[[1,\"          \"],[11,3],[24,6,\"\"],[24,0,\"pagination-next\"],[4,[38,6],[[30,0],\"goToNext\"],null],[12],[1,\"\\n            \"],[1,[28,[35,8],[\"next page\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@isRunning\",\"@containerClassNames\",\"pageData\"],false,[\"if\",\"hasPageNumbers\",\"helpers/is-running\",\"each\",\"-track-array\",\"pageNumbers\",\"action\",\"previousPage\",\"tt\",\"nextPage\"]]",
    "moduleName": "shop/pods/components/layouts/pagination-bar/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/cart/checkout-btn/component", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    websiteService: (0, _service.inject)('website'),
    router: (0, _service.inject)('router'),
    tagName: '',
    isTile: false,
    label: 'checkout',

    closeModalForShopItemAndRedirectToCheckout() {
      this.router.transitionTo('shop.checkout.summary', this.website.shopUrlDisplay, {
        queryParams: {
          tags: null,
          query: null,
          page: null,
          count: null,
          sortBy: null,
          prices: null
        }
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "closeModalForShopItemAndRedirectToCheckout", [_object.action], Object.getOwnPropertyDescriptor(_obj, "closeModalForShopItemAndRedirectToCheckout"), _obj)), _obj));

  _exports.default = _default;
});
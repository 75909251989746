define("shop/pods/components/layouts/carousel-banner/component", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "ramda", "shop/utils/nventor"], function (_exports, _component, _object, _service, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LayoutsCarouselBanner = (_dec = (0, _service.inject)('flows'), _dec2 = (0, _service.inject)('website'), (_class = class LayoutsCarouselBanner extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "flowsService", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);
    }

    setupSliderList(sliderList) {
      var _this$flowsService;

      const slidesDatas = (this === null || this === void 0 ? void 0 : (_this$flowsService = this.flowsService) === null || _this$flowsService === void 0 ? void 0 : _this$flowsService.scrollingNotificationFlowsForRoute) || [];

      if (!slidesDatas) {
        return;
      }

      const scrollingMessageElements = Array.from(sliderList.querySelectorAll('a')) || [];

      if (!sliderList || !_nventor.default.isNilOrEmpty(scrollingMessageElements)) {
        return;
      }

      R.forEach(slideData => {
        const listItem = document.createElement('li');
        const link = document.createElement('a');
        const scrollingMessage = (slideData === null || slideData === void 0 ? void 0 : slideData.scrollingMessage) || '';
        const linkData = (slideData === null || slideData === void 0 ? void 0 : slideData.scrollingMessageLink) || {};

        if (!linkData) {
          return;
        }

        const urlData = this._getUrlData(linkData) || {};
        link.textContent = scrollingMessage;
        R.forEachObjIndexed((value, key) => {
          link.setAttribute(key, value);
        })(urlData);
        listItem.appendChild(link);
        sliderList.appendChild(listItem);
      })(slidesDatas);
      this.startTheSlides(sliderList);
    }

    startTheSlides(container) {
      const listItems = container.querySelectorAll('li');
      const containerHeight = (container === null || container === void 0 ? void 0 : container.offsetHeight) || 40;
      let currentIndex = 0;

      if (R.length(listItems) === 0) {
        return;
      }

      if (R.length(listItems) <= 1) {
        var _listItems$;

        listItems[0].style.display = 'block';
        const itemHeight = ((_listItems$ = listItems[0]) === null || _listItems$ === void 0 ? void 0 : _listItems$.offsetHeight) || 0;
        listItems[0].style.transform = `translateY(${(containerHeight - itemHeight) / 2}px)`;
        return;
      }

      R.forEach((item, index) => {
        if (index === 0) {
          item.style.display = 'block';
          const itemHeight = (item === null || item === void 0 ? void 0 : item.offsetHeight) || 0;
          item.style.transform = `translateY(${(containerHeight - itemHeight) / 2}px)`;
        } else {
          // Hide other items
          item.style.display = 'none';
          item.style.transform = `translateY(${containerHeight}px)`;
        }
      })(listItems);

      const slideItems = () => {
        const currentItem = listItems[currentIndex];
        const nextIndex = (currentIndex + 1) % listItems.length;
        const nextItem = listItems[nextIndex];
        currentItem.style.transform = `translateY(-${containerHeight}px)`;
        nextItem.style.display = 'block';
        const nextItemHeight = (nextItem === null || nextItem === void 0 ? void 0 : nextItem.offsetHeight) || 0;
        nextItem.style.transform = `translateY(${(containerHeight - nextItemHeight) / 2}px)`; // set the position back to bottom for next cycle

        setTimeout(() => {
          currentItem.style.display = 'none';
          currentItem.style.transform = `translateY(${containerHeight}px)`;
        }, 1000);
        currentIndex = nextIndex;
        setTimeout(slideItems, this.getSlideDuration(nextIndex));
      };

      setTimeout(slideItems, this.getSlideDuration(0));
    }

    getSlideDuration(index) {
      var _this$flowsService2;

      const slide = (_this$flowsService2 = this.flowsService) === null || _this$flowsService2 === void 0 ? void 0 : _this$flowsService2.scrollingNotificationFlowsForRoute[index];
      const timer = (slide === null || slide === void 0 ? void 0 : slide.scrollingMessageTimer) || 5; // ms

      return timer * 1000;
    }

    _getUrlData(data = {}) {
      var _this$websiteService;

      const linkType = data['data-href-link-type'] || 'url';
      const resourceType = data['data-href-resource-type'] || '';
      let href = data['data-href'] || '';
      const title = data['data-href-title'] || '';
      const target = data['data-href-target'] || '';
      const isBlankTarget = R.includes('_blank')(target) || false;
      let rel = '';

      if (isBlankTarget) {
        rel = 'noopener noreferrer';
      }

      if (linkType === 'url') {
        return {
          href,
          title,
          target,
          rel
        };
      }

      const website = this === null || this === void 0 ? void 0 : (_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : _this$websiteService.website;

      if (!website) {
        // should always have website!
        return {
          href,
          title,
          target,
          rel
        };
      }

      const resourceKey = data['data-href-resource-key'] || '';
      const resourceName = data['data-href-resource-name'] || '';

      if (linkType === 'pageAndCategory') {
        href = `/${href}`;
        return {
          href,
          title,
          target,
          rel
        };

        if (resourceType === 'category') {
          href = `/shop/${website.shopUrlDisplay}/category/${pageUrl}`;
        } else {
          href = `/${pageUrl}`;
        }
      } else if (resourceType === 'product') {
        href = `/shop/${website.shopUrlDisplay}/item/${resourceKey}`;
      } else if (resourceType === 'blog') {
        href = `/blogs/${resourceKey}/${resourceName}`;
      } else if (resourceType === 'news') {
        href = `/news/${resourceKey}/${resourceName}`;
      } else if (resourceType === 'store-location') {
        href = `/store-location/${resourceKey}`;
      } else if (resourceType === 'flows') {
        href = `/?f=${resourceKey}`;
      }

      return {
        href,
        title,
        target,
        rel
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flowsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupSliderList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupSliderList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startTheSlides", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "startTheSlides"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getSlideDuration", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getSlideDuration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_getUrlData", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_getUrlData"), _class.prototype)), _class));
  _exports.default = LayoutsCarouselBanner;
});
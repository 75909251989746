define("shop/pods/members/route", ["exports", "@ember/routing/route", "rsvp", "@ember/service"], function (_exports, _route, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    membersService: (0, _service.inject)('members'),

    async model(params) {
      const website = this.modelFor('application').website;
      const podKey = website.get('podKey'); // is this route still usable?

      const memberModel = await this.membersService.get('getMemberModelTask').perform(website);
      const profileMenuLists = this.membersService.getMemberProfileMenu({
        source: 'website',
        routeName: this.routeName,
        isShop: false
      });
      return _rsvp.default.hash({
        website,
        member: memberModel,
        profileMenuLists,
        podKey,
        params
      });
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/chat-btn/chat-options/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChatBtnChatOptionsComponent extends _component.default {}

  _exports.default = ChatBtnChatOptionsComponent;
});
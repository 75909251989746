define("shop/pods/components/social-login/bigordr/signup-form/component", ["exports", "@ember/component", "@ember/service", "@ember/object", "ember-concurrency", "ramda"], function (_exports, _component, _service, _object, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    storage: (0, _service.inject)(),
    validator: (0, _service.inject)('validator'),
    isModalVisible: false,
    header: '',
    facebookActive: false,
    lineActive: false,
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: true,

    init() {
      this._super(...arguments);

      const website = this.website;
      const guestModel = this.membersService.setupGuestUserModel(website);
      this.set('model', guestModel);
    },

    didInsertElement() {
      this._super(...arguments);

      this.websiteService.setupRecaptcha();
      this.setupTask.perform();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const countries = yield this.websiteService.get('fetchAllCountriesTask').perform(model);
      const website = this.website;
      model.setDefaultCountry(website, countries);
    }),
    genderLists: (0, _object.computed)('_data', function () {
      const genderLists = [_object.default.create({
        label: 'male',
        value: 'male'
      }), _object.default.create({
        label: 'female',
        value: 'female'
      }), _object.default.create({
        label: 'other',
        value: 'other'
      })];
      return genderLists;
    }),
    allowedCountries: (0, _object.computed)('router.currentRouteName', 'website.{countries.[],shop._data.countries.[]}', function () {
      const routeName = this.router.get('currentRouteName');

      if (R.startsWith('shop', routeName)) {
        return this.get('website.shop._data.countries') || [];
      }

      return this.get('website.countries') || [];
    }),
    bigordrSignupTask: (0, _emberConcurrency.task)(function* () {
      try {
        let model = this.model;
        model.validate('signup', model._data);
        const recaptchaToken = yield this.websiteService.getRecaptchaToken();
        const payload = model._data;
        payload.recaptchaToken = recaptchaToken;
        const locale = this.storage.getValue('locale') || 'zh-tw';
        payload.locale = locale;

        if (payload.birthday) {
          payload.hasUserSetBirthday = true;
        }

        const website = this.website;
        model = yield this.membersService.get('bigordrAuthSignup').perform(website, payload);
        model.removeUserDataFromStorage();

        if (model) {
          if (this.websiteService.isShopRoute) {
            if (this.get('website.shop.hasCartItems')) {
              this.router.transitionTo('shop.checkout.details');
            } else {
              this.router.transitionTo('shop.members.member');
            }
          } else {
            return this.router.transitionTo('members.member');
          }
        }
      } catch (e) {
        this.websiteService.setErrors(e, {
          errorMessage: 'error occurred'
        });
      }
    }),
    genderCheckTask: (0, _emberConcurrency.task)(function* () {
      const gender = this.model.get('_data.gender');

      if (gender !== 'other') {
        this.model.set('_data.otherGender', '');
      }
    }),
    actions: {
      storeGuest() {
        this.model.persistUserDataToStorage();
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/elements/confirmation-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a2Vg6/iK",
    "block": "[[[10,0],[14,0,\"modal cart-content-modal is-active\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[11,0],[24,0,\"is-tappable modal-background\"],[4,[38,1],[\"click\",[30,2]],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"is-tappable modal-background\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[10,0],[15,0,[29,[[30,0,[\"modalCardClassName\"]]]]],[12],[1,\"\\n    \"],[10,\"header\"],[14,0,\"modal-card-head\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[10,2],[15,0,[29,[\"modal-card-title \",[30,4]]]],[12],[1,\"\\n          \"],[1,[28,[35,2],[[30,3]],[[\"translate\"],[[30,0,[\"translate\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"section\"],[14,0,\"modal-card-body\"],[12],[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasButtons\"]],[[[1,\"      \"],[10,\"footer\"],[15,0,[29,[[30,0,[\"footerClassName\"]]]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"          \"],[11,\"button\"],[24,0,\"button\"],[4,[38,1],[\"click\",[30,2]],null],[12],[1,\"\\n            \"],[1,[28,[35,2],[\"close\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[41,[30,5],[[[1,\"          \"],[11,\"button\"],[24,0,\"button button--action button--solid\"],[4,[38,1],[\"click\",[30,5]],null],[12],[1,\"\\n            \"],[1,[28,[35,2],[[30,0,[\"onSubmitLabel\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@closeOnOverlayClick\",\"@onClose\",\"@header\",\"@modalCartTitleClass\",\"@onSubmit\",\"&default\"],false,[\"if\",\"on\",\"tt\",\"yield\"]]",
    "moduleName": "shop/pods/components/elements/confirmation-dialog/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/elements/drawer/component", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    websiteService: (0, _service.inject)('website'),
    headerService: (0, _service.inject)('header'),
    helpers: (0, _service.inject)(),
    router: (0, _service.inject)(),
    tagName: '',
    closeOnOverlayClick: true,
    navbarHeight: 0,
    originalUrl: '',
    isReturnToOriginalUrl: true,

    init() {
      this._super(...arguments); // re-set navbar height inside drawer


      this.headerService.setNavBarHeight(); // disable body scrolling on modal open

      const body = document.documentElement;
      body.style.overflow = 'hidden';
      this.router.on('routeWillChange', transition => {
        this.websiteService.cleanupDrawerOnClose({
          prop: 'isShowDrawerForShopItem'
        });
      }); // prevent back button

      this.handlePopState = this.handlePopState.bind(this);
      window.addEventListener('popstate', this.handlePopState);
    },

    didInsertElement() {
      this._super(...arguments); // prevent back button


      window.addEventListener('popstate', this.handlePopState);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.isDestroyed) {
        return;
      } // enable body scrolling on modal close


      const body = document.documentElement;
      body.style.overflow = 'visible'; // remove prevent back button

      window.removeEventListener('popstate', this.handlePopState);
    },

    handlePopState() {
      this.close();
    },

    close(isGoBackToOriginalUrl) {
      this.websiteService.cleanupDrawerOnClose({
        prop: 'isShowDrawerForShopItem',
        isGoBackToOriginalUrl
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "close", [_object.action], Object.getOwnPropertyDescriptor(_obj, "close"), _obj)), _obj));

  _exports.default = _default;
});
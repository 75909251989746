define("shop/pods/components/layouts/back-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CRNqDvmp",
    "block": "[[[11,3],[24,6,\"#\"],[16,0,[29,[\"button \",[36,0]]]],[4,[38,1],[[30,0],\"back\"],null],[12],[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[1,[28,[35,4],[[33,3]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],null,[[\"@icon\"],[\"fas fa-chevron-left\"]],null],[1,\"\\n\"]],[]]],[13]],[],false,[\"btnClassNames\",\"action\",\"if\",\"label\",\"tt\",\"elements/element-icon\"]]",
    "moduleName": "shop/pods/components/layouts/back-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/flows/games/wheel-of-fortune/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-concurrency-decorators", "shop/utils/nventor", "party-js", "ramda"], function (_exports, _component, _object, _tracking, _service, _emberConcurrencyDecorators, _nventor, _partyJs, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FlowsGamesWheelOfFortuneComponent = (_dec = (0, _service.inject)('flows'), (_class = class FlowsGamesWheelOfFortuneComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "flowsService", _descriptor, this);

      _initializerDefineProperty(this, "isPlaying", _descriptor2, this);

      _initializerDefineProperty(this, "autoSpin", _descriptor3, this);

      _initializerDefineProperty(this, "segments", _descriptor4, this);

      _initializerDefineProperty(this, "rotation", _descriptor5, this);

      _initializerDefineProperty(this, "hasWon", _descriptor6, this);

      _initializerDefineProperty(this, "showMessages", _descriptor7, this);

      _initializerDefineProperty(this, "response", _descriptor8, this);

      _initializerDefineProperty(this, "messages", _descriptor9, this);

      _initializerDefineProperty(this, "numberOfPlaysRemaining", _descriptor10, this);

      const totalSegments = 16;
      this.rotation = 360 / totalSegments;
      const segments = R.times(index => {
        const isWinning = _nventor.default.isEven(index);

        return {
          isWinning,
          rotation: this.rotation * index
        };
      })(16);
      this.segments = segments;
      const step = this.args.step;
      const title = R.propOr('', 'title')(step);
      const text = R.propOr('', 'text')(step);
      this.pushMessages(false, [{
        title,
        text,
        isStart: true
      }], true);
      const actionData = R.propOr({}, 'actionData')(step);
      const numberOfPlays = R.propOr(0, 'numberOfPlays')(actionData);
      this.numberOfPlaysRemaining = parseInt(numberOfPlays) || 0;
    }

    get hasMorePlays() {
      if (this.numberOfPlaysRemaining <= 0) {
        return false;
      }

      return true;
    }

    *startTask() {
      this.showMessages = false;

      if (this.hasMorePlays) {
        this._startTask.perform();
      } else if (!this.hasMorePlays && this.args.close) {
        this.closeDialog();
      }
    }

    closeDialog() {
      this.args.close();
    }

    *_startTask() {
      var _this$segments;

      if (!this.hasMorePlays) {
        return false;
      }

      if (this.isPlaying) {
        return false;
      }

      if (this.flowsService.triggerActionTask.isRunning) {
        return false;
      }

      const step = this.args.step;
      const response = yield this.flowsService.triggerActionTask.perform({
        step,
        goToNextGroup: false
      });
      this.response = response;
      this.isPlaying = true; // console.log('turning the wheel!')

      const hasWon = R.pathOr(false, ['responseData', 'gameResult', 'hasWon'])(response);
      const messages = R.pathOr(false, ['responseSteps'])(response);
      const abortFlow = R.path(['responseData', 'abortFlow'])(response);

      if (abortFlow) {
        this.pushMessages(hasWon, messages, true);
        this.closeDialog();
        return false;
      }

      const wheel = document.querySelector('.wheel-of-fortune__wheel');
      const numberOfPlaysRemaining = R.pathOr(0, ['responseData', 'numberOfPlaysRemaining'])(response);
      const totalSegments = ((_this$segments = this.segments) === null || _this$segments === void 0 ? void 0 : _this$segments.length) || 16;
      const min = 3 * totalSegments; //spin at least 3 x 360 deg

      const max = 4 * totalSegments;

      let random = _nventor.default.getRandomInt(min, max); // winning is always even


      if (hasWon) {
        if (!_nventor.default.isEven(random)) {
          random = random + 1;
        }
      } else {
        if (!_nventor.default.isOdd(random)) {
          random = random + 1;
        }
      }

      const spinDegrees = random * this.rotation;
      const duration = 1000 * (spinDegrees / 360);
      wheel.addEventListener('animationend', function () {
        this.style.transform = `rotate(${parseInt(spinDegrees)}deg)`;
      });
      const player = wheel.animate([{
        transform: 'rotate(0deg)'
      }, {
        transform: `rotate(${parseInt(spinDegrees)}deg)`
      }], {
        duration: duration,
        easing: 'ease-in-out',
        iterations: 1,
        delay: 0,
        fill: 'forwards'
      });
      this.pushMessages(hasWon, messages, false);
      setTimeout(() => {
        this.numberOfPlaysRemaining = numberOfPlaysRemaining;
        this.isPlaying = false;
        this.showMessages = true;

        if (hasWon) {
          this.celebrate(200);
        }
      }, duration);
    }

    pushMessages(hasWon, messages, showMessagesImmediately = true) {
      R.forEach(message => {
        this._pushMessage(hasWon, message);
      })(messages);

      if (showMessagesImmediately) {
        this.showMessages = true;
      }
    }

    _pushMessage(hasWon, message) {
      const messages = this.messages || [];
      message.hasWon = hasWon;
      messages.pushObject(message);
      this.messages = messages;
    }

    celebrate(delay = 0) {
      const element = document.querySelector('.wheel-of-fortune__container');
      setTimeout(() => {
        _partyJs.default.confetti(element, {
          count: _partyJs.default.variation.range(50, 100),
          size: _partyJs.default.variation.range(0.6, 1.4)
        });
      }, delay);
    }

    get lastMessage() {
      return R.pipe(R.propOr([], 'messages'), R.reject(R.propEq('isPrizeMessage', true)), _nventor.default.safeLast)(this);
    }

    willDestroyElement() {
      if (!this.isDestroyed) {
        const step = this.args.step;
        const messages = this.messages || [];
        const response = this.response || {};
        return this.flowsService.completeModalTask.perform({
          messages,
          step,
          response
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flowsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPlaying", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "autoSpin", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "segments", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rotation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasWon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showMessages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "response", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "messages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "numberOfPlaysRemaining", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "startTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "startTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_startTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_startTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pushMessages", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "pushMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_pushMessage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_pushMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "celebrate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "celebrate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyElement"), _class.prototype)), _class));
  _exports.default = FlowsGamesWheelOfFortuneComponent;
});
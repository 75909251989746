define("shop/pods/components/members/promo-code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ILX75x7k",
    "block": "[[[8,[39,0],null,[[\"@website\",\"@shop\",\"@params\",\"@promoCodesList\"],[[30,1,[\"website\"]],[30,1,[\"shop\"]],[30,1,[\"params\"]],[30,1,[\"promoCodesList\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@model\",\"@pageComponent\",\"@onUpdateFilter\",\"@initialFiltersIsVisible\",\"@updateUrlParams\",\"@hasTagsFilters\",\"@hasCategoriesFilters\",\"@hasAdvancedFilters\"],[[30,1],[30,2],[28,[37,3],[[30,2,[\"fetchTask\"]]],null],true,false,[30,0,[\"websiteService\",\"allowMultipleCampaigns\"]],false,false]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@isRunning\",\"@showWhenRunning\"],[[30,2,[\"fetchTask\",\"isRunning\"]],false]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"columns\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"column full-width\"],[12],[1,\"\\n            \"],[8,[39,5],null,[[\"@pageComponent\",\"@filtersComponent\",\"@promoCodesList\",\"@website\",\"@shop\"],[[30,2],[30,3],[30,2,[\"filterModel\",\"results\"]],[30,1,[\"website\"]],[30,1,[\"shop\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@model\",\"pageComponent\",\"filtersComponent\"],false,[\"members/promo-code-content\",\"layouts/page-content\",\"filters\",\"perform\",\"helpers/is-running\",\"members/promo-code-group\"]]",
    "moduleName": "shop/pods/components/members/promo-code/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/website/subscribe/route", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    async model(params) {
      const website = this.modelFor('application').website;
      return _rsvp.default.hash({
        website
      });
    },

    redirect(model) {
      if (!model) {
        this.transitionTo('website.unsubscribe.fail');
      }
    }

  });

  _exports.default = _default;
});
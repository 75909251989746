define("shop/pods/components/shop/checkout/payment-page/recurring-agree-to-terms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G11uck9E",
    "block": "[[[8,[39,0],null,[[\"@actionBtnAction\",\"@actionBtnClassName\",\"@actionBtnIcon\",\"@actionBtnLabel\",\"@header\",\"@onClose\",\"@onNextAfterAction\"],[[30,1],\"button--action-final button--solid\",\"fas fa-check-circle\",\"agree and checkout\",\"terms and conditions\",[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"contains recurring products text\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \\n  \"],[8,[39,2],null,[[\"@classNames\",\"@label\",\"@onSubmit\"],[\"button--small button--no-border\",[52,[30,0,[\"showMoreContent\"]],\"hide\",\"show more\"],[28,[37,4],[[30,0,[\"showMore\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showMoreContent\"]],[[[1,\"    \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,4,[\"_data\",\"recurringAgreement\"]],[[[1,\"          \"],[10,0],[14,0,\"white-space__pre-wrap\"],[12],[1,\"\\n            \"],[1,[28,[35,7],[[30,4,[\"_data\",\"recurringAgreement\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@onAgreeToTerms\",\"@onClose\",\"@onNext\",\"@shop\"],false,[\"elements/modal-dialog\",\"tt\",\"elements/element-btn\",\"if\",\"fn\",\"layouts/page-card\",\"layouts/page-card/card-content\",\"htmlSafe\"]]",
    "moduleName": "shop/pods/components/shop/checkout/payment-page/recurring-agree-to-terms/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
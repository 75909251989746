define("shop/helpers/concatArray", ["exports", "@ember/component/helper", "ramda"], function (_exports, _helper, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function concatArray(params) {
    return R.reduce((acc, current) => {
      if (!R.is(Array)(current)) {
        return acc;
      }

      return R.concat(acc)(current);
    }, [])(params);
  });

  _exports.default = _default;
});
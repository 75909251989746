define("shop/pods/session/service", ["exports", "@ember/service", "shop/config/environment", "ramda", "shop/utils/nventor"], function (_exports, _service, _environment, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global store */

  /**
   * stores to localstorage using the store.js library.
   * will use other methods in localstorage is not available
   */
  // @TODO: test
  var _default = _service.default.extend({
    websiteService: (0, _service.inject)('website'),
    expiry: 2592000000,

    // ms (3 days)
    // init () {
    //   this._super(...arguments)
    //   const storageVersion = config.storageVersion
    //   const currentVersionInfo = window.sessionStorage.getItem('storageVersion') || {}
    //   const currentVersion = R.prop(['val'])(currentVersionInfo) || ''
    //   if (storageVersion !== currentVersion) {
    //     window.sessionStorage.clear()
    //   }
    //   window.sessionStorage.setItem('storageVersion', {
    //     val: storageVersion,
    //     exp: this.exp,
    //     time: new Date().getTime()
    //   })
    // },

    /**
     * Add podKey to the token
     * */
    _appendPodKey(key, website = {}) {
      if (!R.isEmpty(website)) {
        const podKey = website.get('podKey');
        return `${podKey}_${key}`;
      }

      return key;
    },

    /**
     * Add shopKey to the token
     * */
    _appendShopKey(key) {
      let shopKey = R.pathOr('', ['websiteService', 'website', 'shop', '_data', '_key'])(this);

      if (!shopKey) {
        shopKey = R.pathOr('', ['websiteService', 'website', 'shopKey'])(this);
      }

      if (shopKey) {
        return `${shopKey}_${key}`;
      }

      return key;
    },

    /**
     * private: stores to localstorage
     * @param {Object} website
     * @param {String} key
     * @param {Mixin} val
     * @param {Number} exp - expiry in ms
     */
    _setValue(key, val, exp = this.expiry) {
      try {
        const dataString = JSON.stringify({
          val: val,
          exp: exp,
          time: new Date().getTime()
        });
        return window.sessionStorage.setItem(key, dataString);
      } catch (error) {
        this.websiteService.logErrorTask.perform({
          error,
          sessionStorage: window.sessionStorage
        });
        this.clearUnusedCache();
      }
    },

    /**
     * stores to localstorage
     * @param {Object} website
     * @param {String} key
     * @param {Mixin} val
     * @param {Number} exp - expiry in ms
     */
    setValue(key, val, website = {}, exp = this.expiry) {
      const storageKey = this._appendPodKey(key, website);

      return this._setValue(storageKey, val, exp);
    },

    /**
     * private: Get value from localstorage
     * @param {string} key
     * @param {?Object} website
     * @return {null|*}
     */
    _getValue(key) {
      try {
        let info = window.sessionStorage.getItem(key);

        if (!info) {
          return null;
        }

        info = JSON.parse(info);

        if (new Date().getTime() - info.time > info.exp) {
          // localstorage item has expired, remove and return null
          window.sessionStorage.removeItem(key);
          return null;
        }

        return info.val;
      } catch (error) {
        console.error('cannot get from session storage', error);
        return null;
      }
    },

    /**
     * Get value from localstorage
     * @param {string} key
     * @param {?Object} website
     * @return {null|*}
     */
    getValue(key, website = {}) {
      const storageKey = this._appendPodKey(key, website);

      return this._getValue(storageKey);
    },

    /**
     * stores to localstorage
     * @param {String} key
     * @param {Mixin} val
     * @param {Number} exp - expiry in ms
     */
    setValueWithShopKey(key, val, exp = this.expiry) {
      const storageKey = this._appendShopKey(key);

      return this._setValue(storageKey, val, exp);
    },

    /**
     * Get value from localstorage
     * @param {string} key
     * @return {null|*}
     */
    getValueWithShopKey(key) {
      const storageKey = this._appendShopKey(key);

      return this._getValue(storageKey);
    },

    /**
     * Remove key value pair
     * @param {string} key
     */
    removeWithShopKey(key) {
      const storageKey = this._appendShopKey(key);

      window.sessionStorage.removeItem(storageKey);
    },

    removeWithPodKey(key, website = {}) {
      const storageKey = this._appendPodKey(key, website);

      window.sessionStorage.removeItem(storageKey);
    },

    remove(key) {
      window.sessionStorage.removeItem(key);
    },

    clearAll() {
      window.sessionStorage.clear();
    },

    _removeExpiredItems() {
      R.pipe(R.propOr({}, 'localStorage'), R.omit(['clear', 'getItem', 'key', 'removeItem', 'setItem', 'length']), R.forEachObjIndexed((value, key) => {
        try {
          const info = window.sessionStorage.getItem(key);

          if (_nventor.default.isNilOrEmpty(info)) {
            return null;
          }

          if (new Date().getTime() - (info === null || info === void 0 ? void 0 : info.time) > (info === null || info === void 0 ? void 0 : info.exp)) {
            // localstorage item has expired, remove and return null
            window.sessionStorage.removeItem(key);
            return null;
          }
        } catch (error) {
          console.log('cannot remove key', key);
        }
      }))(window);
    },

    _removeItems() {
      R.pipe(R.propOr({}, 'localStorage'), R.omit(['user', 'checkoutData', 'locale', 'token', 'storageVersion', 'clear', 'getItem', 'key', 'removeItem', 'setItem', 'length']), R.forEachObjIndexed((value, key) => {
        try {
          const info = window.sessionStorage.getItem(key);

          if (_nventor.default.isNilOrEmpty(info)) {
            return null;
          }

          window.sessionStorage.removeItem(key);
        } catch (error) {
          console.log('cannot remove key', key);
        }
      }))(window);
    },

    clearUnusedCache() {
      this._removeExpiredItems();

      this._removeItems();
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/filters/search-filters/component", ["exports", "ramda", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "ember-concurrency-decorators"], function (_exports, R, _component, _service, _object, _tracking, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FiltersSearchFiltersComponent = (_dec = (0, _service.inject)('filters'), _dec2 = (0, _service.inject)('shop'), (_class = class FiltersSearchFiltersComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "filtersService", _descriptor2, this);

      _initializerDefineProperty(this, "shopService", _descriptor3, this);

      _initializerDefineProperty(this, "filterModel", _descriptor4, this);

      _initializerDefineProperty(this, "query", _descriptor5, this);

      _initializerDefineProperty(this, "selectedTags", _descriptor6, this);

      this.resetSelectedTags();
    }

    resetSelectedTags() {
      const params = this.args.params || {};
      const defaultData = {
        tags: R.pathOr([], ['args', 'shop', '_data', 'productTags'])(this),
        prices: R.pathOr([], ['args', 'shop', '_data', 'prices'])(this),
        sortBy: R.pathOr([], ['args', 'shop', '_data', 'sortBy'])(this)
      };
      defaultData.tags.forEach(tag => {
        tag.isSelected = false;
      });
      this.filterModel = this.filtersService.setupFilters(defaultData, {
        params
      });
    }

    async onToggleTag(selected) {
      const isSelected = selected.isSelected;
      selected.isSelected = !isSelected;
      this.selectedTags = R.pluck('label')(this.filterModel.selectedTags);

      if (isSelected === false && this.selectedTags.length > 0) {
        this.searchTask.perform(this.query);
      }
    }

    *searchTask(query = '') {
      const shop = this.args.shop;
      this.query = query;
      this.filterModel.setData('query', query); // this.filterModel.hasMore = R.propOr(false, 'hasMore', response)
      // this.filterModel.totalCount = R.propOr(0, 'totalCount', response)

      this.filtersService.updateFilter({
        filterModel: this.filterModel,
        updateUrlParams: true,
        onUpdateFilter: this.transitionTask,
        isSearch: true,
        debug: true
      });
    }

    *transitionTask() {
      var _shop$_data;

      const shop = this.args.shop;
      const query = this.query || '';
      const tags = this.selectedTags || [];
      const website = this.args.website;
      this.router.transitionTo('shop.results', website.shopUrlDisplay, {
        queryParams: {
          query,
          tags,
          count: 30,
          page: 1,
          sortBy: (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.shopProductsSort) || (shop === null || shop === void 0 ? void 0 : shop.shopProductsSort) || 'recommended'
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filterModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "query", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedTags", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resetSelectedTags", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetSelectedTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleTag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "transitionTask"), _class.prototype)), _class));
  _exports.default = FiltersSearchFiltersComponent;
});
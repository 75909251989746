define("shop/pods/components/helpers/horizontal-scroll/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m8ScUlzr",
    "block": "[[[10,0],[14,0,\"level-item has-text-centered\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\"],[\"fas fa-chevron-left\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"level-item level-item--scroll has-text-centered\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"scroll-box\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"scroll-content\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"level-item has-text-centered\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\"],[\"fas fa-chevron-right\"]],null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"elements/element-icon\",\"yield\"]]",
    "moduleName": "shop/pods/components/helpers/horizontal-scroll/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/members/manage-addresses/component", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    disabled: false,
    completeLater: false,
    forceVerification: false,
    decodedMember: null,
    isEditing: false,
    hasAddress: true,
    actions: {
      previous(prevPage) {
        if (prevPage) {
          return this.router.transitionTo(prevPage);
        }
      },

      refreshPage() {
        window.location.reload(true);
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/tabs/headers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tIil0pC+",
    "block": "[[[41,[30,1],[[[1,\"  \"],[3,\"\\n    data-nosnippet=\\\"true\\n    is used to ignore google search snippet\\n    \"],[1,\"\\n  \"],[11,0],[17,2],[24,\"data-nosnippet\",\"true\"],[16,0,[29,[\"tabs is-centered tabs-under-container \",[30,0,[\"componentId\"]],\" \",[30,3]]]],[16,5,[30,0,[\"navbarStyle\"]]],[12],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[39,3],null,[[\"@tabs\",\"@tab\",\"@index\",\"@setTabActive\",\"@isActiveUsingRoute\",\"@translate\"],[[30,1],[30,4],[30,5],[30,6],[30,7],[28,[37,4],[[30,8],[30,4,[\"translate\"]]],null]]],null],[1,\"\\n\"]],[4,5]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@tabs\",\"&attrs\",\"@tabHeaderComponentClass\",\"tab\",\"index\",\"@setTabActive\",\"@isActiveUsingRoute\",\"@translate\"],false,[\"if\",\"each\",\"-track-array\",\"tabs/headers/tab\",\"or\"]]",
    "moduleName": "shop/pods/components/tabs/headers/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
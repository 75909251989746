define("shop/pods/convenience-store/service", ["exports", "@ember/service", "@ember/object", "ember-concurrency-decorators", "ramda"], function (_exports, _service, _object, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const TARGET = '_self';
  let ConvenienceStoreService = (_dec = (0, _service.inject)('website'), (_class = class ConvenienceStoreService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);
    }

    *selectPickupLocation({
      shippingTransporters = []
    } = {}) {
      // Request convenience store pickup location redirect url from api
      const redirectUrl = yield this.requestPickupLocationTask.perform({
        shippingTransporters
      }); // Redirect convenience store pickup location website selector

      window.open(redirectUrl, TARGET);
      return redirectUrl;
    }

    *requestPickupLocationTask({
      shippingTransporters = []
    }) {
      const url = '/api/public/websites/convenience-stores';
      const transporterKey = R.pathOr('', ['websiteService', 'website', 'shop', 'transporters', 0, 'transporterKey'])(this);
      const shopUrl = R.pathOr('', ['websiteService', 'website', 'shopUrl'])(this);
      const host = this.websiteService.getHost();
      const response = yield this.ajax.request(url, {
        method: 'POST',
        data: {
          host,
          url: shopUrl,
          transporterKey,
          shippingTransporters
        }
      });
      return (response === null || response === void 0 ? void 0 : response.data) || '#';
    }

    clearConvenienceStoreAddress(checkoutData) {
      const convenienceStoreData = R.pipe(R.propOr({}, 'convenienceStoreData'), R.pick(['transporterAccount', 'brand']))(checkoutData);
      const checkoutAddressData = R.pipe(R.propOr({}, 'checkoutAddressData'), R.pick(['transporterAccount']))(checkoutData);
      checkoutData.set('convenienceStoresCity', '');
      checkoutData.set('convenienceStoresArea', '');
      checkoutData.set('convenienceStoreData', convenienceStoreData);
      checkoutData.set('checkoutAddressData', checkoutAddressData);
      checkoutData.set('storeId', '');
      checkoutData.set('storeName', '');
      checkoutData.set('convenienceStoreAddress', '');
      checkoutData.set('convenienceStoresList', []);
      checkoutData.set('convenienceStoreSelected', []);
    }

    setConvenienceStoreData(model, {
      convenienceStoreData
    } = {}) {
      convenienceStoreData = R.mergeRight({
        // brand: brand,
        country: 'Taiwan',
        countryCode: 'TW',
        hasAdvancedAddress: true,
        hasPostCode: true,
        // region: city,
        // district: area,
        // postCode: postCode?.toString?.(),
        // street: address,
        // storeId,
        // storeName,
        // storeAddress: `${city}${area}${address}`,
        type: 'convenienceStore'
      }, convenienceStoreData);
      model.set('convenienceStoreData', convenienceStoreData);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectPickupLocation", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "selectPickupLocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "requestPickupLocationTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "requestPickupLocationTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearConvenienceStoreAddress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearConvenienceStoreAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setConvenienceStoreData", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setConvenienceStoreData"), _class.prototype)), _class));
  _exports.default = ConvenienceStoreService;
});
define("shop/pods/components/shop/header-hero/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8/H4ILkm",
    "block": "[[[10,\"section\"],[14,0,\"hero\"],[12],[1,\"\\n\"],[41,[30,1,[\"_data\",\"showHeaderImg\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"imageData\",\"width\",\"srcTransform\",\"srcsetTransform\",\"imgClassNames\"],[[30,1,[\"_data\",\"imageData\"]],[30,0,[\"imgWidth\"]],[30,0,[\"imgSrcTransformUrl\"]],[30,0,[\"imgSrcsetTransformUrl\"]],\"full-width-image\"]]]],[1,\" \\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"max-content-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"hero-body\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"title\"],[12],[1,\"\\n          \"],[1,[30,1,[\"_data\",\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[51,[30,1,[\"_data\",\"isExpired\"]]],[[[41,[30,1,[\"_data\",\"description\"]],[[[1,\"            \"],[10,0],[14,0,\"subtitle\"],[12],[1,\"\\n              \"],[10,1],[14,0,\"desc-line-break\"],[12],[1,\"\\n                \"],[1,[30,1,[\"_data\",\"description\"]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"campaignDates\"]],[[[1,\"            \"],[10,2],[12],[1,[28,[35,3],[\"limited_campaign\"],null]],[1,\": \"],[1,[30,1,[\"campaignDates\"]]],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[41,[30,1,[\"_data\",\"isExpired\"]],[[[1,\"          \"],[10,2],[12],[1,\"\\n            \"],[1,[28,[35,3],[\"limited_campaign_ended\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@shop\"],false,[\"if\",\"elements/image-display\",\"unless\",\"tt\"]]",
    "moduleName": "shop/pods/components/shop/header-hero/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/items/go-to/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TNaYA8Ft",
    "block": "[[[41,[30,0,[\"isLink\"]],[[[41,[30,0,[\"isAutomation\"]],[[[41,[30,0,[\"automationKey\"]],[[[1,\"      \"],[8,[39,1],null,[[\"@route\",\"@models\",\"@classNames\"],[\"shop.detail\",[28,[37,2],[[30,1,[\"shopUrlDisplay\"]],[30,2],[30,0,[\"itemNameUrl\"]],[30,0,[\"automationKey\"]]],null],[30,0,[\"linkToClassNamesComputed\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,3,null],[1,\"\\n\"]],[]]]],[]],[[[41,[30,0,[\"showAsModal\"]],[[[1,\"      \"],[11,0],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"openItemPageTask\"]]],null]],null],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,1],null,[[\"@route\",\"@models\",\"@classNames\"],[\"shop.item.page\",[28,[37,2],[[33,6,[\"shopUrlDisplay\"]],[33,7],[33,8]],null],[30,0,[\"linkToClassNamesComputed\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]]],[]]]],[]],[[[1,\"\\n\"],[41,[33,9],[[[41,[33,10],[[[1,\"      \"],[11,3],[4,[38,11],[[30,0],\"goTo\",[33,6,[\"shopUrlDisplay\"]],[33,7],[33,8]],null],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,3,null],[1,\"\\n\"]],[]]]],[]],[[[1,\"    \"],[11,3],[4,[38,11],[[30,0],\"goTo\",[33,6,[\"shopUrlDisplay\"]],[33,7],[33,8]],null],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]],[\"@website\",\"@itemKey\",\"&default\"],false,[\"if\",\"link-to\",\"array\",\"yield\",\"on\",\"perform\",\"website\",\"itemKey\",\"itemNameUrl\",\"isAutomation\",\"automationKey\",\"action\"]]",
    "moduleName": "shop/pods/components/items/go-to/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/members/reset-password/route", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    async model() {
      const website = this.modelFor('application').website;
      let userModel = await this.membersService.get('getMemberModelTask').perform(website);

      if (!userModel) {
        // setup guest user heres
        userModel = this.membersService.setupGuestUserModel(website);
      }

      return _rsvp.default.hash({
        website
      });
    }

  });

  _exports.default = _default;
});
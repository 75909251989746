define("shop/pods/components/elements/element-select/select-option/component", ["exports", "ramda", "shop/utils/nventor", "@glimmer/component"], function (_exports, R, _nventor, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ElementsElementSelectSelectOption extends _component.default {
    get hasTranslate() {
      const option = this.args.option;
      const translate = _nventor.default.isNotNilOrEmpty(R.path(['translate'])(option)) ? R.path(['translate'])(option) : _nventor.default.isNotNilOrEmpty(R.path(['_data', 'translate'])(option)) ? R.path(['_data', 'translate'])(option) : R.pathOr(true, ['translate'])(this.args);
      return translate;
    }

    get suffixLabel() {
      const suffixKey = this.args.suffixKey;

      if (suffixKey) {
        const option = this.args.option;

        if (suffixKey === 'rate') {
          return `(NT ${option === null || option === void 0 ? void 0 : option[suffixKey]})`;
        }

        return option === null || option === void 0 ? void 0 : option[suffixKey];
      }

      return this.args.suffix;
    }

  }

  _exports.default = ElementsElementSelectSelectOption;
});
define("shop/pods/components/elements/modal-dialog-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DFz+vugr",
    "block": "[[[11,\"button\"],[16,0,[30,1]],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"showModal\"]]],null]],null],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,2]],[[\"translate\"],[[30,3]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isModalVisible\"]],[[[1,\"  \"],[8,[39,4],null,[[\"@closeOnOverlayClick\",\"@header\",\"@isModalVisible\",\"@onClose\",\"@translate\"],[[30,0,[\"closeOnOverlayClick\"]],[30,4],[30,0,[\"isModalVisible\"]],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n    \"],[18,7,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@btnClassNames\",\"@label\",\"@translate\",\"@header\",\"@onClose\",\"@headerTranslate\",\"&default\"],false,[\"on\",\"fn\",\"tt\",\"if\",\"elements/modal-dialog\",\"yield\"]]",
    "moduleName": "shop/pods/components/elements/modal-dialog-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/shop/back-btn/component", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    showBackToShoppingButton: (0, _object.computed)('shop._data.showBackToShoppingButton', 'itemStatus.isAdded', function () {
      var _this$shop, _this$shop$_data, _this$itemStatus;

      const showBackToShoppingButton = (_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : (_this$shop$_data = _this$shop._data) === null || _this$shop$_data === void 0 ? void 0 : _this$shop$_data.showBackToShoppingButton;

      if (showBackToShoppingButton) {
        return true;
      }

      if ((_this$itemStatus = this.itemStatus) !== null && _this$itemStatus !== void 0 && _this$itemStatus.isAdded) {
        return false;
      }

      return true;
    }),
    label: (0, _object.computed)('shop._data.isDisallowShopping', function () {
      const shop = this.shop;

      if (shop._data.isDisallowShopping) {
        return 'back';
      }

      return 'back to store';
    }),
    btnClassNames: (0, _object.computed)('isTile', function () {
      if (this.isTile) {
        return 'button button--tile';
      }

      return 'button';
    }),
    tagsValue: (0, _object.computed)('tags', 'backToIndex', function () {
      if (this.backToIndex) {
        return null;
      }

      if (this.tags) {
        return this.tags;
      }

      return null;
    }),
    queryValue: (0, _object.computed)('query', 'backToIndex', function () {
      if (this.backToIndex) {
        return null;
      }

      if (this.query) {
        return this.query;
      }

      return null;
    })
  });

  _exports.default = _default;
});
define("shop/pods/components/social-login/facebook-btn/component", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    facebook: (0, _service.inject)(),
    website: (0, _service.inject)(),
    tagName: '',
    actions: {
      facebookLoginFlow() {
        const website = this.website || {};
        const externalUrl = (website === null || website === void 0 ? void 0 : website.failedScheduledPaymentExternalUrl) || ''; // @NOTE: cannot put when clicking pay_by_link button
        // because if the user change mind not to pay, when go to member and login
        // it will redirect to payment page, so need to put here

        if (externalUrl) {
          this.storage.setValueWithShopKey('externalUrlForScheduledPayment', externalUrl);
        }

        this.facebook.startLoginFlow();
      }

    }
  });

  _exports.default = _default;
});
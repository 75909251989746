define("shop/pods/components/layouts/modal-overlay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qt+Wx26s",
    "block": "[[[11,0],[16,0,[29,[\"modal-overlay \",[36,0]]]],[4,[38,1],[[30,0],\"close\"],null],[12],[13],[1,\"\\n\"]],[],false,[\"modalClassNames\",\"action\"]]",
    "moduleName": "shop/pods/components/layouts/modal-overlay/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/shop/checkout/payment-page/promo-code/component", ["exports", "@glimmer/component", "@ember/object", "ember-concurrency-decorators", "@glimmer/tracking", "@ember/service", "shop/utils/nventor", "ramda"], function (_exports, _component, _object, _emberConcurrencyDecorators, _tracking, _service, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShopCheckoutPaymentPagePromoCodeComponent = (_dec = (0, _service.inject)('shop'), _dec2 = (0, _service.inject)('members'), _dec3 = (0, _service.inject)('website'), (_class = class ShopCheckoutPaymentPagePromoCodeComponent extends _component.default {
    constructor() {
      var _this$args, _this$args2, _this$args3;

      super(...arguments);

      _initializerDefineProperty(this, "storage", _descriptor, this);

      _initializerDefineProperty(this, "shopService", _descriptor2, this);

      _initializerDefineProperty(this, "membersService", _descriptor3, this);

      _initializerDefineProperty(this, "websiteService", _descriptor4, this);

      _initializerDefineProperty(this, "hasPromoCode", _descriptor5, this);

      _initializerDefineProperty(this, "hasPromoCodeError", _descriptor6, this);

      _initializerDefineProperty(this, "showPromoCode", _descriptor7, this);

      _initializerDefineProperty(this, "isSuccess", _descriptor8, this);

      _initializerDefineProperty(this, "showReferralCode", _descriptor9, this);

      _initializerDefineProperty(this, "isShowAvailablePromoCodes", _descriptor10, this);

      _initializerDefineProperty(this, "hasAvailablePromoCodes", _descriptor11, this);

      _initializerDefineProperty(this, "incorrectPromoCode", _descriptor12, this);

      const website = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.website) || {};
      const user = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.user) || {};
      const isLoggedIn = (website === null || website === void 0 ? void 0 : website.isLoggedIn) || false;

      if (isLoggedIn) {
        this.getPromoCodesListTask.perform();
      }

      const emailForPromoCode = this.storage.getValue('emailForPromoCode') || '';
      const checkoutData = ((_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.checkoutData) || {};
      const promoCode = this.storage.getValueWithShopKey('promoCode') || (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.promoCode); // if user enter promocode from url

      if (promoCode) {
        this.showPromoCode = true;
      }

      const GuestAndHasAppliedPromoCode = !isLoggedIn && emailForPromoCode && promoCode;

      if (_nventor.default.isNotNilOrEmpty(user) && GuestAndHasAppliedPromoCode) {
        user._data.email = emailForPromoCode;
      }
    }

    get showHavePromoCodeButton() {
      var _this$args4, _this$args5;

      const showEnterPromoCode = R.pathEq(['args', 'shop', '_data', 'showEnterPromoCode'], true)(this);
      const shop = ((_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : _this$args4.shop) || {};
      const promoCode = shop === null || shop === void 0 ? void 0 : shop.promoCode;
      const showPromoCode = this.showPromoCode;
      const checkoutData = ((_this$args5 = this.args) === null || _this$args5 === void 0 ? void 0 : _this$args5.checkoutData) || {};

      if (checkoutData !== null && checkoutData !== void 0 && checkoutData.promoCode) {
        return false;
      }

      if (!showEnterPromoCode || promoCode || showPromoCode) {
        return false;
      }

      return true;
    }

    *getPromoCodesListTask() {
      var _this$args6, _this$args7, _shop$_data;

      const shop = ((_this$args6 = this.args) === null || _this$args6 === void 0 ? void 0 : _this$args6.shop) || {};
      const website = ((_this$args7 = this.args) === null || _this$args7 === void 0 ? void 0 : _this$args7.website) || {};
      const sourceKey = (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.shopKey) || '';
      const promoCodesData = yield this.membersService.get('getPromoCodesListTask').perform({
        website,
        shopUrl: shop.url,
        sourceKey,
        params: {
          count: 10
        }
      });
      (0, _object.set)(this, 'promoCodes', (promoCodesData === null || promoCodesData === void 0 ? void 0 : promoCodesData.promoCodes) || []);
      (0, _object.set)(this, 'isShowAvailablePromoCodes', true);
      const showPromoCode = (this === null || this === void 0 ? void 0 : this.showPromoCode) || false;

      if (!showPromoCode) {
        (0, _object.set)(this, 'showPromoCode', !showPromoCode);
      }
    }

    *applyPromoCodeFromListTask({
      code
    }) {
      const checkoutData = this.args.checkoutData || {};

      if (_nventor.default.isNotNilOrEmpty(checkoutData)) {
        checkoutData.set('promoCode', code);
      }

      yield this.args.applyPromoCodeTask.perform();
    }

    *validateCartItemsWithPromoCodeTask() {
      var _this$args8, _this$args9, _this$args10, _this$args11;

      const user = ((_this$args8 = this.args) === null || _this$args8 === void 0 ? void 0 : _this$args8.user) || {};
      const shop = ((_this$args9 = this.args) === null || _this$args9 === void 0 ? void 0 : _this$args9.shop) || {};
      const hasAddress = ((_this$args10 = this.args) === null || _this$args10 === void 0 ? void 0 : _this$args10.hasAddress) || {};
      const checkoutData = ((_this$args11 = this.args) === null || _this$args11 === void 0 ? void 0 : _this$args11.checkoutData) || {};
      return yield this.shopService.get('validateCartItemsTask').perform({
        shop,
        user,
        hasAddress,
        checkoutData
      }).catch(e => {
        return this.shopService.get('validateCartItemsTask').perform({
          shop,
          user,
          hasAddress,
          checkoutData
        });
      });
    }

    *setupPromoCodeTask() {
      var _this$args12;

      const checkoutData = ((_this$args12 = this.args) === null || _this$args12 === void 0 ? void 0 : _this$args12.checkoutData) || {};
      const promoCode = this.storage.getValueWithShopKey('promoCode') || (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.promoCode);

      if (promoCode && _nventor.default.isNotNilOrEmpty(checkoutData)) {
        checkoutData.set('promoCode', promoCode);
        yield this.args.applyPromoCodeTask.perform();
      }

      if (checkoutData !== null && checkoutData !== void 0 && checkoutData.promoCode) {
        this.hasPromoCode = true;
      }
    }

    hidePromoCodesList() {
      (0, _object.set)(this, 'isShowAvailablePromoCodes', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "storage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasPromoCode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasPromoCodeError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showPromoCode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isSuccess", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showReferralCode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isShowAvailablePromoCodes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "hasAvailablePromoCodes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "incorrectPromoCode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getPromoCodesListTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getPromoCodesListTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyPromoCodeFromListTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "applyPromoCodeFromListTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateCartItemsWithPromoCodeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "validateCartItemsWithPromoCodeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupPromoCodeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupPromoCodeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hidePromoCodesList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hidePromoCodesList"), _class.prototype)), _class));
  _exports.default = ShopCheckoutPaymentPagePromoCodeComponent;
});
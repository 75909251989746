define("shop/pods/components/helpers/tooltip-container/tooltip-content/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'span',
    classNames: ['tooltip-content']
  });

  _exports.default = _default;
});
define("shop/pods/components/blogs/blogs-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N3Nw7x3j",
    "block": "[[[10,0],[14,0,\"blogs-group__container\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[11,0],[24,0,\"blogs-container\"],[17,2],[4,[38,1],[[28,[37,2],[[30,0,[\"websiteService\",\"scrollToSavedScrollPosition\"]],\"blogs-container\"],null]],null],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[39,5],null,[[\"@website\",\"@blog\",\"@isGrid\",\"@titleLineCount\",\"@descriptionLineCount\",\"@isGjs\"],[[30,4],[30,3],true,[30,4,[\"blogsTitleLineCount\"]],[30,4,[\"blogsDescriptionLineCount\"]],false]],null],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[1,[28,[35,6],null,[[\"linkToRoute\",\"linkToUrl\",\"pageComponent\",\"website\",\"hasMore\",\"currentPage\",\"count\",\"totalCount\"],[\"blogs\",\"\",[30,5],[30,5,[\"website\"]],[30,5,[\"filterModel\",\"hasMore\"]],[30,5,[\"filterModel\",\"_data\",\"page\"]],[30,5,[\"filterModel\",\"_data\",\"count\"]],[30,5,[\"filterModel\",\"totalCount\"]]]]]],[1,\"\\n\"]],[\"@blogs\",\"&attrs\",\"blog\",\"@website\",\"@pageComponent\"],false,[\"if\",\"did-insert\",\"fn\",\"each\",\"-track-array\",\"blogs/blog-card\",\"layouts/pagination-bar\"]]",
    "moduleName": "shop/pods/components/blogs/blogs-group/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/progress-bar/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProgressBarComponent = (_class = class ProgressBarComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "hasCount", _descriptor, this);

      _initializerDefineProperty(this, "hasAutoRedirect", _descriptor2, this);

      _initializerDefineProperty(this, "speed", _descriptor3, this);
    }

    didInsert() {
      if (this.args.autoRedirectUrl) {
        this.hasAutoRedirect = true;
      } // const simulateThrottle = R.propOr(true, ['args', 'simulateThrottle'])(this)


      const simulateThrottle = false;
      const containerClass = this.args.attatchToContainerClassName || 'progress-bar__inner-container';
      const container = document.querySelector(`.${containerClass}`);
      container.classList.add('progress-bar__container');
      const bar = document.querySelector('.progress-bar');
      const counter = document.querySelector('.count');
      let i = 0;
      const throttle = 0.7; // 0-1

      const speed = this.speed;
      const self = this;

      (function draw() {
        if (i <= 100) {
          if (!self.hasAutoRedirect) {
            return false;
          }

          let r = speed;

          if (simulateThrottle) {
            r = Math.random();
          }

          requestAnimationFrame(draw);
          bar.style.width = i + '%';

          if (counter !== null && counter !== void 0 && counter.innerHTML) {
            counter.innerHTML = Math.round(i) + '%';
          }

          if (simulateThrottle) {
            if (r < throttle) {
              // Simulate d/l speed and uneven bitrate
              i = i + r;
            }
          } else {
            i = i + r;
          }
        } else {
          // bar.className += ' done'
          if (self.hasAutoRedirect) {
            self.redirectToUrl();
          }
        }
      })();
    }

    cancelRedirect() {
      this.hasAutoRedirect = false;
    }

    get redirectUrl() {
      if (this.args.autoRedirectUrl) {
        try {
          const hasHttp = R.pipe(R.toLower, R.startsWith('http'))(this.args.autoRedirectUrl);
          let url = this.args.autoRedirectUrl;

          if (!hasHttp) {
            url = `http://${url}`;
          }

          return url;
        } catch (e) {
          return '';
        }
      }
    }

    redirectToUrl() {
      if (this.args.autoRedirectUrl) {
        try {
          window.location.href = this.redirectUrl;
        } catch (e) {
          console.log('could not auto redirect to url: ' + this.args.autoRedirectUrl);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hasCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R.propOr(false, ['args', 'hasCount'])(this);
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasAutoRedirect", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "speed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0.23;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelRedirect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelRedirect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectToUrl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToUrl"), _class.prototype)), _class);
  _exports.default = ProgressBarComponent;
});
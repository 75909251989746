define("shop/pods/components/shop/orders-query/orders-status/component", ["exports", "ramda", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "ember-concurrency-decorators", "shop/utils/nventor"], function (_exports, R, _component, _service, _object, _tracking, _emberConcurrencyDecorators, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShopOrdersQueryOrdersStatusComponent = (_dec = (0, _service.inject)('flows'), _dec2 = (0, _service.inject)('website'), _dec3 = (0, _service.inject)('google'), (_class = class ShopOrdersQueryOrdersStatusComponent extends _component.default {
    constructor() {
      var _this$args;

      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "flowsService", _descriptor2, this);

      _initializerDefineProperty(this, "websiteService", _descriptor3, this);

      _initializerDefineProperty(this, "googleService", _descriptor4, this);

      _initializerDefineProperty(this, "fetchedOrders", _descriptor5, this);

      _initializerDefineProperty(this, "hasBackButton", _descriptor6, this);

      _initializerDefineProperty(this, "ordersToken", _descriptor7, this);

      _initializerDefineProperty(this, "messages", _descriptor8, this);

      _initializerDefineProperty(this, "message", _descriptor9, this);

      const retryCountLimit = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.retryCountLimit) || 0;

      if (this.args.autoFetch && this.args.email && this.args.ref) {
        this.checkOrderStatusTask.perform({
          email: this.args.email,
          ref: this.args.ref
        }, retryCountLimit);
      }

      if (!R.isNil(this.args.hasBackButton)) {
        this.hasBackButton = this.args.hasBackButton;
      }
    }

    get orders() {
      var _this$args2, _this$args2$model;

      const ordersData = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$model = _this$args2.model) === null || _this$args2$model === void 0 ? void 0 : _this$args2$model.orders) || this.fetchedOrders || [];
      let orders = [];

      if (ordersData !== null && ordersData !== void 0 && ordersData.orders) {
        orders = ordersData.orders;
      }

      if (_nventor.default.isNotNilOrEmpty(orders)) {
        return orders;
      }

      return ordersData || [];
    }

    *checkOrderStatusTask(data, retryCountLimit = 0) {
      var _this$args$model;

      const shop = (_this$args$model = this.args.model) === null || _this$args$model === void 0 ? void 0 : _this$args$model.shop;
      let shopUrl;

      if (shop) {
        shopUrl = shop.get('_data.url');
      }

      const params = {
        email: data.email,
        ref: data.ref
      };

      try {
        const response = yield this.websiteService.orderQueryTask.perform({
          shopUrl,
          params,
          retryCountLimit
        });
        const orders = R.propOr([], 'orders')(response);
        const messages = R.propOr([], 'messages')(response);
        this.messages = messages;
        const details = R.pipe(R.pluck('details'), R.flatten)(orders);
        const inclTotal = R.pipe(R.pluck('inclTotal'), R.sum)(orders);
        const orderWithDetails = {
          details,
          inclTotal
        };
        const cart = details;
        const ref = data.ref;
        const isCheckoutSuccess = R.pathEq(['args', 'isCheckoutSuccess'], true)(this);

        if (isCheckoutSuccess) {
          this.websiteService.fbPixelTrack('purchase', {
            ref,
            cart
          });
          this.googleService.checkoutComplete({
            _data: params
          }, orderWithDetails);
        }

        this.fetchedOrders = orders;
        const ordersToken = R.propOr('', 'ordersToken')(response);
        this.ordersToken = ordersToken;
        this.flowsService.setOrderToken(ordersToken);
      } catch (error) {
        if (1 < retryCountLimit) {
          yield new Promise(resolve => setTimeout(resolve, 5000));
          const updatedRetry = retryCountLimit - 1;
          return yield this.checkOrderStatusTask.perform(data, updatedRetry);
        }

        console.error(error);
        this.fetchedOrders = {};
      }
    }

    back() {
      const model = this.args.model || {};
      const orders = this.orders || [{}];
      const orderData = R.last(orders);
      const email = R.propOr('', 'email', orderData);
      const ref = R.propOr('', 'ref', orderData);
      const shop = model.website.shop;

      if (shop) {
        const url = shop.get('_data.url');
        this.router.transitionTo('shop.orders', url, {
          queryParams: {
            email,
            ref
          }
        });
      } else {
        this.router.transitionTo('orders', {
          queryParams: {
            email,
            ref
          }
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flowsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "googleService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fetchedOrders", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasBackButton", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "ordersToken", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "messages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "message", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "checkOrderStatusTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "checkOrderStatusTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype)), _class));
  _exports.default = ShopOrdersQueryOrdersStatusComponent;
});
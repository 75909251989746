define("shop/pods/components/news/group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LVEdB71k",
    "block": "[[[10,0],[14,0,\"news-group__container\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[11,0],[24,0,\"news-container\"],[17,2],[4,[38,1],[[28,[37,2],[[30,0,[\"websiteService\",\"scrollToSavedScrollPosition\"]],\"news-container\"],null]],null],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[39,5],null,[[\"@model\",\"@website\"],[[30,3],[30,4]]],null],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@count\",\"@currentPage\",\"@hasMore\",\"@linkToRoute\",\"@linkToUrl\",\"@pageComponent\",\"@totalCount\",\"@website\"],[[30,5,[\"filterModel\",\"_data\",\"count\"]],[30,5,[\"filterModel\",\"_data\",\"page\"]],[30,5,[\"filterModel\",\"hasMore\"]],\"store-location\",\"\",[30,5],[30,5,[\"filterModel\",\"totalCount\"]],[30,5,[\"website\"]]]],null],[1,\"\\n\"]],[\"@news\",\"&attrs\",\"newModel\",\"@website\",\"@pageComponent\"],false,[\"if\",\"did-insert\",\"fn\",\"each\",\"-track-array\",\"news/card\",\"layouts/pagination-bar\"]]",
    "moduleName": "shop/pods/components/news/group/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
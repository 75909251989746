define("shop/pods/components/social-login/line-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4b7iYSNd",
    "block": "[[[1,[28,[35,0],null,[[\"classNames\",\"icon\",\"label\",\"onSubmit\"],[\"button--solid line-login-btn\",\"fab fa-line\",\"loginByLine\",[28,[37,1],[[30,0],\"lineLoginFlow\"],null]]]]]],[],false,[\"elements/element-btn\",\"action\"]]",
    "moduleName": "shop/pods/components/social-login/line-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/shop/is-expired/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ShopIsExpired extends _component.default {
    get hasHeader() {
      return R.pathOr(true, ['args', 'hasHeader'])(this);
    }

  }

  _exports.default = ShopIsExpired;
});
define("shop/pods/components/news/news-article/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W9Se+ImI",
    "block": "[[[11,0],[24,0,\"blog-page__container\"],[17,1],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"title blog-page__name\"],[12],[1,[30,2,[\"_data\",\"name\"]]],[13],[1,\"\\n        \\n    \"],[10,0],[14,0,\"blog-page__content\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@content\",\"@params\",\"@website\"],[[30,2,[\"_data\",\"template\"]],[30,3],[30,4]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@article\",\"@params\",\"@website\"],false,[\"content-display\"]]",
    "moduleName": "shop/pods/components/news/news-article/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
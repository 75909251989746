define("shop/pods/components/layouts/page-card/card-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gKr+wRPS",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,2],[14,0,\"card-header-title\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[33,1]],[[\"translate\"],[[33,3]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"header\",\"tt\",\"translate\",\"yield\"]]",
    "moduleName": "shop/pods/components/layouts/page-card/card-header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/shop/checkout/failed/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TS29Zr2w",
    "block": "[[[10,2],[14,0,\"card-header-title\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"checkout_unsuccessful_msg\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,2],[14,0,\"card-header-title\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"credit_card_failed_possible_reasons\"],null]],[1,\" :\\n\"],[13],[1,\"\\n\"],[10,\"ul\"],[12],[1,\"\\n  \"],[10,\"li\"],[12],[1,[28,[35,0],[\"credit card was entered incorrectly\"],null]],[13],[1,\"\\n  \"],[10,\"li\"],[12],[1,[28,[35,0],[\"problems with auto filling credit card details\"],null]],[13],[1,\"\\n  \"],[10,\"li\"],[12],[1,[28,[35,0],[\"the bank may be under maintenance\"],null]],[13],[1,\"\\n\"],[13]],[],false,[\"tt\"]]",
    "moduleName": "shop/pods/shop/checkout/failed/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/error-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nYoyp98+",
    "block": "[[[41,[30,0,[\"label\"]],[[[1,\"  \"],[10,\"article\"],[14,0,\"message is-danger\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"message-body\"],[12],[1,\"\\n      \"],[10,\"strong\"],[12],[2,[30,0,[\"label\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[10,0],[14,0,\"message-footer\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "shop/pods/components/layouts/error-message/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/shop/side-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iu0lyHAg",
    "block": "[[[10,0],[14,0,\"sidebar-inner has-text-left\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "shop/pods/components/shop/side-bar/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
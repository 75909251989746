define("shop/pods/members/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sQuRMa55",
    "block": "[[[10,0],[14,0,\"container margin-top--20 checkout-form\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@website\",\"@allowGuest\"],[[30,0,[\"model\",\"website\"]],false]],null],[1,\"\\n\"],[13]],[],false,[\"social-login\"]]",
    "moduleName": "shop/pods/members/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/nav-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yXEZSXVX",
    "block": "[[[10,0],[15,0,[29,[\"nav-menu__container nav-footer \",[33,0,[\"menuPosition\"]]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"nav-menu__content\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"website\",\"menu\",\"toggle\",\"isFooter\",\"initialIsExpanded\",\"isExpanded\"],[[33,0],[33,0,[\"menu\"]],[33,2],true,[33,0,[\"menuExpandSubMenus\"]],[33,0,[\"menuExpandSubMenus\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"website\",\"layouts/nav-menu-nested\",\"toggle\"]]",
    "moduleName": "shop/pods/components/layouts/nav-footer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/catchall/route", ["exports", "ramda", "@ember/routing/route"], function (_exports, R, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    beforeModel() {
      const url = window.location.pathname;

      if (R.includes('/orders/status')(url)) {
        const urlSegments = R.pipe(R.drop(1), R.replace('/orders/status', ''), R.split('/'))(url); // console.log(urlSegments)

        const shopUrl = R.head(urlSegments);
        const refAndEmailSegments = R.drop(1, urlSegments);
        const email = R.pipe(R.takeLast(2), R.head)(refAndEmailSegments);
        const ref = R.last(refAndEmailSegments);

        if (shopUrl && email && ref !== email) {
          return this.transitionTo('shop.orders.status', shopUrl, email, ref);
        }

        if (shopUrl) {
          return this.transitionTo('shop.orders', shopUrl);
        }
      }

      return this.transitionTo('/not-found-404');
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/items/item-customizations-selectors/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "ramda"], function (_exports, _component, _object, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    tagName: '',
    classNames: [],
    selectedImage: null,
    // customizationAdded: false,
    customizationsProxy: (0, _object.computed)('item._data.customizations.@each.selectedValue', 'customizations.@each.selectedValue', function () {
      if (!R.isEmpty(this.get('item._data.customizations'))) {
        return this.get('item._data.customizations') || [];
      }

      if (!R.isEmpty(this.customizations)) {
        return this.customizations;
      }

      return [];
    }),

    select(customization, selected) {
      var _this$item;

      let selectedValue = R.prop('selectedValue')(customization);

      if (selectedValue === selected || selected === '') {
        selectedValue = '';
        customization.set('selectedValue', selectedValue);
      } else {
        customization.set('selectedValue', selected);
      }

      const item = this === null || this === void 0 ? void 0 : this.item;

      if (!item) {
        return;
      }

      item.set('hasSelectedCustomizationsChanged', Math.random());
      const onSelect = this === null || this === void 0 ? void 0 : this.onSelect;

      if (onSelect) {
        onSelect(customization, selected);
      }

      if (this.onSelectCustomization && this !== null && this !== void 0 && (_this$item = this.item) !== null && _this$item !== void 0 && _this$item.selectedCustomization) {
        var _this$item2;

        this.onSelectCustomization(this === null || this === void 0 ? void 0 : (_this$item2 = this.item) === null || _this$item2 === void 0 ? void 0 : _this$item2.selectedCustomization);
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "select", [_object.action], Object.getOwnPropertyDescriptor(_obj, "select"), _obj)), _obj));

  _exports.default = _default;
});
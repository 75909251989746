define("shop/pods/components/flows/flow-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/hwKcH0v",
    "block": "[[[41,[28,[37,1],[[30,0,[\"allowFlows\"]],[30,1,[\"_key\"]]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@isPopup\",\"@isMenu\"],[[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,3]],[[[41,[30,1,[\"eventGreeting\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@content\",\"@website\"],[[30,1,[\"eventGreeting\"]],[30,4]]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[41,[51,[28,[37,5],[[30,1,[\"type\"]],\"notification\"],null]],[[[1,\"      \"],[10,0],[14,0,\"chat-message__btn\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"button is-success\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"flowsService\",\"startFlowTask\"]],[30,1],[30,5]],null]],null],[12],[1,\"\\n          \"],[1,[30,1,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@flow\",\"@isPopup\",\"@isMenu\",\"@website\",\"@onStart\"],false,[\"if\",\"and\",\"flows/flow-chat-message\",\"unless\",\"content-display\",\"eq\",\"on\",\"perform\"]]",
    "moduleName": "shop/pods/components/flows/flow-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
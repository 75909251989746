define("shop/pods/components/referral-btn/component", ["exports", "@glimmer/component", "@ember/object", "shop/utils/nventor"], function (_exports, _component, _object, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ReferralBtnComponent = (_class = class ReferralBtnComponent extends _component.default {
    setReferralButtonVisibility() {
      window.addEventListener('scroll', this.onScroll);
    }

    willDestroy() {
      window.removeEventListener('scroll', this.onScroll);
    }

    onScroll() {
      const referralBlock = document.querySelector('.referral-block');

      if (referralBlock) {
        const referralBlockPosition = referralBlock.getBoundingClientRect().top;
        const referralBlockHeight = referralBlock.offsetHeight;
        const referralBtn = document.querySelector('.referral-btn__container');

        if (referralBtn) {
          const referralBtnPosition = referralBtn.getBoundingClientRect().top;
          const beforeElement = referralBtnPosition > referralBlockPosition;
          const afterElement = -1 * Math.abs(referralBlockHeight - referralBtnPosition) > referralBlockPosition;

          if (beforeElement === afterElement) {
            document.querySelector('.referral-btn__container').style.visibility = 'visible';
          } else {
            document.querySelector('.referral-btn__container').style.visibility = 'hidden';
          }
        }
      }
    }

    goToReferral() {
      _nventor.default.goToClassName('referral-block', -120);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setReferralButtonVisibility", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setReferralButtonVisibility"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToReferral", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goToReferral"), _class.prototype)), _class);
  _exports.default = ReferralBtnComponent;
});
define("shop/pods/components/layouts/lazy-loader/lazy-loader-btn/component", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    tagName: ''
  });

  _exports.default = _default;
});
define("shop/mixins/orders-status", ["exports", "ramda", "@ember/object/mixin", "rsvp", "shop/mixins/orders-query"], function (_exports, R, _mixin, _rsvp, _ordersQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { inject } from '@ember/service'
  var _default = _mixin.default.create(_ordersQuery.default, {
    async _model(params) {
      const website = this.modelFor('application').website;
      let shop;

      if (website.get('shopKey')) {
        shop = this.modelFor('shop').shop;
      }

      return _rsvp.default.hash({
        website,
        shop,
        params
      });
    },

    _redirect(model) {
      if (R.isEmpty(model.orderData)) {
        const shop = model === null || model === void 0 ? void 0 : model.shop;
        const website = model === null || model === void 0 ? void 0 : model.website;
        const url = website === null || website === void 0 ? void 0 : website.shopUrlDisplay;

        if (shop && url) {
          this.transitionTo('shop.orders', url);
        } else {
          this.transitionTo('orders');
        }
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/shop/items-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E+ER3+yE",
    "block": "[[[10,0],[14,0,\"store-items__container\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[11,0],[24,0,\"store-items__products-container\"],[4,[38,2],[[28,[37,3],[[30,0,[\"websiteService\",\"scrollToSavedScrollPosition\"]],\"items-group\"],null]],null],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[33,1]],null]],null],null,[[[1,\"        \"],[1,[28,[35,6],null,[[\"website\",\"shop\",\"item\"],[[30,2],[33,7],[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[1,[28,[35,8],null,[[\"linkToRoute\",\"linkToUrl\",\"shop\",\"pageComponent\",\"hasMore\",\"currentPage\",\"count\",\"totalCount\",\"classNameToGo\"],[\"shop\",[30,2,[\"shopUrlDisplay\"]],[33,7],[30,3],[30,3,[\"filterModel\",\"hasMore\"]],[30,3,[\"filterModel\",\"_data\",\"page\"]],[30,3,[\"filterModel\",\"_data\",\"count\"]],[30,3,[\"filterModel\",\"totalCount\"]],\"shop-products-column\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,9],null,[[\"@title\",\"@message\",\"@headerClassNames\",\"@messageClassNames\"],[\"sorry, no products found\",\"please search again\",\"flex-container--justify-center\",\"has-text-centered\"]],null],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[1,[28,[35,10],null,[[\"website\",\"onAfterAddCart\",\"shop\",\"isRunning\",\"automations\"],[[30,2],[28,[37,11],[[30,0],\"cartChanged\"],null],[33,7],[33,12,[\"validateCartItemsTask\",\"isRunning\"]],[33,7,[\"automationsForMayAdd\"]]]]]],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"item\",\"@website\",\"@pageComponent\"],false,[\"if\",\"items\",\"did-insert\",\"fn\",\"each\",\"-track-array\",\"items/item-card\",\"shop\",\"layouts/pagination-bar\",\"layouts/message-card\",\"automations/may-add\",\"action\",\"shopService\"]]",
    "moduleName": "shop/pods/components/shop/items-group/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/cart/content-summary/totals/totals-shipping/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CartContentSummaryTotalsTotalsShippingComponent extends _component.default {
    get isFreeShipping() {
      var _this$args;

      const zone = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.zone) || {};
      const zoneSupported = zone === null || zone === void 0 ? void 0 : zone.zoneSupported;
      const rate = Number(zone === null || zone === void 0 ? void 0 : zone.rate) || 0;
      const hasShippingFee = zone === null || zone === void 0 ? void 0 : zone.hasShippingFee;

      if (!zoneSupported) {
        return false;
      }

      if (hasShippingFee) {
        return false;
      }

      if (rate > 0) {
        return false;
      }

      return true;
    }

    get selectedExpectedDateData() {
      var _this$args2;

      const checkoutData = this.args.checkoutData || {};

      if (checkoutData !== null && checkoutData !== void 0 && checkoutData.isPickup) {
        return false;
      }

      const zone = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.zone) || {};
      const expectedArrivalDatesSelection = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.expectedArrivalDatesSelection) || [];
      const found = R.find(selection => {
        const priorityZone = selection.priorityZone || [];
        return priorityZone === zone.priorityZone;
      })(expectedArrivalDatesSelection);
      return found;
    }

  }

  _exports.default = CartContentSummaryTotalsTotalsShippingComponent;
});
define("shop/helpers/eqStr", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    compute(params, hash) {
      const value1 = params[0];
      const value2 = params[1];

      if (`${value1}` === `${value2}`) {
        return true;
      }

      return false;
    }

  });

  _exports.default = _default;
});
define("shop/pods/shop/members/member/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bQkSdFcm",
    "block": "[[[10,0],[14,0,\"container checkout-form\"],[12],[1,\"\\n\"],[41,[33,1,[\"isEditingProfile\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"model\",\"website\",\"shop\",\"params\",\"isEditing\",\"prevPage\",\"nextPage\"],[[33,1,[\"website\",\"user\"]],[33,1,[\"website\"]],[33,1,[\"website\",\"shop\"]],[33,1,[\"params\"]],[33,1,[\"isEditingProfile\"]],\"shop.members\",\"shop.checkout.details\"]]]],[1,\"\\n\"]],[]],[[[41,[33,1,[\"isEditingAddress\"]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"model\",\"address\",\"website\",\"params\",\"isEditing\",\"prevPage\",\"nextPage\"],[[33,1,[\"website\",\"user\"]],[33,1,[\"website\",\"user\",\"defaultAddress\"]],[33,1,[\"website\"]],[33,1,[\"params\"]],[33,1,[\"isEditingAddress\"]],\"shop.members\",\"shop.checkout.details\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,4],null,[[\"model\",\"member\",\"website\",\"params\",\"prevPage\",\"nextPage\"],[[33,1],[33,1,[\"website\",\"user\"]],[33,1,[\"website\"]],[33,1,[\"params\"]],\"shop.members\",\"shop.checkout.details\"]]]],[1,\"\\n  \"]],[]]]],[]]],[13]],[],false,[\"if\",\"model\",\"members/profile-page\",\"elements/address-selector\",\"members/management-page\"]]",
    "moduleName": "shop/pods/shop/members/member/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
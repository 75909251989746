define("shop/pods/components/members/order-history-group/component", ["exports", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "ember-concurrency-decorators", "ramda", "shop/utils/nventor"], function (_exports, _component, _service, _object, _tracking, _emberConcurrencyDecorators, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MembersOrderHistoryGroupComponent = (_dec = (0, _service.inject)('website'), (_class = class MembersOrderHistoryGroupComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);

      _initializerDefineProperty(this, "showModal", _descriptor2, this);

      _initializerDefineProperty(this, "orderData", _descriptor3, this);
    }

    get isModalVisible() {
      return this.showModal;
    }

    *getOrderDataTask(order) {
      const shopUrl = order.shopUrl || '';
      const email = order.email || '';
      const ref = order.ref || '';
      const isRecurringMaster = order.isRecurringMaster || false;
      this.orderData = order;

      if (shopUrl && email && ref && isRecurringMaster) {
        const params = {
          email,
          ref
        };
        const response = yield this.websiteService.orderQueryTask.perform({
          shopUrl,
          params,
          retryCountLimit: 0
        });
        const fetchedOrder = R.pathOr({}, ['orders', '0'])(response);

        if (_nventor.default.isNotNilOrEmpty(fetchedOrder)) {
          this.orderData = fetchedOrder;
        }
      }
    }

    *onClickShowModalTask(order) {
      yield this.getOrderDataTask.perform(order);
      this.showModal = true;
    }

    onClickCloseDialog() {
      this.showModal = false;
      this.orderData = {};
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showModal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "orderData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getOrderDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getOrderDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickShowModalTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onClickShowModalTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickCloseDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickCloseDialog"), _class.prototype)), _class));
  _exports.default = MembersOrderHistoryGroupComponent;
});
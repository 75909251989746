define("shop/pods/components/flows/games/scratch-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vvzna+et",
    "block": "[[[11,0],[24,0,\"flows-games__scratch-card\"],[4,[38,0],[[28,[37,1],[[30,0,[\"setupTask\"]]],null]],null],[4,[38,2],[[28,[37,3],[[30,0,[\"willDestroyElement\"]]],null]],null],[12],[1,\"\\n  \"],[8,[39,4],null,[[\"@isRunning\",\"@showWhenRunning\",\"@inPlace\"],[[30,0,[\"isRunning\"]],false,true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@close\",\"@onDone\",\"@scratchCardData\",\"@startTask\"],[[30,1],[30,0,[\"showGameResults\"]],[28,[37,6],null,[[\"backgroundBgColor\",\"backgroundImageUrl\",\"backgroundText\",\"backgroundTextColor\",\"coverBgColor\",\"coverImageUrl\",\"coverText\",\"coverTextColor\"],[[30,0,[\"backgroundColor\"]],[30,0,[\"backgroundImageUrl\"]],[30,0,[\"backgroundText\"]],[30,0,[\"backgroundTextColor\"]],[30,0,[\"coverBgColor\"]],[30,0,[\"coverImageUrl\"]],[30,0,[\"coverText\"]],[30,0,[\"coverTextColor\"]]]]],[30,0,[\"startTask\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@close\"],false,[\"did-insert\",\"perform\",\"will-destroy\",\"fn\",\"helpers/is-running\",\"components-group-scratch-card\",\"hash\"]]",
    "moduleName": "shop/pods/components/flows/games/scratch-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
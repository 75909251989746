define("shop/pods/components/layouts/nav-horizontal/component", ["exports", "@ember/component", "@ember/object", "shop/utils/nventor"], function (_exports, _component, _object, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    tagName: '',
    isOverflown: false,
    isExpandNav: false,

    setupExpander(element) {
      var _this$website;

      if (!element) {
        return;
      }

      if (((_this$website = this.website) === null || _this$website === void 0 ? void 0 : _this$website.menuExpandBehavior) === 'autoExpand') {
        (0, _object.set)(this, 'isExpandNav', true);
      }

      const isOverflown = _nventor.default.isOverflown(element);

      (0, _object.set)(this, 'isOverflown', isOverflown);
    },

    toggleIsExpandNav() {
      const isExpandNav = !this.isExpandNav;
      (0, _object.set)(this, 'isExpandNav', isExpandNav);
    }

  }, (_applyDecoratedDescriptor(_obj, "setupExpander", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setupExpander"), _obj), _applyDecoratedDescriptor(_obj, "toggleIsExpandNav", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleIsExpandNav"), _obj)), _obj));

  _exports.default = _default;
});
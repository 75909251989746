define("shop/pods/components/items/go-to/component", ["exports", "@ember/component", "@ember/service", "@ember/object", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _service, _object, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    helpers: (0, _service.inject)(),
    applicationService: (0, _service.inject)('application'),
    shopService: (0, _service.inject)('shop'),
    websiteService: (0, _service.inject)('website'),
    tagName: '',
    isLink: true,
    linkToClassNamesComputed: (0, _object.computed)('linkToClassNames', function () {
      if (this.linkToClassNames) {
        return `text--main ${this.linkToClassNames}`;
      }

      return 'text--main';
    }),
    itemNameUrl: (0, _object.computed)('itemName', 'item.{urlProductName,_data.urlProductName}', function () {
      var _this$item, _this$item2, _this$item2$_data;

      const urlProductName = ((_this$item = this.item) === null || _this$item === void 0 ? void 0 : _this$item.urlProductName) || ((_this$item2 = this.item) === null || _this$item2 === void 0 ? void 0 : (_this$item2$_data = _this$item2._data) === null || _this$item2$_data === void 0 ? void 0 : _this$item2$_data.urlProductName) || this.itemName || '';
      return this.helpers.beautifyUrl(urlProductName);
    }),
    routePath: (0, _object.computed)('item', function () {
      const automation = this.automation;

      if (automation) {
        return 'shop.detail';
      }

      return 'shop.item.page';
    }),
    isAutomation: (0, _object.computed)('item.isAutomation', function () {
      var _this$item3, _this$item3$_data, _this$item4;

      return (this === null || this === void 0 ? void 0 : (_this$item3 = this.item) === null || _this$item3 === void 0 ? void 0 : (_this$item3$_data = _this$item3._data) === null || _this$item3$_data === void 0 ? void 0 : _this$item3$_data.isAutomation) || (this === null || this === void 0 ? void 0 : (_this$item4 = this.item) === null || _this$item4 === void 0 ? void 0 : _this$item4.isAutomation) || false;
    }),
    automationKey: (0, _object.computed)('item.{automationKey,_data.automationKey}', function () {
      var _this$item5, _this$item5$_data, _this$item6;

      return (this === null || this === void 0 ? void 0 : (_this$item5 = this.item) === null || _this$item5 === void 0 ? void 0 : (_this$item5$_data = _this$item5._data) === null || _this$item5$_data === void 0 ? void 0 : _this$item5$_data.automationKey) || (this === null || this === void 0 ? void 0 : (_this$item6 = this.item) === null || _this$item6 === void 0 ? void 0 : _this$item6.automationKey) || '';
    }),
    showAsModal: (0, _object.computed)('router.currentRouteName', function () {
      // return false
      const currentRoute = this.router.currentRouteName;

      if (!R.includes('shop.checkout', currentRoute)) {
        return true;
      }

      return false;
    }),

    *openItemPageTask() {
      const itemKey = this === null || this === void 0 ? void 0 : this.itemKey;
      const shop = this === null || this === void 0 ? void 0 : this.shop;

      if (shop && itemKey) {
        const originalUrl = window.location.href;
        this.websiteService.setDrawerState({
          prop: 'isShowDrawerForShopItem',
          originalUrl,
          state: true
        });
        const item = yield this.shopService.get('getShopItemTask').perform({
          shop,
          itemKey: itemKey
        });
        const model = {
          item
        };
        this.websiteService.setDrawerModel({
          prop: 'isShowDrawerForShopItem',
          model: model
        });
      }
    },

    actions: {
      goTo(url, itemKey, itemNameUrl) {
        const routePath = this.routePath;
        const isAutomation = this.isAutomation;

        if (isAutomation) {
          if (this.automationKey) {
            const automationKey = this.automationKey;
            return this.router.transitionTo('shop.detail', url, itemKey, itemNameUrl, automationKey);
          }
        }

        return this.router.transitionTo(routePath, url, itemKey, itemNameUrl);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "openItemPageTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "openItemPageTask"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/components/items/recurring-schedules-selector/component", ["exports", "@glimmer/component", "shop/utils/nventor", "@ember/service", "@ember/object", "@glimmer/tracking", "ramda"], function (_exports, _component, _nventor, _service, _object, _tracking, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ItemsRecurringSchedulesSelector = (_class = class ItemsRecurringSchedulesSelector extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "recurringScheduleKey", _descriptor2, this);

      _initializerDefineProperty(this, "recurringScheduleSelected", _descriptor3, this);

      _initializerDefineProperty(this, "recurringScheduleOptions", _descriptor4, this);
    }

    didInsert() {
      var _this$args, _item$_data;

      const item = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.item) || {};
      const recurringSchedules = (item === null || item === void 0 ? void 0 : (_item$_data = item._data) === null || _item$_data === void 0 ? void 0 : _item$_data.recurringSchedules) || [];
      const isRecurringScheduleOnlyAndHasOneRecurringSchedules = this.isRecurringScheduleOnlyAndHasOneRecurringSchedules || false;

      if (isRecurringScheduleOnlyAndHasOneRecurringSchedules) {
        const recurringScheduleKey = R.pathOr('', ['0', '_key'])(recurringSchedules);
        this.selectRecurringSchedule(recurringSchedules, {}, recurringScheduleKey);
      }
    }

    get isRecurringScheduleOnlyAndHasOneRecurringSchedules() {
      var _this$args2, _item$_data2, _item$_data3;

      const item = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.item) || {};
      const recurringSchedulesOnly = (item === null || item === void 0 ? void 0 : (_item$_data2 = item._data) === null || _item$_data2 === void 0 ? void 0 : _item$_data2.recurringSchedulesOnly) || false;
      const recurringSchedules = (item === null || item === void 0 ? void 0 : (_item$_data3 = item._data) === null || _item$_data3 === void 0 ? void 0 : _item$_data3.recurringSchedules) || [];
      return recurringSchedulesOnly && R.length(recurringSchedules) === 1;
    }

    selectRecurringSchedule(recurringSchedules, e = {}, selectedValue = '') {
      var _recurringSchedule;

      const selectedOption = R.pipe(R.pathOr([], ['target', 'selectedOptions']), R.head)(e);
      let selected = '';

      if (selectedOption) {
        selected = R.propOr('', 'value', selectedOption);
      }

      if (!selectedOption && selectedValue) {
        selected = selectedValue;
      }

      if (selected === 'single') {
        var _this$args3;

        this.recurringScheduleSelected = this.args.item;

        if ((_this$args3 = this.args) !== null && _this$args3 !== void 0 && _this$args3.item) {
          this.args.item.set('recurringScheduleSelected', null);
        }

        return this.args.item;
      }

      if (!selected) {
        this.recurringScheduleSelected = null;
        return;
      }

      let recurringSchedule;

      if (selected) {
        var _this$args4;

        recurringSchedule = R.find(R.propEq('recurringScheduleKey', selected))(recurringSchedules) || {};

        if ((_this$args4 = this.args) !== null && _this$args4 !== void 0 && _this$args4.item) {
          this.args.item.set('recurringScheduleSelected', recurringSchedule);
        }
      }

      this.recurringScheduleSelected = recurringSchedule;
      this.recurringScheduleKey = (_recurringSchedule = recurringSchedule) === null || _recurringSchedule === void 0 ? void 0 : _recurringSchedule.recurringScheduleKey;
      return recurringSchedule;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "recurringScheduleKey", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "recurringScheduleSelected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "recurringScheduleOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectRecurringSchedule", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectRecurringSchedule"), _class.prototype)), _class);
  _exports.default = ItemsRecurringSchedulesSelector;
});
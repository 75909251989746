define("shop/pods/components/elements/date-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JX3lnT1/",
    "block": "[[[10,0],[14,0,\"datepicker__input-container\"],[12],[1,\"\\n  \"],[10,\"input\"],[14,0,\"input is-tappable datepicker__input\"],[15,1,[28,[37,0],[\"datepicker-\",[30,1]],null]],[15,\"required\",[52,[30,2],\"required\"]],[15,\"readonly\",[30,3]],[15,\"placeholder\",[52,[30,2],[28,[37,2],[\"required\"],null],[28,[37,2],[\"optional\"],null]]],[15,2,[30,0,[\"shownDate\"]]],[14,4,\"text\"],[12],[13],[1,\"\\n\\n\"],[41,[51,[30,2]],[[[1,\"    \"],[10,\"button\"],[14,0,\"delete datepicker__clear-button\"],[14,\"aria-label\",\"clear\"],[15,\"onclick\",[28,[37,4],[[30,0,[\"clearDate\"]],[30,1]],null]],[15,5,[52,[30,2],\"display:none;\"]],[12],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,5],null,[[\"@icon\"],[[30,0,[\"isRequiredIcon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,0],[16,1,[28,[37,0],[\"datepicker-container-\",[30,1]],null]],[4,[38,6],[[28,[37,4],[[30,0,[\"setupCalendar\"]]],null]],null],[12],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@id\",\"@isRequired\",\"@isReadonly\"],false,[\"concat\",\"if\",\"tt\",\"unless\",\"fn\",\"elements/element-icon\",\"did-insert\"]]",
    "moduleName": "shop/pods/components/elements/date-input/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
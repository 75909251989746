define("shop/pods/components/elements/confirmation-dialog/component", ["exports", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "ramda"], function (_exports, _component, _service, _object, _tracking, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ElementsConfirmationDialogComponent = (_dec = (0, _service.inject)('website'), (_class = class ElementsConfirmationDialogComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);
    }

    get translate() {
      return R.pathOr(true, ['args', 'translate'])(this);
    }

    get hasButtons() {
      var _this$args, _this$args2;

      if (this !== null && this !== void 0 && (_this$args = this.args) !== null && _this$args !== void 0 && _this$args.onSubmit) {
        return true;
      }

      if (this !== null && this !== void 0 && (_this$args2 = this.args) !== null && _this$args2 !== void 0 && _this$args2.onClose) {
        return true;
      }

      return false;
    }

    get footerClassName() {
      var _this$args3, _this$args4;

      let className = 'modal-card-foot modal-card-footer flex-container-row ';

      if (this !== null && this !== void 0 && (_this$args3 = this.args) !== null && _this$args3 !== void 0 && _this$args3.onSubmit && this !== null && this !== void 0 && (_this$args4 = this.args) !== null && _this$args4 !== void 0 && _this$args4.onClose) {
        className += 'flex-container--justify-space-between';
      } else {
        className += 'flex-container--justify-end';
      }

      return className;
    }

    get onSubmitLabel() {
      var _this$args5;

      const onSubmitLabel = this === null || this === void 0 ? void 0 : (_this$args5 = this.args) === null || _this$args5 === void 0 ? void 0 : _this$args5.onSubmitLabel;

      if (onSubmitLabel) {
        return onSubmitLabel;
      }

      return 'submit';
    }

    get modalCardClassName() {
      const classNames = ['modal-card', 'confirmation-card'];
      const modalCardClass = this.args.modalCardClass;

      if (modalCardClass) {
        classNames.push(modalCardClass);
      }

      let isMobile = this.websiteService.isMobile;

      if (window.innerWidth && window.innerWidth <= 800) {
        isMobile = true;
      }

      if (isMobile) {
        classNames.push('confirmation-card--mobile');
      } else {
        classNames.push('confirmation-card--not-mobile');
      }

      return classNames.join(' ');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ElementsConfirmationDialogComponent;
});
define("shop/pods/filters/model", ["exports", "ramda", "@glimmer/tracking", "@ember/object", "shop/utils/nventor", "shop/pods/base/model"], function (_exports, R, _tracking, _object, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _class3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _class5, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FilterItem = (_class = class FilterItem {
    constructor(defaults) {
      _initializerDefineProperty(this, "isSelected", _descriptor, this);

      _initializerDefineProperty(this, "isHeader", _descriptor2, this);

      _initializerDefineProperty(this, "isNotAllowMultiple", _descriptor3, this);

      this.isSelected = defaults.isSelected;
      this.isHeader = defaults.isHeader;
      this.isNotAllowMultiple = defaults.isNotAllowMultiple;
      this.label = defaults.label;
      this.showTagsOnFilter = defaults.showTagsOnFilter;
      this.showTagsOnPage = defaults.showTagsOnPage;
      this.isShopTags = defaults.isShopTags;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isSelected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isHeader", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isNotAllowMultiple", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  let TrackedData = (_class3 = class TrackedData {
    constructor() {
      _initializerDefineProperty(this, "page", _descriptor4, this);

      _initializerDefineProperty(this, "count", _descriptor5, this);

      _initializerDefineProperty(this, "prices", _descriptor6, this);

      _initializerDefineProperty(this, "sortBy", _descriptor7, this);

      _initializerDefineProperty(this, "tags", _descriptor8, this);

      _initializerDefineProperty(this, "query", _descriptor9, this);

      _defineProperty(this, "_keys", ['page', 'count', 'prices', 'sortBy', 'tags', 'query']);
    }

  }, (_descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "count", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 30;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "prices", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "sortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "tags", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "query", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  })), _class3);
  let FiltersModel = (_class5 = class FiltersModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_data", _descriptor10, this);

      _initializerDefineProperty(this, "results", _descriptor11, this);

      _initializerDefineProperty(this, "hasMore", _descriptor12, this);

      _initializerDefineProperty(this, "totalCount", _descriptor13, this);
    }

    get selectedTags() {
      return this._getSelectedProp('tags');
    }

    get selectedPrices() {
      return this._getSelectedProp('prices');
    }

    get selectedSortBy() {
      return this._getSelectedProp('sortBy');
    }

    get hasAnySelectedItems() {
      var _this$_data;

      const tags = this.filtersTags || [];
      const prices = this._data.prices || [];
      const query = this._data.query || '';
      let possibleChoices = prices;

      if (!((_this$_data = this._data) !== null && _this$_data !== void 0 && _this$_data.category)) {
        possibleChoices = R.concat(tags)(prices);
      }

      const hasSelected = R.find(R.propEq('isSelected', true))(possibleChoices);

      if (hasSelected) {
        return true;
      }

      if (query) {
        return true;
      }
    }

    get hasFilters() {
      return R.pipe(R.all(R.isEmpty), R.not)([this.selectedTags, this.selectedPrices]);
    }

    get filtersTags() {
      const tags = this._data.tags || [];
      return R.filter(tag => {
        if (tag.isHeader) {
          return true;
        }

        if (tag.isLink) {
          return true;
        }

        if (R.isNil(tag.showTagsOnFilter)) {
          // for backwards compatibility, when blogs/news etc does not yet have this property
          return true;
        }

        return tag.showTagsOnFilter;
      })(tags);
    }

    populate(data, attrs = {}) {
      const mergedData = R.pipe(R.propOr({}, 'params'), R.reject(R.is(Array)), R.mergeLeft(data))(attrs);
      super.populate(mergedData, attrs);
      R.mapObjIndexed((value, prop) => {
        if (!R.isNil(value)) {
          this.setData(prop, value);
        }
      })(mergedData);
      const hasTagsOnParams = R.pipe(R.prop('params'), R.anyPass([R.isNil, R.isEmpty]), R.not)(attrs);

      if (hasTagsOnParams) {
        const tags = this.transformTags(mergedData, attrs.params);
        this.setData('tags', tags);
      }

      this.setData('prices', this.transformPrices(mergedData, attrs.params));
      const sortBy = this.transformSortBy(mergedData, attrs.params);
      this.setData('sortBy', sortBy);
    }

    update(params) {
      const data = this._data;
      this.setData('tags', this.transformTags(data, params));
      this.setData('prices', this.transformPrices(data, params)); // const sortBy = this.setSeletctedSortBy(data, params)
      // this.setData('sortBy', sortBy)

      R.mapObjIndexed((value, prop) => {
        if (!R.is(Array, value) && prop !== 'sortBy') {
          this.setData(prop, value);
        }
      })(params);
    }

    resetItems(filterItems) {
      if (R.is(Array)(filterItems)) {
        return R.map(item => {
          item.isSelected = false; // item.isSelected = R.propOr(false, 'isSelected')(item)

          return item;
        })(filterItems);
      }

      if (R.is(String)(filterItems)) {
        return filterItems = '';
      }
    }

    resetParams({
      isShopOrCategoryPage = false
    } = {}) {
      if (!isShopOrCategoryPage) {
        this.setData('tags', this.resetItems(this._data.tags));
      }

      this.setData('prices', this.resetItems(this._data.prices));
      this.setData('query', this.resetItems(this._data.query));
    }

    transformTags(data, params = {}) {
      return R.pipe(R.propOr([], 'tags'), R.map(tag => {
        tag.isSelected = R.pipe(R.propOr([], 'tags'), tags => {
          const includes = R.includes(tag.label)(tags);

          if (includes) {
            return true;
          }

          return false;
        })(params);
        tag.isHeader = R.propOr(false, 'isHeader')(tag);
        tag.isNotAllowMultiple = R.propOr(false, 'isNotAllowMultiple')(tag);
        return new FilterItem(tag);
      }))(data);
    }

    transformPrices(data, params = {}) {
      return R.pipe(R.propOr([], 'prices'), R.map(price => {
        let priceObj = {};

        if (R.is(Object, price)) {
          priceObj.label = price.label || '';
        } else {
          priceObj.label = price;
        }

        priceObj.isSelected = R.pipe(R.propOr([], 'prices'), R.includes(priceObj.label))(params);
        return new FilterItem(priceObj);
      }))(data);
    }

    transformSortBy(data, params = {}) {
      let index = 0;
      const sortBy = R.pipe(R.propOr([], 'sortBy'), R.map(sortItem => {
        let sortByObj = {};

        if (R.is(Object, sortItem)) {
          sortByObj.label = sortItem.label || '';
        } else {
          sortByObj.label = sortItem;
        }

        sortByObj.isSelected = R.pipe(R.propOr('', 'sortBy'), R.equals(sortByObj.label))(params); // set first item as selected if none is set

        const hasSelectedFromParams = R.pipe(R.prop('sortBy'), _nventor.default.isNotNilOrEmpty)(params);

        if (!hasSelectedFromParams && !sortByObj.isSelected && index === 0) {
          sortByObj.isSelected = true;
        }

        index = index + 1;
        return new FilterItem(sortByObj);
      }))(data);
      return sortBy;
    } // setSeletctedSortBy (data, params) {
    //   return R.pipe(
    //     R.propOr([], 'sortBy'),
    //     R.map((sortByObj) => {
    //       sortByObj.isSelected = R.pipe(
    //         R.propOr('', 'sortBy'),
    //         R.equals(sortByObj.label)
    //       )(params)
    //       return sortByObj
    //     })
    //   )(data)
    //   // const hasSelected = R.find(R.propEq('isSelected', true))(sortBy)
    //   // if (!hasSelected && !nventor.isNilOrEmpty(sortBy)) {
    //   //   sortBy[0].isSelected = true
    //   // }
    //   // return sortBy
    // }


    _getSelectedProp(prop) {
      const array = this._data[prop] || [];
      return R.filter(R.propEq('isSelected', true))(array);
    }
    /**
     * return selected filters as params object
     */


    getAsParams() {
      const pickedData = this.serialize();
      const selectedFilters = R.pipe(R.mapObjIndexed((value, prop) => {
        if (R.is(Array)(value)) {
          const selected = this._getSelectedProp(prop);

          return R.pluck('label')(selected);
        }

        return value;
      }))(pickedData);
      selectedFilters.sortBy = _nventor.default.safeHeadOr('', selectedFilters.sortBy);
      return selectedFilters;
    }

  }, (_descriptor10 = _applyDecoratedDescriptor(_class5.prototype, "_data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new TrackedData();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class5.prototype, "results", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class5.prototype, "hasMore", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class5.prototype, "totalCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class5.prototype, "_getSelectedProp", [_object.action], Object.getOwnPropertyDescriptor(_class5.prototype, "_getSelectedProp"), _class5.prototype), _applyDecoratedDescriptor(_class5.prototype, "getAsParams", [_object.action], Object.getOwnPropertyDescriptor(_class5.prototype, "getAsParams"), _class5.prototype)), _class5);
  _exports.default = FiltersModel;
});
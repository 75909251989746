define("shop/pods/components/store-location/card/component", ["exports", "@ember/service", "@glimmer/component", "@ember/object", "@glimmer/tracking", "ramda"], function (_exports, _service, _component, _object, _tracking, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StoreLocationCardComponent = (_class = class StoreLocationCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "date", _descriptor, this);

      _initializerDefineProperty(this, "isDropdownActive", _descriptor2, this);

      _defineProperty(this, "thumbSize", 64);
    }

    get addressHref() {
      return R.pipe(R.pathOr('', ['args', 'storeLocation', '_data', 'address']), R.replace(/\s/gm, '+'))(this);
    }

    get imgWidth() {
      return this.args.width || this.thumbSize;
    }

    get imgHeight() {
      const ratio = this.args.website.blogsImagesRatio || 'fourByThree';

      if (ratio === 'oneByOne') {
        return this.imgWidth;
      }

      return this.thumbSize;
    }

    get fourByThreeHeight() {
      const imgWidth = this.imgWidth;
      return Math.round(imgWidth * 3 / 4, 0);
    }

    get imgSrcTransformUrl() {
      const imgWidth = this.imgWidth;
      const imgHeight = this.imgHeight;
      return `/-/scale_crop/${imgWidth}x${imgHeight}/center/`;
    }

    get imgSrcsetTransformUrl() {
      const imgWidth = parseInt(this.imgWidth);
      const width2x = imgWidth * 2;
      const imgHeight = this.imgHeight * 2;
      return `/-/scale_crop/${width2x}x${imgHeight}/center/-/quality/lighter/ 2x`;
    }

    get showOpenHours() {
      const openHours = R.pathOr({}, ['args', 'storeLocation', '_data', 'openHours'])(this);
      const hasOpenHours = R.pipe(R.isEmpty, R.not)(openHours);

      if (hasOpenHours) {
        return R.reduce((acc, day) => {
          const time = R.propOr('', day)(openHours);
          const hasOpeningTime = R.pipe(R.pathEq(['open'], 'none'), R.not)(time);

          if (hasOpeningTime) {
            acc.push({
              day,
              time
            });
          }

          return acc;
        }, [])(this.date.getWeekdays());
      }

      return [];
    }

    dropdown() {
      this.isDropdownActive = !this.isDropdownActive;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "date", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDropdownActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "dropdown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dropdown"), _class.prototype)), _class);
  _exports.default = StoreLocationCardComponent;
});
define("shop/pods/shop/checkout/summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pHMj1uD0",
    "block": "[[[10,0],[14,0,\"checkout-form\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@steps\",\"@allowNav\",\"@currentStep\"],[[30,0,[\"model\",\"shop\",\"checkoutSteps\"]],false,\"checkout_summary\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@isSummaryStep\",\"@shop\",\"@user\",\"@website\"],[true,[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"user\"]],[30,0,[\"model\",\"website\"]]]],null]],[],false,[\"layouts/page-steps\",\"shop/checkout/summary-page\"]]",
    "moduleName": "shop/pods/shop/checkout/summary/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/automations/must-add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7y6nQn2z",
    "block": "[[[41,[33,1],[[[42,[28,[37,3],[[28,[37,3],[[33,1]],null]],null],null,[[[1,\"    \"],[8,[39,4],null,[[\"@automation\",\"@shop\",\"@isRunning\",\"@isReadonly\",\"@onCartChanged\",\"@isSummaryStep\",\"@isDetailStep\"],[[30,1],[99,5,[\"@shop\"]],[99,6,[\"@isRunning\"]],[99,7,[\"@isReadonly\"]],[30,2],[30,3],[30,4]]],null],[1,\"\\n\"]],[1]],null]],[]],null]],[\"automation\",\"@onCartChanged\",\"@isSummaryStep\",\"@isDetailStep\"],false,[\"if\",\"automations\",\"each\",\"-track-array\",\"automations/must-add/must-add-automation\",\"shop\",\"isRunning\",\"isReadonly\"]]",
    "moduleName": "shop/pods/components/automations/must-add/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
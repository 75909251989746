define("shop/pods/components/items/item-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1nojY3rK",
    "block": "[[[41,[28,[37,1],[[33,2],[33,3]],null],[[[1,\"  \"],[10,2],[14,0,\"has-text-centered store-item__item-activity-container\"],[12],[1,\"\\n\"],[41,[28,[37,4],[[33,2],[30,0,[\"isAvailableQtyGteZero\"]]],null],[[[1,\"      \"],[10,1],[14,0,\"store-item__qty-info-container\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"available qty\"],null]],[1,\": \"],[10,1],[14,0,\"has-text-weight-bold store-item__qty-info-number\"],[12],[1,[34,6]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,3],[[[1,\"      \"],[10,1],[14,0,\"store-item__qty-info-container\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"qty sold\"],null]],[1,\": \"],[10,1],[14,0,\"has-text-weight-bold store-item__qty-info-number\"],[12],[1,[34,7]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"or\",\"hasShowAvailableQty\",\"hasShowSalesQty\",\"and\",\"tt\",\"availableQty\",\"salesQty\"]]",
    "moduleName": "shop/pods/components/items/item-activity/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
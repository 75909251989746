define("shop/pods/store-location/index/route", ["exports", "@ember/routing/route", "rsvp", "shop/pods/page/model", "ramda", "shop/utils/nventor"], function (_exports, _route, _rsvp, _model, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StoreLocationIndexRoute extends _route.default {
    model(params) {
      try {
        const shop = this.modelFor('application').shop;
        const website = this.modelFor('application').website;
        const currentPage = website.getDynamicPage('store location');

        if (_nventor.default.isNilOrEmpty(currentPage)) {
          return this.transitionTo('/not-found-404');
        }

        const homePageRedirect = R.propEq('homePageRedirect', true)(currentPage);

        if (homePageRedirect) {
          return this.transitionTo('/');
        }

        const page = _model.default.create();

        page.populate(currentPage);
        return _rsvp.default.hash({
          params,
          shop,
          website,
          page
        });
      } catch (e) {
        this.transitionTo('/');
      }
    }

  }

  _exports.default = StoreLocationIndexRoute;
});
define("shop/pods/components/layouts/message-chips/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Iyb3/E6x",
    "block": "[[[41,[30,0,[\"websiteService\",\"messageChips\"]],[[[1,\"  \"],[10,0],[14,0,\"message-chip-list-container\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"websiteService\",\"messageChips\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@chip\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"chip\"],false,[\"if\",\"each\",\"-track-array\",\"layouts/message-chips/chip\"]]",
    "moduleName": "shop/pods/components/layouts/message-chips/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/cart/content-summary/component", ["exports", "@ember/component", "@ember/object", "ramda", "@ember/service", "shop/utils/nventor"], function (_exports, _component, _object, R, _service, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    intl: (0, _service.inject)(),
    router: (0, _service.inject)(),
    checkoutService: (0, _service.inject)('checkout'),
    tagName: '',
    showModal: false,
    allowClearAll: (0, _object.computed)('shop.hasExpiredGracefully', 'isReadonly', 'isDetailStep', function () {
      if (this.shop.hasExpiredGracefully) {
        return true;
      }

      if (this.isDetailStep) {
        return false;
      }

      return !this.isReadonly;
    }),
    isModalVisible: (0, _object.computed)('showModal', function () {
      return this.showModal;
    }),
    _checkoutData: (0, _object.computed)('shop.checkoutData', 'checkoutData', function () {
      if (_nventor.default.isNotNilOrEmpty(this.checkoutData)) {
        return this.checkoutData;
      }

      const shop = this.shop || {};
      return (shop === null || shop === void 0 ? void 0 : shop.checkoutData) || {};
    }),
    isShowSubtotal: (0, _object.computed)('shippingFeeData.combinedZones.[]', '_checkoutData.{pickupMethod,isConvenienceStore,convenienceStoreData.storeId}', function () {
      var _this$shippingFeeData, _checkoutData$conveni;

      const combinedZones = ((_this$shippingFeeData = this.shippingFeeData) === null || _this$shippingFeeData === void 0 ? void 0 : _this$shippingFeeData.combinedZones) || [];
      const checkoutData = this._checkoutData;
      const isConvenienceStore = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.isConvenienceStore) || false;
      const storeId = (checkoutData === null || checkoutData === void 0 ? void 0 : (_checkoutData$conveni = checkoutData.convenienceStoreData) === null || _checkoutData$conveni === void 0 ? void 0 : _checkoutData$conveni.storeId) || '';

      if (!isConvenienceStore && _nventor.default.isNotNilOrEmpty(combinedZones)) {
        return true;
      }

      if (isConvenienceStore && storeId && _nventor.default.isNotNilOrEmpty(combinedZones)) {
        return true;
      }

      if (isConvenienceStore && !storeId && _nventor.default.isNilOrEmpty(combinedZones)) {
        return true;
      }

      return false;
    }),
    isShowShippingFee: (0, _object.computed)('hasUserAddressData', 'shippingFeeData.combinedZones.[]', 'isSummaryStep', '_checkoutData.pickupMethod', function () {
      var _this$shippingFeeData2;

      const combinedZones = ((_this$shippingFeeData2 = this.shippingFeeData) === null || _this$shippingFeeData2 === void 0 ? void 0 : _this$shippingFeeData2.combinedZones) || [];
      const isSummaryStep = (this === null || this === void 0 ? void 0 : this.isSummaryStep) || false;
      const checkoutData = this._checkoutData;
      const pickupMethod = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.pickupMethod) || 'homeDelivery';
      const hasUserAddressData = this.hasUserAddressData || false;

      if (isSummaryStep) {
        return false;
      }

      if (pickupMethod === 'storeLocationPickup') {
        return false;
      }

      if (!isSummaryStep && !hasUserAddressData) {
        return false;
      }

      if (!isSummaryStep && _nventor.default.isNilOrEmpty(combinedZones)) {
        return false;
      }

      return true;
    }),
    inclTotalDisplay: (0, _object.computed)('hasUserAddressData', 'isSummaryStep', 'isDetailStep', 'shop.{inclTotal,inclTotalExclPaymentFees,inclTotalExclPaymentFeesAndShippingFees}', 'checkoutData.pickupMethod', function () {
      const shop = this.shop || {};
      const isSummaryStep = (this === null || this === void 0 ? void 0 : this.isSummaryStep) || false;
      const isDetailStep = (this === null || this === void 0 ? void 0 : this.isDetailStep) || false;
      const checkoutData = this._checkoutData;
      const pickupMethod = checkoutData.pickupMethod || 'homeDelivery';
      const hasUserAddressData = this.hasUserAddressData || false;
      const inclTotalExclPaymentFeesAndShippingFees = (shop === null || shop === void 0 ? void 0 : shop.inclTotalExclPaymentFeesAndShippingFees) || 0;
      const inclTotalExclPaymentFees = (shop === null || shop === void 0 ? void 0 : shop.inclTotalExclPaymentFees) || 0;
      const inclTotal = (shop === null || shop === void 0 ? void 0 : shop.inclTotal) || 0;

      if (isSummaryStep) {
        return inclTotalExclPaymentFeesAndShippingFees;
      }

      if (isDetailStep && pickupMethod === 'storeLocationPickup') {
        return inclTotalExclPaymentFeesAndShippingFees;
      }

      if (isDetailStep && !hasUserAddressData) {
        return inclTotalExclPaymentFeesAndShippingFees;
      }

      if (isDetailStep && hasUserAddressData) {
        return inclTotalExclPaymentFees;
      }

      return inclTotal;
    }),
    hasAppliedBulkDiscounts: (0, _object.computed)('cartItems.@each.bulkDiscountId', function () {
      const shop = this.shop || {};
      const cartItems = cartItems || [];
      return R.pipe(R.pluck('bulkDiscountId'), R.reject(R.isNil), R.reject(R.isEmpty), R.uniq, _nventor.default.isNotNilOrEmpty)(cartItems);
    }),

    removeCartItem(item) {
      this.shop.removeCartItem('cartItems', item);
      this.onCartChanged();
      this.transitionToShopIfEmpty();
    },

    removeAutomationItem(automation, item) {
      this.shop.removeMayAddItem(automation, item);
      this.onCartChanged();
    },

    transitionToShopIfEmpty() {
      var _this$shop;

      if (_nventor.default.isNilOrEmpty((_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : _this$shop.cartItems)) {
        this.router.transitionTo('shop');
      }
    },

    clearCartCancel() {
      // Hide the modal
      this.set('showClearCartModal', false);
    },

    clearCartSubmit() {
      try {
        this.checkoutService.clearCheckoutId();
        this.router.transitionTo('shop.reset');
      } catch (e) {
        window.localStorage.clear();
        this.transitionTo('shop', {
          queryParams: {
            reload: true
          }
        });
      } // Hide the modal


      this.set('showClearCartModal', false);
    },

    clearCart() {
      this.set('showClearCartModal', true);
    }

  }, (_applyDecoratedDescriptor(_obj, "removeCartItem", [_object.action], Object.getOwnPropertyDescriptor(_obj, "removeCartItem"), _obj), _applyDecoratedDescriptor(_obj, "removeAutomationItem", [_object.action], Object.getOwnPropertyDescriptor(_obj, "removeAutomationItem"), _obj), _applyDecoratedDescriptor(_obj, "transitionToShopIfEmpty", [_object.action], Object.getOwnPropertyDescriptor(_obj, "transitionToShopIfEmpty"), _obj), _applyDecoratedDescriptor(_obj, "clearCartCancel", [_object.action], Object.getOwnPropertyDescriptor(_obj, "clearCartCancel"), _obj), _applyDecoratedDescriptor(_obj, "clearCartSubmit", [_object.action], Object.getOwnPropertyDescriptor(_obj, "clearCartSubmit"), _obj), _applyDecoratedDescriptor(_obj, "clearCart", [_object.action], Object.getOwnPropertyDescriptor(_obj, "clearCart"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/helpers/currency", ["exports", "@ember/component/helper", "shop/mixins/big", "@ember/service"], function (_exports, _helper, _big, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend(_big.default, {
    intl: (0, _service.inject)(),

    compute(params, hash) {
      let number = params[0];
      const defaultValue = params[1];
      const rounding = hash.rounding;
      const negative = hash.negative;
      const str = number + '';

      if (number == null || str.trim() === '') {
        if (defaultValue != null) {
          return defaultValue;
        }

        return 'NT 0';
      }

      if (negative) {
        try {
          const toFixedNumber = this.newBig(number).times(-1).toFixed();
          number = toFixedNumber;
        } catch (e) {
          number = number;
        }
      }

      let options = {};

      if (rounding != null) {
        number = this.newBig(number).toFixed(rounding);
        options = {
          maximumFractionDigits: rounding
        };
      }

      return 'NT ' + this.intl.formatNumber(number, options);
    }

  });

  _exports.default = _default;
});
define("shop/pods/facebook/callback-from-facebook/route", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    facebook: (0, _service.inject)(),

    model() {
      this.facebook.redirectToOriginalWebsite();
    }

  });

  _exports.default = _default;
});
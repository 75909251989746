define("shop/helpers/tt", ["exports", "@ember/component/helper", "@ember/service"], function (_exports, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    intl: (0, _service.inject)(),

    compute(params, hash) {
      const key = params[0];
      const defaultKey = params[1];

      if (!key && defaultKey != null) {
        return this.intl.t(defaultKey, hash);
      }

      let translate = true;

      if (hash.translate === false) {
        translate = false;
      }

      if (translate && key) {
        return this.intl.t(key, hash);
      }

      return key;
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/news/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dBwS4PWB",
    "block": "[[[10,0],[14,0,\"news__card is-condensed news__card--override\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"news__card-wrapper flex-container-column\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card-image news__card-image-container\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@route\",\"@models\",\"@classNames\"],[\"news.article\",[28,[37,1],[[30,1,[\"_data\",\"_key\"]],[30,0,[\"articleNameUrl\"]]],null],\"news-group news-group__container\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@backgroundColor\",\"@defaultAlt\",\"@figureClassNames\",\"@imgClassNames\",\"@imageData\",\"@ratio\",\"@width\",\"@autoResizeToRatio\",\"@imagesHoverFilter\",\"@imagesHoverTransform\"],[[30,1,[\"defaultImageBackgroundColor\"]],[30,0,[\"cropName\"]],\"store-item__card-grid-container is-tappable\",\"store-item__full-grid-position store-location__image-display\",[30,1,[\"defaultImageData\"]],[30,2,[\"newsImagesRatio\"]],\"300\",true,[30,0,[\"imagesHoverFilter\"]],[30,0,[\"imagesHoverTransform\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"news__card-content\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@route\",\"@models\"],[\"news.article\",[28,[37,1],[[30,1,[\"_data\",\"_key\"]],[30,0,[\"articleNameUrl\"]]],null]]],[[\"default\"],[[[[1,\"     \\n        \"],[10,\"h4\"],[14,0,\"title is-4 news-card_is-6\"],[12],[1,[30,0,[\"cropName\"]]],[13],[1,\"\\n\\n        \"],[1,[30,0,[\"cropDescription\"]]],[1,\"\\n        \"],[10,0],[14,0,\"news-card__desc\"],[12],[1,\"\\n          \"],[8,[39,3],null,[[\"@content\",\"@char\"],[[30,1,[\"_data\",\"description\"]],50]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\",\"@website\"],false,[\"link-to\",\"array\",\"elements/image-display\",\"helpers/multiline-truncate\"]]",
    "moduleName": "shop/pods/components/news/card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
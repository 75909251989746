define("shop/pods/components/layouts/social-icons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YmjMbjLA",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@icon\"],[[30,1,[\"icon\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null]],[\"social\"],false,[\"each\",\"-track-array\",\"socialItems\",\"elements/element-icon\"]]",
    "moduleName": "shop/pods/components/layouts/social-icons/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
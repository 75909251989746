define("shop/pods/components/elements/element-btn/component", ["exports", "@ember/component", "@ember/service", "ramda"], function (_exports, _component, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object'
  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    tagName: 'button',
    classNames: ['element-btn', 'button'],
    classNameBindings: ['disabled', 'isRunning:is-loading', 'isSelected:is-selected'],
    attributeBindings: ['disabled', 'tabindex', 'style'],
    translate: true,
    rememberSelected: false,
    _isSelected: false,

    init() {
      this._super(...arguments);

      if (this.rememberSelected) {
        this.set('_isSelected', this.isSelected);
      }
    },

    click(e) {
      const linkTo = this.linkTo;

      if (linkTo) {
        const url = this.url || undefined;
        const queryParams = this.queryParams || undefined;

        if (url && queryParams) {
          return this.router.transitionTo(linkTo, url, queryParams);
        } else if (url) {
          return this.router.transitionTo(linkTo, url);
        }

        return this.router.transitionTo(linkTo);
      } else {
        if (this.rememberSelected) {
          this.toggleProperty('_isSelected');
          this.set('isSelected', this._isSelected);
        }

        if (this.onSubmit) {
          return this.onSubmit(e);
        }
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/items/item-drawer/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "ramda"], function (_exports, _component, _object, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    websiteService: (0, _service.inject)('website'),
    shopService: (0, _service.inject)('shop'),
    helpers: (0, _service.inject)(),
    router: (0, _service.inject)(),
    itemNameUrl: (0, _object.computed)('websiteService.isShowDrawerForShopItemModel.item.{urlProductName,_data.urlProductName}', function () {
      var _this$websiteService, _this$websiteService$, _this$websiteService$2, _this$websiteService2, _this$websiteService3, _this$websiteService4, _this$websiteService5, _this$websiteService6, _this$websiteService7, _this$websiteService8, _this$websiteService9;

      const urlProductName = ((_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : (_this$websiteService$ = _this$websiteService.isShowDrawerForShopItemModel) === null || _this$websiteService$ === void 0 ? void 0 : (_this$websiteService$2 = _this$websiteService$.item) === null || _this$websiteService$2 === void 0 ? void 0 : _this$websiteService$2.urlProductName) || ((_this$websiteService2 = this.websiteService) === null || _this$websiteService2 === void 0 ? void 0 : (_this$websiteService3 = _this$websiteService2.isShowDrawerForShopItemModel) === null || _this$websiteService3 === void 0 ? void 0 : (_this$websiteService4 = _this$websiteService3.item) === null || _this$websiteService4 === void 0 ? void 0 : (_this$websiteService5 = _this$websiteService4._data) === null || _this$websiteService5 === void 0 ? void 0 : _this$websiteService5.urlProductName) || ((_this$websiteService6 = this.websiteService) === null || _this$websiteService6 === void 0 ? void 0 : (_this$websiteService7 = _this$websiteService6.isShowDrawerForShopItemModel) === null || _this$websiteService7 === void 0 ? void 0 : (_this$websiteService8 = _this$websiteService7.item) === null || _this$websiteService8 === void 0 ? void 0 : (_this$websiteService9 = _this$websiteService8._data) === null || _this$websiteService9 === void 0 ? void 0 : _this$websiteService9.item) || '';
      const urlEncoded = encodeURIComponent(urlProductName);
      return this.helpers.beautifyUrl(urlEncoded);
    }),
    modifiedRoute: (0, _object.computed)('websiteService.isShowDrawerForShopItemModel.item._data.itemKey', 'itemNameUrl', function () {
      var _this$websiteService10, _this$websiteService11, _item$_data, _this$websiteService$3;

      const item = ((_this$websiteService10 = this.websiteService) === null || _this$websiteService10 === void 0 ? void 0 : (_this$websiteService11 = _this$websiteService10.isShowDrawerForShopItemModel) === null || _this$websiteService11 === void 0 ? void 0 : _this$websiteService11.item) || {};

      if (!item) {
        return '';
      }

      const itemKey = item === null || item === void 0 ? void 0 : (_item$_data = item._data) === null || _item$_data === void 0 ? void 0 : _item$_data.itemKey;
      const shopUrlDisplay = (_this$websiteService$3 = this.websiteService.website) === null || _this$websiteService$3 === void 0 ? void 0 : _this$websiteService$3.shopUrlDisplay;
      return `/shop/${shopUrlDisplay}/item/${itemKey}/${this.itemNameUrl}`;
    })
  });

  _exports.default = _default;
});
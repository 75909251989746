define("shop/pods/components/items/item-price/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "shop/mixins/big", "shop/utils/nventor", "ramda"], function (_exports, _component, _object, _service, _big, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_big.default, {
    router: (0, _service.inject)(),
    dateService: (0, _service.inject)('date'),
    shopService: (0, _service.inject)('shop'),
    tagName: '',
    showPriceRange: true,
    showDiscountModal: false,
    // currentPrice: computed('item._data.{price,priceDiscounted,onSale}', function () {
    //   const discountedPrice = this.get('item._data.priceDiscounted')
    //   if (!discountedPrice) {
    //     return this.get('item._data.price')
    //   }
    //   return discountedPrice
    // })
    isDiscountModalVisible: (0, _object.computed)('showDiscountModal', function () {
      return this.showDiscountModal;
    }),
    foundCartItem: (0, _object.computed)('item.{hasSelectedCustomizationsChanged,selectedVariant.itemKey,_data._key,_data.itemKey}', 'shop.cartItems.@each.{itemKey,recurringScheduleSelected.recurringScheduleKey}', 'recurringSchedule.recurringScheduleKey', function () {
      var _this$shop;

      return this._findCartItem({
        cartItems: (this === null || this === void 0 ? void 0 : (_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : _this$shop.cartItems) || [],
        item: this.item,
        itemKey: this.get('item._data.itemKey') || '',
        recurringSchedule: this.recurringSchedule
      });
    }),

    _findCartItem({
      cartItems,
      item,
      recurringSchedule
    }) {
      var _item$_data, _item$_data2, _item$selectedVariant;

      let itemKey = (item === null || item === void 0 ? void 0 : (_item$_data = item._data) === null || _item$_data === void 0 ? void 0 : _item$_data.itemKey) || '';
      let key = (item === null || item === void 0 ? void 0 : (_item$_data2 = item._data) === null || _item$_data2 === void 0 ? void 0 : _item$_data2._key) || '';

      if (item !== null && item !== void 0 && (_item$selectedVariant = item.selectedVariant) !== null && _item$selectedVariant !== void 0 && _item$selectedVariant.itemKey) {
        var _item$selectedVariant2, _item$selectedVariant3;

        itemKey = item === null || item === void 0 ? void 0 : (_item$selectedVariant2 = item.selectedVariant) === null || _item$selectedVariant2 === void 0 ? void 0 : _item$selectedVariant2.itemKey;
        key = item === null || item === void 0 ? void 0 : (_item$selectedVariant3 = item.selectedVariant) === null || _item$selectedVariant3 === void 0 ? void 0 : _item$selectedVariant3._key;
      }

      const criteria = [R.propEq('_key', key), R.propEq('itemKey', itemKey), this.shopService.isCartItemCustomizationsSimilar(item)];

      if (recurringSchedule !== null && recurringSchedule !== void 0 && recurringSchedule.recurringScheduleKey) {
        criteria.pushObject(R.pathEq(['recurringScheduleSelected', 'recurringScheduleKey'], recurringSchedule === null || recurringSchedule === void 0 ? void 0 : recurringSchedule.recurringScheduleKey));
      }

      return R.find(R.allPass(criteria))(cartItems);
    },

    isAnyAddedToCartItems: (0, _object.computed)('item.{selectedVariant.itemKey,_data._key,_data.itemKey}', 'shop.cartItems.@each.{itemKey,recurringScheduleSelected.recurringScheduleKey}', 'recurringSchedule.recurringScheduleKey', function () {
      var _this$shop2, _this$item, _this$item$selectedVa;

      const cartItems = (this === null || this === void 0 ? void 0 : (_this$shop2 = this.shop) === null || _this$shop2 === void 0 ? void 0 : _this$shop2.cartItems) || [];
      let itemKey = this.get('item._data.itemKey') || '';

      if (this !== null && this !== void 0 && (_this$item = this.item) !== null && _this$item !== void 0 && (_this$item$selectedVa = _this$item.selectedVariant) !== null && _this$item$selectedVa !== void 0 && _this$item$selectedVa.itemKey) {
        var _this$item2, _this$item2$selectedV;

        itemKey = this === null || this === void 0 ? void 0 : (_this$item2 = this.item) === null || _this$item2 === void 0 ? void 0 : (_this$item2$selectedV = _this$item2.selectedVariant) === null || _this$item2$selectedV === void 0 ? void 0 : _this$item2$selectedV.itemKey;
      }

      const key = this.get('item._data._key') || '';
      const criterias = [R.propEq('_key', key), R.propEq('itemKey', itemKey)];
      const found = R.pipe(R.find(R.allPass(criterias)), _nventor.default.isNotNilOrEmpty)(cartItems);
      return found;
    }),
    isShowBulkDiscountOnPage: (0, _object.computed)('router.currentRouteName', function () {
      var _this$router;

      const routeName = (this === null || this === void 0 ? void 0 : (_this$router = this.router) === null || _this$router === void 0 ? void 0 : _this$router.currentRouteName) || '';

      if (routeName === 'shop.item.page' || routeName === 'shop.detail') {
        // shows bulk discount for item page and shop automation may add
        return true;
      }

      return false;
    }),
    hasBulkDiscount: (0, _object.computed)('foundCartItem.{qty,bulkDiscountData}', 'hasBulkDiscountWithOneQty', function () {
      return this._hasBulkDiscount({
        hasBulkDiscountWithOneQty: this.hasBulkDiscountWithOneQty,
        foundCartItem: this.foundCartItem
      });
    }),

    _hasBulkDiscount({
      foundCartItem,
      hasBulkDiscountWithOneQty
    }) {
      const bulkDiscountData = (foundCartItem === null || foundCartItem === void 0 ? void 0 : foundCartItem.bulkDiscountData) || {};
      return !R.isEmpty(bulkDiscountData) || hasBulkDiscountWithOneQty;
    },

    hasBulkDiscountWithOneQty: (0, _object.computed)('item.bulkDiscountId', 'shop.{bulkDiscounts,rewardData,_data.rewardData}', 'memberService.tokenData.memberLevel}Id', function () {
      return this._hasBulkDiscountWithOneQty({
        bulkDiscountId: this.get('item.bulkDiscountId') || '',
        bulkDiscounts: this.get('shop.bulkDiscounts') || []
      });
    }),

    _hasBulkDiscountWithOneQty({
      bulkDiscountId,
      bulkDiscounts
    }) {
      const bulkDiscountData = this._getBulkDiscountRule({
        bulkDiscounts,
        bulkDiscountId
      });

      return !R.isEmpty(bulkDiscountData);
    },

    isOnSale: (0, _object.computed)('item.{_data.onSale,_data.isSetWithChoices,_data.hasVariants,hasSelectedVariantChanged}', function () {
      if (this.get('item._data.onSale') === 'on') {
        return true;
      } // if (this.get('item._data.isSetWithChoices') || this.get('item._data.hasVariants')) {
      //   const choices = this.get('item._data.choices') || []
      //   const choicesIsOnSale = R.pipe(
      //     R.pluck('onSale'),
      //     R.flatten,
      //     R.includes('on')
      //   )(choices)
      //   return choicesIsOnSale
      // }


      return false;
    }),
    oldPrice: (0, _object.computed)('item.{hasSelectedCustomizationsChanged,_data.isSetWithChoices,_data.hasVariants}', 'hasBulkDiscount', 'totalAmountOld', function () {
      // total from selected choice
      const totalAmountOld = Number(this.totalAmountOld) || 0;

      if (totalAmountOld > 0 && this.get('item._data.isSetWithChoices')) {
        return totalAmountOld;
      }

      if (this.get('item._data.isSetWithChoices')) {
        return Number(this.lowestSetWithChoicesPriceOld) || 0;
      }

      if (this.get('choice._data.isSetPriceOnBundle')) {
        return Number(this.get('choice._data.price')) || 0;
      }

      if (this.get('item._data.hasVariants')) {
        let price = Number(this.lowestVariantPriceOld) || 0;
        const selectedVariant = this.get('item.selectedVariant') || false;

        if (selectedVariant) {
          price = Number(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.price) || 0;
        }

        return this.item._calculateCustomizationsPrice(price);
      }

      const price = Number(this.get('item._data.price')) || 0;
      return this.item._calculateCustomizationsPrice(price);
    }),

    _getBulkDiscountRule({
      bulkDiscounts,
      bulkDiscountId,
      foundCartItem
    }) {
      var _shop$_data;

      const nowZ = this.dateService.getNowZ();
      const memberLevelId = this.get('membersService.tokenData.memberLevelId') || '';
      const shop = this.shop || {};
      const rewardData = (shop === null || shop === void 0 ? void 0 : shop.rewardData) || (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.rewardData) || {};

      if (_nventor.default.isNotNilOrEmpty(foundCartItem)) {
        return foundCartItem.bulkDiscountData || {};
      }

      return R.pipe(R.find(bulkDiscount => {
        const campaignBulkDiscountId = R.propOr('', 'bulkDiscountId')(bulkDiscount);
        const campaignBulkDiscountStatus = R.propOr('enabled', 'status')(bulkDiscount);
        const isEnabledAndMatchedBulkDiscountId = campaignBulkDiscountStatus === 'enabled' && campaignBulkDiscountId === bulkDiscountId;

        if (isEnabledAndMatchedBulkDiscountId) {
          return true;
        }

        return false;
      }), R.propOr([], 'schedules'), R.find(schedule => {
        const dateStartZ = schedule.dateStartZ || '';
        let dateEndZ = schedule.dateEndZ || '';

        if (dateEndZ) {
          dateEndZ = this.dateService.getEndOfDayZ(dateEndZ);
        }

        if (dateStartZ && !dateEndZ && dateStartZ <= nowZ) {
          return true;
        }

        if (dateEndZ && !dateStartZ && dateEndZ >= nowZ) {
          return true;
        }

        if (dateStartZ <= nowZ && dateEndZ >= nowZ) {
          return true;
        }

        return false;
      }), R.propOr([], 'rules'), R.find(rule => {
        const hasMemberLevels = R.propOr(false, 'hasMemberLevels')(rule);
        const status = rule.status || 'active';
        const when = rule.when || '';
        const ruleValue = rule.value || 0;
        const ruleMemberLevelId = rule.memberLevelId || '';

        if (hasMemberLevels && _nventor.default.isNotNilOrEmpty(rewardData)) {
          return when === 'itemQty' && ruleValue === 1 && ruleMemberLevelId === memberLevelId && status === 'active';
        }

        return when === 'itemQty' && ruleValue === 1 && status === 'active';
      }))(bulkDiscounts) || {};
    },

    _calculateBulkDiscountedPrice({
      foundCartItem,
      price
    }) {
      const bulkDiscountId = this.get('item.bulkDiscountId') || '';
      const bulkDiscounts = this.get('shop.bulkDiscounts') || [];
      let bulkDiscountData = {};

      if (_nventor.default.isNilOrEmpty(foundCartItem)) {
        bulkDiscountData = this._getBulkDiscountRule({
          bulkDiscounts,
          bulkDiscountId
        });
      } else {
        bulkDiscountData = this._getBulkDiscountRule({
          bulkDiscounts,
          bulkDiscountId,
          foundCartItem
        });
      }

      const bulkDiscountType = R.propOr('rate', 'discountType')(bulkDiscountData);
      let bulkDiscountAmtBig = this.newBig(R.propOr(0, 'discountAmount')(bulkDiscountData));

      if (bulkDiscountType === 'rate') {
        const bulkDiscountRate = R.propOr(0, 'discountRate')(bulkDiscountData);
        const bulkDiscountRateAmtBig = this.newBig(bulkDiscountRate).div(100);
        bulkDiscountAmtBig = this.newBig(price).times(bulkDiscountRateAmtBig);
      } else if (bulkDiscountType === 'amount') {
        bulkDiscountAmtBig = 0;
      }

      return Number(this.newBig(price).minus(bulkDiscountAmtBig).toFixed(0) || 0);
    },

    currentPrice: (0, _object.computed)('hasIncompleteChoices', 'isOnSale', 'item.{_data.price,_data.priceDiscounted,variantChildId,hasSelectedVariantChanged,selectedVariant.priceFinal,totalAmountCurrent,hasSelectedCustomizationsChanged}', 'hasBulkDiscount', 'hasBulkDiscountWithOneQty', 'foundCartItem.price', 'isRecurringItem', 'recurringSchedule.recurringScheduleKey', function () {
      const foundCartItem = this.foundCartItem;

      if (this.isRecurringItem) {
        if (this.hasBulkDiscount) {
          return this._calculateBulkDiscountedPrice({
            foundCartItem,
            price: this.recurringPrice
          });
        }

        return this.recurringPrice;
      }

      if (this.get('item._data.isSetWithChoices')) {
        let price = this.lowestSetWithChoicesPriceOld;

        if (this.isOnSale) {
          price = this.lowestSetWithChoicesPricePossible;
        }

        if (!this.showPriceRange) {
          price = this.item.get('totalAmountCurrent');
        }

        if (this.hasBulkDiscount) {
          return this._calculateBulkDiscountedPrice({
            foundCartItem,
            price: price
          });
        }

        return price;
      }

      if (this.get('choice._data.isSetPriceOnBundle')) {
        let price = this.get('choice._data.price');

        if (this.isOnSale) {
          price = this.get('choice._data.priceDiscounted');
        }

        if (this.hasBulkDiscount) {
          return this._calculateBulkDiscountedPrice({
            foundCartItem,
            price: price
          });
        }

        return price;
      }

      if (this.get('item._data.hasVariants')) {
        let price = this.lowestVariantPriceOld;

        if (this.isOnSale) {
          price = this.lowestVariantPriceCurrent;
        }

        if (!this.showPriceRange) {
          price = this.item.get('selectedVariant.priceFinal');
        }

        price = this.item._calculateCustomizationsPrice(price);
        const selectedVariant = this.get('item.selectedVariant') || false;

        if (this.hasBulkDiscount && !selectedVariant) {
          return this._calculateBulkDiscountedPrice({
            foundCartItem,
            price: price
          });
        }

        if (this.hasBulkDiscount && selectedVariant) {
          if (this.get('cartItem')) {
            var _this$foundCartItem;

            const price = ((_this$foundCartItem = this.foundCartItem) === null || _this$foundCartItem === void 0 ? void 0 : _this$foundCartItem.price) || 0;
            return Number(this.newBig(price).toFixed(0) || 0);
          }

          return this._calculateBulkDiscountedPrice({
            foundCartItem,
            price: price
          });
        }

        return price;
      }

      if (this.isOnSale) {
        let price = this.get('item._data.priceFinal');
        price = this.item._calculateCustomizationsPrice(price);

        if (this.hasBulkDiscount) {
          return this._calculateBulkDiscountedPrice({
            foundCartItem,
            price: price
          });
        }

        return price;
      }

      let price = this.get('item._data.price');
      price = this.item._calculateCustomizationsPrice(price);

      if (this.hasBulkDiscount) {
        return this._calculateBulkDiscountedPrice({
          foundCartItem,
          price: price
        });
      }

      return price;
    }),
    variantChildrenData: (0, _object.computed)('item._data.variantChildren', function () {
      return R.pathOr([], ['item', '_data', 'variantChildren'], this);
    }),
    choiceData: (0, _object.computed)('item._data.choices.[]', function () {
      return R.pipe(R.pathOr([], ['item', '_data', 'choices']), R.pluck('_data'), R.reject(R.isEmpty || R.isNil))(this);
    }),
    choiceDetailsData: (0, _object.computed)('choiceData.[]', function () {
      const choiceData = (this === null || this === void 0 ? void 0 : this.choiceData) || [];
      return R.pipe(R.map(choice => {
        return R.pathOr([], ['details'])(choice);
      }), R.flatten, R.reject(R.isEmpty || R.isNil), R.pluck('_data'), R.reject(R.isEmpty || R.isNil))(choiceData);
    }),
    lowestVariantOldPriceRange: (0, _object.computed)('item._data.{price,priceDiscounted}', 'variantChildrenData.@each.{price,priceDiscounted}', function () {
      const variantChildrenData = this.variantChildrenData;
      const variantChildren = this.get('item._data.variantChildren') || [];

      const diff = function (a, b) {
        return parseFloat(a) - parseFloat(b);
      };

      const allPriceFilled = R.pipe(R.map(child => {
        return child.price || 0;
      }), R.reject(n => R.equals(n, 0)), R.length, R.equals(variantChildren.length))(variantChildren);
      let variantMasterPrice = this.get('item._data.price');

      if (!variantMasterPrice) {
        variantMasterPrice = 0;
      }

      if (allPriceFilled) {
        variantMasterPrice = 0;
      }

      const isEmpty = R.curry((prop, child) => {
        return R.isEmpty(R.prop(prop, child));
      });
      return R.pipe(R.reject(R.allPass([isEmpty('price'), isEmpty('priceDiscounted')])), R.map(variantChild => {
        const price = R.prop('price')(variantChild);

        if (price) {
          return price || 0;
        }

        return R.prop('priceDiscounted')(variantChild) || 0;
      }), R.concat([variantMasterPrice]), R.reject(n => R.equals(n, 0)), R.uniq, R.sort(diff))(variantChildren);
    }),
    lowestVariantPriceOld: (0, _object.computed)('item._data.{price,priceDiscounted}', 'variantChildrenData.@each.{price,priceDiscounted}', function () {
      const variantChildrenData = this.variantChildrenData;
      const lowestVariantPrices = this.lowestVariantOldPriceRange || [];
      let lowestVariantPrice;

      if (lowestVariantPrices.length > 0) {
        lowestVariantPrice = lowestVariantPrices[0];
      }

      return lowestVariantPrice;
    }),
    lowestVariantCurrentPriceRange: (0, _object.computed)('item._data.{price,priceDiscounted,onSale}', 'variantChildrenData.@each.{price,priceDiscounted}', function () {
      const variantChildrenData = this.variantChildrenData;
      const variantChildren = this.get('item._data.variantChildren') || [];

      const diff = function (a, b) {
        return parseFloat(a) - parseFloat(b);
      };

      const onSale = this.get('item._data.onSale');
      const allPriceFilled = R.pipe(R.map(child => {
        return child.price || 0;
      }), R.reject(n => R.equals(n, 0)), R.length, R.equals(variantChildren.length))(variantChildren);
      const allPriceDiscountedFilled = R.pipe(R.map(child => {
        return child.priceDiscounted || 0;
      }), R.reject(n => R.equals(n, 0)), R.length, R.equals(variantChildren.length))(variantChildren);
      let variantMasterPrice = this.get('item._data.price');

      if (!variantMasterPrice || onSale) {
        variantMasterPrice = this.get('item._data.priceDiscounted') || 0;

        if (allPriceDiscountedFilled) {
          variantMasterPrice = 0;
        }
      } else {
        if (allPriceFilled) {
          variantMasterPrice = 0;
        }
      }

      const isEmpty = R.curry((prop, child) => {
        return R.isEmpty(R.prop(prop, child));
      });
      return R.pipe(R.reject(R.allPass([isEmpty('price'), isEmpty('priceDiscounted')])), R.map(variantChild => {
        if (onSale === 'on') {
          // if (variantChild.onSale === 'on') {
          return variantChild.priceDiscounted || 0; // }
        }

        return variantChild.price || 0;
      }), R.concat([variantMasterPrice]), R.reject(n => R.equals(n, 0)), R.uniq, R.sort(diff))(variantChildren);
    }),
    lowestVariantPriceCurrent: (0, _object.computed)('item._data.{price,priceDiscounted,onSale}', 'variantChildrenData.@each.{price,priceDiscounted}', function () {
      const variantChildrenData = this.variantChildrenData;
      const lowestVariantPrices = this.lowestVariantCurrentPriceRange || [];
      let lowestVariantPrice;

      if (lowestVariantPrices.length > 0) {
        lowestVariantPrice = lowestVariantPrices[0];
      }

      return lowestVariantPrice;
    }),
    lowestSetWithChoicesPriceOld: (0, _object.computed)('item._data.{choices.[]}', function () {
      const choices = this.get('item._data.choices') || [];

      const diff = function (a, b) {
        return parseFloat(a) - parseFloat(b);
      };

      const lowestTotalPrice = R.pipe(R.map(choice => {
        const chooseQty = choice._data.qty;
        const isSetPriceOnBundle = choice._data.isSetPriceOnBundle;
        const details = choice._data.details || [];
        const lowestPrices = R.map(detail => {
          if (detail._data.price) {
            return detail._data.price;
          }

          return detail._data.priceDiscounted;
        })(details);
        let lowestPrice = R.pipe(R.sort(diff), R.head)(lowestPrices);

        if (isSetPriceOnBundle) {
          if (choice._data.price) {
            lowestPrice = choice._data.price || 0;
          } else {
            lowestPrice = choice._data.priceDiscounted || 0;
          }

          return lowestPrice;
        }

        return chooseQty * lowestPrice;
      }), R.flatten, R.reduce((acc, lowestPrice) => {
        acc = parseInt(acc) + parseInt(lowestPrice);
        return acc;
      }, 0))(choices);
      return lowestTotalPrice;
    }),
    lowestSetWithChoicesPricePossible: (0, _object.computed)('item._data.{choices.[],onSale}', function () {
      const choices = this.get('item._data.choices') || [];

      const diff = function (a, b) {
        return parseFloat(a) - parseFloat(b);
      };

      const onSale = this.get('item._data.onSale');
      const lowestTotalPrice = R.pipe(R.map(choice => {
        const chooseQty = choice._data.qty;
        const isSetPriceOnBundle = choice._data.isSetPriceOnBundle;
        const details = choice._data.details || [];
        const lowestPrices = R.map(detail => {
          if (onSale === 'on') {
            // if (detail._data.onSale === 'on') {
            return detail._data.priceDiscounted; // }
          }

          return detail._data.price;
        })(details);
        let lowestPrice = R.pipe(R.sort(diff), R.head)(lowestPrices);

        if (isSetPriceOnBundle) {
          if (onSale === 'on') {
            lowestPrice = choice._data.priceDiscounted || 0;
          } else {
            lowestPrice = choice._data.price || 0;
          }

          return lowestPrice;
        }

        return chooseQty * lowestPrice;
      }), R.flatten, R.reduce((acc, lowestPrice) => {
        acc = parseInt(acc) + parseInt(lowestPrice);
        return acc;
      }, 0))(choices);
      return lowestTotalPrice;
    }),

    getAllPricesForWhenHasRecurring() {
      var _this$shop3;

      const cartItems = (this === null || this === void 0 ? void 0 : (_this$shop3 = this.shop) === null || _this$shop3 === void 0 ? void 0 : _this$shop3.cartItems) || [];
      const item = this.item || {};
      const currentPrice = this.currentPrice; // for non-recurring item price

      const recurringSchedules = R.pathOr([], ['_data', 'recurringSchedules'])(item);
      let allPrices = R.pipe(R.map(recurringSchedule => {
        let recurringPrice = this.item.getRecurringPrice(recurringSchedule);

        if (item._data.isSetWithChoices) {
          var _this$item3;

          const choiceDetailsData = this.choiceDetailsData || [];
          const totalChosenQty = R.pipe(R.pluck('qty'), R.sum)(choiceDetailsData);
          recurringPrice = ((_this$item3 = this.item) === null || _this$item3 === void 0 ? void 0 : _this$item3.totalAmountCurrent) || '0';

          if (R.equals(totalChosenQty, 0)) {
            let price = this.lowestSetWithChoicesPriceOld;

            if (this.isOnSale) {
              price = this.lowestSetWithChoicesPricePossible;
            }

            recurringPrice = this.item.getRecurringPrice(recurringSchedule, price);
          }
        }

        const foundCartItem = this._findCartItem({
          cartItems,
          item,
          recurringSchedule
        });

        const hasBulkDiscount = this._hasBulkDiscount({
          hasBulkDiscountWithOneQty: this.hasBulkDiscountWithOneQty,
          foundCartItem
        });

        if (hasBulkDiscount) {
          return this._calculateBulkDiscountedPrice({
            foundCartItem,
            price: recurringPrice
          });
        }

        return recurringPrice;
      }))(recurringSchedules); // if (!item?._data?.recurringSchedulesOnly) {
      //   allPrices = R.prepend(currentPrice)(allPrices)
      // }

      allPrices = R.pipe(R.uniq, R.map(price => {
        return Number(price) || price;
      }), _nventor.default.sortAscending)(allPrices);
      return allPrices;
    },

    isOneRecurringSchedulesPriceRange: (0, _object.computed)('recurringSchedulesPriceRange.[]', function () {
      const recurringSchedulesPriceRange = this.recurringSchedulesPriceRange || [];
      return R.length(recurringSchedulesPriceRange) === 1;
    }),
    recurringSchedulesPriceRange: (0, _object.computed)('item._data.{onSale,recurringSchedulesEnabled}', 'choiceDetailsData.@each.{price,priceDiscounted,qty}', 'variantChildrenData.@each.{price,priceDiscounted}', 'cartItems.[]', function () {
      const choiceDetailsData = this.choiceDetailsData; // NEEDED TO TRIGGER THE COMPUTED??

      const variantChildrenData = this.variantChildrenData; // NEEDED TO TRIGGER THE COMPUTED??

      const allPrices = this.getAllPricesForWhenHasRecurring() || [];
      return allPrices;
    }),
    recurringSchedulesLowest: (0, _object.computed)('item._data.{onSale,recurringSchedulesEnabled}', 'choiceDetailsData.@each.{price,priceDiscounted,qty}', 'variantChildrenData.@each.{price,priceDiscounted}', 'cartItems.[]', function () {
      const choiceDetailsData = this.choiceDetailsData; // NEEDED TO TRIGGER THE COMPUTED??

      const variantChildrenData = this.variantChildrenData; // NEEDED TO TRIGGER THE COMPUTED??

      const allPrices = this.recurringSchedulesPriceRange || [];
      return _nventor.default.safeHead(allPrices) || 0;
    }),
    recurringSchedulesHighest: (0, _object.computed)('item._data.{onSale,recurringSchedulesEnabled}', 'choiceDetailsData.@each.{price,priceDiscounted,qty}', 'variantChildrenData.@each.{price,priceDiscounted}', 'cartItems.[]', function () {
      const choiceDetailsData = this.choiceDetailsData; // NEEDED TO TRIGGER THE COMPUTED??

      const variantChildrenData = this.variantChildrenData; // NEEDED TO TRIGGER THE COMPUTED??

      const allPrices = this.recurringSchedulesPriceRange || [];
      return _nventor.default.safeLast(allPrices) || 0;
    }),
    isAutomationRelated: (0, _object.computed)('item._data.isAutomation', 'founcCartItem.isAutomation', function () {
      var _this$item4, _this$item4$_data, _this$foundCartItem2;

      if ((_this$item4 = this.item) !== null && _this$item4 !== void 0 && (_this$item4$_data = _this$item4._data) !== null && _this$item4$_data !== void 0 && _this$item4$_data.isAutomation) {
        return true;
      }

      if ((_this$foundCartItem2 = this.foundCartItem) !== null && _this$foundCartItem2 !== void 0 && _this$foundCartItem2.isAutomation) {
        return true;
      }

      return false;
    }),
    isAutomationMustAdd: (0, _object.computed)('item._data.automationThen', function () {
      var _this$item5, _this$item5$_data;

      if ((this === null || this === void 0 ? void 0 : (_this$item5 = this.item) === null || _this$item5 === void 0 ? void 0 : (_this$item5$_data = _this$item5._data) === null || _this$item5$_data === void 0 ? void 0 : _this$item5$_data.automationThen) === 'mustAdd') {
        return true;
      }

      return false;
    }),
    isRecurringItem: (0, _object.computed)('isAutomationRelated', 'recurringSchedule.recurringScheduleKey', function () {
      var _this$recurringSchedu;

      if (this.isAutomationRelated) {
        return false;
      }

      if ((_this$recurringSchedu = this.recurringSchedule) !== null && _this$recurringSchedu !== void 0 && _this$recurringSchedu.recurringScheduleKey) {
        return true;
      }
    }),
    hasPriceRange: (0, _object.computed)('item._data.{onSale,recurringSchedulesEnabled}', 'choiceDetailsData.@each.{price,priceDiscounted,qty}', 'variantChildrenData.@each.{price,priceDiscounted}', 'recurringSchedule.recurringScheduleKey', 'isRecurringItem', 'isAutomationRelated', function () {
      var _item$_data3, _this$recurringSchedu2;

      const item = this.item;
      const choiceDetailsData = this.choiceDetailsData; // NEEDED TO TRIGGER THE COMPUTED??

      const variantChildrenData = this.variantChildrenData; // NEEDED TO TRIGGER THE COMPUTED??

      if (item !== null && item !== void 0 && (_item$_data3 = item._data) !== null && _item$_data3 !== void 0 && _item$_data3.recurringSchedulesEnabled && !((_this$recurringSchedu2 = this.recurringSchedule) !== null && _this$recurringSchedu2 !== void 0 && _this$recurringSchedu2.recurringScheduleKey) && !this.isAutomationRelated) {
        var _this$shop4;

        const cartItems = (this === null || this === void 0 ? void 0 : (_this$shop4 = this.shop) === null || _this$shop4 === void 0 ? void 0 : _this$shop4.cartItems) || [];
        const item = this.item || {};
        const recurringSchedules = R.pathOr([], ['_data', 'recurringSchedules'])(item);
        const allPrices = this.recurringSchedulesPriceRange || []; // if has any recurring schedules price (even just 1)
        // show price range

        if (allPrices.length > 0) {
          return true;
        }

        return false;
      }

      const choices = this.get('item._data.choices') || [];
      const onSale = this.get('item._data.onSale');

      if (this.get('item._data.hasVariants')) {
        const lowestVariantCurrentPriceRange = this.lowestVariantCurrentPriceRange || [];

        if (lowestVariantCurrentPriceRange.length > 1) {
          return true;
        }

        return false;
      }

      const onlyChoicesWithPriceRanges = R.filter(choice => {
        const details = choice._data.details || [];
        const isSetPriceOnBundle = choice._data.isSetPriceOnBundle;

        if (isSetPriceOnBundle) {
          return false;
        }

        const allUniqueDetailPrices = R.pipe(R.map(detail => {
          if (onSale === 'on') {
            // if (detail._data.onSale === 'on') {
            return detail._data.priceDiscounted; // }
          }

          return detail._data.price;
        }), R.uniq)(details);

        if (allUniqueDetailPrices.length > 1) {
          return true;
        }

        return false;
      })(choices);

      if (this.get('item._data.isSetWithChoices')) {
        const allChoiceisSetPriceOnBundleArray = R.pipe(R.pathOr([], ['item', '_data', 'choices']), R.pluck('_data'), R.reject(R.isEmpty || R.type(undefined)), R.pluck('isSetPriceOnBundle'))(this) || [];

        if (R.all(R.equals(true), allChoiceisSetPriceOnBundleArray)) {
          return false;
        }
      }

      if (onlyChoicesWithPriceRanges.length > 0) {
        return true;
      }

      return false;
    }),
    recurringPrice: (0, _object.computed)('item.{hasSelectedCustomizationsChanged,_data.price,_data.priceDiscounted,_data.onSale}', 'recurringSchedule.recurringScheduleKey', function () {
      var _this$item6, _this$item6$getRecurr, _this$item7, _this$item7$_data;

      const recurringSchedule = this.recurringSchedule;
      let recurringPrice = ((_this$item6 = this.item) === null || _this$item6 === void 0 ? void 0 : (_this$item6$getRecurr = _this$item6.getRecurringPrice) === null || _this$item6$getRecurr === void 0 ? void 0 : _this$item6$getRecurr.call(_this$item6, recurringSchedule)) || ''; // how to handle when there is an error. what price to show?

      if (this !== null && this !== void 0 && (_this$item7 = this.item) !== null && _this$item7 !== void 0 && (_this$item7$_data = _this$item7._data) !== null && _this$item7$_data !== void 0 && _this$item7$_data.isSetWithChoices) {
        var _this$item8;

        const choiceDetailsData = this.choiceDetailsData || [];
        const totalChosenQty = R.pipe(R.pluck('qty'), R.sum)(choiceDetailsData);
        let price = ((_this$item8 = this.item) === null || _this$item8 === void 0 ? void 0 : _this$item8.totalAmountCurrent) || '0';

        if (R.equals(totalChosenQty, 0)) {
          price = this.lowestSetWithChoicesPriceOld;

          if (this.isOnSale) {
            price = this.lowestSetWithChoicesPricePossible;
          }
        }

        recurringPrice = this.item.getRecurringPrice(recurringSchedule, price);
      }

      return recurringPrice;
    }),
    actions: {
      onClickShowDiscountModal() {
        (0, _object.set)(this, 'showDiscountModal', true);
      },

      onClickCloseDiscountDialog() {
        (0, _object.set)(this, 'showDiscountModal', false);
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/payments/card/by-link-page/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-concurrency-decorators", "shop/utils/nventor", "ramda"], function (_exports, _component, _object, _tracking, _service, _emberConcurrencyDecorators, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PaymentCardByLinkPageComponent = (_dec = (0, _service.inject)('config'), _dec2 = (0, _service.inject)('website'), (_class = class PaymentCardByLinkPageComponent extends _component.default {
    constructor() {
      var _this$args;

      super(...arguments);

      _initializerDefineProperty(this, "configService", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "tapPay", _descriptor4, this);

      _initializerDefineProperty(this, "storage", _descriptor5, this);

      _initializerDefineProperty(this, "isEditingCreditCard", _descriptor6, this);

      _initializerDefineProperty(this, "isNewCreditCard", _descriptor7, this);

      _initializerDefineProperty(this, "tapPayLinePay", _descriptor8, this);

      _initializerDefineProperty(this, "taxNumberError", _descriptor9, this);

      _defineProperty(this, "tapPayUseFields", true);

      const website = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.website) || {};
      const checkoutUser = R.pipe(R.pathOr({}, ['user', '_data']), R.pick(['memberId', 'email', 'name']))(website);
      this.storage.setValue('checkoutUser', checkoutUser, website, 300000);
      const document = this.args.document || {};

      if (document.autoPrintInvoices === 'on') {
        if (document !== null && document !== void 0 && document.taxNumber) {
          (0, _object.set)(document, 'twEInvoiceCarrierType', 'b2b');
        }
      } else {
        (0, _object.set)(document, 'taxNumber', '');
        (0, _object.set)(document, 'twEInvoiceCarrierType', '');
        (0, _object.set)(document, 'twEInvoiceCarrierId', '');
      }
    }

    get isCreditCard() {
      var _this$args2, _this$args2$document;

      return ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$document = _this$args2.document) === null || _this$args2$document === void 0 ? void 0 : _this$args2$document.paymentMethodKey) === 'creditCard';
    }

    get hasFailedScheduledPaymentForRecurring() {
      var _this$args3;

      const document = ((_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.document) || {};
      const paymentCreditCardStatus = (document === null || document === void 0 ? void 0 : document.paymentCreditCardStatus) || '';
      const isNotApprovedPayment = paymentCreditCardStatus !== 'approved';
      const isRecurringChild = (document === null || document === void 0 ? void 0 : document.isRecurringChild) || false;
      const isCreditCard = this.isCreditCard;

      if (isRecurringChild && isNotApprovedPayment && isCreditCard) {
        return true;
      }

      return false;
    }

    get isSuccessful() {
      return R.pathEq(['args', 'document', 'paymentCreditCardStatus'], 'approved')(this);
    }

    editCreditCard() {
      const user = this.args.user;
      const checkoutCreditCardData = user.get('checkoutCreditCardData');
      checkoutCreditCardData.toggleProperty('useExistingCreditCard');
      this.isEditingCreditCard = !this.isEditingCreditCard;
      this.isNewCreditCard = false;
    }

    resetAndUseExistingCreditCard() {
      const user = this.args.user;
      const checkoutCreditCardData = user.getAndReturnDefaultCreditCard({
        isTapPay: true
      });
      user.set('checkoutCreditCardData', checkoutCreditCardData);
      this.isEditingCreditCard = !this.isEditingCreditCard;
      this.isNewCreditCard = false;
      this.websiteService.clearErrors();
    }

    changeCreditCard(ccNumber) {
      const user = this.args.user;
      const checkoutCreditCardData = user.getAndReturnDefaultCreditCard({
        isTapPay: true,
        ccId: ccNumber
      });
      user.set('checkoutCreditCardData', checkoutCreditCardData);
    }

    newCreditCard() {
      const user = this.args.user;
      const checkoutCreditCardData = user.getAndReturnDefaultCreditCard({
        isTapPay: true,
        newCard: true
      });
      user.set('checkoutCreditCardData', checkoutCreditCardData);
      user.set('checkoutCreditCardData.useExistingCreditCard', false);
      this.isEditingCreditCard = !this.isEditingCreditCard;
      this.isNewCreditCard = true;
      this.websiteService.clearErrors();
    }

    hasCompleteCreditCardInfoTapPay() {
      var _user$checkoutCreditC;

      const user = this.args.user;

      if (user !== null && user !== void 0 && (_user$checkoutCreditC = user.checkoutCreditCardData) !== null && _user$checkoutCreditC !== void 0 && _user$checkoutCreditC.useExistingCreditCard) {
        return true;
      }

      return this.tapPay.hasCompleteCreditCardInfoTapPay;
    }

    *payNowTask() {
      if (this.isCreditCard) {
        const validCCInfo = this.hasCompleteCreditCardInfoTapPay();

        if (!validCCInfo) {
          _nventor.default.goToClassName('checkout-payment-page__credit-card-container', -70);

          return false;
        }
      }

      try {
        this.websiteService.clearErrors();
        const document = this.args.document;
        const user = this.args.user;
        let userData = user.serialize();

        try {
          if (document.paymentMethodKey === 'creditCard') {
            const useExistingCreditCard = R.path(['checkoutCreditCardData', 'useExistingCreditCard'])(user);
            const ccId = R.path(['checkoutCreditCardData', 'ccId'])(user);

            if (ccId && useExistingCreditCard) {
              userData = R.assocPath(['creditCard', 'useExistingCreditCard'], true)(userData);
              userData = R.assocPath(['creditCard', 'ccId'], ccId)(userData);
            } else {
              const primeData = yield this.tapPay.getPrimeTask.perform({
                tapPayUseFields: this.tapPayUseFields
              });
              const rememberCreditCard = R.path(['checkoutCreditCardData', 'rememberCreditCard'])(user);
              userData = R.assocPath(['creditCard', 'primeData'], primeData)(userData);
              userData = R.assocPath(['creditCard', 'rememberCreditCard'], rememberCreditCard)(userData);
            }
          } else if (document.paymentMethodKey === 'linePay') {
            const primeData = yield this.tapPay.getLinePayPrimeTask.perform();
            userData = R.assocPath(['linePay', 'primeData'], primeData)(userData);
          }
        } catch (e) {
          throw new Error('TapPayError', 'tapPayError');
        }

        const payload = {
          version: this.configService.config.APP.version,
          user: userData,
          document
        }; // const resp = yield this.get('shopService').get('checkoutTask').perform({ shopKey, payload })
        // const host = this.websiteService.getHost()
        // payload.user.host = host

        const resp = yield this.websiteService.request(`/api/public/payments/card/doc`, {
          method: 'POST',
          data: payload
        });

        if (resp.data.redirect) {
          window.location.replace(resp.data.redirect.url);
          return;
        }

        const responseData = (resp === null || resp === void 0 ? void 0 : resp.data) || {};
        const isApproved = (responseData === null || responseData === void 0 ? void 0 : responseData.paymentCreditCardStatus) === 'approved';
        const email = responseData === null || responseData === void 0 ? void 0 : responseData.email;
        const ref = responseData === null || responseData === void 0 ? void 0 : responseData.ref;

        if (isApproved && email && ref) {
          return this.router.transitionTo('orders.status', email, ref);
        } // window.location.reload(true)


        this.router.transitionTo('erp.payments.card.doc', document.externalId, {
          queryParams: {
            success: 'payment successful'
          }
        });
      } catch (e) {
        const serverErrorMsg = R.path(['payload', 'message'])(e) || false;

        if (e.message === 'TapPayError') {
          this.websiteService.setErrors(e, {
            errorMessage: 'invalid credit card'
          });
        } else if (e.message === 'Validation Error') {
          // client side credit card validation error
          this.websiteService.setErrors(e, {
            errorMessage: 'error occurred, please contact us'
          });
        } else if (serverErrorMsg === 'validation error' || serverErrorMsg === 'incorrect credit card details') {
          this.websiteService.setErrors(e, {
            errorMessage: 'error occurred, please contact us'
          });
        } else {
          // unknown errors
          const errorData = R.path(['data'])(e) || {};
          errorData.message = R.path(['message'])(e) || '';
          errorData.stack = R.path(['stack'])(e) || ''; // let toSendErrors
          // try {
          //   toSendErrors = JSON.parse(JSON.stringify(errorData))
          // } catch (e) {
          //   toSendErrors = { error: 'to send error stringify error' }
          // }
          // yield this.websiteService.get('logTask').perform({
          //   event: 'checkoutValidationError',
          //   eventType: 'error',
          //   page: 'checkout/detail-page',
          //   errors: toSendErrors || 'validationError',
          //   data: {
          //     errors: toSendErrors || 'validationError',
          //     version: config.APP.version,
          //     userData,
          //     cart: document
          //   }
          // })

          this.websiteService.setErrors({}, {
            errorMessage: 'error occurred, please contact us'
          });
        }
      }
    }

    toggleTapPayLinePay(value = false) {
      this.tapPayLinePay = value;
    }

    onSetTwEInvoiceCarrierType() {
      if (this.args.document.twEInvoiceCarrierType) {
        (0, _object.set)(this.args.document, 'taxNumber', '');
        (0, _object.set)(this.args.document, 'companyName', '');
      } else {
        (0, _object.set)(this.args.document, 'twEInvoiceCarrierId', '');
      }
    }

    onSetTwEInvoiceCarrierId(value) {
      const carrierId = this.args.document.twEInvoiceCarrierId || '';
      (0, _object.set)(this.args.document, 'twEInvoiceCarrierId', R.toUpper(carrierId));
    }

    *validateTaxNumberTask(taxNumber) {
      var _taxNumber;

      this.taxNumberError = false;
      const document = this.args.document;

      if (!taxNumber) {
        taxNumber = document.taxNumber || '';
      }

      if (!taxNumber) {
        return;
      }

      if (((_taxNumber = taxNumber) === null || _taxNumber === void 0 ? void 0 : _taxNumber.length) < 8) {
        this.taxNumberError = true;
        return;
      }

      const taxNumberData = yield this.websiteService.fetchAllDetailsFromGovTask.perform(taxNumber);

      if ((taxNumberData === null || taxNumberData === void 0 ? void 0 : taxNumberData.error) === 404) {
        this.taxNumberError = true;
        return;
      }

      if (taxNumberData !== null && taxNumberData !== void 0 && taxNumberData.name) {
        (0, _object.set)(document, 'companyName', taxNumberData === null || taxNumberData === void 0 ? void 0 : taxNumberData.name);
      }

      if (!(taxNumberData !== null && taxNumberData !== void 0 && taxNumberData.name)) {
        this.taxNumberError = true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "configService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tapPay", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "storage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isEditingCreditCard", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isNewCreditCard", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tapPayLinePay", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "taxNumberError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "editCreditCard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editCreditCard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetAndUseExistingCreditCard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetAndUseExistingCreditCard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeCreditCard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeCreditCard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newCreditCard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newCreditCard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "payNowTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "payNowTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleTapPayLinePay", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleTapPayLinePay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSetTwEInvoiceCarrierType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSetTwEInvoiceCarrierType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSetTwEInvoiceCarrierId", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSetTwEInvoiceCarrierId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateTaxNumberTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "validateTaxNumberTask"), _class.prototype)), _class));
  _exports.default = PaymentCardByLinkPageComponent;
});
define("shop/pods/components/shop/orders-query/component", ["exports", "ramda", "@ember/component", "@ember/service", "@ember/object", "ember-concurrency", "shop/utils/nventor"], function (_exports, R, _component, _service, _object, _emberConcurrency, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    shopService: (0, _service.inject)('shop'),
    tagName: 'div',
    orders: null,

    init() {
      this._super(...arguments);

      const params = this.params;
      const email = R.propOr('', 'email', params);
      const ref = R.propOr('', 'ref', params);
      this.set('data', {
        email,
        ref
      });
      this.set('orders', null);
    },

    isEmailOrRefEmpty: (0, _object.computed)('params.ref', 'params.email', function () {
      if (_nventor.default.safeTrim(this.params.ref) === '' || _nventor.default.safeTrim(this.params.email) === '') {
        return true;
      }

      return false;
    }),
    searchTask: (0, _emberConcurrency.task)(function* (data) {
      const shop = this.shop;

      if (shop) {
        const url = shop.get('_data.url');
        this.router.transitionTo('shop.orders.status', url, data.email, data.ref);
      } else {
        this.router.transitionTo('orders.status', data.email, data.ref);
      }
    })
  });

  _exports.default = _default;
});
define("shop/pods/shop/referral/route", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "ramda"], function (_exports, _route, _rsvp, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShopReferralRoute = (_class = class ShopReferralRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "queryParams", {
        code: {
          refreshModel: true
        }
      });
    }

    async model(params) {
      try {
        const shop = this.modelFor('shop').shop;
        const website = this.modelFor('application').website;
        const referralCode = params.code || '';
        const isCampaign = this.modelFor('application').isCampaign;
        const shopKey = shop.shopKey || shop._data._key || '';
        const websiteKey = website._data._key || '';
        const podKey = shop._data.podKey || website._data.podKey || '';
        const shopUrl = shop.url;
        const response = await this.websiteService.request(`/api/public/websites/members/${shopUrl}/referral`, {
          method: 'GET',
          data: {
            referralCode,
            shopKey,
            websiteKey,
            podKey
          }
        });
        const referralData = R.propOr({}, 'data')(response);
        return _rsvp.default.hash({
          isCampaign,
          website,
          shop,
          referralData,
          inputtedReferralCode: referralData.referralCode || referralCode || ''
        });
      } catch (e) {
        this.transitionTo('/');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ShopReferralRoute;
});
define("shop/pods/components/layouts/filters/filters-sort/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BcRVYJFK",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"filters-section__header\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,1]],[[\"translate\"],[[30,2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[15,0,[29,[[52,[30,3],[30,3],\"filters-section__content\"]]]],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@onSelect\",\"@optionLabelKey\",\"@optionValueKey\",\"@options\",\"@translate\",\"@value\"],[[30,4],\"label\",\"label\",[30,5],true,[99,3,[\"@value\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@header\",\"@translate\",\"@filtersClassName\",\"@onUpdateSortBy\",\"@sortBy\"],false,[\"if\",\"tt\",\"elements/element-select\",\"selectedSortBy\"]]",
    "moduleName": "shop/pods/components/layouts/filters/filters-sort/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
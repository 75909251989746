define("shop/pods/components/items/item-card/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "shop/mixins/images", "ramda"], function (_exports, _component, _object, _service, _images, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_images.default, {
    itemService: (0, _service.inject)('item'),
    websiteService: (0, _service.inject)('website'),
    intl: (0, _service.inject)(),
    tagName: '',
    isLink: true,
    maxNameLength: 18,

    didInsertElement() {
      this._super(...arguments);

      const item = this === null || this === void 0 ? void 0 : this.item;
      this.itemService.isUnlocked(item);
    },

    // @TODO: move shop computed properties to model
    isCondensed: (0, _object.computed)('shop.isOverViewStyleCondensed', function () {
      return true;
    }),
    itemHasBorder: (0, _object.computed)('shop.itemHasBorder', function () {
      return this.get('shop.itemHasBorder') || false;
    }),
    // name: computed('item._data.item', function () {
    //   const name = this.get('item._data.item') || ''
    //   if (name.length > 20) {
    //     return R.take(20)(name) + '...'
    //   }
    //   return name
    // }),
    width: (0, _object.computed)('website.imageSize', function () {
      var _this$website;

      return (_this$website = this.website) === null || _this$website === void 0 ? void 0 : _this$website.imageSize;
    }),
    isSetWithChoicesOrHasVariants: (0, _object.computed)('item._data.{isSetWithChoices,hasVariants}', function () {
      if (this.get('item._data.isSetWithChoices')) {
        return true;
      }

      if (this.get('item._data.hasVariants')) {
        return true;
      }

      return false;
    }),
    showAddToCartBtn: (0, _object.computed)('shop._data.showAddToCartBtn', 'automation', function () {
      if (this.automation) {
        return true;
      }

      const hasAddToCartBtn = this.get('shop._data.showAddToCartBtn');

      if (hasAddToCartBtn === false) {
        return false;
      }

      return true;
    }),
    showItemName: (0, _object.computed)('shop._data.showAddItemName', function () {
      const showAddItemName = this.get('shop._data.showAddItemName');

      if (showAddItemName === false) {
        return false;
      }

      return true;
    }),
    showItemCode: (0, _object.computed)('shop._data.showItemCode', function () {
      var _this$shop, _this$shop$_data;

      return (this === null || this === void 0 ? void 0 : (_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : (_this$shop$_data = _this$shop._data) === null || _this$shop$_data === void 0 ? void 0 : _this$shop$_data.showItemCode) || false;
    }),
    showItemDescription: (0, _object.computed)('shop._data.showItemDescription', function () {
      const showItemDescription = this.get('shop._data.showItemDescription');

      if (showItemDescription === true) {
        return true;
      }

      return false;
    }),
    cartItem: (0, _object.computed)('shop.cartItems.[]', 'item._data.itemKey', function () {
      const cartItems = this.get('shop.cartItems') || [];
      const itemKey = this.get('item._data.itemKey') || '';
      return R.find(R.propEq('itemKey', itemKey))(cartItems) || {};
    }),
    isHidePrice: (0, _object.computed)('item._data.{isHidePriceWhenHasPassword,_key}', 'isUnlocked', function () {
      var _this$item, _this$item$_data, _this$item2, _this$item2$_data;

      const isUnlocked = this === null || this === void 0 ? void 0 : this.isUnlocked;
      const isHidePriceWhenHasPassword = (this === null || this === void 0 ? void 0 : (_this$item = this.item) === null || _this$item === void 0 ? void 0 : (_this$item$_data = _this$item._data) === null || _this$item$_data === void 0 ? void 0 : _this$item$_data.isHidePriceWhenHasPassword) || false;
      const itemKey = this === null || this === void 0 ? void 0 : (_this$item2 = this.item) === null || _this$item2 === void 0 ? void 0 : (_this$item2$_data = _this$item2._data) === null || _this$item2$_data === void 0 ? void 0 : _this$item2$_data._key;

      if (isHidePriceWhenHasPassword && !isUnlocked) {
        return true;
      }

      return false;
    }),
    actions: {
      afterAddCart() {
        if (this.onAfterAddCart) {
          this.onAfterAddCart();
        }
      }

    },
    productsNameFontSize: (0, _object.computed)('shop.productsNameFontSize', function () {
      var _this$shop2, _this$shop2$_data;

      return (this === null || this === void 0 ? void 0 : (_this$shop2 = this.shop) === null || _this$shop2 === void 0 ? void 0 : (_this$shop2$_data = _this$shop2._data) === null || _this$shop2$_data === void 0 ? void 0 : _this$shop2$_data.productsNameFontSize) || 'small';
    }),
    productsNameLineCount: (0, _object.computed)('shop.productsNameLineCount', function () {
      var _this$shop3, _this$shop3$_data;

      return `store-item__card-name--lines-${(this === null || this === void 0 ? void 0 : (_this$shop3 = this.shop) === null || _this$shop3 === void 0 ? void 0 : (_this$shop3$_data = _this$shop3._data) === null || _this$shop3$_data === void 0 ? void 0 : _this$shop3$_data.productsNameLineCount) || 2}`;
    })
  });

  _exports.default = _default;
});
define("shop/pods/components/elements/message-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X70relsW",
    "block": "[[[41,[28,[37,1],[[33,2,[\"success\"]],[33,2,[\"error\"]],[33,2,[\"errorMessages\"]],[33,3],[33,4]],null],[[[1,\"  \"],[10,0],[14,0,\"checkout-form section\"],[12],[1,\"\\n\"],[6,[39,5],null,null,[[\"default\"],[[[[6,[39,6],null,null,[[\"default\"],[[[[6,[39,7],null,null,[[\"default\"],[[[[6,[39,8],null,[[\"classNames\"],[\"btns-group--right\"]],[[\"default\"],[[[[1,\"            \"],[11,3],[24,0,\"delete\"],[4,[38,9],[[30,0],\"close\"],null],[12],[13],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n\"],[41,[48,[30,1]],[[[6,[39,11],null,[[\"translate\",\"translateSuccess\",\"website\",\"errorMessage\",\"successMessage\"],[[33,12],[33,13],[33,2],[33,3],[33,4]]],[[\"default\"],[[[[1,\"            \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[]],[[[1,\"\\n          \"],[1,[28,[35,11],null,[[\"translate\",\"translateSuccess\",\"website\",\"errorMessage\",\"successMessage\"],[[33,12],[33,13],[33,2],[33,3],[33,4]]]]],[1,\"\\n\\n\"]],[]]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"or\",\"website\",\"errorMessage\",\"successMessage\",\"layouts/page-card\",\"layouts/page-card/card-content\",\"form-row\",\"layouts/btns-group\",\"action\",\"has-block\",\"elements/message-block/error-message\",\"translate\",\"translateSuccess\",\"yield\"]]",
    "moduleName": "shop/pods/components/elements/message-block/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
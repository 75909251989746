define("shop/pods/shop/route", ["exports", "@ember/routing/route", "shop/pods/shop/shop-route-mixin", "@ember/service", "ramda"], function (_exports, _route, _shopRouteMixin, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_shopRouteMixin.default, {
    flowsService: (0, _service.inject)('flows'),
    cookie: (0, _service.inject)(),
    websiteService: (0, _service.inject)('website'),
    seoService: (0, _service.inject)('seo'),
    shopService: (0, _service.inject)('shop'),
    query: null,
    tags: null,
    queryParams: {
      page: {
        refreshModel: true
      },
      count: {
        refreshModel: true
      },
      query: {
        refreshModel: true
      },
      flow: {
        refreshModel: false,
        as: 'shopFlow'
      },
      f: {
        refreshModel: false,
        as: 'shopF'
      },
      tags: {
        type: 'array',
        refreshModel: true
      },
      prices: {
        type: 'array',
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      reload: {
        refreshModel: true
      }
    },

    async model(params) {
      var _this$modelFor, _model$website, _model$website2;

      const isCampaign = ((_this$modelFor = this.modelFor('application')) === null || _this$modelFor === void 0 ? void 0 : _this$modelFor.isCampaign) || false;
      const model = await this._model(params, {
        fetchMember: true,
        isCampaign
      });
      const websiteName = model === null || model === void 0 ? void 0 : (_model$website = model.website) === null || _model$website === void 0 ? void 0 : _model$website.name;

      if (websiteName) {
        this.websiteService.updateTitle(websiteName);
      }

      this.websiteService.setFacebookClickId();
      model.shopService = this.shopService;
      const isAllowToCrawl = R.pathOr(true, ['shop', '_data', 'isAllowToCrawl'])(model);
      const hasLinkToWebsites = R.pathOr(true, ['shop', '_data', 'hasLinkToWebsites'])(model);
      const isBigOrdr = this.websiteService.get('isBigOrdr');

      if (!isAllowToCrawl || isBigOrdr && hasLinkToWebsites) {
        this.seoService.setBlockIndexing();
      }

      const shopStructuredData = {
        data: (model === null || model === void 0 ? void 0 : model.shop) || {},
        type: 'shop'
      };

      if (!this.websiteService.isBigOrdrShopRoute) {
        shopStructuredData.additionalData = {
          website: model === null || model === void 0 ? void 0 : model.website
        };
      }

      this.seoService.setupStructuredData({
        structuredData: [shopStructuredData, {
          data: (model === null || model === void 0 ? void 0 : model.shop) || {},
          type: 'faq'
        }],
        isNewStruct: true,
        wrapOnGraphKey: false
      });

      if (this.websiteService.isBigOrdr || model !== null && model !== void 0 && (_model$website2 = model.website) !== null && _model$website2 !== void 0 && _model$website2.allowAllCampaigns) {
        var _model$shop, _model$shop$_data;

        if (model !== null && model !== void 0 && (_model$shop = model.shop) !== null && _model$shop !== void 0 && (_model$shop$_data = _model$shop._data) !== null && _model$shop$_data !== void 0 && _model$shop$_data.name) {
          var _model$shop2, _model$shop2$_data, _model$shop3, _model$shop3$_data, _window, _window$location, _model$shop4, _model$shop4$topBanne;

          const shopName = model === null || model === void 0 ? void 0 : (_model$shop2 = model.shop) === null || _model$shop2 === void 0 ? void 0 : (_model$shop2$_data = _model$shop2._data) === null || _model$shop2$_data === void 0 ? void 0 : _model$shop2$_data.name;
          this.websiteService.updateTitle(shopName);
          const description = (model === null || model === void 0 ? void 0 : (_model$shop3 = model.shop) === null || _model$shop3 === void 0 ? void 0 : (_model$shop3$_data = _model$shop3._data) === null || _model$shop3$_data === void 0 ? void 0 : _model$shop3$_data.description) || '';
          const descriptionKeywordsOpenGraphData = {
            description,
            keywords: '',
            openGraphData: {
              type: 'website',
              title: shopName,
              description,
              url: (_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.href,
              site_name: shopName
            }
          };

          if (model !== null && model !== void 0 && (_model$shop4 = model.shop) !== null && _model$shop4 !== void 0 && (_model$shop4$topBanne = _model$shop4.topBanner) !== null && _model$shop4$topBanne !== void 0 && _model$shop4$topBanne.uuid) {
            descriptionKeywordsOpenGraphData.openGraphData.image = [`https://ucarecdn.com/${model.shop.topBanner.uuid}/-/format/webp/-/progressive/yes/`];
          }

          this.seoService.setMetaTags(descriptionKeywordsOpenGraphData);
        }
      }

      return model;
    },

    afterModel(model) {
      if (model) {
        var _model$params, _model$params2;

        this.flowsService.autoStartFlowTask.perform({
          flowName: (model === null || model === void 0 ? void 0 : (_model$params = model.params) === null || _model$params === void 0 ? void 0 : _model$params.flow) || '',
          flowKey: (model === null || model === void 0 ? void 0 : (_model$params2 = model.params) === null || _model$params2 === void 0 ? void 0 : _model$params2.f) || ''
        });
      }
    }

  });

  _exports.default = _default;
});
define("shop/helpers/includes", ["exports", "ramda", "@ember/component/helper"], function (_exports, R, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    compute(params) {
      const value = params[0] || '';
      const array = params[1] || [];
      return R.includes(value, array);
    }

  });

  _exports.default = _default;
});
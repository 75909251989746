define("shop/pods/members/index/route", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model(params) {
      const website = this.modelFor('application').website;
      const token = this.membersService.getToken(website);

      if (token) {
        return this.membersService.redirectToNextAfterLogin(website, false);
      }

      const isCampaign = this.modelFor('application').isCampaign;
      return _rsvp.default.hash({
        params,
        website,
        isCampaign
      });
    }

  });

  _exports.default = _default;
});
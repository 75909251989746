define("shop/pods/components/helpers/horizontal-scroll/component", ["exports", "@ember/component", "jquery"], function (_exports, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'nav',
    classNames: 'level',
    defaultImgWidth: 470,

    didRender() {
      this._super(...arguments);

      const items = (0, _jquery.default)(this.element).find('.scroll-item');
      let totalWidth = 0;
      const minItems = items.length || 1;
      const defaultWidth = minItems * this.defaultImgWidth;
      items.each((index, item) => {
        const outerWidth = parseInt((0, _jquery.default)(this.element).find(item).outerWidth());
        totalWidth = totalWidth + outerWidth;
      });
      totalWidth = totalWidth || defaultWidth;
      totalWidth = totalWidth + Math.round(totalWidth * 0.02);
      (0, _jquery.default)(this.element).find('.scroll-content').width(totalWidth);
    }

  });

  _exports.default = _default;
});
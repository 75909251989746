define("shop/pods/members/reward-history/route", ["exports", "@ember/routing/route", "rsvp", "ramda"], function (_exports, _route, _rsvp, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    async model(params) {
      const host = this.modelFor('application').host;
      const website = this.modelFor('application').website;
      const shop = this.modelFor('application').shop;
      const isCampaign = this.modelFor('application').isCampaign;
      const token = this.membersService.getToken(website);

      if (!token) {
        return this.transitionTo('members');
      }

      const memberModel = await this.membersService.getStoredMemberModel({
        website
      });
      const isEmpty = R.pipe(R.propOr({}, '_data'), R.isEmpty)(memberModel);

      if (isEmpty) {
        return this.router.transitionTo('members');
      }

      const rewardsList = await this.membersService.get('getRewardsListTask').perform({
        website
      });

      try {
        return _rsvp.default.hash({
          host,
          params,
          shop,
          website,
          isCampaign,
          member: memberModel,
          rewardsList
        });
      } catch (e) {
        this.transitionTo('members');
      }
    },

    redirect(model) {
      if (!model) {
        this.transitionTo('/');
      }
    }

  });

  _exports.default = _default;
});
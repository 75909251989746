define("shop/pods/components/links-slider/component", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "ramda", "@ember/object/internals", "ember-concurrency-decorators"], function (_exports, _component, _object, _service, R, _internals, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LinksSliderComponent = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('header'), (_class = class LinksSliderComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);

      _initializerDefineProperty(this, "headerService", _descriptor2, this);

      _defineProperty(this, "componentId", (0, _internals.guidFor)(this));
    }

    get mobileOnly() {
      return R.pathOr(true, ['args', 'mobileOnly'])(this);
    }

    get classNamesWhenFaqs() {
      var _this$websiteService, _this$websiteService2, _this$websiteService3, _this$websiteService4;

      const isMobileClassName = (_this$websiteService = this.websiteService) !== null && _this$websiteService !== void 0 && _this$websiteService.isMobile ? 'links-slider__isMobile' : '';
      return (_this$websiteService2 = this.websiteService) !== null && _this$websiteService2 !== void 0 && (_this$websiteService3 = _this$websiteService2.website) !== null && _this$websiteService3 !== void 0 && (_this$websiteService4 = _this$websiteService3.shop) !== null && _this$websiteService4 !== void 0 && _this$websiteService4.hasFaqs ? `links-slider__has-faqs ${isMobileClassName}` : `links-slider__no-faqs ${isMobileClassName}`;
    }

    get linkSliderHeight() {
      var _this$websiteService5, _website$shop;

      let topAdjustment = this.headerService.navBarHeightPlusTimer;
      const website = (_this$websiteService5 = this.websiteService) === null || _this$websiteService5 === void 0 ? void 0 : _this$websiteService5.website; // Handle when has faqs

      if (website !== null && website !== void 0 && (_website$shop = website.shop) !== null && _website$shop !== void 0 && _website$shop.hasFaqs && website !== null && website !== void 0 && website.showShopHero) {
        topAdjustment = this.headerService.navBarHeightPlusTimerPlusTabsOnTopContainer;
      }

      return topAdjustment;
    }

    get linkSliderStyle() {
      return `top: ${this.linkSliderHeight}px; z-index: 8;`;
    }

    onToggle(link, event) {
      const onToggle = R.pathOr(null, ['args', 'onToggle'])(this);

      if (onToggle) {
        return onToggle(link);
      }

      const scrollBy = this.websiteService.isShopIndexRoute ? 150 : 100;
      event.preventDefault();
      const offsetTop = document.querySelector(link.href).offsetTop;
      scroll({
        top: offsetTop - scrollBy,
        behavior: 'smooth'
      });
    }

    *setupTopInnerStickyTabs(navBarHeight) {
      var _thisComponent$getBou;

      const thisComponent = document.querySelector(`.${this.componentId}`);
      const thisComponentBoundingClientRectHeight = (thisComponent === null || thisComponent === void 0 ? void 0 : (_thisComponent$getBou = thisComponent.getBoundingClientRect()) === null || _thisComponent$getBou === void 0 ? void 0 : _thisComponent$getBou.height) || 0;
      const allHeaderTabsNewTop = navBarHeight;
      const allHeaderTabs = Array.from(document.querySelectorAll('.bigordr-tab-container__sticky-tabs')) || [];
      R.forEach(tab => {
        tab.style.top = `${allHeaderTabsNewTop + thisComponentBoundingClientRectHeight - 1}px`;
      })(allHeaderTabs);
      return null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "headerService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onToggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onToggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupTopInnerStickyTabs", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "setupTopInnerStickyTabs"), _class.prototype)), _class));
  _exports.default = LinksSliderComponent;
});
define("shop/pods/store-location/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+dzYNC+1",
    "block": "[[[8,[39,0],null,[[\"@page\",\"@shop\",\"@website\"],[[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@website\",\"@params\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"params\"]]]],[[\"default\"],[[[[1,\"\\n\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n\\n      \"],[8,[39,3],null,[[\"@hasSearchBar\",\"@model\",\"@onUpdateFilter\",\"@pageComponent\",\"@updateUrlParams\",\"@hasTagsFilters\",\"@hasCategoriesFilters\",\"@hasAdvancedFilters\"],[true,[30,0,[\"model\"]],[28,[37,4],[[30,2,[\"fetchTask\"]]],null],[30,2],true,[30,0,[\"model\",\"website\",\"_data\",\"isShowStoreLocationsTags\"]],false,false]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@storeLocations\",\"@filtersComponent\",\"@pageComponent\",\"@website\"],[[30,2,[\"filterModel\",\"results\"]],[30,3],[30,2],[30,0,[\"model\",\"website\"]]]],null],[1,\"\\n      \"]],[3]]]]],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n\\n  \"]],[2]]]]],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\"]],[\"websiteContainer\",\"pageComponent\",\"filtersComponent\"],false,[\"website/website-container\",\"store-location/content\",\"layouts/page-content\",\"filters\",\"perform\",\"store-location/group\"]]",
    "moduleName": "shop/pods/store-location/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
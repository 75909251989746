// /* global store */
// /* global R */
// /**
//  * stores to localstorage using the store.js library.
//  * will use other methods in localstorage is not available
//  */
// const expiry = 604800000
// const self = {
//   _key (shopKey, key) {
//     return `${key}${shopKey}`
//   },
//   setValue: R.curry((shopKey, key, val) => {
//     key = self._key(shopKey, key)
//     store.set(key, {
//       val: val,
//       exp: expiry,
//       time: new Date().getTime()
//     })
//     return val
//   }),
//   setValueWithCustomExpiry: R.curry((shopKey, exp, key, val) => {
//     key = self._key(shopKey, key)
//     store.set(key, {
//       val: val,
//       exp: exp,
//       time: new Date().getTime()
//     })
//   }),
//   getValue: (shopKey, key) => {
//     key = self._key(shopKey, key)
//     const info = store.get(key)
//     if (!info) {
//       return null
//     }
//     if (new Date().getTime() - info.time > info.exp) {
//       // localstorage item has expired, remove and return null
//       store.remove(key)
//       return null
//     }
//     return info.val
//   },
//   remove: (shopKey, key) => {
//     key = self._key(shopKey, key)
//     store.remove(key)
//   },
//   clearAll: () => {
//     store.clearAll()
//   }
// }
// export default self
define("shop/pods/storage/utils", [], function () {
  "use strict";
});
define("shop/pods/members/order-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WkS1sxsJ",
    "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null]],[],false,[\"members/orders-history\"]]",
    "moduleName": "shop/pods/members/order-history/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/flows/games/scratch-card/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-concurrency-decorators", "@ember/string", "shop/utils/nventor", "ramda"], function (_exports, _component, _object, _tracking, _service, _emberConcurrencyDecorators, _string, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FlowsGamesScratchCardComponent = (_dec = (0, _service.inject)('flows'), (_class = class FlowsGamesScratchCardComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "flowsService", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "hasWon", _descriptor3, this);

      _initializerDefineProperty(this, "response", _descriptor4, this);

      _initializerDefineProperty(this, "prizeData", _descriptor5, this);

      _initializerDefineProperty(this, "messages", _descriptor6, this);

      _initializerDefineProperty(this, "numberOfPlaysRemaining", _descriptor7, this);

      _initializerDefineProperty(this, "isRunning", _descriptor8, this);

      _initializerDefineProperty(this, "coverImageUrl", _descriptor9, this);

      _initializerDefineProperty(this, "coverText", _descriptor10, this);

      _initializerDefineProperty(this, "coverTextColor", _descriptor11, this);

      _initializerDefineProperty(this, "coverBgColor", _descriptor12, this);

      _initializerDefineProperty(this, "backgroundImageUrl", _descriptor13, this);

      _initializerDefineProperty(this, "backgroundText", _descriptor14, this);

      _initializerDefineProperty(this, "backgroundTextColor", _descriptor15, this);

      _initializerDefineProperty(this, "backgroundBgColor", _descriptor16, this);

      _defineProperty(this, "brush", new Image());

      _defineProperty(this, "width", 400);

      _defineProperty(this, "height", 300);

      const numberOfPlays = R.pathOr(0, ['step', 'actionData', 'numberOfPlays'])(this.args);
      this.numberOfPlaysRemaining = parseInt(numberOfPlays) || 0;
    }

    get hasMorePlays() {
      if (this.numberOfPlaysRemaining <= 0) {
        return false;
      }

      return true;
    }

    setupCoverImageUrl(step) {
      let scratchCardImageUuid;

      if (step.scratchCardBackgroundType === 'backgroundImage') {
        scratchCardImageUuid = R.pipe(R.pathOr([], ['images']), R.find(R.propEq('resource', 'flows-scratchCardImage')), R.prop('uuid'))(step);
      }

      let coverImageUrl = '';

      if (scratchCardImageUuid) {
        coverImageUrl = `https://ucarecdn.com/${scratchCardImageUuid}/-/scale_crop/${this.width}x${this.height}/center/-/format/jpeg/`;
      }

      this.coverImageUrl = coverImageUrl;
    }

    setupCoverText(step) {
      this.coverText = (0, _string.htmlSafe)(R.pathOr('', ['text'])(step));
    }

    setupCoverTextColor(step) {
      this.coverTextColor = R.pathOr('#fff', ['scratchCardTextColor'])(step);
    }

    setupCoverBgColor(step) {
      let coverBgColor = '#000';

      if (step.scratchCardBackgroundType === 'backgroundColor' || !this.coverImageUrl) {
        coverBgColor = step.scratchCardBackgroundColor || coverBgColor;
      }

      this.coverBgColor = coverBgColor;
    }

    *setupPrize(step) {
      const prizeData = yield this.startTask.perform();
      let prizeUuid;

      if (prizeData.hasWon) {
        prizeUuid = R.pipe(R.pathOr([], ['images']), R.find(R.propEq('resourceKey', prizeData.subStepId)), R.prop('uuid'))(step);
      } else {
        prizeUuid = R.pipe(R.pathOr([], ['images']), R.find(R.propEq('resource', 'flows-scratchCardImageLose')), R.prop('uuid'))(step);
      }

      if (prizeUuid) {
        prizeData.backgroundImageUrl = `https://ucarecdn.com/${prizeUuid}/-/scale_crop/${this.width}x${this.height}/center/-/format/jpeg/`;
      }

      this.prizeData = prizeData;
      this.backgroundImageUrl = R.pathOr('', ['backgroundImageUrl'])(prizeData);
      this.backgroundText = prizeData.successfulTitle;
    }

    *setupTask() {
      const step = this.args.step || {};
      this.setupCoverImageUrl(step);
      this.setupCoverBgColor(step);
      this.setupCoverText(step);
      this.setupCoverTextColor(step);
      yield this.setupPrize.perform(step);
      this.isRunning = false;
    }

    *startTask() {
      if (this.hasMorePlays) {
        return yield this._startTask.perform();
      } else if (!this.hasMorePlays && this.args.close) {
        this.closeDialog();
      }
    }

    closeDialog() {
      this.args.close();
    }

    *_startTask() {
      if (!this.hasMorePlays) {
        return false;
      }

      if (this.flowsService.triggerActionTask.isRunning) {
        return false;
      }

      const step = this.args.step;
      const response = yield this.flowsService.triggerActionTask.perform({
        step,
        goToNextGroup: false
      });
      this.response = response;
      const hasWon = R.pathOr(false, ['responseData', 'gameResult', 'hasWon'])(response);
      const messages = R.pathOr(false, ['responseSteps'])(response);
      const abortFlow = R.path(['responseData', 'abortFlow'])(response);

      if (abortFlow) {
        this.pushMessages(hasWon, messages, true);
        this.closeDialog();
        return false;
      }

      const numberOfPlaysRemaining = R.pathOr(0, ['responseData', 'numberOfPlaysRemaining'])(response);
      this.numberOfPlaysRemaining = numberOfPlaysRemaining;
      this.hasWon = hasWon;
      this.pushMessages(hasWon, messages, false);
      const prizeData = R.pathOr({}, ['responseData', 'gameResult'])(response);

      if (hasWon) {
        return prizeData;
      } else {
        const unsuccessfulMessage = R.pathOr('', ['responseData', 'gameResult', 'unsuccessfulMessage'])(response);
        prizeData.successfulTitle = unsuccessfulMessage || this.intl.t('game_scratch_card_sorry');
        return prizeData;
      }
    }

    pushMessages(hasWon, messages) {
      R.forEach(message => {
        this._pushMessage(hasWon, message);
      })(messages);
    }

    _pushMessage(hasWon, message) {
      const messages = this.messages || [];
      message.hasWon = hasWon;
      messages.pushObject(message);
      this.messages = messages;
    }

    get lastMessage() {
      return R.pipe(R.propOr([], 'messages'), R.reject(R.propEq('isPrizeMessage', true)), _nventor.default.safeLast)(this);
    }
    /**
     * Called by handlePercentage
     * @returns {Object} celebrate (bool)
     */


    showGameResults() {
      if (this.hasWon) {
        return {
          celebrate: true
        };
      }

      return {
        celebrate: false
      };
    }

    willDestroyElement() {
      if (!this.isDestroyed) {
        const step = this.args.step;
        const messages = this.messages || [];
        const response = this.response || {};
        return this.flowsService.completeModalTask.perform({
          messages,
          step,
          response
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flowsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasWon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "response", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "prizeData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "messages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "numberOfPlaysRemaining", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isRunning", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "coverImageUrl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "coverText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "coverTextColor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "coverBgColor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "backgroundImageUrl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "backgroundText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "backgroundTextColor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "backgroundBgColor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupPrize", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupPrize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "startTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_startTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_startTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pushMessages", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "pushMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_pushMessage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_pushMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showGameResults", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showGameResults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyElement"), _class.prototype)), _class));
  _exports.default = FlowsGamesScratchCardComponent;
});
define("shop/pods/components/elements/message-block/error-message/component", ["exports", "@ember/component", "@ember/service", "@ember/object", "shop/utils/nventor"], function (_exports, _component, _service, _object, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    translate: true,
    timesDisplayed: 0,

    didInsertElement() {
      this._super(...arguments);

      const currentRoute = this.router.get('currentRouteName');
      const website = this.website;

      if (website) {
        if (!website.get('messageInitRoute')) {
          website.set('messageInitRoute', currentRoute);
        }
      }
    },

    hasAlertMessage: (0, _object.computed)('errorMessage', 'successMessage.[]', 'website.{error,errorMessages.[],success.[]}', function () {
      const website = this.website || {};
      const websiteError = website.error || '';
      const errorMessages = website.errorMessages || [];
      const success = website.success || [];
      const errorMessage = this.errorMessage || '';
      const successMessage = this.successMessage || [];

      const hasAnyAlertMessage = websiteError || errorMessage || _nventor.default.isNotNilOrEmpty(successMessage) || _nventor.default.isNotNilOrEmpty(errorMessages) || _nventor.default.isNotNilOrEmpty(success);

      if (hasAnyAlertMessage) {
        return true;
      }

      return false;
    }),

    removeAlertMessage(prop) {
      (0, _object.set)(this, prop, '');
    } // didRender () {
    //   this._super(...arguments)
    //   const website = this.website
    //   const currentRoute = this.router.get('currentRouteName')
    //   if (currentRoute !== website.get('messageInitRoute')) {
    //     const messageDisplayCount = website.get('messageDisplayCount') || 0
    //     if (messageDisplayCount > 0) {
    //       // this.get('onClear')()
    //       this.websiteService.clearMessages()
    //     } else {
    //       website.set('messageDisplayCount', messageDisplayCount + 1)
    //     }
    //   } else {
    //     website.set('messageDisplayCount', 1)
    //   }
    // }


  }, (_applyDecoratedDescriptor(_obj, "removeAlertMessage", [_object.action], Object.getOwnPropertyDescriptor(_obj, "removeAlertMessage"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/components/elements/drawer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sUxfHxBU",
    "block": "[[[41,[30,0,[\"websiteService\",\"isMobile\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@drawerBackgroundClass\",\"@hasOverlay\",\"@closeOnOverlayClick\",\"@modifiedRoute\",\"@isReturnToOriginalUrl\",\"@onClose\"],[[30,1],[30,2],true,[30,3],[30,4],[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@drawerBackgroundClass\",\"@hasOverlay\",\"@closeOnOverlayClick\",\"@modifiedRoute\",\"@isReturnToOriginalUrl\",\"@onClose\"],[[30,1],[30,2],true,[30,3],[30,4],[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"@drawerBackgroundClass\",\"@hasOverlay\",\"@modifiedRoute\",\"@isReturnToOriginalUrl\",\"&default\"],false,[\"if\",\"elements/drawer/drawer-mobile\",\"yield\",\"elements/drawer/drawer-desktop\"]]",
    "moduleName": "shop/pods/components/elements/drawer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
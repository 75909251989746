define("shop/pods/components/layouts/page-steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q2DFhONE",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"    \"],[1,[28,[35,3],null,[[\"steps\",\"index\",\"step\",\"translate\",\"currentStep\"],[[33,2],[30,2],[30,1],[33,4],[33,5]]]]],[1,\"\\n\"]],[1,2]],null]],[\"step\",\"index\"],false,[\"each\",\"-track-array\",\"steps\",\"layouts/page-steps/step-display\",\"translate\",\"currentStep\"]]",
    "moduleName": "shop/pods/components/layouts/page-steps/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
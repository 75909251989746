define("shop/pods/website/model", ["exports", "shop/utils/nventor", "@ember/service", "ramda", "@ember/object", "@ember/application", "shop/pods/menu/model", "shop/config/environment"], function (_exports, _nventor, _service, R, _object, _application, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultParams = {
    query: null,
    tags: [],
    sortBy: null,
    page: null,
    count: null
  };

  var _default = _object.default.extend({
    intl: (0, _service.inject)(),
    storage: (0, _service.inject)(),
    membersService: (0, _service.inject)('members'),
    websiteService: (0, _service.inject)('website'),
    translateErrors: true,
    success: '',
    uploadcareUrl: 'https://ucarecdn.com',

    populate(data) {
      var _data, _data4, _data5;

      if ((_data = data) !== null && _data !== void 0 && _data.shop) {
        var _data2, _data2$shop;

        const applicationInstance = (0, _application.getOwner)(this);

        if (_nventor.default.isNilOrEmpty((_data2 = data) === null || _data2 === void 0 ? void 0 : (_data2$shop = _data2.shop) === null || _data2$shop === void 0 ? void 0 : _data2$shop._data)) {
          var _data3;

          const shopModel = applicationInstance.lookup('shop:main');
          shopModel.populate((_data3 = data) === null || _data3 === void 0 ? void 0 : _data3.shop);
          data.shop = shopModel;
        }
      }

      if ((_data4 = data) !== null && _data4 !== void 0 && _data4.pages && (_data5 = data) !== null && _data5 !== void 0 && _data5.pages.length) {
        data.pages = this._transformCategoryProductTags(data);
      }

      data.isShowBlogsTags = this._isShowTags({
        data,
        isShowTagsProp: 'isShowBlogsTags',
        tagsProp: 'blogTags'
      });
      data.isShowNewsTags = this._isShowTags({
        data,
        isShowTagsProp: 'isShowNewsTags',
        tagsProp: 'newsTags'
      });
      data.isShowStoreLocationsTags = this._isShowTags({
        data,
        isShowTagsProp: 'isShowStoreLocationsTags',
        tagsProp: 'storeLocationTags'
      });
      data = {
        _data: data
      };
      this.setProperties(data); // console.log('_pages>>>')
      // console.log(this._data.pages)
      // const pages = this._getPageModels()
      // console.log('pages>>>')
      // console.log(pages)
      // this.set('menu', this._menu('showOnMenu', pages))
      // this.set('footerMenu', this._menu('showOnFooter', pages))
    },

    _isShowTags({
      data,
      isShowTagsProp,
      tagsProp
    }) {
      if (!isShowTagsProp || !tagsProp) {
        return false;
      }

      if (_nventor.default.isNilOrEmpty(data[tagsProp])) {
        return false;
      }

      return R.propOr(true, isShowTagsProp)(data);
    },

    showLoginBtn: (0, _object.computed)('_data.{hideFacebookLogin,hideLineLogin,hideBigOrdrLogin}', function () {
      const data = this._data;

      if (data !== null && data !== void 0 && data.hideFacebookLogin && data !== null && data !== void 0 && data.hideLineLogin && data !== null && data !== void 0 && data.hideBigOrdrLogin) {
        return false;
      }

      return true;
    }),
    googleAnalyticsId: (0, _object.computed)('_data.googleAnalyticsId', function () {
      return this.get('_data.googleAnalyticsId') || '';
    }),
    googleAnalyticsUrl: (0, _object.computed)('googleAnalyticsId', function () {
      const id = this.googleAnalyticsId;
      return `https://www.googletagmanager.com/gtag/js?id=${id}`;
    }),
    googleAnalyticsScript: (0, _object.computed)('googleAnalyticsId', 'googleAnalyticsUrl', function () {
      if (!this.googleAnalyticsId) {
        return '';
      }

      return `
      <script async src='${this.googleAnalyticsUrl}'></script>

      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${this.googleAnalyticsId}');
      </script>
    `;
    }),
    blogsImagesRatio: (0, _object.computed)('_data.blogsImagesRatio', function () {
      return this.get('_data.blogsImagesRatio') || 'fourByThree';
    }),
    blogsTitleLineCount: (0, _object.computed)('_data.blogsLineCountForTitle', function () {
      return this.get('_data.blogsLineCountForTitle') || '2';
    }),
    blogsDescriptionLineCount: (0, _object.computed)('_data.blogsLineCountForDescription', function () {
      return this.get('_data.blogsLineCountForDescription') || '3';
    }),
    newsImagesRatio: (0, _object.computed)('_data.newsImagesRatio', function () {
      return this.get('_data.newsImagesRatio') || 'fourByThree';
    }),
    podKey: (0, _object.computed)('_data.{podKey,shop._data.podKey}', function () {
      return this.get('_data.podKey') || this.get('_data.shop._data.podKey');
    }),
    hasWebsite: (0, _object.computed)('_data.name', function () {
      return this.get('_data.name');
    }),
    shop: (0, _object.computed)('_data.shop', function () {
      return this.get('_data.shop');
    }),
    shopUrl: (0, _object.computed)('shop._data.url', '_data.shop.url', function () {
      // this can be used for using ajax requests
      return this.get('shop._data.url') || this.get('_data.shop.url');
    }),
    shopUrlDisplay: (0, _object.computed)('_data.shopUrlDisplay', 'shop._data.url', '_data.shop.url', function () {
      var _this$_data;

      // this CANNOT be used for using ajax requests.
      // only for displaying in browser url
      if (this !== null && this !== void 0 && (_this$_data = this._data) !== null && _this$_data !== void 0 && _this$_data.shopUrlDisplay) {
        var _this$_data2;

        // this value is only for website shops, which will always be 'products'
        return this === null || this === void 0 ? void 0 : (_this$_data2 = this._data) === null || _this$_data2 === void 0 ? void 0 : _this$_data2.shopUrlDisplay;
      }

      return this.get('shop._data.url') || this.get('_data.shop.url');
    }),
    shopKey: (0, _object.computed)('_data.{shopKey,shop.shopKey}', 'shop._data._key', function () {
      const shopKey = this.get('_data.shopKey') || this.get('_data.shop.shopKey');
      return shopKey || this.get('shop._data._key');
    }),
    hideBigOrdrFromWebsites: (0, _object.computed)('_data.{hideBigOrdrFromWebsites,hideBigOrdr}', function () {
      var _this$_data3, _this$_data4;

      if (this !== null && this !== void 0 && (_this$_data3 = this._data) !== null && _this$_data3 !== void 0 && _this$_data3.hideBigOrdr) {
        return true;
      }

      if (this !== null && this !== void 0 && (_this$_data4 = this._data) !== null && _this$_data4 !== void 0 && _this$_data4.hideBigOrdrFromWebsites) {
        return true;
      }

      return false;
    }),
    flows: (0, _object.computed)('shop._data.flows.@each.flowKey', function () {
      return this.get('shop._data.flows') || [];
    }),
    checkoutAfterFlows: (0, _object.computed)('shop._data.flows.@each.flowKey', function () {
      const flows = this.get('shop._data.flows') || [];
      return R.filter(R.propEq('eventType', 'checkoutAfter'))(flows);
    }),
    pageViewFlows: (0, _object.computed)('shop._data.flows.@each.flowKey', function () {
      const flows = this.get('shop._data.flows') || [];
      return R.filter(R.propEq('eventType', 'view'))(flows);
    }),
    // signupFlows: computed('shop._data.flows.@each.flowKey', function () {
    //   const flows = this.get('shop._data.flows') || []
    //   const memberId = this.get('user._data.memberId') || ''
    //   const emailVerified = this.get('user._data.emailVerified') || ''
    //   if (memberId && emailVerified) {
    //     return R.filter(R.propEq('eventType', 'signup'))(flows)
    //   }
    //   return []
    // }),
    scrollingNotificationFlows: (0, _object.computed)('shop._data.flows.@each.flowKey', function () {
      const flows = this.get('shop._data.flows') || [];
      return R.filter(R.propEq('type', 'scrollingNotification'))(flows);
    }),
    formFlows: (0, _object.computed)('shop._data.flows.@each.flowKey', function () {
      const flows = this.get('shop._data.flows') || [];
      return R.filter(R.propEq('type', 'form'))(flows);
    }),
    websiteKey: (0, _object.computed)('_data.{_key,shop._data._key}', function () {
      return this.get('_data._key');
    }),
    facebookPage: (0, _object.computed)('_data.{facebookPage,shop._data.facebookPage}', function () {
      return this.get('_data.facebookPage') || this.get('_data.shop._data.facebookPage') || this.get('_data.shop.facebookPage');
    }),
    facebookPluginEnable: (0, _object.computed)('_data.{facebookPluginEnable,shop._data.facebookPluginEnable}', function () {
      return this.get('_data.facebookPluginEnable') || this.get('_data.shop._data.facebookPluginEnable') || this.get('_data.shop.facebookPluginEnable');
    }),
    shopFacebookPluginEnable: (0, _object.computed)('_data.shop._data.facebookPluginEnable', function () {
      return this.get('_data.shop._data.facebookPluginEnable') || this.get('_data.shop.facebookPluginEnable');
    }),
    websiteFacebookPluginEnable: (0, _object.computed)('_data.facebookPluginEnable', function () {
      const url = this.get('_data.facebookPluginEnable');
      return this._convertToLink(url);
    }),
    facebookUrl: (0, _object.computed)('_data.{facebookUrl,shop._data.facebookUrl}', function () {
      const url = this.get('_data.facebookUrl') || this.get('_data.shop._data.facebookUrl') || this.get('_data.shop.facebookUrl');
      return this._convertToLink(url);
    }),
    instagramUrl: (0, _object.computed)('_data.{instagramUrl,shop._data.instagramUrl}', function () {
      const url = this.get('_data.instagramUrl') || this.get('_data.shop._data.instagramUrl') || this.get('_data.shop.instagramUrl');
      return this._convertToLink(url);
    }),
    youtubeUrl: (0, _object.computed)('_data.{youtubeUrl,shop._data.youtubeUrl}', function () {
      const url = this.get('_data.youtubeUrl') || this.get('_data.shop._data.youtubeUrl') || this.get('_data.shop.youtubeUrl');
      return this._convertToLink(url);
    }),

    _convertToLink(url = '') {
      if (url === '') {
        return false;
      }

      if (R.includes('http', url)) {
        return decodeURIComponent(url);
      }

      url = decodeURIComponent(url);
      return `https://${url}`;
    },

    _addLineFriendUrl(id = '') {
      if (id === '') {
        return false;
      }

      if (R.includes('http', id)) {
        return id;
      }

      return `https://line.me/R/ti/p/${id}`;
    },

    lineId: (0, _object.computed)('_data.{lineId,shop._data.lineId}', function () {
      const lineId = this.get('_data.lineId') || this.get('_data.shop._data.lineId') || this.get('_data.shop.lineId');
      return this._addLineFriendUrl(lineId);
    }),
    hasLineChatEnabled: (0, _object.computed)('lineId', '_data.{lineChatEnable,shop._data.lineChatEnable}', function () {
      const lineId = this.lineId;

      if (!lineId) {
        return false;
      }

      const enabled = this.get('_data.lineChatEnable') || this.get('_data.shop._data.lineChatEnable') || this.get('_data.shop.lineChatEnable');

      if (enabled) {
        return lineId;
      }

      return false;
    }),

    setupShop(shop) {
      this.set('_data.shop', shop);
      this.websiteService.addTrackersForShop(shop);
    },

    hasCartItems: (0, _object.computed)('shop.cartItems.@each._key', function () {
      const items = this.get('shop.cartItems') || [];

      if (items.length > 0) {
        return items;
      }

      return false;
    }),
    hasLogoUrl: (0, _object.computed)('_data.logo', function () {
      const customLogo = this.get('_data.logo');

      if (customLogo) {
        return `${this.uploadcareUrl}/${customLogo}`;
      }

      return false;
    }),
    hasLogoMobileUrl: (0, _object.computed)('_data.logo', '_data.logoMobile', function () {
      const defaultLogo = this.get('_data.logo');
      const customLogo = this.get('_data.logoMobile') || defaultLogo;

      if (customLogo) {
        return `${this.uploadcareUrl}/${customLogo}`;
      }

      return false;
    }),
    hasFavicon: (0, _object.computed)('_data.favicon', function () {
      const customFavicon = this.get('_data.favicon');

      if (customFavicon) {
        return `${this.uploadcareUrl}/${customFavicon}`;
      }

      return this.hasLogoUrl;
    }),
    companyName: (0, _object.computed)('_data.{companyName,shop._data.companyName,useDefaultsContactInfo}', function () {
      const useDefaultsContactInfo = this.get('_data.useDefaultsContactInfo');

      if (useDefaultsContactInfo) {
        return this.get('_data.shop._data.companyName') || this.get('_data.shop.companyName') || this.get('_data.companyName');
      } else {
        return this.get('_data.companyName') || this.get('_data.shop._data.companyName') || this.get('_data.shop.companyName');
      }
    }),
    companyTel: (0, _object.computed)('_data.{companyTel,shop._data.companyTel}', function () {
      const useDefaultsContactInfo = this.get('_data.useDefaultsContactInfo');

      if (useDefaultsContactInfo) {
        return this.get('_data.shop._data.companyTel') || this.get('_data.shop.companyTel') || this.get('_data.companyTel');
      } else {
        return this.get('_data.companyTel');
      }
    }),
    companyAddress: (0, _object.computed)('_data.{companyAddress}', function () {
      return this.get('_data.companyAddress');
    }),
    companyAddressLink: (0, _object.computed)('_data.{companyAddress}', function () {
      return R.pipe(R.pathOr('', ['_data', 'companyAddress']), R.replace(/\s/gm, '+'))(this);
    }),
    emailFromAddress: (0, _object.computed)('_data.{emailFromAddress,shop._data.emailFromAddress}', function () {
      const useDefaultsContactInfo = this.get('_data.useDefaultsContactInfo');

      if (useDefaultsContactInfo) {
        return this.get('_data.shop._data.emailFromAddress') || this.get('_data.shop.emailFromAddress');
      } else {
        return this.get('_data.emailFromAddress');
      }
    }),
    name: (0, _object.computed)('_data.name', function () {
      return this.get('_data.name') || 'BigOrdr';
    }),
    menuStyle: (0, _object.computed)('_data.menuStyle', function () {
      return this.get('_data.menuStyle') || 'horizontal';
    }),
    menuStyleIsHorizontal: (0, _object.computed)('menuStyle', function () {
      if (this.menuStyle === 'horizontal') {
        return true;
      }

      return false;
    }),
    menuExpandBehavior: (0, _object.computed)('_data.menuExpandBehavior', function () {
      var _this$_data5;

      return (this === null || this === void 0 ? void 0 : (_this$_data5 = this._data) === null || _this$_data5 === void 0 ? void 0 : _this$_data5.menuExpandBehavior) || 'autoHide';
    }),
    menuExpandAlignment: (0, _object.computed)('_data.menuExpandAlignment', function () {
      var _this$_data6;

      return (this === null || this === void 0 ? void 0 : (_this$_data6 = this._data) === null || _this$_data6 === void 0 ? void 0 : _this$_data6.menuExpandAlignment) || 'left';
    }),
    hasReward: (0, _object.computed)('shop.rewardData.name', 'shop._data.rewardData.name', function () {
      const rewardName = this.get('shop.rewardData.name') || this.get('shop._data.rewardData.name') || '';

      if (R.isNil(rewardName) || R.isEmpty(rewardName)) {
        return false;
      }

      return true;
    }),
    isAllowSpendReward: (0, _object.computed)('shop._data.isAllowSpendReward', function () {
      if (!this.hasReward) {
        return false;
      }

      const isAllowSpendReward = this.get('shop._data.isAllowSpendReward');

      if (R.isNil(isAllowSpendReward) || R.isEmpty(isAllowSpendReward)) {
        return true;
      }

      return isAllowSpendReward;
    }),
    rewardName: (0, _object.computed)('shop.rewardData.name', 'shop._data.rewardData.name', function () {
      return this.get('shop.rewardData.name') || this.get('shop._data.rewardData.name') || '';
    }),
    rewardData: (0, _object.computed)('shop.rewardData', 'shop._data.rewardData', function () {
      return this.get('shop.rewardData') || this.get('shop._data.rewardData') || '';
    }),
    menuPosition: (0, _object.computed)('_data.menuPosition', function () {
      return this.get('_data.menuPosition') || 'right';
    }),
    menuExpandSubMenus: (0, _object.computed)('_data.menuExpandSubMenus', function () {
      return this.get('_data.menuExpandSubMenus') || false;
    }),
    menuIsAlwaysHidden: (0, _object.computed)('_data.menuIsAlwaysHidden', function () {
      return this.get('_data.menuIsAlwaysHidden') || false;
    }),
    hasShowMenuLogo: (0, _object.computed)('_data.menuShowLogo', function () {
      return R.pathOr(true, ['_data', 'menuShowLogo'])(this);
    }),
    hasShowMenuName: (0, _object.computed)('_data.menuShowName', function () {
      return R.pathOr(true, ['_data', 'menuShowName'])(this);
    }),
    isLogoPositionClassNames: (0, _object.computed)('_data.logoPosition', function () {
      const logoPosition = R.pathOr('top', ['_data', 'logoPosition'])(this);

      if (logoPosition === 'top') {
        return 'flex-container-column';
      }

      return 'flex-container-column-reverse';
    }),
    logoUrl: (0, _object.computed)('hasLogoUrl', '_data.menuShowLogoSize', '_data.menuShowLogoMobileSize', '_data.menuShowLogoDesktopOnlySize', '_data.logoMenuPosition', 'websiteService.isMobile', function () {
      let hasLogoUrl = this.hasLogoUrl;
      const menuShowLogoSize = this._data.menuShowLogoSize || '20px';
      let size = R.replace('px', '')(menuShowLogoSize) || '20px';

      if (hasLogoUrl) {
        const isMobile = this.websiteService.isMobile;
        const logoMenuPosition = this._data.logoMenuPosition;

        if (!isMobile && logoMenuPosition === 'separateLine') {
          const menuShowLogoDesktopOnlySize = this._data.menuShowLogoDesktopOnlySize || menuShowLogoSize || '20px';
          size = R.replace('px', '')(menuShowLogoDesktopOnlySize) || '20px';
        }

        if (isMobile) {
          hasLogoUrl = this.hasLogoMobileUrl || this.hasLogoUrl;
          const menuShowLogoMobileSize = this._data.menuShowLogoMobileSize || menuShowLogoSize || '20px';
          size = R.replace('px', '')(menuShowLogoMobileSize) || '20px';
        }

        return `${hasLogoUrl}/-/resize/x${size}/-/quality/better/`;
      }

      return 'https://ucarecdn.com/0182ea1b-f79c-42f0-bc45-5fc81a8ec511/-/resize/20x20/';
    }),
    logoUrlSrcSet: (0, _object.computed)('hasLogoUrl', '_data.menuShowLogoSize', '_data.menuShowLogoMobileSize', '_data.menuShowLogoDesktopOnlySize', '_data.logoMenuPosition', 'websiteService.isMobile', function () {
      let hasLogoUrl = this.hasLogoUrl;
      const menuShowLogoSize = this._data.menuShowLogoSize || '20px';
      let size = R.replace('px', '')(menuShowLogoSize);
      size = parseInt(size) || 20;

      if (hasLogoUrl) {
        const isMobile = this.websiteService.isMobile;
        const logoMenuPosition = this._data.logoMenuPosition;

        if (!isMobile && logoMenuPosition === 'separateLine') {
          const menuShowLogoDesktopOnlySize = this._data.menuShowLogoDesktopOnlySize || menuShowLogoSize || '20px';
          size = R.replace('px', '')(menuShowLogoDesktopOnlySize) || '20px';
        }

        if (isMobile) {
          hasLogoUrl = this.hasLogoMobileUrl || this.hasLogoUrl;
          const menuShowLogoMobileSize = this._data.menuShowLogoMobileSize || menuShowLogoSize || '20px';
          size = R.replace('px', '')(menuShowLogoMobileSize) || '20px';
        }

        const height = size * 2;
        return `${hasLogoUrl}/-/resize/x${height}/-/quality/better/-/format/webp/-/progressive/yes/ 2x`;
      }

      return 'https://ucarecdn.com/0182ea1b-f79c-42f0-bc45-5fc81a8ec511/-/resize/40x40/-/format/webp/-/progressive/yes/ 2x';
    }),
    faviconUrls: (0, _object.computed)('hasFavicon', function () {
      const faviconUrl = this.hasFavicon;

      if (faviconUrl) {
        return [`${faviconUrl}/-/resize/16x16/`, `${faviconUrl}/-/resize/32x32/`, `${faviconUrl}/-/resize/48x48/`, `${faviconUrl}/-/resize/57x57/`, `${faviconUrl}/-/resize/64x64/`, `${faviconUrl}/-/resize/76x76/`, `${faviconUrl}/-/resize/96x96/`, `${faviconUrl}/-/resize/128x128/`];
      }

      return false;
    }),
    showShopHero: (0, _object.computed)('hasWebsite', function () {
      var _this$websiteService, _this$websiteService$;

      const hasWebsite = this.hasWebsite;
      const isBigOrdr = this.get('websiteService.isBigOrdr');
      const allowAllCampaigns = ((_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : (_this$websiteService$ = _this$websiteService.website._data) === null || _this$websiteService$ === void 0 ? void 0 : _this$websiteService$.allowAllCampaigns) || '';

      if (isBigOrdr) {
        return true;
      }

      if (hasWebsite && !allowAllCampaigns) {
        return false;
      }

      return true;
    }),
    showQuickNav: (0, _object.computed)('_data.showQuickNav', function () {
      return R.pathOr(true, ['_data', 'showQuickNav'])(this);
    }),
    showBackBtn: (0, _object.computed)('_data.showBackBtn', function () {
      return R.pathOr(true, ['_data', 'showBackBtn'])(this);
    }),
    isDisplayBlogsAuthor: (0, _object.computed)('_data.isDisplayBlogsAuthor', function () {
      return R.pathOr(false, ['_data', 'isDisplayBlogsAuthor'])(this);
    }),
    isDisplayBlogsDate: (0, _object.computed)('_data.isDisplayBlogsDate', function () {
      return R.pathOr(false, ['_data', 'isDisplayBlogsDate'])(this);
    }),
    isDisplayBlogsTitle: (0, _object.computed)('_data.isDisplayBlogsTitle', function () {
      return R.pathOr(true, ['_data', 'isDisplayBlogsTitle'])(this);
    }),
    isDisplayBlogsDescription: (0, _object.computed)('_data.isDisplayBlogsDescription', function () {
      return R.pathOr(true, ['_data', 'isDisplayBlogsDescription'])(this);
    }),
    isDisplayBlogsTags: (0, _object.computed)('_data.isDisplayBlogsTags', function () {
      return R.pathOr(false, ['_data', 'isDisplayBlogsTags'])(this);
    }),

    // showShopTemplate: computed('hasWebsite', function () {
    //   const hasWebsite = this.hasWebsite
    //   const isBigOrdr = this.get('websiteService.isBigOrdr')
    //   if (hasWebsite && !isBigOrdr) {
    //     return false
    //   }
    //   return true
    // }),
    getPage(pageUrl = '') {
      pageUrl = pageUrl.toLowerCase();
      const pages = this.get('_data.pages') || [];

      if (!pageUrl || pageUrl === '' || pageUrl === '/') {
        return R.find(R.propEq('isHome', true))(pages);
      }

      return R.find(R.pipe(R.pathOr('', ['url']), R.toLower, R.equals(pageUrl)))(pages);
    },

    translateName(dynamicPage) {
      if (dynamicPage.pageName) {
        return dynamicPage.pageName;
      }

      return this.intl.t(dynamicPage.name);
    },

    setupDynamicPage(item) {
      const isBigOrdr = this.get('websiteService.isBigOrdr');
      const hasStoreLocation = R.pathOr(false, ['_data', 'hasStoreLocation'])(this);

      if (hasStoreLocation && item.name === 'store location') {
        const dynamicStoreLocation = R.omit(['url'])(item);

        if (dynamicStoreLocation.showOnMenu || dynamicStoreLocation.showOnFooter) {
          dynamicStoreLocation.name = this.translateName(dynamicStoreLocation);
          return R.mergeRight({
            isAutoMenu: true,
            isBlogs: false,
            name: this.intl.t('store location'),
            route: 'store-location',
            translate: false,
            url: '/store-location'
          }, dynamicStoreLocation);
        }
      }

      const hasNews = R.pathOr(false, ['_data', 'hasNews'])(this);

      if (hasNews && item.name === 'news') {
        const dynamicNews = R.omit(['url'])(item);

        if (dynamicNews.showOnMenu || dynamicNews.showOnFooter) {
          dynamicNews.name = this.translateName(dynamicNews);
          return R.mergeRight({
            isAutoMenu: true,
            isBlogs: false,
            name: this.intl.t('news'),
            route: 'news',
            translate: false,
            url: '/news'
          }, dynamicNews);
        }
      }

      const hasBlogs = this.get('_data.hasBlogs');

      if (hasBlogs && item.name === 'blogs') {
        const dynamicBlog = R.omit(['url'])(item);

        if (dynamicBlog.showOnMenu || dynamicBlog.showOnFooter) {
          dynamicBlog.name = this.translateName(dynamicBlog);
          return R.mergeRight({
            name: this.intl.t('blogs'),
            url: '/blogs',
            translate: false,
            route: 'blogs',
            isAutoMenu: true,
            isBlogs: true
          }, dynamicBlog);
        }
      }

      const url = this.get('shopUrlDisplay');
      const shopPath = `/shop/${url}`;

      if (this.get('shopKey') && url && item.name === 'shop') {
        const dynamicShop = R.omit(['url'])(item);

        if (dynamicShop.showOnMenu || dynamicShop.showOnFooter) {
          dynamicShop.name = this.translateName(dynamicShop);
          return R.mergeRight({
            name: this.intl.t('shop'),
            translate: false,
            url: shopPath,
            params: '',
            isAutoMenu: true,
            route: '',
            isShop: true
          }, dynamicShop);
        }
      }

      if ((!_nventor.default.isNilOrEmpty(this.get('shopKey')) || this.get('websiteService.isBigOrdr')) && item.name === 'check order status') {
        const dynamicCheckOrderStatus = R.omit(['url'])(item);

        if (dynamicCheckOrderStatus.showOnMenu || dynamicCheckOrderStatus.showOnFooter) {
          dynamicCheckOrderStatus.name = this.translateName(dynamicCheckOrderStatus);

          if (_nventor.default.isNilOrEmpty(this.get('shopKey')) && this.get('websiteService.isBigOrdr')) {
            return R.mergeRight({
              name: this.intl.t('check order status'),
              url: '/orders',
              params: '',
              translate: false,
              route: '',
              isAutoMenu: true,
              isBlogs: false,
              isShop: false
            }, dynamicCheckOrderStatus);
          }

          return R.mergeRight({
            name: this.intl.t('check order status'),
            url: `${shopPath}/orders`,
            params: '',
            translate: false,
            route: '',
            isAutoMenu: true,
            isBlogs: false,
            isShop: true
          }, dynamicCheckOrderStatus);
        }
      }

      const faqs = R.path(['shop', 'faqs'])(this) || R.path(['shop', '_data', 'faqs'])(this);
      const hasFaqs = !_nventor.default.isNilOrEmpty(faqs);

      if (hasFaqs && item.name === 'faqs' && !isBigOrdr) {
        if (this.get('shopKey') && url) {
          const dynamicFaqs = R.omit(['url'])(item);

          if (dynamicFaqs.showOnMenu || dynamicFaqs.showOnFooter) {
            dynamicFaqs.name = this.translateName(dynamicFaqs);
            return R.mergeRight({
              name: this.intl.t('faqs'),
              url: `${shopPath}/faqs`,
              params: '',
              translate: false,
              route: '',
              isShop: true,
              isAutoMenu: true,
              isBlogs: false
            }, dynamicFaqs);
          }
        }
      }

      const hasContactInfo = R.prop('companyName')(this) || R.prop('companyTel')(this);

      if (hasContactInfo && item.name === 'contact us') {
        const dynamicContactUs = R.omit(['url'])(item);

        if (dynamicContactUs.showOnMenu || dynamicContactUs.showOnFooter) {
          dynamicContactUs.name = this.translateName(dynamicContactUs);
          return R.mergeRight({
            name: this.intl.t('contact us'),
            url: 'contact-us',
            translate: false,
            route: '',
            isAutoMenu: true,
            isBlogs: false
          }, dynamicContactUs);
        }
      }
    },

    isBigordrPath: (0, _object.computed)('window.location.href', function () {
      const bigOrdrPath = 'https://bigordr.com/';
      const bigOrdrStagingPath = 'https://9889.co/';

      if (_environment.default.APP.websiteStub && _environment.default.APP.websiteStub === 'bigordr.com') {
        return true;
      } else if (window.location.href === bigOrdrPath) {
        return true;
      } else if (window.location.href === bigOrdrStagingPath) {
        return true;
      }

      return false;
    }),

    _filterOutBigOrderMenuItems(allPages = [], shopPodKey = '') {
      if (!shopPodKey) {
        const allMenusBelongToSamePodKey = R.pipe(R.pluck('podKey'), R.uniq, R.propSatisfies(R.equals(R.__, 1), 'length'))(allPages);

        if (this.isBigordrPath || allMenusBelongToSamePodKey) {
          return allPages;
        }
      }

      const keepMenu = R.filter(R.anyPass([R.propEq('isHome', true), R.propEq('url', 'privacy'), R.propEq('name', 'shop'), R.propEq('name', 'check order status')]))(allPages) || [];
      const isBigOrdr = R.filter(R.propEq('podKey', shopPodKey))(allPages) || [];
      return R.pipe(R.concat(keepMenu), R.uniqBy(R.prop('_id')))(isBigOrdr);
    },

    _getPageModels({
      shopUrl,
      showOnMenuProp,
      allPages,
      filterByProps = {}
    }) {
      var _this$shop, _this$shop$_data;

      const shopPodKey = this === null || this === void 0 ? void 0 : (_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : (_this$shop$_data = _this$shop._data) === null || _this$shop$_data === void 0 ? void 0 : _this$shop$_data.podKey;
      allPages = this._filterOutBigOrderMenuItems(allPages, shopPodKey);
      let pageModels = allPages;

      if (showOnMenuProp) {
        pageModels = R.filter(R.propEq(showOnMenuProp, true))(pageModels);
      }

      if (_nventor.default.isNotNilOrEmpty(filterByProps)) {
        pageModels = R.filter(R.whereEq(filterByProps))(pageModels);
      }

      pageModels = R.pipe( // Filter by show on menu/footer
      pages => {
        const homePage = R.find(R.propEq('isHome', true))(pages) || {};
        homePage.name = R.prop('pageName')(homePage) || this.intl.t('home page');
        homePage.translate = false;
        return pages;
      }, R.map(data => {
        if (data.isDynamicPage) {
          data = this.setupDynamicPage(data);
        }

        if (!data) {
          return false;
        }

        data.shopUrl = shopUrl;
        return _model.default.create(data);
      }), R.reject(R.equals(false)))(pageModels);
      const hasHomePage = R.find(R.pathEq(['isHome'], true))(pageModels);

      if (hasHomePage) {
        const homePage = R.find(R.propEq('isHome', true))(pageModels) || {};
        homePage.set('posIndex', -1000);
      }

      return pageModels;
    },

    allProductsMenu: (0, _object.computed)('isBigOrdr', 'shopUrl', '_data.pages.[]', function () {
      try {
        // return []
        let pageModels = this._menu('', {
          filterByProps: {
            isDynamicPage: true,
            name: 'shop'
          }
        }); // @NOTE: TMP FIX


        pageModels = R.map(model => {
          model.set('name', this.intl.t('all products'));
          model.set('isAllProducts', true);
          return model;
        })(pageModels);
        return pageModels;
      } catch (e) {
        console.error(e);
        return [];
      }
    }),
    hasCategories: (0, _object.computed)('categoriesMenu', function () {
      const categoriesMenu = this.categoriesMenu || [];

      if (R.length(categoriesMenu) > 0) {
        return true;
      }

      return false;
    }),
    categoriesMenu: (0, _object.computed)('isBigOrdr', 'shopUrl', '_data.pages.[]', function () {
      return this._menu('showOnFilters', {
        isCategories: true
      });
    }),
    menu: (0, _object.computed)('isBigOrdr', 'shopUrl', '_data.pages.[]', function () {
      return this._menu('showOnMenu');
    }),
    footerMenu: (0, _object.computed)('isBigOrdr', 'shopUrl', '_data.pages.[]', function () {
      return this._menu('showOnFooter');
    }),

    getAllPagesFlattened({
      showOnMenuProp = false,
      filterByProps = {},
      isCategories = false
    } = {}) {
      const shopUrl = (this === null || this === void 0 ? void 0 : this.shopUrlDisplay) || (this === null || this === void 0 ? void 0 : this.shopUrl) || '';
      let allPages = R.pipe(R.pathOr([], ['_data', 'pages']), R.reject(R.propEq('status', 'inactive')))(this);
      const isCategory = R.propEq('type', 'category');
      const encodeUrlAsUriComponent = R.evolve({
        url: encodeURIComponent
      }); // only encode categories urls
      // @TODO: should be moved to menu model linkToUrl

      allPages = R.map(R.when(isCategory, encodeUrlAsUriComponent))(allPages);
      const allPagesCopy = R.clone(allPages);
      allPages = R.map(page => {
        if (page.type === 'link') {
          const linkType = page.linkType;
          const resourceType = page.linkResourceType;
          const resourceKey = page.linkResourceKey;
          let url = page.url || '';

          if (linkType === 'pageAndCategory') {
            const foundMenuItem = R.find(R.propEq('_key', resourceKey))(allPagesCopy) || {};
            const pageUrl = R.propOr('', 'url')(foundMenuItem);

            if (resourceType === 'category') {
              url = `/shop/${this.shopUrlDisplay}/category/${pageUrl}`;
            } else if (foundMenuItem !== null && foundMenuItem !== void 0 && foundMenuItem.isDynamicPage) {
              url = pageUrl;
            } else {
              url = `/${pageUrl}`;
            }
          } else if (linkType === 'url') {
            url = page.linkUrl;
          } else if (resourceType === 'product') {
            url = `/shop/${this.shopUrlDisplay}/item/${resourceKey}`;
          } else if (resourceType === 'blog') {
            url = `/blogs/${resourceKey}`;
          } else if (resourceType === 'news') {
            url = `/news/${resourceKey}`;
          } else if (resourceType === 'store-location') {
            url = `/store-location/${resourceKey}`;
          }

          page.url = url;
        }

        return page;
      })(allPages);

      if (isCategories) {
        allPages = R.filter(R.propEq('type', 'category'))(allPages);
      }

      let pages = this._getPageModels({
        shopUrl,
        showOnMenuProp,
        allPages,
        filterByProps
      });

      if (this.websiteService.isBigOrdrShopRoute) {
        pages = R.reject(R.propEq('isBlogs', true))(pages);
      }

      return pages;
    },

    _menu(showOnMenuProp, {
      filterByProps = {},
      isCategories = false
    } = {}) {
      const pages = this.getAllPagesFlattened({
        showOnMenuProp,
        filterByProps,
        isCategories
      }) || [];
      const shopUrl = (this === null || this === void 0 ? void 0 : this.shopUrlDisplay) || (this === null || this === void 0 ? void 0 : this.shopUrl) || '';
      const byDepths = R.pipe(R.sortWith([R.ascend(R.prop('depth'))]), R.groupBy(R.prop('depth')), R.values, R.map(R.sortWith([R.ascend(R.prop('posIndex'))])))(pages);
      const childrenByParent = R.pipe(R.drop(1), R.flatten, R.groupBy(R.prop('parent')))(byDepths);

      const findChildrenByParent = (parentKey, childrenByParent) => {
        return R.pipe(R.mapObjIndexed((childrenPages, key) => {
          const hasMatchingLastSegment = R.pipe(R.split('/'), _nventor.default.safeLast, R.equals(parentKey))(key);

          if (hasMatchingLastSegment) {
            return childrenPages;
          }

          return false;
        }), R.values, R.reject(R.equals(false)), R.flatten)(childrenByParent);
      };

      return R.pipe(R.map(R.pipe(R.map(item => {
        if (!item) {
          return false;
        }

        const sub = findChildrenByParent(item._key, childrenByParent);
        item.set('sub', sub);
        item.set('shopUrl', shopUrl);
        return item;
      }), R.reject(R.equals(false)), R.reject(_nventor.default.isNilOrEmpty))), _nventor.default.safeHeadOr([]))(byDepths);
    },

    getDynamicPage(name) {
      return R.pipe(R.pathOr([], ['_data', 'pages']), R.find(R.anyPass([R.propEq('name', name), R.propEq('url', name)])))(this) || {};
    },

    setParams(params = {}) {
      const newParams = R.mergeRight(defaultParams)(params);
      this.set('resetParams', Math.random());
      this.set('params', newParams);
      return newParams;
    },

    _transformCategoryProductTags(data) {
      const pages = R.propOr([], 'pages')(data);
      const categoryPages = R.filter(R.propEq('type', 'category'))(pages);
      const noCategoryPages = R.reject(R.propEq('type', 'category'))(pages);
      const categoryPagesGroupByParent = R.pipe(R.map(page => {
        // Remove nested parent path
        // 1007467844/1007467001/1007467882 -> 1007467882
        try {
          const parent = R.pipe(R.propOr('', 'parent'), R.split('/'), R.last)(page);

          if (!parent) {
            return page;
          }

          return R.mergeRight(page, {
            parent
          });
        } catch (error) {
          return page;
        }
      }), R.groupBy(R.prop('parent')))(categoryPages);
      const categoryPagesGroupByDepth = R.groupBy(R.prop('depth'))(categoryPages);
      const updatedPages = R.pipe(R.mapObjIndexed((pages, depth) => {
        return R.map(parentPage => {
          const parentKey = parentPage._key;
          const childrenPages = categoryPagesGroupByParent[parentKey] || [];
          const childrenProductTags = getChildrenProductTags(childrenPages);
          const parentPageProductTagsWithChildrenProductTags = R.pipe(R.propOr([], 'productTags'), R.concat(childrenProductTags))(parentPage);
          return R.mergeRight(parentPage, {
            productTags: parentPageProductTagsWithChildrenProductTags
          });
        })(pages);
      }), R.values, R.flatten, R.concat(noCategoryPages))(categoryPagesGroupByDepth);

      function getChildrenProductTags(childrenPages) {
        return R.pipe(R.map(child => {
          const productTags = R.propOr([], 'productTags')(child);
          const subChildren = categoryPagesGroupByParent[child._key] || [];
          const hasRecursiveParent = R.includes(child === null || child === void 0 ? void 0 : child._key)(child === null || child === void 0 ? void 0 : child.parent);

          if (hasRecursiveParent) {
            // @TODO: send email
            // console.log('child', child?._key)
            // console.log('parent', child?.parent)
            // console.log('recursive parent error', child._key)
            return [];
          }

          if (_nventor.default.isNotNilOrEmpty(subChildren)) {
            return getChildrenProductTags(subChildren);
          }

          return productTags;
        }), R.flatten)(childrenPages);
      }

      return updatedPages;
    },

    _addChildToParent(child, pages) {
      return R.pipe(R.map(page => {
        if (R.pathEq(['_key'], child === null || child === void 0 ? void 0 : child.parent)(page)) {
          const children = (page === null || page === void 0 ? void 0 : page.children) || [];
          page.children = children.concat(child);
          return page;
        }

        if (_nventor.default.isNotNilOrEmpty(page === null || page === void 0 ? void 0 : page.children)) {
          return this._addChildToParent(child, page === null || page === void 0 ? void 0 : page.children);
        }

        return page;
      }))(pages);
    },

    websitePageStructure: (0, _object.computed)('_data.pages.[]', function () {
      const pages = this.getAllPagesFlattened();
      let sortedPages = R.pipe(R.reduce((acc = [], page) => {
        var _page$url;

        if (_nventor.default.isNotNilOrEmpty(page.password)) {
          return acc;
        }

        if (!R.pathEq(['status'], 'active')(page)) {
          return acc;
        }

        if (R.pathEq(['isHome'], true)(page)) {
          return acc;
        }

        if (R.pathEq(['isShop'], true)(page)) {
          return acc;
        }

        if (R.pathEq(['type'], 'link')(page)) {
          return acc;
        }

        if (!(page !== null && page !== void 0 && (_page$url = page.url) !== null && _page$url !== void 0 && _page$url.startsWith('/'))) {
          page.url = `/${page.url}`;
        }

        return acc.concat(page);
      }, []))(pages);
      const homePage = R.filter(R.pathEq(['isHome'], true))(pages);
      sortedPages = homePage.concat(sortedPages);
      const shopPage = R.filter(R.pathEq(['isShop'], true))(pages);

      if (shopPage) {
        sortedPages = sortedPages.concat(shopPage);
      }

      return sortedPages;
    })
  });

  _exports.default = _default;
});
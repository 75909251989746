define("shop/pods/components/chat-btn/fb-chat-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cgKvl1E4",
    "block": "[[[41,[28,[37,1],[[30,1],[30,2]],null],[[[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],[[16,0,[30,0,[\"btnClassName\"]]],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"openFacebookDialog\"]]],null]],null]],[[\"@icon\"],[\"fab fa-facebook-messenger\"]],null],[1,\"\\n\"]],[]]]],[]],null]],[\"@hasFacebookChatPlugin\",\"@facebookPage\",\"&default\"],false,[\"if\",\"and\",\"has-block\",\"yield\",\"elements/element-icon\",\"on\",\"fn\"]]",
    "moduleName": "shop/pods/components/chat-btn/fb-chat-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/mixins/automations", ["exports", "@ember/object/mixin", "@ember/service"], function (_exports, _mixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import * as R from 'ramda'
  // import nventor from '../utils/nventor'
  var _default = _mixin.default.create({
    ajax: (0, _service.inject)(),
    autoValidate: true,

    // init () {
    //   this._super(...arguments)
    // if (this.autoValidate) {
    //   const checkoutData = this.checkoutData
    //   const user = this.user
    //   const shop = this.shop
    //   const hasAddress = this.hasAddress
    //   this.shopService.get('validateCartItemsTask').perform({ shop, checkoutData, user, hasAddress })
    // }
    // },
    cartChanged() {
      const checkoutData = this.checkoutData;
      const user = this.user;
      const shop = this.shop;
      const hasAddress = this.hasAddress;
      this.shopService.get('validateCartItemsTask').perform({
        shop,
        checkoutData,
        user,
        hasAddress,
        forceValidation: true
      });
    },

    actions: {
      cartChanged() {
        this.cartChanged();
      },

      chooseMustAdd(automation, itemData) {
        this.shop.chooseMustAddItem(automation, itemData);
        this.cartChanged();
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/page-breadcrumb/component", ["exports", "ramda", "@glimmer/component", "@ember/service"], function (_exports, R, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LayoutsPageBreadcrumb = (_dec = (0, _service.inject)('website'), (_class = class LayoutsPageBreadcrumb extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);
    }

    get isCampaign() {
      var _this$websiteService, _this$websiteService$, _this$websiteService$2;

      const shopKey = (this === null || this === void 0 ? void 0 : (_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : (_this$websiteService$ = _this$websiteService.website) === null || _this$websiteService$ === void 0 ? void 0 : (_this$websiteService$2 = _this$websiteService$._data) === null || _this$websiteService$2 === void 0 ? void 0 : _this$websiteService$2.shopKey) || '';

      if (shopKey) {
        return true;
      }

      return false;
    }

    get indexSum() {
      if (this.isCampaign) {
        return 1;
      }

      return 2;
    }

    get allPagesOriginal() {
      return R.pipe(R.pathOr([], ['websiteService', 'website', '_data', 'pages']))(this);
    }

    get homePageName() {
      const homeWebPage = R.pipe(R.pathOr([], ['websiteService', 'website', '_data', 'pages']), R.find(R.propEq('isHome', true)))(this);

      if (!homeWebPage) {
        return this.intl.t('home');
      }

      if (homeWebPage.pageName) {
        return homeWebPage.pageName;
      }

      return homeWebPage.name;
    }

    get pageData() {
      var _this$args, _this$args2, _this$args3, _this$args4, _this$args5;

      const page = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.page) || {};
      const allPages = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.allPages) || [];

      if ((_this$args3 = this.args) !== null && _this$args3 !== void 0 && _this$args3.isBlog) {
        const blogPage = R.find(R.propEq('url', 'blogs'))(allPages);

        if (blogPage !== null && blogPage !== void 0 && blogPage._data) {
          return blogPage._data;
        }

        return blogPage;
      }

      if ((_this$args4 = this.args) !== null && _this$args4 !== void 0 && _this$args4.isNews) {
        const newsPage = R.find(R.propEq('url', 'news'))(allPages);

        if (newsPage !== null && newsPage !== void 0 && newsPage._data) {
          return newsPage._data;
        }

        return newsPage;
      }

      if ((_this$args5 = this.args) !== null && _this$args5 !== void 0 && _this$args5.isProduct) {
        const shopPage = R.find(R.propEq('url', 'shop'))(allPages);

        if (shopPage !== null && shopPage !== void 0 && shopPage._data) {
          return shopPage._data;
        }

        return shopPage;
      }

      if (page !== null && page !== void 0 && page._data) {
        return page._data;
      }

      return page;
    }

    get pageName() {
      var _this$args6, _this$args8, _this$args9, _this$pageData;

      if ((_this$args6 = this.args) !== null && _this$args6 !== void 0 && _this$args6.isProduct) {
        var _this$args7;

        const breadcrumbModel = ((_this$args7 = this.args) === null || _this$args7 === void 0 ? void 0 : _this$args7.breadcrumbModel) || {};
        const itemName = (breadcrumbModel === null || breadcrumbModel === void 0 ? void 0 : breadcrumbModel.itemName) || '';
        return itemName;
      }

      if ((_this$args8 = this.args) !== null && _this$args8 !== void 0 && _this$args8.isBlog || (_this$args9 = this.args) !== null && _this$args9 !== void 0 && _this$args9.isNews) {
        var _this$args10, _breadcrumbModel$_dat, _breadcrumbModel$_dat2;

        const breadcrumbModel = ((_this$args10 = this.args) === null || _this$args10 === void 0 ? void 0 : _this$args10.breadcrumbModel) || {};
        const dynamicPageName = (breadcrumbModel === null || breadcrumbModel === void 0 ? void 0 : (_breadcrumbModel$_dat = breadcrumbModel._data) === null || _breadcrumbModel$_dat === void 0 ? void 0 : _breadcrumbModel$_dat.pageName) || (breadcrumbModel === null || breadcrumbModel === void 0 ? void 0 : (_breadcrumbModel$_dat2 = breadcrumbModel._data) === null || _breadcrumbModel$_dat2 === void 0 ? void 0 : _breadcrumbModel$_dat2.name) || '';
        return dynamicPageName;
      }

      const pName = R.pipe(R.pathOr([], ['args', 'menu']), R.find(R.propEq('_key', (_this$pageData = this.pageData) === null || _this$pageData === void 0 ? void 0 : _this$pageData._key)), R.prop('name'))(this) || R.pathOr('', ['pageData', 'name'])(this);
      return pName;
    }

    get showBreadcrumb() {
      var _this$pageData2, _this$breadcrumbs;

      // current fix for very long breadcrumb
      if (this.websiteService.isMobile) {
        return false;
      }

      let isBreadcrumbsOn = ((_this$pageData2 = this.pageData) === null || _this$pageData2 === void 0 ? void 0 : _this$pageData2.showBreadcrumb) || false; // const isBigOrdr = this.websiteService?.isBigOrdr
      // if (isBigOrdr) {
      //   isBreadcrumbsOn = true
      // }

      const currentRoute = this.router.currentRouteName;

      if (currentRoute === 'shop.results') {
        return true;
      }

      if (!isBreadcrumbsOn) {
        return false;
      }

      if (this.websiteService.isShopIndexRoute && (this === null || this === void 0 ? void 0 : (_this$breadcrumbs = this.breadcrumbs) === null || _this$breadcrumbs === void 0 ? void 0 : _this$breadcrumbs.length) <= 1) {
        return false;
      }

      return true;
    }

    get breadcrumbs() {
      var _this$pageData3, _this$pageData4, _this$pageData5, _this$pageData6, _this$args12, _this$args13, _this$args14;

      const parent = ((_this$pageData3 = this.pageData) === null || _this$pageData3 === void 0 ? void 0 : _this$pageData3.parent) || '';
      let parents = [];

      if (parent) {
        parents = parent.split('/');
      }

      const name = ((_this$pageData4 = this.pageData) === null || _this$pageData4 === void 0 ? void 0 : _this$pageData4.pageName) || ((_this$pageData5 = this.pageData) === null || _this$pageData5 === void 0 ? void 0 : _this$pageData5.name) || ((_this$pageData6 = this.pageData) === null || _this$pageData6 === void 0 ? void 0 : _this$pageData6.url) || '';
      parents.push(name);
      const menu = this.args.menu || [];
      const arr = [];
      let i = 0;

      const findDescendants = (originalMenu, _menu) => {
        var _parents;

        const target = (_parents = parents) === null || _parents === void 0 ? void 0 : _parents[i];
        let menuItem = R.find(R.propEq('pageName', target))(_menu) || R.find(R.propEq('name', target))(_menu) || R.find(R.propEq('_key', target))(_menu) || R.find(R.propEq('pageName', target))(originalMenu) || R.find(R.propEq('name', target))(originalMenu) || R.find(R.propEq('_key', target))(originalMenu) || '';
        const updatedMenuItem = R.find(R.propEq('_key', menuItem === null || menuItem === void 0 ? void 0 : menuItem._key))(_menu);
        const menuItemName = R.prop('name')(updatedMenuItem);
        const sub = (menuItem === null || menuItem === void 0 ? void 0 : menuItem.sub) || [];

        if (sub.length > 0 && i < parents.length) {
          i++;
          findDescendants(sub, menu);
        }

        let route = `/${(updatedMenuItem === null || updatedMenuItem === void 0 ? void 0 : updatedMenuItem.route) || ''}`;

        if (updatedMenuItem !== null && updatedMenuItem !== void 0 && updatedMenuItem.isShop) {
          var _this$args11;

          const shop = (_this$args11 = this.args) === null || _this$args11 === void 0 ? void 0 : _this$args11.shop;
          route = `/shop/${shop === null || shop === void 0 ? void 0 : shop.url}`;
        }

        let url = `/${(updatedMenuItem === null || updatedMenuItem === void 0 ? void 0 : updatedMenuItem.url) || ''}`;

        if (menuItem.type === 'category') {
          url = menuItem.url;
        }

        if (!url) {
          url = '#';
        }

        const data = {
          hasTemplate: menuItem === null || menuItem === void 0 ? void 0 : menuItem.hasTemplate,
          type: (menuItem === null || menuItem === void 0 ? void 0 : menuItem.type) || '',
          isDynamicPage: menuItem === null || menuItem === void 0 ? void 0 : menuItem.isDynamicPage,
          name: menuItemName || (menuItem === null || menuItem === void 0 ? void 0 : menuItem.name) || '',
          id: menuItem === null || menuItem === void 0 ? void 0 : menuItem._id,
          route,
          url
        };

        if (data.name) {
          arr.unshift(data);
        }
      };

      const currentRoute = this.router.currentRouteName;

      if (currentRoute !== 'shop.results') {
        findDescendants(this.allPagesOriginal, menu);
      }

      if ((_this$args12 = this.args) !== null && _this$args12 !== void 0 && _this$args12.isBlog || (_this$args13 = this.args) !== null && _this$args13 !== void 0 && _this$args13.isNews || (_this$args14 = this.args) !== null && _this$args14 !== void 0 && _this$args14.isProduct) {
        var _this$args15, _breadcrumbModel$_dat3, _breadcrumbModel$_dat4, _this$args16;

        const name = this.pageName;
        const breadcrumbModel = ((_this$args15 = this.args) === null || _this$args15 === void 0 ? void 0 : _this$args15.breadcrumbModel) || {};
        let id = breadcrumbModel === null || breadcrumbModel === void 0 ? void 0 : (_breadcrumbModel$_dat3 = breadcrumbModel._data) === null || _breadcrumbModel$_dat3 === void 0 ? void 0 : _breadcrumbModel$_dat3._id;
        let key = breadcrumbModel === null || breadcrumbModel === void 0 ? void 0 : (_breadcrumbModel$_dat4 = breadcrumbModel._data) === null || _breadcrumbModel$_dat4 === void 0 ? void 0 : _breadcrumbModel$_dat4._key;

        if ((_this$args16 = this.args) !== null && _this$args16 !== void 0 && _this$args16.isProduct) {
          var _breadcrumbModel$_dat5, _this$args17;

          const itemKey = breadcrumbModel === null || breadcrumbModel === void 0 ? void 0 : (_breadcrumbModel$_dat5 = breadcrumbModel._data) === null || _breadcrumbModel$_dat5 === void 0 ? void 0 : _breadcrumbModel$_dat5.itemKey;
          const shop = (_this$args17 = this.args) === null || _this$args17 === void 0 ? void 0 : _this$args17.shop;
          const shopUrl = `/shop/${shop === null || shop === void 0 ? void 0 : shop.url}`;
          id = `item/${itemKey}/${name}`;
          key = `${shopUrl}/item/${itemKey}/${name}`;
        }

        arr.push({
          hasTemplate: true,
          id,
          key,
          name
        });
        parents.push(name);
      }

      if (currentRoute === 'shop.results') {
        var _this$args$website, _this$args18;

        const _arr = [];
        const websiteData = (_this$args$website = this.args.website) === null || _this$args$website === void 0 ? void 0 : _this$args$website._data;
        const menu = (_this$args18 = this.args) === null || _this$args18 === void 0 ? void 0 : _this$args18.menu;
        const shop = websiteData.shop;
        const shopUrl = `/shop/${shop.url}`;
        const shopItem = R.find(R.propEq('url', shopUrl))(menu);

        if (shopItem) {
          _arr.push({
            isDynamicPage: true,
            name: shopItem.name,
            route: shopUrl
          });
        }

        _arr.push({
          id: 'results',
          key: '#',
          name: this.intl.t('search results')
        });

        return _arr;
      }

      return arr.splice(0, parents.length);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LayoutsPageBreadcrumb;
});
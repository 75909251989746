define("shop/pods/components/referrals/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "@ember/object", "@ember/service", "@glimmer/tracking", "shop/utils/nventor", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, _object, _service, _tracking, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ReferralsComponent = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('shop'), (_class = class ReferralsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);

      _initializerDefineProperty(this, "shopService", _descriptor2, this);

      _initializerDefineProperty(this, "storage", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "createdReferralCodeData", _descriptor5, this);

      const website = this.args.website || {};
      const isLoggedIn = website.isLoggedIn || false;

      if (isLoggedIn) {
        var _user$_data;

        const user = (website === null || website === void 0 ? void 0 : website.user) || {};
        const email = (user === null || user === void 0 ? void 0 : (_user$_data = user._data) === null || _user$_data === void 0 ? void 0 : _user$_data.email) || '';
        (0, _object.set)(this, 'email', email);
      }
    }

    get hasValidReferralData() {
      const referralData = this.args.referralData || {};

      if (_nventor.default.isNilOrEmpty(referralData)) {
        return false;
      }

      return true;
    }

    get hasPromoCodeFromReferralCode() {
      const createdReferralCodeData = this.createdReferralCodeData || {};

      if (_nventor.default.isNotNilOrEmpty(createdReferralCodeData)) {
        return true;
      }

      return false;
    }

    *createReferralCodeTask() {
      var _this$args, _this$args$shop;

      (0, _object.set)(this, 'createdReferralCodeData', '');
      (0, _object.set)(this, 'hasAppliedToShop', false);
      const referralData = this.args.referralData;
      const email = this.email || '';
      const shopUrl = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$shop = _this$args.shop) === null || _this$args$shop === void 0 ? void 0 : _this$args$shop.url) || '';

      if (email && referralData.referralCode && shopUrl) {
        var _response$data, _response$data3;

        const data = R.clone(referralData);
        data.recipientEmail = email;
        const response = yield this.websiteService.request(`/api/public/websites/members/${shopUrl}/referral`, {
          method: 'POST',
          data
        });

        if (response !== null && response !== void 0 && (_response$data = response.data) !== null && _response$data !== void 0 && _response$data.errors || false) {
          var _response$data2;

          const errorMessage = (response === null || response === void 0 ? void 0 : (_response$data2 = response.data) === null || _response$data2 === void 0 ? void 0 : _response$data2.message) || '';
          (0, _object.set)(this, 'hasErrorMessage', true);
          (0, _object.set)(this, 'errorMessage', errorMessage);
          (0, _object.set)(this, 'isSuccess', false);
        }

        if (response !== null && response !== void 0 && (_response$data3 = response.data) !== null && _response$data3 !== void 0 && _response$data3.model) {
          (0, _object.set)(this, 'hasErrorMessage', false);
          (0, _object.set)(this, 'errorMessage', '');
          (0, _object.set)(this, 'isSuccess', true);
          const createdReferralCodeData = response.data.model;
          (0, _object.set)(this, 'createdReferralCodeData', createdReferralCodeData);
          this.applyPromoCode({
            createdReferralCodeData,
            email: this.email
          });
        }
      }
    }

    applyPromoCode({
      createdReferralCodeData,
      email
    }) {
      var _createdReferralCodeD;

      const promoCode = (createdReferralCodeData === null || createdReferralCodeData === void 0 ? void 0 : (_createdReferralCodeD = createdReferralCodeData.promoCodeData) === null || _createdReferralCodeD === void 0 ? void 0 : _createdReferralCodeD.code) || '';

      if (promoCode && email) {
        this.shopService.setPromoCode(promoCode);
        this.storage.setValue('emailForPromoCode', email);
        (0, _object.set)(this, 'hasAppliedToShop', true);
      }
    }

    goToShop() {
      this.router.transitionTo('shop');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createdReferralCodeData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createReferralCodeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createReferralCodeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToShop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goToShop"), _class.prototype)), _class));
  _exports.default = ReferralsComponent;
});
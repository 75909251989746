define("shop/pods/components/layouts/page-content/component", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',
    classNames: ['page-container', 'section'],
    websiteService: (0, _service.inject)('website')
  });

  _exports.default = _default;
});
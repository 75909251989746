define("shop/pods/google/gtag/service", ["exports", "@ember/service", "ramda", "@ember/object", "@glimmer/tracking"], function (_exports, _service, R, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CURRENCY = 'TWD';
  let GoogleGtagService = (_dec = (0, _service.inject)('google'), (_class = class GoogleGtagService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "googleService", _descriptor, this);

      _initializerDefineProperty(this, "hasTrackingId", _descriptor2, this);

      _initializerDefineProperty(this, "hasAlreadyInit", _descriptor3, this);

      _initializerDefineProperty(this, "allTrackingData", _descriptor4, this);
    }

    get hasTracker() {
      if (!this.hasTrackingId) {
        return false;
      }

      if (!window.gtag) {
        return false;
      }

      return true;
    }

    genAddressHref(address) {
      return R.replace(/\s/gm, '+')(address);
    }

    addTracker(trackingData = {}) {
      const trackingId = trackingData === null || trackingData === void 0 ? void 0 : trackingData.id;
      const allTrackingData = this.allTrackingData || [];
      const alreadyAdded = R.find(R.propEq('id', trackingId))(allTrackingData);

      if (!alreadyAdded) {
        this.allTrackingData.pushObject(trackingData);

        if (!this.hasAlreadyInit) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingData.id}`;
          const customScript = document.createElement('script');
          customScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('set', { 'currency': '${CURRENCY}' })
        `;
          const pageScripts = jQuery('head');
          pageScripts.append(script);
          pageScripts.append(customScript);
          this.hasAlreadyInit = true;
        }

        window.gtag('config', trackingData.id, {
          'send_page_view': true,
          'debug_mode': false
        });
      }

      this.hasTrackingId = true;
    }

    addToCart(cartItem) {
      const event = 'add_to_cart';
      this.googleService.sendConversions({
        event,
        allTrackingData: this.allTrackingData
      });

      this._sendEvent(event, cartItem);
    }

    removeFromCart(cartItem) {
      this._sendEvent('remove_from_cart', cartItem);
    }

    viewItem(item) {
      this._sendEvent('view_item', item);
    }

    _sendEvent(event, item) {
      if (!this.hasTracker) {
        return false;
      }

      const items = this.googleService.parseItems([item]);
      window.gtag('event', event, {
        currency: CURRENCY,
        value: R.pipe(R.pluck('price'), R.sum)(items),
        items
      });
    }

    checkoutStep({
      step,
      shop
    }) {
      if (!this.hasTracker) {
        return false;
      }

      let event = '';
      const cartItems = shop.cartItems || [];
      const items = this.googleService.parseItems(cartItems);
      const payloadData = {
        checkout_step: step,
        currency: CURRENCY,
        value: R.pipe(R.map(item => item.price * item.quantity), R.sum)(items),
        items
      };
      const uaTags = R.pipe(R.propOr([{}], 'allTrackingData'), R.pluck('id'), R.filter(R.includes('UA-')))(this);
      const nonUaTags = R.pipe(R.propOr([{}], 'allTrackingData'), R.pluck('id'), R.reject(R.includes('UA-')))(this);
      const hasUaTags = uaTags.length > 0;
      const hasNonUaTags = nonUaTags.length > 0;

      if (step === 1) {
        event = 'begin_checkout';
        window.gtag('event', event, payloadData);
      } else if (step === 2) {
        event = 'add_shipping_info';

        if (hasNonUaTags) {
          window.gtag('event', event, {
            payloadData,
            send_to: nonUaTags
          });
        } // Send event to old UA-XXXXXX-1 tags


        if (hasUaTags) {
          window.gtag('event', 'set_checkout_option', {
            send_to: uaTags,
            'checkout_step': step,
            'checkout_option': event,
            'value': 3
          });
        }
      } else if (step === 3) {
        event = 'add_payment_info';

        if (hasNonUaTags) {
          window.gtag('event', event, {
            payloadData,
            send_to: nonUaTags
          });
        } // Send event to old UA-XXXXXX-1 tags


        if (hasUaTags) {
          window.gtag('event', 'set_checkout_option', {
            send_to: uaTags,
            'checkout_step': step,
            'checkout_option': event,
            'value': 3
          });
        }
      }
    }

    checkoutComplete(success, order) {
      var _success$_data;

      const event = 'purchase';

      if (!this.hasTracker) {
        return false;
      }

      if (!order.inclTotal) {
        return false;
      }

      const cartItems = order.details || [];
      const items = this.googleService.parseItems(cartItems);
      window.gtag('event', event, {
        'transaction_id': success === null || success === void 0 ? void 0 : (_success$_data = success._data) === null || _success$_data === void 0 ? void 0 : _success$_data.ref,
        'value': parseInt(order.inclTotal),
        'currency': CURRENCY,
        'items': items
      });
      this.googleService.sendConversions({
        event,
        order,
        items,
        success,
        allTrackingData: this.allTrackingData
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "googleService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasTrackingId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasAlreadyInit", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "allTrackingData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addTracker", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addTracker"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addToCart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addToCart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeFromCart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeFromCart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "viewItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_sendEvent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_sendEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkoutStep", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkoutStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkoutComplete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkoutComplete"), _class.prototype)), _class));
  _exports.default = GoogleGtagService;
});
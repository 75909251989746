define("shop/pods/shop/influencer/expired/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KC5oPBez",
    "block": "[[[10,0],[14,0,\"container margin-top--20 checkout-form\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"your login link is no longer valid\"],null]],[1,\"\\n\"],[13]],[],false,[\"tt\"]]",
    "moduleName": "shop/pods/shop/influencer/expired/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/shop/checkout/summary-page/component", ["exports", "@ember/component", "@ember/service", "shop/mixins/automations", "shop/mixins/checkout", "ember-concurrency", "jquery", "shop/utils/nventor", "ramda"], function (_exports, _component, _service, _automations, _checkout, _emberConcurrency, _jquery, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import * as R from 'ramda'
  var _default = _component.default.extend(_automations.default, _checkout.default, {
    tagName: '',
    router: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    googleService: (0, _service.inject)('google'),
    websiteService: (0, _service.inject)('website'),
    showPromoCode: false,
    hasNavBar: true,
    autoValidate: false,

    init() {
      this._super(...arguments);

      _nventor.default.goToTop();

      this.setupTask.perform();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      if (this.isSummaryStep) {
        yield this.setupUser.perform({
          initiateCheckout: true
        });
      } // should set rewardPointApplied to 0 if back to summary to enable promoCode if but reward cannot applied with promoCode


      if (this.get('isSummaryStep') || this.get('isDetailStep')) {
        this.checkoutData.set('rewardPointApplied', '');
      }

      if (!this.get('isSummaryStep')) {
        const memberId = this.get('website.user._data.memberId');
        this.get('websiteService').get('logTask').perform({
          event: 'view',
          eventType: 'view',
          memberId
        });
      }
    }),

    hasIsMustAddChoicesNotYetChosen() {
      const shop = this.shop;
      const automations = shop.get('automationsForIsMustAddAndChooseBetween');
      const allComplete = R.map(automation => {
        return automation.get('isComplete') || false;
      })(automations) || [];
      return R.includes(false)(allComplete);
    },

    focusOnErrorInput(goToErrorInput) {
      const goToOffset = (0, _jquery.default)(this.element).find(`.${goToErrorInput}`).offset();

      if (goToOffset) {
        (0, _jquery.default)('html, body').animate({
          scrollTop: (0, _jquery.default)(this.element).find(`.${goToErrorInput}`).offset().top - 120
        }, 200);
        (0, _jquery.default)(this.element).find(`.${goToErrorInput}`).find('input').select();
      }
    },

    // applyPromoCodeTask: task(function * () {
    //   const user = this.user
    //   const shop = this.shop
    //   const hasAddress = this.hasAddress
    //   const checkoutData = this.checkoutData
    //   if (this.get('checkoutData.promoCode')) {
    //     this.set('hasPromoCodeError', false)
    //     const promoCode = this.checkoutData?.promoCode || ''
    //     yield this.shopService.get('validateCartItemsTask').perform({ shop, user, hasAddress, checkoutData })
    //     if (shop.get('discountAmount')) {
    //       this.set('hasPromoCode', true)
    //       this.storage.setValueWithShopKey('checkoutData', checkoutData)
    //     } else {
    //       this.shopService.setPromoCode('')
    //       this.checkoutData.set('promoCode', '')
    //       this.set('incorrectPromoCode', promoCode)
    //       this.set('hasPromoCodeError', true)
    //     }
    //   }
    // }).drop(),
    // clearPromoCodeTask: task(function * () {
    //   // const promoCode = this.checkoutData.promoCode || ''
    //   // if (promoCode) {
    //   nventor.removeAndUpdateQueryParams(['promo', ''])
    //   this.shopService.setPromoCode('')
    //   this.checkoutData.set('promoCode', '')
    //   this.storage.setValueWithShopKey('checkoutData', this.checkoutData)
    //   this.shopService.get('validateCartItemsTask').perform({
    //     shop: this.shop,
    //     user: this.user,
    //     hasAddress: this.hasAddress,
    //     checkoutData: this.checkoutData
    //   })
    //   this.websiteService.clearMessages()
    //   this.set('hasPromoCode', false)
    //   // }
    // }).drop(),
    actions: {
      // toggleHasPromoCode () {
      //   this.toggleProperty('showPromoCode')
      // },
      back() {
        this.router.transitionTo('shop');
      },

      next() {
        var _website$_data, _website$_data2;

        const hasEmptyChoices = this.hasIsMustAddChoicesNotYetChosen();

        if (hasEmptyChoices) {
          const msg = this.intl.t('please make a selection');
          window.alert(msg);

          _nventor.default.goToClassName('must-add-choices', -90);

          return false;
        }

        const website = this.website; // Set FB Pixel tracker for initiate checkout

        this.websiteService.fbPixelTrack('initiateCheckout', this.shop); // Track Initial Checkout Step

        this.googleService.checkoutStep({
          step: 1,
          shop: this.shop
        });
        const token = this.membersService.getToken(website);

        if (token) {
          return this.router.transitionTo('shop.checkout.details');
        }

        if (website !== null && website !== void 0 && (_website$_data = website._data) !== null && _website$_data !== void 0 && _website$_data.hideFacebookLogin && website !== null && website !== void 0 && (_website$_data2 = website._data) !== null && _website$_data2 !== void 0 && _website$_data2.hideLineLogin) {
          return this.router.transitionTo('shop.checkout.details');
        }

        return this.router.transitionTo('shop.members');
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/items/item-page/components-group-thumb/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4mhxs8D8",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[16,1,[30,0,[\"id\"]]],[16,0,[29,[\"components-group checkout-form__extra-max-width\\n    \",[52,[30,0,[\"isScrollable\"]],\"components-group--grid-thumbs\"]]]],[17,2],[12],[1,\"\\n    \"],[11,0],[16,1,[29,[[28,[37,1],[[30,0,[\"id\"]],\"-content\"],null]]]],[16,0,[29,[\"has-animation-slow components-group-content \",[52,[51,[30,0,[\"isScrollable\"]]],\"flex-container--justify-center\"],\" \",[52,[30,0,[\"isGrid\"]],\"components-group-content--grid\"],\" \",[30,3]]]],[4,[38,3],[[30,0,[\"setScroll\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"        \"],[18,4,[[30,0,[\"setScroll\"]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isScrollable\"]],[[[41,[51,[30,0,[\"hideLeft\"]]],[[[1,\"        \"],[11,3],[24,0,\"button slider-button components-group-controls-thumbs components-group-button-left-thumbs\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"moveHorizontally\"]],\"left\"],null]],null],[12],[1,\"\\n          \"],[10,1],[14,0,\"icon\"],[12],[1,\"\\n            \"],[10,\"i\"],[14,0,\"fas fa-angle-left\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isScrollable\"]],[[[41,[51,[30,0,[\"hideRight\"]]],[[[1,\"        \"],[11,3],[24,0,\"button slider-button components-group-controls-thumbs components-group-button-right-thumbs\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"moveHorizontally\"]],\"right\"],null]],null],[12],[1,\"\\n          \"],[10,1],[14,0,\"icon\"],[12],[1,\"\\n            \"],[10,\"i\"],[14,0,\"fas fa-angle-right\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,4,null],[1,\"\\n\"]],[]]]],[\"@hasThumbsSlider\",\"&attrs\",\"@groupContent\",\"&default\"],false,[\"if\",\"concat\",\"unless\",\"did-resize\",\"has-block\",\"yield\",\"on\",\"fn\"]]",
    "moduleName": "shop/pods/components/items/item-page/components-group-thumb/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
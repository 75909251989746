define("shop/pods/components/shop/block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "blucfX3t",
    "block": "[[[41,[30,0,[\"hasTemplateToDisplay\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@loadCssRaw\",\"@templateHtml\",\"@loadPageDetails\",\"@page\",\"@pageComponent\",\"@shop\",\"@website\"],[[30,0,[\"advancedTemplateCss\"]],[30,0,[\"advancedTemplateHtml\"]],[30,0,[\"advancedTemplate\"]],[30,1],[30,2],[30,3],[30,4]]],null],[1,\"\\n\"]],[]],null]],[\"@page\",\"@pageComponent\",\"@shop\",\"@website\"],false,[\"if\",\"website/website-page\"]]",
    "moduleName": "shop/pods/components/shop/block/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/initializers/website-model", ["exports", "shop/pods/website/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('website:main', _model.default);
  }

  var _default = {
    name: 'website-model',
    initialize
  };
  _exports.default = _default;
});
define("shop/pods/components/elements/drawer/drawer-desktop/component", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    websiteService: (0, _service.inject)('website'),
    helpers: (0, _service.inject)(),
    router: (0, _service.inject)(),
    tagName: '',
    closeOnOverlayClick: true,
    drawerCardClassName: (0, _object.computed)('drawerCardClass', 'websiteService.isMobile', function () {
      const classNames = ['modal-card', 'cart-modal-card', 'modal-card--fullscreen'];
      const drawerCardClass = this === null || this === void 0 ? void 0 : this.drawerCardClass;

      if (drawerCardClass) {
        classNames.push(drawerCardClass);
      }

      classNames.push('modal-card--not-mobile');
      return classNames.join(' ');
    })
  });

  _exports.default = _default;
});
define("shop/pods/components/cart/qty-selector-set-choice-variant/qty-selector-variant/component", ["exports", "ramda", "@ember/component", "@ember/object"], function (_exports, R, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    lastQty: '',

    init() {
      this._super(...arguments);
    },

    showQtySelectorVariant: (0, _object.computed)('variantChoice.selectedVariantChild.item', 'qty', function () {
      var _variantChoice$select;

      const variantChoice = this.variantChoice || {};
      const selectedVariantName = (variantChoice === null || variantChoice === void 0 ? void 0 : (_variantChoice$select = variantChoice.selectedVariantChild) === null || _variantChoice$select === void 0 ? void 0 : _variantChoice$select.item) || '';

      if (!R.isEmpty(selectedVariantName) && this.qty > 0) {
        return true;
      }

      return false;
    }),
    disableSelectQty: (0, _object.computed)('variantChoice.{qty,selectedVariantChild}', 'choice.isGroupComplete', function () {
      if (this.get('choice.isGroupComplete')) {
        return true;
      }

      if (this.get('variantChoice.selectedVariantChild')) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});
define("shop/pods/components/helpers/tooltip-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7AHI2bPi",
    "block": "[[[10,0],[15,0,[29,[[52,[33,1],\"tooltip\"]]]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"if\",\"hasTooltip\",\"yield\"]]",
    "moduleName": "shop/pods/components/helpers/tooltip-container/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/campaign/route", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ajax: (0, _service.inject)(),

    // fastboot: service(),
    model() {
      const shopData = {
        _data: {
          bankingDetails: '玉山銀行-北屯分行(銀行代號：808)<br/>\n戶名：大晴洋行有限公司<br/>\n帳號：1388940-000501<br/>',
          companyName: 'Go Wild',
          companyTel: '(04) 2422-8806',
          emailFromAddress: 'info@gowild.tw',
          'dateStartZ': '2018-04-08T16:00:00.000Z',
          'dateEndZ': '2018-04-14T16:00:00.000Z',
          'title': '維媽GoWild野餐團',
          'description': '風格野餐必備好物~限時開團'
        }
      };
      return shopData;
    }

  });

  _exports.default = _default;
});
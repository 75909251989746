define("shop/pods/components/layouts/message-chips/chip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y3sERt27",
    "block": "[[[11,0],[16,0,[29,[\"message-chip-container is-tappable  \",[52,[30,1,[\"autoClose\"]],\"is-auto-closing\"]]]],[16,5,[30,0,[\"autoCloseTimeStyle\"]]],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"closeChip\"]],[30,1]],null]],null],[4,[38,3],[[28,[37,2],[[30,0,[\"didInsert\"]]],null]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"message-chip__button\"],[12],[1,\"\\n\"],[41,[30,1,[\"autoClose\"]],[[[1,\"      \"],[10,0],[14,0,\"message-chip__auto-closing-progress-bar\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"message-chip__text\"],[12],[1,\"\\n      \"],[1,[30,1,[\"message\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"message-chip__close-btn\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@icon\"],[\"fas fa-times-circle\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@chip\"],false,[\"if\",\"on\",\"fn\",\"did-insert\",\"elements/element-icon\"]]",
    "moduleName": "shop/pods/components/layouts/message-chips/chip/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/items/item-set-with-choices/item-choice-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yFAmVhCw",
    "block": "[[[6,[39,0],null,[[\"classNames\"],[\"profile-container\"]],[[\"default\"],[[[[6,[39,1],null,[[\"isSticky\",\"translate\",\"classNames\"],[true,false,\"store-item__choice-header\"]],[[\"default\"],[[[[1,\"    \"],[10,2],[14,0,\"card-header-title\"],[12],[1,\"\\n      \"],[1,[33,2,[\"_data\",\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[1,\"    \"],[10,1],[14,0,\"btns-group__item\"],[12],[1,\"\\n\"],[1,\"\\n\"],[41,[33,2,[\"isGroupComplete\"]],[[[1,\"        \"],[10,1],[14,0,\"has-text-success\"],[12],[1,\"\\n          \"],[8,[39,4],null,[[\"@icon\"],[\"fas fa-check-circle\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,1],[14,0,\"tag\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"chosen qty\"],null]],[1,\": \"],[1,[28,[35,6],[[33,2,[\"totalSelectedQty\"]]],null]],[1,\" / \"],[1,[33,2,[\"_data\",\"qty\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,7],null,null,[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"has-text-left\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[33,2,[\"_data\",\"details\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,10],null,[[\"groupSelectedData\",\"choice\",\"hasPriceRange\",\"shop\",\"item\",\"index\",\"onNewVariantChoice\",\"onUpdateGroupSelectedQty\"],[[33,11],[33,2],[33,12],[33,13],[30,1],[33,14],[28,[37,15],[[30,0],[33,16],[30,1]],null],[33,17]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"  \\n\"]],[]]]]]],[]]]]],[1,\"\\n\"]],[\"child\"],false,[\"layouts/page-card\",\"layouts/page-card/card-header\",\"choice\",\"if\",\"elements/element-icon\",\"tt\",\"number\",\"layouts/page-card/card-content\",\"each\",\"-track-array\",\"items/item-set-with-choices/item-choice-group/item-choice\",\"groupSelectedData\",\"hasPriceRange\",\"shop\",\"index\",\"action\",\"onNewVariantChoice\",\"onUpdateGroupSelectedQty\"]]",
    "moduleName": "shop/pods/components/items/item-set-with-choices/item-choice-group/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
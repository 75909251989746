define("shop/helpers/ramda", ["exports", "@ember/component/helper", "shop/mixins/big", "ramda"], function (_exports, _helper, _big, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend(_big.default, {
    compute(params, hash = {}) {
      const fnName = params[0];
      const args = R.drop(1, params);

      try {
        return R.apply(R[fnName], args);
      } catch (e) {
        return R.prop('defaultValue')(hash) || '';
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/orders/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jv67Zv6/",
    "block": "[[[8,[39,0],null,[[\"@website\",\"@shop\",\"@params\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"params\"]]]],null],[1,\"\\n\"]],[],false,[\"shop/orders-query\"]]",
    "moduleName": "shop/pods/orders/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/items/go-to-automation-detail/component", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @DEPRECATED
  var _default = _component.default.extend({
    tagName: 'span',
    isLink: true,
    router: (0, _service.inject)(),
    helpers: (0, _service.inject)(),
    routePath: 'shop.detail',
    itemNameUrl: (0, _object.computed)('itemName', function () {
      return this.helpers.beautifyUrl(this.itemName);
    }),
    actions: {
      goTo(itemKey, itemNameUrl, automationKey) {
        const routePath = this.routePath;
        return this.router.transitionTo(routePath, itemKey, itemNameUrl, automationKey);
      }

    }
  });

  _exports.default = _default;
});
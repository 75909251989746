define("shop/pods/components/shop/orders-query/orders-status/order-display/details/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ShopOrdersQueryOrdersStatusOrderDisplayDetailsComponent extends _component.default {}

  _exports.default = ShopOrdersQueryOrdersStatusOrderDisplayDetailsComponent;
});
define("shop/pods/components/blogs/blog-card/mobile/blog-card-style2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c84ChTyt",
    "block": "[[[8,[39,0],null,[[\"@blog\",\"@website\",\"@imagesHoverFilter\",\"@imagesHoverTransform\",\"@blogNameUrl\",\"@blogsFontSize\",\"@blogsFontAlignment\",\"@blogsLineCountForTitle\",\"@blogsLineCountForDescription\",\"@isGrid\",\"@isFullWidth\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11]]],null],[1,\"\\n\"]],[\"@blog\",\"@website\",\"@imagesHoverFilter\",\"@imagesHoverTransform\",\"@blogNameUrl\",\"@blogsFontSize\",\"@blogsFontAlignment\",\"@blogsLineCountForTitle\",\"@blogsLineCountForDescription\",\"@isGrid\",\"@isFullWidth\"],false,[\"blogs/blog-card/mobile/blog-card-style1\"]]",
    "moduleName": "shop/pods/components/blogs/blog-card/mobile/blog-card-style2/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
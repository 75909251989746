define("shop/helpers/isBeforeDate", ["exports", "ramda", "@ember/component/helper", "@ember/service"], function (_exports, R, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    dateService: (0, _service.inject)('date'),

    compute(params) {
      const date1 = params[0];
      const date2 = params[1] || this.dateService.getMoment();

      try {
        const date1Moment = this.dateService.getMoment(date1);
        return date1Moment.isBefore(date2);
      } catch (e) {
        return false;
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/elements/element-select/select-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4EbwjM9m",
    "block": "[[[10,\"option\"],[15,2,[28,[37,0],[[30,1],[30,2]],null]],[15,\"selected\",[52,[28,[37,2],[[28,[37,0],[[30,1],[30,2]],null],[30,3]],null],\"selected\"]],[12],[1,\"\\n  \"],[1,[28,[35,3],[[28,[37,0],[[30,1],[30,4]],null]],[[\"translate\"],[[30,0,[\"hasTranslate\"]]]]]],[1,\" \"],[1,[30,0,[\"suffixLabel\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@option\",\"@optionValueKey\",\"@value\",\"@optionLabelKey\"],false,[\"get\",\"if\",\"eq\",\"tt\"]]",
    "moduleName": "shop/pods/components/elements/element-select/select-option/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/header/service", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object", "@ember/runloop", "shop/utils/nventor", "ramda"], function (_exports, _service, _tracking, _object, _runloop, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let HeaderService = (_dec = (0, _service.inject)('website'), (_class = class HeaderService extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "navBarHeight", _descriptor, this);

      _initializerDefineProperty(this, "disableHideLogoAndName", _descriptor2, this);

      _initializerDefineProperty(this, "websiteService", _descriptor3, this);

      this.setNavBarHeight();
    }

    get navBarHeightPlusTimer() {
      let topAdjustment = this.navBarHeight;
      const timer = document.querySelector('.timer-container');

      if (timer) {
        const timerHeight = (timer === null || timer === void 0 ? void 0 : timer.clientHeight) || 0;
        topAdjustment += timerHeight;
      }

      return Math.floor(topAdjustment);
    }

    get navBarHeightPlusTimerPlusTabsOnTopContainer() {
      let topAdjustment = this.navBarHeightPlusTimer;
      const tabsOnTop = document.querySelector('.tabs-on-top-container');

      if (tabsOnTop) {
        const tabsOnTopHeight = (tabsOnTop === null || tabsOnTop === void 0 ? void 0 : tabsOnTop.clientHeight) || 0;
        topAdjustment += tabsOnTopHeight;
      }

      return Math.floor(topAdjustment);
    }

    getNavBarHeight() {
      if (this.websiteService.isShowDrawerForShopItem) {
        const navbarHeader = document.querySelector('.modal-card--fullscreen .my-navbar-header');

        if (navbarHeader) {
          return (navbarHeader === null || navbarHeader === void 0 ? void 0 : navbarHeader.clientHeight) || 0;
        }

        const navbarWithoutSearch = document.querySelector('.my-navbar-container');
        return (navbarWithoutSearch === null || navbarWithoutSearch === void 0 ? void 0 : navbarWithoutSearch.clientHeight) || 0;
      }

      const navbarHeader = document.querySelector('.my-navbar-header');
      return (navbarHeader === null || navbarHeader === void 0 ? void 0 : navbarHeader.clientHeight) || 0;
    }

    setNavBarHeight() {
      let navBarHeight = this.getNavBarHeight(); // for the demical point in height

      this.navBarHeight = Math.floor(navBarHeight);
    }

    trackNavBarHeight() {
      // Handle On NavBar Image Height Change

      /** @type {HTMLImageElement} */
      const navBarImg = document.querySelector('.my-navbar-header a.logo-text img');

      if (!navBarImg || navBarImg.onload) {
        this.setNavBarHeight();
        this.hideLogoAndName();
        return;
      }

      navBarImg.onload = () => {
        this.setNavBarHeight();
        this.hideLogoAndName();
      };
    } // shrink or hide logo and company name while scroll down


    hideLogoAndName() {
      var _this$websiteService, _websiteModel$_data;

      const websiteModel = (_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : _this$websiteService.website; // if menu at the center?

      const isSeparateLine = (websiteModel === null || websiteModel === void 0 ? void 0 : (_websiteModel$_data = websiteModel._data) === null || _websiteModel$_data === void 0 ? void 0 : _websiteModel$_data.logoMenuPosition) || '';

      if (isSeparateLine) {
        window.addEventListener('scroll', () => {
          (0, _runloop.debounce)(this, this.onScroll, 10);
          this.setNavBarHeight();
        });

        if (this.websiteService.isShowDrawerForShopItem) {
          const modalCardBody = document.querySelector('.modal-card-body');

          if (modalCardBody) {
            modalCardBody.addEventListener('scroll', () => {
              (0, _runloop.debounce)(this, this.onScroll, 10);
              this.setNavBarHeight();
            });
          }
        }
      }
    }

    setPositionForElement({
      className,
      style
    }) {
      const element = document.querySelector(className);

      if (_nventor.default.isNilOrEmpty(element)) {
        return;
      }

      R.forEachObjIndexed((value, key) => {
        element.style[key] = value;
      })(style);
    } // @dropTask
    // * onScrollTask () {


    onScroll() {
      var _this$websiteService2, _websiteModel$_data2;

      if (this.disableHideLogoAndName) {
        return;
      }

      const websiteModel = (_this$websiteService2 = this.websiteService) === null || _this$websiteService2 === void 0 ? void 0 : _this$websiteService2.website;
      const method = (websiteModel === null || websiteModel === void 0 ? void 0 : (_websiteModel$_data2 = websiteModel._data) === null || _websiteModel$_data2 === void 0 ? void 0 : _websiteModel$_data2.logoCenteredScrollMethod) || '';

      if (!method) {
        return;
      }

      const navbarContainer = document.querySelector('.my-navbar-outer-container');
      const logoWithName = document.querySelector('.nav-logo-container');
      const WebsiteName = document.querySelector('.navbar-website-name');

      if (!logoWithName) {
        return;
      }

      if (!WebsiteName) {
        return;
      } //set class names for each method


      const navClassName = 'nav-container-smaller';
      const navLogoReveal = 'nav-logo--reveal';
      const navNameHide = 'nav-name--hidden';
      let className = '';

      if (method === 'shrink') {
        className = 'nav-logo--shrink';
      } else if (method === 'hide') {
        className = 'nav-logo--hidden';
      } // const sticky = navbarContainer.offsetTop


      const scrollThreshold = 100;
      let didHide = false;

      if (window.scrollY > scrollThreshold) {
        didHide = true;
        logoWithName.classList.add(className);
        logoWithName.classList.remove(navLogoReveal);
        WebsiteName.classList.add(navNameHide);
        navbarContainer.classList.add(navClassName);
      } else if (window.scrollY <= 0) {
        didHide = false;
        logoWithName.classList.add(navLogoReveal);
        logoWithName.classList.remove(className);
        navbarContainer.classList.remove(navClassName);
        WebsiteName.classList.remove(navNameHide);
      }

      if (didHide && window.scrollY <= 5) {
        this.disableHideLogoAndName = true;
        setTimeout(() => {
          this.disableHideLogoAndName = false;
        }, 500);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "navBarHeight", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "disableHideLogoAndName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setNavBarHeight", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setNavBarHeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackNavBarHeight", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "trackNavBarHeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideLogoAndName", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideLogoAndName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPositionForElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setPositionForElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onScroll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onScroll"), _class.prototype)), _class));
  _exports.default = HeaderService;
});
define("shop/pods/components/chat-btn/line-chat-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hKggTGNx",
    "block": "[[[41,[30,1,[\"hasLineChatEnabled\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@link\",\"@linkClassNames\"],[[30,1,[\"hasLineChatEnabled\"]],\"flex-container-row\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"      \"],[18,3,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],[[16,0,[29,[\"chat-options__btn \",[52,[30,2],\"is-mobile\"]]]]],[[\"@icon\"],[\"fab fa-line\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@website\",\"@isMobile\",\"&default\"],false,[\"if\",\"helpers/new-browser-tab-link\",\"has-block\",\"yield\",\"elements/element-icon\"]]",
    "moduleName": "shop/pods/components/chat-btn/line-chat-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
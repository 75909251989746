define("shop/pods/components/layouts/page-steps/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',
    classNames: 'page-steps-container',
    translate: true
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/filters/filters-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GxgZYy3k",
    "block": "[[[41,[28,[37,1],[[33,2],[33,3]],null],[[[1,\"  \"],[10,0],[14,0,\"filters-section__header\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[[33,2]],[[\"translate\"],[[33,5]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[11,0],[24,0,\"filters-section__content u-margin-bottom-important-0\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"goToItemsContainerTop\"]]],null]],null],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"if\",\"and\",\"header\",\"hasCatergories\",\"tt\",\"translate\",\"on\",\"fn\",\"yield\"]]",
    "moduleName": "shop/pods/components/layouts/filters/filters-section/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/website/subscribe/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4MOg80QW",
    "block": "[[[8,[39,0],null,[[\"@page\",\"@params\",\"@query\",\"@shop\",\"@website\"],[[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"params\"]],[30,0,[\"model\",\"params\",\"query\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"container checkout-form\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"card-header-title\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"subscribe mailing list\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"website/website-container\",\"layouts/page-content\",\"tt\"]]",
    "moduleName": "shop/pods/website/subscribe/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
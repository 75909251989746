define("shop/pods/shop/members/member/route", ["exports", "@ember/routing/route", "shop/pods/shop/shop-route-mixin"], function (_exports, _route, _shopRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_shopRouteMixin.default, {
    queryParams: {
      from: {},
      edit: {}
    },

    model(params) {
      const requireMember = true;
      const website = this.modelFor('application').website;
      const shop = this.modelFor('application').shop; // @NOTE: redirect for failed scheduled payment

      const externalUrlForScheduledPayment = this.storage.getValueWithShopKey('externalUrlForScheduledPayment');

      if (externalUrlForScheduledPayment) {
        this.storage.removeWithShopKey('externalUrlForScheduledPayment');
        return this.transitionTo('erp.payments.card.doc', externalUrlForScheduledPayment);
      }

      return this._model(params, {
        requireMember
      });
    }

  });

  _exports.default = _default;
});
define("shop/pods/shop/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cGvTr9Z+",
    "block": "[[[8,[39,0],null,[[\"@breadcrumbModel\",\"@isIndex\",\"@isProduct\",\"@item\",\"@page\",\"@params\",\"@query\",\"@shop\",\"@website\",\"@isCampaign\",\"@showAsModal\"],[[30,0,[\"model\",\"item\"]],false,true,[30,0,[\"model\",\"item\"]],[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"parentParams\"]],[30,0,[\"model\",\"parentParams\",\"query\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"isCampaign\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n\\n\"],[41,[28,[37,2],[[30,2,[\"hasTimer\"]],[28,[37,3],[[30,0,[\"model\",\"shop\",\"hasExpiredGracefully\"]]],null]],null],[[[1,\"    \"],[8,[39,4],null,[[\"@dateEndZ\"],[[30,0,[\"model\",\"shop\",\"_data\",\"dateEndZ\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container max-content-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"max-content-container\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@website\",\"@shop\",\"@item\",\"@tags\",\"@query\",\"@selectedVariantKeyByUrlParam\",\"@showAsModal\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"item\"]],[30,0,[\"model\",\"parentParams\",\"tag\"]],[30,0,[\"model\",\"parentParams\",\"query\"]],[30,0,[\"model\",\"params\",\"variant\"]],[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@showAsModal\",\"websiteContainer\"],false,[\"website/website-container\",\"if\",\"and\",\"not\",\"countdown-timer\",\"items/item-page\"]]",
    "moduleName": "shop/pods/shop/item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
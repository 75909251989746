define("shop/pods/components/layouts/lazy-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aNeNrcC1",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"flex-container-row flex-container--justify-center\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"hasMore\",\"onLazyLoadTask\"],[[33,3],[33,4]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"isViewable\",\"layouts/lazy-loader/lazy-loader-btn\",\"hasMore\",\"onAutoLazyLoadTask\"]]",
    "moduleName": "shop/pods/components/layouts/lazy-loader/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/nav-menu-nested/nav-nested/component", ["exports", "@ember/component", "@ember/object", "jquery"], function (_exports, _component, _object, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',
    classNames: ['nav-nested'],
    classNameBindings: ['isSub:is-sub'],
    translate: true,
    item: null,
    isExpanded: false,
    childIsExpanded: false,
    disabled: false,
    autoPosition: true,
    count: 0,

    init() {
      this._super(...arguments);

      if (this.initialIsExpanded) {
        this.set('isExpanded', true);
      }
    },

    didInsertElement() {
      const navItemWidth = (0, _jquery.default)(this.element).outerWidth();
      this.set('navItemWidth', navItemWidth);
    },

    hasExpandOnHover: (0, _object.computed)('isVertical', 'isSub', 'isFooter', function () {
      if (this.isHorizontal && this.isSub) {
        return false;
      }

      if (!this.isVertical && !this.isFooter) {
        return true;
      }

      return false;
    }),

    mouseEnter() {
      if (this.hasExpandOnHover) {
        this.set('isExpanded', true);
        const searchFilter = document.querySelector('.nav-menu__search-filter-container');

        if (searchFilter) {
          searchFilter.style.display = 'none';
        }
      }
    },

    mouseLeave() {
      if (!this.initialIsExpanded) {
        if (this.hasExpandOnHover) {
          this.set('isExpanded', false);
          const searchFilter = document.querySelector('.nav-menu__search-filter-container');

          if (searchFilter) {
            searchFilter.style.display = '';
          }
        }
      }
    },

    actions: {
      toggleMenu() {
        if (!this.initialIsExpanded) {
          this.toggleProperty('isExpanded');
        }
      }

    }
  });

  _exports.default = _default;
});
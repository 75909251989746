define("shop/pods/shop/orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qtSXJY45",
    "block": "[[[8,[39,0],null,[[\"@website\",\"@page\",\"@shop\",\"@params\",\"@query\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"params\"]],[30,0,[\"model\",\"params\",\"query\"]]]],[[\"default\"],[[[[1,\"\\n\\n\"],[41,[28,[37,2],[[30,1,[\"hasTimer\"]],[28,[37,3],[[30,0,[\"model\",\"shop\",\"hasExpiredGracefully\"]]],null]],null],[[[1,\"    \"],[8,[39,4],null,[[\"@dateEndZ\"],[[30,0,[\"model\",\"shop\",\"_data\",\"dateEndZ\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\"]],[\"websiteContainer\"],false,[\"website/website-container\",\"if\",\"and\",\"not\",\"countdown-timer\",\"layouts/page-content\",\"component\",\"-outlet\"]]",
    "moduleName": "shop/pods/shop/orders/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
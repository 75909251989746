define("shop/pods/shop/members/reset-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mB7Nt7nd",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"container checkout-form\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@website\"],[[30,0,[\"model\",\"website\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[],false,[\"members/reset-password-page\"]]",
    "moduleName": "shop/pods/shop/members/reset-password/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
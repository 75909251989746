define("shop/helpers/htmlSafe", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    compute(params, hash) {
      const html = params[0];
      const additionalClass = (hash === null || hash === void 0 ? void 0 : hash.class) || '';

      try {
        return (0, _template.htmlSafe)(`<div class='content-display ${additionalClass}'>${html}</div>`);
      } catch (e) {
        return '';
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/cart/content-summary/detail-line/component", ["exports", "@ember/component", "@ember/object", "ramda", "shop/utils/nventor"], function (_exports, _component, _object, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    showDiscountModal: false,
    isDiscountModalVisible: (0, _object.computed)('showDiscountModal', function () {
      return this.showDiscountModal;
    }),
    images: (0, _object.computed)('cartItem.{uploadedFiles.[],isVariantChildOf}', function () {
      let images = this.get('cartItem.uploadedFiles') || [];

      if (this.get('cartItem.isVariantChildOf')) {
        images = this.get('cartItem.parent.uploadedFiles') || [];
      }

      return images.sortBy('timestampZ');
    }),
    cartItemLabel: (0, _object.computed)('cartItem.{isVariantChildOf,item,parent.item}', function () {
      if (this.get('cartItem.isVariantChildOf')) {
        return this.get('cartItem.parent.item');
      }

      return this.get('cartItem.item');
    }),
    cartItemLabelWithPreOrderDesc: (0, _object.computed)('cartItem.{isVariantChildOf,item,parent.item,salesPreOrderLimitedQtyDesc}', function () {
      var _this$cartItem;

      const label = this.cartItemLabel || '';
      let salesPreOrderLimitedQtyDesc = (this === null || this === void 0 ? void 0 : (_this$cartItem = this.cartItem) === null || _this$cartItem === void 0 ? void 0 : _this$cartItem.salesPreOrderLimitedQtyDesc) || '';

      if (salesPreOrderLimitedQtyDesc && !this.isFutureRecurringOrderDetail) {
        salesPreOrderLimitedQtyDesc = ` - ${salesPreOrderLimitedQtyDesc}`;
      }

      return label + salesPreOrderLimitedQtyDesc;
    }),
    cartItemKey: (0, _object.computed)('cartItem.{isVariantChildOf,itemKey,parent.itemKey}', function () {
      if (this.get('cartItem.isVariantChildOf')) {
        return this.get('cartItem.parent.itemKey');
      }

      return this.get('cartItem.itemKey');
    }),
    defaultImage: (0, _object.computed)('images.@each.isDefault', 'cartItem.{defaultImage,parent.defaultImage}', function () {
      const images = this.images || [];
      const defaultImageData = R.find(R.propEq('isDefault', true))(images);

      if (defaultImageData) {
        return defaultImageData;
      }

      if (images.get('firstObject')) {
        const firstImage = images.get('firstObject');

        if (firstImage) {
          return firstImage;
        }
      }

      return false;
    }),
    inclAmt: (0, _object.computed)('cartItem.qty', function () {
      const cartItem = this.cartItem;
      return this.shop.calculateCartLineAmt(cartItem);
    }),
    hasBulkDiscountData: (0, _object.computed)('cartItem.bulkDiscountData', function () {
      var _this$cartItem2;

      const bulkDiscountData = (this === null || this === void 0 ? void 0 : (_this$cartItem2 = this.cartItem) === null || _this$cartItem2 === void 0 ? void 0 : _this$cartItem2.bulkDiscountData) || {};

      if (!R.isEmpty(bulkDiscountData)) {
        return true;
      }

      return false;
    }),
    showCartItemWarning: (0, _object.computed)('cartItem.isNotAllowedReason', function () {
      const isNotAllowedReason = this.get('cartItem.isNotAllowedReason') || [];
      const hasOverLimitedQtyPerCheckout = R.pipe(R.find(R.equals('over limited qty per checkout')), _nventor.default.isNotNilOrEmpty)(isNotAllowedReason);

      if (hasOverLimitedQtyPerCheckout) {
        return true;
      }

      const hasOverLimitedQty = R.pipe(R.find(R.equals('over limited qty')), _nventor.default.isNotNilOrEmpty)(isNotAllowedReason);

      if (hasOverLimitedQty) {
        return true;
      }

      return false;
    }),
    actions: {
      updateCartItemQty(group, cartItem, qty) {
        this.shop.updateCartItemQty({
          group,
          cartItem,
          qty
        });

        if (this.onCartChanged) {
          this.onCartChanged();
        }
      },

      onClickShowDiscountModal() {
        (0, _object.set)(this, 'showDiscountModal', true);
      },

      onClickCloseDiscountDialog() {
        (0, _object.set)(this, 'showDiscountModal', false);
      }

    }
  });

  _exports.default = _default;
});
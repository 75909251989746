define("shop/pods/components/layouts/message-card/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class LayoutsMessageCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "translate", R.isNil(this.args.translate) ? true : this.args.translate);

      _defineProperty(this, "BigordrLogo", `<a class='u-margin-right-2 u-margin-left-2 bigordr-link' rel="{{if this.websiteService.isBigOrdr '' 'nofollow'}}" href='https://bigordr.com/'>
    <div class='bigordr-logo logo-inline'>
      <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><defs></defs><path class="b" d="M75.54,20.36c-30.15-.3-54.88,23.94-55.18,54.09-.3,30.15,23.94,54.88,54.09,55.18,30.15,.3,54.88-23.94,55.18-54.09,.3-30.15-23.94-54.88-54.09-55.18Z"/><g><path class="c" d="M75.65,9.67C39.59,9.31,10.03,38.3,9.67,74.35c-.36,36.06,28.63,65.62,64.68,65.98,36.06,.36,65.62-28.62,65.98-64.68,.36-36.06-28.62-65.62-64.68-65.98Zm8.23,96.96c-2.76,2.36-5.85,3.99-9.28,4.89-3.74,.86-8.42,1.27-14.04,1.21l-10.23-.1c-2.28-.02-4.46-.98-6.05-2.65-1.6-1.67-2.48-3.93-2.46-6.27l.29-28.87v-.53l.29-28.87c.02-2.34,.92-4.58,2.51-6.22,1.58-1.64,3.71-2.55,5.93-2.53l9.93,.1c5.46,.05,9.99,.55,13.61,1.48,3.31,.96,6.28,2.65,8.91,5.07,4.38,3.92,6.54,8.69,6.49,14.3-.04,4.28-1.32,8.02-3.84,11.22-1.99,2.59-4.55,4.54-7.67,5.86,3.69,1.39,6.64,3.54,8.85,6.45,2.53,3.25,3.77,7.02,3.73,11.3-.06,5.61-2.38,10.34-6.97,14.17Zm25.19-5.04c-1.1,1.28-2.66,1.92-4.23,1.91-1.26-.01-2.51-.45-3.54-1.34-2.3-1.99-2.56-5.46-.57-7.77,7.25-8.41,9.43-17.39,6.48-26.71-2.24-7.07-6.65-11.78-6.69-11.83-2.11-2.19-2.04-5.68,.15-7.79,2.19-2.11,5.68-2.04,7.79,.15,.25,.26,6.21,6.53,9.25,16.13,2.88,9.08,3.72,22.92-8.64,37.24Z"/><path class="c" d="M74.67,84.06c-2.58-2.1-7.2-3.19-13.89-3.26l-6.29-.06-.22,21.73,7.68,.08c5.62,.06,9.75-.86,12.4-2.76,2.79-2.05,4.21-4.67,4.24-7.87,.03-3.1-1.28-5.72-3.92-7.86Z"/><path class="c" d="M73.9,65.71c2.61-2.08,3.93-4.68,3.96-7.78s-1.29-5.84-3.96-7.95c-2.54-1.95-6.53-2.95-11.99-3l-7.46-.07-.22,21.73,6.11,.06c6.49,.06,11.01-.93,13.55-2.98Z"/></g></svg> 
    </div>
    <div class='u-margin-left-2'>Bigordr</div>
  </a>`);
    }

  }

  _exports.default = LayoutsMessageCardComponent;
});
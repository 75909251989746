define("shop/pods/components/elements/drawer/drawer-desktop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eEl36IgC",
    "block": "[[[10,0],[14,0,\"modal cart-content-modal is-active modal-animate__animated modal-animate__fadeInUpBig modal-bottom modal-fullscreen\"],[15,5,[30,0,[\"drawerElementStyle\"]]],[12],[1,\"\\n\"],[41,[30,1],[[[41,[30,2],[[[1,\"      \"],[11,0],[16,0,[29,[\"is-tappable modal-background \",[30,3]]]],[4,[38,1],[[30,4],true],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,0,[29,[\"is-tappable modal-background \",[30,3]]]],[12],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n  \"],[10,0],[15,0,[29,[[30,0,[\"drawerCardClassName\"]]]]],[15,5,[30,0,[\"elementStyle\"]]],[12],[1,\"\\n    \"],[10,\"section\"],[14,0,\"modal-card-body\"],[12],[1,\"\\n      \"],[18,5,[[28,[37,1],[[30,4],true],null]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@hasOverlay\",\"@closeOnOverlayClick\",\"@drawerBackgroundClass\",\"@onClose\",\"&default\"],false,[\"if\",\"fn\",\"yield\"]]",
    "moduleName": "shop/pods/components/elements/drawer/drawer-desktop/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
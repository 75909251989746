define("shop/pods/components/elements/element-inputpvt/component", ["exports", "@ember/component/text-field", "ember-concurrency", "jquery", "ramda"], function (_exports, _textField, _emberConcurrency, _jquery, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textField.default.extend({
    attributeBindings: ['placeholder', 'disabled'],
    classNames: ['input'],
    autofocus: false,
    hasError: null,
    translate: true,
    placeholder: '',
    readonly: '',

    /**
     * input event:
     * similar to keyUp except only triggers if input value changes
     * (unsupported in IE < 9)
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    input(e) {
      if (this.onInput) {
        this.debounceTask.perform(e);
      }
    },

    debounceTask: (0, _emberConcurrency.task)(function* (e) {
      const DEBOUNCE_MS = R.pathOr(450, ['debounce_ms'])(this);

      if (DEBOUNCE_MS !== 0) {
        yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      }

      this.onInput(this.value, e);
    }).restartable(),

    didInsertElement() {
      this._super(...arguments);

      if (this.autofocus && !this.readonly && !this.disabled) {
        (0, _jquery.default)(this.element).select();
      }
    }

  });

  _exports.default = _default;
});
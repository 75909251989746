define("shop/pods/components/flows/flow-chat-window/flow-message/flow-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jKcuOFU1",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"chat-message chat-message-left\"],[12],[1,\"\\n    \"],[1,[34,2]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"chat-message chat-message-left\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"translate\",\"label\",\"value\",\"onSubmit\"],[false,[33,4,[\"title\"]],[33,5],[28,[37,6],[[30,0],\"openNewLink\",[33,4]],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showButton\",\"title\",\"elements/element-btn\",\"button\",\"textValue\",\"action\"]]",
    "moduleName": "shop/pods/components/flows/flow-chat-window/flow-message/flow-buttons/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/erp/payments/card/doc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Je/KzjMt",
    "block": "[[[8,[39,0],null,[[\"@document\",\"@user\",\"@website\",\"@errors\"],[[30,0,[\"model\",\"document\"]],[30,0,[\"model\",\"user\"]],[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"errors\"]]]],null],[1,\"\\n\"]],[],false,[\"payments/card/by-link-page\"]]",
    "moduleName": "shop/pods/erp/payments/card/doc/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/items/item-set-with-choices/item-choice-group/item-choice/component", ["exports", "@ember/component", "@ember/object", "ramda"], function (_exports, _component, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    isLink: true,
    showOuterPrice: true,
    images: (0, _object.computed)('item._data.uploadedFiles.[]', function () {
      const images = this.get('item._data.uploadedFiles') || [];
      return images.sortBy('timestampZ');
    }),
    defaultImage: (0, _object.computed)('images.@each.isDefault', function () {
      const images = this.images || [];
      const defaultImageData = R.find(R.propEq('isDefault', true))(images);

      if (defaultImageData) {
        return defaultImageData;
      }

      if (images.get('firstObject')) {
        const firstImage = images.get('firstObject');

        if (firstImage) {
          return firstImage;
        }
      }

      return false;
    }),
    hasEmptyVariantChoices: (0, _object.computed)('item.selectedVariantChoices.@each.qty', function () {
      const selectedVariantChoices = this.get('item.selectedVariantChoices') || [];
      return R.pipe(R.pluck('qty'), R.reject(qty => {
        qty = parseInt(qty) || 0;
        return R.gt(qty, 0);
      }), R.length, R.gt(R.__, 0))(selectedVariantChoices);
    }),
    actions: {
      toggleShowVariantPrice(showOuterPrice) {
        this.set('showOuterPrice', showOuterPrice);
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/blogs/blog-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sHtBqN+W",
    "block": "[[[11,0],[24,0,\"blog-page__container\"],[17,1],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"title blog-page__name\"],[12],[1,[30,2,[\"_data\",\"name\"]]],[13],[1,\"\\n\\n\"],[1,\"\\n    \"],[10,0],[14,0,\"blog-page__content\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@content\",\"@params\",\"@website\"],[[30,2,[\"_data\",\"template\"]],[30,3],[30,4]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"u-section\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@count\",\"@excludeKey\",\"@page\",\"@params\",\"@website\"],[8,[30,2,[\"_data\",\"_key\"]],1,[28,[37,2],null,[[\"tags\"],[[30,2,[\"tagNames\"]]]]],[30,4]]],[[\"default\"],[[[[1,\"\\n\\n\"],[41,[30,5,[\"blogs\"]],[[[1,\"      \"],[10,\"h2\"],[14,0,\"has-text-centered\"],[12],[1,[28,[35,4],[\"related\"],null]],[13],[1,\"\\n      \"],[8,[39,5],null,[[\"@style\"],[\"slider\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,5,[\"blogs\"]]],null]],null],null,[[[1,\"          \"],[11,1],[4,[38,8],[[28,[37,9],[[30,6],true],null]],null],[12],[1,\"\\n            \"],[8,[39,10],null,[[\"@website\",\"@shop\",\"@blog\",\"@isGrid\",\"@titleLineCount\",\"@descriptionLineCount\",\"@isGjs\"],[[30,4],[30,8],[30,7],false,[30,4,[\"blogsTitleLineCount\"]],[30,4,[\"blogsDescriptionLineCount\"]],false]],null],[1,\"  \\n          \"],[13],[1,\"\\n\"]],[7]],null],[1,\"      \"]],[6]]]]],[1,\"\\n\\n\"]],[]],null],[1,\"  \"]],[5]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@blog\",\"@params\",\"@website\",\"pageComponent\",\"onDidInsert\",\"blog\",\"@shop\"],false,[\"content-display\",\"blogs/page-content\",\"hash\",\"if\",\"tt\",\"components-group\",\"each\",\"-track-array\",\"did-insert\",\"fn\",\"blogs/blog-card\"]]",
    "moduleName": "shop/pods/components/blogs/blog-page/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/tabs/headers/tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KAyF4gUn",
    "block": "[[[11,\"li\"],[16,1,[30,0,[\"id\"]]],[16,0,[29,[[52,[28,[37,1],[[30,1,[\"isActive\"]],[30,0,[\"isActive\"]]],null],\"is-active\"],\" tabs-header\"]]],[4,[38,2],[[28,[37,3],[[30,0,[\"didInsert\"]]],null]],null],[4,[38,4],[\"click\",[28,[37,3],[[30,0,[\"onClick\"]]],null]],null],[12],[1,\"\\n  \"],[10,3],[12],[1,\"\\n\"],[41,[30,1,[\"icon\"]],[[[1,\"      \"],[10,1],[14,0,\"icon is-small\"],[12],[1,\"\\n        \"],[10,\"i\"],[15,0,[30,1,[\"icon\"]]],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,1],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@tab\"],false,[\"if\",\"or\",\"did-insert\",\"fn\",\"on\"]]",
    "moduleName": "shop/pods/components/tabs/headers/tab/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/filters/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hYEYdL23",
    "block": "[[[41,[30,0,[\"hasSearchBar\"]],[[[1,\"  \"],[8,[39,1],[[17,1]],[[\"@model\",\"@hasSearchToggle\",\"@filtersComponent\",\"@valuePath\",\"@onSearch\",\"@onReset\"],[[30,2,[\"filterModel\"]],[30,3],[30,4],\"_data.query\",[28,[37,2],[[30,0,[\"filtersService\",\"updateFilter\"]],[28,[37,3],null,[[\"filterModel\",\"onUpdateFilter\",\"isSearch\"],[[30,2,[\"filterModel\"]],[30,5],true]]]],null],[28,[37,4],[[30,4,[\"resetSearchBarTask\"]],[28,[37,3],null,[[\"isShopOrCategoryPage\"],[[30,6]]]]],null]]],null],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"@pageComponent\",\"@hasSearchToggle\",\"@filtersComponent\",\"@onUpdateFilter\",\"@isShopOrCategoryPage\"],false,[\"if\",\"elements/search-bar\",\"fn\",\"hash\",\"perform\"]]",
    "moduleName": "shop/pods/components/filters/search-bar/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/components-group-stories/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wRrp1tls",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"bigordr-stories-header-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"bigordr-stories-header\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2,[\"imageObjects\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"bigordr-stories-header__bubbles\"],[12],[1,\"\\n          \"],[11,0],[16,0,[29,[\"bigordr-stories-header__circle \",[52,[30,3,[\"isOpen\"]],\"open\"]]]],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"onClickShowSliderModal\"]],[30,2],[30,3],[30,4]],null]],null],[12],[1,\"\\n            \"],[10,0],[14,0,\"bigordr-stories-header__crop-img\"],[12],[1,\"\\n              \"],[8,[39,5],null,[[\"@website\",\"@content\",\"@isStories\"],[[30,5],[30,3,[\"modalContent\"]],true]],null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,3,[\"isActive\"]],[[[1,\"          \"],[8,[39,6],null,[[\"@isSliderModalVisible\",\"@sliderContent\",\"@mouseDown\",\"@mouseUp\",\"@toNextSlide\",\"@toBackSlide\",\"@onModalClose\",\"@story\",\"@onloadImg\",\"@isImgLoaded\",\"@isPaused\"],[[30,0,[\"isSliderModalVisible\"]],[30,3,[\"modalContent\"]],[30,0,[\"handleMouseDown\"]],[30,0,[\"handleMouseUp\"]],[28,[37,4],[[30,0,[\"toNextSlide\"]],[30,2]],null],[28,[37,4],[[30,0,[\"toBackSlide\"]],[30,2]],null],[28,[37,4],[[30,0,[\"onModalClose\"]],[30,3]],null],[30,2],[28,[37,4],[[30,0,[\"startSlideshow\"]],[30,2]],null],[30,0,[\"isImgLoaded\"]],[30,0,[\"isPaused\"]]]],null],[1,\"\\n\"]],[]],null]],[3,4]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]],null]],[\"@stories\",\"story\",\"imageObject\",\"index\",\"@website\"],false,[\"each\",\"-track-array\",\"if\",\"on\",\"fn\",\"content-display\",\"stories\"]]",
    "moduleName": "shop/pods/components/components-group-stories/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/blogs/index/route", ["exports", "@ember/routing/route", "rsvp", "shop/pods/page/model", "@ember/service", "@ember/object", "ramda", "shop/utils/nventor"], function (_exports, _route, _rsvp, _model, _service, _object, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _route.default.extend((_obj = {
    websiteService: (0, _service.inject)('website'),
    query: null,
    tag: null,
    queryParams: {
      page: {
        refreshModel: true
      },
      count: {
        refreshModel: true
      },
      query: {
        refreshModel: true
      },
      tags: {
        type: 'array',
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      }
    },

    async model(params) {
      const host = this.modelFor('application').host;
      const website = this.modelFor('application').website;
      const shop = this.modelFor('application').shop;
      const isCampaign = this.modelFor('application').isCampaign;
      const blogsPage = website.getDynamicPage('blogs');

      if (_nventor.default.isNilOrEmpty(blogsPage)) {
        return this.transitionTo('/not-found-404');
      }

      const homePageRedirect = R.propEq('homePageRedirect', true)(blogsPage);

      if (homePageRedirect) {
        return this.transitionTo('/');
      }

      const page = _model.default.create();

      page.populate(blogsPage);
      this.websiteService.updateTitle(website === null || website === void 0 ? void 0 : website.name);

      try {
        return _rsvp.default.hash({
          host,
          params,
          shop,
          website,
          isCampaign,
          page
        });
      } catch (e) {
        this.transitionTo('/');
      }
    },

    redirect(model) {
      if (!model) {
        this.transitionTo('/');
      }
    },

    willTransition() {
      this.websiteService.saveSnapshot();
    }

  }, (_applyDecoratedDescriptor(_obj, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_obj, "willTransition"), _obj)), _obj));

  _exports.default = _default;
});
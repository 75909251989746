define("shop/pods/components/items/status-container/component", ["exports", "ramda", "@ember/component", "@ember/object", "@ember/service"], function (_exports, R, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    shopService: (0, _service.inject)('shop'),
    tagName: '',
    qty: 1,
    isPreOrder: (0, _object.computed)('item.{calculatedStatusAfterCart,_data.calculatedStatusAfterCart}', 'isOverAvailableQty', 'overSalesLimitedQtyStatus', 'calculatedStatusAfterCart', 'cartItem.qty', function () {
      const calculatedStatusAfterCart = this.calculatedStatusAfterCart;

      if (calculatedStatusAfterCart === 'preOrder') {
        return true;
      }

      if (this.isOverAvailableQty && this.overSalesLimitedQtyStatus === 'preOrder') {
        return true;
      }

      return false;
    }),
    isOverAvailableQty: (0, _object.computed)('availableQty', 'hasLimitedQty', 'qty', function () {
      const qty = this.qty || 0;
      const hasLimitedQty = this.hasLimitedQty;

      if (hasLimitedQty) {
        const availableQty = parseInt(this.availableQty) || 0;

        if (qty > availableQty) {
          return true;
        }
      }

      return false;
    }),
    // @NOTE: currently not used..but should be used instead of cart qty selector
    allowedMaxQty: (0, _object.computed)('availableQty', 'personLimitedQty', 'hasLimitedQty', 'overSalesLimitedQtyStatus', function () {
      const overSalesLimitedQtyStatus = this.overSalesLimitedQtyStatus;

      if (overSalesLimitedQtyStatus === 'preOrder') {
        return '';
      }

      const personLimitedQty = parseInt(this.personLimitedQty) || 0;
      const availableQty = parseInt(this.availableQty) || 0;

      if (this.hasLimitedQty) {
        if (personLimitedQty < availableQty) {
          return personLimitedQty;
        }

        return this.availableQty;
      }

      if (personLimitedQty) {
        return personLimitedQty;
      }

      return '';
    }),
    calculatedStatusAfterCart: (0, _object.computed)('item.{calculatedStatusAfterCart,_data.calculatedStatusAfterCart,selectedVariant.calculatedStatusAfterCart}', 'cartItem.qty', function () {
      let calculatedStatusAfterCart = this.get('item.selectedVariant.calculatedStatusAfterCart') || this.get('item.calculatedStatusAfterCart');
      calculatedStatusAfterCart = calculatedStatusAfterCart || this.get('item._data.calculatedStatusAfterCart');

      if (this.get('cartItem.qty') > 0) {
        calculatedStatusAfterCart = this.get('cartItem.calculatedStatusAfterCart') || calculatedStatusAfterCart;
      }

      return calculatedStatusAfterCart;
    }),
    calculatedStatusToDisplayForAddToCart: (0, _object.computed)('item.{calculatedStatusToDisplayForAddToCart,_data.calculatedStatusToDisplayForAddToCart,selectedVariant.calculatedStatusToDisplayForAddToCart}', 'cartItem.qty', function () {
      let calculatedStatusToDisplayForAddToCart = this.get('item.selectedVariant.calculatedStatusToDisplayForAddToCart') || this.get('item.calculatedStatusToDisplayForAddToCart');
      calculatedStatusToDisplayForAddToCart = calculatedStatusToDisplayForAddToCart || this.get('item._data.calculatedStatusToDisplayForAddToCart');

      if (this.get('cartItem.qty') > 0) {
        calculatedStatusToDisplayForAddToCart = this.get('cartItem.calculatedStatusToDisplayForAddToCart') || calculatedStatusToDisplayForAddToCart;
      }

      return calculatedStatusToDisplayForAddToCart;
    }),
    statusTagClassName: (0, _object.computed)('calculatedStatusToDisplayForAddToCart', function () {
      if (R.equals(this.calculatedStatusToDisplayForAddToCart, 'soldOut')) {
        return 'is-black';
      }

      if (R.equals(this.calculatedStatusToDisplayForAddToCart, 'preOrder')) {
        return 'is-warning';
      }

      return '';
    }),
    isOutOfStock: (0, _object.computed)('calculatedStatusAfterCart', function () {
      const calculatedStatusAfterCart = this.calculatedStatusAfterCart;

      if (calculatedStatusAfterCart === 'outOfStock') {
        return true;
      }

      return false;
    }),
    isSoldOut: (0, _object.computed)('calculatedStatusAfterCart', 'isOverAvailableQty', 'overSalesLimitedQtyStatus', 'cartItem.qty', 'personLimitedQty', function () {
      const calculatedStatusAfterCart = this.calculatedStatusAfterCart;

      if (calculatedStatusAfterCart === 'soldOut' || calculatedStatusAfterCart === 'inactive') {
        return true;
      }

      if (this.isOverAvailableQty && this.overSalesLimitedQtyStatus === 'soldOut') {
        return true;
      }

      const personLimitedQty = parseInt(this.personLimitedQty) || false;

      if (personLimitedQty) {
        const currentQty = parseInt(this.get('cartItem.qty')) || 0;

        if (personLimitedQty <= currentQty) {
          return true;
        }
      }

      return false;
    }),
    verifyCustomizationsError: (0, _object.computed)('item.{hasSelectedCustomizationsChanged,_data.verifyCustomizationsError}', function () {
      return R.pathOr(false, ['item', '_data', 'verifyCustomizationsError'])(this);
    }),
    isMayAdd: (0, _object.computed)('automation.thenAction', function () {
      if (this.get('automation.thenAction') === 'mayAdd') {
        return true;
      }

      return false;
    }),
    // isAdded: computed('shop.{cartItems.[]}', 'automation.selected.[]', 'isMayAdd', 'item._data.itemKey', function () {
    //   if (this.get('isSetWithChoicesOrHasVariants')) {
    //     return false
    //   }
    //   const item = this.get('item')
    //   if (item) {
    //     const items = this.get('isMayAdd')
    //       ? this.get('automation.selected') || []
    //       : this.get('shop.cartItems') || []
    //     let itemKey
    //     if (item._data) {
    //       itemKey = item._data.itemKey
    //     } else {
    //       itemKey = item.itemKey
    //     }
    //     return items.findBy('itemKey', itemKey)
    //   }
    //   return {}
    // }),
    allCartItemsForRecurringSchedulesQty: (0, _object.computed)('shop.cartItems.[]', 'item._data.{itemKey,_key}', function () {
      var _item$_data;

      const item = this.item;

      if (!(item !== null && item !== void 0 && (_item$_data = item._data) !== null && _item$_data !== void 0 && _item$_data.recurringSchedulesEnabled)) {
        return 0;
      }

      const allItems = this._fetchAllItemsForItemKey({
        item
      });

      return R.reduce((acc, item) => {
        const qty = Number(item.qty) || 0;
        return acc + qty;
      }, 0)(allItems);
    }),

    _fetchAllItemsForItemKey({
      item
    }) {
      if (item) {
        var _item$_data2;

        const items = this.get('shop.cartItems') || [];
        let itemKey;
        const detailKey = item === null || item === void 0 ? void 0 : (_item$_data2 = item._data) === null || _item$_data2 === void 0 ? void 0 : _item$_data2._key;

        if (item.hasVariants) {
          if (item.selectedVariantName) {
            const itemKey = item.selectedVariant.itemKey;
            return R.filter(R.allPass([R.propEq('itemKey', itemKey)]))(items);
          }

          return [];
        }

        if (item._data) {
          itemKey = item._data.itemKey;
        } else {
          itemKey = item.itemKey;
        }

        return R.filter(R.allPass([R.propEq('itemKey', itemKey), R.propEq('_key', detailKey)]))(items);
      }

      return [];
    },

    // @NOTE: this does not work for variants. after adding variants to cart it does not trigger this computed prop
    cartItem: (0, _object.computed)('shop.cartItems.[]', 'item._data.{itemKey,_key}', 'item.{selectedVariantName,hasSelectedCustomizationsChanged}', function () {
      var _this$item, _this$item$_data;

      // @TODO: this could fetch the wrong cart item.
      // it could be itemkey but with or without recurring..
      const item = this.item;

      if (this !== null && this !== void 0 && (_this$item = this.item) !== null && _this$item !== void 0 && (_this$item$_data = _this$item._data) !== null && _this$item$_data !== void 0 && _this$item$_data.isSetWithChoices) {
        // @TODO: @DARWIN ADD CART ITEM FOR SET WITH CHOICES
        return false;
      }

      if (item) {
        var _item$_data3;

        const cartItems = this.get('shop.cartItems') || [];
        let itemKey;
        const detailKey = item === null || item === void 0 ? void 0 : (_item$_data3 = item._data) === null || _item$_data3 === void 0 ? void 0 : _item$_data3._key;

        if (item.hasVariants) {
          if (item.selectedVariantName) {
            const itemKey = item.selectedVariant.itemKey;
            return R.find(R.allPass([R.propEq('itemKey', itemKey), this.shopService.isCartItemCustomizationsSimilar(item)]))(cartItems);
          }

          return false;
        }

        if (item._data) {
          itemKey = item._data.itemKey;
        } else {
          itemKey = item.itemKey;
        }

        if (item.isSetWithChoices) {
          const setItems = R.filter(item => item.itemKey === itemKey)(cartItems);
          item.set('setItemsNum', setItems.length);
        }

        const found = R.find(R.allPass([R.propEq('itemKey', itemKey), R.propEq('_key', detailKey), this.shopService.isCartItemCustomizationsSimilar(item)]))(cartItems);
        return found;
      }

      return false;
    }),
    isAdded: (0, _object.computed)('cartItem', function () {
      return this.cartItem;
    }),
    isSetWithChoicesOrHasVariants: (0, _object.computed)('item._data.{isSetWithChoices,hasVariants}', function () {
      if (this.get('item._data.isSetWithChoices')) {
        return true;
      }

      if (this.get('item._data.hasVariants')) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});
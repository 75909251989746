define("shop/pods/components/news/content/component", ["exports", "@glimmer/component", "@ember/service", "@glimmer/tracking", "ember-concurrency-decorators", "ramda", "shop/utils/nventor"], function (_exports, _component, _service, _tracking, _emberConcurrencyDecorators, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NewsContentComponent = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('filters'), _dec3 = (0, _service.inject)('seo'), (_class = class NewsContentComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _initializerDefineProperty(this, "filtersService", _descriptor3, this);

      _initializerDefineProperty(this, "seoService", _descriptor4, this);

      _initializerDefineProperty(this, "filterModel", _descriptor5, this);

      this.setup.perform();
    }

    *setup() {
      const params = this.args.params || {};
      const defaultData = {
        tags: R.pathOr([], ['args', 'website', '_data', 'newsTags'])(this)
      };
      this.filterModel = this.filtersService.setupFilters(defaultData, {
        params
      });
      const response = yield this.fetchTask.perform(params);
      const news = (response === null || response === void 0 ? void 0 : response.news) || [];

      const hasNews = _nventor.default.isNotNilOrEmpty(news);

      if (!hasNews) {
        return;
      }

      const structuredData = R.map(data => {
        return {
          data,
          type: 'news'
        };
      })(news);
      this.seoService.setupStructuredData({
        structuredData: structuredData
      });
    }

    *fetchTask(params = {}) {
      const website = this.args.website; // let params = this.args.getFromParams ? this.filterModel.params : this.filterModel.getAsParams()
      // if (!R.isEmpty(queryParams)) {
      //   params.count = queryParams.count
      //   params.page = queryParams.page
      // }

      const response = yield this.websiteService.getNewsTask.perform({
        website,
        params
      });
      this.filterModel.results = R.propOr([response], 'news', response);
      this.filterModel.hasMore = R.propOr(false, 'hasMore', response);
      this.filterModel.totalCount = R.propOr(0, 'totalCount', response);
      return response;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "seoService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filterModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTask"), _class.prototype)), _class));
  _exports.default = NewsContentComponent;
});
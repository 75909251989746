define("shop/pods/base/model", ["exports", "ramda", "@ember/object"], function (_exports, R, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BaseModel = (_class = class BaseModel {
    constructor(data = {}, attrs) {
      this.populate(data, attrs);
    }
    /**
     * set _data directly
     * when to use. when you only have the property of the model.
     * @param {string} prop the property to set on _data. eg: _data[prop] 
     * @param {*} value the value of the prop
     */


    setData(prop, value) {
      (0, _object.set)(this, `_data.${prop}`, value);
      return value;
    }
    /**
     * set model property value directly.
     * can handle dot paths.
     * @param {string} prop the property to set on model eg: model[prop] 
     * @param {*} value the value of the prop
     */


    set(path, value) {
      if (!path) {
        throw new Error('no path set');
      }

      (0, _object.set)(this, path, value);
      return value;
    }

    populate(data, attrs = {}) {
      R.mapObjIndexed((value, prop) => {
        this[prop] = value;
        return value;
      })(attrs);
      R.mapObjIndexed((value, prop) => {
        if (!R.isNil(value)) {
          this.setData(prop, value);
        }

        return value;
      })(data);
      return data;
    }

    serialize() {
      return R.pick(this._data._keys)(this._data);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "serialize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "serialize"), _class.prototype)), _class);
  _exports.default = BaseModel;
});
define("shop/pods/components/filters/search-filters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BI1upXY3",
    "block": "[[[10,0],[14,0,\"nav-menu__search-filter-container flex-container-column flex-container--align-items-center\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@onSearch\"],[[30,0,[\"searchTask\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"filterModel\",\"_data\",\"tags\"]],[[[1,\"    \"],[10,0],[14,0,\"shop-tags-menu__mobile-container\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"filterModel\",\"filtersTags\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@classNames\",\"@isRunning\",\"@label\",\"@onSubmit\",\"@translate\"],[[29,[\"button \",[52,[30,1,[\"isSelected\"]],\"is-selected\"]]],[30,0,[\"searchTask\",\"isRunning\"]],[30,1,[\"label\"]],[28,[37,5],[[30,0,[\"onToggleTag\"]],[30,1]],null],false]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"tag\"],false,[\"filters/search-filters/search-bar\",\"if\",\"each\",\"-track-array\",\"elements/element-btn\",\"fn\"]]",
    "moduleName": "shop/pods/components/filters/search-filters/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/flows/flow-chat-message/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/runloop"], function (_exports, _component, _object, _tracking, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FlowsFlowChatMessageComponent = (_class = class FlowsFlowChatMessageComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isReady", _descriptor, this);
    }

    didInsert() {
      (0, _runloop.scheduleOnce)('afterRender', this, function () {
        this.isReady = true;
      });
    }

    get messageFromClass() {
      if (this.args.isPopup) {
        return 'chat-message-greeting';
      }

      if (this.args.isMenu) {
        return 'chat-message-menu';
      }

      if (this.args.isUser) {
        return 'chat-message-right';
      }

      return 'chat-message-left';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isReady", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype)), _class);
  _exports.default = FlowsFlowChatMessageComponent;
});
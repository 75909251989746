define("shop/pods/users/model", ["exports", "@ember/object", "@ember/service", "shop/mixins/base", "shop/mixins/big", "shop/utils/nventor", "ramda"], function (_exports, _object, _service, _base, _big, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * User model is only used for checkout. When a person is either a guest or member, it will be a user model but
   * with either isGuest or isMember
   */
  // import Component from '@ember/component'
  var _default = _object.default.extend(_base.default, _big.default, {
    // fastboot: service(),
    storage: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    websiteService: (0, _service.inject)('website'),
    validatorService: (0, _service.inject)('validator'),
    isInComplete: false,
    isMember: (0, _object.computed)('_data.memberId', function () {
      return this.get('_data.memberId') || false;
    }),
    isGuest: (0, _object.computed)('isMember', function () {
      if (this.isMember) {
        return false;
      }

      return true;
    }),

    init() {
      this._super(...arguments);

      const website = this.websiteService.website;
      const allowRememberCreditCardDefault = website.get('shop._data.creditCardProviderAllowRememberCard') || false;
      const defaults = {
        addresses: [{
          country: '',
          countryCode: '',
          district: '',
          hasPostCode: true,
          postCode: '',
          region: '',
          street: ''
        }],
        alternateAddressData: {
          name: '',
          telephone: ''
        },
        birthday: '',
        locale: this.storage.getValue('locale'),
        checkoutCreditCardData: {},
        companyName: '',
        confirmPassword: '',
        country: '',
        countryCode: '',
        creditCards: [{
          ccBrand: '',
          ccExpiry: '',
          ccNumber: '',
          ccSecureCode: '',
          isValid: false,
          rememberCreditCard: allowRememberCreditCardDefault,
          type: 'creditCard'
        }],
        email: '',
        gender: '',
        hasAddresses: false,
        hasCreditCards: false,
        hasUserSetBirthday: false,
        isPickup: '',
        mailingList: true,
        memberLevel: '',
        name: '',
        notes: '',
        otherGender: '',
        password: '',
        paymentMethod: '',
        pendingPoints: 0,
        promoCode: '',
        taxNumber: '',
        telephone: '',
        totalPoints: 0,
        twEInvoiceCarrierId: '',
        twEInvoiceCarrierType: '',
        // twEInvoicePrint: false,
        useAlternateAddress: false,
        useDefaultTaxNumber: false,
        validPoints: 0,
        transporterPreferredArrivalTimePeriod: 'anytime'
      };
      this.set('defaults', defaults);
      this.set('_data', {});
      this.setupValidators();
    },

    setupValidators() {
      const props = {
        name: {
          type: 'string',
          minLength: 1,
          maxLength: 150
        },
        requiredEmail: {
          anyOf: [{
            type: 'string',
            format: 'email',
            minLength: 1,
            maxLength: 320
          }]
        },
        email: {
          anyOf: [{
            type: 'null'
          }, {
            type: 'string',
            maxLength: 0
          }, {
            type: 'string',
            format: 'email',
            maxLength: 320
          }]
        },
        unverifiedEmail: {
          anyOf: [{
            type: 'null'
          }, {
            type: 'string',
            maxLength: 0
          }, {
            type: 'string',
            format: 'email',
            maxLength: 320
          }]
        },
        telephone: {
          type: 'string',
          minLength: 9,
          maxLength: 30
        },
        telephoneByCountryCode: [{
          if: {
            properties: {
              countryCode: {
                const: 'TW'
              }
            }
          },
          then: {
            properties: {
              telephone: {
                type: 'string',
                minLength: 10,
                maxLength: 10
              }
            }
          },
          else: {
            properties: {
              telephone: {
                type: 'string',
                minLength: 9,
                maxLength: 30
              }
            }
          }
        }],
        // {
        //   type: 'string',
        //   allOf: [{
        //     if: {
        //       properties: {
        //         countryCode: {
        //           const: 'TWWW'
        //         }
        //       }
        //     },
        //     then: {
        //       type: 'string',
        //       minLength: 10,
        //       maxLength: 10
        //     },
        //     else: {
        //       type: 'string',
        //       minLength: 9,
        //       maxLength: 30
        //     }
        //   }]
        // },
        taxNumber: {
          type: 'string',
          minLength: 8,
          maxLength: 8,
          if: {
            pattern: '^\\d{1,7}$'
          },
          then: {
            pattern: '^\\d{8}$'
          },
          else: {
            pattern: '^\\d*$'
          }
        },
        companyName: {
          type: 'string',
          minLength: 3,
          maxLength: 200
        },
        twEInvoiceCarrierType: {
          type: 'string'
        },
        twEInvoiceCarrierId: {
          type: 'string'
        },
        twEInvoiceCarrierId_mobile: {
          type: 'string',
          minLength: 8,
          maxLength: 8
        },
        twEInvoiceCarrierId_citizenDigitalCertificate: {
          type: 'string',
          minLength: 16,
          maxLength: 16
        },
        gender: {
          type: 'string'
        },
        otherGender: {
          type: 'string',
          maxLength: 300
        },
        birthday: {
          type: 'string'
        },
        // hasUserSetBirthday: {
        //   type: 'boolean'
        // },
        useDefaultTaxNumber: {
          type: 'boolean'
        },
        password: {
          minLength: 8,
          type: 'string'
        },
        confirmPassword: {
          const: {
            $data: '1/password'
          },
          type: 'string'
        },
        country: {
          type: 'string',
          anyOf: [{
            minLength: 2,
            maxLength: 300
          }]
        },
        countryCode: {
          type: 'string',
          anyOf: [{
            minLength: 2,
            maxLength: 300
          }]
        },
        address: {
          country: {
            type: 'string',
            anyOf: [{
              minLength: 2,
              maxLength: 300
            }]
          },
          countryCode: {
            type: 'string',
            anyOf: [{
              minLength: 2,
              maxLength: 300
            }]
          },
          street: {
            type: 'string',
            anyOf: [{
              minLength: 4,
              maxLength: 300
            }]
          },
          district: {
            type: 'string',
            anyOf: [{
              maxLength: 0
            }, {
              minLength: 2,
              maxLength: 100
            }]
          },
          region: {
            type: 'string',
            anyOf: [{
              maxLength: 0
            }, {
              minLength: 2,
              maxLength: 300
            }]
          },
          hasPostCode: {
            type: 'boolean'
          },
          postCode: {
            type: 'string',
            anyOf: [{
              minLength: 3,
              maxLength: 12
            }]
          },
          postCodeNotRequired: {
            type: 'string',
            anyOf: [{
              minLength: 0,
              maxLength: 12
            }, {
              minLength: 3,
              maxLength: 12
            }]
          }
        },
        convenienceStore: {
          brand: {
            type: 'string',
            anyOf: [{
              minLength: 2,
              maxLength: 300
            }]
          },
          storeId: {
            type: 'string',
            anyOf: [{
              minLength: 2,
              maxLength: 300
            }]
          },
          storeName: {
            type: 'string',
            anyOf: [{
              minLength: 2,
              maxLength: 300
            }]
          },
          storeAddress: {
            type: 'string',
            anyOf: [{
              minLength: 2,
              maxLength: 300
            }]
          },
          type: {
            type: 'string',
            anyOf: [{
              minLength: 0,
              maxLength: 20
            }]
          }
        },
        creditCard: {
          ccNumber: {
            type: 'string',
            pattern: '^(?:(?=⦁)([⦁]{12}[0-9]{4})|(^([0-9]{4})\\s([0-9]{4})\\s([0-9]{4})\\s([0-9]{4})$))',
            maxLength: 19
          },
          ccExpiry: {
            type: 'string',
            pattern: '^(?:(?=0)([0][1-9])|([1][0-2]))\\/(?:(?=1)([1][9])|([2-3][0-9]))$',
            maxLength: 5
          },
          ccSecureCode: {
            type: 'string',
            pattern: '^(?:(?=⦁)([⦁]{3})|(^([0-9]{3})$))',
            maxLength: 3
          }
        },
        existingCreditCard: {
          ccNumber: {
            type: 'string',
            minLength: 19,
            maxLength: 19
          },
          ccExpiry: {
            type: 'string',
            pattern: '^(?:(?=0)([0][1-9])|([1][0-2]))\\/(?:(?=1)([1][9])|([2-3][0-9]))$',
            maxLength: 5
          }
        }
      };
      this.addSchema('profile', {
        required: ['name', 'telephone', 'country', 'countryCode'],
        type: 'object',
        properties: {
          name: props.name,
          email: props.email,
          country: props.country,
          countryCode: props.countryCode,
          unverifiedEmail: props.unverifiedEmail,
          gender: props.gender,
          otherGender: props.otherGender,
          birthday: props.birthday,
          // hasUserSetBirthday: props.hasUserSetBirthday,
          // telephone: props.telephoneByCountryCode,
          // taxNumber: props.taxNumber,
          // companyName: props.companyName,
          twEInvoiceCarrierType: props.twEInvoiceCarrierType,
          twEInvoiceCarrierId: props.twEInvoiceCarrierId,
          useDefaultTaxNumber: props.useDefaultTaxNumber
        },
        allOf: props.telephoneByCountryCode,
        if: {
          properties: {
            twEInvoiceCarrierType: {
              const: 'mobile'
            }
          }
        },
        then: {
          properties: {
            twEInvoiceCarrierId: props.twEInvoiceCarrierId_mobile
          }
        },
        else: {
          if: {
            properties: {
              twEInvoiceCarrierType: {
                const: 'citizenDigitalCertificate'
              }
            }
          },
          then: {
            properties: {
              twEInvoiceCarrierId: {
                const: props.twEInvoiceCarrierId_citizenDigitalCertificate
              }
            }
          },
          else: {
            if: {
              properties: {
                twEInvoiceCarrierType: {
                  const: 'b2b'
                }
              }
            },
            then: {
              properties: {
                taxNumber: props.taxNumber,
                companyName: props.companyName
              }
            }
          }
        }
      });
      this.addSchema('checkout', {
        required: ['name', 'email', 'telephone', 'country', 'countryCode', 'twEInvoiceCarrierType', 'twEInvoiceCarrierId'],
        type: 'object',
        properties: {
          name: props.name,
          email: props.requiredEmail,
          country: props.country,
          countryCode: props.countryCode,
          gender: props.gender,
          otherGender: props.otherGender,
          birthday: props.birthday,
          // hasUserSetBirthday: props.hasUserSetBirthday,
          // telephone: props.telephoneByCountryCode,
          // taxNumber: props.taxNumber,
          // companyName: props.companyName,
          twEInvoiceCarrierType: props.twEInvoiceCarrierType,
          twEInvoiceCarrierId: props.twEInvoiceCarrierId,
          useDefaultTaxNumber: props.useDefaultTaxNumber
        },
        allOf: props.telephoneByCountryCode,
        if: {
          properties: {
            twEInvoiceCarrierType: {
              const: 'mobile'
            }
          }
        },
        then: {
          properties: {
            twEInvoiceCarrierId: props.twEInvoiceCarrierId_mobile
          }
        },
        else: {
          if: {
            properties: {
              twEInvoiceCarrierType: {
                const: 'citizenDigitalCertificate'
              }
            }
          },
          then: {
            properties: {
              twEInvoiceCarrierId: props.twEInvoiceCarrierId_citizenDigitalCertificate
            }
          },
          else: {
            if: {
              properties: {
                twEInvoiceCarrierType: {
                  const: 'b2b'
                }
              }
            },
            then: {
              properties: {
                taxNumber: props.taxNumber,
                companyName: props.companyName
              }
            }
          } // else: {
          //   properties: {
          //     twEInvoiceCarrierId: props.twEInvoiceCarrierId
          //   }
          // }

        }
      });
      this.addSchema('checkoutWithoutCountry', {
        required: ['name', 'email', 'telephone'],
        type: 'object',
        properties: {
          name: props.name,
          email: props.requiredEmail
        },
        allOf: props.telephoneByCountryCode,
        if: {
          properties: {
            twEInvoiceCarrierType: {
              const: 'mobile'
            }
          }
        },
        then: {
          properties: {
            twEInvoiceCarrierId: props.twEInvoiceCarrierId_mobile
          }
        },
        else: {
          if: {
            properties: {
              twEInvoiceCarrierType: {
                const: 'citizenDigitalCertificate'
              }
            }
          },
          then: {
            properties: {
              twEInvoiceCarrierId: props.twEInvoiceCarrierId_citizenDigitalCertificate
            }
          },
          else: {
            if: {
              properties: {
                twEInvoiceCarrierType: {
                  const: 'b2b'
                }
              }
            },
            then: {
              properties: {
                taxNumber: props.taxNumber,
                companyName: props.companyName
              }
            }
          } // else: {
          //   properties: {
          //     twEInvoiceCarrierId: props.twEInvoiceCarrierId
          //   }
          // }

        }
      });
      this.addSchema('address', {
        required: ['country', 'countryCode', 'street', 'postCode'],
        properties: {
          country: props.address.country,
          countryCode: props.address.countryCode,
          street: props.address.street,
          district: props.address.district,
          region: props.address.region,
          hasPostCode: props.address.hasPostCode,
          postCode: props.address.postCode
        }
      });
      this.addSchema('addressPostCodeNotRequired', {
        required: ['country', 'countryCode', 'street'],
        properties: {
          country: props.address.country,
          countryCode: props.address.countryCode,
          street: props.address.street,
          district: props.address.district,
          region: props.address.region,
          hasPostCode: props.address.hasPostCode,
          postCode: props.address.postCodeNotRequired
        }
      });
      this.addSchema('alternateAddress', {
        properties: {
          alternateAddressData: {
            required: ['name', 'telephone', 'countryCode'],
            type: 'object',
            properties: {
              name: props.name,
              countryCode: props.address.countryCode
            },
            allOf: props.telephoneByCountryCode
          }
        },
        type: 'object'
      });
      this.addSchema('convenienceStore', {
        required: ['country', 'countryCode', 'brand', 'storeId', 'storeName'],
        properties: {
          country: props.address.country,
          countryCode: props.address.countryCode,
          street: props.address.street,
          district: props.address.district,
          region: props.address.region,
          hasPostCode: props.address.hasPostCode,
          postCode: props.address.postCode,
          brand: props.convenienceStore.brand,
          storeId: props.convenienceStore.storeId,
          storeName: props.convenienceStore.storeName,
          storeAddress: props.convenienceStore.storeAddress,
          type: props.convenienceStore.type
        }
      });
      this.addSchema('creditCard', {
        required: ['ccNumber', 'ccExpiry', 'ccSecureCode'],
        properties: {
          ccNumber: props.creditCard.ccNumber,
          ccExpiry: props.creditCard.ccExpiry,
          ccSecureCode: props.creditCard.ccSecureCode
        },
        type: 'object'
      });
      this.addSchema('existingCreditCard', {
        required: ['ccNumber', 'ccExpiry'],
        properties: {
          ccNumber: props.existingCreditCard.ccNumber,
          ccExpiry: props.existingCreditCard.ccExpiry
        },
        type: 'object'
      });
      this.addSchema('creditCard.ccNumber', {
        required: ['ccNumber'],
        properties: {
          ccNumber: props.creditCard.ccNumber
        },
        type: 'object'
      });
      this.addSchema('creditCard.ccExpiry', {
        required: ['ccExpiry'],
        properties: {
          ccExpiry: props.creditCard.ccExpiry
        },
        type: 'object'
      });
      this.addSchema('creditCard.ccSecureCode', {
        required: ['ccSecureCode'],
        properties: {
          ccSecureCode: props.creditCard.ccSecureCode
        },
        type: 'object'
      });
      this.addSchema('signup', {
        required: ['name', 'email', 'country', 'countryCode', 'password', 'telephone', 'confirmPassword'],
        type: 'object',
        properties: {
          name: props.name,
          email: props.email,
          country: props.address.country,
          countryCode: props.address.countryCode,
          gender: props.gender,
          otherGender: props.otherGender,
          birthday: props.birthday,
          // hasUserSetBirthday: props.hasUserSetBirthday,
          // telephone: props.telephone,
          password: props.password,
          confirmPassword: props.confirmPassword
        },
        allOf: props.telephoneByCountryCode
      });
      this.addSchema('verification', {
        required: ['name', 'email', 'telephone'],
        properties: {
          name: props.name,
          email: props.email,
          telephone: props.telephone
        },
        type: 'object'
      });
      this.addSchema('verificationEmailUnverified', {
        required: ['name', 'unverifiedEmail', 'telephone'],
        properties: {
          name: props.name,
          unverifiedEmail: props.unverifiedEmail,
          telephone: props.telephone
        },
        type: 'object'
      });
      this.addSchema('email', {
        required: ['email'],
        properties: {
          email: props.email
        },
        type: 'object'
      });
      this.addSchema('setNewPassword', {
        required: ['password', 'confirmPassword'],
        properties: {
          password: props.password,
          confirmPassword: props.confirmPassword
        },
        type: 'object'
      });
    },

    hasAdditionalInfo: (0, _object.computed)('_data.{gender,birthday,hasUserSetBirthday}', function () {
      // @TODO: allow users to add additional info when not yet filled out
      return true; // const gender = R.path(['_data', 'gender'])(this)
      // const hasUserSetBirthday = R.path(['_data', 'hasUserSetBirthday'])(this)
      // if (gender && hasUserSetBirthday) {
      //   return true
      // }
      // return false
    }),
    requireVerifyDetails: (0, _object.computed)('isGuest', '_data.memberVerified', function () {
      if (this.isGuest) {
        return false;
      }

      const memberVerified = this.get('_data.memberVerified') || false;

      if (!memberVerified) {
        return true;
      }

      return false;
    }),
    requireVerifyEmailToCheckout: (0, _object.computed)('isGuest', '_data.{memberVerified,emailVerified,isSocialLogin}', function () {
      if (this.isGuest) {
        return false;
      }

      const isSocialLogin = this.get('_data.isSocialLogin');
      const emailVerified = this.get('_data.emailVerified');

      if (isSocialLogin && !emailVerified) {
        return true;
      }

      if (!isSocialLogin && !emailVerified) {
        return true;
      }

      return false;
    }),

    // hasSavedCreditCard: computed('isGuest', '_data.paymentMethods.[]', function () {
    //   if (this.get('isGuest')) {
    //     return false
    //   }
    //   const paymentMethods = this.get('_data.paymentMethods') || []
    //   return paymentMethods.length > 0
    // }),
    populate(data = {}) {
      const defaultData = this.defaults;
      const userData = this.getUserDataFromStorage() || {};
      const hasData = R.pipe(_nventor.default.isNilOrEmpty, R.not)(data);
      let _data = {};

      if (hasData) {
        _data = R.mergeRight(defaultData, data);
      } else {
        _data = _nventor.default.mergeRightWhenNotEmpty(userData, defaultData);
      }

      _data.addresses = R.pipe(R.propOr([], 'addresses'), R.map(address => {
        address.hasPostCode = R.pathOr(true, ['hasPostCode'])(address);
        return address;
      }))(_data);

      if (!_nventor.default.isNilOrEmpty(userData)) {
        this.persistUserDataToStorage(_data);
      }

      _data = this.transformAddresses(_data);
      _data = this.transformCreditCard(_data);
      this.setProperties({
        _data
      });
    },

    transformAddresses(_data) {
      _data.addresses = R.pipe(R.propOr([], 'addresses'), R.map(addressData => _object.default.create(addressData)))(_data); // const defaultAddress = this.getDefaultAddress(_data)
      // if (defaultAddress) {
      //   _data.addressData = EmberObject.create(_data.addressData)
      // }

      return _data;
    },

    transformCreditCard(_data) {
      _data.paymentMethods = R.pipe(R.propOr([], 'paymentMethods'), R.reject(R.propEq('isValid', false)), R.map(creditCard => {
        const brand = R.propOr('', 'ccBrand')(creditCard);
        const ccBrand = `fab fa-cc-${brand}`;
        const ccExpiry = R.propOr('', 'ccExpiry')(creditCard);
        const ccNumber = R.join(' ', R.concat(R.repeat('⦁⦁⦁⦁', 3), [R.propOr('', 'ccNumberLastFour')(creditCard)]));
        const ccSecureCode = '⦁⦁⦁';
        const isValid = R.propOr('', 'isValid')(creditCard);
        const useExistingCreditCard = true;
        const rememberCreditCard = true;
        const isDefault = R.propOr(false, 'isDefault')(creditCard);
        const type = R.propOr('creditCard', 'type')(creditCard);
        const ccId = R.propOr('', 'ccId')(creditCard);
        const provider = R.propOr('', 'provider')(creditCard); // set default to gmo

        const ccDesc = `${ccNumber} - ${this.intl.t(brand)} (${provider})`;
        return _object.default.create({
          ccBrand,
          ccExpiry,
          ccNumber,
          ccSecureCode,
          type,
          isDefault,
          isValid,
          rememberCreditCard,
          useExistingCreditCard,
          provider,
          ccId,
          ccDesc
        });
      }))(_data); // if (_data.creditCardData) {
      //   const defaultCreditCard = nventor.safeHead(_data.creditCards)
      //   if (defaultCreditCard) {
      //     _data.creditCardData = EmberObject.create(_data.creditCardData)
      //   }
      // }

      return _data;
    },

    persistUserDataToStorage(userData) {
      if (this.isGuest) {
        if (!userData) {
          userData = this.serialize();
        }

        const toStoreUserData = R.omit(['password', 'confirmPassword', 'creditCards', 'creditCard', 'creditCardData', 'notes', 'allowPickup', 'allowShipping', 'isShipping', 'hasCreditCards', 'addressData', 'convenienceStoreData'], userData);
        this.storage.setValue('user', toStoreUserData);
      } else {
        const toStoreUserData = R.pick(['taxNumber', 'companyName', 'twEInvoiceCarrierType', 'twEInvoiceCarrierId'])(userData);
        this.storage.setValue('user', toStoreUserData);
      }
    },

    removeUserDataFromStorage() {
      this.storage.setValue('user', {});
    },

    getUserDataFromStorage() {
      return this.storage.getValue('user') || [];
    },

    getFlowData(key) {
      if (key === 'address') {// get address differently here
      }

      if (key === 'mobile') {
        key = 'telephone';
      }

      const allowedKeys = ['name', 'email', 'birthday', 'gender', 'telephone', 'validPoints', 'addresses'];

      if (!R.includes(key, allowedKeys)) {
        return '';
      }

      return R.path(['_data', key])(this) || '';
    },

    setData(key, value) {
      this.set(`_data.${key}`, value);
      this.persistUserDataToStorage(this.serialize());
    },

    setCountry(country) {
      this.set('_data.country', country.name);
      this.set('_data.countryCode', country.code);
      this.websiteService.fetchRegionsTask.perform(country.code);
    },

    setDefaultCountry(website, countries = []) {
      let defaultCountry = R.find(R.propEq('isDefault', true), countries);

      if (!defaultCountry) {
        defaultCountry = _nventor.default.safeHead(countries);
      }

      if (defaultCountry) {
        if (!this.get('_data.country')) {
          this.setCountry(defaultCountry);
        }
      }
    },

    setDefaultAllowedCountry(address, countries) {
      const countryCode = this.get('_data.countryCode');

      if (!address.countryCode) {
        const defaultCountry = this.getDefaultAllowedCountry({
          countries,
          countryCode
        });
        this.setAddressCountry(address, defaultCountry);
      }
    },

    getDefaultAllowedCountry({
      countries,
      countryCode
    }) {
      let defaultCountry;

      if (countryCode) {
        defaultCountry = R.find(R.propEq('code', countryCode), countries);
      }

      if (!defaultCountry) {
        defaultCountry = R.find(R.propEq('isDefault', true), countries);
      }

      if (!defaultCountry) {
        defaultCountry = _nventor.default.safeHeadOr({}, countries);
      }

      return defaultCountry;
    },

    resetDefaultAllowedCountry(address, countries) {
      if (address !== null && address !== void 0 && address.set) {
        (0, _object.set)(address, 'countryCode', '');
        (0, _object.set)(address, 'country', '');
      }

      this.setDefaultAllowedCountry(address, countries);
    },

    setAddressCountry(address, country = {}) {
      (0, _object.set)(address, 'country', country.name);
      (0, _object.set)(address, 'countryCode', country.code);
      (0, _object.set)(address, 'locale', country.locale);
      (0, _object.set)(address, 'postCode', '');
      (0, _object.set)(address, 'street', '');
      (0, _object.set)(address, 'district', '');
      (0, _object.set)(address, 'region', '');
      (0, _object.set)(address, 'hasAdvancedAddress', country.hasAdvancedAddress || false);
      this.websiteService.fetchRegionsTask.perform(country.code);
      let hasPostCode = R.pathOr(true, ['hasPostCode'])(country);

      if (R.toUpper(country.code) === 'OTHER') {
        hasPostCode = false;
      }

      (0, _object.set)(address, 'hasPostCode', hasPostCode);
      this.setupValidators();
    },

    defaultAddress: (0, _object.computed)('_data.{addresses.[],hasAddresses,addressData}', function () {
      return this.getDefaultAddress(this._data);
    }),
    genderLists: (0, _object.computed)('_data', function () {
      const genderLists = [_object.default.create({
        label: 'male',
        value: 'male'
      }), _object.default.create({
        label: 'female',
        value: 'female'
      }), _object.default.create({
        label: 'other',
        value: 'other'
      })];
      return genderLists;
    }),

    getDefaultAddress(data) {
      if (data.hasAddresses) {
        const addresses = data.addresses || [];
        return _nventor.default.safeHeadOr(false, addresses);
      }

      if (data.addressData) {
        return data.addressData;
      }

      return false;
    },

    getAndReturnDefaultCreditCard({
      shop,
      isTapPay = false,
      isGmo = false,
      isPayuni = false,
      ccId = false,
      newCard = false
    } = {}) {
      let defaultCard = {};

      if (shop) {
        isGmo = shop.isGmo;
        isTapPay = shop.isTapPay;
        isPayuni = shop.isPayuni;
      }

      if (this.isMember && !newCard) {
        const paymentMethods = this.get('_data.paymentMethods') || [];
        const validCards = R.reject(R.propEq('isValid', false))(paymentMethods);
        const validCardsForPaymentProvider = R.filter(paymentMethod => {
          if (isGmo) {
            if (_nventor.default.isNilOrEmpty(paymentMethod.provider) || paymentMethod.provider === 'gmo') {
              return true;
            }
          }

          if (isTapPay) {
            if (paymentMethod.provider === 'tapPay') {
              return true;
            }
          }

          if (isPayuni) {
            if (paymentMethod.provider === 'payuni') {
              return true;
            }
          }

          return false;
        })(validCards);

        if (ccId) {
          defaultCard = R.find(R.propEq('ccId', ccId))(validCardsForPaymentProvider);
        } else {
          defaultCard = R.find(R.propEq('isDefault', true))(validCardsForPaymentProvider);

          if (!defaultCard) {
            defaultCard = _nventor.default.safeHeadOr(false, validCardsForPaymentProvider);
          }
        }
      }

      let provider = 'gmo';

      if (isTapPay) {
        provider = 'tapPay';
      }

      if (isPayuni) {
        provider = 'payuni';
      }

      return _object.default.create({
        ccNumber: R.propOr('', 'ccNumber', defaultCard),
        ccExpiry: R.propOr('', 'ccExpiry', defaultCard),
        ccSecureCode: R.propOr('', 'ccSecureCode', defaultCard),
        ccBrand: R.propOr('', 'ccBrand', defaultCard),
        isValid: R.propOr(false, 'isValid', defaultCard),
        rememberCreditCard: R.propOr(true, 'rememberCreditCard', defaultCard),
        useExistingCreditCard: R.propOr(false, 'useExistingCreditCard', defaultCard),
        ccId: R.propOr('', 'ccId', defaultCard),
        type: 'creditCard',
        provider
      });
    },

    selectExistingCreditCard(ccId) {
      try {
        this.set('checkoutCreditCardData.ccId', ccId);
      } catch (e) {
        console.log('cannot set existing card');
      }
    },

    toggleAlternateAddress(website, bool) {
      if (bool && typeof bool === 'boolean') {
        this.setData('useAlternateAddress', bool);
      }

      const useAlternateAddress = this.get('_data.useAlternateAddress');

      if (useAlternateAddress) {
        const countries = website.get('shop._data.countries');
        this.setDefaultCountry(website, countries);
      } else {
        const defaultAddress = this.defaultAddress;

        if (defaultAddress) {
          this.set('_data.addressData', R.clone(defaultAddress));
        }
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/application/service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    checkIsBrowser(name) {
      if (name === 'instagram') {
        return this.instagram();
      }

      return false;
    },

    instagram() {
      var _navigator, _navigator$userAgent, _navigator$userAgent$;

      const isInstagramWebview = ((_navigator = navigator) === null || _navigator === void 0 ? void 0 : (_navigator$userAgent = _navigator.userAgent) === null || _navigator$userAgent === void 0 ? void 0 : (_navigator$userAgent$ = _navigator$userAgent.includes) === null || _navigator$userAgent$ === void 0 ? void 0 : _navigator$userAgent$.call(_navigator$userAgent, 'Instagram')) || window.location.href.includes('instagram.com') || typeof window.InstagramFBDesktop !== 'undefined';

      if (isInstagramWebview) {
        return true;
      }

      return false;
    }

  });

  _exports.default = _default;
});
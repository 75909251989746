define("shop/pods/erp/payments/card/doc/route", ["exports", "@ember/routing/route", "@ember/service", "ramda", "rsvp", "shop/utils/nventor"], function (_exports, _route, _service, _ramda, _rsvp, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ajax: (0, _service.inject)(),
    websiteService: (0, _service.inject)('website'),

    // queryParams: {
    //   email: { refreshModel: false },
    //   ref: { refreshModel: false },
    //   errors: { refreshModel: false }
    // },
    async model(params) {
      const externalUrl = params.external_url;
      const website = this.modelFor('application').website;

      try {
        const url = `/api/public/payments/card/doc/${externalUrl}`;
        const response = await this.ajax.request(url, {
          method: 'GET'
        });

        const document = _ramda.default.propOr({}, 'data')(response);

        const shopUrl = (document === null || document === void 0 ? void 0 : document.shopUrl) || '';
        const isRecurringChild = (document === null || document === void 0 ? void 0 : document.isRecurringChild) || false;
        const isLoggedIn = (website === null || website === void 0 ? void 0 : website.isLoggedIn) || false;

        if (!isLoggedIn && isRecurringChild) {
          website.failedScheduledPaymentExternalUrl = externalUrl;

          if (shopUrl) {
            return this.transitionTo('shop.members', shopUrl);
          } else {
            return this.transitionTo('members');
          }
        }

        if (document.allowPayByLink) {
          let userModel;
          userModel = await this.membersService.get('getMemberModelTask').perform(website);

          if (!userModel) {
            // setup guest user heres
            userModel = this.membersService.setupGuestUserModel(website);
          } else {
            const checkoutCreditCardData = userModel.getAndReturnDefaultCreditCard({
              isTapPay: true
            });
            userModel.set('checkoutCreditCardData', checkoutCreditCardData);
          }

          const country = document.country || 'Taiwan';

          const addressObj = _ramda.default.pathOr({}, ['defaultAddress'])(userModel);

          const addressString = await this.websiteService.getCountryAddressStringTask.perform({
            country,
            addressObj
          });
          userModel.set('_data.address', addressString);

          const userDefaults = _ramda.default.pipe(_ramda.default.propOr({}, '_data'), _ramda.default.pick(['name', 'email', 'address', 'telephone']), _nventor.default.renameKeys({
            name: 'contact'
          }))(userModel);

          _ramda.default.mapObjIndexed((userValue, prop) => {
            const docValue = document[prop];

            if (!docValue && userValue) {
              document[prop] = userValue;
            }

            return userValue;
          })(userDefaults);

          return _rsvp.default.hash({
            document,
            website,
            params,
            user: userModel
          });
        }

        return _rsvp.default.hash({
          document,
          website
        });
      } catch (e) {
        return _rsvp.default.hash({
          website,
          errors: true
        });
      }
    }

  });

  _exports.default = _default;
});
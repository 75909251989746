define("shop/pods/components/shop/orders-query/orders-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+SpbOh/c",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@isRunning\"],[[30,0,[\"checkOrderStatusTask\",\"isRunning\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,0,[\"checkOrderStatusTask\",\"isRunning\"]]],[[[41,[28,[37,3],[\"isEmpty\",[30,0,[\"orders\"]]],null],[[[1,\"        \"],[1,[28,[35,4],[\"no order found\"],null]],[1,\"\\n\"]],[]],[[[42,[28,[37,6],[[28,[37,6],[[30,0,[\"orders\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"u-section card\"],[12],[1,\"\\n            \"],[8,[39,7],null,[[\"@website\",\"@shop\",\"@hideVirtualAccount\",\"@orderData\"],[[30,3,[\"website\"]],[30,3,[\"shop\"]],[52,[28,[37,8],[[30,2],0],null],false,true],[30,1]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"\\n        \"],[8,[39,9],null,[[\"@ref\",\"@email\",\"@messages\",\"@shop\"],[[30,4],[30,5],[30,0,[\"messages\"]],[30,3,[\"shop\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"hasBackButton\"]],[[[1,\"      \"],[10,0],[14,0,\"u-section\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"button button--solid\"],[24,4,\"button\"],[4,[38,10],[\"click\",[28,[37,11],[[30,0,[\"back\"]]],null]],null],[12],[1,[28,[35,4],[\"back\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"orderData\",\"index\",\"@model\",\"@ref\",\"@email\"],false,[\"helpers/is-running\",\"unless\",\"if\",\"ramda\",\"tt\",\"each\",\"-track-array\",\"shop/orders-query/orders-status/order-display\",\"eq\",\"shop/orders-messages\",\"on\",\"fn\"]]",
    "moduleName": "shop/pods/components/shop/orders-query/orders-status/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/elements/drawer/drawer-mobile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KD3jS/1L",
    "block": "[[[10,0],[14,0,\"modal cart-content-modal is-active modal-animate__animated modal-animate__fadeInUpBig modal-bottom modal-fullscreen is-mobile\"],[15,5,[30,0,[\"drawerElementStyle\"]]],[12],[1,\"\\n\"],[41,[30,1],[[[41,[30,0,[\"closeOnOverlayClick\"]],[[[1,\"      \"],[11,0],[16,0,[29,[\"is-tappable modal-background \",[30,2]]]],[4,[38,1],[[30,3],true],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,0,[29,[\"is-tappable modal-background \",[30,2]]]],[12],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n  \"],[11,0],[16,0,[29,[[30,0,[\"drawerCardClassName\"]]]]],[16,5,[30,0,[\"elementStyle\"]]],[4,[38,2],[\"touchstart\",[30,0,[\"handleTouchStartTopOnly\"]]],null],[4,[38,2],[\"touchmove\",[30,0,[\"handleTouchMoveTopOnly\"]]],null],[4,[38,2],[\"touchend\",[30,0,[\"handleTouchEndTopOnly\"]]],null],[4,[38,2],[\"touchcancel\",[30,0,[\"handleTouchCancelTopOnly\"]]],null],[12],[1,\"\\n    \"],[10,\"section\"],[14,0,\"modal-card-body\"],[12],[1,\"\\n      \"],[11,0],[24,0,\"swipe-to-close\"],[4,[38,2],[\"touchstart\",[30,0,[\"handleTouchStart\"]]],null],[4,[38,2],[\"touchmove\",[30,0,[\"handleTouchMove\"]]],null],[4,[38,2],[\"touchend\",[30,0,[\"handleTouchEnd\"]]],null],[4,[38,2],[\"touchcancel\",[30,0,[\"handleTouchCancel\"]]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"swipe-to-close-line\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[18,4,[[28,[37,1],[[30,3],true],null]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@hasOverlay\",\"@drawerBackgroundClass\",\"@onClose\",\"&default\"],false,[\"if\",\"fn\",\"on\",\"yield\"]]",
    "moduleName": "shop/pods/components/elements/drawer/drawer-mobile/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/shop/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OuG0P177",
    "block": "[[[8,[39,0],null,[[\"@isCampaign\",\"@page\",\"@params\",\"@shop\",\"@website\",\"@model\"],[[30,0,[\"model\",\"isCampaign\"]],[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"params\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]],[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[],false,[\"shop/container\"]]",
    "moduleName": "shop/pods/shop/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/shop/header-hero/component", ["exports", "jquery", "@ember/component"], function (_exports, _jquery, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    width: '',

    didInsertElement() {
      this._super(...arguments); // @TODO: @Chloe905 can remove?


      const defaultWidth = (0, _jquery.default)(this.element).outerWidth();
      this.set('width', defaultWidth);
      const headerSrcTransform = this.websiteService.imgSrc({
        width: this.width,
        ratio: this.shop._data.headerImgRatio || 'fourByThree'
      });
      this.set('imgSrcTransformUrl', headerSrcTransform.imgSrcTransformUrl);
      this.set('imgSrcsetTransformUrl', headerSrcTransform.imgSrcsetTransformUrl);
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/nav-horizontal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jt6gvKLX",
    "block": "[[[11,0],[16,0,[29,[\"nav-menu__container horizontal \",[30,1,[\"menuPosition\"]]]]],[4,[38,0],[[28,[37,1],[[30,0,[\"setupExpander\"]]],null]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"nav-menu__content \",[52,[30,0,[\"isOverflown\"]],\"is-overflown\"],\" \",[52,[30,0,[\"isExpandNav\"]],\"no-wrap\",\"\"],\" flex-container-row\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"nav-tree-container\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@classNames\",\"@initialIsExpanded\",\"@isExpanded\",\"@isHorizontal\",\"@itemClassNames\",\"@menu\",\"@toggle\",\"@website\"],[[30,2],false,[30,1,[\"menuExpandSubMenus\"]],true,\"button button--tile\",[30,1,[\"menu\"]],[30,3],[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[28,[37,4],[[30,0,[\"isOverflown\"]],[28,[37,5],[[30,1,[\"menuExpandBehavior\"]],\"autoHide\"],null]],null],[[[1,\"      \"],[10,0],[14,0,\"nav-menu__expander flex-container-row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"nav-menu__expander-fader\"],[12],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,6],null,[[\"@classNames\",\"@icon\",\"@onSubmit\"],[\"button button--tile\",[52,[30,0,[\"isExpandNav\"]],\"fas fa-chevron-down\",\"fas fa-chevron-right\"],[28,[37,1],[[30,0,[\"toggleIsExpandNav\"]]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@website\",\"@classNames\",\"@toggle\"],false,[\"did-insert\",\"fn\",\"if\",\"layouts/nav-menu-nested\",\"and\",\"eq\",\"elements/element-btn\"]]",
    "moduleName": "shop/pods/components/layouts/nav-horizontal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
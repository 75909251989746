define("shop/pods/members/service", ["exports", "@ember/service", "ember-concurrency", "@ember/application", "ramda", "shop/config/environment", "@ember/object", "shop/utils/nventor"], function (_exports, _service, _emberConcurrency, _application, R, _environment, _object, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _service.default.extend((_obj = {
    router: (0, _service.inject)(),
    storage: (0, _service.inject)(),
    websiteService: (0, _service.inject)('website'),
    intl: (0, _service.inject)(),
    flowsService: (0, _service.inject)('flows'),
    tokenData: false,
    originalMemberData: false,

    logout(website) {
      this.storage.remove('token'); // this.storage.removeWithShopKey('isPopupVisibleOnStartUp')

      this.storage.removeWithShopKey('isSignupPopupVisibleOnStartUp');
      this.set('tokenData', false);
      this.set('isLoggedIn', '');
      this.set('user', '');

      if (website) {
        this.websiteService.set('memberModel', {});
        website.set('isLoggedIn', false);
        website.set('signupFlows', []);
      }
    },

    /**
     * check if member is already logged in based on token, and return member model
     */
    getMemberModelTask: (0, _emberConcurrency.task)(function* (website, {
      fetchFromServer = true
    } = {}) {
      const token = this.getToken(website);

      if (token) {
        const tokenData = this.decodeMemberToken(token);

        if (tokenData.name) {
          const podKey = website.get('podKey');
          const shopKey = website.get('shopKey');

          if (fetchFromServer) {
            try {
              let appendPath = podKey;

              if (shopKey) {
                appendPath = `${appendPath}/${shopKey}`;
              }

              const response = yield this.websiteService.request(`/api/protected/websites/members/${appendPath}`, {
                method: 'GET',
                headers: {
                  authorization: `Bearer ${token}`
                }
              });
              return this.handleLoginResponse(website, {
                response
              });
            } catch (e) {
              this.logout(website);
            }
          } else {
            // dont refetch tokenData from server. re-use cached
            const memberModel = this.newMemberModel(website, tokenData);
            return memberModel;
          }
        } else {
          this.logout(website);
        }
      }

      return false;
    }).drop(),

    async getStoredMemberModel({
      website,
      refetchMember = false
    }) {
      var _this$websiteService, _this$websiteService$, _this$websiteService$2;

      let memberModel; // not sure why need to fetch member on website?

      const memberId = ((_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : (_this$websiteService$ = _this$websiteService.memberModel) === null || _this$websiteService$ === void 0 ? void 0 : (_this$websiteService$2 = _this$websiteService$._data) === null || _this$websiteService$2 === void 0 ? void 0 : _this$websiteService$2._key) || '';

      if (memberId) {
        var _this$websiteService2;

        memberModel = ((_this$websiteService2 = this.websiteService) === null || _this$websiteService2 === void 0 ? void 0 : _this$websiteService2.memberModel) || {};
      }

      if (!memberId || _nventor.default.isNilOrEmpty(memberModel) || refetchMember) {
        var _memberModel, _memberModel$_data;

        memberModel = await this.get('getMemberModelTask').perform(website);
        const memberId = ((_memberModel = memberModel) === null || _memberModel === void 0 ? void 0 : (_memberModel$_data = _memberModel._data) === null || _memberModel$_data === void 0 ? void 0 : _memberModel$_data._key) || '';

        if (memberId && _nventor.default.isNotNilOrEmpty(this === null || this === void 0 ? void 0 : this.websiteService)) {
          this.websiteService.memberModel = memberModel;
        }
      }

      return memberModel;
    },

    influencerLoginSuccessRedirect(response) {
      const url = R.path(['data', 'redirect'])(response);
      const influencerToken = R.path(['data', 'influencerToken'])(response);
      this.storage.setValueWithShopKey('influencerRedirectUrl', url, 1209600000); // 14 days

      this.storage.setValueWithShopKey('influencerToken', influencerToken, 1209600000); // 14 days

      window.open(url, '_self');
      return {
        isRedirect: true
      };
    },

    handleLoginResponse(website, {
      response = {},
      token
    }) {
      const isInfluencerLogin = R.path(['data', 'isInfluencerLogin'])(response);

      if (isInfluencerLogin) {
        return this.influencerLoginSuccessRedirect(response);
      }

      website.set('alternateLoginData', false);
      token = token || R.pathOr(false, ['data', 'token'], response);

      if (token) {
        const tokenData = this.decodeMemberToken(token);

        if (tokenData) {
          this.set('tokenData', tokenData);
          const storage = this.storage;
          storage.setValue('token', token);
          website.set('isLoggedIn', true);
          const memberModel = this.newMemberModel(website, tokenData);
          const signupFlows = R.pathOr([], ['data', 'signupFlows'], response);
          website.set('signupFlows', signupFlows);
          this.flowsService.updated = Math.random();
          return memberModel;
        }
      }

      const resetToken = R.pathOr(false, ['data', 'resetToken'], response);

      if (resetToken) {
        const tokenData = this.decodeMemberToken(resetToken);

        if (tokenData) {
          return true;
        }

        return false;
      }

      const hasAlternateLogin = R.pathOr(false, ['data', 'memberId'], response);

      if (hasAlternateLogin) {
        const hasAlternateLoginData = R.pathOr(false, ['data'], response);
        hasAlternateLoginData.header = 'already a member, please login';
        website.set('alternateLoginData', hasAlternateLoginData);
      }

      this.logout(website);
      return false;
    },

    decodeMemberToken(token) {
      return this.websiteService.decodeToken(token);
    },

    setInfluencerLoginData(data) {
      var _window, _window$location;

      const path = (_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.pathname;
      const params = this.websiteService.parseParamsFromWindowLocation(window.location);
      const isInfluencerRoute = R.includes('influencer')(path);

      if (isInfluencerRoute) {
        data.token = R.prop('token', params);
        data.url = path.split(/\//gm)[2];
      }

      return data;
    },

    /**
     * sends a request to the specified url from a form. this will change the window location.
     * @param {string} path the path to send the post request to
     * @param {object} params the parameters to add to the url
     * @param {string} [method=post] the method to use on the form
     */
    _formPost(path, params, method = 'post') {
      const form = document.createElement('form');
      form.method = method;
      form.action = path;

      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = params[key];
          form.appendChild(hiddenField);
        }
      }

      document.body.appendChild(form);
      form.submit();
    },

    bigordrAuthLogin: (0, _emberConcurrency.task)(function* (website, data) {
      const bigordrAuthLoginEndpoint = '/api/public/websites/members/auth/login';
      const podKey = website.get('podKey');
      data.login = true;
      data.podKey = podKey;
      data = this.setInfluencerLoginData(data);
      const response = yield this.websiteService.request(bigordrAuthLoginEndpoint, {
        method: 'POST',
        data
      });
      return this.handleLoginResponse(website, {
        response
      });
    }).drop(),
    bigordrAuthSignup: (0, _emberConcurrency.task)(function* (website, data) {
      const bigordrAuthSignupEndpoint = '/api/public/websites/members/auth/signup';
      const podKey = website.get('podKey');
      data.podKey = podKey;
      data.login = false;
      data.websiteKey = website.get('_data._key');
      data.shopKey = website.get('shopKey');
      data = this.setInfluencerLoginData(data);
      const response = yield this.websiteService.request(bigordrAuthSignupEndpoint, {
        method: 'POST',
        data
      });
      return this.handleLoginResponse(website, {
        response
      });
    }).drop(),
    socialAuth: (0, _emberConcurrency.task)(function* (website, {
      socialType,
      code,
      podKey
    }) {
      try {
        const response = yield this.websiteService.request(`/api/public/websites/members/auth/${socialType}/authenticate`, {
          method: 'POST',
          data: {
            code,
            podKey: podKey
          }
        });
        return this.handleLoginResponse(website, {
          response
        });
      } catch (e) {
        return this.handleLoginResponse(website, {});
      }
    }).drop(),
    updateMemberTask: (0, _emberConcurrency.task)(function* (website, member) {
      delete member._data.addresses;
      return yield this.updateMemberAddressesTask.perform(website, member);
    }).drop(),
    updateMemberAddressesTask: (0, _emberConcurrency.task)(function* (website, member) {
      const token = this.getToken(website);
      const data = member.serialize();
      data.podKey = website.get('podKey');
      data.websiteKey = website.get('_data._key');
      data.shopKey = website.get('shopKey');
      const locale = this.storage.getValue('locale') || 'zh-tw';
      data.locale = locale;
      const memberVerified = member.get('_data.memberVerified');

      if (memberVerified && !this.isVerification) {
        member.validate('profile', data);
        R.pipe(R.propOr([], 'addresses'), R.map(addressData => {
          if (addressData.hasPostCode) {
            member.validate('address', addressData);
          } else {
            member.validate('addressPostCodeNotRequired', addressData);
          }
        }))(data);
      } else {
        if (member.get('_data.emailVerified')) {
          member.validate('verification', data);
        } else {
          member.validate('verificationEmailUnverified', data);
        }
      }

      const response = yield this.websiteService.request('/api/protected/websites/members', {
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${token}`
        },
        data
      });
      return this.handleLoginResponse(website, {
        response
      });
    }).drop(),
    sendEmailVerificationTask: (0, _emberConcurrency.task)(function* (website, member) {
      const token = this.getToken(website);
      const data = member.serialize(); // delete data.addresses

      data.podKey = website.get('podKey');
      data.websiteKey = website.get('_data._key');
      data.shopKey = website.get('shopKey');
      const locale = this.storage.getValue('locale') || 'zh-tw';
      data.locale = locale;
      const response = yield this.websiteService.request('/api/protected/websites/members/verify-email/send', {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`
        },
        data
      });
      return this.websiteService.handleResponseMessages(website, response);
    }).drop(),
    verifyMemberEmailTask: (0, _emberConcurrency.task)(function* (website, token) {
      const response = yield this.websiteService.request('/api/protected/websites/members/verify-email/complete', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`
        },
        data: {}
      });
      this.handleLoginResponse(website, {
        response
      });
      return {
        verifyEmail: undefined
      };
    }).drop(),
    sendResetPasswordEmailTask: (0, _emberConcurrency.task)(function* (website, data) {
      data.podKey = website.get('podKey');
      data.websiteKey = website.get('_data._key');
      data.shopKey = website.get('shopKey');
      const response = yield this.websiteService.request('/api/public/websites/members/reset-password', {
        method: 'POST',
        data
      });
      return this.handleLoginResponse(website, {
        response
      });
    }).drop(),
    verifyMemberToken: (0, _emberConcurrency.task)(function* (website, payload) {
      const response = yield this.websiteService.request('/api/public/websites/members/verify-token', {
        method: 'POST',
        data: payload
      });
      return this.handleLoginResponse(website, {
        response
      });
    }).drop(),
    deleteCcTask: (0, _emberConcurrency.task)(function* (website, creditCard, options = {}) {
      try {
        const ccId = creditCard.ccId;

        if (!ccId) {
          throw new Error('cannot delete card');
        }

        const locale = this.storage.getValue('locale') || 'zh-tw';
        creditCard.locale = locale;
        const appendPath = R.propOr('', 'appendPath')(options);
        let url = '/api/protected/websites/members/credit-card';

        if (appendPath) {
          url = `${url}/${appendPath}`;
        }

        const msg = this.intl.t('are you sure you want to delete stored credit card');

        if (window.confirm(msg)) {
          const response = yield this.websiteService.request(url, {
            method: 'DELETE',
            data: creditCard
          });
          const responseData = this.handleLoginResponse(website, {
            response
          });

          if (options.reload) {
            window.location.reload(true);
          }

          return responseData;
        }
      } catch (e) {
        if (options.component) {
          options.component.errorMessage = 'error occurred';
        }
      }
    }).drop(),
    setNewPassword: (0, _emberConcurrency.task)(function* (website, payload) {
      const response = yield this.websiteService.request('/api/public/websites/members/set-new-password', {
        method: 'POST',
        data: payload
      });
      return this.handleLoginResponse(website, {
        response
      });
    }).drop(),
    loginAndRedirectToOriginalPageTask: (0, _emberConcurrency.task)(function* (website, socialType) {
      const params = this.websiteService.parseParamsFromWindowLocation(window.location);
      const hasCorrectParams = R.allPass([R.has('state'), R.has('code')])(params);

      if (!hasCorrectParams) {
        return this.router.transitionTo('/');
      }

      const podKey = website.get('podKey');
      const payload = {
        socialType,
        podKey,
        code: params.code
      };
      const member = yield this.socialAuth.perform(website, payload); // transition back to either /members or /shop/members

      if (!member) {
        return this.router.transitionTo('/');
      }

      const path = R.pipe(R.pathOr('', ['state']), atob, R.split('/'), R.drop(3), R.join('/'))(params);
      return this.router.transitionTo(`/${path}`);
    }).drop(),

    redirectToNextAfterLogin(website, isShopMembersRoute = false) {
      const hasItemsInCart = website.get('hasCartItems');
      let finalDestination = 'members.member';

      if (isShopMembersRoute) {
        if (hasItemsInCart) {
          finalDestination = 'shop.checkout.details';
        } else {
          finalDestination = `shop.${finalDestination}`;
        }
      }

      return this.router.transitionTo(finalDestination);
    },

    newMemberModel(website, memberData) {
      const applicationInstance = (0, _application.getOwner)(this);
      const memberModel = applicationInstance.lookup('users:main');
      memberModel.populate(memberData);
      website.set('user', memberModel);
      return memberModel;
    },

    setupGuestUserModel(website) {
      const applicationInstance = (0, _application.getOwner)(this);
      const userModel = applicationInstance.lookup('users:main');
      userModel.populate();
      const countries = website.get('shop._data.countries');
      userModel.setDefaultCountry(website, countries);
      website.set('user', userModel);
      return userModel;
    },

    getToken(website) {
      const token = this.storage.getValue('token');

      if (token) {
        website.set('isLoggedIn', true);
        return token;
      } else {
        website.set('isLoggedIn', false);
      }

      return false;
    },

    getShopAndWebsiteOrderTask: (0, _emberConcurrency.task)(function* (website) {
      const token = this.getToken(website);
      const response = yield this.websiteService.request('/api/protected/websites/members/order-history-list', {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      return R.propOr([], 'data')(response);
    }).drop(),
    getOrderHistoryTask: (0, _emberConcurrency.task)(function* ({
      website,
      podKey,
      sourceKey,
      source,
      params = {}
    }) {
      const token = this.getToken(website);
      params.podKey = podKey;
      params.sourceKey = sourceKey;
      params.source = source;
      const response = yield this.websiteService.request('/api/protected/websites/members/order-history', {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`
        },
        data: params
      });
      return R.propOr([], 'data')(response);
    }).drop(),

    getMemberProfileMenu({
      source = 'website',
      routeName,
      isShop = true
    }) {
      let linkToMember = 'members.member';
      let linkToMemberOrderHistory = 'members.order-history';
      let linkToMemberRewardHistory = 'members.reward-history';
      let linkToMemberPromoCodes = 'members.promo-codes';

      if (source === 'shop') {
        linkToMember = 'shop.members.member';
        linkToMemberOrderHistory = 'shop.members.order-history';
        linkToMemberRewardHistory = 'shop.members.reward-history';
        linkToMemberPromoCodes = 'shop.members.promo-codes';
      }

      const filter = {
        filterModel: {
          _data: {
            tags: [{
              label: 'profile',
              name: 'profile',
              linkTo: linkToMember
            }, {
              label: 'order history',
              name: 'order history',
              linkTo: linkToMemberOrderHistory
            }, {
              label: 'reward history',
              name: 'reward history',
              linkTo: linkToMemberRewardHistory
            }]
          }
        }
      };

      if (isShop) {
        filter.filterModel._data.tags.push({
          label: 'promo codes',
          name: 'promo codes',
          linkTo: linkToMemberPromoCodes
        });
      }

      return filter;
    },

    getPromoCodesListTask: (0, _emberConcurrency.task)(function* ({
      website,
      shopUrl,
      sourceKey,
      params = {}
    }) {
      const token = this.getToken(website);

      if (!token) {
        console.error('token is not available');
        return {};
      }

      params.podKey = website.podKey;
      params.sourceKey = sourceKey;
      params.url = shopUrl;
      const response = yield this.websiteService.request('/api/protected/websites/members/promo-codes-list', {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`
        },
        data: params
      });
      return R.propOr({}, 'data')(response);
    }).drop(),
    getRewardsListTask: (0, _emberConcurrency.task)(function* ({
      website
    }) {
      const token = this.getToken(website);
      const podKey = website.podKey || '';
      const response = yield this.websiteService.request('/api/protected/websites/members/rewards-list', {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`
        },
        data: {
          podKey
        }
      });
      return R.propOr([], 'data')(response);
    }).drop(),
    fetchRewardHistoryTask: (0, _emberConcurrency.task)(function* ({
      website,
      podKey = '',
      params = {}
    }) {
      var _website$_data;

      const token = this.getToken(website);
      params.podKey = podKey || website.podKey || '';
      params.shopKey = website.shopKey || (website === null || website === void 0 ? void 0 : (_website$_data = website._data) === null || _website$_data === void 0 ? void 0 : _website$_data._key) || '';
      let source = 'website';

      if (website.shopKey) {
        source = 'shop';
      }

      params.source = source;
      const response = yield this.websiteService.request('/api/protected/websites/members/reward-history', {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`
        },
        data: params
      });
      return R.propOr([], 'data')(response);
    }).drop(),
    sendReferralRewardTask: (0, _emberConcurrency.task)(function* ({
      website,
      shop,
      recipientEmail,
      referralCode = ''
    }) {
      const token = this.getToken(website);
      const data = {
        websiteKey: website.get('_data._key') || '',
        shopKey: shop.get('_data._key') || website.get('_data.shopKey') || '',
        podKey: website.get('_data.podKey') || shop.get('_data.podKey') || '',
        recipientEmail,
        referralCode,
        isSendReferralEmail: true
      };
      const shopUrl = shop.url || '';
      const response = yield this.websiteService.request(`/api/public/websites/members/${shopUrl}/referral`, {
        method: 'POST',
        data
      });
      return response;
    }).drop()
  }, (_applyDecoratedDescriptor(_obj, "logout", [_object.action], Object.getOwnPropertyDescriptor(_obj, "logout"), _obj), _applyDecoratedDescriptor(_obj, "influencerLoginSuccessRedirect", [_object.action], Object.getOwnPropertyDescriptor(_obj, "influencerLoginSuccessRedirect"), _obj), _applyDecoratedDescriptor(_obj, "handleLoginResponse", [_object.action], Object.getOwnPropertyDescriptor(_obj, "handleLoginResponse"), _obj)), _obj));

  _exports.default = _default;
});
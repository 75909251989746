define("shop/pods/shop/orders/index/route", ["exports", "@ember/routing/route", "shop/mixins/orders-query"], function (_exports, _route, _ordersQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_ordersQuery.default, {
    queryParams: {
      email: {
        refreshModel: false
      },
      ref: {
        refreshModel: false
      },
      errors: {
        refreshModel: false
      }
    },

    async model(params) {
      return this._model(params);
    },

    redirect(model) {
      this._redirect(model);
    }

  });

  _exports.default = _default;
});
define("shop/mixins/big", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Big */
  // import { inject as service } from '@ember/service'
  var _default = _mixin.default.create({
    /**
     * create new big number
     * @param  {string|integer|float} number
     * @return {class} big number class instance
     */
    newBig: function (number) {
      // if (!this.get('fastboot.isFastBoot')) {
      number = parseFloat(number);
      return new Big(number || 0); // }
      //
      // return;
    },

    /**
     * create new big number from object's property value
     * @param  {string} property name
     * @return {class} big number class instance
     */
    getBig: function (prop) {
      // if (!this.get('fastboot.isFastBoot')) {
      var number = parseFloat(this.get(prop));
      return new Big(number || 0); // }
      //
      // return;
    }
  });

  _exports.default = _default;
});
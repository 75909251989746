define("shop/pods/components/layouts/nav-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k6GXbqS1",
    "block": "[[[10,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\"],[\"fas fa-bars\"]],null],[1,\"\\n\"],[13]],[],false,[\"elements/element-icon\"]]",
    "moduleName": "shop/pods/components/layouts/nav-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
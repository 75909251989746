define("shop/pods/website/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ar4X57G6",
    "block": "[[[8,[39,0],null,[[\"@isIndex\",\"@website\",\"@shop\",\"@page\"],[false,[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"page\"]]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@website\",\"@page\",\"@shop\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"shop\"]]]],null],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"website/website-container\",\"website/website-page\"]]",
    "moduleName": "shop/pods/website/page/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NnJqY29J",
    "block": "[[[8,[39,0],null,[[\"@website\",\"@page\",\"@shop\",\"@params\",\"@query\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"params\"]],[30,0,[\"model\",\"params\",\"query\"]]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"website/website-container\",\"layouts/page-content\",\"component\",\"-outlet\"]]",
    "moduleName": "shop/pods/orders/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
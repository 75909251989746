define("shop/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OQn+lXHV",
    "block": "[[[41,[30,0,[\"model\",\"isMaintenance\"]],[[[1,\"  \"],[10,0],[14,0,\"section has-text-centered\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"您好，於今日03:00AM - 06:00AM期間進行系統維護，目前暫停服務中，造成不便敬請見諒！\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"website\",\"websiteService\",\"isShowDrawerForShopItem\"]],[[[1,\"    \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"component\",\"-outlet\",\"items/item-drawer\"]]",
    "moduleName": "shop/pods/application/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
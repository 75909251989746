define("shop/pods/components/filters/search-filters/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XteAm30e",
    "block": "[[[10,0],[14,0,\"flex-container-column flex-container--align-items-center\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"search-bar__container is-visible is-expanded\"],[4,[38,0],[[28,[37,1],[[30,0,[\"focus\"]]],null]],null],[12],[1,\"\\n    \"],[8,[39,2],[[16,\"autofocus\",true],[24,0,\"search-bar__input is-expanded\"],[16,\"placeholder\",[28,[37,3],[[28,[37,4],[\"search\"],null],\": \",[28,[37,4],[\"all products\"],null]],null]]],[[\"@value\",\"@enter\"],[[30,0,[\"query\"]],[28,[37,5],[[30,1],[30,0,[\"query\"]]],null]]],null],[1,\"\\n\\n    \"],[11,3],[24,0,\"search-bar__btn\"],[4,[38,6],[\"click\",[28,[37,5],[[30,1],[30,0,[\"query\"]]],null]],null],[12],[1,\"\\n      \"],[8,[39,7],null,[[\"@icon\"],[\"fas fa-search\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@onSearch\"],false,[\"did-insert\",\"fn\",\"input\",\"concat\",\"tt\",\"perform\",\"on\",\"elements/element-icon\"]]",
    "moduleName": "shop/pods/components/filters/search-filters/search-bar/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
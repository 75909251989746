define("shop/pods/shop/shop-route-mixin", ["exports", "ramda", "@ember/object/mixin", "rsvp", "shop/utils/nventor", "shop/pods/page/model", "@ember/service"], function (_exports, R, _mixin, _rsvp, _nventor, _model, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    socialLoginService: (0, _service.inject)('social-login'),
    websiteService: (0, _service.inject)('website'),
    router: (0, _service.inject)('router'),
    seoService: (0, _service.inject)('seo'),

    redirect(model) {
      if (!model) {
        return this.transitionTo('/');
      }
    },

    async _model(params = {}, {
      fetchMember = false,
      requireMember = false,
      ordersList = [],
      rewardsList = [],
      promoCodesList = []
    } = {}) {
      try {
        const profileMenuLists = this.membersService.getMemberProfileMenu({
          source: 'shop',
          routeName: this.routeName
        }); // reload is not bolean

        if (R.propEq('reload', 'true')(params)) {
          _nventor.default.removeAndUpdateQueryParams(['reload']);
        }

        const fromRoute = R.propOr('', 'from', params);
        const edit = R.propOr('', 'edit', params);
        let isEditingProfile = false;
        let isEditingAddress = false;

        if (fromRoute === 'checkout') {
          if (edit === 'profile') {
            isEditingProfile = true;
          }

          if (edit === 'address') {
            isEditingAddress = true;
          }
        }

        let shop = R.prop('shop', this.modelFor('application'));

        if (!shop) {
          shop = R.prop('shop', this.modelFor('shop'));
        }

        if (!shop) {
          // this should never run for websites with shop. that is fetched by application route
          shop = await this.shopService.get('getShopWithoutWebsiteTask').perform({
            params
          });
        }

        const website = this.modelFor('application').website;
        const websiteWithoutShopKey = R.pipe(R.pathOr('', ['_data', 'shopKey']), _nventor.default.isNilOrEmpty)(website); // SET DYNAMIC TITLE ONLY FOR BIGORDR SHOPS

        if (website.isBigOrdr) {
          const title = R.path(['_data', 'name'])(shop) || document.title;
          this.websiteService.updateTitle(title);
        }

        const shopPage = website.getDynamicPage('shop');
        /*
          Omit redirect 404 when accessing a subpage like products or search results under shop route.
          Ignore transitions to shop.index, since the shopPage is checking for inactive status
        */

        const transitTo = R.pathOr('', ['router', '_router', 'currentState', 'router', 'activeTransition', 'targetName'])(this);
        const isShopSubPageAllow = R.includes('shop.')(transitTo) && !R.includes('shop.index')(transitTo);
        const isShopIndexPage = transitTo === 'shop.index';

        if (_nventor.default.isNilOrEmpty(shopPage) && !isShopSubPageAllow) {
          // no index
          this.seoService.setBlockIndexing();
        }

        const homePageRedirect = R.propEq('homePageRedirect', true)(shopPage);

        if (homePageRedirect && isShopIndexPage) {
          return this.transitionTo('/');
        }

        const page = _model.default.create();

        page.populate(shopPage);
        website.setupShop(shop);
        let userModel;
        const isInfluencerRoute = this.socialLoginService.isInfluencerRoute;

        if (!isInfluencerRoute && (fetchMember || requireMember)) {
          let refetchMember = false;

          if (shop.hasReward) {
            var _shop, _this$websiteService;

            // when website doesnt have shopKey, need to refetch member to get reward data
            refetchMember = websiteWithoutShopKey && _nventor.default.isNotNilOrEmpty((_shop = shop) === null || _shop === void 0 ? void 0 : _shop.shopKey);
            const existingMemberModel = ((_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : _this$websiteService.memberModel) || {};
            const existingMemberModelHasRewardData = R.pipe(R.pathOr('', ['_data', 'memberLevelId']), _nventor.default.isNotNilOrEmpty)(existingMemberModel); // only fetch member when no reward data found

            refetchMember = refetchMember && !existingMemberModelHasRewardData;
          }

          userModel = await this.membersService.getStoredMemberModel({
            website,
            refetchMember
          });

          if (!userModel && requireMember) {
            const routeName = this.currentRouteName;

            if (R.startsWith('shop', routeName)) {
              return this.transitionTo('shop.members');
            } else {
              return this.transitionTo('members');
            }
          }
        }

        const websiteService = this.websiteService;

        if (_nventor.default.isNotNilOrEmpty(websiteService.memberModel)) {
          userModel = websiteService === null || websiteService === void 0 ? void 0 : websiteService.memberModel;
        }

        if (!userModel) {
          // setup guest user heres
          userModel = this.membersService.setupGuestUserModel(website);
        }

        const isCampaign = this.modelFor('application').isCampaign;
        return _rsvp.default.hash({
          shop,
          user: userModel,
          isEditingAddress,
          isEditingProfile,
          params,
          website,
          isCampaign,
          page,
          ordersList,
          rewardsList,
          promoCodesList,
          profileMenuLists
        });
      } catch (e) {
        console.log('shop route error', e);
        return this.transitionTo('/');
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/elements/delivery-info/arrival-date-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "me/kPrFK",
    "block": "[[[8,[39,0],null,[[\"@optionLabelKey\",\"@optionValueKey\",\"@options\",\"@translate\",\"@value\"],[\"label\",\"label\",[30,0,[\"options\"]],true,[30,1]]],null],[1,\"\\n\"]],[\"@value\"],false,[\"elements/element-select\"]]",
    "moduleName": "shop/pods/components/elements/delivery-info/arrival-date-selector/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
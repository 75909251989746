define("shop/pods/components/form-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PAinFOAH",
    "block": "[[[10,0],[15,0,[29,[\"field \",[36,0]]]],[12],[1,\"\\n\"],[41,[33,2],[[[1,\"    \"],[10,\"label\"],[14,0,\"label u-inline-block\"],[12],[1,[28,[35,3],[[33,2]],[[\"translate\"],[[33,4]]]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,5],[[[1,\"    \"],[10,\"i\"],[14,0,\"fas fa-question-circle rewards-tooltip\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"rewards-tooltiptext\"],[12],[1,[28,[35,3],[[33,5]],[[\"translate\"],[[33,6]]]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,7],[[[1,\"    \"],[1,[28,[35,8],null,[[\"translate\",\"class\",\"label\"],[[33,9],\"form-row__sub-label\",[33,7]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[15,0,[29,[\"control \",[30,1]]]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\\n\"],[41,[33,11],[[[1,\"      \"],[10,2],[14,0,\"error-msg has-text-danger has-text-right\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[[33,11]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@controlClassNames\",\"&default\"],false,[\"field\",\"if\",\"label\",\"tt\",\"translate\",\"tooltip\",\"tooltipTranslate\",\"subLabel\",\"elements/element-label\",\"translateSubLabel\",\"yield\",\"hasError\"]]",
    "moduleName": "shop/pods/components/form-row/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/shop/orders-query/orders-status/order-display/recurring-docs/component", ["exports", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShopOrdersQueryOrdersStatusOrderDisplayRecurringDocsComponent = (_class = class ShopOrdersQueryOrdersStatusOrderDisplayRecurringDocsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "date", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);
    }

    get isShowPaymentLink() {
      var _this$args;

      const docData = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.recurringDocData) || {};
      const paymentMethodKey = docData.paymentMethodKey || '';
      const recurringGroupId = docData.recurringGroupId || '';
      let isNotYetPaid = false;

      if (paymentMethodKey === 'creditCard' && recurringGroupId) {
        const paymentCreditCardStatus = docData.paymentCreditCardStatus || '';
        isNotYetPaid = paymentCreditCardStatus !== 'approved';
      }

      return isNotYetPaid;
    }

    onClickRedirectToPaymentPage() {
      var _this$args2;

      const docData = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.recurringDocData) || {};
      const externalUrl = docData.externalUrl || '';
      const paymentMethodKey = docData.paymentMethodKey || '';
      const recurringGroupId = docData.recurringGroupId || '';
      const isRecurringOrderWithCreditCard = paymentMethodKey === 'creditCard' && recurringGroupId;
      const nowZ = this.date.getNowZ();
      const paymentDueDateZ = docData.paymentDueDateZ || '';
      const afterPaymentDueDateZ = this.date.getMoment(paymentDueDateZ).add(7, 'hours').toISOString();
      const formattedAfterPaymentDueDateZ = this.date.getMoment(afterPaymentDueDateZ).format('YYYY-MM-DD hh:mm');
      const isDue = nowZ >= afterPaymentDueDateZ;
      let confirmed = true;

      if (isRecurringOrderWithCreditCard && !isDue) {
        const msg = this.intl.t('pay before payment due date', {
          date: formattedAfterPaymentDueDateZ
        });
        confirmed = window.confirm(msg);
      }

      if (confirmed) {
        this.router.transitionTo('erp.payments.card.doc', externalUrl);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "date", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onClickRedirectToPaymentPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickRedirectToPaymentPage"), _class.prototype)), _class);
  _exports.default = ShopOrdersQueryOrdersStatusOrderDisplayRecurringDocsComponent;
});
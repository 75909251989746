define("shop/pods/components/countdown-timer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A0aCcm8x",
    "block": "[[[11,0],[16,0,[29,[\"timer-container is-size-7 flex-container-row flex-container--justify-center \",[30,1]]]],[16,5,[30,0,[\"timerStyle\"]]],[4,[38,0],[[28,[37,1],[[30,0,[\"setupTask\"]]],null]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"timer-centered\"],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[\"timer \",[52,[30,0,[\"showTimer\"]],\"animate__animated bigordr-animate__fade-in\",\"u-hidden-placeholder\"]]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"\"],[12],[1,\"\\n        \"],[1,[30,0,[\"days\"]]],[1,\" \"],[10,1],[14,0,\"timer__item--day-label\"],[12],[1,[28,[35,3],[\"days\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"timer__item\"],[12],[1,\"\\n        \"],[1,[30,0,[\"hours\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[12],[1,\"\\n        :\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"timer__item\"],[12],[1,\"\\n        \"],[1,[30,0,[\"minutes\"]]],[1,\"\\n      \"],[13],[1,\"\\n        \"],[10,0],[12],[1,\"\\n          :\\n        \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"timer__item\"],[12],[1,\"\\n        \"],[1,[30,0,[\"seconds\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@timerContainerClassNames\"],false,[\"did-insert\",\"perform\",\"if\",\"tt\"]]",
    "moduleName": "shop/pods/components/countdown-timer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
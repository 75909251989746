define("shop/pods/components/flows/flow-chat-window/flow-message/message-type/component", ["exports", "@glimmer/component", "@glimmer/tracking", "ember-concurrency-decorators", "@ember/service", "@ember/object"], function (_exports, _component, _tracking, _emberConcurrencyDecorators, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // import { computed } from '@ember/object'
  let FlowsFlowChatWindowFlowMessageMessageTypeComponent = (_dec = (0, _service.inject)('config'), _dec2 = (0, _service.inject)('flows'), (_class = class FlowsFlowChatWindowFlowMessageMessageTypeComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "configService", _descriptor, this);

      _initializerDefineProperty(this, "flowsService", _descriptor2, this);

      _initializerDefineProperty(this, "isModalVisible", _descriptor3, this);

      _initializerDefineProperty(this, "currentFlow", _descriptor4, this);

      this.setupTask.perform();
    }

    *setupTask() {
      this.currentFlow = this.args.flow;
      const step = this.args.step;
      const website = this.args.website;

      if (!step.hasRunStep) {
        if (step.actionType === 'games') {
          step.set('hasRunStep', true);
          step.set('isModalVisible', true);
        }

        if ((step === null || step === void 0 ? void 0 : step.stepType) === 'replyText') {
          step.set('hasRunStep', true);
          yield this.flowsService.autoFillTask.perform({
            step
          });
        }
      }
    }

    *autoTriggerAction() {
      if (this.args.autoTriggerStepAction) {
        const step = this.args.step;

        if (!step.hasRunStep) {
          step.set('hasRunStep', true);
          return yield this.args.autoTriggerStepAction.perform({
            step
          });
        }
      }
    }

    closeModalDialog(step) {
      step.set('isModalVisible', false);
    } // hasButtons: computed('flowPayload', 'step.buttons.[]', function () {
    //   const buttons = this.get('step.buttons') || []
    //   if (buttons.length > 0) {
    //     const flowPayload = this.get('flowPayload') || {}
    //     let match = true
    //     R.forEach(button => {
    //       const onlyWhen = R.path(['metadata', 'onlyWhen'])(button) || {}
    //       const keys = R.keys(onlyWhen)
    //       R.forEach(key => {
    //         if (onlyWhen[key] !== flowPayload[key]) {
    //           match = false
    //         }
    //       })(keys)
    //     })(buttons)
    //     return match
    //   }
    //   return false
    // })


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "configService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flowsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentFlow", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autoTriggerAction", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "autoTriggerAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModalDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModalDialog"), _class.prototype)), _class));
  _exports.default = FlowsFlowChatWindowFlowMessageMessageTypeComponent;
});
define("shop/pods/components/layouts/nav-menu-nested/component", ["exports", "@ember/component", "jquery", "@ember/service", "@ember/object"], function (_exports, _component, _jquery, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    websiteService: (0, _service.inject)('website'),
    tagName: 'div',
    classNames: ['nav-tree'],
    classNameBindings: ['menuExpandAlignment'],
    translate: false,
    disabled: false,
    labelKey: 'name',
    subProperty: 'sub',

    didInsertElement() {
      this._super(...arguments);

      if (this.get('website.menuPosition') === 'right' && !this.get('website.menuIsAlwaysHidden') && !this.isFooter) {
        const container = (0, _jquery.default)(this.element);
        const width = container.outerWidth();
        const left = container.offset().left;
        const right = left + width;
        const viewportRight = (0, _jquery.default)(window).width();

        if (right > viewportRight) {
          const rightDifference = right - viewportRight + 10;
          const maxLeft = 5;
          const newLeft = left - rightDifference;

          if (newLeft > maxLeft) {
            container.css('right', 10);
          }
        }
      }
    },

    menuExpandAlignment: (0, _object.computed)('website.{menuExpandBehavior,menuExpandAlignment}', function () {
      var _this$website, _this$website2;

      const menuExpandBehavior = (this === null || this === void 0 ? void 0 : (_this$website = this.website) === null || _this$website === void 0 ? void 0 : _this$website.menuExpandBehavior) || 'autoHide';

      if (menuExpandBehavior === 'autoHide') {
        return 'flex-container--justify-start';
      }

      const alignment = (this === null || this === void 0 ? void 0 : (_this$website2 = this.website) === null || _this$website2 === void 0 ? void 0 : _this$website2.menuExpandAlignment) || 'left';

      if (alignment === 'center') {
        return 'flex-container--justify-center';
      }

      if (alignment === 'right') {
        return 'flex-container--justify-end';
      }

      return 'flex-container--justify-start';
    }),
    actions: {
      toggleExpand(component) {
        component.toggleProperty('isExpanded');
      }

    }
  });

  _exports.default = _default;
});
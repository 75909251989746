define("shop/pods/orders/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jnPvTPsX",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@params\",\"@autoFetch\",\"@email\",\"@ref\"],[[30,0,[\"model\"]],[30,0,[\"model\",\"params\"]],true,[30,0,[\"model\",\"params\",\"email\"]],[30,0,[\"model\",\"params\",\"ref\"]]]],null]],[],false,[\"shop/orders-query/orders-status\"]]",
    "moduleName": "shop/pods/orders/status/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
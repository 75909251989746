define("shop/pods/seo/service", ["exports", "@ember/service", "ramda", "shop/utils/nventor"], function (_exports, _service, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SeoService = (_dec = (0, _service.inject)('structured-data'), (_class = class SeoService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "structuredDataService", _descriptor, this);
    }

    _getImages(ProductModel) {
      const images = (ProductModel === null || ProductModel === void 0 ? void 0 : ProductModel.images) || (ProductModel === null || ProductModel === void 0 ? void 0 : ProductModel.uploadedFiles) || [];
      const imagesUrls = R.pipe(R.pluck('uuid'), R.map(imageUuid => this._getImageUrl(imageUuid)))(images);
      return imagesUrls;
    }

    _getImageUrl(imageUuid) {
      return `https://ucarecdn.com/${imageUuid}/-/format/webp/-/progressive/yes/`;
    }
    /**
     * Set meta tags
     * @param {string} description
     * @param {string} keywords
     * @param {object} openGraphData
     * @param {string} openGraphData.type
     * @param {string} openGraphData.title
     * @param {Array<string>} openGraphData.image
     * @param {object} openGraphData.price
     * @param {string} openGraphData.price.amount
     * @param {string} openGraphData.price.currency
     * @param {string} openGraphData.description
     * @param {string} openGraphData.url
     * @param {string} openGraphData.site_name
     */


    setMetaTags({
      description = '',
      keywords = '',
      openGraphData
    } = {}) {
      if (!R.isNil(description)) {
        this.setMetaTag('description', description);
      }

      if (!R.isNil(keywords)) {
        this.setMetaTag('keywords', keywords);
      }

      const hasOpenGraph = !!openGraphData;

      if (!hasOpenGraph) {
        return;
      }

      if (openGraphData.type) {
        this.setOpenGraphTag('type', openGraphData.type);
      }

      if (openGraphData.title) {
        this.setOpenGraphTag('title', openGraphData.title);
      }

      if (!R.isNil(openGraphData.description)) {
        this.setOpenGraphTag('description', openGraphData.description);
      }

      if (openGraphData.url) {
        this.setOpenGraphTag('url', openGraphData.url);
      }

      if (openGraphData.site_name) {
        this.setOpenGraphTag('site_name', openGraphData.site_name);
      }

      if (openGraphData.price) {
        this.setOpenGraphTag('price:amount', openGraphData.price.amount);
        this.setOpenGraphTag('price:currency', openGraphData.price.currency);
      }

      if (openGraphData.image) {
        R.forEach(img => {
          const httpHref = R.replace('https', 'http')(img);
          this.setOpenGraphTag('image', httpHref);
          this.setOpenGraphTag('image:secure_url', img);
        })(openGraphData.image);
      }
    }

    setMetaTag(name, content) {
      if (_nventor.default.isNilOrEmpty(content)) {
        return;
      }

      const metaDescription = document.head.querySelector(`meta[name="${name}"]`);

      if (metaDescription) {
        metaDescription.content = content;
      } else {
        const meta = document.createElement('meta');
        meta.setAttribute('name', name);
        meta.setAttribute('content', content);
        document.head.appendChild(meta);
      }
    }

    setOpenGraphTag(property, content) {
      const metaDescription = document.head.querySelector(`meta[property="og:${property}"]`);

      if (metaDescription) {
        metaDescription.content = content;
      } else {
        const meta = document.createElement('meta');
        meta.setAttribute('property', `og:${property}`);
        meta.setAttribute('content', content);
        document.head.appendChild(meta);
      }
    }

    setProductRichResultsSeo(product = {}, options = {}) {
      // const itemStatus = options?.itemStatus || {}
      const productData = product._data || {}; // const script = document.createElement('script')
      // script.type = 'application/ld+json'
      // const productStatusSchema = {
      //   preOrder: 'https://schema.org/PreOrder',
      //   outOfStock: 'https://schema.org/OutOfStock',
      //   soldOut: 'https://schema.org/SoldOut',
      //   inStock: 'https://schema.org/InStock'
      // }
      // let availability = ''
      // if (itemStatus.isPreOrder) {
      //   availability = productStatusSchema.preOrder
      // } else if (itemStatus.isOutOfStock) {
      //   availability = productStatusSchema.outOfStock
      // } else if (itemStatus.isSoldOut) {
      //   availability = productStatusSchema.soldOut
      // } else {
      //   availability = productStatusSchema.inStock
      // }

      const p = document.createElement('p');
      p.innerHTML = productData.description;
      const description = p.innerText.replace(/[\n\t]/gm, ' ').trim();
      let href = '';

      try {
        var _window, _window$location;

        href = (_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.href;
      } catch (error) {// Dp nothing
      }

      const images = this._getImages(product); // const productMetadata = {
      //   '@context': 'https://schema.org/',
      //   '@id': href,
      //   '@type': 'Product',
      //   productID: productData.itemKey,
      //   name: productData.item,
      //   image: images,
      //   description,
      //   category: '__generic',
      //   sku: '0446310786',
      //   mpn: '925872',
      //   brand: {
      //     '@type': 'Brand',
      //     name: productData.brand
      //   },
      //   url: href,
      //   offers: {
      //     '@type': 'Offer',
      //     priceCurrency: 'TWD',
      //     price: productData.priceFinal,
      //     itemCondition: 'https://schema.org/NewCondition',
      //     availability
      //   }
      // }
      // script.innerHTML = JSON.stringify(productMetadata, null, 2)
      // document.head.appendChild(script)
      // Set Meta Tags


      const seoDescription = productData.seoDescription || description;
      this.setMetaTags({
        description: seoDescription,
        keywords: productData.seoKeywords || productData.item,
        openGraphData: {
          type: 'product',
          title: productData.item,
          image: images,
          price: {
            amount: productData.priceFinal,
            currency: 'TWD'
          },
          description: seoDescription,
          url: href,
          site_name: ''
        }
      });
    }
    /**
     * Update meta tags when value is empty
     * @param metadata
     * @example
     * // Update description with the value keywords if description is empty, otherwise do nothing
     * const keywords = 'a,b,c,d'
     * this.updateMetaWhenEmpty({ description: keywords })
     */


    updateMetaWhenEmpty(metadata = {}) {
      R.forEachObjIndexed((value, key) => {
        const htmlDescription = document.querySelector(`meta[name="${key}"]`); // const hasContent = htmlDescription?.content || false

        const isDifferentContent = (htmlDescription === null || htmlDescription === void 0 ? void 0 : htmlDescription.content) !== value;

        if (isDifferentContent) {
          this.setMetaTags({
            [key]: value,
            openGraphData: {
              [key]: value,
              type: 'website'
            }
          });
        }
      })(metadata);
    }

    setupStructuredData({
      structuredData = [],
      isNewStruct = false,
      wrapOnGraphKey = true
    }) {
      try {
        let permanentStructuredDataScripts = [];

        if (!isNewStruct) {
          permanentStructuredDataScripts = this.structuredDataService.getPermanentStructuredData();
        }

        let newGraph = [];
        R.forEach(structuredData => {
          var _data;

          let data = (structuredData === null || structuredData === void 0 ? void 0 : structuredData.data) || {};

          if (_nventor.default.isNotNilOrEmpty((_data = data) === null || _data === void 0 ? void 0 : _data._data)) {
            data = data._data;
          }

          const type = structuredData === null || structuredData === void 0 ? void 0 : structuredData.type;
          const additionalData = (structuredData === null || structuredData === void 0 ? void 0 : structuredData.additionalData) || {};
          additionalData.model = structuredData === null || structuredData === void 0 ? void 0 : structuredData.data; // only for product

          const isPermanent = (structuredData === null || structuredData === void 0 ? void 0 : structuredData.isPermanent) || false;
          const graph = this.structuredDataService.getStructuredData({
            type,
            data,
            additionalData,
            isPermanent
          });

          if (_nventor.default.isNotNilOrEmpty(graph)) {
            newGraph = newGraph.concat(graph);
          }
        })(structuredData);
        const data = R.pipe(R.concat(permanentStructuredDataScripts), R.uniq)(newGraph);
        this.structuredDataService.setupStructuredData({
          data,
          isNewStruct,
          wrapOnGraphKey
        });
      } catch (e) {
        // @TODO: send email
        console.log('error', e);
      }
    }

    setBlockIndexing() {
      this.setMetaTag('robots', 'noindex');
      this.setMetaTag('googlebot', 'noindex');
      this.setMetaTag('AdsBot-Google', 'noindex');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "structuredDataService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SeoService;
});
define("shop/pods/application/route", ["exports", "ramda", "@ember/routing/route", "@ember/service", "shop/utils/nventor", "@ember/object", "@glimmer/tracking", "@ember/runloop", "rsvp"], function (_exports, R, _route, _service, _nventor, _object, _tracking, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // export default Route.extend({
  let ApplicationRoute = (_dec = (0, _service.inject)('config'), _dec2 = (0, _service.inject)('shop'), _dec3 = (0, _service.inject)('members'), _dec4 = (0, _service.inject)('checkout'), _dec5 = (0, _service.inject)('website'), _dec6 = (0, _service.inject)('seo'), _dec7 = (0, _service.inject)('flows'), _dec8 = (0, _service.inject)('storage'), _dec9 = (0, _service.inject)('social-login'), (_class = class ApplicationRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "configService", _descriptor2, this);

      _initializerDefineProperty(this, "shopService", _descriptor3, this);

      _initializerDefineProperty(this, "membersService", _descriptor4, this);

      _initializerDefineProperty(this, "checkoutService", _descriptor5, this);

      _initializerDefineProperty(this, "websiteService", _descriptor6, this);

      _initializerDefineProperty(this, "seoService", _descriptor7, this);

      _initializerDefineProperty(this, "router", _descriptor8, this);

      _initializerDefineProperty(this, "flowsService", _descriptor9, this);

      _initializerDefineProperty(this, "storage", _descriptor10, this);

      _initializerDefineProperty(this, "socialLoginService", _descriptor11, this);

      _initializerDefineProperty(this, "isMaintenance", _descriptor12, this);

      _defineProperty(this, "queryParams", {
        verifyEmail: {
          refreshModel: true
        },
        relogin: {
          refreshModel: true
        },
        success: {
          refreshModel: true
        },
        error: {
          refreshModel: true
        },
        flow: {
          refreshModel: false
        },
        f: {
          refreshModel: false
        },
        promo: {
          refreshModel: false
        },
        locale: {
          refreshModel: true
        },
        RID: {
          refreshModel: true
        },
        Click_ID: {
          refreshModel: true
        },
        fbclid: {
          refreshModel: true
        }
      });
    }

    init() {
      super.init(...arguments);
      const params = this.websiteService.parseParamsFromWindowLocation(window.location) || {}; // Remove corrupt cachep

      if ((params === null || params === void 0 ? void 0 : params.clearCache) === 'true') {
        this.storage.clearAll();
        let urlOrigin = `${window.location.origin}${window.location.pathname}`;
        const urlWithoutRemoveCache = `${window.location.search.replace('?clearCache=true', '')}`;

        if (urlWithoutRemoveCache) {
          urlOrigin += `?${urlWithoutRemoveCache}`;
        }

        window.location.replace(urlOrigin);
      }

      this.addErrorLogging(); // prevent url ended with =

      const currentUrl = window.location.href;

      if (currentUrl && currentUrl.endsWith('=')) {
        const fixedUrl = currentUrl.slice(0, -1);
        window.location.replace(fixedUrl);
      }
    }

    didTransition(transition) {
      const routeBeforeTransition = this.router.currentURL;
      (0, _runloop.scheduleOnce)('afterRender', this, function () {
        const page = this.router.currentURL;

        if (routeBeforeTransition && routeBeforeTransition != page) {
          if (window.gtag) {
            window.gtag('event', 'page_view', {
              page_title: page,
              page_path: page
            });
          }

          if (window.dataLayer) {
            dataLayer.push({
              event: 'pageview'
            });
          }
        }
      });
      return true;
    }

    async model(params) {
      window.version = this.configService.config.APP.version;

      if (this.isMaintenance) {
        return {
          isMaintenance: true
        };
      }

      try {
        const website = await this.websiteService.get('getWebsiteTask').perform({
          params
        });
        const relogin = R.propOr(false, 'relogin', params);

        if (relogin) {
          website.set('relogin', 'true');
          return this.transitionTo({
            queryParams: {
              verifyEmail: undefined,
              relogin: undefined
            }
          });
        }

        const success = R.propOr('', 'success')(params);

        if (success) {
          website.set('success', success);
        }

        const error = R.propOr('', 'error')(params);

        if (error) {
          website.set('error', error);
        }

        _nventor.default.removeAndUpdateQueryParams(['success', 'error']);

        if (params.verifyEmail) {
          const redirectQueryParams = await this.membersService.get('verifyMemberEmailTask').perform(website, params.verifyEmail);
          return this.transitionTo({
            queryParams: redirectQueryParams
          });
        }

        let shop = false;
        let isCampaign = true;

        if (website.get('shopKey')) {
          isCampaign = false;
          shop = this.shopService.getShopModelFrom(website);
          this.websiteService.trackIsFromTwShopDotCom({
            params
          });
        }

        const isInfluencerRoute = this.socialLoginService.isInfluencerRoute;
        let memberModel;

        if (!isInfluencerRoute) {
          // is it needed to fetch member when fetching website (possible to not have shopKey)?
          // what case it need member for website (no shop)
          memberModel = await this.membersService.getStoredMemberModel({
            website
          });
        }

        if (shop) {
          // check for previous checkout
          await this.checkoutService.queryCheckoutStatus.perform(shop);
          this.websiteService.setFacebookClickId({
            params
          });
        }

        if (!_nventor.default.isNilOrEmpty(params.promo)) {
          website.set('success', 'promo code applied');
          this.shopService.setPromoCode(params.promo);
        }

        this.websiteService.addTrackersForWebsite(website);

        if (shop) {
          this.websiteService.addTrackersForShop(shop);
        }

        return _rsvp.default.hash({
          website,
          shop,
          member: memberModel,
          params,
          isCampaign
        });
      } catch (e) {
        if (this.configService.isProduction) {
          return false;
        }

        throw e;
      }
    }

    afterModel(model) {
      if (this.isMaintenance) {
        return model;
      }

      if (model) {
        const websiteService = this.websiteService;
        websiteService.setupTitle(model.website);
        websiteService.setupFavicon(model.website);
        websiteService.setupTheme(model.website);
        const currentUrl = window.location.href;

        if (!R.includes('/shop/')(currentUrl)) {
          var _model$params, _model$params2;

          this.flowsService.autoStartFlowTask.perform({
            flowName: (model === null || model === void 0 ? void 0 : (_model$params = model.params) === null || _model$params === void 0 ? void 0 : _model$params.flow) || '',
            flowKey: (model === null || model === void 0 ? void 0 : (_model$params2 = model.params) === null || _model$params2 === void 0 ? void 0 : _model$params2.f) || ''
          });
        }

        if (this.websiteService.isBigOrdrShopRoute) {
          return;
        }

        this.seoService.setupStructuredData({
          structuredData: [{
            data: model.website,
            type: 'website',
            isPermanent: true
          }, {
            data: model.website,
            type: 'logo',
            isPermanent: true
          }],
          isNewStruct: true
        });
        this.seoService.setupStructuredData({
          structuredData: [{
            data: model.website,
            type: 'breadcrumb'
          }],
          isNewStruct: true,
          wrapOnGraphKey: false
        });
      }
    } // @action


    addErrorLogging() {
      const self = this;

      window.Ember.onerror = function (error) {
        console.log(error); // has srcElement / target / etc

        if (self.configService.environment === 'production' || self.configService.environment === 'staging') {
          const customErrorData = {
            from: 'bigordr-shop',
            message: (error === null || error === void 0 ? void 0 : error.message) || '',
            stack: (error === null || error === void 0 ? void 0 : error.stack) || ''
          };
          const previousErrorMessages = self.get('lastErrorMsgs') || [];
          const currentErrorMsg = `${error.message}`;

          if (!R.includes(currentErrorMsg)(previousErrorMessages)) {
            previousErrorMessages.pushObject(currentErrorMsg);
            self.set('lastErrorMsgs', previousErrorMessages);
            self.websiteService.logErrorTask.perform({
              error: customErrorData,
              event: 'Global Error',
              page: window.location.href,
              isGlobalError: true
            });
          } // const msg = self.intl.t('encountered unknown error')
          // window.alert(msg)

        }
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "configService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "checkoutService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "seoService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "flowsService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "socialLoginService", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isMaintenance", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class));
  _exports.default = ApplicationRoute;
});
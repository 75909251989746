define("shop/pods/components/elements/delivery-info/arrival-date-selector/component", ["exports", "@glimmer/component", "@ember/object", "ramda"], function (_exports, _component, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ElementsDeliveryInfoArrivalDateSelectorComponent extends _component.default {
    get options() {
      const options = ['anytime', 'beforeNoon', 'afterNoon'];
      return R.map(label => {
        return _object.default.create({
          label
        });
      })(options);
    }

  }

  _exports.default = ElementsDeliveryInfoArrivalDateSelectorComponent;
});
define("shop/pods/components/items/item-variants/variants-selectors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ch8ezO4q",
    "block": "[[[10,0],[15,0,[29,[\"flex-container--align-items-center flex-container--justify-center\\n            \",[52,[28,[37,1],[[30,1,[\"_data\",\"variantSelectorStyle\"]],\"buttons\"],null],\"flex-container-column\"]]]],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"variantsProxy\"]]],null]],null],null,[[[1,\"    \"],[8,[39,4],null,[[\"@availableQty\",\"@hasLimitedQty\",\"@hasSelectedVariantChanged\",\"@isFinalSelection\",\"@item\",\"@itemStatus\",\"@onSelect\",\"@overSalesLimitedQtyStatus\",\"@overSalesLimitedVariantChildren\",\"@shop\",\"@variant\",\"@variantsProxy\",\"@selectedVariantKeyByUrlParam\"],[[30,3],[30,4],[30,5,[\"hasSelectedVariantChanged\"]],[30,0,[\"isFinalSelection\"]],[30,5],[30,6],[30,0,[\"select\"]],[30,7],[30,0,[\"overSalesLimitedVariantChildren\"]],[30,1],[30,2],[30,0,[\"variantsProxy\"]],[30,8]]],null],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"@shop\",\"variant\",\"@availableQty\",\"@hasLimitedQty\",\"@item\",\"@itemStatus\",\"@overSalesLimitedQtyStatus\",\"@selectedVariantKeyByUrlParam\"],false,[\"if\",\"eq\",\"each\",\"-track-array\",\"items/item-variants/variants-selectors/options-selectors\"]]",
    "moduleName": "shop/pods/components/items/item-variants/variants-selectors/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/date/service", ["exports", "@ember/service", "moment", "shop/utils/nventor"], function (_exports, _service, moment, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DateService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "defaultDateFormat", 'YYYY-MM-DD');

      _defineProperty(this, "nonWorkDays", [6, 7]);
    }

    // isoWeekdays (1=mon, 7=sun). default non-working days are sat/sun

    /**
     * Get weekdays (default to start from Monday)
     * @param {String} startingFrom the beginning of the week ()
     * @returns {Array<String>} weekdays in order
     */
    getWeekdays(startingFrom) {
      if (startingFrom === 'sunday') {
        return ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      }

      return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    }
    /**
     * Get moment, will return moment js
     * moment will check if string is an ISO8601 Date
     * @param {String} string date string
     * @param {String} format (eg. 'yyyy-mm-dd')
     * @returns {String} date format (need to check if moment() is also a string)
     */


    getMoment(string, format) {
      if (string) {
        return moment(string, format) || '';
      }

      return moment();
    }
    /**
     * Get format date for url
     * @param {String|Object} date
     * @param {String} dateFormat optional, default to 'YYYY-MM-DD'
     * @returns {String} date in format YYYY-MM-DD
     */


    formatDateForUrl(date, dateFormat = this.defaultDateFormat) {
      if (_nventor.default.isNilOrEmpty(date)) {
        return '';
      }

      if (typeof date === 'string') {
        date = this.getMoment(date, dateFormat);
      }

      return date.format(dateFormat);
    }
    /**
     * Get local time of certain dateString
     * @param {String} dateString
     * @param {String} format (optional) (eg. 'yyyy-mm-dd')
     * @returns {String}
     */


    toLocalTime(dateString, format) {
      return this.getMoment(dateString, format).local();
    }
    /**
     * Get nowZ
     * @returns {String} Zulu Time in ISO 8601 format
     */


    getNowZ() {
      return this.getMoment().toISOString();
    }
    /**
     * return the start of day at local time
     * (converted to Zulu Time)
     * @param {String} dateString (optional)
     * @param {String} format (optional) (eg. 'yyyy-mm-dd')
     * @returns {String} Zulu Time in ISO 8601 format
     */


    getStartOfDayZ(dateString, format) {
      return this.getMoment(dateString, format).startOf('day').toISOString();
    }
    /**
     * return the end of day at local time
     * (converted to Zulu Time)
     * @param {String} dateString (optional)
     * @param {String} format (optional) (eg. 'yyyy-mm-dd')
     * @returns {String} Zulu Time in ISO 8601 format
     */


    getEndOfDayZ(dateString, format) {
      return this.getMoment(dateString, format).endOf('day').toISOString();
    }
    /**
      * Get the date after days
      * @param {String} dateString date string
      * @param {Number} days default to zero (today)
      * @param {String} format (optional) (eg. 'yyyy-mm-dd')
      * @returns {String|Undefined} Zulu Time in ISO 8601 format | undifined if date string is incorrect
      */


    getDaysAfter(dateString, days = 0, format) {
      var _this$getMoment, _dateString, _dateString$toString, _dateString2, _dateString2$add;

      if (!dateString) {
        dateString = this.getMoment(dateString, format);
      }

      dateString = (_this$getMoment = this.getMoment) === null || _this$getMoment === void 0 ? void 0 : _this$getMoment.call(this, (_dateString = dateString) === null || _dateString === void 0 ? void 0 : (_dateString$toString = _dateString.toString) === null || _dateString$toString === void 0 ? void 0 : _dateString$toString.call(_dateString), format);
      return (_dateString2 = dateString) === null || _dateString2 === void 0 ? void 0 : (_dateString2$add = _dateString2.add) === null || _dateString2$add === void 0 ? void 0 : _dateString2$add.call(_dateString2, days, 'days');
    }
    /**
     * Get dateZ after days
     * @param {String} dateString date string
     * @param {Number} days default to zero (today)
     * @param {String} format (optional) (eg. 'yyyy-mm-dd')
     * @returns {String} Zulu Time in ISO 8601 format
     */


    getDaysAfterZ(dateString, days = 0, format) {
      var _daysAfter$toISOStrin;

      const daysAfter = this.getDaysAfter(dateString, days, format);
      return daysAfter === null || daysAfter === void 0 ? void 0 : (_daysAfter$toISOStrin = daysAfter.toISOString) === null || _daysAfter$toISOStrin === void 0 ? void 0 : _daysAfter$toISOStrin.call(daysAfter);
    }
    /**
     * Get the date before days
     * @param {String} dateString date string
     * @param {Number} days default to zero
     * @param {String} format (optional) (eg. 'yyyy-mm-dd')
     * @returns {String} Zulu Time in ISO 8601 format
     */


    getDaysBefore(dateString, days = 0, format) {
      var _this$getMoment2, _dateString3, _dateString3$toString, _dateString4, _dateString4$subtract;

      if (!dateString) {
        dateString = this.getMoment(dateString, format);
      }

      dateString = (_this$getMoment2 = this.getMoment) === null || _this$getMoment2 === void 0 ? void 0 : _this$getMoment2.call(this, (_dateString3 = dateString) === null || _dateString3 === void 0 ? void 0 : (_dateString3$toString = _dateString3.toString) === null || _dateString3$toString === void 0 ? void 0 : _dateString3$toString.call(_dateString3), format);
      return (_dateString4 = dateString) === null || _dateString4 === void 0 ? void 0 : (_dateString4$subtract = _dateString4.subtract) === null || _dateString4$subtract === void 0 ? void 0 : _dateString4$subtract.call(_dateString4, days, 'days');
    }
    /**
     * Get dateZ before days
     * @param {String} dateString date string
     * @param {Number} days default to zero
     * @param {String} format (optional) (eg. 'yyyy-mm-dd')
     * @returns {String} Zulu Time in ISO 8601 format
     */


    getDaysBeforeZ(dateString, days = 0, format) {
      var _daysBefore$toISOStri;

      const daysBefore = this.getDaysBefore(dateString, days, format);
      return daysBefore === null || daysBefore === void 0 ? void 0 : (_daysBefore$toISOStri = daysBefore.toISOString) === null || _daysBefore$toISOStri === void 0 ? void 0 : _daysBefore$toISOStri.call(daysBefore);
    }

  }

  _exports.default = DateService;
});
define("shop/pods/components/components-form-group/component", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency-decorators", "shop/utils/nventor", "ramda"], function (_exports, _component, _object, _service, _tracking, _emberConcurrencyDecorators, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ComponentsFormGroupComponent = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('date'), _dec3 = (0, _service.inject)('validator'), _dec4 = (0, _service.inject)('flows'), _dec5 = (0, _service.inject)('router'), _dec6 = (0, _service.inject)('checkout'), (_class = class ComponentsFormGroupComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "validatorService", _descriptor3, this);

      _initializerDefineProperty(this, "flowsService", _descriptor4, this);

      _initializerDefineProperty(this, "routerService", _descriptor5, this);

      _initializerDefineProperty(this, "checkoutService", _descriptor6, this);

      _initializerDefineProperty(this, "isActive", _descriptor7, this);

      _initializerDefineProperty(this, "isFirstPage", _descriptor8, this);

      _initializerDefineProperty(this, "isLastpage", _descriptor9, this);

      _initializerDefineProperty(this, "page", _descriptor10, this);

      _initializerDefineProperty(this, "formData", _descriptor11, this);

      _initializerDefineProperty(this, "hasErrorMessage", _descriptor12, this);

      _initializerDefineProperty(this, "errorMessages", _descriptor13, this);

      _initializerDefineProperty(this, "isSuccessful", _descriptor14, this);

      _initializerDefineProperty(this, "isFormSubmitted", _descriptor15, this);

      _initializerDefineProperty(this, "formWasSubmitted", _descriptor16, this);

      _initializerDefineProperty(this, "formSaveWasError", _descriptor17, this);

      _initializerDefineProperty(this, "flowUpdateKey", _descriptor18, this);

      this.initializeFormDataTask.perform();
    }
    /**
     * for date input(if need to block the date before today)
     * @returns todayZ
     */
    // get todayZ () {
    //   const todayZ = this.dateService.getStartOfDayZ()
    //   return todayZ
    // }


    setupFormStep() {
      var _this$args, _this$args$form;

      const formSteps = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$form = _this$args.form) === null || _this$args$form === void 0 ? void 0 : _this$args$form.formSteps) || [];

      if (!formSteps) {
        return;
      }

      const forEachIndexed = R.addIndex(R.forEach);
      forEachIndexed((formStep, index) => {
        if (index === 0) {
          (0, _object.set)(formStep, 'isActive', true);
          (0, _object.set)(formStep, 'isFirstPage', true);
        } else {
          // set isActive to false again
          (0, _object.set)(formStep, 'isActive', false);
        }

        if (index === R.length(formSteps) - 1) {
          (0, _object.set)(formStep, 'isLastPage', true);
        }

        return formStep;
      })(formSteps);
    }

    updateFormData(step, value) {
      const stepId = (step === null || step === void 0 ? void 0 : step.stepId) || '';

      if (!stepId) {
        return;
      }

      const inputValue = value || '';
      this.formData[stepId] = inputValue;
    }

    updateSelectData(step, event) {
      var _event$target;

      const stepId = (step === null || step === void 0 ? void 0 : step.stepId) || '';

      if (!stepId) {
        return;
      }

      const selectedValue = (event === null || event === void 0 ? void 0 : (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.value) || '';
      this.formData[stepId] = selectedValue;
    }

    *initializeFormDataTask() {
      var _this$args2, _this$args2$form, _this$args3, _this$args4, _this$args4$form;

      const flowKey = (this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$form = _this$args2.form) === null || _this$args2$form === void 0 ? void 0 : _this$args2$form._key) || '';
      const checkoutFormUid = this.checkoutService.getCheckoutFormUid();
      const isCheckoutForm = this === null || this === void 0 ? void 0 : (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.isCheckoutForm;
      let flowPayloadRaw = {};

      if (flowKey && checkoutFormUid && isCheckoutForm) {
        const flowCapturedData = yield this.flowsService.fetchFlowsCapturedDataForCheckoutForm.perform(flowKey, checkoutFormUid);
        flowPayloadRaw = R.pipe(R.pathOr({}, ['data', 'flowPayloadRaw']), R.reject(R.isEmpty))(flowCapturedData);
        this.flowUpdateKey = R.pathOr('', ['data', '_key'])(flowCapturedData);
      }

      const formSteps = (this === null || this === void 0 ? void 0 : (_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : (_this$args4$form = _this$args4.form) === null || _this$args4$form === void 0 ? void 0 : _this$args4$form.formSteps) || [];
      const allSteps = R.pipe(R.map(R.prop('steps')), R.flatten, R.reject(R.propEq('stepId', '')))(formSteps);
      this.formData = R.pipe(R.map(step => {
        let value = '';
        const stepKey = (step === null || step === void 0 ? void 0 : step.key) || '';

        if (stepKey) {
          value = R.pathOr('', ['args', 'user', '_data', stepKey])(this);
        }

        return [step.stepId, value];
      }), R.fromPairs, R.mergeRight(R.__, flowPayloadRaw))(allSteps);
    }

    validateStep(step, value) {
      if (step.dataType === 'email') {
        step.dataType = 'requiredEmail';
      }

      return this.validatorService.validateByType({
        type: step.dataType,
        key: step.key,
        value: value
      });
    }

    *goToNextStepTask(e) {
      var _this$args5, _this$args5$form;

      e.preventDefault();
      const formSteps = ((_this$args5 = this.args) === null || _this$args5 === void 0 ? void 0 : (_this$args5$form = _this$args5.form) === null || _this$args5$form === void 0 ? void 0 : _this$args5$form.formSteps) || [];

      if (!formSteps) {
        return;
      }

      let currentIndex = R.findIndex(R.propEq('isActive', true), formSteps);

      try {
        yield this.goToNextFormStepTask.perform(formSteps[currentIndex].steps, this.formData);

        if (currentIndex >= 0 && currentIndex < formSteps.length - 1) {
          (0, _object.set)(formSteps[currentIndex], 'isActive', false);
          (0, _object.set)(formSteps[currentIndex + 1], 'isActive', true);
          this.hasErrorMessage = false;
          this.errorMessages = [];

          _nventor.default.goToTop();
        }
      } catch (error) {
        this.hasErrorMessage = true;
        this.errorMessages = (error === null || error === void 0 ? void 0 : error.details) || [];

        _nventor.default.goToTop();
      }
    }

    goToPreviousStep() {
      var _this$args6, _this$args6$form;

      const formSteps = ((_this$args6 = this.args) === null || _this$args6 === void 0 ? void 0 : (_this$args6$form = _this$args6.form) === null || _this$args6$form === void 0 ? void 0 : _this$args6$form.formSteps) || [];

      if (!formSteps) {
        return;
      }

      let currentIndex = R.findIndex(R.propEq('isActive', true), formSteps);

      if (currentIndex > 0) {
        (0, _object.set)(formSteps[currentIndex], 'isActive', false);
        (0, _object.set)(formSteps[currentIndex - 1], 'isActive', true);
      }

      _nventor.default.goToTop();
    }

    *onSubmitTask(e) {
      var _this$args7, _this$args7$form;

      if (e !== null && e !== void 0 && e.preventDefault) {
        var _e$preventDefault;

        e === null || e === void 0 ? void 0 : (_e$preventDefault = e.preventDefault) === null || _e$preventDefault === void 0 ? void 0 : _e$preventDefault.call(e);
      }

      const formSteps = ((_this$args7 = this.args) === null || _this$args7 === void 0 ? void 0 : (_this$args7$form = _this$args7.form) === null || _this$args7$form === void 0 ? void 0 : _this$args7$form.formSteps) || [];

      if (!formSteps) {
        return;
      }

      let currentIndex = R.findIndex(R.propEq('isActive', true), formSteps);

      try {
        yield this.goToNextFormStepTask.perform(formSteps[currentIndex].steps, this.formData);
        this.isSuccessful = true;
        this.hasErrorMessage = false;
        this.errorMessages = [];
        this.checkoutService.setupCheckoutFormUid();
        yield this.submitFormTask.perform();
        this.isFormSubmitted = true;
        return true; // to check on checkout form if successful or not
      } catch (error) {
        var _this$args8;

        this.hasErrorMessage = true;
        this.errorMessages = (error === null || error === void 0 ? void 0 : error.details) || [];

        if (!(this !== null && this !== void 0 && (_this$args8 = this.args) !== null && _this$args8 !== void 0 && _this$args8.isCheckoutForm)) {
          _nventor.default.goToTop();
        }

        return false; // to check on checkout form if successful or not
      }
    }

    *goToNextFormStepTask(steps, formData) {
      let validateFailedSteps = [];

      for (const step of steps) {
        if (step.isReplyRequired) {
          const userInput = formData[step.stepId] || '';

          try {
            yield this.validateStep(step, userInput);
          } catch (error) {
            const validateFailedStep = R.clone(step); // the errorMessage is there but no proper translation, no proper language that will be understood by customer, (e.g. date validation error message is showing the regex)
            // so the error message is passed for now, but not used
            // step.errorMessage = R.pipe(
            //   R.pathOr({}, ['data', 'errors']),
            //   R.values,
            //   R.map(R.propOr('', 'message')),
            //   R.flatten,
            //   R.reject(R.isEmpty),
            //   R.join(', ')
            // )(error)

            validateFailedSteps.push(validateFailedStep);
          }
        }
      }

      if (validateFailedSteps.length > 0) {
        const validationError = new Error('Validation Error');
        validationError.details = {};
        R.forEach(validateFailedStep => {
          const stepId = (validateFailedStep === null || validateFailedStep === void 0 ? void 0 : validateFailedStep.stepId) || '';

          if (stepId) {
            validationError.details[stepId] = validateFailedStep;
          }
        })(validateFailedSteps);
        yield this.websiteService.logErrorTask.perform({
          error: {
            message: 'checkout form validation error',
            data: {
              validationError,
              formData: this.formData
            }
          }
        });
        throw validationError;
      }
    }

    *submitFormTask() {
      var _this$args9, _this$args9$form, _this$args10;

      const website = this.websiteService.website;

      if (!website) {
        return false;
      }

      let urlType = 'shopUrl';
      let shopUrl = (website === null || website === void 0 ? void 0 : website.shopUrl) || '';

      if (!shopUrl) {
        urlType = 'host';
        shopUrl = (website === null || website === void 0 ? void 0 : website.name) || '';
      }

      const flowKey = (_this$args9 = this.args) === null || _this$args9 === void 0 ? void 0 : (_this$args9$form = _this$args9.form) === null || _this$args9$form === void 0 ? void 0 : _this$args9$form._key;

      if (!flowKey) {
        throw new Error('invalid flow setup');
      }

      const defaultUrl = `/api/public/flows/${shopUrl}/${flowKey}/hook`;
      const flowPayload = this.formData || {};
      const data = {
        urlType,
        flowUpdateKey: this.flowUpdateKey
      };
      const isCheckoutForm = this === null || this === void 0 ? void 0 : (_this$args10 = this.args) === null || _this$args10 === void 0 ? void 0 : _this$args10.isCheckoutForm;

      if (isCheckoutForm) {
        data.isTemp = true;
        data.checkoutFormUid = this.checkoutService.getCheckoutFormUid();
      }

      data.flowPayload = flowPayload;
      data.isSuccessful = this.isSuccessful;

      if (_nventor.default.isNotNilOrEmpty(data.flowPayload)) {
        var _res$data3;

        const res = yield this.websiteService.request(defaultUrl, {
          method: 'PATCH',
          data
        });

        if (R.equals(urlType, 'shopurl')) {
          var _res$data;

          website.set('shop._data.flows', (res === null || res === void 0 ? void 0 : (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.allowedMemberFlows) || []);
        } else {
          var _res$data2;

          website.set('_data.websiteFlows', (res === null || res === void 0 ? void 0 : (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.allowedMemberFlows) || []);
        }

        this.flowUpdateKey = (res === null || res === void 0 ? void 0 : (_res$data3 = res.data) === null || _res$data3 === void 0 ? void 0 : _res$data3.responseDataKey) || '';
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "validatorService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flowsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "routerService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "checkoutService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isFirstPage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isLastpage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "formData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "hasErrorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "errorMessages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isSuccessful", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isFormSubmitted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "formWasSubmitted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "formSaveWasError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "flowUpdateKey", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupFormStep", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupFormStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFormData", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFormData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSelectData", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initializeFormDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "initializeFormDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateStep", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToNextStepTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "goToNextStepTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToPreviousStep", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goToPreviousStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToNextFormStepTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "goToNextFormStepTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitFormTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "submitFormTask"), _class.prototype)), _class));
  _exports.default = ComponentsFormGroupComponent;
});
define("shop/initializers/website", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'websiteService', 'service:website');
    application.inject('adapter', 'websiteService', 'service:website');
    application.inject('component', 'websiteService', 'service:website');
    application.inject('service:cart', 'websiteService', 'service:website');
    application.inject('service:members', 'websiteService', 'service:website');
    application.inject('service:facebook', 'websiteService', 'service:website');
    application.inject('service:line', 'websiteService', 'service:website');
    application.inject('service:validator', 'websiteService', 'service:website');
  }

  var _default = {
    name: 'websiteService',
    initialize: initialize
  };
  _exports.default = _default;
});
define("shop/pods/news/model", ["exports", "@ember/object", "ramda"], function (_exports, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NewsModel extends _object.default {
    get tagNames() {
      return R.pipe(R.pathOr([], ['_data', 'tags']), R.pluck('label'))(this);
    }

    get images() {
      return R.pipe(R.pathOr([], ['_data', 'uploadedFiles']), R.sortWith([R.ascend(R.prop('timestampZ'))]))(this);
    }

    get defaultImageData() {
      const images = this.images;
      const defaultImage = R.pathOr('', ['_data', 'defaultImage']);

      if (defaultImage) {
        const uuid = this.get('_data.defaultImage');

        if (uuid) {
          const found = R.find(R.propEq('uuid', uuid))(images);

          if (found) {
            return found;
          }
        }
      }

      if (images.get('firstObject')) {
        const firstImage = this.images.get('firstObject');

        if (firstImage) {
          return firstImage;
        }
      }

      return {};
    }

    get defaultImageBackgroundColor() {
      var _this$imagesService, _this$imagesService$g;

      return this === null || this === void 0 ? void 0 : (_this$imagesService = this.imagesService) === null || _this$imagesService === void 0 ? void 0 : (_this$imagesService$g = _this$imagesService.getBackgroundRGBColor) === null || _this$imagesService$g === void 0 ? void 0 : _this$imagesService$g.call(_this$imagesService, {
        pathArray: ['defaultImageData', 'mainColors'],
        model: this
      });
    }

  }

  _exports.default = NewsModel;
});
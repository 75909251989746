define("shop/pods/components/layouts/message-chips/chip/component", ["exports", "@glimmer/component", "@ember/service", "@ember/object", "@ember/string"], function (_exports, _component, _service, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LayoutsMessageChipsChipComponent = (_dec = (0, _service.inject)('website'), (_class = class LayoutsMessageChipsChipComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);
    }

    get autoCloseTimeStyle() {
      var _this$args$chip;

      const autoCloseTimeInMs = ((_this$args$chip = this.args.chip) === null || _this$args$chip === void 0 ? void 0 : _this$args$chip.autoCloseTime) || 3000;
      const autoCloseTimeInSeconds = autoCloseTimeInMs / 1000;
      return (0, _string.htmlSafe)(`--message-chip__auto-close-time: ${autoCloseTimeInSeconds}s;`);
    }

    didInsert(element) {
      this.chipElement = element;
    }

    closeChip() {
      const element = this.chipElement;
      element.classList.add('is-closing');
      setTimeout(() => {
        this.websiteService.removeMessageChip(this.args.chip);
      }, 140);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeChip", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeChip"), _class.prototype)), _class));
  _exports.default = LayoutsMessageChipsChipComponent;
});
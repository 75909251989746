define("shop/pods/components/cart/qty-selector-set-choice-variant/component", ["exports", "ramda", "@ember/component", "@ember/object"], function (_exports, R, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',

    didInsertElement() {
      this._super(...arguments);

      if (this.get('item._data.hasVariants')) {
        this.newVariantChoice();
      }
    },

    addSetLabel: (0, _object.computed)('allowAnotherVariantChoice', function () {
      const selectedVariantChoices = this.get('item.selectedVariantChoices') || [];
      const hasSelected = R.pipe(R.pluck('selectedVariantChild'), R.flatten)(selectedVariantChoices);

      if (R.isEmpty(hasSelected)) {
        return 'choose';
      }

      return 'add another set';
    }),
    choiceDetailStatus: (0, _object.computed)('item._data.{calculatedStatusBeforeCart,calculatedTotalAvailableQtyBeforeCart,overSalesLimitedQtyStatus,qty}', function () {
      const item = this.item;

      if (item.get('_data.hasLimitedQty')) {
        const availableQty = parseInt(item.get('_data.calculatedTotalAvailableQtyBeforeCart')) || 0;
        const qty = parseInt(item.get('_data.qty')) || 0;

        if (qty > availableQty || availableQty <= 0) {
          if (item.get('_data.overSalesLimitedQtyStatus') === 'preOrder') {
            return 'preOrder';
          }

          return 'soldOut';
        }
      }

      return item.get('_data.calculatedStatusBeforeCart');
    }),
    allowAnotherVariantChoice: (0, _object.computed)('item.selectedVariantChoices.@each.{qty,selectedVariantChild}', 'choice.isGroupComplete', function () {
      const isGroupComplete = this.get('choice.isGroupComplete');

      if (isGroupComplete) {
        return false;
      }

      const selectedVariantChoices = this.get('item.selectedVariantChoices') || [];
      const hasUnselectedChoices = R.pipe(R.pluck('selectedVariantChild'), R.includes(''))(selectedVariantChoices);

      if (hasUnselectedChoices) {
        return false;
      }

      return true;
    }),
    totalQty: (0, _object.computed)('item.selectedVariantChoices.@each.qty', function () {
      const selectedVariantChoices = this.get('item.selectedVariantChoices') || [];
      return R.pipe(R.pluck('qty'), R.reduce((acc, qty) => {
        return acc + qty;
      }, 0))(selectedVariantChoices);
    }),

    updateVariantQty(variantChoice, qtyUpdated = true) {
      const totalQty = this.totalQty || 0;
      const item = this.item;
      const selectedVariant = item.get('selectedVariant');
      variantChoice.set('selectedVariant', selectedVariant);
      variantChoice.set('selectedVariantName', item.get('selectedVariantName'));
      variantChoice.set('selectedVariantOptions', item.get('selectedVariantOptions'));
      variantChoice.set('selectedVariantPriceOld', item.get('selectedVariantPriceOld'));
      variantChoice.set('selectedVariantPriceCurrent', item.get('selectedVariantPriceCurrent'));
      item.set('_data.qty', totalQty);
      this.onChangeQty(totalQty, qtyUpdated);
      item.set('hasSelectedVariantChanged', Math.random());
      const remainingQty = parseInt(this.get('choice.groupRemainingQty')) || 0;
      const selectedVariantChoices = this.get('item.selectedVariantChoices') || [];
      R.forEach(variantChoice => {
        const itemQty = parseInt(variantChoice.get('qty')) || 0;
        const maxQty = itemQty + remainingQty;
        variantChoice.set('maxQty', maxQty);
      })(selectedVariantChoices);
    },

    newVariantChoice() {
      this.get('onNewVariantChoice')(...arguments);

      if (this.get('onToggleShowVariantPrice')) {
        this.get('onToggleShowVariantPrice')(false);
      }
    },

    removeVariantChoice(variantChoice) {
      const selectedVariantChoices = this.get('item.selectedVariantChoices') || [];
      selectedVariantChoices.removeObject(variantChoice);
      this.updateVariantQty(variantChoice);
      this.newVariantChoice();
    },

    actions: {
      newVariantChoice() {
        this.newVariantChoice();
      },

      selectVariantOption(variantChoice, variant, selected
      /*, e */
      ) {
        const remainingQty = this.get('choice.groupRemainingQty');
        let qtyUpdated = true;

        if (selected) {
          var _this$item, _this$item$_data;

          // if (remainingQty <= 0) {
          //   return this.removeVariantChoice(variantChoice)
          // }
          const choiceItemSelectedOptions = variantChoice.get('selectedOptions') || {};
          const option = variant.get('_data.name');
          const optionIndex = variant.get('_data.index');
          choiceItemSelectedOptions[optionIndex] = {
            name: option,
            value: selected
          };
          const item = this.item;
          const variants = (this === null || this === void 0 ? void 0 : (_this$item = this.item) === null || _this$item === void 0 ? void 0 : (_this$item$_data = _this$item._data) === null || _this$item$_data === void 0 ? void 0 : _this$item$_data.variants) || [];
          const selectedOptions = R.values(choiceItemSelectedOptions);
          const variantChild = item.getSelectedVariant(variants, selectedOptions); // get all options to see if all has been selected

          if (variantChild) {
            let currentVariantChoiceQty = parseInt(variantChoice.get('qty')) || 1;

            if (variantChild.calculatedStatusBeforeCart === 'outOfStock' || variantChild.calculatedStatusBeforeCart === 'soldOut') {
              currentVariantChoiceQty = 0;
            }

            let hasPreviouSelectedVariantChild = true;

            if (remainingQty <= 0) {
              if (variantChoice.get('selectedVariantChild')) {
                qtyUpdated = false;
              } else {
                hasPreviouSelectedVariantChild = false;
              }
            }

            if (hasPreviouSelectedVariantChild) {
              variantChoice.set('selectedVariantChild', variantChild);
              variantChoice.set('qty', currentVariantChoiceQty);

              if (currentVariantChoiceQty <= 0) {
                variantChoice.set('disabled', true);
              } else {
                variantChoice.set('disabled', false);
              }
            }
          } else {
            variantChoice.set('selectedVariantChild', '');
            variantChoice.set('qty', 0);
          }
        } else {
          variantChoice.set('selectedVariantChild', '');
          variantChoice.set('qty', 0);
        }

        this.updateVariantQty(variantChoice, qtyUpdated);
      },

      updateVariantQty(variantChoice, qty) {
        variantChoice.set('qty', qty);
        this.updateVariantQty(variantChoice);
      },

      removeVariantChoice(variantChoice) {
        this.removeVariantChoice(variantChoice);
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/tap-pay/card/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "@ember/runloop", "ramda", "shop/utils/nventor"], function (_exports, _component, _object, _tracking, _service, _runloop, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TapPayCardComponent = (_dec = (0, _service.inject)('tapPay'), _dec2 = (0, _service.inject)('website'), _dec3 = (0, _service.inject)('members'), (_class = class TapPayCardComponent extends _component.default {
    constructor() {
      var _shop$_data;

      super(...arguments);

      _initializerDefineProperty(this, "tapPayService", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _initializerDefineProperty(this, "membersService", _descriptor3, this);

      _defineProperty(this, "selectedCCId", '');

      _initializerDefineProperty(this, "isEditingCreditCard", _descriptor4, this);

      _initializerDefineProperty(this, "isNewCreditCard", _descriptor5, this);

      const defaultCard = R.find(R.propEq('isDefault', true))(this.availableCards) || {};

      if (!R.isEmpty(defaultCard)) {
        this.setCard(defaultCard.ccId);
      }

      const user = this.args.user;
      const shop = this.args.shop;

      if (shop !== null && shop !== void 0 && shop.hasRecurringItems && user) {
        (0, _object.set)(user, '_data.checkoutCreditCardData.rememberCreditCard', true);
      }

      const creditCardProviderAllowRememberCard = (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.creditCardProviderAllowRememberCard) || false;

      if (!creditCardProviderAllowRememberCard && user) {
        (0, _object.set)(user, '_data.checkoutCreditCardData.rememberCreditCard', false);
      }
    }

    get allowRememberCreditCard() {
      var _shop$_data2;

      const shop = this.args.shop;
      const user = this.args.user;
      const creditCardProviderAllowRememberCard = (shop === null || shop === void 0 ? void 0 : (_shop$_data2 = shop._data) === null || _shop$_data2 === void 0 ? void 0 : _shop$_data2.creditCardProviderAllowRememberCard) || false;

      if (!creditCardProviderAllowRememberCard) {
        return false;
      }

      if (this.args.hasFailedScheduledPaymentForRecurring) {
        return true;
      }

      if (user !== null && user !== void 0 && user.isMember && this.args.isCheckout) {
        return true;
      }

      return false;
    }

    get failedCcId() {
      var _this$args, _this$args$document, _this$args$document$p;

      return (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$document = _this$args.document) === null || _this$args$document === void 0 ? void 0 : (_this$args$document$p = _this$args$document.paymentSchedule) === null || _this$args$document$p === void 0 ? void 0 : _this$args$document$p.failedCcId) || '';
    }

    get failedCreditCard() {
      var _this$args2, _this$args2$user, _this$args2$user$_dat;

      const paymentMethods = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$user = _this$args2.user) === null || _this$args2$user === void 0 ? void 0 : (_this$args2$user$_dat = _this$args2$user._data) === null || _this$args2$user$_dat === void 0 ? void 0 : _this$args2$user$_dat.paymentMethods) || [];
      const failedCcId = this.failedCcId || '';

      if (_nventor.default.isNotNilOrEmpty(paymentMethods) && failedCcId) {
        return R.find(R.propEq('ccId', failedCcId))(paymentMethods) || {};
      }

      return {};
    }

    get lastFourDigitForFailedCreditCard() {
      const failedCreditCard = this.failedCreditCard || {};

      if (_nventor.default.isNotNilOrEmpty(failedCreditCard)) {
        return R.pipe(R.propOr('', 'ccNumber'), R.split(' '), _nventor.default.safeLastOr(''))(failedCreditCard);
      }

      return '';
    }

    get availableCards() {
      var _this$args3, _this$args3$user, _this$args3$user$_dat;

      const paymentMethods = ((_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : (_this$args3$user = _this$args3.user) === null || _this$args3$user === void 0 ? void 0 : (_this$args3$user$_dat = _this$args3$user._data) === null || _this$args3$user$_dat === void 0 ? void 0 : _this$args3$user$_dat.paymentMethods) || [];

      if (R.isEmpty(paymentMethods)) {
        return [];
      }

      return R.filter(R.allPass([R.propEq('provider', 'tapPay'), R.propEq('isValid', true)]))(paymentMethods);
    }

    get showNewCardInputs() {
      if (this.args.isNew) {
        return true;
      }

      const availableCards = this.availableCards || [];

      if (R.isEmpty(availableCards)) {
        return true;
      }

      const ccId = R.path(['checkoutCreditCardData', 'ccId'])(this.args.user);

      if (ccId) {
        return false;
      }

      return true;
    }

    setupTapPay() {
      (0, _runloop.scheduleOnce)('afterRender', this, function () {
        this.tapPay.setupSDK();
      });
    }

    setCard(selectedCCId) {
      var _this$args$user;

      (_this$args$user = this.args.user) === null || _this$args$user === void 0 ? void 0 : _this$args$user.selectExistingCreditCard(selectedCCId);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tapPayService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isEditingCreditCard", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isNewCreditCard", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupTapPay", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupTapPay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setCard"), _class.prototype)), _class));
  _exports.default = TapPayCardComponent;
});
define("shop/pods/components/shop/checkout/payment-page/referral-code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VQPE0b9u",
    "block": "[[[8,[39,0],null,[[\"@classNames\"],[\"referral-code-container\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@label\"],[\"referral code\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@readonly\",\"@value\",\"@placeholder\"],[[28,[37,4],[[30,0,[\"hasEmail\"]]],null],[30,1,[\"referralCode\"]],\"referral_code_enter\"]],null],[1,\"\\n\"],[41,[51,[30,0,[\"hasEmail\"]]],[[[1,\"        \"],[10,1],[14,0,\"has-text-small has-text-danger\"],[12],[1,\"*\"],[1,[28,[35,6],[\"referral_code_email_required\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"      \"],[8,[39,8],null,[[\"@successMessage\"],[\"promo code claimed\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[8,[39,8],null,[[\"@errorMessage\"],[[30,3]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@checkoutData\",\"@hasClaimedReferralCode\",\"@referralCodeError\"],false,[\"layouts/page-card\",\"layouts/page-card/card-content\",\"form-row\",\"elements/element-input\",\"not\",\"unless\",\"tt\",\"if\",\"elements/message-block/error-message\"]]",
    "moduleName": "shop/pods/components/shop/checkout/payment-page/referral-code/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
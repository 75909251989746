define("shop/pods/shop/orders/status/route", ["exports", "@ember/routing/route", "shop/mixins/orders-status"], function (_exports, _route, _ordersStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_ordersStatus.default, {
    async model(params) {
      return this._model(params);
    },

    redirect(model) {
      this._redirect(model);
    }

  });

  _exports.default = _default;
});
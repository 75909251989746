define("shop/pods/components/items/item-card/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a+iW2sTN",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"cart-add-btn__container flex-container--justify-space-evenly cart-add-btn__container--override\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"cart-add-btn__sub-container\"],[12],[1,\"\\n\\n      \"],[8,[39,2],[[24,0,\"cart-add-btn--adding--override\"]],[[\"@itemStatus\"],[[30,1]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"cart-add-btn__status-container flex-push-center\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@itemStatus\"],[[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@itemStatus\"],false,[\"if\",\"showTagsRow\",\"cart/added-status\",\"cart/item-status\"]]",
    "moduleName": "shop/pods/components/items/item-card/status/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/cart/content-summary/discounts/component", ["exports", "@glimmer/component", "@ember/service", "@glimmer/tracking", "ramda", "shop/utils/nventor"], function (_exports, _component, _service, _tracking, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CartContentSummaryDiscountsComponent = (_dec = (0, _service.inject)('big'), (_class = class CartContentSummaryDiscountsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "bigService", _descriptor2, this);
    }

    get hasBulkDiscountCartItems() {
      const shop = this.args.shop || {};
      const cartItems = (shop === null || shop === void 0 ? void 0 : shop.cartItems) || [];
      return R.reject(item => {
        const bulkDiscountTotalAmt = Number(item === null || item === void 0 ? void 0 : item.bulkDiscountTotalAmt) || 0;

        if (bulkDiscountTotalAmt <= 0) {
          return true;
        }
      })(cartItems);
    }

    get bulkDiscountsTotal() {
      var _this$args;

      const cartItem = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.cartItem) || {};
      const cartItemBulkDiscountId = cartItem.bulkDiscountId || '';
      let cartItems = this.hasBulkDiscountCartItems || []; // remove if want to show all available bulk discounts

      if (_nventor.default.isNotNilOrEmpty(cartItem)) {
        cartItems = R.filter(R.propEq('bulkDiscountId', cartItemBulkDiscountId))(cartItems);
      }

      return this.bigService.sumProp('bulkDiscountTotalAmt')(cartItems);
    }

    get bulkDiscounts() {
      var _this$args2;

      const cartItem = (this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.cartItem) || {};
      const cartItemBulkDiscountId = cartItem.bulkDiscountId || '';
      let cartItems = this.hasBulkDiscountCartItems || [];
      let cartItemsBulkDiscountIds = R.pipe(R.pluck('bulkDiscountId'), R.reject(R.isNil), R.reject(R.isEmpty), R.uniq)(cartItems); // remove if want to show all available bulk discounts

      if (_nventor.default.isNotNilOrEmpty(cartItem) && _nventor.default.isNotNilOrEmpty(cartItemBulkDiscountId)) {
        cartItemsBulkDiscountIds = R.filter(R.equals(cartItemBulkDiscountId))(cartItemsBulkDiscountIds);
      }

      return R.map(bulkDiscountId => {
        const items = R.filter(R.propEq('bulkDiscountId', bulkDiscountId))(cartItems);
        const bulkDiscountData = R.pipe(R.head, R.propOr({}, 'bulkDiscountData'))(items);
        const bulkDiscountName = bulkDiscountData.name || this.intl.t('bulk discount') || '';
        const when = bulkDiscountData.when || '';
        const criteria = bulkDiscountData.criteria || '';
        const criteriaValue = bulkDiscountData.value || '';
        const discountType = bulkDiscountData.discountType || '';
        const isDefaultDescription = bulkDiscountData.isDefaultDescription || false;
        let discountValue = bulkDiscountData.discountAmount;
        let discountDesc = `NT ${discountValue}`;

        if (discountType === 'rate') {
          discountValue = bulkDiscountData.discountRate;
          discountDesc = `${discountValue}%`;
        }

        let description = bulkDiscountData.description || '';

        if (isDefaultDescription && criteria && criteriaValue && discountType && discountValue) {
          if (when === 'itemQty') {
            description = this.intl.t('bulk discount item qty description', {
              discount: discountDesc,
              criteria: this.intl.t(criteria),
              criteriaValue
            });
          }

          if (when === 'itemValue') {
            description = this.intl.t('bulk discount item value description', {
              discount: discountDesc,
              criteria: this.intl.t(criteria),
              criteriaValue
            });
          }
        }

        return {
          bulkDiscountName,
          description,
          items
        };
      })(cartItemsBulkDiscountIds);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bigService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CartContentSummaryDiscountsComponent;
});
define("shop/pods/components/cart/qty-selector/component", ["exports", "@ember/component", "@ember/object", "shop/utils/nventor", "ramda", "@ember/service"], function (_exports, _component, _object, _nventor, R, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    googleService: (0, _service.inject)('google'),
    tagName: 'span',
    classNames: ['store-item__qty-selector'],
    showButtons: true,
    defaultQty: 1,
    minQty: 1,
    isFocusOnInput: false,
    isOverPersonLimitedQty: false,
    showClearCartModal: false,

    init() {
      this._super(...arguments);

      let qty = parseInt(this.qty);

      if (R.equals(NaN, qty)) {
        qty = this.minQty;
      }

      if (R.equals(NaN, qty)) {
        qty = this.defaultQty;
      }

      qty = this.checkUpperAndLowerLimit(qty);
      this.set('qty', qty);
      this.set('initialQty', qty);
    },

    inputClassNames: (0, _object.computed)('qty', function () {
      if (this.qty > 0) {
        return 'has-value';
      }

      return '';
    }),
    // showSelectBtn: computed('qty', 'isFocusOnInput', function () {
    //   if (this.isFocusOnInput) {
    //     return false
    //   }
    //   if (this.qty) {
    //     return false
    //   }
    //   return true
    // }),
    personLimitedQty: (0, _object.computed)('item.personLimitedQty', 'qty', function () {
      let personLimitedQty = parseInt(this.get('item._data.personLimitedQty')) || false;
      personLimitedQty = personLimitedQty || parseInt(this.get('item.personLimitedQty'));
      personLimitedQty = personLimitedQty || false;
      return personLimitedQty;
    }),
    isRemoveDisabled: (0, _object.computed)('allowRemove', 'isGroupComplete', 'qty', function () {
      const qty = parseInt(this.qty) || 0;

      if (qty <= 0) {
        return true;
      }

      if (this.allowRemove) {
        return false;
      }

      return this.isGroupComplete;
    }),
    isItemAvailableOrIsPreOrder: (0, _object.computed)('itemStatus.{}', 'qty', function () {
      var _this$itemStatus;

      const qty = parseInt(this.qty) || 0;
      const isPreOrder = this.get('itemStatus.isPreOrder');
      const availableQty = this.get('itemStatus.availableQty');

      if (isPreOrder) {
        return true;
      }

      if (!(this !== null && this !== void 0 && (_this$itemStatus = this.itemStatus) !== null && _this$itemStatus !== void 0 && _this$itemStatus.hasLimitedQty)) {
        return true;
      }

      const qtyGreaterThanOrEqualToAvailableQty = availableQty <= qty && R.is(Number)(availableQty);

      if (qtyGreaterThanOrEqualToAvailableQty) {
        return false;
      }

      return true;
    }),
    isPlusDisabled: (0, _object.computed)('isGroupComplete', 'itemStatus.{}', 'isItemAvailableOrIsPreOrder', 'isOverPersonLimitedQty', 'shop.{_data.hasOneItemLimitPerCheckout,totalQtyWithoutAutomations}', function () {
      var _shop$_data;

      const isGroupComplete = this.get('isGroupComplete');
      const isSoldOut = this.get('itemStatus.isSoldOut');
      const isItemAvailableOrIsPreOrder = this.get('isItemAvailableOrIsPreOrder');

      if (isGroupComplete === true || isSoldOut === true || !isItemAvailableOrIsPreOrder) {
        return true;
      }

      const shop = this.shop;
      const hasOneItemLimitPerCheckout = (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.hasOneItemLimitPerCheckout) || false;

      if (hasOneItemLimitPerCheckout && (shop === null || shop === void 0 ? void 0 : shop.totalQtyWithoutAutomations) >= 1) {
        return true;
      }

      const isOverPersonLimitedQty = this.get('isOverPersonLimitedQty') || false;

      if (isOverPersonLimitedQty) {
        return true;
      }

      return false;
    }),
    allowIncreaseQty: (0, _object.computed)('personLimitedQty', 'qty', 'shop.cartItems.[]', 'item.itemKey', function () {
      var _this$item, _this$item3, _this$item4, _this$shop, _this$item5;

      // const qty = parseInt(this.qty) || 0
      let personLimitedQty = parseInt(this.personLimitedQty) || 0;

      if (this !== null && this !== void 0 && (_this$item = this.item) !== null && _this$item !== void 0 && _this$item.isVariantChild) {
        var _this$item2, _this$item2$parent;

        personLimitedQty = parseInt(this === null || this === void 0 ? void 0 : (_this$item2 = this.item) === null || _this$item2 === void 0 ? void 0 : (_this$item2$parent = _this$item2.parent) === null || _this$item2$parent === void 0 ? void 0 : _this$item2$parent.personLimitedQty) || 0;
      }

      let itemKey = this === null || this === void 0 ? void 0 : (_this$item3 = this.item) === null || _this$item3 === void 0 ? void 0 : _this$item3.itemKey;
      let isAutomation = (this === null || this === void 0 ? void 0 : (_this$item4 = this.item) === null || _this$item4 === void 0 ? void 0 : _this$item4.isAutomation) || false;
      const cartItems = (this === null || this === void 0 ? void 0 : (_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : _this$shop.cartItems) || [];

      if (!personLimitedQty) {
        return true;
      }

      let addedCartItemQtys;

      if (this !== null && this !== void 0 && (_this$item5 = this.item) !== null && _this$item5 !== void 0 && _this$item5.isVariantChild) {
        var _this$item6, _this$item6$parent, _this$item7, _this$item7$parent;

        itemKey = this === null || this === void 0 ? void 0 : (_this$item6 = this.item) === null || _this$item6 === void 0 ? void 0 : (_this$item6$parent = _this$item6.parent) === null || _this$item6$parent === void 0 ? void 0 : _this$item6$parent.itemKey;
        isAutomation = (this === null || this === void 0 ? void 0 : (_this$item7 = this.item) === null || _this$item7 === void 0 ? void 0 : (_this$item7$parent = _this$item7.parent) === null || _this$item7$parent === void 0 ? void 0 : _this$item7$parent.isAutomation) || false;
        addedCartItemQtys = R.pipe(R.filter(cartItem => {
          var _cartItem$parent, _cartItem$parent2;

          const cartItemKey = cartItem === null || cartItem === void 0 ? void 0 : (_cartItem$parent = cartItem.parent) === null || _cartItem$parent === void 0 ? void 0 : _cartItem$parent.itemKey;
          const cartItemIsAutomation = (cartItem === null || cartItem === void 0 ? void 0 : (_cartItem$parent2 = cartItem.parent) === null || _cartItem$parent2 === void 0 ? void 0 : _cartItem$parent2.isAutomation) || false;

          if (cartItemKey === itemKey && cartItemIsAutomation === isAutomation) {
            return true;
          }

          return false;
        }), R.pluck('qty'), R.sum)(cartItems);
      } else {
        addedCartItemQtys = R.pipe(R.filter(cartItem => {
          const cartItemKey = cartItem === null || cartItem === void 0 ? void 0 : cartItem.itemKey;
          const cartItemIsAutomation = (cartItem === null || cartItem === void 0 ? void 0 : cartItem.isAutomation) || false;

          if (cartItemKey === itemKey && cartItemIsAutomation === isAutomation) {
            return true;
          }

          return false;
        }), R.pluck('qty'), R.sum)(cartItems);
      }

      addedCartItemQtys = addedCartItemQtys || 0;

      if (addedCartItemQtys < personLimitedQty) {
        return true;
      }

      return false;
    }),
    allowedMaxQty: (0, _object.computed)('personLimitedQty', 'shop.cartItems.[]', 'item.{_data.limitedQty,_data.calculatedTotalAvailableQtyBeforeCart,_data.overSalesLimitedQtyStatus,limitedQty,calculatedTotalAvailableQtyBeforeCart,overSalesLimitedQtyStatus}', 'qty', 'maxQty', 'minQty', function () {
      var _this$item8;

      const item = this.item;
      const limits = [];
      const maxQty = parseInt(this.maxQty);

      if (!R.equals(NaN, maxQty)) {
        limits.push(maxQty);
      }

      let limitedQty = this.get('item._data.limitedQty');
      limitedQty = limitedQty || this.get('item.limitedQty');
      limits.push(limitedQty);
      let personLimitedQty = this.personLimitedQty || false;

      if (this !== null && this !== void 0 && (_this$item8 = this.item) !== null && _this$item8 !== void 0 && _this$item8.isVariantChild) {
        var _this$item9, _this$item9$parent;

        personLimitedQty = parseInt(this === null || this === void 0 ? void 0 : (_this$item9 = this.item) === null || _this$item9 === void 0 ? void 0 : (_this$item9$parent = _this$item9.parent) === null || _this$item9$parent === void 0 ? void 0 : _this$item9$parent.personLimitedQty) || 0;
      }

      if (personLimitedQty) {
        var _this$shop2, _this$item10, _this$item11, _this$item12;

        limits.push(personLimitedQty); // add up all cart items

        let addedCartItemQtys;
        const cartItems = (this === null || this === void 0 ? void 0 : (_this$shop2 = this.shop) === null || _this$shop2 === void 0 ? void 0 : _this$shop2.cartItems) || [];
        let itemKey = (this === null || this === void 0 ? void 0 : (_this$item10 = this.item) === null || _this$item10 === void 0 ? void 0 : _this$item10.itemKey) || '';
        let isAutomation = (this === null || this === void 0 ? void 0 : (_this$item11 = this.item) === null || _this$item11 === void 0 ? void 0 : _this$item11.isAutomation) || false;

        if (this !== null && this !== void 0 && (_this$item12 = this.item) !== null && _this$item12 !== void 0 && _this$item12.isVariantChild) {
          var _this$item13, _this$item13$parent, _this$item14, _this$item14$parent;

          itemKey = this === null || this === void 0 ? void 0 : (_this$item13 = this.item) === null || _this$item13 === void 0 ? void 0 : (_this$item13$parent = _this$item13.parent) === null || _this$item13$parent === void 0 ? void 0 : _this$item13$parent.itemKey;
          isAutomation = (this === null || this === void 0 ? void 0 : (_this$item14 = this.item) === null || _this$item14 === void 0 ? void 0 : (_this$item14$parent = _this$item14.parent) === null || _this$item14$parent === void 0 ? void 0 : _this$item14$parent.isAutomation) || false;
          addedCartItemQtys = R.pipe(R.filter(cartItem => {
            var _cartItem$parent3, _cartItem$parent4;

            const cartItemKey = cartItem === null || cartItem === void 0 ? void 0 : (_cartItem$parent3 = cartItem.parent) === null || _cartItem$parent3 === void 0 ? void 0 : _cartItem$parent3.itemKey;
            const cartItemIsAutomation = (cartItem === null || cartItem === void 0 ? void 0 : (_cartItem$parent4 = cartItem.parent) === null || _cartItem$parent4 === void 0 ? void 0 : _cartItem$parent4.isAutomation) || false;

            if (cartItemKey === itemKey && cartItemIsAutomation === isAutomation) {
              return true;
            }

            return false;
          }), R.pluck('qty'), R.sum)(cartItems);
        } else {
          addedCartItemQtys = R.pipe(R.filter(cartItem => {
            const cartItemKey = cartItem === null || cartItem === void 0 ? void 0 : cartItem.itemKey;
            const cartItemIsAutomation = (cartItem === null || cartItem === void 0 ? void 0 : cartItem.isAutomation) || false;

            if (cartItemKey === itemKey && cartItemIsAutomation === isAutomation) {
              return true;
            }

            return false;
          }), R.pluck('qty'), R.sum)(cartItems);
        }

        const qty = parseInt(this.qty) || 0;
        limits.push(parseInt(personLimitedQty || 0) - addedCartItemQtys + qty);
      }

      let availableQty = this.get('item._data.calculatedTotalAvailableQtyBeforeCart');
      availableQty = availableQty || this.get('item.calculatedTotalAvailableQtyBeforeCart');
      const overSalesLimitedQtyStatus = this.get('item._data.overSalesLimitedQtyStatus');

      if (overSalesLimitedQtyStatus === 'soldOut') {
        limits.push(availableQty);
      }

      if (item) {
        return R.pipe(R.reject(R.anyPass([R.isNil, R.equals(false)])), R.sort(R.comparator(R.lt)), R.head)(limits);
      }

      return null;
    }),

    // inputQty: computed('qty', function () {
    //   const qty = this.qty
    //   if (qty) {
    //     return qty
    //   }
    //   this.set('qty', 0)
    // }),
    // focusIn (e) {
    //   if (!this.disabled && !this.isReadonly) {
    //     this.set('showButtons', true)
    //   }
    // },
    checkUpperAndLowerLimit(qty) {
      const maxQty = this.allowedMaxQty;
      const minQty = this.minQty;

      if (!R.isNil(maxQty) && !R.isEmpty(maxQty) && qty > maxQty) {
        qty = maxQty;
      }

      if (minQty !== '' && qty < minQty) {
        qty = minQty;
      }

      return qty;
    },

    ensureInteger() {
      let qty = this.qty;

      if (qty === '') {
        qty = parseInt(qty) || 0;
        qty = this.checkUpperAndLowerLimit(qty);
        this.set('qty', qty);

        if (this.onChangeQty) {
          this.onChangeQty(qty);
        }
      }
    },

    onMinusQtyCancel() {
      if (this.resetCount) {
        const resetQty = this.get('initialQty');
        this.set('qty', resetQty);
        this.set('resetCount', false);
      }

      this.set('showClearCartModal', false);
    },

    onMinusQtySubmit(qty) {
      qty = qty || this.qty;
      qty = _nventor.default.getAsInt(qty, 1);
      qty = qty - 1;
      qty = this.checkUpperAndLowerLimit(qty);
      this.set('qty', qty);

      if (this.onChangeQty) {
        this.onChangeQty(qty);
      }

      if (qty <= 0) {
        if (this.onZeroQty) {
          this.onZeroQty();
        }
      }

      this.set('showClearCartModal', false);
    },

    actions: {
      changeQty(qty) {
        // let qty = this.qty
        qty = parseInt(qty) || 0;
        qty = this.checkUpperAndLowerLimit(qty);
        this.set('qty', qty);
      },

      plusQty() {
        if (!this.isGroupComplete) {
          var _this$item15;

          let qty = this.qty;
          qty = parseInt(qty) || 0;
          qty = qty + 1;
          qty = this.checkUpperAndLowerLimit(qty); // if (!this.item) {
          //   const shop = this.shop
          //   const item = this.shopItem
          //   const automation = this.automation
          //   const isPreOrder = R.path(['itemStatus', 'isPreOrder'])(this) || false
          //   shop.addCartItem({ item, qty, automation, isPreOrder })
          // }

          this.set('qty', qty);

          if (this.onChangeQty) {
            this.onChangeQty(qty);
          }

          let itemClone;

          if (this !== null && this !== void 0 && (_this$item15 = this.item) !== null && _this$item15 !== void 0 && _this$item15._data) {
            var _this$item16, _this$item16$serializ;

            // serialize first
            itemClone = R.clone(this === null || this === void 0 ? void 0 : (_this$item16 = this.item) === null || _this$item16 === void 0 ? void 0 : (_this$item16$serializ = _this$item16.serialize) === null || _this$item16$serializ === void 0 ? void 0 : _this$item16$serializ.call(_this$item16));
          } else {
            itemClone = R.clone(this.item);
          }

          itemClone.qty = 1;
          this.googleService.addToCart(itemClone);
        }
      },

      minusQty() {
        if (!this.isRemoveDisabled) {
          var _this$item17;

          let qty = this.qty;
          qty = _nventor.default.getAsInt(qty, 1);

          if (this.hasRemoveConfirmation && qty === 1) {
            this.set('showClearCartModal', true);
            return;
          }

          qty = qty - 1;
          qty = this.checkUpperAndLowerLimit(qty);
          this.set('qty', qty);

          if (this.onChangeQty) {
            this.onChangeQty(qty);
          }

          if (qty <= 0) {
            if (this.onZeroQty) {
              this.onZeroQty();
            }
          }

          let itemClone;

          if (this !== null && this !== void 0 && (_this$item17 = this.item) !== null && _this$item17 !== void 0 && _this$item17._data) {
            var _this$item18, _this$item18$serializ;

            // serialize first
            itemClone = R.clone(this === null || this === void 0 ? void 0 : (_this$item18 = this.item) === null || _this$item18 === void 0 ? void 0 : (_this$item18$serializ = _this$item18.serialize) === null || _this$item18$serializ === void 0 ? void 0 : _this$item18$serializ.call(_this$item18));
          } else {
            itemClone = R.clone(this.item);
          }

          itemClone.qty = 1;
          this.googleService.removeFromCart(itemClone);
        }
      },

      focusOnInput() {
        this.set('initialQty', this.checkUpperAndLowerLimit(this.qty));
        this.set('isFocusOnInput', true);
      },

      focusOutOnInput(qty) {
        this.ensureInteger();
        qty = parseInt(qty) || 0;

        if (qty === 0) {
          var _item$_data, _item$_data2;

          if (this.hasRemoveConfirmation && qty < 1) {
            this.set('qty', qty);
            this.set('showClearCartModal', true);
            this.set('resetCount', true);
          }

          const item = (this === null || this === void 0 ? void 0 : this.item) || {};
          const isAutomation = (item === null || item === void 0 ? void 0 : (_item$_data = item._data) === null || _item$_data === void 0 ? void 0 : _item$_data.isAutomation) || false;
          const automationThen = item === null || item === void 0 ? void 0 : (_item$_data2 = item._data) === null || _item$_data2 === void 0 ? void 0 : _item$_data2.automationThen;

          if (isAutomation && automationThen === 'mustAdd' && _nventor.default.isNotNilOrEmpty(this === null || this === void 0 ? void 0 : this.onChangeQty)) {
            this.onChangeQty(qty);
          }
        } else {
          if (qty > this.allowedMaxQty) {
            this.set('qty', this.allowedMaxQty);
          }

          if (this.onChangeQty) {
            var _this$item19;

            const initialQty = parseInt(this.initialQty);
            let itemClone;

            if (this !== null && this !== void 0 && (_this$item19 = this.item) !== null && _this$item19 !== void 0 && _this$item19._data) {
              var _this$item20, _this$item20$serializ;

              // serialize first
              itemClone = R.clone(this === null || this === void 0 ? void 0 : (_this$item20 = this.item) === null || _this$item20 === void 0 ? void 0 : (_this$item20$serializ = _this$item20.serialize) === null || _this$item20$serializ === void 0 ? void 0 : _this$item20$serializ.call(_this$item20));
            } else {
              itemClone = R.clone(this.item);
            }

            itemClone.qty = 1;

            if (initialQty > qty) {
              itemClone.qty = initialQty - qty;
              this.googleService.removeFromCart(itemClone);
            } else {
              itemClone.qty = qty - initialQty;
              this.googleService.addToCart(itemClone);
            }

            if (initialQty !== qty) {
              this.onChangeQty(qty);
            }
          }

          this.set('isFocusOnInput', false);
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "onMinusQtyCancel", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onMinusQtyCancel"), _obj), _applyDecoratedDescriptor(_obj, "onMinusQtySubmit", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onMinusQtySubmit"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/components/shop/back-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3EiY/ZkX",
    "block": "[[[41,[30,0,[\"showBackToShoppingButton\"]],[[[41,[30,1],[[[1,\"    \"],[8,[39,1],null,[[\"@classNames\",\"@label\",\"@onSubmit\"],[[30,0,[\"btnClassNames\"]],[30,0,[\"label\"]],[28,[37,2],[[30,0,[\"websiteService\",\"cleanupDrawerOnClose\"]],[28,[37,3],null,[[\"prop\",\"isGoBackToOriginalUrl\"],[\"isShowDrawerForShopItem\",true]]]],null]]],null],[1,\"\\n\"]],[]],[[[6,[39,4],null,[[\"classNames\",\"query\",\"route\",\"model\"],[[33,5],[28,[37,6],null,[[\"tags\",\"query\"],[[33,7],[33,8]]]],\"shop\",[33,9,[\"shopUrlDisplay\"]]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,10],[[30,0,[\"label\"]]],null]],[1,\"\\n\"]],[]]]]]],[]]]],[]],null]],[\"@showAsModal\"],false,[\"if\",\"elements/element-btn\",\"fn\",\"hash\",\"link-to\",\"btnClassNames\",\"-hash\",\"tagsValue\",\"queryValue\",\"website\",\"tt\"]]",
    "moduleName": "shop/pods/components/shop/back-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/elements/stories-modal-dialog/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StoriesModalDialogComponent = (_class = class StoriesModalDialogComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "hasCloseBtn", _descriptor, this);

      _initializerDefineProperty(this, "hasPauseBtn", _descriptor2, this);

      _initializerDefineProperty(this, "closeOnOverlayClick", _descriptor3, this);

      _initializerDefineProperty(this, "isSliderModalVisible", _descriptor4, this);

      _initializerDefineProperty(this, "mouseDownTime", _descriptor5, this);

      _initializerDefineProperty(this, "clickTime", _descriptor6, this);
    }

    close() {
      if (this.args.onModalClose) {
        return this.args.onModalClose();
      }
    }

    pause(e) {
      const modalCard = e.currentTarget.closest('.modal-card');
      const modalCardBody = modalCard.querySelector('.modal-card-body');

      if (!modalCard || !modalCardBody) {
        return;
      }

      if (this.args.isPaused) {
        modalCardBody.classList.remove('paused-animation');
        return this.args.mouseUp();
      }

      modalCardBody.classList.add('paused-animation');
      return this.args.mouseDown();
    }

    mouseDown(e) {
      this.handleDownEvent(e);
    }

    mouseUp(e) {
      this.handleUpEvent(e);
    }

    touchStart(e) {
      e.preventDefault();
      this.handleDownEvent(e.changedTouches[0]);
    }

    touchEnd(e) {
      this.handleUpEvent(e.changedTouches[0]);
    }

    handleDownEvent(e) {
      const currentTarget = e.currentTarget || e.target;
      currentTarget.closest('.modal-card-body').classList.add('paused-animation');
      this.mouseDownTime = new Date().getTime();

      if (this.args.mouseDown) {
        return this.args.mouseDown();
      }
    }

    handleUpEvent(e) {
      if (this.mouseDownTime) {
        const mouseUpTime = new Date().getTime();
        const timeDifference = mouseUpTime - this.mouseDownTime;
        const story = this.args.story;
        const storyModalCard = document.querySelector('.story-modal-card');
        const modalWidth = storyModalCard.offsetWidth;
        const clickPosition = e.clientX - storyModalCard.getBoundingClientRect().left;
        this.clickTime = timeDifference;

        if (this.clickTime < 200 && clickPosition < modalWidth / 2) {
          this.OnClickToBackSlide(story);
        }

        if (this.clickTime < 200 && clickPosition > modalWidth / 2) {
          this.OnClickToNextSlide(story);
        }

        this.mouseDownTime = null; // Reset
      }

      const currentTarget = e.currentTarget || e.target;
      currentTarget.closest('.modal-card-body').classList.remove('paused-animation');

      if (this.args.mouseUp) {
        return this.args.mouseUp();
      }
    }

    OnClickToBackSlide(story) {
      if (this.args.toBackSlide) {
        return this.args.toBackSlide(story);
      }
    }

    OnClickToNextSlide(story) {
      if (this.args.toNextSlide) {
        return this.args.toNextSlide(story);
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hasCloseBtn", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasPauseBtn", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "closeOnOverlayClick", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isSliderModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "mouseDownTime", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "clickTime", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pause", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "pause"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mouseDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mouseDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mouseUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mouseUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "touchStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "touchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "touchEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "touchEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDownEvent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDownEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleUpEvent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "OnClickToBackSlide", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "OnClickToBackSlide"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "OnClickToNextSlide", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "OnClickToNextSlide"), _class.prototype)), _class);
  _exports.default = StoriesModalDialogComponent;
});
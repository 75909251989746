define("shop/pods/components/cart/item-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iT0kN4vz",
    "block": "[[[41,[51,[28,[37,1],[[30,1,[\"calculatedStatusToDisplayForAddToCart\"]],\"active\"],null]],[[[41,[30,1,[\"statusTagClassName\"]],[[[1,\"    \"],[11,1],[16,0,[29,[\"tag \",[30,1,[\"statusTagClassName\"]],\" is-small is-condensed store-item__qty-child\"]]],[17,2],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1,[\"calculatedStatusToDisplayForAddToCart\"]],\"soldOut\"],null],[[[1,\"        \"],[1,[28,[35,3],[\"soldOut\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,3],[[30,1,[\"calculatedStatusToDisplayForAddToCart\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[\"@itemStatus\",\"&attrs\"],false,[\"unless\",\"eq\",\"if\",\"tt\"]]",
    "moduleName": "shop/pods/components/cart/item-status/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
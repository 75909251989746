define("shop/pods/components/flows/flow-chat-window/flow-message/flow-buttons/component", ["exports", "@ember/component", "@ember/object", "ramda"], function (_exports, _component, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    classNames: [],
    title: (0, _object.computed)('step.text', 'button.metadata.override.text', function () {
      const text = this.get('button.metadata.override.text') || this.get('step.text');
      return text;
    }),
    showButton: (0, _object.computed)('flowPayload', 'button.metadata.onlyWhen', function () {
      const onlyWhen = this.get('button.metadata.onlyWhen') || {};
      const flowPayload = this.flowPayload || {};
      const keys = R.keys(onlyWhen);
      let match = true;
      R.forEach(key => {
        if (onlyWhen[key] !== flowPayload[key]) {
          match = false;
        }
      })(keys);
      return match;
    }),
    actions: {
      openNewLink(button) {
        window.open(button.url, '_blank');
      }

    }
  });

  _exports.default = _default;
});
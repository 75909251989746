define("shop/pods/components/cart/checkout-tag/component", ["exports", "@ember/component", "@ember/object", "ramda"], function (_exports, _component, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    showCart: false,

    init() {
      this._super(...arguments);

      if (!this.shopService.get('validateCartItemsTask').isRunning) {
        this.shopService.get('validateCartItemsTask').perform({
          shop: this.shop
        });
      }
    },

    totalItems: (0, _object.computed)('shop.cartItems.length,shop.cartItems.[]', function () {
      const cartItems = this.get('shop.cartItems');
      return R.pipe(R.reject(R.propEq('isAutomation', true)), R.length)(cartItems); // return this.get('shop.cartItems.length')
    })
  });

  _exports.default = _default;
});
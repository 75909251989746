define("shop/pods/components/news/card/component", ["exports", "@glimmer/component", "shop/utils/nventor", "@ember/service", "ramda"], function (_exports, _component, _nventor, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NewsCardComponent = (_dec = (0, _service.inject)('website'), (_class = class NewsCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "helpers", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _defineProperty(this, "nameLimit", 45);
    }

    get colorRgba() {
      return _nventor.default.hexToRGBA('#fff');
    }

    get cropName() {
      return R.pipe(R.pathOr('', ['args', 'model', '_data', 'name']), R.when(R.propSatisfies(R.gt(R.__, this.nameLimit), 'length'), R.pipe(R.take(this.nameLimit), R.concat(R.__, '...'))))(this);
    }

    get articleNameUrl() {
      var _this$args, _this$args$model, _this$args$model$_dat;

      const articleName = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$model = _this$args.model) === null || _this$args$model === void 0 ? void 0 : (_this$args$model$_dat = _this$args$model._data) === null || _this$args$model$_dat === void 0 ? void 0 : _this$args$model$_dat.name) || '';
      return this.helpers.beautifyUrl(articleName);
    }

    get imagesHoverFilter() {
      var _this$websiteService, _this$websiteService$, _this$websiteService$2, _this$websiteService$3;

      return `hover-filter--${((_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : (_this$websiteService$ = _this$websiteService.website) === null || _this$websiteService$ === void 0 ? void 0 : (_this$websiteService$2 = _this$websiteService$.shop) === null || _this$websiteService$2 === void 0 ? void 0 : (_this$websiteService$3 = _this$websiteService$2._data) === null || _this$websiteService$3 === void 0 ? void 0 : _this$websiteService$3.productsImagesHoverFilter) || 100}`;
    }

    get imagesHoverTransform() {
      var _this$websiteService2, _this$websiteService3, _this$websiteService4, _this$websiteService5;

      return `hover-transform--${((_this$websiteService2 = this.websiteService) === null || _this$websiteService2 === void 0 ? void 0 : (_this$websiteService3 = _this$websiteService2.website) === null || _this$websiteService3 === void 0 ? void 0 : (_this$websiteService4 = _this$websiteService3.shop) === null || _this$websiteService4 === void 0 ? void 0 : (_this$websiteService5 = _this$websiteService4._data) === null || _this$websiteService5 === void 0 ? void 0 : _this$websiteService5.productsImagesHoverTransform) || 'bigger'}`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "helpers", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = NewsCardComponent;
});
define("shop/pods/google/gtm/service", ["exports", "@ember/service", "ramda", "@ember/object", "@glimmer/tracking"], function (_exports, _service, R, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CURRENCY = 'TWD';
  let GoogleGtmService = (_dec = (0, _service.inject)('google'), (_class = class GoogleGtmService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "googleService", _descriptor, this);

      _initializerDefineProperty(this, "hasTrackingId", _descriptor2, this);

      _initializerDefineProperty(this, "allTrackingData", _descriptor3, this);
    }

    genAddressHref(address) {
      return R.replace(/\s/gm, '+')(address);
    }

    get hasTracker() {
      if (!this.hasTrackingId) {
        return false;
      }

      if (!window.dataLayer) {
        return false;
      }

      return true;
    }

    addTracker(trackingData) {
      const trackingId = trackingData === null || trackingData === void 0 ? void 0 : trackingData.id;
      const allTrackingData = this.allTrackingData || [];
      const alreadyAdded = R.find(R.propEq('id', trackingId))(allTrackingData);

      if (!alreadyAdded) {
        window.dataLayer = window.dataLayer || [];
        this.allTrackingData.pushObject(trackingData);
        const customScript = document.createElement('script');
        customScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${trackingData.id}');`;
        document.getElementsByTagName('head')[0].appendChild(customScript);
        const customNonScript = document.createElement('noscript');
        customNonScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${trackingData.id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
        document.getElementsByTagName('body')[0].appendChild(customNonScript);
      }

      this.hasTrackingId = true;
    }

    addToCart(cartItem) {
      this._sendEvent('add_to_cart', cartItem);
    }

    removeFromCart(cartItem) {
      this._sendEvent('remove_from_cart', cartItem);
    }

    viewItem(item) {
      this._sendEvent('view_item', item);
    }

    _sendEvent(event, item) {
      if (!this.hasTracker) {
        return false;
      }

      window.dataLayer.push({
        ecommerce: null
      });
      const ga4Items = this.googleService.parseItems([item]);
      window.dataLayer.push({
        event,
        ecommerce: {
          items: ga4Items
        }
      });
    }

    checkoutStep({
      step,
      shop
    }) {
      if (!this.hasTracker) {
        return false;
      }

      const cartItems = shop.cartItems || [];
      window.dataLayer.push({
        ecommerce: null
      });
      let event = '';

      if (step === 1) {
        event = 'begin_checkout';
      } else if (step === 2) {
        event = 'add_shipping_info';
      } else if (step === 3) {
        event = 'add_payment_info';
      }

      const ga4Items = this.googleService.parseItems(cartItems);
      window.dataLayer.push({
        event,
        ecommerce: {
          currency: CURRENCY,
          value: shop.inclTotal || 0,
          items: ga4Items
        }
      });
    }

    checkoutComplete(success = '', order = {}) {
      var _success$_data;

      if (!this.hasTracker) {
        return false;
      }

      if (!order.inclTotal) {
        return false;
      }

      window.dataLayer.push({
        ecommerce: null
      }); // Clear the previous ecommerce object.

      const cartItems = order.details || [];
      const ga4Items = this.googleService.parseItems(cartItems);
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: success === null || success === void 0 ? void 0 : (_success$_data = success._data) === null || _success$_data === void 0 ? void 0 : _success$_data.ref,
          value: order.inclTotal,
          currency: CURRENCY,
          items: ga4Items
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "googleService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasTrackingId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "allTrackingData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addTracker", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addTracker"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addToCart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addToCart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeFromCart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeFromCart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "viewItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_sendEvent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_sendEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkoutStep", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkoutStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkoutComplete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkoutComplete"), _class.prototype)), _class));
  _exports.default = GoogleGtmService;
});
define("shop/pods/components/elements/convenience-stores-searcher/component", ["exports", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/object", "ember-concurrency-decorators", "@ember/array", "ramda", "shop/utils/nventor"], function (_exports, _component, _service, _tracking, _object, _emberConcurrencyDecorators, _array, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ElementsConvenienceStoresSearcher = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('shop'), (_class = class ElementsConvenienceStoresSearcher extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "convenienceStoresQuery", _descriptor, this);

      _initializerDefineProperty(this, "allRegions", _descriptor2, this);

      _initializerDefineProperty(this, "searchByDistrict", _descriptor3, this);

      _initializerDefineProperty(this, "searchByStore", _descriptor4, this);

      _initializerDefineProperty(this, "convenienceStoresCity", _descriptor5, this);

      _initializerDefineProperty(this, "convenienceStoresArea", _descriptor6, this);

      _initializerDefineProperty(this, "storeId", _descriptor7, this);

      _initializerDefineProperty(this, "storeName", _descriptor8, this);

      _initializerDefineProperty(this, "userConvenienceStoreData", _descriptor9, this);

      _initializerDefineProperty(this, "intl", _descriptor10, this);

      _initializerDefineProperty(this, "websiteService", _descriptor11, this);

      _initializerDefineProperty(this, "shopService", _descriptor12, this);

      _defineProperty(this, "convenienceStoresBrands", Object.freeze({
        'Family Mart': 'familyMart',
        'Seven Eleven': 'sevenEleven'
      }));
    }

    get dataForCountry() {
      return R.find(R.propEq('countryCode', 'TW'))(this.allRegions) || {};
    }

    get regionsForCountry() {
      var _this$dataForCountry;

      let regions = ((_this$dataForCountry = this.dataForCountry) === null || _this$dataForCountry === void 0 ? void 0 : _this$dataForCountry.regions) || [];
      return regions;
    }

    get districtsForRegion() {
      const getRegionKey = zhTw => {
        return R.pipe(R.filter(R.pathEq(['zh-tw'], zhTw)), R.head, R.path(['key']))(this.regionsForCountry);
      };

      const region = this.convenienceStoresCity || '';
      const regionKey = getRegionKey(region);
      const dataForCountry = this.dataForCountry || {};
      let districts = (dataForCountry === null || dataForCountry === void 0 ? void 0 : dataForCountry.districts) || [];
      return R.filter(R.pathEq(['region_key'], regionKey))(districts);
    }

    get hasConvenienceStoreSearch() {
      var _this$args;

      const checkoutData = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.checkoutData) || {};
      const convenienceStoresList = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.convenienceStoresList) || [];

      if ((convenienceStoresList === null || convenienceStoresList === void 0 ? void 0 : convenienceStoresList.length) === 0) {
        return true;
      }

      if (this.searchByDistrict || this.searchByStore) {
        return true;
      }

      return false;
    }

    get isSearchConvenienceStoreDisabled() {
      if (this.searchByStore && (this.storeId || this.storeName)) {
        return false;
      }

      if (this.searchByDistrict && this.convenienceStoresArea) {
        return false;
      }

      return true;
    }

    get showFetchUserBtn() {
      const convenienceStoresData = this.userConvenienceStoreData;

      if (convenienceStoresData !== null && convenienceStoresData !== void 0 && convenienceStoresData.storeId && convenienceStoresData !== null && convenienceStoresData !== void 0 && convenienceStoresData.storeName) {
        return true;
      }

      return false;
    }

    *fetchRegionsTask(convenienceStoreData = {}) {
      var _this$websiteService, _this$websiteService$, _this$websiteService$2;

      yield (_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : (_this$websiteService$ = _this$websiteService.fetchRegionsTask) === null || _this$websiteService$ === void 0 ? void 0 : (_this$websiteService$2 = _this$websiteService$.perform) === null || _this$websiteService$2 === void 0 ? void 0 : _this$websiteService$2.call(_this$websiteService$, 'TW');
      const website = this.args.website || {};
      this.allRegions = (website === null || website === void 0 ? void 0 : website.allRegions) || [];

      if (convenienceStoreData !== null && convenienceStoreData !== void 0 && convenienceStoreData.address || convenienceStoreData !== null && convenienceStoreData !== void 0 && convenienceStoreData.storeAddress) {
        this.onSelectConvenienceStore(convenienceStoreData);
      }
    }

    *searchConvenienceStoresTask() {
      var _this$args2, _results$data;

      const checkoutData = (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.checkoutData;
      (0, _object.set)(checkoutData, 'convenienceStoreAddress', '');
      this.searchByStore = false;
      this.searchByDistrict = false;
      const brand = checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.pickupMethod;
      const query = this.convenienceStoresQuery;
      const city = this.convenienceStoresCity || '';
      const area = this.convenienceStoresArea || '';
      const storeId = this.storeId || '';
      const storeName = this.storeName || '';
      const params = {
        query,
        city,
        area,
        storeId,
        storeName
      };
      const url = `/api/public/channels/shop/checkout/list/${brand}`;
      const results = yield this.websiteService.request(url, {
        method: 'GET',
        data: params
      });

      if ((results === null || results === void 0 ? void 0 : (_results$data = results.data) === null || _results$data === void 0 ? void 0 : _results$data.length) > 0) {
        (0, _object.set)(checkoutData, 'convenienceStoresList', results.data);
      } else {
        (0, _object.set)(checkoutData, 'convenienceStoreAddress', this.intl.t('no matched results'));
      }
    }

    toggleSearchCondition(instruct) {
      if (instruct === 'district') {
        this.searchByDistrict = !this.searchByDistrict;
      }

      if (instruct === 'store') {
        this.searchByStore = !this.searchByStore;
      }

      this.setSearchCondition();
    }

    setSearchCondition() {
      var _this$args3;

      const checkoutData = ((_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.checkoutData) || {};

      if (!this.searchByDistrict) {
        this.convenienceStoresCity = '';
        this.convenienceStoresQuery = '';
      }

      if (!this.searchByStore) {
        this.storeId = '';
        this.storeName = '';
      }

      (0, _object.set)(checkoutData, 'convenienceStoresList', []);
    }

    clearAreaAndQuery() {
      var _this$args4;

      const checkoutData = ((_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : _this$args4.checkoutData) || {};
      this.convenienceStoresArea = '';
      this.convenienceStoresQuery = '';
      (0, _object.set)(checkoutData, 'convenienceStoresList', []);
    }

    onSelectConvenienceStore(convenienceStore) {
      var _this$args5, _this$args6, _this$convenienceStor, _postCode$toString, _this$shopService, _this$shopService$val, _this$shopService$val2, _this$args7;

      const checkoutData = ((_this$args5 = this.args) === null || _this$args5 === void 0 ? void 0 : _this$args5.checkoutData) || {};
      const user = ((_this$args6 = this.args) === null || _this$args6 === void 0 ? void 0 : _this$args6.user) || {};
      const city = (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.city) || (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.region) || '';
      this.convenienceStoresCity = city;
      const area = (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.area) || (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.district) || '';
      this.convenienceStoresArea = area;
      const address = (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.address) || (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.street) || '';
      const storeName = (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.name) || (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.storeName) || '';
      this.storeName = storeName;
      const storeId = (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.storeId) || '';
      this.storeId = storeId;
      const pickupMethod = checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.pickupMethod;
      const brand = ((_this$convenienceStor = this.convenienceStoresBrands) === null || _this$convenienceStor === void 0 ? void 0 : _this$convenienceStor[convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.brand]) || (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.brand);
      const districtData = R.find(R.pathEq(['zh-tw'], area))(this.districtsForRegion); // const regionKey = R.pathOr('', ['region_key'])(districtData)

      const postCode = R.pathOr(null, ['postcode'])(districtData);

      if (storeId && storeName) {
        const storeAddress = `(${storeId}${storeName})${city}${area}${address}`;
        (0, _object.set)(checkoutData, 'convenienceStoreAddress', storeAddress);
      }

      (0, _object.set)(checkoutData, 'convenienceStoresList', []);
      const convenienceStoreSelected = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.convenienceStoreSelected) || [];

      if (_nventor.default.isNilOrEmpty(convenienceStoreSelected)) {
        convenienceStoreSelected.push({
          pickupMethod,
          convenienceStore
        });
      } else {
        const store = R.find(R.propEq('pickupMethod', pickupMethod))(convenienceStoreSelected);

        if (store) {
          store.convenienceStore = convenienceStore;
        } else {
          convenienceStoreSelected.push({
            pickupMethod,
            convenienceStore
          });
        }
      }

      const userConvenienceStoreData = {
        brand: brand,
        country: 'Taiwan',
        countryCode: 'TW',
        hasAdvancedAddress: true,
        hasPostCode: true,
        region: city,
        district: area,
        postCode: postCode === null || postCode === void 0 ? void 0 : (_postCode$toString = postCode.toString) === null || _postCode$toString === void 0 ? void 0 : _postCode$toString.call(postCode),
        street: address,
        storeId,
        storeName,
        storeAddress: `${city}${area}${address}`,
        type: 'convenienceStore'
      };
      const userAddress = `[${this.intl.t(brand)}](${storeId}${storeName})${userConvenienceStoreData === null || userConvenienceStoreData === void 0 ? void 0 : userConvenienceStoreData.storeAddress}`;
      (0, _object.set)(checkoutData, 'convenienceStoreSelected', convenienceStoreSelected);
      (0, _object.set)(checkoutData, 'address', userAddress);
      (0, _object.set)(checkoutData, 'isConvenienceStore', true);
      (0, _object.set)(checkoutData, 'convenienceStoreData', userConvenienceStoreData);
      (0, _object.set)(user, 'convenienceStoreData', userConvenienceStoreData);
      (0, _object.set)(checkoutData, 'storeId', storeId);
      (0, _object.set)(checkoutData, 'storeName', storeName);
      (0, _object.set)(checkoutData, 'convenienceStoresCity', city);
      (0, _object.set)(checkoutData, 'convenienceStoresArea', area);
      this.searchByStore = false;
      this.searchByDistrict = false;
      this.userConvenienceStoreData = userConvenienceStoreData;
      user.setData('hasAddress', true);
      user.setData('addressData', userConvenienceStoreData);
      this.args.onSetMemberAddress(this.args.user, this.args.checkoutData);
      (_this$shopService = this.shopService) === null || _this$shopService === void 0 ? void 0 : (_this$shopService$val = _this$shopService.validateCartItemsTask) === null || _this$shopService$val === void 0 ? void 0 : (_this$shopService$val2 = _this$shopService$val.perform) === null || _this$shopService$val2 === void 0 ? void 0 : _this$shopService$val2.call(_this$shopService$val, {
        shop: (_this$args7 = this.args) === null || _this$args7 === void 0 ? void 0 : _this$args7.shop,
        checkoutData,
        user,
        hasAddress: true,
        forceValidation: true
      });
    }

    fetchUserConvenienceStore() {
      var _this$args8;

      const checkoutData = ((_this$args8 = this.args) === null || _this$args8 === void 0 ? void 0 : _this$args8.checkoutData) || {}; // @TODO: put this logic to local storage rather than fetch from user

      const convenienceStoreSelected = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.convenienceStoreSelected) || [];
      const pickupMethod = checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.pickupMethod;
      const convenienceStoreData = R.find(R.propEq('pickupMethod', pickupMethod))(convenienceStoreSelected);
      const isRestoreAllowed = (convenienceStoreData === null || convenienceStoreData === void 0 ? void 0 : convenienceStoreData.pickupMethod) === pickupMethod;
      (0, _object.set)(checkoutData, 'storeId', '');
      (0, _object.set)(checkoutData, 'storeName', '');
      (0, _object.set)(checkoutData, 'convenienceStoresCity', '');
      (0, _object.set)(checkoutData, 'convenienceStoresArea', '');
      this.storeId = '';
      this.storeName = '';
      this.convenienceStoresCity = '';
      this.convenienceStoresArea = '';

      if (isRestoreAllowed) {
        const convenienceStore = convenienceStoreData === null || convenienceStoreData === void 0 ? void 0 : convenienceStoreData.convenienceStore;
        const storeId = (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.storeId) || '';
        const storeName = (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.name) || (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.storeName) || '';
        const city = (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.city) || (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.region) || '';
        const area = (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.area) || (convenienceStore === null || convenienceStore === void 0 ? void 0 : convenienceStore.district) || '';
        (0, _object.set)(checkoutData, 'storeId', storeId);
        (0, _object.set)(checkoutData, 'storeName', storeName);
        (0, _object.set)(checkoutData, 'convenienceStoresCity', city);
        (0, _object.set)(checkoutData, 'convenienceStoresArea', area);
        this.storeId = storeId;
        this.storeName = storeName;
        this.convenienceStoresCity = city;
        this.convenienceStoresArea = area;
        this.searchConvenienceStoresTask.perform();
      }

      this.searchByStore = true;
      this.searchByDistrict = true;
    }

    clearConvenienceStoreAddress() {
      var _this$args9;

      const checkoutData = (_this$args9 = this.args) === null || _this$args9 === void 0 ? void 0 : _this$args9.checkoutData;
      this.convenienceStoresCity = '';
      this.convenienceStoresArea = '';
      this.storeId = '';
      this.storeName = '';
      (0, _object.set)(checkoutData, 'convenienceStoresCity', '');
      (0, _object.set)(checkoutData, 'convenienceStoresArea', '');
      (0, _object.set)(checkoutData, 'convenienceStoreData', {});
      (0, _object.set)(checkoutData, 'storeId', '');
      (0, _object.set)(checkoutData, 'storeName', '');
      (0, _object.set)(checkoutData, 'convenienceStoreAddress', '');
      (0, _object.set)(checkoutData, 'convenienceStoresList', []);
      (0, _object.set)(checkoutData, 'convenienceStoreSelected', []);
      this.convenienceStoresQuery = '';
      this.searchByStore = false;
      this.searchByDistrict = true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "convenienceStoresQuery", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "allRegions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "searchByDistrict", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "searchByStore", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "convenienceStoresCity", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "convenienceStoresArea", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "storeId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "storeName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "userConvenienceStoreData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchRegionsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchRegionsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchConvenienceStoresTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchConvenienceStoresTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSearchCondition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSearchCondition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSearchCondition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setSearchCondition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearAreaAndQuery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearAreaAndQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectConvenienceStore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectConvenienceStore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchUserConvenienceStore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchUserConvenienceStore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearConvenienceStoreAddress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearConvenienceStoreAddress"), _class.prototype)), _class));
  _exports.default = ElementsConvenienceStoresSearcher;
});
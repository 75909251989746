define("shop/pods/components/layouts/nav-btn/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'span',
    classNames: ['nav-btn'],
    showNavMenu: false
  });

  _exports.default = _default;
});
define("shop/pods/components/blogs/blog-page/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { inject as service } from '@ember/service'
  class BlogsBlogPageComponent extends _component.default {
    get imgWidth() {
      return this.args.width || 500;
    }

    get imgSrcTransformUrl() {
      const imgWidth = this.imgWidth;
      return `/-/resize/${imgWidth}x/`;
    }

    get imgSrcsetTransformUrl() {
      const imgWidth = parseInt(this.imgWidth);
      const width2x = imgWidth * 2;
      return `/-/resize/${width2x}/-/quality/lighter/ 2x`;
    }

  }

  _exports.default = BlogsBlogPageComponent;
});
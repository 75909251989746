define("shop/pods/shop/orders/route", ["exports", "@ember/routing/route", "rsvp", "shop/pods/page/model", "ramda"], function (_exports, _route, _rsvp, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    async model(params) {
      const host = this.modelFor('application').host;
      const website = this.modelFor('application').website;
      const shop = this.modelFor('application').shop || (website === null || website === void 0 ? void 0 : website.shop);
      const isCampaign = this.modelFor('application').isCampaign;
      const ordersPage = website.getDynamicPage('check order status');
      const homePageRedirect = R.propEq('homePageRedirect', true)(ordersPage);

      if (homePageRedirect) {
        return this.transitionTo('/');
      }

      const page = _model.default.create();

      page.populate(ordersPage);

      try {
        return _rsvp.default.hash({
          host,
          params,
          shop,
          website,
          isCampaign,
          page
        });
      } catch (e) {
        this.transitionTo('/');
      }
    }

  });

  _exports.default = _default;
});
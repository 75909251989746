define("shop/pods/shop/members/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JuUdeRn5",
    "block": "[[[41,[30,0,[\"model\",\"shop\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@model\",\"@shop\",\"@params\"],[[30,0,[\"model\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"params\"]]]],[[\"default\"],[[[[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@isIndex\",\"@website\",\"@page\",\"@shop\",\"@params\",\"@onFilterTask\",\"@query\"],[true,[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"params\"]],[30,0,[\"pageComponent\",\"fetchTask\"]],[30,0,[\"model\",\"params\",\"query\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"model\",\"website\",\"isLoggedIn\"]],[[[1,\"        \"],[8,[39,3],[[24,0,\"tabs-on-top-container\"]],[[\"@isActiveUsingRoute\",\"@tabs\",\"@setTabActive\"],[true,[30,0,[\"model\",\"profileMenuLists\",\"filterModel\",\"_data\",\"tags\"]],[28,[37,4],[[30,2,[\"goToTabLink\"]]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"isCampaign\"]]],null],[[[1,\"        \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n          \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n          \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\\n    \"]],[2]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"pageComponent\",\"websiteContainer\"],false,[\"if\",\"shop/page-content\",\"website/website-container\",\"tabs/headers\",\"fn\",\"and\",\"component\",\"-outlet\"]]",
    "moduleName": "shop/pods/shop/members/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
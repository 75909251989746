define("shop/pods/components/shop/tags-menu/component", ["exports", "@ember/component", "jquery"], function (_exports, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',

    didInsertElement() {
      this._super(...arguments);

      const element = (0, _jquery.default)(this.element).find('.tags-menu__ul');
      const width = element.outerWidth();
      const scrollWidth = (0, _jquery.default)(window).width();

      if (width > scrollWidth) {
        this.set('hasOverflow', true);
      } else {
        this.set('hasOverflow', false);
      }
    },

    actions: {
      scrollRight() {
        const element = (0, _jquery.default)(this.element).find('.tags-nav-bar__container');
        const scrollWidth = (0, _jquery.default)(window).width() - 150;
        const leftPos = element.scrollLeft();
        element.animate({
          scrollLeft: leftPos + scrollWidth
        }, 800);
      }

    }
  });

  _exports.default = _default;
});
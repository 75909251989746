define("shop/helpers/isEmpty", ["exports", "ramda", "@ember/component/helper"], function (_exports, R, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    // should be renamed isPropEmpty?
    compute(params) {
      const obj = params[0];
      const prop = params[1];
      const value = obj[prop];

      if (!value) {
        return true;
      }

      if (R.is(Array, value) || R.is(Object)) {
        return R.isEmpty(value);
      }

      if (value) {
        return true;
      }

      return false;
    }

  });

  _exports.default = _default;
});
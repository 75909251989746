define("shop/pods/item/service", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    storage: (0, _service.inject)(),
    router: (0, _service.inject)(),
    helpers: (0, _service.inject)(),
    oneDay: 8.64e+7,

    isUnlocked(item) {
      var _item$_data, _item$_data2;

      const hasPassword = item === null || item === void 0 ? void 0 : (_item$_data = item._data) === null || _item$_data === void 0 ? void 0 : _item$_data.hasPassword;

      if (!hasPassword) {
        (0, _object.set)(item, 'isUnlocked', true);
        return;
      }

      const itemKey = item === null || item === void 0 ? void 0 : (_item$_data2 = item._data) === null || _item$_data2 === void 0 ? void 0 : _item$_data2._key;
      const isUnlocked = this.storage.getValueWithShopKey(`isUnlocked_${itemKey}`);

      if (isUnlocked) {
        (0, _object.set)(item, 'isUnlocked', true);
        return;
      }

      (0, _object.set)(item, 'isUnlocked', false);
    },

    unlockItem({
      item,
      itemPassword
    }) {
      var _item$_data3;

      const hasPassword = (item === null || item === void 0 ? void 0 : (_item$_data3 = item._data) === null || _item$_data3 === void 0 ? void 0 : _item$_data3.hasPassword) || false;

      if (hasPassword) {
        var _item$_data4;

        const password = item === null || item === void 0 ? void 0 : (_item$_data4 = item._data) === null || _item$_data4 === void 0 ? void 0 : _item$_data4.password;

        if (itemPassword === password) {
          var _item$_data5;

          (0, _object.set)(item, 'isUnlocked', true);
          const itemKey = item === null || item === void 0 ? void 0 : (_item$_data5 = item._data) === null || _item$_data5 === void 0 ? void 0 : _item$_data5._key;
          this.storage.setValueWithShopKey(`isUnlocked_${itemKey}`, true, this.oneDay);
          return {
            isSuccess: true
          };
        } else {
          (0, _object.set)(item, 'isUnlocked', false);
          return {
            isSuccess: false
          };
        }
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/shop/checkout/success/route", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "ramda"], function (_exports, _route, _rsvp, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    configService: (0, _service.inject)('config'),
    googleService: (0, _service.inject)('google'),
    websiteService: (0, _service.inject)('website'),
    storage: (0, _service.inject)(),
    cookie: (0, _service.inject)(),
    flowsService: (0, _service.inject)('flows'),
    scrollService: (0, _service.inject)('scroll'),

    model(params) {
      var _this$modelFor, _this$modelFor2, _this$modelFor3, _shop$_data, _shop$_data$thirdPart, _shop$_data$thirdPart2;

      // clear cart
      const ref = params.ref_id;
      const success = {
        _data: {
          ref
        }
      };
      const website = (_this$modelFor = this.modelFor('application')) === null || _this$modelFor === void 0 ? void 0 : _this$modelFor.website;
      const isCampaign = (_this$modelFor2 = this.modelFor('application')) === null || _this$modelFor2 === void 0 ? void 0 : _this$modelFor2.isCampaign; // Handle Error:
      // eventID: 5E24lm7xl38rnkxz

      const shop = (_this$modelFor3 = this.modelFor('shop')) === null || _this$modelFor3 === void 0 ? void 0 : _this$modelFor3.shop;
      const userData = this.storage.getValue('checkoutUser', website);
      const paymentData = this.storage.getValueWithShopKey('paymentData', website);
      this.storage.removeWithShopKey('paymentData'); // @NOTE: this might be triggered twice
      // this.checkoutService.queryCheckoutStatus(shop)

      const memberId = website.get('user._data.memberId') || (userData === null || userData === void 0 ? void 0 : userData.memberId) || '';

      if (!R.isNil(userData)) {
        this.get('websiteService').get('logTask').perform({
          event: 'view',
          eventType: 'view',
          memberId,
          ref,
          email: (userData === null || userData === void 0 ? void 0 : userData.email) || '',
          allowReloadPage: false
        });
      }

      shop.cartSuccessClear();
      const shopDotComTrackingData = this.websiteService.getTrackingDataForTwShopDotCom();
      const rid = (shopDotComTrackingData === null || shopDotComTrackingData === void 0 ? void 0 : shopDotComTrackingData.rid) || '';
      const clickId = (shopDotComTrackingData === null || shopDotComTrackingData === void 0 ? void 0 : shopDotComTrackingData.clickId) || '';
      const isFromShopDotCom = rid && clickId;
      let isShopDotComEnabled = false;
      const shopDotComEnabled = shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : (_shop$_data$thirdPart = _shop$_data.thirdParty) === null || _shop$_data$thirdPart === void 0 ? void 0 : (_shop$_data$thirdPart2 = _shop$_data$thirdPart.shopDotCom) === null || _shop$_data$thirdPart2 === void 0 ? void 0 : _shop$_data$thirdPart2.enabled;

      if (isFromShopDotCom && shopDotComEnabled) {
        isShopDotComEnabled = true;
      }

      return _rsvp.default.hash({
        success,
        paymentData,
        user: userData,
        shop: shop,
        website,
        isCampaign,
        isShopDotComEnabled,
        scrollService: this.scrollService
      });
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/shop/side-bar/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',
    classNames: ['sidebar-outer', 'has-text-left']
  });

  _exports.default = _default;
});
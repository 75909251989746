define("shop/pods/shop/faqs/route", ["exports", "@ember/routing/route", "rsvp", "shop/pods/page/model", "ramda"], function (_exports, _route, _rsvp, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ShopFaqsRoute extends _route.default {
    async model(params) {
      const host = this.modelFor('application').host;
      const website = this.modelFor('application').website;
      const shop = this.modelFor('application').shop;
      const isCampaign = this.modelFor('application').isCampaign;
      const faqsPage = website.getDynamicPage('faqs');
      const homePageRedirect = R.propEq('homePageRedirect', true)(faqsPage);

      if (homePageRedirect) {
        return this.transitionTo('/');
      }

      const page = _model.default.create();

      page.populate(faqsPage);

      try {
        return _rsvp.default.hash({
          host,
          params,
          shop,
          website,
          isCampaign,
          page
        });
      } catch (e) {
        this.transitionTo('/');
      }
    }

  }

  _exports.default = ShopFaqsRoute;
});
define("shop/pods/shop/automation/model", ["exports", "ramda", "@ember/object", "shop/mixins/base", "shop/mixins/big", "shop/mixins/date", "shop/utils/nventor", "shop/pods/shop/automation/automationItem/model", "shop/pods/shop/item/model"], function (_exports, R, _object, _base, _big, _date, _nventor, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_base.default, _big.default, _date.default, {
    populate(data) {
      data.details = this._transformDetails(data);
      data.selected = this._transformSelected(data);
      data = {
        _data: data
      };
      this.setProperties(data);
    },

    _transformSelected(data) {
      const selected = R.propOr([], 'selected')(data);
      return R.map(item => {
        const isClass = R.hasPath(['_data'])(item);

        if (!isClass) {
          const automationItemModel = _model.default.create();

          automationItemModel.populate(item);
          return automationItemModel;
        }

        return item;
      })(selected);
    },

    _transformDetails(data) {
      const details = R.propOr([], 'details')(data);
      return R.pipe(R.map(detail => {
        var _detail$_data;

        const automationThen = (detail === null || detail === void 0 ? void 0 : detail.automationThen) || (detail === null || detail === void 0 ? void 0 : (_detail$_data = detail._data) === null || _detail$_data === void 0 ? void 0 : _detail$_data.automationThen) || '';

        if (automationThen === 'mustAdd' && detail.calculatedStatus === 'soldOut') {
          return '';
        }

        const isClass = R.hasPath(['_data'])(detail);

        if (!isClass) {
          const itemModel = _model2.default.create();

          itemModel.populate(detail);
          return itemModel;
        }

        return detail;
      }), R.reject(_nventor.default.isNilOrEmpty))(details);
    },

    isMustAddBetweenWithOneDetail: (0, _object.computed)('_data.{thenAction,thenSelectionType,details.[]}', function () {
      var _this$_data, _this$_data2, _this$_data3;

      const thenAction = this === null || this === void 0 ? void 0 : (_this$_data = this._data) === null || _this$_data === void 0 ? void 0 : _this$_data.thenAction;
      const thenSelectionType = this === null || this === void 0 ? void 0 : (_this$_data2 = this._data) === null || _this$_data2 === void 0 ? void 0 : _this$_data2.thenSelectionType;
      const details = (this === null || this === void 0 ? void 0 : (_this$_data3 = this._data) === null || _this$_data3 === void 0 ? void 0 : _this$_data3.details) || [];

      if (thenAction === 'mustAdd' && thenSelectionType === 'between' && R.length(details) === 1) {
        return true;
      }

      return false;
    }),
    isMustAddAll: (0, _object.computed)('_data.{thenAction,thenSelectionType}', function () {
      const thenAction = this.get('_data.thenAction');
      const thenSelectionType = this.get('_data.thenSelectionType');

      if (thenAction === 'mustAdd' && thenSelectionType === 'all') {
        return true;
      }

      return false;
    }),
    totalSelected: (0, _object.computed)('_data.details.@each.selectedQty', 'updated', function () {
      const updated = this.get('updated');
      const details = this.get('_data.details') || [];
      const totalQty = R.pipe(R.map(detail => {
        return Number(detail.get('_data.qty') || 0);
      }), R.sum)(details);
      return totalQty;
    }),
    isComplete: (0, _object.computed)('isMustAddAll', 'totalSelected', '_data.thenSelectionQty', function () {
      var _this$_data4;

      if (this.get('isMustAddAll')) {
        return true;
      }

      const totalSelected = parseInt(this === null || this === void 0 ? void 0 : this.totalSelected) || 0;
      const thenSelectionQty = parseInt(this === null || this === void 0 ? void 0 : (_this$_data4 = this._data) === null || _this$_data4 === void 0 ? void 0 : _this$_data4.thenSelectionQty) || 0;

      if (totalSelected === thenSelectionQty) {
        return true;
      }

      return false;
    }),
    // used for hasAutomationsError
    hasError: (0, _object.computed)('_data.hasError', function () {
      var _this$_data5;

      return (this === null || this === void 0 ? void 0 : (_this$_data5 = this._data) === null || _this$_data5 === void 0 ? void 0 : _this$_data5.hasError) || false;
    })
  });

  _exports.default = _default;
});
define("shop/pods/components/items/item-card/status/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CartAddBtnStatus extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "qty", 1);

      _defineProperty(this, "tagName", 'span');
    }

    get showTagsRow() {
      const itemStatus = this.args.itemStatus || {};

      if (R.prop('isAdded')(itemStatus)) {
        return true;
      }

      const hasOtherStatuses = R.anyPass([R.propEq('isSoldOut', true), R.propEq('isOutOfStock', true), R.propEq('isPreOrder', true)]);

      if (hasOtherStatuses(itemStatus)) {
        return true;
      }

      return false;
    }

  }

  _exports.default = CartAddBtnStatus;
});
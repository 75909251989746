define("shop/pods/components/components-group-scratch-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cGruwi3I",
    "block": "[[[11,0],[16,0,[29,[\"scratch-card__outer-container flex-container-row flex-container--justify-center \",[30,1]]]],[4,[38,0],[[28,[37,1],[[30,0,[\"willDestroyElement\"]]],null]],null],[4,[38,2],[[28,[37,3],[[30,0,[\"setupTask\"]]],null]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"scratch-card__container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"scratch-card flex-container-column flex-container--justify-center\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"scratch-card__text-layer content-display no-margin\"],[12],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"        \"],[10,0],[14,0,\"has-text-centered\"],[12],[1,\"\\n          \"],[11,\"button\"],[24,0,\"button scratch-card__close-btn\"],[4,[38,5],[\"click\",[28,[37,3],[[30,0,[\"startTask\"]]],null]],null],[12],[1,\"\\n            \"],[1,[28,[35,6],[\"close\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[28,[37,7],[[30,3],[28,[37,8],[[30,0,[\"revealed\"]]],null]],null],[[[1,\"      \"],[11,\"button\"],[24,0,\"button scratch-card__reveal-btn\"],[4,[38,5],[\"click\",[28,[37,1],[[30,0,[\"reveal\"]]],null]],null],[12],[1,\"\\n        \"],[1,[28,[35,6],[\"reveal\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@classNames\",\"@startTask\",\"@onDone\"],false,[\"will-destroy\",\"fn\",\"did-insert\",\"perform\",\"if\",\"on\",\"tt\",\"and\",\"not\"]]",
    "moduleName": "shop/pods/components/components-group-scratch-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/blogs/blog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6u8xHAfg",
    "block": "[[[8,[39,0],null,[[\"@breadcrumbModel\",\"@isBlog\",\"@shop\",\"@website\"],[[30,0,[\"model\",\"blog\"]],true,[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@blog\",\"@host\",\"@params\",\"@shop\",\"@website\"],[[30,0,[\"model\",\"blog\"]],[30,0,[\"model\",\"host\"]],[30,0,[\"model\",\"params\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"website/website-container\",\"layouts/page-content\",\"blogs/blog-page\"]]",
    "moduleName": "shop/pods/blogs/blog/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
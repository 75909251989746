define("shop/pods/components/cart/add-btn/component", ["exports", "ramda", "@ember/component", "@ember/object", "ember-concurrency", "@ember/service", "shop/mixins/automations", "shop/utils/nventor"], function (_exports, R, _component, _object, _emberConcurrency, _service, _automations, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_automations.default, (_obj = {
    googleService: (0, _service.inject)('google'),
    websiteService: (0, _service.inject)('website'),
    storage: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    helpers: (0, _service.inject)(),
    router: (0, _service.inject)(),
    itemService: (0, _service.inject)('item'),
    qty: 1,
    // fastboot: inject(),
    tagName: 'span',
    isSolid: true,
    showIsAdded: true,
    isAllowed: true,
    isRemove: false,
    isPasswordModalVisible: false,
    itemPassword: '',
    isUnlockNow: false,
    isPasswordError: false,
    buttonLabel: (0, _object.computed)('shop._data.hasOneItemLimitPerCheckout', function () {
      var _this$shop, _this$shop$_data;

      const hasOneItemLimitPerCheckout = ((_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : (_this$shop$_data = _this$shop._data) === null || _this$shop$_data === void 0 ? void 0 : _this$shop$_data.hasOneItemLimitPerCheckout) || false;

      if (hasOneItemLimitPerCheckout) {
        return this.intl.t('buy now');
      }

      return `+ ${this.intl.t('add to cart')}`;
    }),
    showTagsRow: (0, _object.computed)('showIsAdded', 'itemStatus.{isAdded,isSoldOut,isOutOfStock,isPreOrder}', function () {
      if (this.showIsAdded && R.path(['itemStatus', 'isAdded'])(this)) {
        return true;
      }

      if (R.path(['itemStatus', 'isSoldOut'])(this) || R.path(['itemStatus', 'isOutOfStock'])(this) || R.path(['itemStatus', 'isPreOrder'])(this)) {
        return true;
      }

      return false;
    }),
    isDisabled: (0, _object.computed)('automation.isAllowed', 'disabled', 'itemStatus.cartItem.qty', 'item._data.personLimitedQty', 'shop.cartItems.[]', function () {
      var _this$item, _this$item$_data, _this$shop2, _this$item2, _this$item2$_data, _this$item3, _this$item3$_data, _this$item4, _this$item4$_data;

      const disabled = R.pathOr(false, ['disabled'])(this);

      if (disabled) {
        return true;
      }

      const personLimitedQty = (this === null || this === void 0 ? void 0 : (_this$item = this.item) === null || _this$item === void 0 ? void 0 : (_this$item$_data = _this$item._data) === null || _this$item$_data === void 0 ? void 0 : _this$item$_data.personLimitedQty) || 0;
      const cartItems = (this === null || this === void 0 ? void 0 : (_this$shop2 = this.shop) === null || _this$shop2 === void 0 ? void 0 : _this$shop2.cartItems) || [];
      const itemKey = (this === null || this === void 0 ? void 0 : (_this$item2 = this.item) === null || _this$item2 === void 0 ? void 0 : (_this$item2$_data = _this$item2._data) === null || _this$item2$_data === void 0 ? void 0 : _this$item2$_data.itemKey) || '';
      const isAutomation = (this === null || this === void 0 ? void 0 : (_this$item3 = this.item) === null || _this$item3 === void 0 ? void 0 : (_this$item3$_data = _this$item3._data) === null || _this$item3$_data === void 0 ? void 0 : _this$item3$_data.isAutomation) || false;

      if (!itemKey) {
        return true;
      }

      let addedCartItemQtys;

      if (this !== null && this !== void 0 && (_this$item4 = this.item) !== null && _this$item4 !== void 0 && (_this$item4$_data = _this$item4._data) !== null && _this$item4$_data !== void 0 && _this$item4$_data.hasVariants) {
        addedCartItemQtys = R.pipe(R.filter(cartItem => {
          var _cartItem$parent, _cartItem$parent2;

          const cartItemKey = cartItem === null || cartItem === void 0 ? void 0 : (_cartItem$parent = cartItem.parent) === null || _cartItem$parent === void 0 ? void 0 : _cartItem$parent.itemKey;
          const cartItemIsAutomation = (cartItem === null || cartItem === void 0 ? void 0 : (_cartItem$parent2 = cartItem.parent) === null || _cartItem$parent2 === void 0 ? void 0 : _cartItem$parent2.isAutomation) || false;

          if (cartItemKey === itemKey && cartItemIsAutomation === isAutomation) {
            return true;
          }

          return false;
        }), R.pluck('qty'), R.sum)(cartItems);
      } else {
        addedCartItemQtys = R.pipe(R.filter(cartItem => {
          const cartItemKey = cartItem === null || cartItem === void 0 ? void 0 : cartItem.itemKey;
          const cartItemIsAutomation = (cartItem === null || cartItem === void 0 ? void 0 : cartItem.isAutomation) || false;

          if (cartItemKey === itemKey && cartItemIsAutomation === isAutomation) {
            return true;
          }

          return false;
        }), R.pluck('qty'), R.sum)(cartItems);
      }

      if (parseInt(personLimitedQty) > 0) {
        if (parseInt(addedCartItemQtys || 0) >= parseInt(personLimitedQty)) {
          return true;
        }
      }

      const automation = R.pathOr({}, ['automation'])(this);

      if (!_nventor.default.isNilOrEmpty(automation)) {
        // default to isAllowed = true (temp solution for when backend doesnt have isAllowed)
        const isAllowed = R.pathOr(true, ['automation', 'isAllowed'])(this);

        if (isAllowed === true) {
          return false;
        }

        if (isAllowed === false) {
          return true;
        }
      }

      return false;
    }),
    passwordBtnLabel: (0, _object.computed)('item._data.passwordBtnLabel', function () {
      var _this$item5, _this$item5$_data;

      const passwordBtnLabel = (this === null || this === void 0 ? void 0 : (_this$item5 = this.item) === null || _this$item5 === void 0 ? void 0 : (_this$item5$_data = _this$item5._data) === null || _this$item5$_data === void 0 ? void 0 : _this$item5$_data.passwordBtnLabel) || '';

      if (passwordBtnLabel) {
        return passwordBtnLabel;
      }

      return this.intl.t('unlock');
    }),
    itemNameUrl: (0, _object.computed)('item.{urlProductName,_data.urlProductName,_data.item}', function () {
      var _item$_data, _item$_data2;

      const item = (this === null || this === void 0 ? void 0 : this.item) || {};
      const urlProductName = (item === null || item === void 0 ? void 0 : item.urlProductName) || (item === null || item === void 0 ? void 0 : (_item$_data = item._data) === null || _item$_data === void 0 ? void 0 : _item$_data.urlProductName) || (item === null || item === void 0 ? void 0 : (_item$_data2 = item._data) === null || _item$_data2 === void 0 ? void 0 : _item$_data2.item) || '';
      return this.helpers.beautifyUrl(urlProductName);
    }),
    allowIsAdding: (0, _object.computed)('item._data.hasVariants', 'itemStatus', function () {
      var _this$item6, _this$item6$_data;

      const itemStatus = this === null || this === void 0 ? void 0 : this.itemStatus;
      const hasVariants = this === null || this === void 0 ? void 0 : (_this$item6 = this.item) === null || _this$item6 === void 0 ? void 0 : (_this$item6$_data = _this$item6._data) === null || _this$item6$_data === void 0 ? void 0 : _this$item6$_data.hasVariants;
      return itemStatus && !hasVariants;
    }),

    increaseCartItem({
      item,
      qty,
      isRecurring = false,
      recurringSchedule,
      onAfterAddCart
    }) {
      const shop = this.shop;
      const automation = this.automation;

      if (this.allowIsAdding) {
        this.itemStatus.set('isAdding', true);
        shop.set('isAdding', true);
      }

      shop.increaseCartItem({
        item,
        qty,
        automation,
        isRecurring,
        recurringSchedule
      });
      const addedToCart = R.pipe(R.propOr(item, '_data'), R.pick(['itemKey', 'item', 'customId']))(item);
      this.shopService.get('validateCartItemsTask').perform({
        shop,
        addedToCart,
        forceValidation: true
      });

      if (this.allowIsAdding) {
        setTimeout(() => {
          shop.set('isAdding', false);

          if (!this.isDestroyed) {
            this.itemStatus.set('isAdding', false);
          }
        }, 800);
      }

      if (onAfterAddCart) {
        onAfterAddCart(item, qty);
      }

      return true;
    },

    onClickShowPasswordModal() {
      (0, _object.set)(this, 'isPasswordError', false);
      this.set('isPasswordModalVisible', true);
    },

    onClickClosePasswordModal() {
      (0, _object.set)(this, 'isPasswordError', false);
      this.set('isPasswordModalVisible', false);
    },

    onClickUnlockItem() {
      const item = (this === null || this === void 0 ? void 0 : this.item) || {};
      const itemPassword = (this === null || this === void 0 ? void 0 : this.itemPassword) || '';
      const unlockItem = this.itemService.unlockItem({
        item,
        itemPassword
      });

      if (unlockItem !== null && unlockItem !== void 0 && unlockItem.isSuccess) {
        (0, _object.set)(this, 'isPasswordError', false);

        if (this !== null && this !== void 0 && this.isItemCardPage) {
          var _this$shop3, _item$_data3;

          const shopUrl = this === null || this === void 0 ? void 0 : (_this$shop3 = this.shop) === null || _this$shop3 === void 0 ? void 0 : _this$shop3.url;
          const itemItemKey = item === null || item === void 0 ? void 0 : (_item$_data3 = item._data) === null || _item$_data3 === void 0 ? void 0 : _item$_data3.itemKey;
          const itemNameUrl = this === null || this === void 0 ? void 0 : this.itemNameUrl;
          return this.router.transitionTo('shop.item.page', shopUrl, itemItemKey, itemNameUrl);
        }
      } else {
        (0, _object.set)(this, 'isPasswordError', true);
      }
    },

    addCartItemTask: (0, _emberConcurrency.task)(function* ({
      item,
      qty,
      onAfterAddCart,
      isRecurring = false,
      recurringSchedule = null
    }) {
      var _shop$_data;

      // Send track to FB Pixel
      const isAdded = R.path(['itemStatus', 'isAdded'])(this);
      const shop = this.shop;
      const hasOneItemLimitPerCheckout = (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.hasOneItemLimitPerCheckout) || false;

      if (hasOneItemLimitPerCheckout) {
        var _item$_data4;

        const cartItems = (shop === null || shop === void 0 ? void 0 : shop.cartItems) || [];
        const isAutomation = (item === null || item === void 0 ? void 0 : (_item$_data4 = item._data) === null || _item$_data4 === void 0 ? void 0 : _item$_data4.isAutomation) || false;

        if (R.length(cartItems) >= 1 && !isAutomation) {
          alert(this.intl.t('alert_limit of one item per checkout'));
          return;
        }
      }

      if (!isAdded) {
        var _shop$_data2;

        if (this.allowIsAdding) {
          shop.set('isAdding', true);
          this.itemStatus.set('isAdding', true);
        }

        const automation = this.automation;
        const isPreOrder = R.path(['itemStatus', 'isPreOrder'])(this) || false;
        let addedToCart = shop.addCartItem({
          item,
          qty,
          automation,
          isPreOrder,
          isRecurring,
          recurringSchedule
        });
        addedToCart = R.pick(['itemKey', 'item', 'customId'])(addedToCart); // dont want to wait for result

        this.shopService.validateCartItemsTask.perform({
          shop,
          addedToCart,
          forceValidation: true
        });

        if (this.allowIsAdding) {
          setTimeout(() => {
            shop.set('isAdding', false);

            if (!this.isDestroyed) {
              this.itemStatus.set('isAdding', false);
            }
          }, 800);
        }

        if (onAfterAddCart) {
          onAfterAddCart(item, qty);
        }

        if (this.onResetSetItem) {
          this.onResetSetItem();
        }

        if (shop !== null && shop !== void 0 && (_shop$_data2 = shop._data) !== null && _shop$_data2 !== void 0 && _shop$_data2.hasOneItemLimitPerCheckout) {
          var _this$websiteService, _this$websiteService$;

          this._logAddToCart(item);

          return this.router.transitionTo('shop.checkout.summary', (_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : (_this$websiteService$ = _this$websiteService.website) === null || _this$websiteService$ === void 0 ? void 0 : _this$websiteService$.shopUrlDisplay);
        }
      } else {
        var _shop$_data3;

        if (shop !== null && shop !== void 0 && (_shop$_data3 = shop._data) !== null && _shop$_data3 !== void 0 && _shop$_data3.hasOneItemLimitPerCheckout) {
          var _this$websiteService2, _this$websiteService3;

          return this.router.transitionTo('shop.checkout.summary', (_this$websiteService2 = this.websiteService) === null || _this$websiteService2 === void 0 ? void 0 : (_this$websiteService3 = _this$websiteService2.website) === null || _this$websiteService3 === void 0 ? void 0 : _this$websiteService3.shopUrlDisplay);
        }

        this.increaseCartItem({
          item,
          qty,
          isRecurring,
          recurringSchedule,
          onAfterAddCart
        });
      }

      this._logAddToCart(item);

      this.websiteService.addMessageChip({
        message: 'added to cart',
        translate: true,
        autoClose: true,
        autoCloseTime: 2000
      });
      return true;
    }),

    _logAddToCart(item) {
      try {
        this.googleService.addToCart(item === null || item === void 0 ? void 0 : item._data);
        this.websiteService.fbPixelTrack('addToCart', item.serialize());
      } catch (error) {
        console.error(error);
      }
    },

    actions: {
      updateCartItemQty(group, cartItem, qty) {
        this.shop.updateCartItemQty({
          group,
          cartItem,
          qty
        });
        this.shopService.get('validateCartItemsTask').perform({
          shop: this.shop,
          forceValidation: true
        });
      },

      removeCartItem(item) {
        if (this.automation) {
          this.shop.removeMayAddItem(this.automation, item);
        } else {
          this.shop.removeCartItem('cartItems', item);
        }

        this.shopService.get('validateCartItemsTask').perform({
          shop: this.shop,
          forceValidation: true
        });
        this.googleService.removeFromCart(item === null || item === void 0 ? void 0 : item._data);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "increaseCartItem", [_object.action], Object.getOwnPropertyDescriptor(_obj, "increaseCartItem"), _obj), _applyDecoratedDescriptor(_obj, "onClickShowPasswordModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onClickShowPasswordModal"), _obj), _applyDecoratedDescriptor(_obj, "onClickClosePasswordModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onClickClosePasswordModal"), _obj), _applyDecoratedDescriptor(_obj, "onClickUnlockItem", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onClickUnlockItem"), _obj), _applyDecoratedDescriptor(_obj, "_logAddToCart", [_object.action], Object.getOwnPropertyDescriptor(_obj, "_logAddToCart"), _obj)), _obj));

  _exports.default = _default;
});
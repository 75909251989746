define("shop/pods/components/members/rewards-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4TvxrA1m",
    "block": "[[[8,[39,0],null,[[\"@website\",\"@params\",\"@rewardsList\"],[[30,1,[\"website\"]],[30,1,[\"params\"]],[30,1,[\"rewardsList\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@initialFiltersIsVisible\",\"@model\",\"@onUpdateFilter\",\"@pageComponent\",\"@updateUrlParams\",\"@hasTagsFilters\",\"@hasCategoriesFilters\",\"@hasAdvancedFilters\"],[true,[30,1],[28,[37,3],[[30,2,[\"fetchTask\"]]],null],[30,2],false,[30,0,[\"websiteService\",\"allowMultipleCampaigns\"]],false,false]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@isRunning\",\"@showWhenRunning\"],[[30,2,[\"fetchTask\",\"isRunning\"]],false]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@pageComponent\",\"@filtersComponent\",\"@website\"],[[30,2],[30,3],[30,1,[\"website\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@model\",\"pageComponent\",\"filtersComponent\"],false,[\"members/rewards-history-content\",\"layouts/page-content\",\"filters\",\"perform\",\"helpers/is-running\",\"members/rewards-history-group\"]]",
    "moduleName": "shop/pods/components/members/rewards-history/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
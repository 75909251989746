define("shop/pods/components/elements/element-select-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SLHIzBuw",
    "block": "[[[10,0],[14,0,\"control\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"select-btn-group \",[30,1]]]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"\\t\\t\\t\"],[8,[39,2],[[16,\"onclick\",[28,[37,3],[[30,0,[\"onSelectMethod\"]],[30,3,[\"value\"]]],null]]],[[\"@classNames\",\"@isSelected\",\"@icon\",\"@iconClassNames\",\"@label\"],[[29,[\"select-btn \",[30,4]]],[52,[28,[37,5],[[30,5],[30,3,[\"value\"]]],null],true,false],[52,[28,[37,5],[[30,5],[30,3,[\"value\"]]],null],\"fas fa-check-circle\",\"far fa-circle\"],\"icon-display-left\",[30,3,[\"label\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@selectBtnGroupClassNames\",\"@options\",\"opt\",\"@selectBtnClassNames\",\"@value\"],false,[\"each\",\"-track-array\",\"elements/element-btn\",\"fn\",\"if\",\"eq\"]]",
    "moduleName": "shop/pods/components/elements/element-select-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/lazy-loader/component", ["exports", "@ember/component", "@ember/service", "ember-concurrency"], function (_exports, _component, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    tagName: '',
    onAutoLazyLoadTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(100);
      yield this.onLazyLoadTask();
      this.set('isDone', true);
      yield (0, _emberConcurrency.timeout)(500);
      this.set('isDone', false);
    })
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/go-to-top/component", ["exports", "ramda", "@ember/component", "shop/mixins/scroll", "jquery", "shop/utils/nventor", "@ember/object", "@ember/service"], function (_exports, R, _component, _scroll, _jquery, _nventor, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_scroll.default, (_obj = {
    websiteService: (0, _service.inject)('website'),
    scrollService: (0, _service.inject)('scroll'),
    tagName: 'div',
    isViewable: false,
    isGoToProductsBtnViewable: false,

    didInsertElement() {
      this._super(...arguments);

      document.addEventListener('scroll', this.displayScrollToTopButton);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.isDestroyed) {
        return;
      }

      document.removeEventListener('scroll', this.displayScrollToTopButton);
    },

    displayScrollToTopButton(e) {
      const scrollTop = (0, _jquery.default)(window).scrollTop();
      this.scrollService.handleChatBtnPositioning({
        isModal: false
      });
      let isViewable = true;

      if (scrollTop <= 0) {
        isViewable = false;
      }

      (0, _object.set)(this, 'isViewable', isViewable);
    },

    goToTop() {
      _nventor.default.goToTop();
    },

    goToProduct() {
      _nventor.default.goToClassName('store-items__container', -50);
    }

  }, (_applyDecoratedDescriptor(_obj, "displayScrollToTopButton", [_object.action], Object.getOwnPropertyDescriptor(_obj, "displayScrollToTopButton"), _obj), _applyDecoratedDescriptor(_obj, "goToTop", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToTop"), _obj), _applyDecoratedDescriptor(_obj, "goToProduct", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToProduct"), _obj)), _obj));

  _exports.default = _default;
});
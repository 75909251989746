define("shop/pods/components/helpers/new-browser-tab-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sD8Vhn4B",
    "block": "[[[10,3],[15,6,[29,[[36,0]]]],[14,\"target\",\"_blank\"],[15,0,[29,[\"link \",[36,1]]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[34,0]],[1,\"\\n\"]],[]]],[13]],[\"&default\"],false,[\"link\",\"linkClassNames\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "shop/pods/components/helpers/new-browser-tab-link/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
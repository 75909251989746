define("shop/pods/components/items/item-variants/variants-selectors/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "ramda"], function (_exports, _component, _object, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    tagName: '',
    classNames: [],
    selectedImage: null,
    variantAdded: false,
    variantsProxy: (0, _object.computed)('item.hasSelectedVariantChanged', 'item._data.variants.@each.selectedValue', 'variants.@each.selectedValue', function () {
      if (!R.isEmpty(this.get('item._data.variants'))) {
        return this.get('item._data.variants') || [];
      }

      if (!R.isEmpty(this.variants)) {
        return this.variants;
      }

      return [];
    }),
    isFinalSelection: (0, _object.computed)('item.hasSelectedVariantChanged', 'variantsProxy.[]', function () {
      const variants = this.variantsProxy || [];
      const selectedVariants = R.filter(variant => {
        return variant.get('selectedValue');
      })(variants);

      if (variants.length === 1) {
        return true;
      }

      if (variants.length - 1 === selectedVariants.length) {
        return true;
      }

      if (variants.length === selectedVariants.length) {
        return true;
      }

      return false;
    }),
    overSalesLimitedVariantChildren: (0, _object.computed)('item._data.variantChildren.[]', 'shop.cartItems.@each.{isVariantChildOf,qty,calculatedStatusToDisplayForAddToCart}', function () {
      const item = this.item;
      return R.pipe(R.pathOr([], ['_data', 'variantChildren']), R.filter(variantChild => {
        const variantChildInsideCartItems = R.pipe(R.pathOr([], ['shop', 'cartItems']), R.find(R.propEq('isVariantChildOf', variantChild.isVariantChildOf) && R.propEq('itemKey', variantChild.itemKey)))(this);

        if (variantChildInsideCartItems) {
          // use calculatedStatusToDisplayForAddToCart from cartItem when have it instead of from the pages 
          variantChild.set('calculatedStatusToDisplayForAddToCart', variantChildInsideCartItems.calculatedStatusToDisplayForAddToCart);
        }

        if (variantChild.calculatedStatusToDisplayForAddToCart === 'preOrder') {
          return true;
        }

        if (variantChild.calculatedStatusToDisplayForAddToCart === 'soldOut') {
          return true;
        }

        if (variantChild.isUnlimited) {
          return false;
        }

        return false;
      }))(item);
    }),

    select(variant, selected) {
      let selectedValue = R.prop('selectedValue')(variant);

      if (selectedValue === selected || selected === '') {
        selectedValue = '';
        variant.set('selectedValue', selectedValue);
      } else {
        variant.set('selectedValue', selected);
      }

      this.item.set('hasSelectedVariantChanged', Math.random());
      const onSelect = this.onSelect;

      if (onSelect) {
        onSelect(variant, selected);
      }

      if (this.onSelectVariant && this.item.selectedVariant) {
        this.onSelectVariant(this.item.selectedVariant);
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "select", [_object.action], Object.getOwnPropertyDescriptor(_obj, "select"), _obj)), _obj));

  _exports.default = _default;
});
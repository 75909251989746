define("shop/pods/filters/service", ["exports", "shop/utils/nventor", "ramda", "@ember/object", "@ember/service", "shop/pods/filters/model"], function (_exports, _nventor, R, _object, _service, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FiltersService = (_dec = (0, _service.inject)('website'), (_class = class FiltersService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, 'router', _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);
    }

    setupFilters(defaults, attrs) {
      return new _model.default(defaults, attrs);
    }

    buildQueryString(params) {
      return R.pipe(R.mapObjIndexed((value, key) => {
        if (_nventor.default.isNilOrEmpty(value)) {
          return '';
        }

        if (R.is(Array)(value)) {
          const arrayString = R.pipe(R.map(part => {
            return `"${part}"`;
          }), R.join(','))(value);
          return `${key}=` + encodeURIComponent(`[${arrayString}]`);
        }

        return key + '=' + encodeURIComponent(value);
      }), R.values, R.reject(R.isEmpty), R.join('&'))(params);
    }

    updateFilter({
      filterModel = {},
      onUpdateFilter,
      updateUrlParams = true,
      isPaginate = false,
      isSearch = false,
      isShopTagsFromCategoryPage = false
    }, hasSortByChanged) {
      let paramsData;

      if (!isPaginate) {
        const resetParamsData = this.goBackToFirstPage(filterModel, hasSortByChanged, updateUrlParams);
        paramsData = resetParamsData.newParamsData;
      } else {
        paramsData = filterModel.getAsParams();
      } // update filter model
      // filterModel.update(paramsData)
      // if (isSearch) {
      // filterModel.setData('prices', null)
      // return this.router.transitionTo(
      //   'shop',
      //   {
      //     queryParams: {
      //       query: paramsData.query || '',
      //       tags: null,
      //       prices: null
      //     }
      //   }
      // )
      // }


      if (updateUrlParams) {
        const currentUrl = R.pipe(R.split('?'), R.head)(window.location.href);

        if (isShopTagsFromCategoryPage) {
          // paramsData = R.mapObjIndexed((value, key) => {
          //   if (R.is(Array, value)) {
          //     const arrayString = R.pipe(
          //       R.map(part => {
          //         return `"${part}"`
          //       }),
          //       R.join(',')
          //     )(value)
          //     return encodeURIComponent(`[${arrayString}]`)
          //     // return R.map(val => {
          //     //   return encodeURIComponent(val)
          //     // })(value)
          //   }
          //   return encodeURIComponent(value)
          // })(paramsData)
          this.router.transitionTo('shop', {
            queryParams: paramsData
          }); // const newUrl = `${currentUrl}?${this.buildQueryString(paramsData)}`
          // window.location.href = newUrl

          return;
        }

        const newUrl = `${currentUrl}?${this.buildQueryString(paramsData)}`;
        window.history.replaceState(paramsData, null, newUrl);
      }

      if (onUpdateFilter.perform) {
        return onUpdateFilter.perform(paramsData);
      }

      return onUpdateFilter(paramsData);
    }

    goBackToFirstPage(filterModel, hasSortByChanged, updateUrlParams) {
      const paramsData = filterModel.getAsParams();
      let transition = false;

      if (paramsData.page !== 1) {
        if (paramsData.query !== '' || paramsData.tags.length !== 0 || paramsData.prices.length !== 0 || hasSortByChanged) {
          paramsData.page = 1;

          if (updateUrlParams) {
            transition = true;
          }
        }
      }

      return {
        newParamsData: paramsData,
        transition
      };
    }

    updateSortBy({
      filterModel = {},
      updateUrlParams,
      onUpdateFilter
    }, selected) {
      let sortBy = filterModel._data.sortBy;
      const beforeSelected = R.find(R.propEq('isSelected', true))(sortBy);
      sortBy = R.mapObjIndexed(sortItem => {
        sortItem.isSelected = R.equals(sortItem.label)(selected);
        return sortItem;
      })(sortBy);
      const hasSortByChanged = !R.equals(beforeSelected.label)(selected);
      this.updateFilter({
        filterModel,
        updateUrlParams,
        onUpdateFilter
      }, hasSortByChanged);
    }

    resetFilters({
      filterModel,
      updateUrlParams,
      onUpdateFilter,
      isShopOrCategoryPage = false
    }) {
      filterModel.resetParams({
        isShopOrCategoryPage
      });
      this.updateFilter({
        filterModel,
        updateUrlParams,
        onUpdateFilter
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, 'router', [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buildQueryString", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "buildQueryString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goBackToFirstPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goBackToFirstPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSortBy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSortBy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetFilters"), _class.prototype)), _class));
  _exports.default = FiltersService;
});
define("shop/pods/members/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RHnobhhU",
    "block": "[[[8,[39,0],null,[[\"@isIndex\",\"@website\",\"@params\",\"@query\"],[true,[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"params\"]],[30,0,[\"model\",\"params\",\"query\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"model\",\"website\",\"isLoggedIn\"]],[[[1,\"    \"],[8,[39,2],[[24,0,\"tabs-on-top-container\"]],[[\"@isActiveUsingRoute\",\"@tabs\",\"@setTabActive\"],[true,[30,0,[\"model\",\"profileMenuLists\",\"filterModel\",\"_data\",\"tags\"]],[28,[37,3],[[30,1,[\"goToTabLink\"]]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n    \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\\n\"]],[\"websiteContainer\"],false,[\"website/website-container\",\"if\",\"tabs/headers\",\"fn\",\"component\",\"-outlet\"]]",
    "moduleName": "shop/pods/members/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
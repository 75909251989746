define("shop/pods/components/shop/container/component", ["exports", "@glimmer/component", "@ember/service", "ramda"], function (_exports, _component, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShopContainerComponent = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('shop'), (_class = class ShopContainerComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);

      _initializerDefineProperty(this, "shopService", _descriptor2, this);
    }

    get displayProductsGrid() {
      const page = this.args.page;

      if ((page === null || page === void 0 ? void 0 : page.type) === 'category') {
        if (page !== null && page !== void 0 && page.hasTemplate) {
          return false;
        }

        return true;
      }

      const shop = this.args.shop;
      const displayProductsInShop = R.pathOr(true, ['_data', 'advancedTemplate', 'displayProductsInShop'])(shop);
      return displayProductsInShop || false;
    }

    get displayFiltersWithTemplate() {
      const page = this.args.page;

      if ((page === null || page === void 0 ? void 0 : page.type) === 'category' && page !== null && page !== void 0 && page.hasTemplate) {
        return true;
      }

      return false;
    }

    get isShopOrCategoryPage() {
      var _page$_data;

      const page = this.args.page; // shop also need to show categories filters
      // websitesPages for shop always has name === 'shop'

      if ((page === null || page === void 0 ? void 0 : (_page$_data = page._data) === null || _page$_data === void 0 ? void 0 : _page$_data.name) === 'shop') {
        return true;
      }

      return this.isCategoryPage;
    }

    get isCategoryPage() {
      const page = this.args.page;
      const type = page === null || page === void 0 ? void 0 : page.type;

      if (type === 'category') {
        return true;
      }

      return false;
    }

    get hasCategoriesFilters() {
      var _this$websiteService, _website$_data, _this$args, _this$args$model;

      const website = (_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : _this$websiteService.website;

      if (!this.isShopOrCategoryPage || website !== null && website !== void 0 && (_website$_data = website._data) !== null && _website$_data !== void 0 && _website$_data.allowAllCampaigns) {
        return false;
      }

      const shop = this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$model = _this$args.model) === null || _this$args$model === void 0 ? void 0 : _this$args$model.shop;
      return shop === null || shop === void 0 ? void 0 : shop.hasCategoriesFilters;
    }

    get hasTagsFilters() {
      var _this$args2, _this$args2$model;

      if (!this.isShopOrCategoryPage) {
        return false;
      }

      const shop = this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$model = _this$args2.model) === null || _this$args2$model === void 0 ? void 0 : _this$args2$model.shop;
      return shop === null || shop === void 0 ? void 0 : shop.hasTagsFilters;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ShopContainerComponent;
});
define("shop/pods/components/cart/qty-selector-set-choice-variant/qty-selector-variant/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9moPqEUq",
    "block": "[[[41,[33,1],[[[1,\"  \"],[8,[39,2],null,[[\"@disabled\",\"@allowRemove\",\"@showSelectBtn\",\"@showButtons\",\"@item\",\"@variantChoice\",\"@qty\",\"@minQty\",\"@maxQty\",\"@onChangeQty\",\"@onZeroQty\"],[[30,0,[\"disableSelectQty\"]],true,false,true,[30,0,[\"item\"]],[30,0,[\"variantChoice\"]],[30,0,[\"qty\"]],[30,0,[\"minQty\"]],[30,0,[\"variantChoice\",\"maxQty\"]],[30,0,[\"onChangeQty\"]],[30,0,[\"onZeroQty\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showQtySelectorVariant\",\"cart/qty-selector\"]]",
    "moduleName": "shop/pods/components/cart/qty-selector-set-choice-variant/qty-selector-variant/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/helpers/is-running/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k5Hse8Zy",
    "block": "[[[41,[48,[30,2]],[[[1,\"\\n\"],[41,[33,2],[[[41,[33,3],[[[1,\"      \"],[10,0],[14,0,\"loading-btn__container has-text-centered\"],[12],[1,\"\\n        \"],[10,3],[14,0,\"button button--no-bg button--no-border is-loading\"],[12],[1,[28,[35,4],[\"loading\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[41,[33,3],[[[41,[33,6],[[[1,\"        \"],[10,3],[14,0,\"button button--no-bg button--no-border is-loading\"],[12],[1,[28,[35,4],[\"loading\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"loading-btn__container has-text-centered\"],[12],[1,\"\\n          \"],[10,3],[14,0,\"button button--no-bg button--no-border is-loading\"],[12],[1,[28,[35,4],[\"loading\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]]]],[]]]],[]],[[[41,[30,1],[[[41,[33,3],[[[1,\"    \"],[10,3],[14,0,\"button button--no-bg button--no-border is-loading\"],[12],[1,[28,[35,4],[\"loading\"],null]],[13],[1,\"\\n\"]],[]],null]],[]],[[[41,[33,3],[[[1,\"    \"],[10,0],[14,0,\"loading-btn__container has-text-centered\"],[12],[1,\"\\n      \"],[10,3],[14,0,\"button button--no-bg button--no-border is-loading\"],[12],[1,[28,[35,4],[\"loading\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[\"@isJustSpinner\",\"&default\"],false,[\"if\",\"has-block\",\"showWhenRunning\",\"isRunning\",\"tt\",\"yield\",\"inPlace\"]]",
    "moduleName": "shop/pods/components/helpers/is-running/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/elements/convenience-stores/pickup-location/component", ["exports", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "ember-concurrency-decorators", "ramda", "shop/utils/nventor"], function (_exports, _component, _service, _object, _tracking, _emberConcurrencyDecorators, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ElementsConvenienceStoresPickupLocationComponent = (_dec = (0, _service.inject)('convenience-store'), _dec2 = (0, _service.inject)('website'), _dec3 = (0, _service.inject)('shop'), _dec4 = (0, _service.inject)('storage'), (_class = class ElementsConvenienceStoresPickupLocationComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "convenienceStoreService", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _initializerDefineProperty(this, "shopService", _descriptor3, this);

      _initializerDefineProperty(this, "storageService", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "convenienceStoreId", _descriptor6, this);

      _initializerDefineProperty(this, "convenienceStoreName", _descriptor7, this);

      _initializerDefineProperty(this, "convenienceStoreAddress", _descriptor8, this);

      _initializerDefineProperty(this, "convenienceStoreOutlying", _descriptor9, this);

      this.setConvenienceStoreDataFromQueryParams();
    }

    setConvenienceStoreDataFromQueryParams() {
      const params = this.websiteService.parseParamsFromWindowLocation(window.location);

      if (_nventor.default.isNilOrEmpty(params)) {
        console.log("setConvenienceStoreDataFromQueryParams: ", {
          params
        });
      }

      this.convenienceStoreId = (params === null || params === void 0 ? void 0 : params.convenienceStoreId) || '';
      this.convenienceStoreName = (params === null || params === void 0 ? void 0 : params.convenienceStoreName) || '';
      this.convenienceStoreAddress = (params === null || params === void 0 ? void 0 : params.convenienceStoreAddress) || '';
      this.convenienceStoreRegion = (params === null || params === void 0 ? void 0 : params.convenienceStoreRegion) || '';
      this.convenienceStoreDistrict = (params === null || params === void 0 ? void 0 : params.convenienceStoreDistrict) || '';
      this.convenienceStorePostCode = (params === null || params === void 0 ? void 0 : params.convenienceStorePostCode) || '';
      this.convenienceStoreStreet = (params === null || params === void 0 ? void 0 : params.convenienceStoreStreet) || '';
      this.convenienceStoreOutlying = (params === null || params === void 0 ? void 0 : params.convenienceStoreOutlying) || false;
      const transporterAccount = (params === null || params === void 0 ? void 0 : params.transporterAccount) || '';

      if (!this.convenienceStoreId || !this.convenienceStoreName || !this.convenienceStoreAddress) {
        return;
      }

      window.history.pushState(null, null, 'details');
      const storeAddress = `(${this.convenienceStoreId}${this.convenienceStoreName})${this.convenienceStoreAddress}`;
      const convenienceStoreData = {
        region: this.convenienceStoreRegion,
        district: this.convenienceStoreDistrict,
        postCode: this.convenienceStorePostCode,
        address: this.convenienceStoreAddress,
        street: this.convenienceStoreStreet,
        convenienceStoreAddress: storeAddress,
        brand: 'sevenEleven',
        storeId: this.convenienceStoreId,
        storeName: this.convenienceStoreName,
        storeAddress: this.convenienceStoreAddress,
        country: 'Taiwan',
        countryCode: 'TW',
        hasAdvancedAddress: true,
        hasPostCode: true,
        hasAddress: true,
        type: 'convenienceStore',
        outlying: this.convenienceStoreOutlying,
        transporterAccount
      };

      for (const convenienceStoreDataKey in convenienceStoreData) {
        this.args.checkoutData.set(convenienceStoreDataKey, convenienceStoreData[convenienceStoreDataKey]);
        this.args.user.set(`_data.${convenienceStoreDataKey}`, convenienceStoreData[convenienceStoreDataKey]);
      }

      this.args.user.set('_data.convenienceStoreData', convenienceStoreData);
      this.args.user.set('_data.addressData', convenienceStoreData);
      this.args.checkoutData.set('convenienceStoreData', convenienceStoreData);
      this.args.onSetMemberAddress(this.args.user, this.args.checkoutData);
      this.shopService.validateCartItemsTask.perform({
        shop: this.args.shop,
        checkoutData: this.args.checkoutData,
        user: this.args.user,
        hasAddress: true
      });
    }

    *searchConvenienceStore() {
      var _this$args, _this$args$shop;

      const shippingFeeData = (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$shop = _this$args.shop) === null || _this$args$shop === void 0 ? void 0 : _this$args$shop.shippingFeeData;
      const shippingTransporters = R.pipe(R.propOr([], 'combinedZones'), R.map(R.propOr([], 'shippingTransporter')))(shippingFeeData);

      if (this.args.user) {
        const userData = this.args.user.serialize();
        this.args.user.persistUserDataToStorage(userData);
      }

      yield this.convenienceStoreService.selectPickupLocation.perform({
        shippingTransporters
      });
    }

    get checkoutConvenienceStoreDescription() {
      var _this$args2, _this$args2$shop, _this$args2$shop$_dat;

      const convenienceStoreDescription = (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$shop = _this$args2.shop) === null || _this$args2$shop === void 0 ? void 0 : (_this$args2$shop$_dat = _this$args2$shop._data) === null || _this$args2$shop$_dat === void 0 ? void 0 : _this$args2$shop$_dat.checkoutConvenienceStoreDescription;

      if (convenienceStoreDescription) {
        return convenienceStoreDescription;
      }

      return this.intl.t('use id name when choosing convenience store pick up');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "convenienceStoreService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "storageService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "convenienceStoreId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "convenienceStoreName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "convenienceStoreAddress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "convenienceStoreOutlying", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setConvenienceStoreDataFromQueryParams", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setConvenienceStoreDataFromQueryParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchConvenienceStore", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchConvenienceStore"), _class.prototype)), _class));
  _exports.default = ElementsConvenienceStoresPickupLocationComponent;
});
define("shop/pods/tap-pay/service", ["exports", "@ember/service", "@ember/object", "shop/config/environment", "ember-concurrency-decorators", "@glimmer/tracking", "ramda", "shop/utils/nventor"], function (_exports, _service, _object, _environment, _emberConcurrencyDecorators, _tracking, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TapPayService = (_dec = (0, _service.inject)('website'), (_class = class TapPayService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);

      _initializerDefineProperty(this, 'intl', _descriptor2, this);

      _initializerDefineProperty(this, "isSettingUp", _descriptor3, this);

      _initializerDefineProperty(this, "wasAlreadyLoaded", _descriptor4, this);

      _initializerDefineProperty(this, "tapPayNumberStatus", _descriptor5, this);

      _initializerDefineProperty(this, "tapPayExpiryStatus", _descriptor6, this);

      _initializerDefineProperty(this, "tapPayCCVStatus", _descriptor7, this);

      _initializerDefineProperty(this, "tapPayUpdateStatus", _descriptor8, this);

      _initializerDefineProperty(this, "canGetPrime", _descriptor9, this);

      _initializerDefineProperty(this, "couldNotGetPrime", _descriptor10, this);

      _initializerDefineProperty(this, "refreshRequired", _descriptor11, this);

      _initializerDefineProperty(this, "cardType", _descriptor12, this);
    }

    get hasCompleteCreditCardInfoTapPay() {
      return this.canGetPrime;
    }

    get errorMessages() {
      const errors = [];

      if (this.tapPayNumberStatus === 2) {
        errors.pushObject('error_ccNumber');
      }

      if (this.tapPayExpiryStatus === 2) {
        errors.pushObject('error_ccExpiry');
      }

      if (this.tapPayCCVStatus === 2) {
        errors.pushObject('error_ccSecureCode');
      }

      return errors;
    }

    get hasGetPrimeError() {
      const currentStatus = [this.tapPayNumberStatus, this.tapPayExpiryStatus, this.tapPayCCVStatus];

      if (R.equals([0, 0, 0])(currentStatus) && this.couldNotGetPrime) {
        // this is when all fields are correct but getPrime results in an error
        return true;
      }

      return false;
    }

    get ccCardIcon() {
      switch (this.cardType) {
        case 'mastercard':
          return 'fab fa-cc-mastercard';

        case 'visa':
          return 'fab fa-cc-visa';

        case 'jcb':
          return 'fab fa-cc-jcb';

        case 'amex':
          return 'fab fa-cc-amex';

        case 'unknown':
        default:
          return 'far fa-credit-card';
      }
    }

    *setupTapPayFields() {
      return yield new Promise(resolve => {
        const loadedIframes = []; // 以下提供必填 CCV 以及選填 CCV 的 Example
        // 必填 CCV Example

        const domElementCardNumber = document.querySelector('#card-number');
        const domElementCardExpirationDate = document.querySelector('#card-expiration-date');
        const domElementCardCcv = document.querySelector('#card-ccv');
        const fields = {
          number: {
            // css selector
            element: domElementCardNumber,
            placeholder: '**** **** **** ****'
          },
          expirationDate: {
            // DOM object
            element: domElementCardExpirationDate,
            placeholder: 'MM / YY'
          },
          ccv: {
            element: domElementCardCcv,
            placeholder: '⦁⦁⦁' || '後三碼'
          }
        };
        const styles = {
          // Style all elements
          'input': {
            'color': 'gray',
            'font-size': '16px',
            'font-family': `BlinkMacSystemFont,
            -apple-system, "Segoe UI", "Roboto",
            "Oxygen", "Ubuntu", "Cantarell",
            "Fira Sans", "Droid Sans",
            "Helvetica Neue", "Helvetica", "Arial",
            sans-serif`
          },
          // Styling ccv field
          'input.cvc': {// 'font-size': '16px'
          },
          // Styling expiration-date field
          'input.expiration-date': {// 'font-size': '16px'
          },
          // Styling card-number field
          'input.card-number': {// 'font-size': '16px'
          },
          // style focus state
          ':focus': {// 'color': 'black'
          },
          // style valid state
          '.valid': {
            'color': 'green'
          },
          // style invalid state
          '.invalid': {
            'color': 'red'
          },
          // Media queries
          // Note that these apply to the iframe, not the root window.
          '@media screen and (max-width: 400px)': {
            'input': {
              'color': 'orange'
            }
          }
        };

        const onLoadIframe = iframe => {
          loadedIframes.push(iframe);

          if (loadedIframes.length === 3) {
            onUpdate();
            resolve(true);
          }
        }; // set fields


        TPDirect.card.setup({
          fields,
          styles
        });
        domElementCardNumber.querySelector('iframe').onload = onLoadIframe;
        domElementCardExpirationDate.querySelector('iframe').onload = onLoadIframe;
        domElementCardCcv.querySelector('iframe').onload = onLoadIframe; // Set on update event

        const onUpdate = () => {
          return TPDirect.card.onUpdate(update => {
            this.cardType = update.cardType;
            this.canGetPrime = update.canGetPrime;
            this.tapPayNumberStatus = update.status.number;
            this.tapPayExpiryStatus = update.status.expiry;
            this.tapPayCCVStatus = update.status.ccv;
            this.tapPayUpdateStatus = update;
          });
        };
      });
    }

    *loadTapPaySDK(payByLinkDocument) {
      var _website$shop;

      const website = this.websiteService.website;
      let merchantData = (website === null || website === void 0 ? void 0 : (_website$shop = website.shop) === null || _website$shop === void 0 ? void 0 : _website$shop._data) || {};

      if (payByLinkDocument) {
        merchantData = payByLinkDocument;
      }

      const tapPayConfigDataForMerchant = R.pipe(R.pathOr([], ['paymentProvidersConfigData']), R.find(R.propEq('provider', 'tapPay')))(merchantData);
      return yield new Promise(resolve => {
        const tapPayConfig = _environment.default.tapPay;
        this.websiteService.loadAsyncResource('tapPay-sdk', tapPayConfig.sdkUrl, document, 'script', {
          onload(...args) {
            // TPDirect.setupSDK(tapPayConfig.appId, tapPayConfig.appKey, tapPayConfig.env)
            TPDirect.setupSDK(tapPayConfigDataForMerchant.appId, tapPayConfigDataForMerchant.appKey, tapPayConfig.env); // console.log('tapPay loaded')

            resolve(...args);
          }

        });
      });
    }

    *delay(time) {
      return yield new Promise(resolve => setTimeout(resolve, time));
    }

    *setupSDK(useFields, payByLinkDocument) {
      const setupSDK = () => {
        this.isSettingUp = true;
        this.tapPayNumberStatus = '';
        this.tapPayExpiryStatus = '';
        this.tapPayCCVStatus = ''; // @TODO: cannot get appkey
        // const tapPayConfig = config.tapPay
        // TPDirect.setupSDK(tapPayConfig.appId, tapPayConfig.appKey, tapPayConfig.env)

        const defaultCardViewStyle = {
          color: 'rgb(0,0,0)',
          fontSize: '15px',
          lineHeight: '24px',
          fontWeight: '300',
          errorColor: 'red',
          placeholderColor: ''
        };
        const tapPayIframe = document.getElementById('tappay-iframe');

        if (!tapPayIframe) {
          this.refreshRequired = true;
          return false;
        }

        TPDirect.card.setup('#tappay-iframe', defaultCardViewStyle);
        TPDirect.card.onUpdate(update => {
          this.tapPayNumberStatus = update.status.number;
          this.tapPayExpiryStatus = update.status.expiry;
          this.tapPayCCVStatus = update.status.ccv;
          this.canGetPrime = update.canGetPrime;
          this.tapPayUpdateStatus = update;
        });
        setTimeout(() => {
          this.isSettingUp = false;
        }, 1200);
      };

      const onload = () => {
        setupSDK();
        this.wasAlreadyLoaded = true;
      };

      if (this.wasAlreadyLoaded) {
        setupSDK();
      } else {
        yield this.loadTapPaySDK.perform(payByLinkDocument);

        if (useFields) {
          yield this.setupTapPayFields.perform();
        } else {
          onload();
        }
      }
    }

    *getPrimeByTapPayFields() {
      return yield new Promise((resolve, reject) => {
        const status = TPDirect.card.getTappayFieldsStatus();
        const hasError = R.propOr(true, 'hasError')(status);

        if (hasError) {
          return this.handleCannotGetPrime.perform({
            errorBeforeGetPrime: true
          }, status).then(() => reject({
            message: 'error before getting prime'
          }));
        } else {
          TPDirect.card.getPrimeForTappayFields(result => {
            if (result.status !== 0) {
              return this.handleCannotGetPrime.perform(result, status).then(() => reject({
                message: 'can not get prime'
              }));
            }

            return resolve(result.card);
          });
        }
      });
    }

    *getPrimeTask({
      tapPayUseFields
    }) {
      if (tapPayUseFields) {
        return yield this.getPrimeByTapPayFields.perform();
      }

      this.couldNotGetPrime = false;
      return yield new Promise((resolve, reject) => {
        TPDirect.card.getPrime(result => {
          if (result.status !== 0) {
            this.handleCannotGetPrime.perform(result);
            return reject({
              message: 'can not get prime'
            });
          }

          return resolve(result.card);
        });
      });
    }

    *getLinePayPrimeTask() {
      return yield new Promise((resolve, reject) => {
        TPDirect.linePay.getPrime(result => {
          if (result.status !== 0) {
            this.handleCannotGetPrime.perform(result);
            return reject({
              message: 'can not get prime'
            });
          }

          result.primeType = 'linePay';
          return resolve(result);
        });
      });
    }

    *handleCannotGetPrime(result, status) {
      this.couldNotGetPrime = true;
      return yield this.websiteService.logErrorTask.perform({
        error: {
          message: 'TapPayGetPrimeError'
        },
        checkoutData: {},
        automations: {},
        cart: {},
        tapPay: {
          result,
          status: _nventor.default.defaultWhen(_nventor.default.isNilOrEmpty, this.tapPayUpdateStatus, status)
        }
      });
    }

    refresh() {
      window.location.reload(true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, 'intl', [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isSettingUp", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "wasAlreadyLoaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tapPayNumberStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tapPayExpiryStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tapPayCCVStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tapPayUpdateStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "canGetPrime", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "couldNotGetPrime", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "refreshRequired", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "cardType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupTapPayFields", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTapPayFields"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadTapPaySDK", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadTapPaySDK"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delay", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "delay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupSDK", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupSDK"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getPrimeByTapPayFields", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getPrimeByTapPayFields"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getPrimeTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getPrimeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getLinePayPrimeTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getLinePayPrimeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCannotGetPrime", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "handleCannotGetPrime"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refresh", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype)), _class));
  _exports.default = TapPayService;
});
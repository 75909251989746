define("shop/pods/components/layouts/filters/filters-sort/component", ["exports", "ramda", "@ember/component", "@ember/object", "shop/utils/nventor"], function (_exports, R, _component, _object, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',
    classNames: ['filters-sort__container'],
    selectedSortBy: (0, _object.computed)('shop.{shopProductsSort,_data.shopProductsSort}', 'filterModel.params.sortBy', function () {
      var _this$filterModel, _this$filterModel$par, _shop$_data;

      const filterModelSortBy = (this === null || this === void 0 ? void 0 : (_this$filterModel = this.filterModel) === null || _this$filterModel === void 0 ? void 0 : (_this$filterModel$par = _this$filterModel.params) === null || _this$filterModel$par === void 0 ? void 0 : _this$filterModel$par.sortBy) || '';

      if (_nventor.default.isNotNilOrEmpty(filterModelSortBy)) {
        return filterModelSortBy;
      }

      const shop = (this === null || this === void 0 ? void 0 : this.shop) || {};
      return (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.shopProductsSort) || (shop === null || shop === void 0 ? void 0 : shop.shopProductsSort) || 'recommended';
    })
  });

  _exports.default = _default;
});
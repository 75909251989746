define("shop/pods/components/social-login/component", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    configService: (0, _service.inject)('config'),
    allowGuest: true,
    hideBigOrdrLoginMessageFromWebsites: (0, _object.computed)('website.{shopKey,_data.hideBigOrdrLoginMessageFromWebsites,_data.hideFacebookLogin,_data.hideLineLogin,_data.hideBigOrdrLogin}', function () {
      var _this$website, _this$website$_data, _this$website2, _website$_data, _website$_data2, _website$_data3, _website$_data4;

      // @TODO: remove after 2024/12/18
      if (((_this$website = this.website) === null || _this$website === void 0 ? void 0 : (_this$website$_data = _this$website._data) === null || _this$website$_data === void 0 ? void 0 : _this$website$_data._key) === '852189325') {
        return true;
      }

      if (((_this$website2 = this.website) === null || _this$website2 === void 0 ? void 0 : _this$website2.shopKey) === '852277271') {
        return true;
      }

      const website = this.website;

      if (website !== null && website !== void 0 && (_website$_data = website._data) !== null && _website$_data !== void 0 && _website$_data.hideBigOrdrLoginMessageFromWebsites) {
        return true;
      }

      if (!(website !== null && website !== void 0 && (_website$_data2 = website._data) !== null && _website$_data2 !== void 0 && _website$_data2.hideFacebookLogin)) {
        return false;
      }

      if (!(website !== null && website !== void 0 && (_website$_data3 = website._data) !== null && _website$_data3 !== void 0 && _website$_data3.hideLineLogin)) {
        return false;
      }

      if (!(website !== null && website !== void 0 && (_website$_data4 = website._data) !== null && _website$_data4 !== void 0 && _website$_data4.hideBigOrdrLogin)) {
        return false;
      }

      return true;
    }),
    actions: {
      continueAsGuest() {
        return this.router.transitionTo('shop.checkout.details');
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/youtube/component", ["exports", "@glimmer/component", "shop/utils/nventor"], function (_exports, _component, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class YoutubeComponent extends _component.default {
    get videoId() {
      const url = this.args.url || '';

      const params = _nventor.default.parseParams(url);

      const id = (params === null || params === void 0 ? void 0 : params.v) || '';
      return id;
    }

  }

  _exports.default = YoutubeComponent;
});
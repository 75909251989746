define("shop/pods/components/items/status-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6+omqqOW",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,1],[15,0,[29,[\"tag \",[36,2]]]],[12],[1,\"\\n    \"],[1,[28,[35,3],[[33,4]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[33,5],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],null]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"hasTag\",\"classes\",\"tt\",\"status\",\"allowQtySelector\",\"yield\"]]",
    "moduleName": "shop/pods/components/items/status-tag/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/social-icons/component", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object'
  // import * as R from 'ramda'
  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    tagName: 'div',
    classNames: ['social-icons__container'] // socialItems: computed('icons.[]', 'website', function () {
    //   const urls = [{
    //     url: this.get('website.facebookUrl'),
    //     icon: 'fab fa-facebook-square'
    //   }, {
    //     url: this.get('website.instagramUrl'),
    //     icon: 'fab fa-instagram'
    //   }, {
    //     url: this.get('website.youtubeUrl'),
    //     icon: 'fab fa-youtube'
    //   }, {
    //     url: this.get('website.lineId'),
    //     icon: 'fab fa-line'
    //   }]
    //   return R.reject(R.propEq('url', false))(urls)
    // })

  });

  _exports.default = _default;
});
define("shop/pods/faqs/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model() {
      return this.transitionTo('/not-found-404');
    }

  });

  _exports.default = _default;
});
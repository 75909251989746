define("shop/pods/scroll/service", ["exports", "@ember/service", "@ember/object", "shop/utils/nventor"], function (_exports, _service, _object, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ScrollService = (_dec = (0, _service.inject)('website'), (_class = class ScrollService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);
    }

    handleChatBtnPositioning({
      isModal = false
    }) {
      var _this$websiteService;

      if (_nventor.default.isNilOrEmpty(this.websiteService)) {
        return;
      }

      let goToTopContainer = document.querySelector('.sticky-footer.quick-btns');

      if (isModal) {
        goToTopContainer = document.querySelector('.modal-card-body .sticky-footer.quick-btns');
      } // console.log(goToTopContainer)
      // console.log('asd')
      // const flowBtnContainer = document.querySelector('.flow-container')


      let chatBtnContainer = document.querySelector('.chat-btn__container');

      if (isModal) {
        chatBtnContainer = document.querySelector('.modal-card-body .chat-btn__container');
      }

      const referralBtnContainer = document.querySelector('.referral-btn__container');
      const mobileFooterContainer = document.querySelector('.mobile-action-footer');
      const isMobile = (_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : _this$websiteService.isMobile;
      const isMobileFooterVisible = mobileFooterContainer && !(mobileFooterContainer !== null && mobileFooterContainer !== void 0 && mobileFooterContainer.hidden);
      let goToTopContainerBottom = 80;

      if (isMobile) {
        goToTopContainerBottom = 70;
      }

      if (goToTopContainer && chatBtnContainer) {
        /*
          GoToTop and ChatButton should have position absolute when the parent is
          the cart-content-modal
        */
        const isCartContentModal = !!goToTopContainer.closest('.modal.cart-content-modal');

        if (isCartContentModal) {
          goToTopContainer.style.position = 'absolute';
          chatBtnContainer.style.position = 'absolute';
        }

        if (isMobileFooterVisible) {
          const footerHeight = mobileFooterContainer.offsetHeight || 0;
          chatBtnContainer.style.bottom = `${footerHeight + 20}px`;
          goToTopContainer.style.bottom = `${footerHeight + goToTopContainerBottom}px`;

          if (referralBtnContainer) {
            referralBtnContainer.style.bottom = `${footerHeight + 20}px`;
          } // flowBtnContainer.style.bottom = '180px'

        } else {
          chatBtnContainer.style.bottom = '20px';
          goToTopContainer.style.bottom = `${goToTopContainerBottom}px`;

          if (referralBtnContainer) {
            referralBtnContainer.style.bottom = '20px';
          }
        }
      }
    }

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "scrollToTop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToTop"), _class.prototype)), _class));
  _exports.default = ScrollService;
});
define("shop/pods/shop/checkout/payment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ApK1B0KD",
    "block": "[[[1,[28,[35,0],null,[[\"shop\",\"creditCard\",\"user\",\"website\"],[[33,1,[\"shop\"]],[33,1,[\"creditCard\"]],[33,1,[\"website\",\"user\"]],[33,1,[\"website\"]]]]]],[1,\"\\n\"]],[],false,[\"shop/checkout/payment-page\",\"model\"]]",
    "moduleName": "shop/pods/shop/checkout/payment/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
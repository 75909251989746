define("shop/helpers/sum", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function sum([number, increment]) {
    return number + increment;
  });

  _exports.default = _default;
});
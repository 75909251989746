define("shop/pods/components/automations/may-add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0U77O6kk",
    "block": "[[[41,[28,[37,1],[[33,2,[\"showMayAddEverywhere\"]],[30,0,[\"isSummaryPage\"]]],null],[[[1,\"  \"],[10,0],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1]],null]],null],null,[[[41,[28,[37,1],[[30,2,[\"showMayAddEverywhere\"]],[30,0,[\"isSummaryPage\"]]],null],[[[1,\"        \"],[10,0],[14,0,\"checkout-form\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"automations__container\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"has-text-weight-bold has-text-danger has-text-centered\"],[12],[1,\"\\n              \"],[8,[39,5],null,[[\"@icon\"],[\"fas fa-tag\"]],null],[1,\"\\n\\n              \"],[1,[52,[30,2,[\"_data\",\"title\"]],[30,2,[\"_data\",\"title\"]],[28,[37,6],[[30,0,[\"title\"]]],null]]],[1,\"\\n            \"],[13],[1,\"\\n            \\n            \"],[10,0],[14,0,\"store-items__container\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"store-items__products-container is-automations\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,2,[\"_data\",\"details\"]]],null]],null],null,[[[41,[28,[37,7],[[30,0,[\"item\",\"_data\",\"itemKey\"]],[30,3,[\"_data\",\"itemKey\"]]],null],[[[1,\"                    \"],[8,[39,8],null,[[\"@website\",\"@shop\",\"@isRunning\",\"@item\",\"@automation\",\"@onAfterAddCart\",\"@hasGoToTop\",\"@itemKey\"],[[30,4],[30,5],[30,6],[30,3],[30,2],[30,7],[30,8],[30,3,[\"_data\",\"itemKey\"]]]],null],[1,\"\\n\"]],[]],null]],[3]],null],[1,\"              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@automations\",\"automation\",\"automationDetail\",\"@website\",\"@shop\",\"@isRunning\",\"@onAfterAddCart\",\"@hasGoToTop\"],false,[\"if\",\"or\",\"automation\",\"each\",\"-track-array\",\"elements/element-icon\",\"tt\",\"not-eq\",\"items/item-card\"]]",
    "moduleName": "shop/pods/components/automations/may-add/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/mixins/translate", ["exports", "@ember/object/mixin", "@ember/object", "@ember/service"], function (_exports, _mixin, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    intl: (0, _service.inject)(),
    translatePlaceholder: true,
    translatedPlaceholder: (0, _object.computed)('placeholder', 'translatePlaceholder', function () {
      const placeholder = this.placeholder;

      if (placeholder == null) {
        return '';
      }

      if (!this.translatePlaceholder) {
        return placeholder;
      }

      return this.intl.t(placeholder);
    }),

    shouldTranslateLabel(value, itemTranslate) {
      if (this.translate) {
        if (itemTranslate === true) {
          return true;
        }

        if (itemTranslate === '' || itemTranslate == null) {
          return true;
        }

        return itemTranslate;
      }

      if (itemTranslate === '' || itemTranslate == null) {
        return false;
      }

      return itemTranslate;
    }

  });

  _exports.default = _default;
});
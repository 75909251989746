define("shop/pods/components/members/profile-page/component", ["exports", "ramda", "@ember/component", "@ember/service", "ember-concurrency", "@ember/object", "shop/utils/nventor", "shop/mixins/date"], function (_exports, R, _component, _service, _emberConcurrency, _object, _nventor, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_date.default, (_obj = {
    router: (0, _service.inject)(),
    membersService: (0, _service.inject)('members'),
    websiteService: (0, _service.inject)('website'),
    disabled: false,
    completeLater: false,
    forceVerification: false,
    decodedMember: null,
    isEditing: false,
    agreeToTerms: true,
    hasAddress: true,
    isModalVisible: false,
    taxNumberError: false,

    init() {
      this._super(...arguments);

      if (this.requireVerifyDetails) {
        const model = this.model;
        model.set('_data.name', '');
      }
    },

    async didInsertElement() {
      this._super(...arguments);

      const model = this.model;

      if (this.isEditing) {
        await this.websiteService.get('fetchAllCountriesTask').perform(model);
      }

      const website = this.website;
      const countries = this.allowedCountries;
      model.setDefaultCountry(website, countries);
      const member = this.get('website.user');

      if (member.get('_data.taxNumber')) {
        // this.validateTaxNumberTask.perform(member.get('_data.taxNumber'))
        return member.set('_data.twEInvoiceCarrierType', 'b2b');
      }

      (0, _object.set)(this, 'emailForPromoCode', this.storage.getValue('emailForPromoCode') || '');
    },

    showLoginBtn: (0, _object.computed)('website.showLoginBtn', 'model.isMember', function () {
      var _this$model, _this$website;

      if ((_this$model = this.model) !== null && _this$model !== void 0 && _this$model.isMember) {
        return false;
      }

      return (_this$website = this.website) === null || _this$website === void 0 ? void 0 : _this$website.showLoginBtn;
    }),
    memberLevelData: (0, _object.computed)('model._data.memberLevelId', 'shop._data.rewardData', function () {
      const memberLevelId = this.get('model._data.memberLevelId') || '';
      const rewardData = this.get('shop._data.rewardData') || {};
      const memberData = R.pipe(R.propOr([], 'memberLevels'), R.filter(R.propEq('memberLevelId', memberLevelId)))(rewardData);
      return R.head(memberData) || {};
    }),
    memberLevelDescription: (0, _object.computed)('model._data.memberLevelId', 'shop._data.rewardData', function () {
      const memberLevelId = this.get('model._data.memberLevelId') || '';
      const rewardData = this.get('shop._data.rewardData') || {};
      const memberData = R.pipe(R.propOr([], 'memberLevels'), R.filter(R.propEq('memberLevelId', memberLevelId)))(rewardData);
      return R.pathOr('', ['0', 'description'])(memberData);
    }),
    genderCheckTask: (0, _emberConcurrency.task)(function* () {
      const gender = this.model.get('_data.gender');

      if (gender !== 'other') {
        this.model.set('_data.otherGender', '');
      }
    }),
    allowedCountries: (0, _object.computed)('isCheckout', 'website.{countries.[],shop._data.countries.[]}', function () {
      if (this.isCheckout) {
        return this.get('website.shop._data.countries') || [];
      }

      return this.get('website.countries') || [];
    }),
    countryLabel: (0, _object.computed)('website.countries.[]', 'model._data.countryCode', function () {
      const countryCode = this.get('model._data.countryCode');

      if (countryCode) {
        const countries = this.get('website.countries') || [];
        return R.pipe(R.find(R.propEq('code', countryCode)), R.propOr('', 'name'))(countries);
      }

      return '';
    }),
    _isEditing: (0, _object.computed)('isEditing', 'website.user.isMember', function () {
      if (!this.get('website.user.isMember')) {
        return true;
      }

      return this.isEditing;
    }),
    isReadonly: (0, _object.computed)('_isEditing', function () {
      return !this._isEditing;
    }),
    isAllowEditing: (0, _object.computed)('isEditing', 'disableAllEditing', function () {
      const selfIsEditing = this.isEditing;

      if (selfIsEditing) {
        return true;
      }

      if (this.disableAllEditing) {
        return false;
      }

      return true;
    }),
    showEmailVerification: (0, _object.computed)('isCheckout', 'website.user.{requireVerifyDetails,requireVerifyEmailToCheckout}', function () {
      if (this.get('website.user.requireVerifyDetails')) {
        return false;
      }

      if (!this.isCheckout) {
        if (!this.get('website.user._data.emailVerified')) {
          return true;
        }
      }

      return this.get('website.user.requireVerifyEmailToCheckout');
    }),
    // isMemberAddressDisabled: computed('isCheckout', 'website.{user.hasIncompleteProfile,_data.memberId}', 'isReadonly', function () {
    //   if (this.get('isCheckout')) {
    //     if (this.get('website.user.hasIncompleteProfile')) {
    //       return false
    //     }
    //     if (this.get('website.user.isMember')) {
    //       return true
    //     }
    //     return false
    //   }
    //   return this.get('isReadonly')
    // }),
    hasEInvoice: (0, _object.computed)('isVerification', 'model.{requireVerifyEmailToCheckout,_data.countryCode}', 'shop._data.tw_eInvoices', 'isCheckout', 'address.countryCode', function () {
      var _this$model2, _this$model2$_data, _this$address;

      if (this.get('shop._data.tw_eInvoices') === false) {
        return false;
      }

      if ((this === null || this === void 0 ? void 0 : (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : (_this$model2$_data = _this$model2._data) === null || _this$model2$_data === void 0 ? void 0 : _this$model2$_data.countryCode) !== 'TW') {
        return false;
      }

      if ((this === null || this === void 0 ? void 0 : (_this$address = this.address) === null || _this$address === void 0 ? void 0 : _this$address.countryCode) !== 'TW' && this.isCheckout) {
        return false;
      }

      if (this.isVerification || this.get('model.requireVerifyEmailToCheckout')) {
        return false;
      }

      return true;
    }),
    isTaxDisabled: (0, _object.computed)('isCheckout', 'website.user._data.{taxNumber,useDefaultTaxNumber}', 'isReadonly', function () {
      // const member = this.get('website.user')
      const isCheckout = this.isCheckout;

      if (isCheckout) {
        // if (member.get('_data.useDefaultTaxNumber') && member.get('_data.taxNumber')) {
        //   return true
        // }
        return false;
      }

      return this.isReadonly;
    }),
    appliedEmailForPromoCode: (0, _object.computed)('emailForPromoCode', 'createdReferralCodeData.recipientEmail', function () {
      var _this$createdReferral;

      const emailForPromoCode = this.storage.getValue('emailForPromoCode') || '';
      const recipientEmail = (this === null || this === void 0 ? void 0 : (_this$createdReferral = this.createdReferralCodeData) === null || _this$createdReferral === void 0 ? void 0 : _this$createdReferral.recipientEmail) || '';
      return emailForPromoCode || recipientEmail || '';
    }),
    showPromoCodeWarning: (0, _object.computed)('website.isLoggedIn', 'model._data.email', 'promoCode', 'appliedEmailForPromoCode', function () {
      var _this$website2;

      const isLoggedIn = (this === null || this === void 0 ? void 0 : (_this$website2 = this.website) === null || _this$website2 === void 0 ? void 0 : _this$website2.isLoggedIn) || false;
      const emailForPromoCode = this.appliedEmailForPromoCode || '';
      const userEmail = this.model._data.email || '';
      const promoCode = this.promoCode || ''; // in case user change email manually

      const isGuestAndHasDifferentEmailWithAppliedPromoCode = !isLoggedIn && promoCode && userEmail && emailForPromoCode && emailForPromoCode !== userEmail;

      if (isGuestAndHasDifferentEmailWithAppliedPromoCode) {
        return true;
      }

      return false;
    }),
    validateTaxNumberTask: (0, _emberConcurrency.task)(function* (taxNumber) {
      var _taxNumber;

      this.set('taxNumberError', false);
      const model = this.model;

      if (!taxNumber) {
        taxNumber = model._data.taxNumber || '';
      }

      if (!taxNumber) {
        return;
      }

      if (((_taxNumber = taxNumber) === null || _taxNumber === void 0 ? void 0 : _taxNumber.length) < 8) {
        this.set('taxNumberError', true);
        return;
      }

      const taxNumberData = yield this.websiteService.fetchAllDetailsFromGovTask.perform(taxNumber);

      if (taxNumberData !== null && taxNumberData !== void 0 && taxNumberData.name) {
        model.setData('companyName', taxNumberData === null || taxNumberData === void 0 ? void 0 : taxNumberData.name);
      }

      if (!(taxNumberData !== null && taxNumberData !== void 0 && taxNumberData.name)) {
        this.set('taxNumberError', true);
      }
    }),
    updateMemberTask: (0, _emberConcurrency.task)(function* (nextPage) {
      const website = this.website;

      try {
        const birthday = this.model.get('_data.birthday');

        if (birthday) {
          this.model.setData('hasUserSetBirthday', true);
        }

        const memberModel = this.model;
        yield this.membersService.get('updateMemberTask').perform(website, memberModel);
        const formatedBirthday = this.birthday;
        this.set('model._data.birthday', formatedBirthday);
        this.set('isEditing', false);
        this.set('disableAllEditing', false); // not working, this.onNext is undefined
        // if (this.onNext) {
        //   return this.onNext()
        // }

        if (this.websiteService.isShopRoute) {
          if (!R.includes('shop', nextPage)) {
            nextPage = R.concat('shop.', nextPage);
          }

          this.router.transitionTo(nextPage);
        } // if (nextPage) {
        //   const currentRoute = this.get('router').get('currentRouteName')
        //   if (R.includes('shop.', nextPage)) {
        //     console.log('4', website.get('shop.hasCartItems'))
        //     if (website.get('shop.hasCartItems')) {
        //       // go to shop details
        //       console.log('5')
        //       return this.get('router').transitionTo(nextPage)
        //     } else {
        //       // go to shop
        //       console.log('6')
        //       return this.get('router').transitionTo('shop')
        //     }
        //   }
        //   console.log('7')
        //   return this.get('router').transitionTo(nextPage)
        // }

      } catch (e) {
        this.websiteService.setErrors(e);
      }
    }).drop(),
    cancelEditTask: (0, _emberConcurrency.task)(function* () {
      var _this$membersService;

      if (_nventor.default.isNotNilOrEmpty((_this$membersService = this.membersService) === null || _this$membersService === void 0 ? void 0 : _this$membersService.originalMemberData)) {
        var _this$membersService2;

        this.set('model._data', (_this$membersService2 = this.membersService) === null || _this$membersService2 === void 0 ? void 0 : _this$membersService2.originalMemberData);
        this.membersService.set('originalMemberData', false);
      }

      this.set('isEditing', false);
      this.set('disableAllEditing', false);
      const fromRoute = this.get('params.from');

      if (fromRoute === 'checkout') {
        return this.router.transitionTo('shop.checkout.details');
      }

      const website = this.website;
      yield this.membersService.get('getMemberModelTask').perform(website);
    }),

    logout() {
      const website = this.website;
      this.membersService.logout(website);
      this.websiteService.goHome();
    },

    actions: {
      onSetTwEInvoiceCarrierType() {
        if (this.model.get('_data.twEInvoiceCarrierType')) {
          this.model.set('_data.taxNumber', '');
          this.model.set('_data.companyName', '');
          this.model.set('_data.useDefaultTaxNumber', false);
        } else {
          this.model.set('_data.twEInvoiceCarrierId', '');
        }
      },

      onSetTwEInvoiceCarrierId() {
        const carrierId = this.model.get('_data.twEInvoiceCarrierId') || '';
        this.model.set('_data.twEInvoiceCarrierId', R.toUpper(carrierId));
      },

      onSetTwEinvoiceAllowPrint(toggleValue) {
        this.model.setData('twEInvoicePrint', toggleValue);
      },

      // onSetTaxNumber () {
      //   if (this.model.get('_data.taxNumber')) {
      //     this.model.set('_data.twEInvoiceCarrierType', '')
      //     this.model.set('_data.twEInvoiceCarrierId', '')
      //   } else {
      //     this.model.set('_data.useDefaultTaxNumber', false)
      //   }
      // },
      input(value) {
        const sanitizedTelephone = R.replace(/[a-zA-Z]/gm, '')(value);
        this.model.set('_data.telephone', sanitizedTelephone);
      },

      edit() {
        const originalMemberData = this.model.serialize();
        this.membersService.set('originalMemberData', originalMemberData);

        if (this.isCheckout) {
          this.router.transitionTo('shop.members.member', {
            queryParams: {
              from: 'checkout',
              edit: 'profile'
            }
          });
        } else {
          const model = this.model;
          this.websiteService.get('fetchAllCountriesTask').perform(model);
          this.set('isEditing', true);
          this.set('disableAllEditing', true);

          _nventor.default.goToClassName('profile-container', -70);
        }
      },

      previous(prevPage) {
        if (prevPage) {
          return this.router.transitionTo(prevPage);
        }
      },

      refreshPage() {
        window.location.reload(true);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "logout", [_object.action], Object.getOwnPropertyDescriptor(_obj, "logout"), _obj)), _obj));

  _exports.default = _default;
});
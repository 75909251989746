define("shop/pods/components/flows/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FlowsComponent extends _component.default {}

  _exports.default = FlowsComponent;
});
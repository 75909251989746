define("shop/pods/components/elements/drawer/drawer-update-url/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ElementsDrawerDrawerUpdateurlComponent extends _component.default {
    constructor() {
      super(...arguments);

      if (this.args.modifiedRoute) {
        window.history.pushState(null, null, this.args.modifiedRoute);
      }
    }

  }

  _exports.default = ElementsDrawerDrawerUpdateurlComponent;
});
define("shop/pods/components/elements/element-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "//RItRqF",
    "block": "[[[41,[33,1],[[[1,\"\\t\"],[1,[28,[35,2],[[33,1]],[[\"translate\"],[[33,3]]]]],[1,\"\\n\"]],[]],[[[41,[33,4],[[[1,\"\\t\"],[1,[28,[35,2],[[33,4]],[[\"translate\"],[[33,3]]]]],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"label\",\"tt\",\"translate\",\"defaultLabel\"]]",
    "moduleName": "shop/pods/components/elements/element-label/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/shop/items-group/component", ["exports", "@ember/component", "shop/mixins/automations", "@ember/service"], function (_exports, _component, _automations, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_automations.default, {
    tagName: 'div',
    classNames: ['items-group'],
    websiteService: (0, _service.inject)('website')
  });

  _exports.default = _default;
});
define("shop/pods/not-found-404/route", ["exports", "@ember/routing/route", "rsvp", "ramda", "shop/utils/nventor", "@ember/service"], function (_exports, _route, _rsvp, R, _nventor, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NotFound404Route = (_dec = (0, _service.inject)('seo'), _dec2 = (0, _service.inject)('website'), (_class = class NotFound404Route extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "seoService", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);
    }

    async model() {
      var _website$_data;

      this.seoService.setBlockIndexing();
      const website = this.modelFor('application').website;
      const shop = this.modelFor('application').shop; // customerize not-found page

      const seoUrlRedirectsArray = (website === null || website === void 0 ? void 0 : (_website$_data = website._data) === null || _website$_data === void 0 ? void 0 : _website$_data.seoUrlRedirectsArray) || [];
      const removeHttpsFromStart = R.when(R.startsWith('https://'), // Check if the string starts with 'https://'
      R.replace('https://', '') // Replace 'https://' with an empty string
      );
      const removeTrailingSlash = R.when(R.endsWith('/'), // Check if the string ends with '/'
      R.init // If true, return the string without the last character
      );
      let currentUrl = window.location.href;
      currentUrl = removeTrailingSlash(currentUrl);
      currentUrl = decodeURIComponent(currentUrl);
      currentUrl = removeHttpsFromStart(currentUrl);
      const toCheckUrl = R.pipe(R.split('?'), R.head)(currentUrl);
      const found = R.find(urlData => {
        let oldUrl = (urlData === null || urlData === void 0 ? void 0 : urlData.old) || '';
        oldUrl = removeTrailingSlash(oldUrl);
        oldUrl = removeHttpsFromStart(oldUrl);

        if (_nventor.default.isURLEncoded(oldUrl)) {
          try {
            oldUrl = decodeURIComponent(oldUrl);
          } catch (e) {
            oldUrl = '';
          }
        }

        return oldUrl === toCheckUrl;
      })(seoUrlRedirectsArray);

      if (found !== null && found !== void 0 && found.new) {
        const didRedirect = this.websiteService.request301({
          params: found
        });

        if (didRedirect) {
          return;
        }
      }

      const redirectHome = website.getPage('not-found-redirect-home') || {};

      if (!_nventor.default.isNilOrEmpty(redirectHome)) {
        this.transitionTo('/');
      }

      const page = website.getPage('not-found') || {};

      if (!_nventor.default.isNilOrEmpty(page)) {
        this.transitionTo('/not-found');
      }

      return _rsvp.default.hash({
        shop,
        website
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "seoService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = NotFound404Route;
});
define("shop/pods/members/member/route", ["exports", "@ember/routing/route", "rsvp", "ramda", "@ember/service"], function (_exports, _route, _rsvp, R, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    router: (0, _service.inject)(),

    async model() {
      const website = this.modelFor('application').website;
      const token = this.membersService.getToken(website);

      if (!token) {
        return this.router.transitionTo('members');
      } // is this route still usable?


      const member = await this.membersService.get('getMemberModelTask').perform(website);
      const isEmpty = R.pipe(R.propOr({}, '_data'), R.isEmpty)(member); // const profileMenuLists = this.membersService.getMemberProfileMenu({ source: 'website', routeName: this.routeName })

      if (isEmpty) {
        return this.router.transitionTo('members');
      }

      return _rsvp.default.hash({
        website,
        member
      });
    }

  });

  _exports.default = _default;
});
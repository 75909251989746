define("shop/pods/components/store-location/group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "whnooE0R",
    "block": "[[[11,0],[24,0,\"blogs-group store-location-group has-text-centered\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@website\",\"@storeLocation\"],[[30,4],[30,3]]],null],[1,\"\\n\"]],[3]],null],[13],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@count\",\"@currentPage\",\"@hasMore\",\"@linkToRoute\",\"@linkToUrl\",\"@pageComponent\",\"@totalCount\",\"@website\"],[[30,5,[\"filterModel\",\"_data\",\"count\"]],[30,5,[\"filterModel\",\"_data\",\"page\"]],[30,5,[\"filterModel\",\"hasMore\"]],\"store-location\",\"\",[30,5],[30,5,[\"filterModel\",\"totalCount\"]],[30,5,[\"website\"]]]],null],[1,\"\\n\"]],[\"&attrs\",\"@storeLocations\",\"storeLocation\",\"@website\",\"@pageComponent\"],false,[\"each\",\"-track-array\",\"store-location/card\",\"layouts/pagination-bar\"]]",
    "moduleName": "shop/pods/components/store-location/group/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
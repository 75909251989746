define("shop/pods/components/cart/checkout-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CgTnIEI4",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[28,[35,2],null,[[\"shop\",\"website\",\"totalItems\",\"label\"],[[33,3],[33,4],[33,1],[33,5]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"totalItems\",\"cart/checkout-btn\",\"shop\",\"website\",\"label\"]]",
    "moduleName": "shop/pods/components/cart/checkout-tag/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/components-group-blogs/component", ["exports", "@glimmer/component", "@ember/object", "@ember/object/internals", "@glimmer/tracking", "shop/utils/nventor", "jquery", "@ember/service", "ramda", "ember-concurrency-decorators", "@ember/array"], function (_exports, _component, _object, _internals, _tracking, _nventor, _jquery, _service, R, _emberConcurrencyDecorators, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ComponentsGroupBlogsComponent = (_dec = (0, _service.inject)('shop'), _dec2 = (0, _service.inject)('filters'), _dec3 = (0, _service.inject)('website'), (_class = class ComponentsGroupBlogsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "shopService", _descriptor, this);

      _initializerDefineProperty(this, "filtersService", _descriptor2, this);

      _initializerDefineProperty(this, "websiteService", _descriptor3, this);

      _initializerDefineProperty(this, "isScrollable", _descriptor4, this);

      _initializerDefineProperty(this, "hideRight", _descriptor5, this);

      _initializerDefineProperty(this, "hideLeft", _descriptor6, this);

      _initializerDefineProperty(this, "filterModel", _descriptor7, this);

      _initializerDefineProperty(this, "limitBy", _descriptor8, this);

      _initializerDefineProperty(this, "page", _descriptor9, this);

      _initializerDefineProperty(this, "blogs", _descriptor10, this);

      _initializerDefineProperty(this, "preloadBlogs", _descriptor11, this);

      _initializerDefineProperty(this, "tags", _descriptor12, this);

      _initializerDefineProperty(this, "keys", _descriptor13, this);

      _defineProperty(this, "id", (0, _internals.guidFor)(this));

      this.setupTags();
      this.limitBy = R.pipe(R.path(['args', 'limitBy']), parseInt)(this) || this.limitBy;
      this.keys = R.pipe(R.pathOr([], ['args', 'keys']))(this);
      const params = this.args.params || {};
      const defaultData = {
        prices: R.pathOr([], ['args', 'shop', '_data', 'prices'])(this),
        sortBy: R.pathOr([], ['args', 'shop', '_data', 'sortBy'])(this)
      };
      this.filterModel = this.filtersService.setupFilters(defaultData, {
        params
      });

      if (this.hasTags) {
        this.fetchTask.perform();
      } else if (this.hasKeys) {
        this.fetchByKeysTask.perform();
      } else {
        this.handlePreLoadedProducts();
      }
    }

    get hasTags() {
      return R.pipe(R.pathOr([], ['args', 'tags']), R.isEmpty, R.not)(this);
    }

    get hasKeys() {
      return R.pipe(R.pathOr([], ['args', 'keys']), R.isEmpty, R.not)(this);
    }

    get isGrid() {
      return this.args.style === 'grid';
    }

    get hasMoreProductsOnSlider() {
      var _this$blogs, _this$args, _this$args$blogs;

      if (this.isGrid) {
        return false;
      }

      if (this.hasTags) {
        return this.filterModel.hasMore;
      }

      return (this === null || this === void 0 ? void 0 : (_this$blogs = this.blogs) === null || _this$blogs === void 0 ? void 0 : _this$blogs.length) < (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$blogs = _this$args.blogs) === null || _this$args$blogs === void 0 ? void 0 : _this$args$blogs.length);
    }

    get isFullWidth() {
      var _this$args2;

      const isGrid = (this === null || this === void 0 ? void 0 : this.isGrid) || false;

      if (isGrid) {
        return false;
      }

      return ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.fullWidth) || false;
    }

    get titleLineCount() {
      return R.pathOr(2, ['args', 'titleLineCount'])(this);
    }

    get descriptionLineCount() {
      return R.pathOr(3, ['args', 'descriptionLineCount'])(this);
    }

    setupTags() {
      const allTags = R.pathOr([], ['args', 'website', '_data', 'blogTags'])(this);
      const sliderTags = R.pipe(R.pathOr([], ['args', 'tags']), R.split(','))(this);
      this.tags = R.pipe(R.filter(R.pipe(R.propOr('', '_key'), R.includes(R.__, sliderTags))), R.pluck('label'))(allTags);
    }

    replaceBlogsSliders() {
      const staticWrapper = document.querySelector('#page-custom-content').querySelector(`[data-index="${this.args.index}"]`);
      const dynamicWrapper = document.querySelector('.hidden-components').querySelector(`#${this.id}`);
      const hasDynamicWrapper = R.pipe(R.isEmpty, R.not)(dynamicWrapper);

      if (hasDynamicWrapper) {
        staticWrapper.parentElement.replaceChild(dynamicWrapper, staticWrapper);
      }
    }

    handlePreLoadedProducts(blogs = this.preloadBlogs) {
      const preLoadedProducts = blogs || R.pathOr([], ['args', 'blogs'])(this);

      if (this.isGrid) {
        const paginatedProducts = R.splitEvery(this.limitBy, preLoadedProducts);
        const pageIndex = this.page - 1;
        this.blogs = paginatedProducts[pageIndex];
        this.filterModel.hasMore = paginatedProducts.length > this.page;
        this.filterModel.totalCount = preLoadedProducts.length;
      } else {
        // @NOTE: this is to render one by one
        const from = this.blogs.length || 0;
        const end = from + this.limitBy;
        R.pipe(R.slice(from, end), R.map(prod => {
          this.blogs.addObject(prod);
        }))(preLoadedProducts);
        this.hideRight = false;
      }
    }

    handlePaginationByStyle(response) {
      let blogs = R.propOr([], 'blogs')(response);

      if (this.isGrid) {
        this.blogs = blogs;
      } else {
        const blogsToRemove = R.pipe(R.pathOr([], ['blogs']), R.map(R.pathOr('', ['_data', '_key'])))(this);
        blogs = R.reject(p => R.includes(p._data._key, blogsToRemove))(blogs);
        R.forEach(p => this.blogs.addObject(p))(blogs);
      }
    }

    *fetchTask() {
      const params = this.filterModel.getAsParams();
      params.count = this.limitBy;
      params.page = this.page;
      params.tags = this.tags;
      const response = yield this.websiteService.getBlogsTask.perform({
        params
      });
      this.handlePaginationByStyle(response);
      this.filterModel.results = R.propOr([], 'items', response);
      this.filterModel.hasMore = R.propOr(false, 'hasMore', response);
      this.filterModel.totalCount = R.propOr(0, 'totalCount', response);
    }

    *fetchByKeysTask() {
      const response = yield this.websiteService.getBlogsTask.perform({
        params: {
          keys: this.keys
        }
      });
      this.preloadBlogs = R.pipe(R.propOr([], 'blogs'), R.sortBy(R.pipe(R.path(['_data', '_key']), R.indexOf(R.__, this.keys))))(response);
      this.handlePreLoadedProducts();
    }

    *onPaginateTask(nextPage) {
      this.page = nextPage;

      if (this.hasTags) {
        return yield this.fetchTask.perform();
      }

      return this.handlePreLoadedProducts();
    }

    moveHorizontally(direction) {
      const sliderContainerJquery = (0, _jquery.default)(`#${this.id}-content`);
      const sliderContainer = sliderContainerJquery[0];
      const scrollLeft1 = sliderContainer.scrollLeft;
      const containerWidth = sliderContainer.offsetWidth;
      let updatedScrollLeft;
      let moveLeftBy = Math.floor(sliderContainerJquery.width() / sliderContainer.children[0].offsetWidth) * sliderContainer.children[0].offsetWidth;
      moveLeftBy = moveLeftBy + 15; // margin, added because slider always not align

      const totalScrollWidth = sliderContainer.scrollWidth;

      if (direction === 'right') {
        sliderContainer.scrollBy(moveLeftBy, 0);
        updatedScrollLeft = scrollLeft1 + moveLeftBy;
      } else {
        sliderContainer.scrollBy(moveLeftBy * -1, 0);
        updatedScrollLeft = scrollLeft1 - moveLeftBy;
      }

      const updatedCurrentRight = containerWidth + updatedScrollLeft;
      const isOnRightEdge = updatedCurrentRight + 10 >= totalScrollWidth;
      const isOnLeftEdge = updatedScrollLeft - 10 <= 0;
      this.hideRight = isOnRightEdge;
      this.hideLeft = isOnLeftEdge;
    }

    setScroll() {
      if (this.isGrid) {
        this.isScrollable = false;
        return false;
      }

      const sliderContainerDomElement = document.querySelector(`#${this.id}-content`);

      if (sliderContainerDomElement) {
        this.isScrollable = false;
        const hideArrows = sliderContainerDomElement.clientWidth >= sliderContainerDomElement.scrollWidth;
        const isGraterThanWindow = screen.width <= sliderContainerDomElement.clientWidth;
        const isMobile = this.websiteService.isMobile;

        if (isMobile) {
          if (isGraterThanWindow || !hideArrows) {
            return this.isScrollable = true;
          }
        } else {
          if (isGraterThanWindow || !hideArrows) {
            return this.isScrollable = true;
          }

          return this.isScrollable = false;
        }
      }
    }

    onFetchMore() {
      this.page += 1;

      if (this.hasTags) {
        return this.onPaginateTask.perform(this.page);
      }

      return this.handlePreLoadedProducts();
    }

    didInsert() {
      this.replaceBlogsSliders();
      const onDidInsert = R.pathOr(null, ['args', 'onDidInsert'])(this);

      if (onDidInsert) {
        onDidInsert();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isScrollable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hideRight", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hideLeft", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filterModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "limitBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 20;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "blogs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "preloadBlogs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "tags", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "keys", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchByKeysTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchByKeysTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPaginateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onPaginateTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moveHorizontally", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "moveHorizontally"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setScroll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFetchMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFetchMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype)), _class));
  _exports.default = ComponentsGroupBlogsComponent;
});
define("shop/pods/components/social-login/bigordr/login-form/component", ["exports", "@ember/component", "@ember/service", "ember-concurrency", "ramda"], function (_exports, _component, _service, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    storage: (0, _service.inject)(),
    validatorService: (0, _service.inject)('validator'),
    isRegister: false,
    email: '',
    password: '',

    init() {
      this._super(...arguments);

      this.set('schema', {
        required: ['email', 'password'],
        properties: {
          email: {
            type: 'string',
            format: 'email',
            maxLength: 320
          },
          password: {
            minLength: 8,
            type: 'string'
          }
        },
        type: 'object'
      });
    },

    validate() {
      const data = {
        email: this.email,
        password: this.password
      };
      return this.validatorService.validate(this.schema, data);
    },

    bigordrLoginTask: (0, _emberConcurrency.task)(function* () {
      const email = this.email;
      const password = this.password;
      const website = this.website;

      try {
        const data = {
          email,
          password
        };
        this.validate();
        const res = yield this.membersService.get('bigordrAuthLogin').perform(website, data);
        const isRedirect = R.propEq('isRedirect', true)(res);

        if (isRedirect) {
          return;
        }

        const docExternalUrl = (website === null || website === void 0 ? void 0 : website.failedScheduledPaymentExternalUrl) || '';

        if (docExternalUrl) {
          return this.router.transitionTo('erp.payments.card.doc', docExternalUrl);
        }

        if (this.websiteService.isShopRoute) {
          if (this.get('website.shop.hasCartItems')) {
            this.router.transitionTo('shop.checkout.details');
          } else {
            this.router.transitionTo('shop.members.member');
          }
        } else {
          return this.router.transitionTo('members.member');
        }
      } catch (e) {
        this.websiteService.setErrors(e, {
          errorMessage: 'invalid login credentials'
        });
      }
    }),
    actions: {
      toggleRegister() {
        if (this.isRegister) {
          return this.set('isRegister', false);
        } else {
          return this.set('isRegister', true);
        }
      },

      resetPassword() {
        if (this.websiteService.isShopRoute) {
          return this.router.transitionTo('shop.members.reset-password');
        } else {
          this.router.transitionTo('members.reset-password');
        }
      }

    }
  });

  _exports.default = _default;
});
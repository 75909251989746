define("shop/pods/components/blogs/blog-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XLDDse/3",
    "block": "[[[46,[30,0,[\"blogsStyleComponent\"]],null,[[\"blog\",\"website\",\"imagesHoverFilter\",\"imagesHoverTransform\",\"blogNameUrl\",\"blogsFontSize\",\"blogsFontAlignment\",\"blogsLineCountForTitle\",\"blogsLineCountForDescription\",\"isGrid\",\"isFullWidth\"],[[30,1],[30,2],[30,0,[\"imagesHoverFilter\"]],[30,0,[\"imagesHoverTransform\"]],[30,0,[\"blogNameUrl\"]],[30,0,[\"blogsFontSize\"]],[30,0,[\"blogsFontAlignment\"]],[30,3],[30,4],[30,5],[30,6]]],null],[1,\"\\n\"]],[\"@blog\",\"@website\",\"@titleLineCount\",\"@descriptionLineCount\",\"@isGrid\",\"@isFullWidth\"],false,[\"component\"]]",
    "moduleName": "shop/pods/components/blogs/blog-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/ajax-checkout/service", ["exports", "ember-ajax/services/ajax", "shop/config/environment", "@ember/object"], function (_exports, _ajax, _environment, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    host: _environment.default.APP.serverUrlCheckout,
    contentType: 'application/json; charset=utf-8',
    headers: (0, _object.computed)('location.{href,pathname}', function () {
      return {
        'x-request-href': location.href,
        'x-request-path-name': location.pathname
      };
    }).volatile()
  });

  _exports.default = _default;
});
define("shop/pods/components/countdown-timer/component", ["exports", "@glimmer/component", "@ember/service", "ember-concurrency-decorators", "@glimmer/tracking", "shop/utils/nventor"], function (_exports, _component, _service, _emberConcurrencyDecorators, _tracking, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CountdownTimerComponent = (_dec = (0, _service.inject)('date'), _dec2 = (0, _service.inject)('header'), (_class = class CountdownTimerComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "dateService", _descriptor, this);

      _initializerDefineProperty(this, "headerService", _descriptor2, this);

      _initializerDefineProperty(this, "timerInitialized", _descriptor3, this);

      _initializerDefineProperty(this, "distance", _descriptor4, this);
    }

    *setupTask(element) {
      var _this$args;

      let dateEndZ = (_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.dateEndZ;
      dateEndZ = this.dateService.getMoment(dateEndZ).endOf('day').toISOString();
      const endTime = new Date(dateEndZ).getTime();
      let distance = 0;
      const countdownTimer = setInterval(() => {
        const now = new Date().getTime();
        distance = endTime - now;

        if (distance < 0 || this.isDestroyed) {
          clearInterval(countdownTimer);
        } else {
          this.distance = distance;
        }
      }, 1000);
      this.timerInitialized = true;
    }

    get timerStyle() {
      var _this$headerService;

      if (!this.timerInitialized) {
        return '';
      }

      const navBarHeight = ((_this$headerService = this.headerService) === null || _this$headerService === void 0 ? void 0 : _this$headerService.navBarHeight) || 0;
      return `top: ${navBarHeight}px`;
    }

    get isFlashy() {
      if (parseInt(this.hours) < 1 && this.timerInitialized) {
        return true;
      }

      return false;
    }

    get days() {
      const distance = this.distance;
      let number = 0;

      if (distance) {
        number = Math.floor(distance / (1000 * 60 * 60 * 24));
      }

      return number;
    }

    get hours() {
      const distance = this.distance;
      let number = 0;

      if (distance) {
        number = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
      }

      return this._addLeadingZero(number);
    }

    get minutes() {
      const distance = this.distance;
      let number = 0;

      if (distance) {
        number = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
      }

      return this._addLeadingZero(number);
    }

    get seconds() {
      const distance = this.distance;
      let number = 0;

      if (distance) {
        number = Math.floor(distance % (1000 * 60) / 1000);
      }

      return this._addLeadingZero(number);
    }

    get showTimer() {
      if (!_nventor.default.isNilOrEmpty(this.distance) && this.timerInitialized) {
        return true;
      }

      return false;
    }

    _addLeadingZero(number) {
      if (number < 10) {
        return `0${number}`;
      }

      return number;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "headerService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "timerInitialized", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "distance", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype)), _class));
  _exports.default = CountdownTimerComponent;
});
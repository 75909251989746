define("shop/pods/components/referral-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qoDehJPc",
    "block": "[[[11,0],[24,0,\"referral-btn__container\"],[4,[38,0],[[28,[37,1],[[30,0,[\"setReferralButtonVisibility\"]]],null]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@classNames\",\"@label\",\"@onSubmit\"],[\"is-success\",\"referral_code_refer_friends\",[28,[37,3],[[30,0],\"goToReferral\"],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"did-insert\",\"fn\",\"elements/element-btn\",\"action\"]]",
    "moduleName": "shop/pods/components/referral-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/blogs/blog-card/component", ["exports", "@glimmer/component", "@ember/service", "ramda"], function (_exports, _component, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BlogsBlogCardComponent = (_dec = (0, _service.inject)('website'), (_class = class BlogsBlogCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "helpers", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);
    }

    get blogsStyleComponent() {
      var _this$websiteService, _this$args, _website$_data;

      const isMobile = ((_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : _this$websiteService.isMobile) || false;
      let screen = 'desktop';

      if (isMobile) {
        screen = 'mobile';
      }

      const website = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.website) || {};
      const style = (website === null || website === void 0 ? void 0 : (_website$_data = website._data) === null || _website$_data === void 0 ? void 0 : _website$_data.blogsStyle) || 'style1';
      return `blogs/blog-card/${screen}/blog-card-${style}`;
    }

    get blogsFontSize() {
      var _this$args2, _website$_data2, _shop$_data;

      const website = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.website) || {};
      const shop = (website === null || website === void 0 ? void 0 : website.shop) || {};
      return (website === null || website === void 0 ? void 0 : (_website$_data2 = website._data) === null || _website$_data2 === void 0 ? void 0 : _website$_data2.blogsFontSize) || (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.productsNameFontSize) || 'small';
    }

    get blogsFontAlignment() {
      var _this$args3, _website$_data3;

      const website = ((_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.website) || {};
      return (website === null || website === void 0 ? void 0 : (_website$_data3 = website._data) === null || _website$_data3 === void 0 ? void 0 : _website$_data3.blogsFontAlignment) || 'center';
    }

    get blogNameUrl() {
      var _this$args4, _this$args4$blog, _this$args4$blog$_dat;

      const blogName = ((_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : (_this$args4$blog = _this$args4.blog) === null || _this$args4$blog === void 0 ? void 0 : (_this$args4$blog$_dat = _this$args4$blog._data) === null || _this$args4$blog$_dat === void 0 ? void 0 : _this$args4$blog$_dat.name) || '';
      return this.helpers.beautifyUrl(blogName);
    }

    get imagesHoverFilter() {
      var _this$websiteService2, _this$websiteService3, _this$websiteService4, _this$websiteService5;

      return `hover-filter--${((_this$websiteService2 = this.websiteService) === null || _this$websiteService2 === void 0 ? void 0 : (_this$websiteService3 = _this$websiteService2.website) === null || _this$websiteService3 === void 0 ? void 0 : (_this$websiteService4 = _this$websiteService3.shop) === null || _this$websiteService4 === void 0 ? void 0 : (_this$websiteService5 = _this$websiteService4._data) === null || _this$websiteService5 === void 0 ? void 0 : _this$websiteService5.productsImagesHoverFilter) || 100}`;
    }

    get imagesHoverTransform() {
      var _this$websiteService6, _this$websiteService7, _this$websiteService8, _this$websiteService9;

      return `hover-transform--${((_this$websiteService6 = this.websiteService) === null || _this$websiteService6 === void 0 ? void 0 : (_this$websiteService7 = _this$websiteService6.website) === null || _this$websiteService7 === void 0 ? void 0 : (_this$websiteService8 = _this$websiteService7.shop) === null || _this$websiteService8 === void 0 ? void 0 : (_this$websiteService9 = _this$websiteService8._data) === null || _this$websiteService9 === void 0 ? void 0 : _this$websiteService9.productsImagesHoverTransform) || 'bigger'}`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "helpers", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BlogsBlogCardComponent;
});
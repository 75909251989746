define("shop/pods/token/service", ["exports", "@ember/service", "@ember/object", "jwt-decode"], function (_exports, _service, _object, _jwtDecode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let TokenService = (_class = class TokenService extends _service.default {
    parseToken(token) {
      if (token) {
        try {
          return (0, _jwtDecode.default)(token);
        } catch (err) {
          return false;
        }
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "parseToken", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "parseToken"), _class.prototype)), _class);
  _exports.default = TokenService;
});
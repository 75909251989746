define("shop/pods/shop/influencer/route", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ShopInfluencerRoute extends _route.default {}

  _exports.default = ShopInfluencerRoute;
});
define("shop/pods/shop/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IJmPuBh5",
    "block": "[[[8,[39,0],null,[[\"@website\",\"@isIndex\",\"@page\",\"@shop\"],[[30,0,[\"model\",\"website\"]],false,[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"shop\"]]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container max-content-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"max-content-container\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@website\",\"@shop\",\"@item\",\"@automation\",\"@hasGoToTop\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"item\"]],[30,0,[\"model\",\"automation\"]],true]],null],[1,\"\\n      \"],[13],[1,\"    \\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"website/website-container\",\"items/item-page\"]]",
    "moduleName": "shop/pods/shop/detail/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
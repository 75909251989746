define("shop/pods/components/elements/address-selector/component", ["exports", "ramda", "@ember/component", "ember-concurrency", "@ember/object", "shop/utils/nventor", "@ember/service"], function (_exports, R, _component, _emberConcurrency, _object, _nventor, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    shopService: (0, _service.inject)('shop'),
    websiteService: (0, _service.inject)('website'),
    checkoutService: (0, _service.inject)('checkout'),
    router: (0, _service.inject)(),
    tagName: 'div',
    addressStreetLabel: 'address',
    lastCountryCode: '',
    isEditing: false,
    addressErrorPrefix: '',

    didInsertElement() {
      this._super(...arguments);

      this.setupTask.perform();
    },

    showCountryIsNotSupported: (0, _object.computed)('isDetailStep', 'checkoutService.isSupportedCountryForMember', 'checkoutData.pickupMethod', function () {
      var _this$checkoutData, _this$checkoutService;

      const pickupMethod = this === null || this === void 0 ? void 0 : (_this$checkoutData = this.checkoutData) === null || _this$checkoutData === void 0 ? void 0 : _this$checkoutData.pickupMethod;

      if (pickupMethod === 'storeLocationPickup') {
        return false;
      }

      const isDetailStep = this === null || this === void 0 ? void 0 : this.isDetailStep;
      const isSupportedCountryForMember = this === null || this === void 0 ? void 0 : (_this$checkoutService = this.checkoutService) === null || _this$checkoutService === void 0 ? void 0 : _this$checkoutService.isSupportedCountryForMember;

      if (isDetailStep && !isSupportedCountryForMember) {
        return true;
      }

      return false;
    }),
    setupTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const address = this.address;
      yield this.fetchCountriesTask.perform({});
      const currentCountryCode = model.get('_data.countryCode') || address.get('countryCode');
      this.set('lastCountryCode', currentCountryCode);

      if (this.isCheckout && this.alwaysUseAlternateAddress) {
        model.toggleAlternateAddress(this.website, true);
        const checkoutData = this.checkoutData;

        if (checkoutData) {
          (0, _object.set)(checkoutData, 'useAlternateAddress', true);
        }
      }

      yield this.websiteService.get('fetchRegionsTask').perform(currentCountryCode);
    }),
    fetchCountriesTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      yield this.websiteService.get('fetchAllCountriesTask').perform(model);
      this.setCountry();
    }).enqueue(),

    setCountry() {
      const model = this.model;
      const address = this.address;
      const countries = this.allowedCountries;
      model.setDefaultAllowedCountry(address, countries);
    },

    alwaysUseAlternateAddress: (0, _object.computed)('isCheckout', 'checkoutService.isSupportedCountryForMember', 'checkoutData.pickupMethod', function () {
      var _this$checkoutData2;

      const pickupMethod = this === null || this === void 0 ? void 0 : (_this$checkoutData2 = this.checkoutData) === null || _this$checkoutData2 === void 0 ? void 0 : _this$checkoutData2.pickupMethod;

      if (pickupMethod === 'storeLocationPickup') {
        return false;
      }

      if (this.isCheckout && !this.checkoutService.isSupportedCountryForMember) {
        return true;
      }

      return false;
    }),
    allowedCountries: (0, _object.computed)('isCheckout', 'address', 'website.countries.[]', 'checkoutService.allowedCountries.[]', 'isEditing', function () {
      let countries = [];

      if (this.isCheckout) {
        countries = this.checkoutService.allowedCountries;
      } else {
        countries = this.get('website.countries') || [];
      }

      if (this.address) {
        var _this$address;

        // this is not actually an allowed country.
        // this is when the members country is not in the allowed countries list,
        // we need to add it to the list so the dropdown can display the members original country,
        // we will also display zone not supported message when this country is selected.
        const countryCode = (_this$address = this.address) === null || _this$address === void 0 ? void 0 : _this$address.countryCode;
        const alreadyExists = R.find(R.propEq('code', countryCode))(countries);
        const address = R.clone(this.address);
        address.code = address.countryCode;
        address.name = address.country;

        if (!alreadyExists) {
          countries.pushObject(address);
        }
      }

      return countries;
    }),
    isReadonly: (0, _object.computed)('isEditing', function () {
      return !this.isEditing;
    }),
    isAllowEditing: (0, _object.computed)('isEditing', 'disableAllEditing', function () {
      const selfIsEditing = this.isEditing;

      if (selfIsEditing) {
        return true;
      }

      if (this.disableAllEditing) {
        return false;
      }

      return true;
    }),
    hasPostCode: (0, _object.computed)('address.hasPostCode', function () {
      return R.pathOr(true, ['address', 'hasPostCode'])(this);
    }),
    hasIncompleteAddress: (0, _object.computed)('address.{hasPostCode,postCode,street,district,region,countryCode}', 'hasPostCode', function () {
      // return true
      const hasPostCode = this.hasPostCode;
      const postCode = this.get('address.postCode');
      const street = this.get('address.street');
      const required = [street];

      if (hasPostCode) {
        required.push(postCode);
      }

      if (this.get('address.hasAdvancedAddress')) {
        const district = this.get('address.district');
        const region = this.get('address.region');
        required.push(region);
        required.push(district);
      }

      return R.any(_nventor.default.isNilOrEmpty)(required);
    }),
    selectedCountry: (0, _object.computed)('address.countryCode', 'allowedCountries.[]', function () {
      const countries = this.allowedCountries;
      const countryCode = this.get('address.countryCode');
      return this.websiteService.getSelectedCountry(countries, countryCode);
    }),
    isDistrictsDisabled: (0, _object.computed)('address.region', 'isReadonly', function () {
      if (this.isReadonly) {
        return true;
      }

      const region = this.get('address.region') || '';

      if (!region) {
        return true;
      }

      return false;
    }),
    isPostCodeDisabled: (0, _object.computed)('address.hasAdvancedAddress', 'isReadonly', function () {
      if (this.get('address.hasAdvancedAddress')) {
        return true;
      }

      return this.isReadonly;
    }),
    dataForCountry: (0, _object.computed)('website.allRegions.[]', 'address.countryCode', function () {
      const countryCode = this.get('address.countryCode');
      const allRegions = this.get('website.allRegions') || [];
      return R.find(R.propEq('countryCode', countryCode))(allRegions);
    }),
    regionsForCountry: (0, _object.computed)('dataForCountry', 'address.countryCode', function () {
      const dataForCountry = this.dataForCountry || {};
      return R.propOr([], 'regions')(dataForCountry);
    }),
    allowUpdateDeliveryAddress: (0, _object.computed)('checkoutData.{isConvenienceStore,pickupMethod}', function () {
      const isHomeDelivery = R.pathEq(['checkoutData', 'pickupMethod'], 'homeDelivery')(this);

      if (isHomeDelivery) {
        return true;
      }

      const isConvenienceStore = R.pathEq(['checkoutData', 'isConvenienceStore'], true)(this);

      if (isConvenienceStore) {
        return false;
      }

      const isPickup = R.pathEq(['checkoutData', 'pickupMethod'], 'storeLocationPickup')(this);

      if (isPickup) {
        return false;
      }

      return true;
    }),
    districtsForRegion: (0, _object.computed)('regionsForCountry.[]', 'model._data.useAlternateAddress', 'address.region', 'dataForCountry', function () {
      const regionsForCountry = this.regionsForCountry || [];

      const getRegionKey = zhTw => {
        return R.pipe(R.filter(R.pathEq(['zh-tw'], zhTw)), R.head, R.path(['key']))(regionsForCountry);
      };

      const region = this.get('address.region');
      const regionKey = getRegionKey(region);
      const dataForCountry = this.dataForCountry || {};
      const districts = R.propOr([], 'districts')(dataForCountry);
      return R.filter(R.pathEq(['region_key'], regionKey))(districts);
    }),
    selectCountryTask: (0, _emberConcurrency.task)(function* (address, selectedCountryCode) {
      const model = this.model;
      const selectedCountry = this.selectedCountry;
      model.setAddressCountry(address, selectedCountry);
      yield this.websiteService.fetchRegionsTask.perform(selectedCountryCode);

      if (this.isCheckout) {
        this.onValidateAddressTask();
      }
    }),

    updateAddress() {
      const districts = this.districtsForRegion || [];

      if (R.isEmpty(districts)) {
        this.set('address.district', '');
      }

      const selectedDistrictName = this.get('address.district');

      if (selectedDistrictName) {
        const getPostCode = (districtName, districtsArray) => {
          const code = R.pipe(R.filter(R.pathEq(['zh-tw'], districtName)), R.head, R.pathOr('', ['postcode']))(districtsArray);

          if (R.isEmpty(code)) {
            return '';
          }

          return R.toString(code);
        };

        const postCode = getPostCode(selectedDistrictName, districts);
        this.set('address.postCode', postCode);
      } else {
        this.set('address.postCode', '');
      }

      if (this.isCheckout) {
        const countryCode = this.get('address.countryCode') || '';
        const region = this.get('address.region') || '';
        const postCode = this.get('address.postCode') || '';

        if (countryCode && region && postCode) {
          this.onValidateAddressTask();
        }
      }
    },

    cancelEditTask: (0, _emberConcurrency.task)(function* () {
      this.set('isEditing', false);
      this.set('disableAllEditing', false);
      const fromRoute = this.get('params.from');

      if (fromRoute === 'checkout') {
        return this.router.transitionTo('shop.checkout.details');
      }

      const website = this.website;
      yield this.membersService.get('getMemberModelTask').perform(website);
    }),
    updateMemberTask: (0, _emberConcurrency.task)(function* (nextPage) {
      try {
        const website = this.website;
        const memberModel = this.model;
        yield this.membersService.get('updateMemberAddressesTask').perform(website, memberModel);
        this.set('isEditing', false);
        this.set('disableAllEditing', false);

        if (this.onNext) {
          return this.onNext();
        }

        if (nextPage) {
          return this.router.transitionTo(nextPage);
        }
      } catch (e) {
        this.websiteService.setErrors(e);
      }
    }).drop(),
    actions: {
      edit() {
        if (this.isCheckout) {
          this.router.transitionTo('shop.members.member', {
            queryParams: {
              from: 'checkout',
              edit: 'address'
            }
          });
        } else {
          this.fetchCountriesTask.perform();
          this.set('isEditing', true);
          this.set('disableAllEditing', true);

          _nventor.default.goToClassName('address-container', -70);
        }
      },

      toggleAlternateAddress(bool) {
        // only applicable for userModel
        const model = this.model;
        const website = this.website;
        model.toggleAlternateAddress(website, bool);
      },

      updateRegion() {
        this.set('address.district', '');
        this.updateAddress();
      },

      updateAddress() {
        this.updateAddress();
      }

    }
  });

  _exports.default = _default;
});
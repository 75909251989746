define("shop/pods/components/cart/content-summary/totals/component", ["exports", "@ember/component", "@ember/object", "ramda"], function (_exports, _component, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    hasUserAddressDataForFreeShipping: (0, _object.computed)('checkoutData.{pickupMethod,isConvenienceStore,checkoutAddressData.postCode,checkoutAddressData.hasPostCode,checkoutAddressData.country,checkoutAddressData.street,convenienceStoreData.storeId}', function () {
      // @TODO: this is the same as checkout mixin hasUserAddressData
      const checkoutData = this.checkoutData || {};
      const pickupMethod = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.pickupMethod) || 'homeDelivery';
      const isConvenienceStore = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.isConvenienceStore) || false;

      if (pickupMethod === 'storeLocationPickup') {
        return true;
      }

      if (pickupMethod === 'homeDelivery') {
        var _checkoutData$checkou, _checkoutData$checkou2, _checkoutData$checkou3;

        const country = (checkoutData === null || checkoutData === void 0 ? void 0 : (_checkoutData$checkou = checkoutData.checkoutAddressData) === null || _checkoutData$checkou === void 0 ? void 0 : _checkoutData$checkou.country) || '';

        if (R.toUpper(country) === 'OTHER') {
          return true;
        }

        const postCode = (checkoutData === null || checkoutData === void 0 ? void 0 : (_checkoutData$checkou2 = checkoutData.checkoutAddressData) === null || _checkoutData$checkou2 === void 0 ? void 0 : _checkoutData$checkou2.postCode) || '';
        const address = (checkoutData === null || checkoutData === void 0 ? void 0 : (_checkoutData$checkou3 = checkoutData.checkoutAddressData) === null || _checkoutData$checkou3 === void 0 ? void 0 : _checkoutData$checkou3.street) || '';
        const hasPostCode = R.pathOr(true, ['checkoutAddressData', 'hasPostCode'])(checkoutData);

        if (!country) {
          return false;
        }

        if (!address) {
          return false;
        }

        if (hasPostCode && !postCode) {
          return false;
        }

        return true;
      }

      if (isConvenienceStore) {
        var _checkoutData$conveni;

        const storeId = (checkoutData === null || checkoutData === void 0 ? void 0 : (_checkoutData$conveni = checkoutData.convenienceStoreData) === null || _checkoutData$conveni === void 0 ? void 0 : _checkoutData$conveni.storeId) || '';

        if (storeId) {
          return true;
        }
      }

      return false;
    }),
    isShowCalculatingShipping: (0, _object.computed)('hasUserAddressDataForFreeShipping', 'checkoutData.pickupMethod', 'isSummaryStep', function () {
      const isSummaryStep = (this === null || this === void 0 ? void 0 : this.isSummaryStep) || false;

      if (isSummaryStep) {
        return true;
      }

      const checkoutData = (this === null || this === void 0 ? void 0 : this.checkoutData) || {};

      if ((checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.pickupMethod) === 'storeLocationPickup') {
        return false;
      }

      if (!this.hasUserAddressDataForFreeShipping) {
        return true;
      }

      return false;
    }),
    isFreeShipping: (0, _object.computed)('shippingFeeData.{zoneSupported,rate,hasShippingFee}', function () {
      const shippingFeeData = (this === null || this === void 0 ? void 0 : this.shippingFeeData) || {};
      const zoneSupported = shippingFeeData === null || shippingFeeData === void 0 ? void 0 : shippingFeeData.zoneSupported;
      const rate = Number(shippingFeeData === null || shippingFeeData === void 0 ? void 0 : shippingFeeData.rate) || 0;
      const hasShippingFee = shippingFeeData === null || shippingFeeData === void 0 ? void 0 : shippingFeeData.hasShippingFee;

      if (!zoneSupported) {
        return false;
      }

      if (hasShippingFee) {
        return false;
      }

      if (rate > 0) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});
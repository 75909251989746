define("shop/pods/not-found-404/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cRGXuZ0v",
    "block": "[[[8,[39,0],null,[[\"@website\",\"@shop\",\"@hasBreadcrumbs\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"shop\"]],false]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,[[\"@title\",\"@translate\",\"@message\",\"@headerClassNames\",\"@messageClassNames\"],[\"404\",false,[28,[37,3],[\"ooops, page not found\"],null],\"flex-container--justify-center\",\"has-text-centered\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"footer\"],[14,0,\"card-footer\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"card-footer-item\"],[12],[1,\"\\n            \"],[8,[39,4],[[24,0,\"button button--solid button--action\"]],[[\"@route\"],[\"website\"]],[[\"default\"],[[[[1,\"\\n              \"],[1,[28,[35,3],[\"go to home page\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"website/website-container\",\"layouts/page-content\",\"layouts/message-card\",\"tt\",\"link-to\"]]",
    "moduleName": "shop/pods/not-found-404/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
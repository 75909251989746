define("shop/pods/blogs/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zFBXkYNg",
    "block": "[[[8,[39,0],null,[[\"@website\",\"@params\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"params\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@page\",\"@shop\",\"@website\"],[[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"section checkout-form\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@hasSearchBar\",\"@model\",\"@onUpdateFilter\",\"@pageComponent\",\"@updateUrlParams\",\"@hasTagsFilters\",\"@hasCategoriesFilters\",\"@hasAdvancedFilters\"],[true,[30,0,[\"model\"]],[28,[37,3],[[30,1,[\"fetchTask\"]]],null],[30,1],true,[30,0,[\"model\",\"website\",\"_data\",\"isShowBlogsTags\"]],false,false]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,4],null,[[\"@isRunning\",\"@showWhenRunning\"],[[30,1,[\"fetchTask\",\"isRunning\"]],false]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@blogs\",\"@filtersComponent\",\"@pageComponent\",\"@website\"],[[30,1,[\"filterModel\",\"results\"]],[30,3],[30,1],[30,0,[\"model\",\"website\"]]]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"]],[\"pageComponent\",\"websiteContainer\",\"filtersComponent\"],false,[\"blogs/page-content\",\"website/website-container\",\"filters\",\"perform\",\"helpers/is-running\",\"blogs/blogs-group\"]]",
    "moduleName": "shop/pods/blogs/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
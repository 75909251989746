define("shop/pods/components/items/image-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XN57EaQy",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[16,0,[29,[\"has-animation-quick tag tag-in-grids  \",[52,[30,2],\"image-tag--hidden\"]]]],[17,3],[4,[38,1],[[30,0,[\"setupTag\"]]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,1],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"tag-label\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[[30,4]],[[\"translate\"],[[30,5]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[10,0],[14,0,\"timer-in-tag__container\"],[12],[1,\"\\n        \"],[8,[39,3],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@showTag\",\"@hasAnimation\",\"&attrs\",\"@label\",\"@translate\",\"@hasCountdownTimer\"],false,[\"if\",\"did-insert\",\"tt\",\"countdown-timer\"]]",
    "moduleName": "shop/pods/components/items/image-tag/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
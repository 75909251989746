define("shop/pods/shop/item/route", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "rsvp", "ramda"], function (_exports, _route, _service, _object, _rsvp, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    websiteService: (0, _service.inject)('website'),
    shopService: (0, _service.inject)('shop'),
    queryParams: {
      variant: {
        refreshModel: true
      }
    },

    async model(params) {
      var _shop$_data;

      const shop = this.modelFor('shop').shop;
      const website = this.modelFor('application').website;
      const parentParams = this.paramsFor('shop');
      const itemKey = params.item_id;
      const isCampaign = this.modelFor('application').isCampaign;

      if (shop !== null && shop !== void 0 && (_shop$_data = shop._data) !== null && _shop$_data !== void 0 && _shop$_data.isExpired) {
        const url = shop.get('url');
        return this.transitionTo('shop', url);
      }

      try {
        const item = await this.shopService.get('getShopItemTask').perform({
          shop,
          itemKey: itemKey,
          tag: parentParams.tag,
          query: parentParams.query
        });
        const itemName = (item === null || item === void 0 ? void 0 : item.itemName) || '';

        if (itemName) {
          this.websiteService.updateTitle(itemName);
        }

        this.websiteService.updateCanonicalUrl(item);
        return _rsvp.default.hash({
          isCampaign,
          shop,
          item,
          website,
          params,
          parentParams
        });
      } catch (e) {
        const url = website === null || website === void 0 ? void 0 : website.shopUrlDisplay;
        this.transitionTo('shop', url);
      }
    },

    redirect(model) {
      const itemKey = R.pathOr('', ['item', '_data', 'itemKey'])(model);

      if (!itemKey) {
        const website = model === null || model === void 0 ? void 0 : model.website;
        const url = website === null || website === void 0 ? void 0 : website.shopUrlDisplay;
        this.transitionTo('shop', url);
        this.websiteService.setErrors({}, {
          errorMessage: 'no product found'
        });
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/website/index/route", ["exports", "@ember/routing/route", "rsvp", "ember-concurrency", "@ember/object"], function (_exports, _route, _rsvp, _emberConcurrency, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _route.default.extend((_obj = {
    async model(params) {
      this.websiteService.getPageModelData(this, params);
      return this._setup.perform(params);
    },

    _setup: (0, _emberConcurrency.task)(function* (params) {
      const pageUrl = params.page_url || '';
      let website = this.modelFor('application').website;

      if (!website) {
        website = yield this.websiteService.get('getWebsiteTask').perform({
          params
        });
      }

      const shop = this.modelFor('application').shop;
      const parentParams = this.paramsFor('application');
      const page = website.getPage(pageUrl);
      return _rsvp.default.hash({
        website,
        shop,
        page,
        params,
        parentParams
      });
    }),

    willTransition() {
      this.websiteService.saveSnapshot();
    }

  }, (_applyDecoratedDescriptor(_obj, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_obj, "willTransition"), _obj)), _obj));

  _exports.default = _default;
});
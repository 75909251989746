define("shop/pods/components/tabs/headers/tab/component", ["exports", "@glimmer/component", "ramda", "@ember/service", "@ember/object", "shop/utils/nventor", "@glimmer/tracking"], function (_exports, _component, R, _service, _object, _nventor, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TabsHeaderTabComponent = (_dec = (0, _service.inject)('header'), _dec2 = (0, _service.inject)('website'), (_class = class TabsHeaderTabComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "headerService", _descriptor3, this);

      _initializerDefineProperty(this, "websiteService", _descriptor4, this);

      _initializerDefineProperty(this, "target", _descriptor5, this);

      _defineProperty(this, "id", _nventor.default.random.alpha(2));
    }

    didInsert(element) {// console.log('target start')
      // setTimeout(() => {
      //   this.target = element.offsetTop || 0
      //   console.log('target', this.target)
      // }, 5000)
    }

    get translate() {
      return R.pathOr(true, ['args', 'translate'])(this);
    }

    get label() {
      var _this$args2, _this$args2$tab;

      if (this.translate) {
        var _this$args, _this$args$tab;

        return this.intl.t(this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$tab = _this$args.tab) === null || _this$args$tab === void 0 ? void 0 : _this$args$tab.name);
      }

      return (this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$tab = _this$args2.tab) === null || _this$args2$tab === void 0 ? void 0 : _this$args2$tab.name) || '';
    }

    get isActive() {
      var _this$args3, _this$args4, _this$args4$tab;

      const isActiveUsingRoute = (this === null || this === void 0 ? void 0 : (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.isActiveUsingRoute) || false;

      if (!isActiveUsingRoute) {
        return false;
      }

      const currentRoute = this.router.currentRouteName;
      const linkTo = this === null || this === void 0 ? void 0 : (_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : (_this$args4$tab = _this$args4.tab) === null || _this$args4$tab === void 0 ? void 0 : _this$args4$tab.linkTo;

      if (currentRoute === linkTo) {
        return true;
      }

      return false;
    }

    onClick(event) {
      if (this.args.setTabActive) {
        const tabs = this.args.tabs;
        const tab = this.args.tab;
        this.args.setTabActive(tabs, tab);
      }

      const itemPageTabs = document.querySelector('.tabs-under-container.item-page');

      if (itemPageTabs) {
        var _this$headerService;

        const navbarHeight = ((_this$headerService = this.headerService) === null || _this$headerService === void 0 ? void 0 : _this$headerService.navBarHeightPlusTimer) || 0;
        const topBarHeight = navbarHeight + 50;
        const drawerCardBody = document.querySelector('.modal-card-body');

        if (drawerCardBody) {
          const tabContent = document.querySelector('.checkout-form.item-page.is-active');
          drawerCardBody.scrollBy({
            top: tabContent.getBoundingClientRect().y - topBarHeight,
            left: 0,
            behavior: 'smooth'
          });
        } else {
          window.$('html, body').animate({
            scrollTop: window.$(`.checkout-form.item-page.is-active`).offset().top - topBarHeight
          }, 200);
        }

        return;
      }

      const defaultFaqTabContents = document.querySelector('.tab-contents-up');

      if (defaultFaqTabContents) {
        _nventor.default.goToClassName('tab-contents-up', -150);
      } // in advance-template


      const AdvanceFaqTabContents = document.querySelector('.checkout-form');

      if (AdvanceFaqTabContents) {
        _nventor.default.goToClassName('checkout-form', -150);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "headerService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "target", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = TabsHeaderTabComponent;
});
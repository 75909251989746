define("shop/pods/components/items/item-customizations-selectors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9/EEwOlh",
    "block": "[[[10,0],[14,0,\"flex-container--align-items-center flex-container--justify-center flex-container-column\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"customizationsProxy\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@item\",\"@itemStatus\",\"@onSelect\",\"@shop\",\"@customization\"],[[30,2],[30,3],[30,0,[\"select\"]],[30,4],[30,1]]],null],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"customization\",\"@item\",\"@itemStatus\",\"@shop\"],false,[\"each\",\"-track-array\",\"items/item-customizations-selectors/options-selectors\"]]",
    "moduleName": "shop/pods/components/items/item-customizations-selectors/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
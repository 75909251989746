define("shop/helpers/isArray", ["exports", "ramda", "@ember/component/helper"], function (_exports, R, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    compute(params) {
      const value = params[0] || false;
      return R.is(Array, value);
    }

  });

  _exports.default = _default;
});
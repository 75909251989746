define("shop/pods/shop/checkout/3ds/route", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    websiteService: (0, _service.inject)('website'),
    queryParams: {
      auth_code: {
        refreshModel: false
      }
    },

    model(params) {
      params.partialPath = 'card/3ds';
      this.websiteService.checkoutCompleteTask.perform(params);
    }

  });

  _exports.default = _default;
});
define("shop/pods/members/member/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DCZVWdrj",
    "block": "[[[10,0],[14,0,\"container  margin-top--20 checkout-form\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"model\",\"member\",\"website\",\"prevPage\",\"nextPage\"],[[30,0,[\"model\"]],[33,1,[\"website\",\"user\"]],[33,1,[\"website\"]],\"members\",\"members.member\"]]]],[1,\"\\n\"],[13]],[],false,[\"members/management-page\",\"model\"]]",
    "moduleName": "shop/pods/members/member/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
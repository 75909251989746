define("shop/pods/components/elements/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DfE3d7Al",
    "block": "[[[11,1],[16,0,[29,[\"search-bar__outer-container flex-container-row flex-container--align-items-center flex-container--justify-center\\n  \",[52,[30,0,[\"isSearchBarVisible\"]],\"is-visible is-expanded\",\"is-not-visible\"]]]],[17,1],[12],[1,\"\\n  \"],[10,1],[15,0,[29,[\"search-bar__container has-animation-quick flex-container-row flex-container--align-items-center\\n    \",[52,[30,0,[\"isSearchBarVisible\"]],\"is-visible is-expanded\",\"is-not-visible\"]]]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\"],[\"fas fa-search\"]],null],[1,\"\\n\\n    \"],[8,[39,2],[[16,0,[29,[\"search-bar__input \",[52,[30,0,[\"isSearchBarVisible\"]],\"is-expanded\"]]]],[16,\"placeholder\",[30,0,[\"placeholder\"]]]],[[\"@value\",\"@enter\"],[[30,0,[\"query\"]],[28,[37,3],[[30,0,[\"searchTask\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"query\"]],[[[1,\"      \"],[11,3],[24,0,\"search-bar__clear-btn\"],[4,[38,4],[\"click\",[30,0,[\"onReset\"]]],null],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@icon\"],[\"fas fa-times-circle\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"query\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"classNames\",\"label\",\"isRunning\",\"onSubmit\"],[\"search-bar__search-btn button--action button--solid button--no-border\",\"search\",[30,0,[\"searchTask\",\"isRunning\"]],[28,[37,3],[[30,0,[\"searchTask\"]]],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,6],[[30,0,[\"isSearchBarVisible\"]],[30,2]],null],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"classNames\",\"label\",\"onSubmit\"],[\"button--small button--no-border search-bar__cancel-btn\",\"clear\",[28,[37,7],[[30,0,[\"onCancel\"]]],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"@hasSearchToggle\"],false,[\"if\",\"elements/element-icon\",\"input\",\"perform\",\"on\",\"elements/element-btn\",\"and\",\"fn\"]]",
    "moduleName": "shop/pods/components/elements/search-bar/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/orders/index/route", ["exports", "@ember/routing/route", "shop/mixins/orders-query", "shop/utils/nventor"], function (_exports, _route, _ordersQuery, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_ordersQuery.default, {
    queryParams: {
      email: {
        refreshModel: false
      },
      ref: {
        refreshModel: false
      },
      errors: {
        refreshModel: false
      }
    },

    async model(params) {
      const website = this.modelFor('application').website;
      const currentPage = website.getDynamicPage('orders');

      if (_nventor.default.isNilOrEmpty(currentPage)) {
        return this.transitionTo('/not-found-404');
      }

      return this._model(params);
    },

    redirect(model) {
      this._redirect(model);
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/news/news-article/component", ["exports", "@glimmer/component", "shop/utils/nventor"], function (_exports, _component, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NewsNewsArticleComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "nameLimit", 45);

      _defineProperty(this, "descriptionLimit", 65);
    }

    get colorRgba() {
      return _nventor.default.hexToRGBA('#fff');
    }

    get imgWidth() {
      return this.args.width || 64;
    }

    get imgHeight() {
      return this.imgWidth;
    }

    get fourByThreeHeight() {
      const imgWidth = this.imgWidth;
      return Math.round(imgWidth * 3 / 4, 0);
    }

    get imgSrcTransformUrl() {
      const imgWidth = this.imgWidth;
      const imgHeight = this.imgHeight;
      return `/-/scale_crop/${imgWidth}x${imgHeight}/center/`;
    }

    get imgSrcsetTransformUrl() {
      const imgWidth = parseInt(this.imgWidth);
      const width2x = imgWidth * 2;
      const imgHeight = this.imgHeight * 2;
      return `/-/scale_crop/${width2x}x${imgHeight}/center/-/quality/lighter/ 2x`;
    }

  }

  _exports.default = NewsNewsArticleComponent;
});
define("shop/pods/components/items/item-page/component", ["exports", "ramda", "@ember/component", "@ember/service", "@ember/object", "shop/mixins/automations"], function (_exports, R, _component, _service, _object, _automations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_automations.default, (_obj = {
    googleService: (0, _service.inject)('google'),
    websiteService: (0, _service.inject)('website'),
    seoService: (0, _service.inject)('seo'),
    dateService: (0, _service.inject)('date'),
    itemService: (0, _service.inject)('item'),
    tagName: 'div',
    classNames: [],
    selectedImages: null,
    choicesComplete: false,
    hasThumbsSlider: false,
    router: (0, _service.inject)(),

    init() {
      var _this$item;

      this._super(...arguments);

      this.set('selectedImages', []);
      const item = this.item;
      const defaultImageData = (item === null || item === void 0 ? void 0 : item.defaultImageData) || {};
      this.selectImage(defaultImageData);
      const cartItems = this.get('shop.cartItems') || [];

      if (!R.isEmpty(cartItems)) {
        this.shopService.get('validateCartItemsTask').perform({
          shop: this.shop
        });
      }

      this.seoService.setupStructuredData({
        structuredData: [{
          data: this.item,
          type: 'product',
          additionalData: {
            status: (this === null || this === void 0 ? void 0 : (_this$item = this.item) === null || _this$item === void 0 ? void 0 : _this$item._data) || {}
          }
        }, {
          data: (this === null || this === void 0 ? void 0 : this.shop) || {},
          type: 'shop',
          additionalData: {
            website: this === null || this === void 0 ? void 0 : this.website
          }
        }, {
          data: (this === null || this === void 0 ? void 0 : this.shop) || {},
          type: 'faq'
        }]
      });
      const boundToggleMobileFooter = this.toggleMobileFooter.bind(this);
      this.set('boundToggleMobileFooter', boundToggleMobileFooter);
    },

    didInsertElement() {
      var _this$item2;

      this._super(...arguments);

      this.toggleMobileFooter();
      const showAsModal = (this === null || this === void 0 ? void 0 : this.showAsModal) || false;

      if (showAsModal) {
        const drawerCardBodyElement = document.querySelector('.modal-card-body');

        if (drawerCardBodyElement) {
          drawerCardBodyElement.addEventListener('scroll', this.boundToggleMobileFooter);
        }
      } else {
        document.addEventListener('scroll', this.boundToggleMobileFooter);
      }

      const imageContainer = document.querySelector('.store-item__page-image-container');
      const thumbsContainer = document.querySelector('.store-item__page-thumbs-container');
      let browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (browserWidth > 450) {
        browserWidth = 450;
      }

      const thumbsContainerWidth = thumbsContainer === null || thumbsContainer === void 0 ? void 0 : thumbsContainer.offsetWidth;

      if (thumbsContainerWidth > browserWidth) {
        this.set('hasThumbsSlider', true);
      } else {
        this.set('hasThumbsSlider', false);
      }

      if (thumbsContainer) {
        thumbsContainer.style.maxWidth = `${browserWidth}px`;
      }

      this.set('width', browserWidth);
      imageContainer.style.maxWidth = `${browserWidth}px`; // Track item view

      this.googleService.viewItem(this === null || this === void 0 ? void 0 : (_this$item2 = this.item) === null || _this$item2 === void 0 ? void 0 : _this$item2._data);
      const item = this === null || this === void 0 ? void 0 : this.item;
      this.itemService.isUnlocked(item);
    },

    willDestroyElement() {
      if (this.isDestroyed) {
        return;
      }

      const showAsModal = (this === null || this === void 0 ? void 0 : this.showAsModal) || false;

      if (showAsModal) {
        const drawerCardBodyElement = document.querySelector('.modal-card-body');

        if (drawerCardBodyElement) {
          drawerCardBodyElement.removeEventListener('scroll', this.boundToggleMobileFooter);
        }

        return;
      }

      document.removeEventListener('scroll', this.boundToggleMobileFooter);
    },

    toggleMobileFooter() {
      const addToCartContainer = document.querySelector('.add-to-cart__container .cart-add-btn');
      const mobileActionFooter = document.querySelector('.mobile-action-footer');

      if (!addToCartContainer) {
        return;
      }

      const isAddToCartContainerInView = this.elementInViewport(addToCartContainer);

      if (isAddToCartContainerInView) {
        var _mobileActionFooter$c;

        mobileActionFooter === null || mobileActionFooter === void 0 ? void 0 : (_mobileActionFooter$c = mobileActionFooter.classList) === null || _mobileActionFooter$c === void 0 ? void 0 : _mobileActionFooter$c.add('u-hidden');
      } else {
        var _mobileActionFooter$c2;

        mobileActionFooter === null || mobileActionFooter === void 0 ? void 0 : (_mobileActionFooter$c2 = mobileActionFooter.classList) === null || _mobileActionFooter$c2 === void 0 ? void 0 : _mobileActionFooter$c2.remove('u-hidden');
      }
    },

    elementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    },

    isShowingMobileFooter: (0, _object.computed)('this.isAddToCartContainerInView', function () {
      if (this.isAddToCartContainerInView) {
        return false;
      }

      return true;
    }),

    setProductRichResultsSeo(itemStatus) {
      this.seoService.setProductRichResultsSeo(this.item, {
        itemStatus
      });
    },

    isShowBulkDiscountDescriptions: (0, _object.computed)('bulkDiscountData.isShowDescriptions', function () {
      let isShowDescriptions = this.get('bulkDiscountData.isShowDescriptions');

      if (R.isNil(isShowDescriptions) || R.isEmpty(isShowDescriptions)) {
        isShowDescriptions = true;
      }

      return isShowDescriptions;
    }),
    isHidePrice: (0, _object.computed)('item._data.{isHidePriceWhenHasPassword,_key}', 'isUnlocked', function () {
      var _this$item3, _this$item3$_data, _this$item4, _this$item4$_data;

      const isUnlocked = this === null || this === void 0 ? void 0 : this.isUnlocked;
      const isHidePriceWhenHasPassword = (this === null || this === void 0 ? void 0 : (_this$item3 = this.item) === null || _this$item3 === void 0 ? void 0 : (_this$item3$_data = _this$item3._data) === null || _this$item3$_data === void 0 ? void 0 : _this$item3$_data.isHidePriceWhenHasPassword) || false;
      const itemKey = this === null || this === void 0 ? void 0 : (_this$item4 = this.item) === null || _this$item4 === void 0 ? void 0 : (_this$item4$_data = _this$item4._data) === null || _this$item4$_data === void 0 ? void 0 : _this$item4$_data._key;

      if (isHidePriceWhenHasPassword && !isUnlocked) {
        return true;
      }

      return false;
    }),
    bulkDiscountRulesList: (0, _object.computed)('bulkDiscountData.{schedules.[],status}', 'membersService.tokenData.memberLevelId', 'shop.{rewardData,_data.rewardData}', function () {
      const nowZ = this.dateService.getNowZ();
      const memberLevelId = this.get('membersService.tokenData.memberLevelId') || false;
      const rewardData = this.get('shop._data.rewardData') || this.get('shop.rewardData') || {};
      const memberLevels = R.propOr([], 'memberLevels')(rewardData) || [];
      const schedules = this.get('bulkDiscountData.schedules') || [];
      const bulkDiscountStatus = this.get('bulkDiscountData.status') || 'enabled';

      if (!R.isEmpty(schedules) && bulkDiscountStatus === 'enabled') {
        return R.pipe(R.find(schedule => {
          const dateStartZ = schedule.dateStartZ || '';
          const dateEndZ = schedule.dateEndZ || '';

          if (dateStartZ && !dateEndZ && dateStartZ <= nowZ) {
            return true;
          }

          if (dateEndZ && !dateStartZ && dateEndZ >= nowZ) {
            return true;
          }

          if (dateStartZ <= nowZ && dateEndZ >= nowZ) {
            return true;
          }

          return false;
        }), R.propOr([], 'rules'), R.filter(rule => {
          const status = rule.status || 'active';
          const hasMemberLevels = R.propOr(false, 'hasMemberLevels')(rule);

          if (!hasMemberLevels || R.isEmpty(rewardData)) {
            return true;
          }

          if (memberLevelId === false) {
            return false;
          }

          if (hasMemberLevels && R.isEmpty(rewardData)) {
            return false;
          }

          const hasMatchMemberLevel = R.pipe(R.find(R.propEq('memberLevelId', memberLevelId)), R.isNil, R.not)(memberLevels);

          if (hasMatchMemberLevel && status === 'active') {
            return true;
          }

          return false;
        }), R.reject(R.isNil), R.sortBy(R.prop('value')))(schedules);
      }

      return [];
    }),
    isSetWithChoicesOrHasVariants: (0, _object.computed)('item._data.{isSetWithChoices,hasVariants}', function () {
      if (this.get('item._data.isSetWithChoices')) {
        return true;
      }

      if (this.get('item._data.hasVariants')) {
        return true;
      }

      return false;
    }),
    currentProductTags: (0, _object.computed)('item._data.productTags', 'shop._data.productTags', function () {
      if (this.get('shop._data.groupByTags')) {
        const allTags = this.get('shop._data.productTags') || [];
        const tags = this.get('item._data.productTags') || [];

        if (!R.isEmpty(tags)) {
          return R.filter(productTag => R.includes(productTag._key, tags), allTags);
        }
      }

      return [];
    }),
    hasAddbtn: (0, _object.computed)('automation', function () {
      const automation = this.automation;

      if (automation) {
        if (automation.thenAction === 'mayAdd') {
          return true;
        }

        return false;
      }

      return true;
    }),
    itemGroup: (0, _object.computed)('automation', function () {
      if (this.automation) {
        return 'automations';
      }

      return 'cartItems';
    }),
    hasBackToCheckout: (0, _object.computed)('automation', function () {
      const automation = this.automation;

      if (automation) {
        return true;
      }

      return false;
    }),
    hideWhenItemDetailRoute: (0, _object.computed)('router.currentRouteName', function () {
      const currentRoute = this.get('router').get('currentRouteName');

      if (currentRoute === 'shop.detail') {
        return false;
      }

      return true;
    }),
    bulkDiscountData: (0, _object.computed)('shop.bulkDiscounts', 'item.bulkDiscountId', function () {
      const bulkDiscounts = this.get('shop.bulkDiscounts') || [];
      const bulkDiscountId = this.get('item.bulkDiscountId') || '';
      return R.find(R.propEq('bulkDiscountId', bulkDiscountId))(bulkDiscounts);
    }),

    selectImage(image) {
      this.set('selectedImages', [image]);
    },

    goToTop() {
      // @TODO: is this used?
      const body = window.$('html, body');
      body.stop().animate({
        scrollTop: 0
      }, 500, 'swing');
    },

    updateCartItemQty(group, cartItem, qty) {
      this.shop.updateCartItemQty({
        group,
        cartItem,
        qty
      });
      this.shopService.get('validateCartItemsTask').perform({
        shop: this.shop,
        forceValidation: true
      });
    },

    _getDefaultFromUploads(uploadedFiles = []) {
      if (uploadedFiles.length > 0) {
        return R.find(R.propOr(true, 'isDefault'))(uploadedFiles) || uploadedFiles[0];
      }

      return;
    },

    onSelectVariant(itemSelected = {}) {
      const uploadedFiles = (itemSelected === null || itemSelected === void 0 ? void 0 : itemSelected.uploadedFiles) || [];

      let defaultUploadedFile = this._getDefaultFromUploads(uploadedFiles);

      if (!defaultUploadedFile) {
        var _this$item5, _this$item5$_data;

        const modelUploadedFiles = (this === null || this === void 0 ? void 0 : (_this$item5 = this.item) === null || _this$item5 === void 0 ? void 0 : (_this$item5$_data = _this$item5._data) === null || _this$item5$_data === void 0 ? void 0 : _this$item5$_data.uploadedFiles) || [];
        defaultUploadedFile = this._getDefaultFromUploads(modelUploadedFiles);
      }

      if (defaultUploadedFile) {
        this.selectImage(defaultUploadedFile);
      }

      return;
    },

    scrollToItemPageContent() {
      let itemPageContent = document.querySelector('.store-item__page-content');

      if (itemPageContent) {
        itemPageContent.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "willDestroyElement", [_object.action], Object.getOwnPropertyDescriptor(_obj, "willDestroyElement"), _obj), _applyDecoratedDescriptor(_obj, "toggleMobileFooter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleMobileFooter"), _obj), _applyDecoratedDescriptor(_obj, "elementInViewport", [_object.action], Object.getOwnPropertyDescriptor(_obj, "elementInViewport"), _obj), _applyDecoratedDescriptor(_obj, "setProductRichResultsSeo", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setProductRichResultsSeo"), _obj), _applyDecoratedDescriptor(_obj, "selectImage", [_object.action], Object.getOwnPropertyDescriptor(_obj, "selectImage"), _obj), _applyDecoratedDescriptor(_obj, "goToTop", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToTop"), _obj), _applyDecoratedDescriptor(_obj, "updateCartItemQty", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updateCartItemQty"), _obj), _applyDecoratedDescriptor(_obj, "_getDefaultFromUploads", [_object.action], Object.getOwnPropertyDescriptor(_obj, "_getDefaultFromUploads"), _obj), _applyDecoratedDescriptor(_obj, "onSelectVariant", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onSelectVariant"), _obj), _applyDecoratedDescriptor(_obj, "scrollToItemPageContent", [_object.action], Object.getOwnPropertyDescriptor(_obj, "scrollToItemPageContent"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/shop/checkout/failed/route", ["exports", "@ember/routing/route", "rsvp", "@ember/service"], function (_exports, _route, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    websiteService: (0, _service.inject)('website'),

    model() {
      const shop = this.modelFor('shop').shop;
      const website = this.modelFor('application').website;
      const isCampaign = this.modelFor('application').isCampaign;
      return _rsvp.default.hash({
        shop,
        website,
        isCampaign
      });
    }

  });

  _exports.default = _default;
});
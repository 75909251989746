define("shop/pods/news/article/model", ["exports", "shop/pods/news/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NewsArticleModel extends _model.default {}

  _exports.default = NewsArticleModel;
});
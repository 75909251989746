define("shop/pods/components/shop/checkout/payment-page/referral-code/component", ["exports", "@glimmer/component", "@ember/object", "ember-concurrency-decorators", "@ember/service"], function (_exports, _component, _object, _emberConcurrencyDecorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShopCheckoutPaymentPageReferralCodeComponent = (_dec = (0, _service.inject)('shop'), _dec2 = (0, _service.inject)('members'), _dec3 = (0, _service.inject)('website'), (_class = class ShopCheckoutPaymentPageReferralCodeComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "storage", _descriptor, this);

      _initializerDefineProperty(this, "shopService", _descriptor2, this);

      _initializerDefineProperty(this, "membersService", _descriptor3, this);

      _initializerDefineProperty(this, "websiteService", _descriptor4, this);
    }

    get hasEmail() {
      var _this$args, _user$_data;

      const user = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.user) || {};
      const email = (user === null || user === void 0 ? void 0 : (_user$_data = user._data) === null || _user$_data === void 0 ? void 0 : _user$_data.email) || '';

      if (email) {
        return true;
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "storage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ShopCheckoutPaymentPageReferralCodeComponent;
});
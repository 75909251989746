define("shop/pods/components/layouts/filters/filters-section/component", ["exports", "@ember/component", "@ember/object", "ramda", "shop/utils/nventor"], function (_exports, _component, _object, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    tagName: 'div',
    classNames: ['filters-section__container'],
    translate: true,
    hasCatergories: (0, _object.computed)('pageComponent.filterModel._data.tags', function () {
      const tags = R.pathOr([], ['tags'])(this);
      return !_nventor.default.isNilOrEmpty(tags);
    }),

    goToItemsContainerTop() {
      _nventor.default.goToClassName('store-items__container', -150);
    }

  }, (_applyDecoratedDescriptor(_obj, "goToItemsContainerTop", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToItemsContainerTop"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/components/layouts/page-controls/component", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    websiteService: (0, _service.inject)('website'),
    tagName: 'div',
    classNames: ['page-controls', 'flex-container-row'],
    translate: true,
    isActionBtn: true,
    showAgreeToTerms: false,
    previousLabel: 'previous step',
    cancelLabel: 'cancel',
    nextLabel: 'next step',
    allowNext: true,
    nextBtnClassNames: (0, _object.computed)('isActionBtn', function () {
      const classNames = 'button';

      if (this.isActionBtn) {
        return `${classNames} button--action button--solid`;
      }

      return classNames;
    }),
    hasShopCartValidationError: (0, _object.computed)('shop.{hasCartValidationError,hasOverLimitedQtyPerCheckout}', 'isSummaryStep', function () {
      const shop = (this === null || this === void 0 ? void 0 : this.shop) || {};
      const hasOverLimitedQtyPerCheckout = (shop === null || shop === void 0 ? void 0 : shop.hasOverLimitedQtyPerCheckout) || false;

      if (hasOverLimitedQtyPerCheckout) {
        return true;
      }

      const hasCartValidationError = (shop === null || shop === void 0 ? void 0 : shop.hasCartValidationError) || false;
      const isSummaryStep = (this === null || this === void 0 ? void 0 : this.isSummaryStep) || false;

      if (!isSummaryStep && hasCartValidationError) {
        return true;
      }

      if (shop !== null && shop !== void 0 && shop.hasAutomationsError) {
        return true;
      }

      return false;
    }),
    hasNext: (0, _object.computed)('allowNext', 'onNext', function () {
      if (!this.allowNext) {
        return false;
      }

      if (!this.onNext) {
        return false;
      }

      return true;
    }),

    confirmAgreeToTerms() {
      var _this$onAgreeToTerms;

      (_this$onAgreeToTerms = this.onAgreeToTerms) === null || _this$onAgreeToTerms === void 0 ? void 0 : _this$onAgreeToTerms.call(this);
      this.showAgreeToTermsModal(false);
    },

    showAgreeToTermsModal(bool) {
      this.set('showAgreeToTerms', bool);
    }

  }, (_applyDecoratedDescriptor(_obj, "confirmAgreeToTerms", [_object.action], Object.getOwnPropertyDescriptor(_obj, "confirmAgreeToTerms"), _obj), _applyDecoratedDescriptor(_obj, "showAgreeToTermsModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "showAgreeToTermsModal"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/shop/automation/automationItem/model", ["exports", "ramda", "@ember/object", "shop/mixins/base", "shop/mixins/big", "shop/mixins/date", "shop/utils/nventor"], function (_exports, R, _object, _base, _big, _date, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_base.default, _big.default, _date.default, {
    populate(data) {
      data.cardContentAlign = data.cardContentAlign || 'center'; // default to center

      if (R.type(data.customTags) !== 'Array') {
        data.customTags = [];
      }

      data.customTags.clear();

      if (data.onSale === 'on' || data.onSale === true) {
        data.customTags.pushObject({
          showTag: true,
          positionX: 'left',
          positionY: 'top',
          translate: true,
          theme: 'onSaleTag',
          label: 'onSale',
          hasCountdownTimer: false
        });
      }

      data = {
        _data: data
      };
      this.setProperties(data);
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/members/profile-page/referral/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-concurrency-decorators", "ramda", "shop/utils/nventor"], function (_exports, _component, _object, _tracking, _service, _emberConcurrencyDecorators, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MembersProfilePageReferralComponent = (_dec = (0, _service.inject)('members'), (_class = class MembersProfilePageReferralComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "membersService", _descriptor, this);

      _initializerDefineProperty(this, "storage", _descriptor2, this);

      _initializerDefineProperty(this, "referralCodeSent", _descriptor3, this);

      _initializerDefineProperty(this, "errors", _descriptor4, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor5, this);

      _initializerDefineProperty(this, "recipientEmail", _descriptor6, this);

      _initializerDefineProperty(this, "isShowShareByEmail", _descriptor7, this);

      _initializerDefineProperty(this, "isShowShareByLink", _descriptor8, this);

      _initializerDefineProperty(this, "linkCopyLabel", _descriptor9, this);

      _initializerDefineProperty(this, "codeCopyLabel", _descriptor10, this);
    }

    get referralEventData() {
      var _this$args, _shop$_data, _this$args2, _model$_data;

      const shop = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.shop) || {};
      const rewardData = (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.rewardData) || {};
      const model = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.model) || {};
      const memberLevelId = (model === null || model === void 0 ? void 0 : (_model$_data = model._data) === null || _model$_data === void 0 ? void 0 : _model$_data.memberLevelId) || '';
      const referrerData = this.getReferralRewardEventData({
        rewardData,
        memberLevelId
      });
      const recipientData = this.getReferralRewardEventData({
        rewardData,
        memberLevelId: '' // intentionally empty

      });
      return {
        referrerData,
        recipientData
      };
    } // should be the same as API (rewardsHelper.getReferralRewardEventData)


    getReferralRewardEventData({
      rewardData,
      memberLevelId
    }) {
      const memberLevels = R.propOr([], 'memberLevels')(rewardData);
      const findRewardEvent = R.pipe(R.propOr([], 'rewardsEvents'), R.find(R.propEq('type', 'referral')));

      if (_nventor.default.isNilOrEmpty(memberLevelId)) {
        memberLevelId = R.pipe(R.filter(R.where({
          productTags: _nventor.default.isNilOrEmpty
        })), _nventor.default.safeHeadOr({}), R.propOr('', 'memberLevelId'))(memberLevels);
      }

      let isFound = false;
      const referralRewardEventData = R.pipe(R.clone, R.forEach(memberLevel => {
        const rewardMemberLevelId = R.propOr('', 'memberLevelId')(memberLevel);

        if (!isFound) {
          memberLevel.isSelected = true;
        }

        if (rewardMemberLevelId === memberLevelId) {
          isFound = true;
        }
      }), R.map(memberLevel => {
        const isSelected = R.propEq('isSelected', true)(memberLevel);
        const referralEvent = findRewardEvent(memberLevel) || {};

        if (isSelected && _nventor.default.isNotNilOrEmpty(referralEvent)) {
          return referralEvent;
        }

        return {};
      }), R.reject(_nventor.default.isNilOrEmpty), _nventor.default.safeLastOr({}))(memberLevels);
      return referralRewardEventData;
    }

    get referralCode() {
      var _model$_data2;

      const model = this.args.model || {};
      const referralUrl = (model === null || model === void 0 ? void 0 : (_model$_data2 = model._data) === null || _model$_data2 === void 0 ? void 0 : _model$_data2.referralUrl) || '';
      let referralCode = '';

      if (referralUrl) {
        const referralUrlData = R.split('referral?code=')(referralUrl);
        referralCode = R.pathOr('', [1])(referralUrlData);
      }

      return referralCode;
    }

    *sendReferralRewardTask() {
      const recipientEmail = this.recipientEmail || '';
      const referralCode = this.referralCode;

      if (!recipientEmail) {
        (0, _object.set)(this, 'errors', true);
        (0, _object.set)(this, 'errorMessage', 'cannot send referral code');
      } else {
        const website = this.args.website;
        const shop = this.args.shop;
        const result = yield this.membersService.get('sendReferralRewardTask').perform({
          website,
          shop,
          recipientEmail,
          referralCode
        });
        const hasError = R.pathOr(false, ['data', 'errors'])(result);

        if (hasError) {
          (0, _object.set)(this, 'errors', true);
          (0, _object.set)(this, 'errorMessage', R.pathOr(false, ['data', 'message'])(result));
          (0, _object.set)(this, 'referralCodeSent', false);
        } else {
          (0, _object.set)(this, 'errors', false);
          (0, _object.set)(this, 'referralCodeSent', true);
          (0, _object.set)(this, 'recipientEmail', '');
        }
      }
    }

    copyText(text, label) {
      const el = document.createElement('textarea');
      el.value = text;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      (0, _object.set)(this, label, 'copied');
    }

    showShareByEmail() {
      (0, _object.set)(this, 'isShowShareByEmail', !this.isShowShareByEmail);
      (0, _object.set)(this, 'isShowShareByLink', !this.isShowShareByEmail);
    }

    showShareByLink() {
      (0, _object.set)(this, 'isShowShareByLink', !this.isShowShareByLink);
      (0, _object.set)(this, 'isShowShareByEmail', !this.isShowShareByEmail);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "storage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "referralCodeSent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "recipientEmail", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isShowShareByEmail", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isShowShareByLink", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "linkCopyLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'copy';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "codeCopyLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'copy';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sendReferralRewardTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "sendReferralRewardTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyText", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copyText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showShareByEmail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showShareByEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showShareByLink", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showShareByLink"), _class.prototype)), _class));
  _exports.default = MembersProfilePageReferralComponent;
});
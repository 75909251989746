define("shop/pods/storage/service", ["exports", "@ember/service", "shop/config/environment", "ramda", "shop/utils/nventor"], function (_exports, _service, _environment, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global store */

  /**
   * stores to localstorage using the store.js library.
   * will use other methods in localstorage is not available
   */
  // @TODO: test
  var _default = _service.default.extend({
    websiteService: (0, _service.inject)('website'),
    expiry: 2592000000,

    // ms (3 days)
    init() {
      this._super(...arguments);

      const storageVersion = _environment.default.storageVersion;
      const currentVersionInfo = store.get('storageVersion') || {};
      const currentVersion = R.prop(['val'])(currentVersionInfo) || '';

      if (storageVersion !== currentVersion) {
        store.clearAll();
      }

      store.set('storageVersion', {
        val: storageVersion,
        exp: this.exp,
        time: new Date().getTime()
      });
    },

    /**
     * Add podKey to the token
     * */
    _appendPodKey(key, website = {}) {
      if (!R.isEmpty(website)) {
        const podKey = website.get('podKey');
        return `${podKey}_${key}`;
      }

      return key;
    },

    /**
     * Add shopKey to the token
     * */
    _appendShopKey(key) {
      let shopKey = R.pathOr('', ['websiteService', 'website', 'shop', '_data', '_key'])(this);

      if (!shopKey) {
        shopKey = R.pathOr('', ['websiteService', 'website', 'shopKey'])(this);
      }

      if (shopKey) {
        return `${shopKey}_${key}`;
      }

      return key;
    },

    /**
     * stores to localstorage
     * @param {Object} website
     * @param {String} key
     * @param {Mixin} val
     * @param {Number} exp - expiry in ms
     */
    setValue(key, val, website = {}, exp = this.expiry) {
      const storageKey = this._appendPodKey(key, website);

      try {
        return store.set(storageKey, {
          val: val,
          exp: exp,
          time: new Date().getTime()
        });
      } catch (error) {
        this.websiteService.logErrorTask.perform({
          error,
          localStorage: window.localStorage
        });
        this.clearUnusedCache();
      }
    },

    /**
     * Get value from localstorage
     * @param {string} key
     * @param {?Object} website
     * @return {null|*}
     */
    getValue(key, website = {}) {
      const storageKey = this._appendPodKey(key, website);

      const info = store.get(storageKey);

      if (!info) {
        return null;
      }

      if (new Date().getTime() - info.time > info.exp) {
        // localstorage item has expired, remove and return null
        store.remove(storageKey);
        return null;
      }

      return info.val;
    },

    /**
     * stores to localstorage
     * @param {String} key
     * @param {Mixin} val
     * @param {Number} exp - expiry in ms
     */
    setValueWithShopKey(key, val, exp = this.expiry) {
      try {
        const storageKey = this._appendShopKey(key);

        store.set(storageKey, {
          val: val,
          exp: exp,
          time: new Date().getTime()
        });
      } catch (error) {
        this.websiteService.logErrorTask.perform({
          error,
          localStorage: window.localStorage
        });
        this.clearUnusedCache();
      }
    },

    /**
     * Get value from localstorage
     * @param {string} key
     * @return {null|*}
     */
    getValueWithShopKey(key) {
      const storageKey = this._appendShopKey(key);

      const info = store.get(storageKey);

      if (_nventor.default.isNilOrEmpty(info)) {
        return null;
      }

      if (new Date().getTime() - (info === null || info === void 0 ? void 0 : info.time) > (info === null || info === void 0 ? void 0 : info.exp)) {
        // localstorage item has expired, remove and return null
        store.remove(storageKey);
        return null;
      }

      return info.val;
    },

    /**
     * Remove key value pair
     * @param {string} key
     */
    removeWithShopKey(key) {
      const storageKey = this._appendShopKey(key);

      store.remove(storageKey);
    },

    removeWithPodKey(key, website = {}) {
      const storageKey = this._appendPodKey(key, website);

      store.remove(storageKey);
    },

    remove(key) {
      store.remove(key);
    },

    clearAll() {
      store.clearAll();
    },

    _removeExpiredItems() {
      R.pipe(R.propOr({}, 'localStorage'), R.omit(['clear', 'getItem', 'key', 'removeItem', 'setItem', 'length']), R.forEachObjIndexed((value, key) => {
        try {
          const info = store.get(key);

          if (_nventor.default.isNilOrEmpty(info)) {
            return null;
          }

          if (new Date().getTime() - (info === null || info === void 0 ? void 0 : info.time) > (info === null || info === void 0 ? void 0 : info.exp)) {
            // localstorage item has expired, remove and return null
            store.remove(key);
            return null;
          }
        } catch (error) {
          console.log('cannot remove key', key);
        }
      }))(window);
    },

    _removeItems() {
      R.pipe(R.propOr({}, 'localStorage'), R.omit(['user', 'checkoutData', 'locale', 'token', 'storageVersion', 'clear', 'getItem', 'key', 'removeItem', 'setItem', 'length']), R.forEachObjIndexed((value, key) => {
        try {
          const info = store.get(key);

          if (_nventor.default.isNilOrEmpty(info)) {
            return null;
          }

          store.remove(key);
        } catch (error) {
          console.log('cannot remove key', key);
        }
      }))(window);
    },

    clearUnusedCache() {
      this._removeExpiredItems();

      this._removeItems();
    }

  });

  _exports.default = _default;
});
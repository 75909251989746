define("shop/pods/components/members/management-page/component", ["exports", "@ember/component", "ember-concurrency", "@ember/service", "@ember/object", "ramda"], function (_exports, _component, _emberConcurrency, _service, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    membersService: (0, _service.inject)('members'),
    disableAllEditing: false,
    // deleteCcTask: task(function * (creditCard) {
    //   const msg = this.intl.t('are you sure you want to delete stored credit card')
    //   if (window.confirm(msg)) {
    //     const website = this.website
    //     yield this.membersService
    //       .get('deleteCcTask')
    //       .perform(website, {
    //         ccNumber: creditCard.ccNumber,
    //         ccExpiry: creditCard.ccExpiry
    //       })
    //   }
    // }).drop(),
    isShopRoute: (0, _object.computed)('router.currentRouteName', function () {
      const routeName = this.router.get('currentRouteName');

      if (R.startsWith('shop', routeName)) {
        return true;
      }

      return false;
    }),
    isShopIndexRoute: (0, _object.computed)('router.currentRouteName', function () {
      const routeName = this.router.get('currentRouteName');

      if (R.startsWith('shop.index', routeName)) {
        return true;
      }

      return false;
    }),
    availableCards: (0, _object.computed)('shop.{isPayuni,isTapPay},member._data.paymentMethods', function () {
      var _this$website, _this$member, _this$member$_data;

      const shop = (this === null || this === void 0 ? void 0 : (_this$website = this.website) === null || _this$website === void 0 ? void 0 : _this$website.shop) || {};
      const paymentMethods = ((_this$member = this.member) === null || _this$member === void 0 ? void 0 : (_this$member$_data = _this$member._data) === null || _this$member$_data === void 0 ? void 0 : _this$member$_data.paymentMethods) || [];
      const isPayuni = (shop === null || shop === void 0 ? void 0 : shop.isPayuni) || false;

      if (isPayuni) {
        return R.filter(R.propEq('provider', 'payuni'))(paymentMethods);
      }

      const isTapPay = (shop === null || shop === void 0 ? void 0 : shop.isTapPay) || false;

      if (isTapPay) {
        return R.filter(R.propEq('provider', 'tapPay'))(paymentMethods);
      }

      return [];
    }),

    goHome() {
      if (this.websiteService.get('isBigOrdr') && this.isShopRoute) {
        return this.router.transitionTo('shop');
      }

      if (this.isShopRoute) {
        return this.router.transitionTo('shop', {
          queryParams: {
            tags: null
          }
        });
      } else {
        return this.router.transitionTo('/');
      }
    },

    actions: {
      onBackAction() {
        return this.router.transitionTo('shop', {
          queryParams: {
            tags: null
          }
        });
      },

      logout() {
        const website = this.website;
        this.membersService.logout(website);
        this.goHome();
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/campaign/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t4+PLjqE",
    "block": "[[[8,[39,0],null,[[\"@isIndex\",\"@shop\"],[true,[30,0,[\"model\"]]]],null],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"hero is-light\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"hero-body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"title\"],[12],[1,\"\\n        \"],[1,[30,0,[\"model\",\"_data\",\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"subtitle\"],[12],[1,\"\\n        \"],[1,[30,0,[\"model\",\"_data\",\"description\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[12],[1,[28,[35,1],[\"limited_campaign\"],null]],[13],[1,\"\\n      \"],[10,2],[14,0,\"subtitle has-text-danger is-bold\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[[28,[37,3],[[30,0,[\"model\",\"_data\",\"dateStartZ\"]]],null],\" ~ \",[28,[37,3],[[30,0,[\"model\",\"_data\",\"dateEndZ\"]]],null],\" (\",[28,[37,1],[\"campaign expired\"],null],\")\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"section\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@content\"],[[30,0,[\"model\",\"_data\",\"bankingDetails\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"layouts/nav-bar\",\"tt\",\"concat\",\"date\",\"content-display\"]]",
    "moduleName": "shop/pods/campaign/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
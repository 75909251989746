define("shop/pods/components/shop/tags-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/qycmkut",
    "block": "[[[41,[33,1,[\"_data\",\"groupByTags\"]],[[[1,\"  \"],[10,0],[14,0,\"tags-nav-bar__container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tags-menu__container\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"tags-menu__ul\"],[12],[1,\"\\n        \\n\"],[6,[39,2],null,[[\"classNames\",\"query\",\"route\",\"model\"],[\"tags-menu__li tag-menu\",[28,[37,3],null,[[\"tags\",\"query\"],[null,null]]],\"shop\",[33,4,[\"shopUrlDisplay\"]]]],[[\"default\"],[[[[1,\"          \"],[1,[28,[35,5],[\"home\"],null]],[1,\"\\n\"]],[]]]]],[1,\"        \\n\"],[42,[28,[37,7],[[28,[37,7],[[33,1,[\"_data\",\"productTags\"]]],null]],null],null,[[[41,[28,[37,8],[[33,9],[30,1,[\"label\"]]],null],[[[1,\"            \"],[10,\"li\"],[14,0,\"tags-menu__li tag-menu tag-menu--active\"],[12],[1,\"\\n              \"],[1,[30,1,[\"label\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[6,[39,2],null,[[\"tagName\",\"classNames\",\"query\",\"route\",\"model\"],[\"li\",\"tags-menu__li tag-menu\",[28,[37,3],null,[[\"tags\",\"query\"],[[30,1,[\"label\"]],null]]],\"shop\",[33,4,[\"shopUrlDisplay\"]]]],[[\"default\"],[[[[1,\"              \"],[1,[30,1,[\"label\"]]],[1,\"\\n\"]],[]]]]]],[]]]],[1]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[33,10],[[[1,\"        \"],[10,0],[14,0,\"tags-menu__has-more\"],[12],[1,\"\\n          \"],[11,3],[24,0,\"tag-menu\"],[4,[38,11],[[30,0],\"scrollRight\"],null],[12],[1,\"\\n            \"],[8,[39,12],null,[[\"@icon\"],[\"fas fa-chevron-right\"]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"productTag\"],false,[\"if\",\"shop\",\"link-to\",\"-hash\",\"website\",\"tt\",\"each\",\"-track-array\",\"eq\",\"tag\",\"hasOverflow\",\"action\",\"elements/element-icon\"]]",
    "moduleName": "shop/pods/components/shop/tags-menu/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
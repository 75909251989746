define("shop/pods/shop/detail/route", ["exports", "@ember/routing/route", "shop/pods/shop/item/model", "@ember/service", "rsvp"], function (_exports, _route, _model, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ajax: (0, _service.inject)(),
    shopService: (0, _service.inject)('shop'),
    imagesService: (0, _service.inject)('images'),

    // fastboot: service(),
    async model(params) {
      const website = this.modelFor('application').website;
      const shop = this.modelFor('shop').shop;
      const url = shop.get('url');

      try {
        const campaignDetailKey = params.item_id;
        const itemKey = params.item_id;
        const automationKey = params.automation_id;
        const automationDetail = await this.shopService.get('getShopItemTask').perform({
          shop,
          itemKey: itemKey,
          data: {
            isAutomation: true,
            automationKey
          }
        });
        const automations = shop.get('automations') || [];
        const automation = automations.findBy('_key', automationKey); // const details = automation.details || []
        // const detail = details.findBy('_data.itemKey', itemKey)

        const item = _model.default.create(automationDetail);

        item.set('imagesService', this.imagesService);
        return _rsvp.default.hash({
          shop,
          item,
          website,
          automation
        });
      } catch (e) {
        this.transitionTo('shop.checkout.summary', url);
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/elements/modal-dialog/component", ["exports", "@ember/component", "@ember/object", "ember-concurrency-decorators", "@ember/service", "shop/utils/nventor", "ramda"], function (_exports, _component, _object, _emberConcurrencyDecorators, _service, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    websiteService: (0, _service.inject)('website'),
    helpers: (0, _service.inject)(),
    router: (0, _service.inject)(),
    tagName: '',
    isModalVisible: false,
    showHeaderCloseBtn: true,
    closeOnOverlayClick: true,
    hasButton: (0, _object.computed)('hasFooterBtns', 'actionBtnAction', 'onClose', function () {
      if (!R.isNil(this.hasFooterBtns)) {
        return this.hasFooterBtns;
      }

      if (this !== null && this !== void 0 && this.onSubmit) {
        return true;
      }

      if (this !== null && this !== void 0 && this.onClose) {
        return true;
      }

      return false;
    }),
    footerClassName: (0, _object.computed)('actionBtnAction', 'onClose', function () {
      let className = 'modal-card-foot modal-card-footer flex-container-row ';

      if (this !== null && this !== void 0 && this.actionBtnAction && this !== null && this !== void 0 && this.onClose) {
        className += 'flex-container--justify-space-between';
      } else {
        className += 'flex-container--justify-end';
      }

      return className;
    }),
    modalCardClassName: (0, _object.computed)('modalCardClass', 'websiteService.isMobile', function () {
      const classNames = ['modal-card', 'cart-modal-card'];
      const modalCardClass = this === null || this === void 0 ? void 0 : this.modalCardClass;

      if (modalCardClass) {
        classNames.push(modalCardClass);
      }

      let isMobile = this.websiteService.isMobile;

      if (window.innerWidth && window.innerWidth <= 800) {
        isMobile = true;
      }

      if (isMobile) {
        classNames.push('modal-card--mobile');
      } else {
        classNames.push('modal-card--not-mobile');
      }

      return classNames.join(' ');
    }),

    close() {
      this.set('isModalVisible', false);

      if (this.onClose) {
        this.onClose();
      }
    },

    *actionSubmitTask() {
      var _this$actionBtnAction, _this$onNextAfterActi;

      yield (_this$actionBtnAction = this.actionBtnAction) === null || _this$actionBtnAction === void 0 ? void 0 : _this$actionBtnAction.call(this);
      yield (_this$onNextAfterActi = this.onNextAfterAction) === null || _this$onNextAfterActi === void 0 ? void 0 : _this$onNextAfterActi.call(this);
    }

  }, (_applyDecoratedDescriptor(_obj, "close", [_object.action], Object.getOwnPropertyDescriptor(_obj, "close"), _obj), _applyDecoratedDescriptor(_obj, "actionSubmitTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "actionSubmitTask"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/components/shop/unsubscribe/success/component", ["exports", "@glimmer/component", "@ember/service", "ember-concurrency-decorators", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _service, _emberConcurrencyDecorators, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShopUnsubscribeSuccessComponent = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('members'), (_class = class ShopUnsubscribeSuccessComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _initializerDefineProperty(this, "membersService", _descriptor3, this);

      _initializerDefineProperty(this, "websites", _descriptor4, this);

      _initializerDefineProperty(this, "hasMailingListError", _descriptor5, this);

      const resubscribeToken = this.args.resubscribeToken;
      const resubscribeTokenData = this.membersService.decodeMemberToken(resubscribeToken);
      this.websites = R.propOr([], 'websites')(resubscribeTokenData);
    }

    *resubscribeTask() {
      const response = yield this.websiteService.request(`/api/protected/websites/members/subscribe`, {
        method: 'PATCH',
        data: {
          token: this.args.resubscribeToken
        }
      });
      const hasMailingListError = R.pathOr(false, ['data', 'hasMailingListError'])(response);

      if (hasMailingListError) {
        this.hasMailingListError = true;
      } else {
        this.router.transitionTo('website.subscribe');
      }
    }

    onClickSubscribe() {
      this.resubscribeTask.perform();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "websites", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasMailingListError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "resubscribeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "resubscribeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickSubscribe", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickSubscribe"), _class.prototype)), _class));
  _exports.default = ShopUnsubscribeSuccessComponent;
});
define("shop/pods/shop/item/model", ["exports", "ramda", "@ember/object", "shop/mixins/base", "shop/mixins/date", "shop/utils/nventor"], function (_exports, R, _object, _base, _date, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _object.default.extend(_base.default, _date.default, (_obj = {
    // fastboot: service(),
    // storage: service(),
    populate(data) {
      data.cardContentAlign = data.cardContentAlign || 'center'; // default to center

      if (R.type(data.customTags) !== 'Array') {
        data.customTags = [];
      }

      data.customTags.clear();
      const showOnSaleTag = R.propOr(true, 'showOnSaleTag')(data);

      if (showOnSaleTag && (data.onSale === 'on' || data.onSale === true)) {
        var _data;

        const onSaleTagDisplayPosition = ((_data = data) === null || _data === void 0 ? void 0 : _data.onSaleTagDisplayPosition) || 'left-top';
        const [positionX, positionY] = R.split('-', onSaleTagDisplayPosition);
        data.customTags.pushObject({
          showTag: true,
          positionX: positionX,
          positionY: positionY,
          translate: true,
          theme: 'onSaleTag',
          label: 'onSale',
          hasCountdownTimer: false
        });
      }

      data = {
        _data: data
      };
      this.setProperties(data);
    },

    onSaleTagDisplayStyle: (0, _object.computed)('_data.onSaleTagDisplayStyle', function () {
      return `on-sale-tag--${this.get('_data.onSaleTagDisplayStyle') || 'default'}`;
    }),
    tabs: (0, _object.computed)('_data.{templates.[],faqs.@each.isActive}', function () {
      const tabs = [];
      const hasFaqs = R.pipe(R.pathOr([], ['_data', 'faqs']), R.isEmpty, R.not)(this);
      const hasTemplate = R.pipe(R.pathOr([], ['_data', 'templates']), R.isEmpty, R.not)(this);

      if (hasTemplate) {
        let index = 0;
        const templates = R.pipe(R.pathOr([], ['_data', 'templates']), R.filter(R.allPass([R.pathEq(['status'], 'active'), R.pipe(R.prop('template'), R.propSatisfies(R.gte(R.__, 1), 'length'))])))(this);
        R.forEach(templateTab => {
          tabs.pushObject(_object.default.create({
            // icon: 'fas fa-question',
            name: templateTab.header,
            isActive: index === 0,
            template: templateTab.template,
            translate: false
          }));
          index++;
        })(templates);
      }

      if (hasFaqs) {
        let isActive = false;

        if (tabs.length === 0) {
          isActive = true;
        }

        tabs.pushObject(_object.default.create({
          // icon: 'fas fa-question',
          name: 'faqs',
          isActive,
          componentName: 'faqs'
        }));
      }

      return tabs;
    }),
    activeTab: (0, _object.computed)('tabs.@each.isActive', function () {
      return R.find(R.prop('isActive'))(this.tabs);
    }),

    setTabActive(tabs, currentTab) {
      R.forEach(tab => {
        tab.set('isActive', false);
      })(tabs);
      currentTab.set('isActive', true);
    },

    selectedQty: (0, _object.computed)('_data.qty', function () {
      var _this$_data;

      return (this === null || this === void 0 ? void 0 : (_this$_data = this._data) === null || _this$_data === void 0 ? void 0 : _this$_data.qty) || 0;
    }),
    images: (0, _object.computed)('_data.{isSet,setDetails.[],hasVariants,variantChildren.[],uploadedFiles.[]}', function () {
      var _this$_data2, _this$_data3;

      const images = R.pipe(R.pathOr([], ['_data', 'uploadedFiles']), R.reject(R.anyPass([R.propEq('uuid', null), R.propEq('uuid', ''), R.isEmpty, R.isNil])))(this);
      let itemImagesSorted = R.sortWith([R.descend(R.prop('isDefault')), R.ascend(R.prop('index')), R.ascend(R.prop('timestampZ'))])(images) || [];

      if ((_this$_data2 = this._data) !== null && _this$_data2 !== void 0 && _this$_data2.hasVariants) {
        const variantChildren = R.pathOr([], ['_data', 'variantChildren'])(this);
        const variantChildrenImagesArray = R.pipe(R.map(variantChild => R.pipe(R.pathOr([], ['uploadedFiles']), R.reject(R.anyPass([R.propEq('uuid', null), R.propEq('uuid', ''), R.isEmpty, R.isNil])))(variantChild)), R.reject(_nventor.default.isNilOrEmpty), R.flatten)(variantChildren) || [];
        itemImagesSorted = itemImagesSorted.concat(variantChildrenImagesArray);
      }

      const setDetails = R.pathOr([], ['_data', 'setDetails'])(this);

      if ((_this$_data3 = this._data) !== null && _this$_data3 !== void 0 && _this$_data3.isSet) {
        const setDetailsImagesArray = R.pipe(R.map(child => R.pipe(R.pathOr([], ['uploadedFiles']), R.reject(R.anyPass([R.propEq('uuid', null), R.propEq('uuid', ''), R.isEmpty, R.isNil])))(child)), R.reject(_nventor.default.isNilOrEmpty), R.flatten)(setDetails) || [];
        itemImagesSorted = itemImagesSorted.concat(setDetailsImagesArray);
      }

      return R.uniqBy(R.prop('uuid'))(itemImagesSorted);
    }),
    allImagesIncludingChoices: (0, _object.computed)('images.[]', function () {
      let images = this.images || [];
      const choicesImages = R.pipe(R.pathOr([], ['_data', 'choices']), R.map(R.pathOr([], ['_data', 'details'])), R.flatten, R.pluck('defaultImageData'), R.reject(R.anyPass([_nventor.default.isNilOrEmpty, R.equals(false)])))(this);

      if (!_nventor.default.isNilOrEmpty(choicesImages)) {
        images = R.concat(images)(choicesImages);
      }

      return images;
    }),
    thumbImages: (0, _object.computed)('allImagesIncludingChoices.[]', function () {
      let images = this.allImagesIncludingChoices;

      if (images.length > 1) {
        images = R.reduce((acc, img) => {
          var _this$imagesService, _this$imagesService$g;

          img.defaultImageBackgroundColor = this === null || this === void 0 ? void 0 : (_this$imagesService = this.imagesService) === null || _this$imagesService === void 0 ? void 0 : (_this$imagesService$g = _this$imagesService.getBackgroundRGBColor) === null || _this$imagesService$g === void 0 ? void 0 : _this$imagesService$g.call(_this$imagesService, {
            pathArray: ['mainColors'],
            model: img
          });
          const alreadyAdded = acc.some(image => image.uuid === img.uuid);

          if (alreadyAdded) {
            return acc;
          }

          return acc.concat(img);
        }, [])(images);
        return images;
      }

      return [];
    }),
    defaultImageBackgroundColor: (0, _object.computed)('allImagesIncludingChoices.[]', '_data.defaultImage', 'defaultImageData', function () {
      var _this$imagesService2, _this$imagesService2$;

      return this === null || this === void 0 ? void 0 : (_this$imagesService2 = this.imagesService) === null || _this$imagesService2 === void 0 ? void 0 : (_this$imagesService2$ = _this$imagesService2.getBackgroundRGBColor) === null || _this$imagesService2$ === void 0 ? void 0 : _this$imagesService2$.call(_this$imagesService2, {
        pathArray: ['defaultImageData', 'mainColors'],
        model: this
      });
    }),
    defaultImageData: (0, _object.computed)('allImagesIncludingChoices.[]', '_data.defaultImage', function () {
      const images = this.allImagesIncludingChoices; // let uuid

      const defaultImageData = R.find(R.propEq('isDefault', true))(images);

      if (defaultImageData) {
        return defaultImageData;
      } // if (this.get('_data.defaultImage')) {
      //   uuid = this.get('_data.defaultImage')
      //   if (uuid) {
      //     const found = R.find(R.propEq('uuid', uuid))(images)
      //     if (found) {
      //       return found
      //     }
      //   }
      // }


      if (images.get('firstObject')) {
        const firstImage = images.get('firstObject');

        if (firstImage) {
          return firstImage;
        }
      }

      return false;
    }),
    groupedByPositionTags: (0, _object.computed)('_data.customTags.[]', function () {
      // const customTags = this._data.customTags || []
      const customTags = R.pathOr([], ['_data', 'customTags'])(this);
      const groupByPosition = R.groupBy(customTag => {
        return `${customTag.positionX}-${customTag.positionY}`;
      });
      return groupByPosition(customTags);
    }),
    // productDisplayTags: computed('_data.productDisplayTags.[]', function () {
    //   const productDisplayTags = R.pathOr([], ['_data', 'productDisplayTags'])(this)
    //   const updatedProductDisplayTags = R.map(
    //     tag => {
    //       return {
    //         showTag: true,
    //         translate: false,
    //         theme: 'onSaleTag',
    //         ...tag
    //       }
    //     }
    //   )(productDisplayTags)
    //   return updatedProductDisplayTags
    // }),
    // groupedByPositionTags: computed('_data.customTags.[]','productDisplayTags.[]',  function () {
    //   // const customTags = this._data.customTags || []
    //   const customTags = R.pathOr([], ['_data', 'customTags'])(this)
    //   const productDisplayTags = this.productDisplayTags || []
    //   const allTags = R.concat(customTags, productDisplayTags)
    //   const groupByPosition = R.groupBy((allTag) => {
    //     return allTag.position
    //   })
    //   return groupByPosition(allTags)
    // }),
    isOnSale: (0, _object.computed)('_data.onSale', function () {
      // const onSale = this._data.onSale
      const onSale = R.pathOr([], ['_data', 'onsale'])(this);

      if (onSale === 'on' || onSale === true) {
        return true;
      }

      return false;
    }),
    isSetWithChoices: (0, _object.computed)('_data.isSetWithChoices', function () {
      return this.get('_data.isSetWithChoices');
    }),
    hasVariants: (0, _object.computed)('_data.hasVariants', function () {
      return this.get('_data.hasVariants');
    }),
    showCustomizationsSelector: (0, _object.computed)('_data.{hasVariants,hasCustomizations,customizations}', function () {
      var _this$_data4;

      const hasVariants = R.pathOr(false, ['_data', 'hasVariants'])(this);
      const hasCustomizations = R.pathOr(false, ['_data', 'hasCustomizations'])(this);
      const customizations = (this === null || this === void 0 ? void 0 : (_this$_data4 = this._data) === null || _this$_data4 === void 0 ? void 0 : _this$_data4.customizations) || [];
      return !hasVariants && hasCustomizations && !R.isEmpty(customizations);
    }),
    selectedVariantOptions: (0, _object.computed)('hasSelectedVariantChanged', '_data.variants.@each.selectedValue', function () {
      const variants = this.get('_data.variants') || [];
      const selectedVariantOptions = this.getSelectedVariantOptions(variants);
      return selectedVariantOptions;
    }),
    itemName: (0, _object.computed)('_data.{item,salesPreOrderLimitedQtyDesc,calculatedStatusAfterCart}', function () {
      var _this$_data5, _this$_data7;

      if (this !== null && this !== void 0 && (_this$_data5 = this._data) !== null && _this$_data5 !== void 0 && _this$_data5.salesPreOrderLimitedQtyDesc) {
        var _this$_data6;

        return (this === null || this === void 0 ? void 0 : this._data.item) + ' - ' + (this === null || this === void 0 ? void 0 : (_this$_data6 = this._data) === null || _this$_data6 === void 0 ? void 0 : _this$_data6.salesPreOrderLimitedQtyDesc);
      }

      return this === null || this === void 0 ? void 0 : (_this$_data7 = this._data) === null || _this$_data7 === void 0 ? void 0 : _this$_data7.item;
    }),
    isCustomizationsSelectionCompleted: (0, _object.computed)('hasSelectedCustomizationsChanged', function () {
      const hasCustomizations = R.pathOr(false, ['_data', 'hasCustomizations'])(this);

      if (!hasCustomizations) {
        return true;
      }

      const requiredCustomizations = R.pipe(R.pathOr([], ['_data', 'customizations']), R.filter(customization => {
        const isRequired = R.pathOr(false, ['_data', 'isRequired'])(customization);

        if (isRequired) {
          return true;
        }

        const isCustomResponse = R.pathOr('', ['saveSelectedOpt', 'isCustomResponse'])(customization);

        if (isCustomResponse) {
          return true;
        }

        return false;
      }))(this);
      const selectedCustomizations = R.pipe(R.map(customization => {
        const saveSelectedOptName = R.pathOr('', ['saveSelectedOpt', 'name'])(customization);

        if (!saveSelectedOptName) {
          return false;
        }

        const isCustomResponse = R.pathOr('', ['saveSelectedOpt', 'isCustomResponse'])(customization);
        const customResponse = R.pathOr('', ['saveSelectedOpt', 'customResponse'])(customization);

        if (isCustomResponse && R.isEmpty(customResponse)) {
          return false;
        }

        return customization;
      }), R.reject(R.equals(false)))(requiredCustomizations);

      if (requiredCustomizations.length === selectedCustomizations.length) {
        return true;
      }

      return false;
    }),
    selectedVariant: (0, _object.computed)('selectedVariantOptions.[]', 'hasSelectedVariantChanged', 'recurringScheduleSelected.recurringScheduleKey', function () {
      const variants = this.get('_data.variants') || [];
      const selectedVariantOptions = this.selectedVariantOptions || [];
      return this.getSelectedVariant(variants, selectedVariantOptions);
    }),
    selectedCustomizations: (0, _object.computed)('hasSelectedCustomizationsChanged', function () {
      const customizations = R.pathOr([], ['_data', 'customizations'])(this);
      return R.pipe(R.map(customization => {
        const selectedCustomizationIndex = R.pathOr('', ['_data', 'index'])(customization);
        const selectedCustomizationName = R.pathOr('', ['_data', 'name'])(customization);
        const selectedCustomizationValue = R.propOr('', 'selectedValue')(customization);
        const selectedCustomizationObj = R.propOr({}, 'saveSelectedOpt')(customization);

        if (selectedCustomizationValue) {
          return {
            selectedCustomizationIndex,
            selectedCustomizationName,
            selectedCustomizationValue,
            selectedCustomizationObj
          };
        }

        return false;
      }), R.reject(R.equals(false)))(customizations);
    }),

    getSelectedVariantOptions(variants) {
      return R.pipe(R.map(variant => {
        const selectedValue = R.prop('selectedValue')(variant);

        if (selectedValue) {
          return {
            name: R.path(['_data', 'name'])(variant),
            value: selectedValue
          };
        }

        return false;
      }), R.reject(R.equals(false)))(variants);
    },

    getSelectedVariant(variants, selectedVariantOptions) {
      if (this.hasVariants) {
        if (selectedVariantOptions.length === variants.length) {
          return this.getVariantBySelectedOptions(selectedVariantOptions);
        }
      }

      return false;
    },

    getVariantBySelectedOptions(selectedVariantOptions = []) {
      let variantChildren = this.get('_data.variantChildren') || [];
      const found = variantChildren.find(option => {
        return selectedVariantOptions.every(selectedVariant => {
          const variantChildIds = R.pipe(R.pathOr([], ['variantChildId']), R.split(':'))(option);
          const matchVariantType = R.pipe(R.find(R.equals(selectedVariant.name)), _nventor.default.isNotNilOrEmpty)(variantChildIds);
          const matchVariantOption = R.pipe(R.find(R.equals(selectedVariant.value)), _nventor.default.isNotNilOrEmpty)(variantChildIds);
          return matchVariantType && matchVariantOption;
        });
      });

      if (found) {
        // set recurringScheduleSelected in computed instead on addCartItem
        if (this._data.recurringSchedulesEnabled) {
          found.recurringScheduleSelected = this.recurringScheduleSelected;
          found.isRecurring = true;

          if (R.isNil(found.recurringScheduleSelected)) {
            found.isRecurring = false;
          }
        }

        return found;
      }

      return false;
    },

    getVariantChildId(selectedVariantOptions = []) {
      return R.pipe(R.sortWith([R.ascend(R.prop(['name'])), R.ascend(R.prop(['value']))]), R.map(option => {
        const name = R.pipe(R.propOr('', 'name'), R.replace(/:/g, ''), R.trim)(option);

        if (option.value) {
          return `${name}:${option.value}`;
        }

        return name;
      }), R.join(':'))(selectedVariantOptions);
    },

    selectedVariantName: (0, _object.computed)('selectedVariant', 'selectedVariantOptions.[]', function () {
      const selectedVariant = this.selectedVariant;
      return this.getSelectedVariantName(selectedVariant);
    }),

    getSelectedVariantName(selectedVariant) {
      if (selectedVariant) {
        return R.propOr('', 'item', selectedVariant);
      }

      return '';
    },

    selectedVariantPriceOld: (0, _object.computed)('selectedVariant', 'selectedVariantOptions.[]', 'hasSelectedVariantChanged', '_data.{price,priceDiscounted}', function () {
      let variantMasterPrice = this.get('_data.price');

      if (!variantMasterPrice) {
        variantMasterPrice = this.get('_data.priceDiscounted');
      }

      const selectedVariant = this.selectedVariant;

      if (selectedVariant) {
        const price = R.prop('price', selectedVariant);
        const priceDiscounted = R.prop('priceDiscounted', selectedVariant);

        if (price || priceDiscounted) {
          if (price) {
            return price;
          }

          return priceDiscounted;
        }

        return variantMasterPrice;
      }
    }),
    selectedVariantPriceCurrent: (0, _object.computed)('selectedVariant', 'selectedVariantOptions.[]', 'hasSelectedVariantChanged', '_data.priceFinal', function () {
      return this._data.priceFinal; // const onSale = this.get('_data.onSale')
      // let variantMasterPrice = this.get('_data.price')
      // if (onSale === 'on') {
      //   const priceDiscountedMaster = this.get('_data.priceDiscounted')
      //   if (priceDiscountedMaster) {
      //     variantMasterPrice = priceDiscountedMaster
      //   }
      // }
      // const selectedVariant = this.selectedVariant
      // if (selectedVariant) {
      //   const price = R.prop('price', selectedVariant)
      //   const priceDiscounted = R.prop('priceDiscounted', selectedVariant)
      //   if (price || priceDiscounted) {
      //     if (onSale === 'on') {
      //       // if (selectedVariant.onSale === 'on') {
      //       return priceDiscounted
      //       // }
      //     }
      //     return priceDiscounted
      //   }
      //   return variantMasterPrice
      // }
      // return variantMasterPrice
    }),

    getRecurringPrice(recurringSchedule, isSetChoiceCurrentPrice = '') {
      let currentPrice = isSetChoiceCurrentPrice || this.currentPrice;
      currentPrice = this._calculateCustomizationsPrice(currentPrice);
      const currentPriceBig = this.bigService.newBig(currentPrice);
      const discountType = recurringSchedule === null || recurringSchedule === void 0 ? void 0 : recurringSchedule.discountType;

      if (discountType === 'rate') {
        let rate = Number(recurringSchedule === null || recurringSchedule === void 0 ? void 0 : recurringSchedule.discountRate) || 0;
        const onSale = this.get('_data.onSale');

        if (onSale === 'on') {
          rate = 0;

          if (recurringSchedule !== null && recurringSchedule !== void 0 && recurringSchedule.allowOnSale) {
            rate = Number(recurringSchedule === null || recurringSchedule === void 0 ? void 0 : recurringSchedule.discountRateOnSale) || 0;
          }
        }

        if (rate) {
          const toDiscountRateBig = this.bigService.newBig(100).minus(rate);
          return currentPriceBig.times(toDiscountRateBig).div(100).toFixed(0);
        }

        return currentPriceBig.toFixed(0);
      } else if (discountType === 'amount') {
        return currentPriceBig.minus(recurringSchedule === null || recurringSchedule === void 0 ? void 0 : recurringSchedule.discountAmount).toFixed(0);
      }

      return currentPrice;
    },

    _calculateCustomizationsPrice(price) {
      const selectedCustomizations = this.get('selectedCustomizations') || [];

      if (!R.isEmpty(selectedCustomizations)) {
        let priceBig = this.bigService.newBig(price);
        R.forEach(selectedCustomization => {
          var _selectedCustomizatio;

          // should use price when onSale and called by oldPrice?
          const priceFinal = (selectedCustomization === null || selectedCustomization === void 0 ? void 0 : (_selectedCustomizatio = selectedCustomization.selectedCustomizationObj) === null || _selectedCustomizatio === void 0 ? void 0 : _selectedCustomizatio.priceFinal) || '0';
          priceBig = priceBig.plus(priceFinal);
        })(selectedCustomizations);
        return Number(priceBig.toFixed(0) || 0);
      }

      return price;
    },

    currentPrice: (0, _object.computed)('_data.{onSale,priceDiscounted,price}', function () {
      const onSale = this.get('_data.onSale');
      let currentPrice = this.get('_data.price');

      if (onSale === 'on') {
        const priceDiscounted = this.get('_data.priceDiscounted');

        if (priceDiscounted) {
          currentPrice = priceDiscounted;
        }
      }

      return currentPrice;
    }),
    currentPriceOld: (0, _object.computed)('_data.{priceDiscounted,price}', function () {
      let currentPrice = this.get('_data.price');

      if (!currentPrice) {
        currentPrice = this.get('_data.priceDiscounted');
      }

      return currentPrice;
    }),

    _getCurrentChildPrice(priceData) {
      const {
        onSale,
        priceDiscounted,
        price
      } = priceData;
      let currentPrice = price;

      if (onSale === 'on') {
        if (priceDiscounted) {
          currentPrice = priceDiscounted;
        }
      }

      return currentPrice;
    },

    hasPriceRange: (0, _object.computed)('_data.{choices.[],onSale,variantChildren.[]}', function () {
      const isSetWithChoices = this.get('_data.isSetWithChoices');

      if (isSetWithChoices) {
        const choices = this.get('_data.choices') || []; // const variantChildren = this.get('variantChildren') || []

        const hasPriceRange = R.pipe(R.map(R.pipe(R.pathOr([], ['_data', 'details']), R.pluck('_data'), R.map(choiceDetailData => {
          if (choiceDetailData.hasVariants) {
            return this._getPriceRangesForHasVariants(choiceDetailData);
          }

          return this._getCurrentChildPrice(choiceDetailData);
        }), R.flatten, R.uniq, R.length, R.gt(R.__, 1))), R.reject(R.equals(false)), R.isEmpty, R.not);
        return hasPriceRange(choices);
      }

      const hasVariants = this.get('_data.hasVariants');

      if (hasVariants) {
        return this._getPriceRangesForHasVariants(this.serialize());
      }

      return false;
    }),

    _getPriceRangesForHasVariants(choiceDetailData) {
      return R.pipe(R.propOr([], 'variantChildren'), R.map(this._getCurrentChildPrice), R.uniq)(choiceDetailData);
    },

    bulkDiscountId: (0, _object.computed)('_data.bulkDiscountId', function () {
      const bulkDiscountId = this.get('_data.bulkDiscountId') || '';
      return bulkDiscountId;
    })
  }, (_applyDecoratedDescriptor(_obj, "setTabActive", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setTabActive"), _obj)), _obj));

  _exports.default = _default;
});
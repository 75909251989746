define("shop/pods/members/logout/route", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    router: (0, _service.inject)(),

    model() {
      const website = this.modelFor('application').website;
      this.membersService.logout(website);
      return this.router.transitionTo('members');
    }

  });

  _exports.default = _default;
});
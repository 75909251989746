define("shop/pods/components/shop/init/component", ["exports", "@glimmer/component", "@ember/service", "ember-concurrency-decorators", "@glimmer/tracking", "shop/utils/nventor"], function (_exports, _component, _service, _emberConcurrencyDecorators, _tracking, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShopDefaultComponent = (_dec = (0, _service.inject)('website'), (_class = class ShopDefaultComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "storage", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor3, this);

      _initializerDefineProperty(this, "campaignPassword", _descriptor4, this);

      _initializerDefineProperty(this, "isShopLoginSuccess", _descriptor5, this);

      _defineProperty(this, "threeHours", 3 * 60 * 60 * 1000);
    }

    get hasErrorMessage() {
      const errorMessage = (this === null || this === void 0 ? void 0 : this.errorMessage) || '';

      if (errorMessage) {
        return true;
      }

      return false;
    }

    get isAllowShop() {
      var _this$args, _shop$_data;

      const shop = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.shop) || {};
      const hasCampaignPassword = (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.hasCampaignPassword) || (shop === null || shop === void 0 ? void 0 : shop.hasCampaignPassword) || false;

      if (!hasCampaignPassword) {
        return true;
      }

      const isShopLoginSuccess = (this === null || this === void 0 ? void 0 : this.isShopLoginSuccess) || false;
      const isAllowed = this.storage.getValueWithShopKey('isAllowed') || false;
      return isShopLoginSuccess || isAllowed;
    }

    *shopLoginTask() {
      var _this$args2;

      const shop = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.shop) || {};
      const password = (this === null || this === void 0 ? void 0 : this.campaignPassword) || '';

      if (_nventor.default.isNotNilOrEmpty(shop) && password) {
        var _shop$_data2, _shop$_data3;

        this.errorMessage = '';
        const data = {
          shopKey: (shop === null || shop === void 0 ? void 0 : shop.shopKey) || (shop === null || shop === void 0 ? void 0 : (_shop$_data2 = shop._data) === null || _shop$_data2 === void 0 ? void 0 : _shop$_data2._key) || '',
          password
        };
        const shopUrl = (shop === null || shop === void 0 ? void 0 : shop.url) || (shop === null || shop === void 0 ? void 0 : (_shop$_data3 = shop._data) === null || _shop$_data3 === void 0 ? void 0 : _shop$_data3.url) || '';
        const res = yield this.websiteService.request(`/api/public/channels/shop/campaigns/${shopUrl}/login`, {
          method: 'POST',
          data
        });

        if (res.data === true) {
          this.isShopLoginSuccess = true;
          this.storage.setValueWithShopKey('isAllowed', true, this.threeHours);
        } else {
          this.errorMessage = 'error_password';
          this.isShopLoginSuccess = false;
        }
      } else {
        this.errorMessage = 'error_password';
        this.isShopLoginSuccess = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "storage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "campaignPassword", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isShopLoginSuccess", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "shopLoginTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "shopLoginTask"), _class.prototype)), _class));
  _exports.default = ShopDefaultComponent;
});
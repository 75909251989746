define("shop/pods/components/layouts/modal-scroll-to-top/component", ["exports", "ramda", "@ember/component", "shop/mixins/scroll", "@ember/runloop", "@ember/object", "@ember/service"], function (_exports, R, _component, _scroll, _runloop, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_scroll.default, (_obj = {
    websiteService: (0, _service.inject)('website'),
    scrollService: (0, _service.inject)('scroll'),
    tagName: 'div',
    isViewable: false,

    didInsertElement() {
      this._super(...arguments);

      const modalCardBody = document.querySelector('.modal-card-body');

      if (modalCardBody) {
        modalCardBody.addEventListener('scroll', this.displayScrollToTopButton);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.isDestroyed) {
        return;
      }

      const modalCardBody = document.querySelector('.modal-card-body');

      if (modalCardBody) {
        modalCardBody.removeEventListener('scroll', this.displayScrollToTopButton);
      }
    },

    displayScrollToTopButton(e) {
      const scrollTop = $('.modal-card-body').scrollTop();
      this.scrollService.handleChatBtnPositioning({
        isModal: true
      });
      let isViewable = true;

      if (scrollTop <= 0) {
        isViewable = false;
      }

      (0, _object.set)(this, 'isViewable', isViewable);
    },

    goToTop() {
      (0, _runloop.scheduleOnce)('afterRender', () => {
        window.$('.modal-card-body').animate({
          scrollTop: window.$('body').offset().top
        }, 200);
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "displayScrollToTopButton", [_object.action], Object.getOwnPropertyDescriptor(_obj, "displayScrollToTopButton"), _obj), _applyDecoratedDescriptor(_obj, "goToTop", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToTop"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/website/unsubscribe/index/route", ["exports", "@ember/routing/route", "rsvp", "ramda"], function (_exports, _route, _rsvp, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    queryParams: {
      token: {
        refreshModel: true
      }
    },

    async model(params) {
      try {
        const website = this.modelFor('application').website;
        const token = params.token;
        const response = await this.websiteService.request('/api/protected/websites/members/unsubscribe', {
          method: 'PATCH',
          data: {
            token
          }
        });
        const hasMailingListError = R.pathOr(false, ['data', 'hasMailingListError'])(response);
        const resubscribeToken = R.propOr('', 'data')(response);
        return _rsvp.default.hash({
          token,
          website,
          hasMailingListError,
          resubscribeToken
        });
      } catch (e) {
        this.transitionTo('/');
      }
    },

    redirect(model) {
      if (!model.token || !model.resubscribeToken || model.hasMailingListError) {
        this.transitionTo('website.unsubscribe.fail');
      } else {
        this.transitionTo('website.unsubscribe.success', model.resubscribeToken);
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/big/service", ["exports", "@ember/service", "@ember/object", "ramda", "shop/utils/nventor"], function (_exports, _service, _object, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BigService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "sumProp", R.curry((dotPath, arrayOfObjects) => {
        arrayOfObjects = arrayOfObjects || [];
        return R.pipe(R.map(_nventor.default.dotPath(dotPath)), R.reject(_nventor.default.isNilOrEmpty), this.sumArray)(arrayOfObjects);
      }));

      _defineProperty(this, "sumArray", R.reduce((accumulator, amt) => accumulator.plus(amt), new Big(0)));
    }

    /**
     * create new big number
     * @param  {string|integer|float} number
     * @return {class} big number class instance
     */
    newBig(number) {
      number = parseFloat(number);
      return new Big(number || 0);
    }

  }

  _exports.default = BigService;
});
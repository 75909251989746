define("shop/pods/components/elements/element-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UPp/ezUO",
    "block": "[[[41,[48,[30,10]],[[[1,\"  \"],[18,10,null],[1,\"\\n\"]],[]],[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,3],[[16,0,[30,2]]],[[\"@icon\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,4],[[30,1],[30,3]],null],[[[1,\"    \"],[10,1],[14,0,\"element-btn__icon-spacer\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3],[[[41,[30,4],[[[1,\"      \"],[10,1],[15,0,[30,5]],[12],[1,\"\\n        \"],[1,[28,[35,5],[[30,4]],[[\"translate\"],[[30,0,[\"translate\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[1,[28,[35,5],[[30,3]],[[\"translate\"],[[30,0,[\"translate\"]]]]]],[1,\"\\n\\n\"],[41,[30,6],[[[1,\"      \"],[10,1],[15,0,[30,7]],[12],[1,\"\\n        \"],[1,[28,[35,5],[[30,6]],[[\"translate\"],[[30,0,[\"translate\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,8],[[[1,\"      \"],[1,[28,[35,6],[\" [\",[28,[37,5],[\"soldOut\"],null],\"] \"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,9],[[[1,\"      \"],[1,[28,[35,6],[\" [\",[28,[37,5],[\"preOrder\"],null],\"] \"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]],null]],[]]]],[\"@icon\",\"@iconClassNames\",\"@label\",\"@prefix\",\"@prefixClassNames\",\"@suffix\",\"@suffixClassNames\",\"@isSoldOut\",\"@isPreOrder\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"elements/element-icon\",\"and\",\"tt\",\"concat\"]]",
    "moduleName": "shop/pods/components/elements/element-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/members/manage-addresses/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dVRJF57t",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2,[\"_data\",\"addresses\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"disableAllEditing\",\"model\",\"address\",\"website\",\"shop\",\"errors\"],[[33,4],[33,2],[30,1],[33,5],[33,6],[33,5,[\"errors\"]]]]]],[1,\"\\n\"]],[1]],null]],[\"address\"],false,[\"each\",\"-track-array\",\"model\",\"elements/address-selector\",\"disableAllEditing\",\"website\",\"shop\"]]",
    "moduleName": "shop/pods/components/members/manage-addresses/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/components-group/component", ["exports", "@glimmer/component", "@ember/object", "@ember/object/internals", "@glimmer/tracking", "shop/utils/nventor", "jquery", "@ember/service", "ramda", "ember-concurrency-decorators", "@ember/array"], function (_exports, _component, _object, _internals, _tracking, _nventor, _jquery, _service, R, _emberConcurrencyDecorators, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ComponentsGroupComponent = (_dec = (0, _service.inject)('shop'), _dec2 = (0, _service.inject)('filters'), (_class = class ComponentsGroupComponent extends _component.default {
    constructor() {
      var _this$args, _this$args$shop, _this$args$shop$_data, _this$args2, _this$args2$shop;

      super(...arguments);

      _initializerDefineProperty(this, "shopService", _descriptor, this);

      _initializerDefineProperty(this, "filtersService", _descriptor2, this);

      _initializerDefineProperty(this, "isScrollable", _descriptor3, this);

      _initializerDefineProperty(this, "hideRight", _descriptor4, this);

      _initializerDefineProperty(this, "hideLeft", _descriptor5, this);

      _initializerDefineProperty(this, "filterModel", _descriptor6, this);

      _initializerDefineProperty(this, "limitBy", _descriptor7, this);

      _initializerDefineProperty(this, "page", _descriptor8, this);

      _initializerDefineProperty(this, "rowLimit", _descriptor9, this);

      _initializerDefineProperty(this, "products", _descriptor10, this);

      _defineProperty(this, "id", (0, _internals.guidFor)(this));

      this.rowLimit = R.path(['args', 'rowLimit'])(this) || this.rowLimit;
      this.limitBy = R.pipe(R.path(['args', 'limitBy']), parseInt)(this) || this.limitBy;
      const tagsArray = R.pipe(R.pathOr('', ['args', 'tags']), R.split(','))(this);
      const productsTagsArray = R.pathOr([], ['args', 'shop', '_data', 'productTags'])(this);
      const tagsLabel = R.pipe(R.innerJoin((tags, tag) => tags._key === tag, productsTagsArray), R.map(R.pick(['label'])), R.map(R.set(R.lensProp('isSelected'), true)))(tagsArray); // adding params.sortBy or any value to params object will trigger hasTagsOnParams which will cause defaultData.tags to be ignored
      // this is because the filter is trying to use the tags value from the params since it assumes that tags param is inputtedo
      // fix: will set the sort by to the default data for now (WORKING)

      const settingsSortBy = R.of(((_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$shop = _this$args.shop) === null || _this$args$shop === void 0 ? void 0 : (_this$args$shop$_data = _this$args$shop._data) === null || _this$args$shop$_data === void 0 ? void 0 : _this$args$shop$_data.shopProductsSort) || ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$shop = _this$args2.shop) === null || _this$args2$shop === void 0 ? void 0 : _this$args2$shop.shopProductsSort) || 'recommended');
      const params = this.args.params || {}; //@TODO: add a filter/sort to each grid header
      // add to template:
      // <Layouts::Filters::FiltersSection
      //   @header='prices'
      //   @translate={{translate}}
      // >

      const defaultData = {
        tags: tagsLabel || R.pathOr([], ['args', 'shop', '_data', 'productTags'])(this),
        prices: R.pathOr([], ['args', 'shop', '_data', 'prices'])(this),
        sortBy: settingsSortBy || R.pathOr([], ['args', 'shop', '_data', 'sortBy'])(this)
      };
      this.filterModel = this.filtersService.setupFilters(defaultData, {
        params
      });

      if (this.hasTags) {
        this.fetchTask.perform();
      } else {
        this.handlePreLoadedProducts();
      }
    }

    get rowLimitClassName() {
      return `_${this.rowLimit}-items`;
    }

    get hasTags() {
      return R.pipe(R.pathOr([], ['args', 'tags']), R.isEmpty, R.not)(this);
    }

    get isGrid() {
      return this.args.style === 'grid';
    }

    get hasMoreProductsOnSlider() {
      if (this.isGrid) {
        return false;
      }

      if (this.hasTags) {
        return this.filterModel.hasMore;
      }

      return this.products.length < this.args.products.length;
    }

    handlePreLoadedProducts() {
      const preLoadedProducts = R.pathOr([], ['args', 'products'])(this);

      if (this.isGrid) {
        const paginatedProducts = R.splitEvery(this.limitBy, preLoadedProducts);
        const pageIndex = this.page - 1;
        this.products = paginatedProducts[pageIndex];
        this.filterModel.hasMore = paginatedProducts.length > this.page;
        this.filterModel.totalCount = preLoadedProducts.length;
      } else {
        // @NOTE: this is to render one by one
        const from = this.products.length || 0;
        const end = from + this.limitBy;
        R.pipe(R.slice(from, end), R.map(prod => {
          this.products.addObject(prod);
        }))(preLoadedProducts);
        this.hideRight = false;
      }
    }

    handlePaginationByStyle(shop, response) {
      let products = R.pipe(R.pathOr([], ['details']), R.map(product => shop.createAndPopulateShopItem(product)))(response);

      if (this.isGrid) {
        this.products = products;
      } else {
        const productsToRemove = R.pipe(R.pathOr([], ['products']), R.map(R.pathOr('', ['_data', '_key'])))(this);
        products = R.reject(p => R.includes(p._data._key, productsToRemove))(products);
        R.forEach(p => this.products.addObject(p))(products);
      }
    }

    *fetchTask() {
      const params = this.filterModel.getAsParams();
      params.count = this.limitBy;
      params.page = this.page;
      const shop = this.args.shop;
      const response = yield this.shopService.getShopItemsTask.perform({
        shop,
        params,
        cache: true
      });
      this.handlePaginationByStyle(shop, response);
      this.filterModel.results = R.propOr([], 'items', response);
      this.filterModel.hasMore = R.propOr(false, 'hasMore', response);
      this.filterModel.totalCount = R.propOr(0, 'totalCount', response);
    }

    *onPaginateTask(nextPage) {
      this.page = nextPage;

      if (this.hasTags) {
        return yield this.fetchTask.perform();
      }

      return this.handlePreLoadedProducts();
    }

    moveHorizontally(direction) {
      const sliderContainerJquery = (0, _jquery.default)(`#${this.id}-content`);
      const sliderContainer = sliderContainerJquery[0];
      const scrollLeft1 = sliderContainer.scrollLeft;
      const containerWidth = sliderContainer.offsetWidth;
      let updatedScrollLeft;
      let moveLeftBy = Math.floor(sliderContainerJquery.width() / sliderContainer.children[0].offsetWidth) * sliderContainer.children[0].offsetWidth;
      const totalScrollWidth = sliderContainer.scrollWidth;

      if (direction === 'right') {
        sliderContainer.scrollBy(moveLeftBy, 0);
        updatedScrollLeft = scrollLeft1 + moveLeftBy;
      } else {
        sliderContainer.scrollBy(moveLeftBy * -1, 0);
        updatedScrollLeft = scrollLeft1 - moveLeftBy;
      }

      const updatedCurrentRight = containerWidth + updatedScrollLeft;
      const isOnRightEdge = updatedCurrentRight + 10 >= totalScrollWidth;
      const isOnLeftEdge = updatedScrollLeft - 10 <= 0;
      this.hideRight = isOnRightEdge;
      this.hideLeft = isOnLeftEdge;
    }

    setScroll() {
      if (this.isGrid) {
        this.isScrollable = false;
        return false;
      }

      const sliderContainerDomElement = document.querySelector(`#${this.id}-content`);

      if (sliderContainerDomElement) {
        this.isScrollable = true;
        const hideArrows = sliderContainerDomElement.clientWidth >= sliderContainerDomElement.scrollWidth;
        const isGraterThanWindow = window.innerWidth <= sliderContainerDomElement.clientWidth;

        if (isGraterThanWindow) {
          this.isScrollable = true;
        } else if (hideArrows) {
          this.isScrollable = false;
        }
      }
    }

    onFetchMore() {
      this.page += 1;

      if (this.hasTags) {
        return this.onPaginateTask.perform(this.page);
      }

      return this.handlePreLoadedProducts();
    }

    didInsert() {
      this.setScroll();
      const onDidInsert = R.pathOr(null, ['args', 'onDidInsert'])(this);

      if (onDidInsert) {
        onDidInsert();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isScrollable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hideRight", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hideLeft", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filterModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "limitBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 30;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "rowLimit", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '3';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "products", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPaginateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onPaginateTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moveHorizontally", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "moveHorizontally"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setScroll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFetchMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFetchMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype)), _class));
  _exports.default = ComponentsGroupComponent;
});
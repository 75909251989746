define("shop/pods/members/promo-codes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FF8j/OQ/",
    "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[],false,[\"members/promo-code\"]]",
    "moduleName": "shop/pods/members/promo-codes/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
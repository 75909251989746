define("shop/pods/shop/members/reward-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AnYBDphI",
    "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[],false,[\"members/rewards-history\"]]",
    "moduleName": "shop/pods/shop/members/reward-history/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/shop/automations", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const self = {
    getMustAddAutomationsForItemByProduct: (automations, itemData) => {
      const filterByWhenItem = R.pipe(self.onlyForItemQty, R.filter(R.propEq('thenAction', 'mustAdd')), R.filter(R.eqProps('itemKey', itemData)));
      return filterByWhenItem(automations);
    },
    onlyHasSelected: R.filter(automation => automation._data.selected.length > 0),
    onlyForOrderValue: automations => {
      const isOrderValue = R.propEq('when', 'orderValue');
      return R.filter(isOrderValue)(automations);
    },
    onlyForItem: R.curry((itemData, automations) => {
      return R.filter(R.eqProps('itemKey', itemData))(automations);
    }),
    onlyForItemQty: automations => {
      const isItemQty = R.propEq('when', 'itemQty');
      return R.filter(isItemQty)(automations);
    },
    onlyForMustAdd: automations => {
      const isMustAdd = R.pathEq(['_data', 'thenAction'], 'mustAdd');
      return R.filter(isMustAdd)(automations);
    },
    onlyForMustAddAndChooseBetween: automations => {
      const isMustAddAndChooseBetween = R.allPass([R.pathEq(['_data', 'thenAction'], 'mustAdd'), R.pathEq(['_data', 'thenSelectionType'], 'between')]);
      return R.filter(isMustAddAndChooseBetween)(automations);
    },
    onlyForMayAdd: automations => {
      const isMayAdd = R.pathEq(['_data', 'thenAction'], 'mayAdd');
      return R.filter(isMayAdd)(automations);
    },
    getSelected: R.pipe(R.pluck('selected'), R.flatten),
    selectedCount: automations => {
      return R.pipe(self.getSelected, R.length)(automations);
    },
    allowChoose: automation => {
      const {
        thenSelectionQty
      } = automation;
      const selected = automation.selected || [];
      return R.lt(selected.length, thenSelectionQty);
    },
    removeFirstSelected: automation => {
      automation.selected.removeObject(automation.selected.get('firstObject'));
      return automation;
    },
    isAlreadyAdded: R.curry((itemData, automation) => {
      return R.pipe(R.pathOr([], ['_data', 'selected']), R.includes(itemData))(automation);
    }),
    findAdded: R.curry((itemData, automation) => {
      return R.pipe(R.pathOr([], ['_data', 'selected']), R.find(R.propEq('itemKey', itemData.itemKey)))(automation);
    })
  };
  var _default = self;
  _exports.default = _default;
});
define("shop/pods/components/items/item-page/components-group-thumb/component", ["exports", "@glimmer/component", "@ember/object", "@ember/object/internals", "@glimmer/tracking", "shop/utils/nventor", "jquery"], function (_exports, _component, _object, _internals, _tracking, _nventor, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ComponentsGroupBlogsComponent = (_class = class ComponentsGroupBlogsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isScrollable", _descriptor, this);

      _initializerDefineProperty(this, "hideRight", _descriptor2, this);

      _initializerDefineProperty(this, "hideLeft", _descriptor3, this);

      _defineProperty(this, "id", (0, _internals.guidFor)(this));
    }

    moveHorizontally(direction) {
      const sliderContainerJquery = (0, _jquery.default)(`#${this.id}-content`);
      const sliderContainer = sliderContainerJquery[0];
      const scrollLeft1 = sliderContainer.scrollLeft;
      const containerWidth = sliderContainer.offsetWidth;
      let updatedScrollLeft;
      let moveLeftBy = Math.floor(sliderContainerJquery.width() / sliderContainer.children[0].offsetWidth) * sliderContainer.children[0].offsetWidth;
      const totalScrollWidth = sliderContainer.scrollWidth;

      if (direction === 'right') {
        sliderContainer.scrollBy(moveLeftBy, 0);
        updatedScrollLeft = scrollLeft1 + moveLeftBy;
      } else {
        sliderContainer.scrollBy(moveLeftBy * -1, 0);
        updatedScrollLeft = scrollLeft1 - moveLeftBy;
      }

      const updatedCurrentRight = containerWidth + updatedScrollLeft;
      const isOnRightEdge = updatedCurrentRight + 10 >= totalScrollWidth;
      const isOnLeftEdge = updatedScrollLeft - 10 <= 0;
      this.hideRight = isOnRightEdge;
      this.hideLeft = isOnLeftEdge;
    }

    setScroll() {
      const sliderContainerJquery = (0, _jquery.default)(`#${this.id}-content`);

      if (!_nventor.default.isNilOrEmpty(sliderContainerJquery[0])) {
        this.isScrollable = sliderContainerJquery[0].scrollWidth > sliderContainerJquery.innerWidth();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isScrollable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hideRight", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hideLeft", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "moveHorizontally", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "moveHorizontally"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setScroll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setScroll"), _class.prototype)), _class);
  _exports.default = ComponentsGroupBlogsComponent;
});
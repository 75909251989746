define("shop/pods/components/flows/flow-chat-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fyr6Nccr",
    "block": "[[[10,0],[14,0,\"chat-message-row\"],[12],[1,\"\\n  \"],[11,0],[16,0,[29,[\"chat-message \",[30,0,[\"messageFromClass\"]]]]],[4,[38,0],[[28,[37,1],[[30,0,[\"didInsert\"]]],null]],null],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"did-insert\",\"fn\",\"yield\"]]",
    "moduleName": "shop/pods/components/flows/flow-chat-message/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
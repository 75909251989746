define("shop/pods/components/social-login/facebook-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bk1ymOuK",
    "block": "[[[1,[28,[35,0],null,[[\"classNames\",\"icon\",\"label\",\"onSubmit\"],[\"button--solid facebook-login-btn\",\"fab fa-facebook-square\",\"loginByFB\",[28,[37,1],[[30,0],\"facebookLoginFlow\"],null]]]]],[1,\"\\n\\n\"]],[],false,[\"elements/element-btn\",\"action\"]]",
    "moduleName": "shop/pods/components/social-login/facebook-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/orders/status/route", ["exports", "@ember/routing/route", "shop/mixins/orders-status", "rsvp"], function (_exports, _route, _ordersStatus, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_ordersStatus.default, {
    model(params) {
      const website = this.modelFor('application').website;
      let shop; // let shopUrl

      if (website.get('shopKey')) {
        const modelForShopRoute = this.modelFor('shop');
        shop = modelForShopRoute === null || modelForShopRoute === void 0 ? void 0 : modelForShopRoute.shop;
      }

      return _rsvp.default.hash({
        website,
        shop,
        params
      });
    },

    redirect(model) {
      this._redirect(model);
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/items/item-set-with-choices/item-choice-group/item-choice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nTU82hGs",
    "block": "[[[10,0],[14,0,\"store-item__choice-row flex-container-row flex-container--align-items-start\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"store-item__choice-image-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-choice\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@backgroundColor\",\"@defaultAlt\",\"@figureClassNames\",\"@imgClassNames\",\"@imageData\",\"@width\",\"@autoResizeToRatio\"],[[30,0,[\"defaultImage\",\"defaultImageBackgroundColor\"]],[30,1,[\"itemName\"]],\"\",\"\",[30,0,[\"defaultImage\"]],\"100\",true]],null],[1,\" \\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"store-item__choice-detail-container flex-item--flex-grow-1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"desc-child__row\"],[12],[1,\"\\n      \"],[1,[30,1,[\"itemName\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"disabled\",\"hasPriceRange\",\"item\",\"qty\",\"minQty\",\"maxQty\",\"choice\",\"shop\",\"hasPriceRange\",\"onNewVariantChoice\",\"onChangeQty\",\"onToggleShowVariantPrice\"],[[33,2],[33,3],[30,1],[30,1,[\"_data\",\"qty\"]],0,[30,1,[\"itemMaxQty\"]],[33,4],[33,5],[33,3],[33,6],[28,[37,7],[[30,0],[33,8],[33,4],[33,9]],null],[28,[37,7],[[30,0],\"toggleShowVariantPrice\"],null]]]]],[1,\"\\n\\n\"],[41,[33,4,[\"isGroupComplete\"]],[[[41,[30,1,[\"noMoreChoicesMessage\"]],[[[1,\"        \"],[10,0],[14,0,\"desc-child__row\"],[12],[1,\"\\n          \"],[10,2],[14,0,\"is-size-6 has-text-danger\"],[12],[1,[28,[35,11],[[30,1,[\"noMoreChoicesMessage\"]]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"],[13],[1,\"\\n  \\n\"],[13],[1,\"\\n\"]],[\"@item\"],false,[\"elements/image-display\",\"cart/qty-selector-set-choice-variant\",\"disabled\",\"hasPriceRange\",\"choice\",\"shop\",\"onNewVariantChoice\",\"action\",\"onUpdateGroupSelectedQty\",\"item\",\"if\",\"tt\"]]",
    "moduleName": "shop/pods/components/items/item-set-with-choices/item-choice-group/item-choice/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
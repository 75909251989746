define("shop/pods/components/layouts/lazy-loader/lazy-loader-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4zhoIL3W",
    "block": "[[[10,3],[15,0,[29,[\"button is-primary load-more-btn is-small \",[52,[33,1,[\"isRunning\"]],\"is-loading\"]]]],[15,\"onclick\",[28,[37,2],[[33,1]],null]],[12],[1,[28,[35,3],[\"more\"],null]],[13]],[],false,[\"if\",\"onLazyLoadTask\",\"perform\",\"tt\"]]",
    "moduleName": "shop/pods/components/layouts/lazy-loader/lazy-loader-btn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
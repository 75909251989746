define("shop/pods/components/shop/init/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6dgZnB/b",
    "block": "[[[41,[30,0,[\"isAllowShop\"]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]],[[[41,[30,1],[[[1,\"\\n        \"],[10,0],[14,0,\"section checkout-form\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n            \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n              \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"hasErrorMessage\"]],[[[1,\"                  \"],[8,[39,4],null,[[\"@errorMessage\"],[[30,0,[\"errorMessage\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n                \"],[8,[39,5],null,[[\"@controlClassNames\"],[\"u-inline-flex full-width\"]],[[\"default\"],[[[[1,\"\\n                  \"],[8,[39,6],null,[[\"@type\",\"@leftIcon\",\"@value\",\"@classNames\",\"@inputPvtClassNames\"],[\"password\",\"fas fa-lock\",[30,0,[\"campaignPassword\"]],\"full-width\",\"element-input--with-input-and-button\"]],null],[1,\"\\n\\n                  \"],[8,[39,7],null,[[\"@classNames\",\"@isRunning\",\"@label\",\"@onSubmit\"],[\"button--with-input-and-button\",[30,0,[\"shopLoginTask\",\"isRunning\"]],\"submit\",[28,[37,8],[[30,0,[\"shopLoginTask\"]]],null]]],null],[1,\"\\n                \"]],[]]]]],[1,\"\\n              \"]],[]]]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[\"@shop\",\"&default\"],false,[\"if\",\"yield\",\"layouts/page-card\",\"layouts/page-card/card-content\",\"elements/message-block/error-message\",\"form-row\",\"elements/element-input\",\"elements/element-btn\",\"perform\"]]",
    "moduleName": "shop/pods/components/shop/init/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/utils/nventor", ["exports", "ramda", "rsvp", "@ember/runloop", "@ember/object"], function (_exports, R, _rsvp, _runloop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const nventor = (_obj = {
    log(data) {
      return data;
    },

    // (A) STRIP TAGS FUNCTION
    stripTags(original) {
      // (A1) PARSE STRING INTO NEW HTML DOCUMENT
      try {
        const parsed = new window.DOMParser().parseFromString(original, 'text/html'); // (A2) STRIP TAGS, RETURN AS TEXT CONTENT

        return parsed.body.textContent;
      } catch (e) {
        return original;
      }
    },

    dotPath: R.curry((dotPath, obj) => {
      const path = R.split('.')(dotPath);
      return R.path(path)(obj);
    }),

    goToClassName(className, offset) {
      const element = document.querySelector(`.${className}`);

      if (this.isNilOrEmpty(element)) {
        return;
      }

      offset = offset || 0;
      (0, _runloop.scheduleOnce)('afterRender', () => {
        window.$('html, body').animate({
          scrollTop: window.$(`.${className}`).offset().top + offset
        }, 200);
      });
    },

    goToTop(offset) {
      offset = offset || 0;
      (0, _runloop.scheduleOnce)('afterRender', () => {
        window.$('html, body').animate({
          scrollTop: window.$('body').offset().top + offset
        }, 200);
      });
    },

    goToBottom(className, offset = 0) {
      offset = offset || 0;
      (0, _runloop.scheduleOnce)('afterRender', () => {
        const container = window.document.querySelector(`.${className}`);

        if (container) {
          const height = container.scrollHeight;
          container.scrollTo({
            top: offset || height,
            behavior: 'smooth'
          });
        } // window.$(container).animate({
        //   scrollTop: offset
        // }, 200)

      });
    },

    throw(e, msg, data) {
      const isDev = true;

      if (isDev) {
        console.log('----error message----');
        console.log(msg);
        console.log('----error----');
        console.log(e);
        console.log('^^^^^^^^^^^^^');

        if (data) {
          console.log('----error context----');

          if (!R.is(Array, data)) {
            data = [data];
          }

          R.forEach(d => {
            console.log(d);
            console.log('^^^^^^^^^^^^^');
          }, data);
          console.log('=============');
        } // throw msg

      } else {// log message somewhere
      }
    },

    /**
     * creates an error
     */
    error(name, details) {
      const error = new Error(name);
      error.details = details;
      return error;
    },

    resolve(data) {
      return new _rsvp.default.Promise(function (resolve) {
        resolve(data);
      });
    },

    reject(data) {
      return new _rsvp.default.Promise(function (resolve, reject) {
        reject(data);
      });
    },

    isNotNilOrEmpty: R.curry(data => {
      if (R.isNil(data) || R.isEmpty(data)) {
        return false;
      }

      return true;
    }),
    isNilOrEmpty: R.curry(data => {
      if (R.isNil(data) || R.isEmpty(data)) {
        return true;
      }

      return false;
    }),
    isNilOrEmptyOr: R.curry((defaultValue, obj) => {
      return R.when(nventor.isNilOrEmpty, R.always(defaultValue))(obj);
    }),
    isNilOrEmptyPathOr: R.curry((defaultValue, path, obj) => {
      return R.pipe(R.path(path), R.when(nventor.isNilOrEmpty, R.always(defaultValue)))(obj);
    }),
    safeViewLensPathOr: R.curry((defaultValue, path, obj) => {
      try {
        return R.view(R.lensPath(path))(obj);
      } catch (e) {
        return defaultValue;
      }
    }),
    mapIndexed: R.addIndex(R.map),

    safeLast(array) {
      if (R.isEmpty(array) || !R.is(Array, array)) {
        return '';
      }

      return R.last(array);
    },

    safeLastOr: R.curry((defaultValue, array) => {
      if (R.isEmpty(array) || !R.is(Array, array)) {
        return defaultValue;
      }

      return R.last(array);
    }),

    safeHead(array) {
      if (R.isEmpty(array) || !R.is(Array, array)) {
        return '';
      }

      return R.head(array);
    },

    safeHeadOr: R.curry((defaultValue, array) => {
      if (R.isEmpty(array) || !R.is(Array, array)) {
        return defaultValue;
      }

      return R.head(array);
    }),

    safeTrim(text) {
      if (R.is(String)(text)) {
        return R.trim(text);
      }

      return '';
    },

    sortAscending: R.sort((a, b) => a - b),

    arrayToObject(array, keyField) {
      return array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
      }, {});
    },

    parseParams(url) {
      if (!R.is(String)(url)) {
        return {};
      }

      return R.pipe(decodeURIComponent, R.split('?'), // [?, a=1&b=2]
      R.drop(1), // [a=1&b=2]
      R.map(R.split('&')), // [ [a=1], [b=2] ]
      R.map(R.map(R.split('='))), // [ [a, 1], [b, 2] ]
      R.unnest, R.fromPairs)(url);
    },

    parseParamsFromWindowLocation(location) {
      if (!location) {
        location = window.location;
      } // need to handle params that are arrays
      // a[]=1&a[]=2 ==> a: [1, 2]
      // a=&b=2
      // { a: '', b: 2}


      return R.pipe(R.pathOr('', ['search']), // ?a=1&b=2
      decodeURIComponent, R.split('?'), // [?, a=1&b=2]
      R.drop(1), // [a=1&b=2]
      R.map(R.split('&')), // [ [a=1], [b=2] ]
      R.map(R.map(R.split('='))), // [ [a, 1], [b, 2] ]
      R.unnest, R.fromPairs)(location);
    },

    removeAndUpdateQueryParams(toRemoveParams = []) {
      const currentUrl = R.pipe(R.split('?'), this.safeHeadOr(false))(window.location.href);

      if (currentUrl) {
        const params = this.parseParamsFromWindowLocation(window.location);
        const updatedParams = R.omit(toRemoveParams)(params);
        let newUrl = currentUrl;

        if (!R.isEmpty(updatedParams)) {
          const queryString = R.pipe(R.toPairs, R.map(R.join('=')), R.join('&'))(updatedParams);
          newUrl = `${newUrl}?` + queryString;
        }

        window.history.replaceState(updatedParams, null, newUrl);
        return newUrl;
      }

      return false;
    },

    /**
     * rename object keys
     * @param  {object} keySchema: object containing { oldKey: newKey}
     * @param  {object} target: object to perform renaming
     * @return {object} object with keys renamed
     */
    renameKeys: R.curry((keySchema, target) => {
      const rename = R.curry((keysMap, obj) => R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj)));
      return rename(keySchema)(target);
    }),

    /**
     * returns default value if value does not exist
     * @param  {mixed} value
     * @param  {mixed} defaultValue
     * @return {mixed} either value or default
     */
    returnDefault(value, defaultValue) {
      if (defaultValue != null) {
        return defaultValue;
      }

      return value;
    },

    getFilesArray(filesList) {
      let filesArray = [];

      if (filesList.length === 0) {
        filesArray = false;
      } else {
        filesArray = Array.prototype.slice.call(filesList);
      }

      return filesArray;
    },

    mergeRightWhenNotEmpty(obj1, obj2) {
      const overwriteIfEmpty = (key, left, right) => {
        if (this.isNilOrEmpty(right)) {
          return left;
        }

        return right;
      };

      return R.mergeWithKey(overwriteIfEmpty, obj1, obj2);
    },

    mergeRightWhenExists(obj1, obj2) {
      const overwriteIfEmpty = (key, left, right) => {
        if (R.isNil(right)) {
          return left;
        }

        return right;
      };

      return R.mergeWithKey(overwriteIfEmpty, obj1, obj2);
    },

    getAsInt(str, defaultValue = 0) {
      try {
        return parseInt(str);
      } catch (e) {
        return defaultValue;
      }
    },

    safeParseJSON(jsonString) {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        return {};
      }
    },

    random: {
      _alpha() {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      },

      _digits() {
        return '0123456789';
      },

      digits(length) {
        let random = '';

        const possible = this._digits();

        for (let i = 0; i < length; i++) {
          random += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return random;
      },

      alpha(length) {
        let random = '';

        const possible = this._alpha();

        for (let i = 0; i < length; i++) {
          random += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return random;
      },

      alphaNum(length) {
        let random = '';

        const possible = this._alpha() + this._digits();

        for (let i = 0; i < length; i++) {
          random += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return random;
      }

    },

    /**
     * Mask String
     * @param {string} str
     * @param {Number} start
     * @param {Number} end
     * @param {string} placeholder
     * @param {Boolean} oneToOne
     * @return {string}
     */
    maskString(str, {
      start = 0,
      end = 0,
      placeholder,
      oneToOne = false
    }) {
      const stringLength = str.length;
      let startStr = '';

      if (start) {
        if (str.length <= start) {
          startStr = R.take(1)(str);
        } else {
          startStr = R.take(start)(str);
        }
      }

      let endStr = '';

      if (end) {
        if (str.length <= end) {
          endStr = R.takeLast(1)(str);
        } else {
          endStr = R.takeLast(end)(str);
        }
      }

      if (!oneToOne && (startStr || endStr)) {
        return `${startStr}${placeholder}${endStr}`;
      }

      if (startStr && !endStr) {
        return startStr.padEnd(stringLength, placeholder);
      } else if (!startStr && endStr) {
        return endStr.padStart(stringLength, placeholder);
      } else if (startStr || endStr) {
        const middleStr = R.pipe(R.drop(start), R.dropLast(end), R.replace(/./g, placeholder))(str);
        return `${startStr}${middleStr}${endStr}`;
      }

      return '';
    },

    hexToRGBA(hex) {
      let r = 0;
      let g = 0;
      let b = 0;
      let a = 1;

      if (hex.length === 5) {
        r = '0x' + hex[1] + hex[1];
        g = '0x' + hex[2] + hex[2];
        b = '0x' + hex[3] + hex[3];
        a = '0x' + hex[4] + hex[4];
      } else if (hex.length === 9) {
        r = '0x' + hex[1] + hex[2];
        g = '0x' + hex[3] + hex[4];
        b = '0x' + hex[5] + hex[6];
        a = '0x' + hex[7] + hex[8];
      }

      a = +(a / 255).toFixed(3);
      return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + a + ')';
    },

    defaultWhen: R.curry((criteria, defaultVal, val) => {
      if (criteria(val)) {
        return defaultVal;
      }

      return val;
    }),
    isEven: R.curry(number => {
      return number % 2 == 0;
    }),
    isOdd: R.curry(number => {
      return number % 2 == 1;
    }),

    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    propNotEq: R.curry((key, value, object) => {
      const propValue = R.prop(key, object);
      return value !== propValue;
    }),
    propOrWhenIsNilOrEmpty: R.curry((defaultValue, key, object) => {
      const value = R.prop(key, object);

      if (nventor.isNilOrEmpty(value)) {
        return defaultValue;
      }

      return value;
    }),

    removeTrailingSlash(str = '') {
      try {
        // Use a regular expression to remove trailing forward slash if it exists
        return str.replace(/\/$/, '');
      } catch (error) {
        console.error('cannot remove trailing slash', str);
        return str;
      }
    },

    isOverflown({
      clientWidth,
      clientHeight,
      scrollWidth,
      scrollHeight
    }) {
      return scrollHeight > clientHeight || scrollWidth > clientWidth;
    },

    isURLEncoded(url) {
      try {
        return decodeURIComponent(url) !== url;
      } catch (e) {
        // If decoding throws an error, the URL might be malformed
        return false;
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "goToClassName", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToClassName"), _obj)), _obj);
  var _default = nventor;
  _exports.default = _default;
});
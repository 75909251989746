define("shop/pods/contact-us/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FTJsD2Rp",
    "block": "[[[8,[39,0],null,[[\"@website\",\"@shop\",\"@page\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"page\"]]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"card\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"website\",\"companyName\"]],[[[1,\"            \"],[10,2],[14,0,\"subtitle\"],[12],[1,\"\\n              \"],[1,[30,0,[\"model\",\"website\",\"companyName\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"website\",\"companyTel\"]],[[[1,\"            \"],[10,2],[14,0,\"subtitle\"],[12],[1,\"\\n              \"],[10,3],[15,6,[29,[\"tel:\",[30,0,[\"model\",\"website\",\"companyTel\"]]]]],[12],[1,\"\\n                \"],[1,[30,0,[\"model\",\"website\",\"companyTel\"]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"website\",\"address\"]],[[[1,\"            \"],[10,2],[14,0,\"subtitle\"],[12],[1,\"\\n              \"],[1,[30,0,[\"model\",\"website\",\"address\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"website\",\"companyAddress\"]],[[[1,\"            \"],[10,2],[14,0,\"subtitle\"],[12],[1,\"\\n              \"],[10,3],[14,\"rel\",\"noreferrer noopener\"],[14,\"target\",\"_blank\"],[15,6,[29,[\"http://maps.google.com/maps?q=\",[30,0,[\"model\",\"website\",\"companyAddressLink\"]]]]],[12],[1,\"\\n                \"],[1,[30,0,[\"model\",\"website\",\"companyAddress\"]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\"]],[\"websiteContainer\"],false,[\"website/website-container\",\"if\"]]",
    "moduleName": "shop/pods/contact-us/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/checkout/service", ["exports", "@ember/service", "ember-concurrency-decorators", "@glimmer/tracking", "ramda", "shop/utils/nventor", "@ember/object"], function (_exports, _service, _emberConcurrencyDecorators, _tracking, R, _nventor, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CheckoutService = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('google'), (_class = class CheckoutService extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "storage", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "googleService", _descriptor5, this);

      _initializerDefineProperty(this, "cartItems", _descriptor6, this);

      _initializerDefineProperty(this, "checkoutId", _descriptor7, this);

      _initializerDefineProperty(this, "checkoutError", _descriptor8, this);

      _defineProperty(this, "defaultErrorMessage", 'an error has occurred on your previous transaction, please contact us. or retry with the checkout, but may lead to duplicate orders');

      _defineProperty(this, "twoDays", 1.728e+8);

      this.getCheckoutId();
    }

    get cartItems() {
      // @TODO: YES ITS, DO NOT REMOVE
      const website = R.pathOr({}, ['websiteService', 'website'])(this);
      const cartItems = this.storage.getValue(`cartItems${website.shopKey}`);
      this.cartItems = cartItems;
      return cartItems;
    }

    get errorMessage() {
      const website = R.pathOr({}, ['websiteService', 'website'])(this);
      const errorMessage = this.intl.t(this.defaultErrorMessage);
      const contactUs = this.intl.t('contact us');
      return `${errorMessage}

    ${contactUs}:
    ${website.companyName}
    ${website.companyTel}
    `;
    }

    get allowedCountries() {
      return R.pathOr([], ['websiteService', 'website', 'shop', '_data', 'countries'])(this);
    }

    get isSupportedCountryForMember() {
      var _user$_data;

      const user = R.pathOr({}, ['websiteService', 'website', 'user'])(this);

      if (user.isGuest) {
        return true;
      }

      const userCountryCode = user === null || user === void 0 ? void 0 : (_user$_data = user._data) === null || _user$_data === void 0 ? void 0 : _user$_data.countryCode;

      if (user.isMember && userCountryCode) {
        const allowedCountries = this.allowedCountries;
        const found = R.find(R.propEq('code', userCountryCode))(allowedCountries);

        if (found) {
          return true;
        }
      }

      return false;
    }

    getCheckoutId() {
      this.checkoutId = this.storage.getValueWithShopKey('checkoutId');
      return this.checkoutId;
    }

    setupCheckoutId() {
      const website = R.pathOr({}, ['websiteService', 'website'])(this);
      this.getCheckoutId();

      if (!this.checkoutId) {
        var _website$user, _website$user$_data;

        this.checkoutId = `${website === null || website === void 0 ? void 0 : website.podKey}_${website === null || website === void 0 ? void 0 : website.shopKey}_${website === null || website === void 0 ? void 0 : (_website$user = website.user) === null || _website$user === void 0 ? void 0 : (_website$user$_data = _website$user._data) === null || _website$user$_data === void 0 ? void 0 : _website$user$_data.memberId}_${_nventor.default.random.alphaNum(32)}`;
        this.storage.setValueWithShopKey('checkoutId', this.checkoutId, this.twoDays);
      }
    }

    clearCheckoutId() {
      this.checkoutId = '';
      this.checkoutError = null;
      this.storage.removeWithShopKey('checkoutId');
      this.websiteService.clearMessages({
        clearAll: true
      });
    }

    getCheckoutFormUid() {
      this.checkoutFormUid = this.storage.getValueWithShopKey('checkoutFormUid');
      return this.checkoutFormUid;
    }

    setupCheckoutFormUid() {
      const website = R.pathOr({}, ['websiteService', 'website'])(this);
      this.getCheckoutFormUid();

      if (!this.checkoutFormUid) {
        var _website$user2, _website$user2$_data;

        this.checkoutFormUid = `${website === null || website === void 0 ? void 0 : website.podKey}_${website === null || website === void 0 ? void 0 : website.shopKey}_${website === null || website === void 0 ? void 0 : (_website$user2 = website.user) === null || _website$user2 === void 0 ? void 0 : (_website$user2$_data = _website$user2._data) === null || _website$user2$_data === void 0 ? void 0 : _website$user2$_data.memberId}_${_nventor.default.random.alphaNum(32)}`;
        this.storage.setValueWithShopKey('checkoutFormUid', this.checkoutFormUid, this.twoDays);
      }
    }

    clearCheckoutFormUid() {
      this.checkoutFormUid = '';
      this.checkoutError = null;
      this.storage.removeWithShopKey('checkoutFormUid');
      this.websiteService.clearMessages({
        clearAll: true
      });
    }

    *queryCheckoutStatus(shop) {
      // @TODO: currently this is not working as expected
      return; // @NAFIS: should call the function?
      // const checkoutId = this.getCheckoutId()

      if (this.checkoutId) {
        const res = yield this.websiteService.request(`/api/public/channels/shop/checkout/track/${this.checkoutId}`, {
          method: 'GET'
        }).then(R.prop('data')).catch(console.error);
        this.checkoutError = R.propOr(null, 'error')(res);

        if (!_nventor.default.isNilOrEmpty(res === null || res === void 0 ? void 0 : res.response)) {
          return this.handleCheckoutIdResponse(shop, res);
        }
      }

      return {};
    }

    handleCheckoutIdResponse(shop, res) {
      const email = res.email;
      const url = shop.get('_data.url');
      shop.cartSuccessClear();
      this.clearCheckoutId();
      this.router.transitionTo('shop.orders.status', url, email, res.response.ref);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "googleService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cartItems", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "checkoutId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "checkoutError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupCheckoutId", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupCheckoutId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearCheckoutId", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearCheckoutId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCheckoutFormUid", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getCheckoutFormUid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupCheckoutFormUid", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupCheckoutFormUid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearCheckoutFormUid", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearCheckoutFormUid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "queryCheckoutStatus", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "queryCheckoutStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCheckoutIdResponse", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCheckoutIdResponse"), _class.prototype)), _class));
  _exports.default = CheckoutService;
});
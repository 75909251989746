define("shop/pods/website/unsubscribe/success/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ZRyNQ2O",
    "block": "[[[8,[39,0],null,[[\"@page\",\"@params\",\"@query\",\"@shop\",\"@website\"],[[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"params\"]],[30,0,[\"model\",\"params\",\"query\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@website\",\"@resubscribeToken\"],[[30,0,[\"model\",\"website\"]],[30,0,[\"model\",\"resubscribeToken\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"website/website-container\",\"layouts/page-content\",\"shop/unsubscribe/success\"]]",
    "moduleName": "shop/pods/website/unsubscribe/success/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
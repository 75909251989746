define("shop/pods/components/chat-btn/chat-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xn8/4orT",
    "block": "[[[10,0],[14,0,\"chat-options__container\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@facebookPage\",\"@facebookThemeColor\",\"@hasFacebookChatPlugin\",\"@isMobile\",\"@shop\",\"@website\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6]]],null],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@isMobile\",\"@shop\",\"@website\"],[[30,4],[30,5],[30,6]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@facebookPage\",\"@facebookThemeColor\",\"@hasFacebookChatPlugin\",\"@isMobile\",\"@shop\",\"@website\"],false,[\"chat-btn/fb-chat-btn\",\"chat-btn/line-chat-btn\"]]",
    "moduleName": "shop/pods/components/chat-btn/chat-options/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
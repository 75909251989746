define("shop/pods/line/service", ["exports", "@ember/service", "shop/config/environment", "ramda"], function (_exports, _service, _environment, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TARGET = '_self';

  var _default = _service.default.extend({
    router: (0, _service.inject)(),
    socialLogin: (0, _service.inject)(),

    /**
     * on click of login btn,
     * call api to get redirected to bigordr's login page
     */
    startLoginFlow() {
      const url = this.socialLogin.generateLoginUrl('line');
      window.open(url, TARGET);
    },

    /**
     * on load of bigordr login page, redirect immediately to social login page
     */
    redirectToLoginPage() {
      const queryString = this.websiteService.parseParamsFromWindowLocation(window.location);
      const hasLoginParams = R.has('state')(queryString);

      if (hasLoginParams) {
        const lineReq = `${_environment.default.line.api_url}?` + `response_type=${_environment.default.line.api_response_type}&` + `client_id=${_environment.default.line.app_client_id}&` + `redirect_uri=${_environment.default.line.app_redirect_uri}&` + `state=${queryString.state}&` + `scope=${_environment.default.line.api_scope}&` + `ui_locales=${_environment.default.line.api_ui_locales}`;
        window.open(lineReq, TARGET);
      } else {
        this.storage.removeWithShopKey('externalUrlForScheduledPayment');
        return this.router.transitionTo('/');
      }
    },

    /**
     * on success of login from social page, redirect back to original website
     */
    redirectToOriginalWebsite() {
      const url = this.socialLogin.redirectToOrigin('line');

      if (url) {
        window.open(url, TARGET);
      } else {
        this.storage.removeWithShopKey('externalUrlForScheduledPayment');
        return this.router.transitionTo('/');
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/shop/checkout/failed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1rRXMFkT",
    "block": "[[[10,0],[14,0,\"message-card card\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"card-header-title has-text-danger\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"oops sorry\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"websiteService\",\"isUserAgentInstagram\"]],[[[1,\"        \"],[10,2],[14,0,\"has-text-danger\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@icon\"],[\"fas fa-exclamation-circle\"]],null],[1,\"\\n          如使用 Instagram (IG) 瀏覽器可能會有無法刷卡的問題請使用其他瀏覽器開啟。\"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"footer\"],[14,0,\"card-footer\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"card-footer-item\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@shop\",\"@website\"],[[30,1,[\"shop\"]],[30,1,[\"website\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"card-footer-item\"],[12],[1,\"\\n      \"],[8,[39,6],null,[[\"@shop\",\"@website\"],[[30,1,[\"shop\"]],[30,1,[\"website\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\"],false,[\"tt\",\"if\",\"elements/element-icon\",\"component\",\"-outlet\",\"shop/back-btn\",\"cart/checkout-btn\"]]",
    "moduleName": "shop/pods/shop/checkout/failed/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
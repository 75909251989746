define("shop/pods/components/cart/content-summary/out-of-stock-line/component", ["exports", "@ember/component", "@ember/object", "ramda", "shop/utils/nventor"], function (_exports, _component, _object, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    images: (0, _object.computed)('cartItem.uploadedFiles.[]', function () {
      const images = this.get('cartItem.uploadedFiles') || [];
      return images.sortBy('timestampZ');
    }),
    defaultImage: (0, _object.computed)('images.@each.isDefault', function () {
      const images = this.images || [];
      const defaultImageData = R.find(R.propEq('isDefault', true))(images);

      if (defaultImageData) {
        return defaultImageData;
      }

      if (this.images.get('firstObject')) {
        const firstImage = this.images.get('firstObject');

        if (firstImage) {
          return firstImage;
        }
      }

      return false;
    }),
    inclAmt: (0, _object.computed)('cartItem.qty', function () {
      const cartItem = this.cartItem;
      return this.shop.calculateCartLineAmt(cartItem);
    }),
    isOverPersonLimitedQty: (0, _object.computed)('cartItem.isNotAllowedReason.@each', function () {
      const cartItem = this.cartItem;
      const isNotAllowedReason = cartItem.isNotAllowedReason || [];
      const hasOverLimitedQtyPerCheckout = R.pipe(R.find(R.equals('over limited qty per checkout')), _nventor.default.isNotNilOrEmpty)(isNotAllowedReason);

      if (hasOverLimitedQtyPerCheckout) {
        return true;
      }

      const hasOverLimitedQty = R.pipe(R.find(R.equals('over limited qty')), _nventor.default.isNotNilOrEmpty)(isNotAllowedReason);

      if (hasOverLimitedQty) {
        return true;
      }

      return false;
    }),
    actions: {
      updateCartItemQty(group, limitedQtyCartItem, qty) {
        const limitedQtyCartItemIndex = limitedQtyCartItem.cartIndex;
        const cartItem = R.pipe(R.pathOr([], ['shop', 'cartItems']), R.find(R.propEq('cartIndex', limitedQtyCartItemIndex)))(this);
        this.shop.updateCartItemQty({
          group,
          cartItem,
          qty
        });
        this.onCartChanged();
      },

      removeCartItem(group, cartItem) {
        this.shop.removeCartItem(group, cartItem);
        this.onCartChanged();
      }

    }
  });

  _exports.default = _default;
});
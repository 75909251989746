define("shop/mixins/base", ["exports", "@ember/object/mixin", "@ember/service", "shop/utils/nventor"], function (_exports, _mixin, _service, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    validatorService: (0, _service.inject)('validator'),

    addSchema(name, schema) {
      const schemaName = `schema_${name}`;
      this.set(schemaName, schema);
    },

    getSchema(name) {
      const schemaName = `schema_${name}`;
      return this.get(schemaName);
    },

    validate(name, data = {}) {
      if (_nventor.default.isNilOrEmpty(data)) {
        data = this.serialize();
      }

      const schema = this.getSchema(name);
      return this.validatorService.validate(schema, data);
    },

    serialize() {
      const data = this._data || {};
      return JSON.parse(JSON.stringify(data));
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/elements/check-box/component", ["exports", "@ember/component", "jquery"], function (_exports, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    translate: true,
    classNames: ['element-checkbox--container'],
    classNameBindings: ['disabled:element-checkbox--disabled', 'value:is-selected'],
    allowToggle: true,

    toggle() {
      if (!this.disabled && this.allowToggle) {
        const customToggler = this.onToggle;
        const value = this.value;

        if (customToggler) {
          return customToggler(value);
        }

        this.toggleProperty('value');
        const onAfterToggle = this.onAfterToggle;

        if (onAfterToggle) {
          return onAfterToggle(value);
        }
      }
    },

    actions: {
      toggle() {
        this.toggle();
      }

    }
  });

  _exports.default = _default;
});
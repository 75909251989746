define("shop/pods/components/layouts/content-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bwOgwiEL",
    "block": "[[[10,0],[14,0,\"container\"],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"website\",\"content\",\"params\"],[[33,4],[33,5],[33,6]]]]],[1,\"\\n\"]],[]]],[13]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"content-display\",\"website\",\"content\",\"params\"]]",
    "moduleName": "shop/pods/components/layouts/content-container/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/facebook/service", ["exports", "@ember/service", "shop/config/environment", "ramda"], function (_exports, _service, _environment, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TARGET = '_self';

  var _default = _service.default.extend({
    router: (0, _service.inject)(),
    storage: (0, _service.inject)(),
    socialLogin: (0, _service.inject)(),

    /**
     * on click of login btn,
     * call api to get redirected to bigordr's login page
     */
    startLoginFlow() {
      const url = this.socialLogin.generateLoginUrl('facebook');
      window.open(url, TARGET);
    },

    /**
     * on load of bigordr login page, redirect immediately to social login page
     */
    redirectToLoginPage() {
      const queryString = this.websiteService.parseParamsFromWindowLocation(window.location);
      const hasLoginParams = R.has('state')(queryString);

      if (hasLoginParams) {
        const url = `${_environment.default.facebook.authUrl}?client_id=${_environment.default.facebook.appId}&redirect_uri=${_environment.default.facebook.redirectUrl}&state=${queryString.state}&auth_type=rerequest&scope=email`;
        window.open(url, TARGET);
      } else {
        this.storage.removeWithShopKey('externalUrlForScheduledPayment');
        return this.router.transitionTo('/');
      }
    },

    /**
     * on success of login from social page, redirect back to original website
     */
    redirectToOriginalWebsite() {
      const url = this.socialLogin.redirectToOrigin('facebook');

      if (url) {
        window.open(url, TARGET);
      } else {
        this.storage.removeWithShopKey('externalUrlForScheduledPayment');
        return this.router.transitionTo('/');
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/page-steps/step-display/component", ["exports", "@ember/component", "@ember/service", "@ember/object", "ramda"], function (_exports, _component, _service, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    router: (0, _service.inject)(),
    isDisabled: (0, _object.computed)('currentStep', 'steps.[]', 'index', function () {
      const steps = this.steps || [];
      const currentStep = this.currentStep;
      const index = this.index;
      const currentIndex = R.indexOf(currentStep)(steps);

      if (index >= currentIndex) {
        return true;
      }

      return false;
    }),
    stepNumber: (0, _object.computed)('index', function () {
      return this.index + 1;
    }),
    stepClassName: (0, _object.computed)('currentStep', 'steps.[]', 'index', function () {
      const steps = this.steps || [];
      const currentStep = this.currentStep;
      const index = this.index;
      const currentIndex = R.indexOf(currentStep)(steps);

      if (index === currentIndex) {
        return 'current-step';
      }

      if (index < currentIndex) {
        return 'previous-step is-tappable';
      }

      return 'next-step';
    }),
    isLastStep: (0, _object.computed)('stepNumber', 'steps.[]', function () {
      const steps = this.steps || [];

      if (this.stepNumber === steps.length) {
        return true;
      }

      return false;
    }),
    allowClick: (0, _object.computed)('stepNumber', 'steps.[]', function () {
      if (this.stepNumber < this.currentIndex) {
        return true;
      }

      return false;
    }),
    actions: {
      goToStep(step) {
        if (!this.isDisabled) {
          const stepRoute = R.replace('_', '.')(step);
          return this.router.transitionTo(`shop.${stepRoute}`);
        }

        return false;
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/shop/checkout/details/route", ["exports", "@ember/routing/route", "@ember/service", "shop/pods/shop/shop-route-mixin"], function (_exports, _route, _service, _shopRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_shopRouteMixin.default, {
    router: (0, _service.inject)(),

    model(params) {
      const fetchMember = true;
      return this._model(params, {
        fetchMember
      });
    },

    afterModel(model) {
      const website = model.website;

      if (!website.get('shop.hasCartItems')) {
        this.router.transitionTo('shop');
      }

      const shop = model.shop;

      if (shop.get('hasExpiredGracefully')) {
        this.router.transitionTo('shop');
      }

      if (shop !== null && shop !== void 0 && shop.hasRecurringItems && !website.isLoggedIn) {
        this.router.transitionTo('shop.members');
      }
    }

  });

  _exports.default = _default;
});
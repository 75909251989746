define("shop/pods/line/callback-from-line/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YW0ShPpr",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"]],[],false,[\"helpers/route-loading\"]]",
    "moduleName": "shop/pods/line/callback-from-line/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
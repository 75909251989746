define("shop/pods/components/items/status-tag/component", ["exports", "@ember/component", "@ember/object", "ramda"], function (_exports, _component, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    hasTag: (0, _object.computed)('status', function () {
      const status = this.status;
      const showTagsFor = ['preOrder', 'outOfStock', 'soldOut'];
      return R.includes(status, showTagsFor);
    }),
    allowQtySelector: (0, _object.computed)('hasTag', function () {
      const status = this.status;

      if (status === 'preOrder') {
        return true;
      }

      return false;
    }),
    classes: (0, _object.computed)('status', function () {
      const status = this.status;
      let classes = '';

      switch (status) {
        case 'preOrder':
          classes = 'is-warning is-medium';
          break;

        case 'soldOut':
          classes = 'is-black is-medium';
          break;

        case 'outOfstock':
          classes = 'is-black is-medium';
          break;
      }

      return classes;
    })
  });

  _exports.default = _default;
});
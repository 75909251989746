define("shop/pods/components/cart/content-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "THECq6Vu",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"modal cart-content-modal is-active\"],[12],[1,\"\\n    \"],[11,0],[24,0,\"is-tappable modal-background\"],[4,[38,2],[[30,0],[33,3]],null],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"modal-card cart-modal-card\"],[12],[1,\"\\n      \"],[10,\"header\"],[14,0,\"modal-card-head\"],[12],[1,\"\\n\\n        \"],[10,2],[14,0,\"modal-card-title\"],[12],[1,[28,[35,4],[\"cart\"],null]],[13],[1,\"\\n\\n        \"],[11,\"button\"],[24,0,\"delete\"],[24,\"aria-label\",\"close\"],[4,[38,2],[[30,0],[33,3]],null],[12],[13],[1,\"\\n\\n      \"],[13],[1,\"\\n      \"],[10,\"section\"],[14,0,\"modal-card-body\"],[12],[1,\"\\n        \"],[1,[28,[35,5],null,[[\"isSummaryStep\",\"shop\",\"hasNavBar\",\"onBackAction\"],[false,[33,6],false,[33,3]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"isCartModalVisible\",\"action\",\"onHideCartModal\",\"tt\",\"shop/checkout/summary-page\",\"shop\"]]",
    "moduleName": "shop/pods/components/cart/content-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
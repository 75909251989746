define("shop/pods/line/login/route", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    line: (0, _service.inject)(),

    model() {
      this.line.redirectToLoginPage();
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/members/rewards-history-content/component", ["exports", "ramda", "@glimmer/component", "@ember/service", "ember-concurrency-decorators", "@glimmer/tracking", "shop/utils/nventor"], function (_exports, R, _component, _service, _emberConcurrencyDecorators, _tracking, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MembersRewadsHistoryContentComponent = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('filters'), _dec3 = (0, _service.inject)('members'), _dec4 = (0, _service.inject)('date'), (_class = class MembersRewadsHistoryContentComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _initializerDefineProperty(this, "filtersService", _descriptor3, this);

      _initializerDefineProperty(this, "membersService", _descriptor4, this);

      _initializerDefineProperty(this, "dateService", _descriptor5, this);

      _initializerDefineProperty(this, "filterModel", _descriptor6, this);

      _initializerDefineProperty(this, "defaultData", _descriptor7, this);

      const params = this.args.params || {};
      params.count = 30;
      const websiteData = R.pathOr({}, ['args', 'website'])(this);
      const shopData = R.pathOr({}, ['shop', '_data'])(websiteData);
      let tags = R.pipe(R.pathOr([], ['args', 'rewardsList']), R.map(data => {
        data.isNotAllowMultiple = true;
        data.label = data.name;
        return data;
      }))(this) || [];

      if (_nventor.default.isNotNilOrEmpty(websiteData)) {
        tags = R.prepend({
          name: shopData.companyName || '',
          label: shopData.companyName || '',
          isHeader: true,
          isNotAllowMultiple: true
        }, tags);
      }

      const defaultData = {
        tags
      };
      this.defaultData = defaultData;
      this.filterModel = this.filtersService.setupFilters(defaultData, {
        params
      });
      this.fetchTask.perform();
    }

    *fetchTask(params = {}) {
      const website = this.args.website;
      let startDateZ = '';
      let endDateZ = ''; // default value for n-month contact rewards data

      const selectedMonths = parseInt(params.selectedMonths) || 3;

      if (selectedMonths) {
        if (selectedMonths > 0) {
          startDateZ = this.dateService.getMoment().subtract(selectedMonths, 'months').toISOString();
          endDateZ = this.dateService.getMoment().toISOString();
        }
      }

      if (_nventor.default.isNilOrEmpty(params)) {
        const initialFilter = R.pipe(R.pathOr([], ['filterModel', '_data', 'tags']), R.reject(R.propEq('isHeader', true)), _nventor.default.safeHeadOr({}))(this);
        const tag = R.propOr('', 'label')(initialFilter);

        if (!R.isEmpty(initialFilter)) {
          params = {
            tags: [tag]
          };
          initialFilter.isSelected = true;
        }
      }

      let response = {};

      if (!_nventor.default.isNilOrEmpty(params.tags)) {
        const rewardsList = this.args.rewardsList;

        const tag = _nventor.default.safeHead(params.tags);

        const reward = R.find(R.propEq('name', tag))(rewardsList);

        if (!_nventor.default.isNilOrEmpty(reward)) {
          params.rewardKey = R.propOr('', '_key')(reward);
          params.startDateZ = startDateZ;
          params.endDateZ = endDateZ;
          response = yield this.membersService.get('fetchRewardHistoryTask').perform({
            website,
            podKey: reward.podKey,
            params
          });
        }
      }

      this.filterModel.rewardHistory = R.propOr([], 'rewardHistory')(response);
      this.filterModel.memberLevel = R.propOr('', 'memberLevel')(response);
      this.filterModel.memberLevelPoints = R.propOr(0, 'memberLevelPoints')(response);
      this.filterModel.pendingPoints = R.propOr(0, 'pendingPoints')(response);
      this.filterModel.validPoints = R.propOr(0, 'validPoints')(response);
      this.filterModel.params = params;
      this.filterModel.totalCount = 0;
      this.filterModel = this.filterModel;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filterModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "defaultData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTask"), _class.prototype)), _class));
  _exports.default = MembersRewadsHistoryContentComponent;
});
define("shop/pods/components/flows/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vVFWa/DS",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@params\",\"@website\",\"@page\",\"@eventType\",\"@flow\",\"@isMenu\",\"@isPopup\",\"@onStart\"],[[30,3],[30,4],[30,5],\"page\",[30,2],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[2]],null]],[\"@flows\",\"flow\",\"@params\",\"@website\",\"@page\",\"@isMenu\",\"@isPopup\",\"@onStart\"],false,[\"each\",\"-track-array\",\"flows/flow-btn\"]]",
    "moduleName": "shop/pods/components/flows/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/router", ["exports", "@ember/routing/router", "shop/config/environment"], function (_exports, _router, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends _router.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('catchall', {
      path: '/*path'
    });
    this.route('website', {
      path: '/'
    }, function () {
      this.route('page', {
        path: '/:page_url'
      });
      this.route('unsubscribe', {
        path: '/unsubscribe'
      }, function () {
        this.route('success', {
          path: '/success/:resubscribeToken'
        });
        this.route('fail', {
          path: '/fail'
        });
      });
      this.route('subscribe', {
        path: '/subscribe'
      });
      this.route('privacy-policy');
    });
    this.route('members', function () {
      this.route('member', {
        path: '/me'
      });
      this.route('reset-password');
      this.route('order-history');
      this.route('reward-history');
      this.route('promo-codes');
      this.route('logout');
    });
    this.route('erp', function () {
      this.route('payments', function () {
        this.route('card', function () {
          this.route('doc', {
            path: '/doc/:external_url'
          });
          this.route('3ds', {
            path: '/3ds/:token'
          });
          this.route('linepay', {
            path: '/linepay/:token'
          });
        });
      });
    });
    this.route('shop', {
      path: '/shop/:shop_url'
    }, function () {
      this.route('members', function () {
        this.route('member', {
          path: '/me'
        });
        this.route('reset-password');
        this.route('order-history');
        this.route('reward-history');
        this.route('promo-codes');
      });
      this.route('results');
      this.route('category', {
        path: '/category/:page_url'
      });
      this.route('item', {
        path: '/item/:item_id'
      }, function () {
        this.route('page', {
          path: '/:item_name'
        });
      });
      this.route('detail', {
        path: '/detail/:item_id/:item_name/:automation_id'
      });
      this.route('orders', function () {
        this.route('status', {
          path: '/status/:email/:ref'
        });
      });
      this.route('checkout', function () {
        this.route('success', {
          path: '/success/:ref_id'
        });
        this.route('failed', function () {
          this.route('payment');
        });
        this.route('summary');
        this.route('details');
        this.route('payment');
        this.route('3ds', {
          path: '/3ds/:token'
        });
        this.route('linepay', {
          path: '/linepay/:token'
        });
      });
      this.route('reset');
      this.route('reset-cache');
      this.route('reset-logout');
      this.route('referral');
      this.route('faqs');
      this.route('influencer', function () {
        this.route('expired'); // invalid link or no token supplied

        this.route('logout'); // invalid link or no token supplied

        this.route('social-login-success'); // used for line/facebook successful login, will redirect to app report
      });
    });
    this.route('orders', function () {
      this.route('status', {
        path: '/status/:email/:ref'
      });
    });
    this.route('blogs', function () {
      this.route('blog', {
        path: '/:blog_id/:blog_name'
      });
    });
    this.route('shop-loading');
    this.route('website-loading');
    this.route('blog-loading'); // @TODO: remove

    this.route('campaign', {
      path: '/c/:campaign_id'
    });
    this.route('facebook', function () {
      this.route('callback-from-facebook');
      this.route('login');
      this.route('success');
    });
    this.route('line', function () {
      this.route('callback-from-line');
      this.route('login');
      this.route('success');
    });
    this.route('store-location', function () {
      this.route('location', {
        path: '/:location_id'
      });
    });
    this.route('news', function () {
      this.route('article', {
        path: '/:article_id/:article_name'
      });
    });
    this.route('contact-us');
    this.route('not-found-404'); // @deprecated - should always redirect to not-found-404

    this.route('faqs');
  });
});
define("shop/helpers/feature", ["exports", "ramda", "@ember/component/helper", "@ember/service"], function (_exports, R, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    storage: (0, _service.inject)(),

    compute(params) {
      const dotPath = params[0];
      let expData = window.localStorage.experimental;

      try {
        expData = JSON.parse(expData);
      } catch (e) {
        expData = {};
      }

      const path = R.split('.')(dotPath);

      if (R.pathEq(path, true, expData)) {
        return true;
      }

      return false;
    }

  });

  _exports.default = _default;
});
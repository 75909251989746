define("shop/pods/components/shop/unsubscribe/success/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H+5MCWSE",
    "block": "[[[10,0],[14,0,\"container checkout-form\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasMailingListError\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@translate\",\"@errorMessage\",\"@website\"],[true,\"cannot update subscription\",[30,1]]],null],[1,\"  \\n\"]],[]],[[[1,\"    \"],[8,[39,2],null,[[\"@isRunning\"],[[28,[37,3],[[30,0,[\"unsubscribeTask\",\"isRunning\"]],[30,0,[\"subscribeTask\",\"isRunning\"]]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[10,2],[14,0,\"card-header-title\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"unsubscribe mailing list\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"content-display no-margin\"],[12],[1,\"\\n        \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"websites\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,2],[14,0,\"card-header-title\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"did you unsubscribe by accident\"],null]],[1,\" \"],[11,3],[24,0,\"u-margin-left-3\"],[4,[38,7],[\"click\",[28,[37,8],[[30,0,[\"onClickSubscribe\"]]],null]],null],[12],[1,[28,[35,4],[\"re-subscribe\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13]],[\"@website\",\"website\"],false,[\"if\",\"elements/message-block\",\"helpers/is-running\",\"or\",\"tt\",\"each\",\"-track-array\",\"on\",\"fn\"]]",
    "moduleName": "shop/pods/components/shop/unsubscribe/success/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
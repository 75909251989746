define("shop/pods/components/stories/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zZXHKij8",
    "block": "[[[10,0],[14,0,\"bigordr-stories\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@isSliderModalVisible\",\"@modalCardClass\",\"@modalBackgroundClass\",\"@mouseDown\",\"@mouseUp\",\"@toNextSlide\",\"@toBackSlide\",\"@onModalClose\",\"@story\",\"@isImgLoaded\",\"@isPaused\"],[[30,1],\"story-modal-card\",\"story-modal-background\",[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@website\",\"@content\",\"@onloadImg\",\"@isStories\"],[[30,10],[30,11],[30,12],true]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"@isSliderModalVisible\",\"@mouseDown\",\"@mouseUp\",\"@toNextSlide\",\"@toBackSlide\",\"@onModalClose\",\"@story\",\"@isImgLoaded\",\"@isPaused\",\"@website\",\"@sliderContent\",\"@onloadImg\"],false,[\"elements/stories-modal-dialog\",\"content-display\"]]",
    "moduleName": "shop/pods/components/stories/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/elements/message-block/component", ["exports", "@ember/component", "shop/utils/nventor", "@ember/service"], function (_exports, _component, _nventor, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    tagName: 'div',
    classNames: ['message-block'],
    translate: true,
    showMessages: true,
    timesDisplayed: 0,
    isShowingMessage: false,

    init() {
      this._super(...arguments);

      this.router.on('routeWillChange', () => {
        this.clear();
      });
    },

    didReceiveAttrs() {
      this._super(...arguments);

      _nventor.default.removeAndUpdateQueryParams(['success', 'error']);
    },

    clear() {
      this.websiteService.clearMessages({
        clearAll: true
      });
    },

    actions: {
      clear() {
        this.clear();
      },

      close() {
        this.clear();
        this.set('isShowingMessage', false);
      }

    }
  });

  _exports.default = _default;
});
define("shop/mixins/scroll", ["exports", "ramda", "@ember/object/mixin", "@ember/runloop", "jquery"], function (_exports, R, _mixin, _runloop, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * mixin to bind scroll events
   * override scolled function to handle on scroll
   */
  var _default = _mixin.default.create({
    didInsertElement() {
      this._super(...arguments);

      this.bindScrolling();
    },

    willDestroyElement() {
      this._super(...arguments);

      this.unbindScrolling();
    },

    onScroll() {// implement this function to handle scroll event
    },

    bindScrolling(options) {
      const self = this;
      const delay = R.propOr(100, 'debounce')(options);

      const onScrollDebounced = function () {
        (0, _runloop.debounce)(self, self.onScroll, delay);
      };

      (0, _jquery.default)(document).bind('touchmove', onScrollDebounced);
      (0, _jquery.default)(window).bind('scroll', onScrollDebounced);
    },

    unbindScrolling: function () {
      (0, _jquery.default)(window).unbind('scroll');
      (0, _jquery.default)(document).unbind('touchmove');
    }
  });

  _exports.default = _default;
});
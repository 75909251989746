define("shop/pods/components/tabs/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "adzczJwM",
    "block": "[[[41,[30,1,[\"componentName\"]],[[[1,\"  \"],[10,0],[14,0,\"tab-content tab-content-container tab-contents-up\"],[15,5,[52,[28,[37,1],[[30,2],[30,1,[\"isActive\"]]],null],\"\",\"display:none\"]],[12],[1,\"\\n    \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n      \"],[46,[30,1,[\"componentName\"]],null,[[\"model\",\"options\"],[[30,3],[30,4]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[16,0,[29,[\"tab-content flex-container-row tab-content-container\\n    \",[52,[30,5],\"has-content\",\"has-no-content\"],\"\\n    \",[52,[30,0,[\"isTabContentVisible\"]],\"tab-contents-down flex-container--justify-space-between\",\"tab-contents-up flex-container--justify-end\"],\"\\n    \",[30,6]]]],[16,5,[52,[30,1,[\"isActive\"]],\"\",\"display:none\"]],[4,[38,3],[[30,0,[\"websiteService\",\"optimizeVideoIframes\"]]],null],[12],[1,\"\\n\"],[1,\"      \"],[18,7,null],[1,\"\\n\"],[1,\"    \\n\"],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"@tab\",\"@isFaqsAlwaysVisible\",\"@model\",\"@options\",\"@tabContent\",\"@tabContentComponentClass\",\"&default\"],false,[\"if\",\"or\",\"component\",\"did-insert\",\"yield\"]]",
    "moduleName": "shop/pods/components/tabs/content/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
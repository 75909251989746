define("shop/pods/components/layouts/go-to-top/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r7LV31R/",
    "block": "[[[41,[33,1,[\"showQuickNav\"]],[[[41,[33,2],[[[41,[51,[33,4,[\"isCheckoutRoute\"]]],[[[41,[33,5,[\"cartItems\"]],[[[41,[33,6],[[[1,\"          \"],[10,1],[14,0,\"nav-item button bottom-left-btn flex-container-row flex-container--justify-center flex-container--align-items-center\"],[12],[1,\"\\n            \"],[1,[28,[35,7],null,[[\"shop\",\"website\",\"label\"],[[33,5],[33,1],\"\"]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]],null],[1,\"\\n    \"],[11,3],[16,0,[29,[\"has-animation button go-to-top__btn \",[52,[30,0,[\"websiteService\",\"isMobile\"]],\"is-mobile\"]]]],[4,[38,8],[\"click\",[28,[37,9],[[30,0,[\"goToTop\"]]],null]],null],[12],[1,\"\\n      \"],[8,[39,10],null,[[\"@icon\"],[\"fas fa-arrow-up\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"websiteService\",\"isShopIndexRoute\"]],[[[41,[33,11],[[],[]],null]],[]],null]],[]],null]],[],false,[\"if\",\"website\",\"isViewable\",\"unless\",\"websiteService\",\"shop\",\"hasCart\",\"cart/checkout-tag\",\"on\",\"fn\",\"elements/element-icon\",\"isGoToProductsBtnViewable\"]]",
    "moduleName": "shop/pods/components/layouts/go-to-top/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/modal-scroll-to-top/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8o7bx2+5",
    "block": "[[[41,[30,1,[\"showQuickNav\"]],[[[41,[30,0,[\"isViewable\"]],[[[1,\"    \"],[11,3],[16,0,[29,[\"\\n        has-animation\\n        button\\n        go-to-top__btn\\n        \",[52,[30,0,[\"websiteService\",\"isMobile\"]],\"is-mobile\"],\"\\n        \",[52,[30,2],\"is-inside-modal\"],\"\\n      \"]]],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"goToTop\"]]],null]],null],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@icon\"],[\"fas fa-arrow-up\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[\"@website\",\"@insideModal\"],false,[\"if\",\"on\",\"fn\",\"elements/element-icon\"]]",
    "moduleName": "shop/pods/components/layouts/modal-scroll-to-top/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/shop/orders-messages/component", ["exports", "@glimmer/component", "@glimmer/tracking", "ramda", "@ember/service", "ember-concurrency-decorators", "shop/utils/nventor"], function (_exports, _component, _tracking, R, _service, _emberConcurrencyDecorators, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShopOrdersMessages = (_dec = (0, _service.inject)('website'), (_class = class ShopOrdersMessages extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "message", _descriptor3, this);

      _initializerDefineProperty(this, "error", _descriptor4, this);

      _initializerDefineProperty(this, "messageSentSuccessfully", _descriptor5, this);
    }

    *sendMessageTask(message = '') {
      this.messageSentSuccessfully = false;

      if (!message) {
        return false;
      }

      this.error = ''; // if (!this.ordersToken) {
      //   return false
      // }
      // const email = this.args.email
      // const ref = this.args.ref

      const shop = this.args.shop;
      const email = this.args.email;
      const ref = this.args.ref;
      let shopUrl;

      if (shop) {
        shopUrl = shop.get('_data.url');
      }

      try {
        const data = {
          email,
          ref,
          message,
          url: shopUrl
        };
        const response = yield this.websiteService.sendMessageTask.perform({
          data
        });
        const messageData = (response === null || response === void 0 ? void 0 : response.messageData) || {};

        if (_nventor.default.isNotNilOrEmpty(messageData)) {
          this.args.messages.pushObject(messageData);
          this.message = '';
        }

        this.messageSentSuccessfully = true;
      } catch (error) {
        this.error = this.intl.t('error occurred, please refresh page');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "message", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "messageSentSuccessfully", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sendMessageTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "sendMessageTask"), _class.prototype)), _class));
  _exports.default = ShopOrdersMessages;
});
define("shop/pods/components/elements/check-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CXkRegly",
    "block": "[[[41,[33,1],[[[1,\"\\t\"],[11,1],[16,0,[29,[\"element-checkbox__icon element-checkbox--checked \",[30,1]]]],[4,[38,2],[[30,0],\"toggle\"],null],[12],[1,\"\\n\\t\\t\"],[8,[39,3],null,[[\"@icon\"],[\"fas fa-check-square\"]],null],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]],[[[1,\"\\t\"],[11,1],[16,0,[29,[\"element-checkbox__icon element-checkbox--unchecked \",[30,1]]]],[4,[38,2],[[30,0],\"toggle\"],null],[12],[1,\"\\n\\t\\t\"],[8,[39,3],null,[[\"@icon\"],[\"far fa-square\"]],null],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[28,[37,4],[[48,[30,2]],[33,6]],null],[[[1,\"\\t\"],[11,1],[16,0,[29,[\"element-checkbox__label \",[52,[30,0,[\"value\"]],\"is-selected\",\"\"]]]],[4,[38,2],[[30,0],\"toggle\"],null],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"\\t\\t\\t\"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\\t\"],[1,[28,[35,8],[[33,6]],[[\"translate\"],[[33,9]]]]],[1,\"\\n\"]],[]]],[1,\"\\t\"],[13],[1,\"\\n\"]],[]],null]],[\"@checkBoxClassNames\",\"&default\"],false,[\"if\",\"value\",\"action\",\"elements/element-icon\",\"or\",\"has-block\",\"label\",\"yield\",\"tt\",\"translate\"]]",
    "moduleName": "shop/pods/components/elements/check-box/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/page/model", ["exports", "@ember/object", "@ember/service", "@glimmer/tracking", "ramda"], function (_exports, _object, _service, _tracking, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PageModel = (_class = class PageModel extends _object.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "_data", _descriptor2, this);
    }

    get topBannerImageBackgroundColor() {
      var _this$imagesService, _this$imagesService$g;

      return this === null || this === void 0 ? void 0 : (_this$imagesService = this.imagesService) === null || _this$imagesService === void 0 ? void 0 : (_this$imagesService$g = _this$imagesService.getBackgroundRGBColor) === null || _this$imagesService$g === void 0 ? void 0 : _this$imagesService$g.call(_this$imagesService, {
        pathArray: ['_data', 'topBanner', 'mainColors'],
        model: this
      });
    }

    get topBanner() {
      return R.pathOr(null, ['_data', 'topBanner'])(this);
    }

    get bottomBanner() {
      return R.pathOr(null, ['_data', 'bottomBanner'])(this);
    }

    get bottomBannerImageBackgroundColor() {
      try {
        return R.pipe(R.pathOr([[152, 152, 152]], ['_data', 'bottomBanner', 'mainColors']), R.head, rgbColor => `rgb(${rgbColor.toString()})`)(this);
      } catch (e) {
        return 'rgb(152, 152, 152)';
      }
    }

    populate(data) {
      data = {
        _data: data
      };
      this.setProperties(data);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = PageModel;
});
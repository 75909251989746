define("shop/pods/shop/members/reward-history/route", ["exports", "@ember/routing/route", "shop/pods/shop/shop-route-mixin"], function (_exports, _route, _shopRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_shopRouteMixin.default, {
    async model(params) {
      const website = this.websiteService.website || {};
      const rewardsList = await this.membersService.get('getRewardsListTask').perform({
        website
      });

      try {
        return this._model(params, {
          rewardsList
        });
      } catch (e) {
        this.transitionTo('shop.members');
      }
    }

  });

  _exports.default = _default;
});
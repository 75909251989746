define("shop/pods/components/members/rewards-history-group/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-concurrency-decorators", "ramda", "shop/utils/nventor"], function (_exports, _component, _object, _tracking, _service, _emberConcurrencyDecorators, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MembersRewardsHistoryGroupComponent = (_dec = (0, _service.inject)('members'), _dec2 = (0, _service.inject)('date'), (_class = class MembersRewardsHistoryGroupComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "membersService", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "selectedMonths", _descriptor3, this);

      _initializerDefineProperty(this, "selectedSubMenu", _descriptor4, this);

      _initializerDefineProperty(this, "refetchedRewardHistory", _descriptor5, this);

      _initializerDefineProperty(this, "refetchedValidPoints", _descriptor6, this);

      const rewardHistoryOptions = [_object.default.create({
        label: 'all',
        value: -1
      }), _object.default.create({
        label: 'last 1 month',
        value: 1
      }), _object.default.create({
        label: 'last 3 months',
        value: 3
      }), _object.default.create({
        label: 'last 6 months',
        value: 6
      }), _object.default.create({
        label: 'last 1 year',
        value: 12
      })];
      (0, _object.set)(this, 'rewardHistoryOptions', rewardHistoryOptions);
    }

    get rewardHistoryData() {
      var _this$args, _this$args$pageCompon;

      const contactRewards = ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$pageCompon = _this$args.pageComponent) === null || _this$args$pageCompon === void 0 ? void 0 : _this$args$pageCompon.filterModel) || {};
      let rewardHistory = this === null || this === void 0 ? void 0 : this.refetchedRewardHistory;

      if (_nventor.default.isNilOrEmpty(rewardHistory)) {
        rewardHistory = contactRewards === null || contactRewards === void 0 ? void 0 : contactRewards.rewardHistory;
      }

      return rewardHistory || [];
    }

    get rewardExpiry() {
      const rewardHistory = this.rewardHistoryData;
      return R.pipe(R.uniqBy(R.prop('_key')), R.reject(R.anyPass([R.propEq('isOpening', true), _nventor.default.isNilOrEmpty, R.propEq('method', 'use')])))(rewardHistory);
    }

    get rewardHistory() {
      const rewardHistory = this.rewardHistoryData;
      return R.reject(R.propEq('isOpening', true))(rewardHistory);
    }

    get currentPoints() {
      var _this$args2, _this$args2$pageCompo;

      const contactRewards = ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$pageCompo = _this$args2.pageComponent) === null || _this$args2$pageCompo === void 0 ? void 0 : _this$args2$pageCompo.filterModel) || {};
      let validPoints = this === null || this === void 0 ? void 0 : this.refetchedValidPoints;

      if (_nventor.default.isNilOrEmpty(validPoints)) {
        validPoints = contactRewards === null || contactRewards === void 0 ? void 0 : contactRewards.validPoints;
      }

      return validPoints || 0;
    }

    get nextExpirePoints() {
      const rewardHistory = this.rewardHistoryData;
      return R.find(R.propEq('isExpireSoon', true))(rewardHistory);
    }

    get openingPoints() {
      const rewardHistory = this.rewardHistoryData;
      return R.find(R.propEq('isOpening', true))(rewardHistory);
    }

    *getRewardHistoryTask() {
      var _this$args$pageCompon2, _this$args$pageCompon3, _this$args3, _this$args4, _this$args4$website, _this$args4$website$_;

      const params = ((_this$args$pageCompon2 = this.args.pageComponent) === null || _this$args$pageCompon2 === void 0 ? void 0 : (_this$args$pageCompon3 = _this$args$pageCompon2.filterModel) === null || _this$args$pageCompon3 === void 0 ? void 0 : _this$args$pageCompon3.params) || {};
      const selectedMonths = parseInt(this === null || this === void 0 ? void 0 : this.selectedMonths) || 3; // default 3

      params.selectedMonths = selectedMonths;

      if (selectedMonths > 0) {
        const startDateZ = this.dateService.getMoment().subtract(selectedMonths, 'months').toISOString();
        const endDateZ = this.dateService.getMoment().toISOString();
        params.startDateZ = startDateZ;
        params.endDateZ = endDateZ;
      } else {
        params.startDateZ = '';
        params.endDateZ = '';
      }

      const response = yield this.membersService.fetchRewardHistoryTask.perform({
        website: (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.website,
        podKey: (_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : (_this$args4$website = _this$args4.website) === null || _this$args4$website === void 0 ? void 0 : (_this$args4$website$_ = _this$args4$website._data) === null || _this$args4$website$_ === void 0 ? void 0 : _this$args4$website$_.podKey,
        params
      });
      this.refetchedRewardHistory = (response === null || response === void 0 ? void 0 : response.rewardHistory) || [];
      this.refetchedValidPoints = (response === null || response === void 0 ? void 0 : response.validPoints) || 0;
    }

    onSubmitSelectSubMenu(subMenu) {
      this.selectedSubMenu = subMenu;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedMonths", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 3;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedSubMenu", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'history';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "refetchedRewardHistory", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "refetchedValidPoints", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getRewardHistoryTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getRewardHistoryTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitSelectSubMenu", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitSelectSubMenu"), _class.prototype)), _class));
  _exports.default = MembersRewardsHistoryGroupComponent;
});
define("shop/pods/components/content-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZHGc99Tp",
    "block": "[[[41,[30,0,[\"_content\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"_content\"]]],null]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"htmlSafe\"]]",
    "moduleName": "shop/pods/components/content-display/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/line/success/route", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    membersService: (0, _service.inject)('members'),

    model() {
      const website = this.modelFor('application').website;
      this.membersService.loginAndRedirectToOriginalPageTask.perform(website, 'line');
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/flows/flow-modal-window/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C9XtHmh3",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[24,0,\"chat-game-container\"],[4,[38,1],[[30,0,[\"willDestroyElement\"]]],null],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@isModalVisible\",\"&default\"],false,[\"if\",\"will-destroy\",\"yield\"]]",
    "moduleName": "shop/pods/components/flows/flow-modal-window/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/shop/referral/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R5Sirgx4",
    "block": "[[[41,[30,0,[\"model\",\"shop\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@shop\",\"@params\"],[[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"params\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@allowExpiry\",\"@isCampaign\",\"@isIndex\",\"@model\",\"@onFilterTask\",\"@page\",\"@params\",\"@query\",\"@shop\",\"@website\"],[true,[30,0,[\"model\",\"isCampaign\"]],true,[30,0,[\"model\"]],[30,1,[\"fetchTask\"]],[30,0,[\"model\",\"page\"]],[30,0,[\"model\",\"params\"]],[30,0,[\"model\",\"params\",\"query\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"model\",\"website\",\"showShopHero\"]],[[[1,\"        \"],[8,[39,3],null,[[\"@shop\",\"@hasTimer\"],[[30,0,[\"model\",\"shop\"]],[30,2,[\"hasTimer\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,4],[[30,2,[\"hasTimer\"]],[28,[37,5],[[30,0,[\"model\",\"shop\",\"hasExpiredGracefully\"]]],null]],null],[[[1,\"        \"],[8,[39,6],null,[[\"@dateEndZ\"],[[30,0,[\"model\",\"shop\",\"_data\",\"dateEndZ\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n          \"],[8,[39,7],null,[[\"@referralData\",\"@inputtedReferralCode\",\"@shop\",\"@website\"],[[30,0,[\"model\",\"referralData\"]],[30,0,[\"model\",\"inputtedReferralCode\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"pageComponent\",\"websiteContainer\"],false,[\"if\",\"shop/page-content\",\"website/website-container\",\"shop/header-hero\",\"and\",\"not\",\"countdown-timer\",\"referrals\"]]",
    "moduleName": "shop/pods/shop/referral/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
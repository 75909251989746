define("shop/pods/components/shop/page-content/component", ["exports", "ramda", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-concurrency-decorators", "shop/utils/nventor"], function (_exports, R, _component, _tracking, _object, _service, _emberConcurrencyDecorators, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShopPageContentComponent = (_dec = (0, _service.inject)('shop'), _dec2 = (0, _service.inject)('filters'), _dec3 = (0, _service.inject)('website'), (_class = class ShopPageContentComponent extends _component.default {
    constructor() {
      var _this$args$shop2, _this$args$shop2$_dat, _this$args3, _this$args3$shop, _this$args3$shop$_dat;

      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "shopService", _descriptor2, this);

      _initializerDefineProperty(this, "filtersService", _descriptor3, this);

      _initializerDefineProperty(this, "websiteService", _descriptor4, this);

      _initializerDefineProperty(this, "filterModel", _descriptor5, this);

      const params = this.args.params || {};
      const tagsFromShop = R.pathOr([], ['args', 'shop', '_data', 'productTags'])(this);
      const defaultData = {
        // categories: website?.categoriesMenu,
        tags: tagsFromShop,
        prices: R.pathOr([], ['args', 'shop', '_data', 'prices'])(this),
        sortBy: R.pathOr([], ['args', 'shop', '_data', 'sortBy'])(this)
      };
      const page = this.args.page;

      if ((page === null || page === void 0 ? void 0 : page.type) === 'category') {
        let tags = R.path(['args', 'page', 'productTags'])(this);

        if (_nventor.default.isNilOrEmpty(tags)) {
          tags = ['no-product-tags'];
        }

        params.tags = tags;
        defaultData.category = page === null || page === void 0 ? void 0 : page.name;
      } else {
        params.tags = R.pathOr([], ['args', 'params', 'tags'])(this);
      }

      if (!params.sortBy) {
        var _this$args, _this$args$shop, _this$args$shop$_data, _this$args2, _this$args2$shop;

        params.sortBy = (params === null || params === void 0 ? void 0 : params.sortBy) || ((_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$shop = _this$args.shop) === null || _this$args$shop === void 0 ? void 0 : (_this$args$shop$_data = _this$args$shop._data) === null || _this$args$shop$_data === void 0 ? void 0 : _this$args$shop$_data.shopProductsSort) || ((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$shop = _this$args2.shop) === null || _this$args2$shop === void 0 ? void 0 : _this$args2$shop.shopProductsSort) || 'recommended';
      } // params.sortBy = params?.sortBy || this.args?.shop?._data?.shopProductsSort || this.args?.shop?.shopProductsSort || 'recommended'


      const isMobile = this.websiteService.isMobile || false;
      const productsPerRow = ((_this$args$shop2 = this.args.shop) === null || _this$args$shop2 === void 0 ? void 0 : (_this$args$shop2$_dat = _this$args$shop2._data) === null || _this$args$shop2$_dat === void 0 ? void 0 : _this$args$shop2$_dat.productsPerRow) || 3;

      if (!isMobile && Number(productsPerRow) === 4) {
        params.count = 32;
      }

      const filterModel = this.filtersService.setupFilters(defaultData, {
        params
      });
      (0, _object.set)(this, 'filterModel', filterModel);

      if (!(this !== null && this !== void 0 && (_this$args3 = this.args) !== null && _this$args3 !== void 0 && (_this$args3$shop = _this$args3.shop) !== null && _this$args3$shop !== void 0 && (_this$args3$shop$_dat = _this$args3$shop._data) !== null && _this$args3$shop$_dat !== void 0 && _this$args3$shop$_dat.isExpired)) {
        if (page !== null && page !== void 0 && page.hasTemplate && (page === null || page === void 0 ? void 0 : page.type) === 'category') {
          // let template sliders fetch product tags
          return;
        }

        const isMembersOrderHistoryPath = R.pipe(R.pathOr('', ['router', 'currentRouteName']), R.allPass([R.includes('members'), R.includes('order-history')]))(this); // Members do not see products info on order history route, so why fetch it.
        // No need to fetchTask when displayProductsGrid is false (for performance)

        const displayProductsGrid = R.pathOr(false, ['args', 'displayProductsGrid'])(this);

        if (!isMembersOrderHistoryPath && displayProductsGrid) {
          this.fetchTask.perform(params);
        }
      }
    }

    *fetchTask(params = {}) {
      const shop = this.args.shop;
      const data = yield this.shopService.getShopItemsTask.perform({
        shop,
        params,
        cache: true
      });
      const filterModel = this.filterModel;
      filterModel.results = R.propOr([], 'items', data);
      filterModel.hasMore = R.propOr(false, 'hasMore', data);
      filterModel.totalCount = R.propOr(0, 'totalCount', data);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "shopService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filterModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTask"), _class.prototype)), _class));
  _exports.default = ShopPageContentComponent;
});
define("shop/pods/components/layouts/nav-bar/component", ["exports", "ramda", "@ember/component", "@ember/service", "@ember/object", "shop/utils/nventor", "jquery"], function (_exports, R, _component, _service, _object, _nventor, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    storage: (0, _service.inject)(),
    websiteService: (0, _service.inject)('website'),
    headerService: (0, _service.inject)('header'),
    tagName: 'div',
    hasCart: true,
    isCartModalVisible: false,
    showNavMenu: false,
    showTagMenu: false,
    terms: '',
    showWebsiteName: true,
    showShopSearchFilter: false,
    classNames: ['my-navbar-header'],
    websiteNameWidth: 0,

    // fastboot: service(),
    init() {
      this._super(...arguments);

      $(window).on('resize', this.showWebsiteNameByScreenSize);
    },

    didInsertElement() {
      this._super(...arguments);

      setTimeout(() => {
        this.updateNavBar();
      }, 500);
    },

    updateNavBar() {
      if (!this.isDestroyed) {
        const logoTextContainer = (0, _jquery.default)('.nav-item .logo-text').width();
        const iconWidth = (0, _jquery.default)('.nav-icon').outerWidth();
        const websiteNameWidth = (0, _jquery.default)('.navbar-website-name').outerWidth();

        if (iconWidth + websiteNameWidth > logoTextContainer) {
          this.set('showWebsiteName', false);
        } else {
          this.set('showWebsiteName', true);
        }

        this.updateNavBarHeight();
      }
    },

    updateNavBarHeight() {
      this.headerService.trackNavBarHeight();
    },

    isTagMenuVisible: (0, _object.computed)('showTagMenu', function () {
      return this.showTagMenu;
    }),
    hasTimer: (0, _object.computed)('isShopRoute', 'shop.showCountdownTimer', function () {
      if (R.includes('members')(this.router.currentRouteName)) {
        return false;
      }

      if (this.isShopRoute && this.shop.showCountdownTimer) {
        return true;
      }

      return false;
    }),
    hasCampaignHeader: (0, _object.computed)('isShopRoute', 'shop.showCampaignHeader', function () {
      var _this$website;

      if (R.includes('members')(this.router.currentRouteName)) {
        return false;
      }

      const shop = this.shop || (this === null || this === void 0 ? void 0 : (_this$website = this.website) === null || _this$website === void 0 ? void 0 : _this$website.shop);

      if (R.startsWith('shop', this.router.currentRouteName) && shop.showCampaignHeader) {
        return true;
      }

      return false;
    }),
    isShopRoute: (0, _object.computed)('router.currentRouteName', function () {
      const routeName = this.router.get('currentRouteName');

      if (R.startsWith('shop', routeName)) {
        return true;
      }

      return false;
    }),
    isShopIndexRoute: (0, _object.computed)('router.currentRouteName', function () {
      const routeName = this.router.get('currentRouteName');

      if (R.startsWith('shop.index', routeName)) {
        return true;
      }

      return false;
    }),
    // isCheckoutRoute: computed('router.currentRouteName', function () {
    //   const routeName = this.router.get('currentRouteName')
    //   if (R.startsWith('shop.checkout', routeName)) {
    //     return true
    //   }
    //   return false
    // }),
    hasShop: (0, _object.computed)('shop', 'website._data.shopKey', function () {
      if (this.shop) {
        return this.shop;
      }

      if (this.get('website._data.shopKey')) {
        return this.get('website._data.shopKey');
      }

      return false;
    }),
    // maybe just use hasShop
    // hasShopKey: computed('shop', 'website._data.shopKey', function () {
    //   const shop = R.pathOr({}, ['shop'])(this)
    //   const shopKey = R.pathOr('', ['website', '_data', 'shopKey'])(this)
    //   return !nventor.isNilOrEmpty(shop) && !nventor.isNilOrEmpty(shopKey)
    // }),
    logoIconPosition: (0, _object.computed)('website._data.logoIconPosition', function () {
      const position = this.get('website._data.logoIconPosition');

      if (position) {
        return `logo-icon__${position}`;
      }

      return '';
    }),
    logoMiddle: (0, _object.computed)('website._data.logoMenuPosition', function () {
      const isSeparateLine = this.get('website._data.logoMenuPosition') === 'separateLine';

      if (isSeparateLine) {
        return true;
      }

      return false;
    }),
    isNavbarEmpty: (0, _object.computed)('hasShop', 'showNavMenu', 'website._data.menuShowLogo', 'website._data.menuShowName', 'website.menu', function () {
      const menuShowLogo = this.get('website._data.menuShowLogo') || false;
      const menuShowName = this.get('website._data.menuShowName') || false;
      const itemShowOnMenu = R.pipe(R.pathOr([], ['website', 'menu']), R.reject(R.pathEq(['isHome'], true)))(this);

      if (this.hasShop) {
        return false;
      }

      if (this.showNavMenu) {
        return false;
      }

      if (menuShowLogo) {
        return false;
      }

      if (menuShowName) {
        return false;
      }

      if (_nventor.default.isNotNilOrEmpty(itemShowOnMenu)) {
        return false;
      }

      return true;
    }),

    goHome() {
      var _this$website2, _this$website2$_data;

      this.websiteService.cleanupDrawerOnClose({
        prop: 'isShowDrawerForShopItem'
      });
      const query = this.query;
      const allowAllCampaigns = this === null || this === void 0 ? void 0 : (_this$website2 = this.website) === null || _this$website2 === void 0 ? void 0 : (_this$website2$_data = _this$website2._data) === null || _this$website2$_data === void 0 ? void 0 : _this$website2$_data.allowAllCampaigns;
      const isBigOrdr = this.websiteService.get('isBigOrdr');

      if ((isBigOrdr || allowAllCampaigns) && this.isShopRoute) {
        return this.router.transitionTo('shop', {
          queryParams: {
            query,
            tag: null,
            tags: null
          }
        });
      }

      if (this.get('website.hasWebsite')) {
        return this.router.transitionTo('/');
      } else if (this.get('hasShop')) {
        return this.router.transitionTo('shop', {
          queryParams: {
            query,
            tag: null,
            tags: null
          }
        });
      }
    },

    toggleSearchIcon() {
      this.toggleProperty('showShopSearchFilter');
    },

    showWebsiteNameByScreenSize(element) {
      var _element, _this$website3, _$;

      let websiteNameWidth = (_element = element) === null || _element === void 0 ? void 0 : _element.offsetWidth;
      const menuPosition = this === null || this === void 0 ? void 0 : (_this$website3 = this.website) === null || _this$website3 === void 0 ? void 0 : _this$website3.menuPosition;

      if (menuPosition === 'left') {
        const navMenuBtn = document.getElementsByClassName('nav-menu-btn')[0];

        if (navMenuBtn) {
          const navMenuBtnWidth = (navMenuBtn === null || navMenuBtn === void 0 ? void 0 : navMenuBtn.offsetWidth) || 0;

          if (websiteNameWidth) {
            websiteNameWidth = websiteNameWidth + navMenuBtnWidth;
          }
        }
      }

      const navBackBtn = document.getElementsByClassName('button back-btn')[0];

      if (navBackBtn) {
        const navBackBtnWidth = (navBackBtn === null || navBackBtn === void 0 ? void 0 : navBackBtn.offsetWidth) || 0;

        if (websiteNameWidth) {
          websiteNameWidth = websiteNameWidth + navBackBtnWidth;
        }
      } // set init value of websiteNameWidth before its gone


      if (websiteNameWidth) {
        websiteNameWidth = websiteNameWidth + 100;
        this.set('websiteNameWidth', websiteNameWidth);
      } // on resize, element is not the real element, so need to find navbar-website-name


      const websiteNameElement = document.getElementsByClassName('navbar-website-name')[0];

      if (!websiteNameWidth && websiteNameElement) {
        element = websiteNameElement;
      }

      const screenWidth = (_$ = $(window)) === null || _$ === void 0 ? void 0 : _$.innerWidth();
      let navbarRight = document.querySelector('.navbar-part--right');
      const modalNavbarRight = document.querySelector('.modal .navbar-part--right');

      if (modalNavbarRight) {
        navbarRight = modalNavbarRight;
      }

      if (screenWidth && this.websiteNameWidth && navbarRight) {
        const navbarRightWidth = navbarRight.offsetWidth;
        const navbarLeftAvailableWidth = screenWidth - navbarRightWidth;

        if (this.websiteNameWidth > navbarLeftAvailableWidth) {
          element.style.display = 'none';
        } else {
          element.style.display = 'block';
        }
      }
    },

    actions: {
      goHome() {
        this.goHome();
      },

      toggle(prop) {
        this.toggleProperty(prop);
      },

      toggleTagMenu() {
        this.toggleProperty('showTagMenu');
      },

      toggleNavMenu() {
        this.toggleProperty('showNavMenu');
      },

      showCartModal() {
        this.toggleProperty('isCartModalVisible');
      },

      hideCartModal() {
        this.toggleProperty('isCartModalVisible');
      },

      goToMembersSection() {
        const queryParams = {
          from: null,
          to: null,
          count: null,
          page: null,
          price: null,
          query: null,
          sortBy: null,
          tags: null
        };

        if (this.get('website.isLoggedIn')) {
          if (this.isShopRoute) {
            this.router.transitionTo('shop.members.member', {
              queryParams
            });
          } else {
            this.router.transitionTo('members.member');
          }
        } else {
          if (this.isShopRoute) {
            this.router.transitionTo('shop.members', {
              queryParams
            });
          } else {
            this.router.transitionTo('members');
          }
        }
      }

    },

    toggleDataNoSnippet(element) {
      if (!this.websiteService.isBigOrdrShopRoute) {
        return;
      }

      element.setAttribute('data-nosnippet', 'true');
    }

  }, (_applyDecoratedDescriptor(_obj, "updateNavBarHeight", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updateNavBarHeight"), _obj), _applyDecoratedDescriptor(_obj, "toggleSearchIcon", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleSearchIcon"), _obj), _applyDecoratedDescriptor(_obj, "showWebsiteNameByScreenSize", [_object.action], Object.getOwnPropertyDescriptor(_obj, "showWebsiteNameByScreenSize"), _obj), _applyDecoratedDescriptor(_obj, "toggleDataNoSnippet", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleDataNoSnippet"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/components/items/item-choice/component", ["exports", "ramda", "@ember/component", "shop/mixins/big", "@ember/object", "@ember/runloop"], function (_exports, R, _component, _big, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_big.default, (_obj = {
    tagName: 'div',
    isLink: true,
    classNames: ['store-item__choice', 'is-tappable'],
    classNameBindings: ['isSelected', 'showItem::u-hidden'],
    showToggler: false,

    didInsertElement() {
      this._super(...arguments);

      this.setDescription();
      (0, _runloop.next)(this, this.checkIfTextIsOverflow);
    },

    setDescription() {
      var _this$item, _this$item$_data;

      const itemDescription = (_this$item = this.item) === null || _this$item === void 0 ? void 0 : (_this$item$_data = _this$item._data) === null || _this$item$_data === void 0 ? void 0 : _this$item$_data.description;

      if (!itemDescription) {
        return;
      }

      const isNotEmpty = R.compose(R.not, R.isEmpty, R.trim);
      const paragraphs = R.pipe(R.split('</p>'), R.filter(isNotEmpty))(itemDescription);
      const hasMultipleParagraphs = R.gt(R.length(paragraphs), 1);
      this.set('showToggler', hasMultipleParagraphs);
      this.set('itemDescription', itemDescription);
      this.set('isShowMore', false);
    },

    checkIfTextIsOverflow() {
      const element = this.element.querySelector('.item-description__text');

      if (!element) {
        return;
      }

      element.classList.add('expanded');
      const originalHeight = element === null || element === void 0 ? void 0 : element.scrollHeight;
      element.classList.remove('expanded');
      const currentHeight = element === null || element === void 0 ? void 0 : element.clientHeight;

      if (currentHeight < originalHeight) {
        this.set('showToggler', true);
      } else {
        this.set('showToggler', false);
      }
    },

    showItem: (0, _object.computed)('isSelected', 'isReadonly', function () {
      if (this.isReadonly && !this.isSelected) {
        return false;
      }

      return true;
    }),
    images: (0, _object.computed)('item._data.uploadedFiles.[]', function () {
      const images = this.get('item._data.uploadedFiles') || [];
      return images.sortBy('timestampZ');
    }),
    defaultImage: (0, _object.computed)('images.[]', 'item._data.{defaultImageData,defaultImage}', function () {
      if (this.get('item._data.defaultImageData')) {
        return this.get('item._data.defaultImageData');
      }

      if (this.get('item._data.defaultImage')) {
        const uuid = this.get('item._data.defaultImage');
        const defaultImageData = R.pipe(R.pathOr([], ['item', '_data', 'uploadedFiles']), R.find(R.propEq('uuid', uuid)))(this);

        if (defaultImageData !== null && defaultImageData !== void 0 && defaultImageData.uuid) {
          return defaultImageData;
        }

        return {
          uuid
        };
      }

      if (this.images.get('firstObject')) {
        const firstImage = this.images.get('firstObject');

        if (firstImage) {
          return firstImage;
        }
      }

      return false;
    }),
    isRemoveDisabled: (0, _object.computed)('automation._data.{thenAction,thenSelectionType,thenSelectionQty,details.[]}', 'item._data.qty', 'isSummaryStep', 'isDetailStep', function () {
      const itemQty = this.get('item._data.qty');
      const details = this.get('automation._data.details') || [];
      const thenAction = this.get('automation._data.thenAction');
      const thenSelectionType = this.get('automation._data.thenSelectionType');
      const isSummaryStep = this.get('isSummaryStep') || false;
      const isDetailStep = this.isDetailStep || false;

      if (!isSummaryStep && !isDetailStep) {
        return true;
      }

      if (!itemQty || itemQty === 0 || itemQty === '0') {
        return true;
      }

      if (thenAction === 'mustAdd' && thenSelectionType === 'all') {
        return true;
      }

      if (thenAction === 'mustAdd' && thenSelectionType === 'between' && R.length(details) === 1) {
        return true;
      }

      return false;
    }),
    isPlusDisabled: (0, _object.computed)('automation._data.{thenAction,thenSelectionType,thenSelectionQty}', 'automation.isComplete', 'isSummaryStep', 'isDetailStep', function () {
      const thenAction = this.get('automation._data.thenAction');
      const thenSelectionType = this.get('automation._data.thenSelectionType');
      const isComplete = this.get('automation.isComplete');
      const isSummaryStep = this.get('isSummaryStep') || false;
      const isDetailStep = this.isDetailStep || false;

      if (!isSummaryStep && !isDetailStep) {
        return true;
      }

      if (isComplete) {
        return true;
      }

      if (thenAction === 'mustAdd' && thenSelectionType === 'all') {
        return true;
      }

      return false;
    }),
    cartItem: (0, _object.computed)('item._data.{itemKey,_key}', 'shop.{updated,cartItems.@each._key}', function () {
      const itemKey = this.get('item._data.itemKey') || '';

      const _key = this.get('item._data._key') || '';

      const updated = this.get('shop.updated');
      const cartItems = this.get('shop.cartItems') || [];
      return R.find(R.allPass([R.propEq('isAutomation', true), R.propEq('itemKey', itemKey), R.propEq('_key', _key)]))(cartItems);
    }),
    automationItemQty: (0, _object.computed)('item._data.automationQty', function () {
      const automationQty = this.get('item._data.automationQty');
      return this.newBig(automationQty).toFixed(0);
    }),
    isSelected: (0, _object.computed)('shop.cartItems.@each._key', 'automation._key', function () {
      var _automationDetail$_da, _automationDetail$_da2;

      const addedAutomationItems = R.pipe(R.pathOr([], ['shop', 'cartItems']), R.filter(R.propEq('isAutomation', true)))(this);
      const automationDetail = (this === null || this === void 0 ? void 0 : this.item) || {};
      const found = R.find(R.allPass([R.propEq('_key', automationDetail === null || automationDetail === void 0 ? void 0 : (_automationDetail$_da = automationDetail._data) === null || _automationDetail$_da === void 0 ? void 0 : _automationDetail$_da._key), R.propEq('itemKey', automationDetail === null || automationDetail === void 0 ? void 0 : (_automationDetail$_da2 = automationDetail._data) === null || _automationDetail$_da2 === void 0 ? void 0 : _automationDetail$_da2.itemKey)]))(addedAutomationItems);

      if (found) {
        return true;
      }

      return false;
    }),
    hasChoices: (0, _object.computed)('automation.thenSelectionType', function () {
      if (this.get('automation.details').length <= 1) {
        return false;
      }

      if (this.get('automation.thenSelectionType') === 'between') {
        return true;
      }

      return false;
    }),

    toggleDescription() {
      const isShowMore = !this.isShowMore;
      this.set('isShowMore', isShowMore);
      return isShowMore;
    },

    actions: {
      onChangeAutomationQty() {
        var _item$_data, _item$_data2, _automation$_data, _item$_data3;

        const shop = this.shop;
        const automation = this.automation;
        const item = this.item;
        const cartItems = shop.cartItems;
        const cartItem = R.find(R.allPass([R.propEq('itemKey', item === null || item === void 0 ? void 0 : (_item$_data = item._data) === null || _item$_data === void 0 ? void 0 : _item$_data.itemKey), R.propEq('_key', item === null || item === void 0 ? void 0 : (_item$_data2 = item._data) === null || _item$_data2 === void 0 ? void 0 : _item$_data2._key)]))(cartItems);
        const thenSelectionQty = parseInt(automation === null || automation === void 0 ? void 0 : (_automation$_data = automation._data) === null || _automation$_data === void 0 ? void 0 : _automation$_data.thenSelectionQty) || 0;
        const automationQty = item.get('_data.automationQty') || 0;
        let chosenQty = parseInt(item === null || item === void 0 ? void 0 : (_item$_data3 = item._data) === null || _item$_data3 === void 0 ? void 0 : _item$_data3.qty) || 0;

        if (chosenQty > thenSelectionQty) {
          chosenQty = thenSelectionQty;
        }

        const qty = automationQty * chosenQty;

        if (!cartItem) {
          shop.addCartItem({
            item,
            qty,
            automation,
            isPreOrder: false,
            chosenQty
          });
          shop.addAutomationItem(automation);
          this.onCartChanged();
          return;
        }

        if (Number(item.get('_data.qty')) === 0) {
          shop.removeCartItem('cartItems', cartItem);
          this.onCartChanged();
          return;
        }

        shop.updateCartItemQty({
          group: 'cartItems',
          cartItem,
          qty,
          chosenQty
        });
        this.onCartChanged();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "checkIfTextIsOverflow", [_object.action], Object.getOwnPropertyDescriptor(_obj, "checkIfTextIsOverflow"), _obj), _applyDecoratedDescriptor(_obj, "toggleDescription", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleDescription"), _obj)), _obj));

  _exports.default = _default;
});
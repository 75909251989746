define("shop/pods/components/layouts/message-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QFNpMFRH",
    "block": "[[[10,0],[14,0,\"message-card card\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n      \"],[10,2],[15,0,[29,[[30,1],\" card-header-title has-text-danger\"]]],[12],[1,\"\\n        \"],[1,[28,[35,0],[[30,2]],[[\"translate\"],[[30,0,[\"translate\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[15,0,[29,[[30,3],\" flex-container-row flex-container--justify-center\"]]],[12],[1,\"\\n        \"],[2,[28,[37,0],[[30,4]],[[\"translate\",\"BigordrLogo\"],[[30,0,[\"translate\"]],[30,0,[\"BigordrLogo\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[48,[30,5]],[[[1,\"        \"],[18,5,null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@headerClassNames\",\"@title\",\"@messageClassNames\",\"@message\",\"&default\"],false,[\"tt\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "shop/pods/components/layouts/message-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
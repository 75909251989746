define("shop/pods/components/filters/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-concurrency-decorators", "shop/utils/nventor", "ramda"], function (_exports, _component, _tracking, _object, _service, _emberConcurrencyDecorators, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FiltersComponent = (_dec = (0, _service.inject)('filters'), _dec2 = (0, _service.inject)('header'), _dec3 = (0, _service.inject)('website'), (_class = class FiltersComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "filtersService", _descriptor, this);

      _initializerDefineProperty(this, "headerService", _descriptor2, this);

      _initializerDefineProperty(this, "websiteService", _descriptor3, this);

      _initializerDefineProperty(this, "isSearchBarVisible", _descriptor4, this);

      _initializerDefineProperty(this, "isFiltersVisible", _descriptor5, this);
    }

    get hasAnyFilters() {
      if (this.args.hasTagsFilters) {
        return true;
      }

      if (this.args.hasCategoriesFilters) {
        return true;
      }

      if (this.args.hasAdvancedFilters) {
        return true;
      }

      return false;
    }

    toggle(prop) {
      this[prop] = !this[prop];
    }

    async onToggleTag(selected) {
      var _this$args, _this$args$pageCompon, _this$args$pageCompon2, _this$args2, _this$args2$model, _this$args2$model$web, _this$args3;

      const tags = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$pageCompon = _this$args.pageComponent) === null || _this$args$pageCompon === void 0 ? void 0 : (_this$args$pageCompon2 = _this$args$pageCompon.filterModel) === null || _this$args$pageCompon2 === void 0 ? void 0 : _this$args$pageCompon2.filtersTags) || [];
      let isNotAllowMultiple = R.pathOr(false, ['0', 'isNotAllowMultiple'])(tags);
      let updateUrlParams = R.pathOr(false, ['args', 'updateUrlParams'])(this);

      if (_nventor.default.isNotNilOrEmpty((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$model = _this$args2.model) === null || _this$args2$model === void 0 ? void 0 : (_this$args2$model$web = _this$args2$model.website) === null || _this$args2$model$web === void 0 ? void 0 : _this$args2$model$web.hasCategories)) {
        isNotAllowMultiple = true;
        updateUrlParams = true;
      }

      if (isNotAllowMultiple) {
        R.forEach(tag => {
          if (R.equals(tag, selected)) {
            tag.isSelected = true;
          } else {
            tag.isSelected = false;
          }
        })(tags);
      }

      this.filtersService.updateFilter({
        filterModel: this.args.pageComponent.filterModel,
        updateUrlParams,
        onUpdateFilter: this.args.onUpdateFilter,
        isShopTagsFromCategoryPage: ((_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.isShopTagsFromCategoryPage) || false
      });
    }

    *resetSearchBarTask({
      isShopOrCategoryPage = false
    } = {}) {
      const filterModel = this.args.pageComponent.filterModel;
      filterModel._data.query = '';
      yield this.filtersService.resetFilters({
        filterModel,
        onUpdateFilter: this.args.onUpdateFilter,
        isShopOrCategoryPage
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "headerService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isSearchBarVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isFiltersVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R.pathOr(false, ['args', 'initialFiltersIsVisible'])(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleTag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetSearchBarTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "resetSearchBarTask"), _class.prototype)), _class));
  _exports.default = FiltersComponent;
});
define("shop/pods/components/layouts/page-card/card-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9WBxdfZD",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "shop/pods/components/layouts/page-card/card-content/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
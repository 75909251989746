define("shop/pods/components/elements/date-input/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-concurrency-decorators", "ember-concurrency", "moment", "ramda"], function (_exports, _component, _tracking, _object, _service, _emberConcurrencyDecorators, _emberConcurrency, moment, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ElementsDateInputComponent = (_dec = (0, _service.inject)('date'), (_class = class ElementsDateInputComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dateService", _descriptor, this);

      _initializerDefineProperty(this, "dateValue", _descriptor2, this);

      _initializerDefineProperty(this, "dateMin", _descriptor3, this);

      _initializerDefineProperty(this, "dateMax", _descriptor4, this);

      _initializerDefineProperty(this, "picker", _descriptor5, this);

      _defineProperty(this, "DEBOUNCE_MS", 450);
    }

    get isRequiredIcon() {
      var _this$args, _this$args2;

      let iconClassNames = 'fas fa-asterisk element-icon--xs datepicker__is-required-icon';

      if ((_this$args = this.args) !== null && _this$args !== void 0 && _this$args.isRequired && !((_this$args2 = this.args) !== null && _this$args2 !== void 0 && _this$args2.date)) {
        iconClassNames += ' has-text-danger';
      }

      return iconClassNames;
    }

    isDateAllowed({
      blockedDates,
      disallowedDays,
      minDate
    }) {
      const isoDateString = moment(minDate).toISOString();
      const dayOfWeek = minDate.getDay();
      const isBlocked = R.any(d => moment(d).toISOString() === isoDateString)(blockedDates);
      const isDisallowed = R.includes(dayOfWeek, disallowedDays);
      return !isBlocked && !isDisallowed;
    }

    getNextAvailableDate(minDate, blockedDates, disallowedDays) {
      const isDateAllowed = this.isDateAllowed({
        blockedDates,
        disallowedDays,
        minDate
      });

      if (isDateAllowed) {
        return minDate;
      }

      const nextDay = moment(minDate).add(1, 'days').toDate();
      return this.getNextAvailableDate(nextDay, blockedDates, disallowedDays);
    }

    setupCalendar() {
      var _this$args3, _this$args4, _this$args5, _this$args6, _this$args7, _this$args8, _this$args9, _this$args10;

      const dateConstraint = (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.dateConstraint;

      if (dateConstraint) {
        this.dateMin = moment(dateConstraint === null || dateConstraint === void 0 ? void 0 : dateConstraint.min).toDate();
        this.dateMax = moment(dateConstraint === null || dateConstraint === void 0 ? void 0 : dateConstraint.max).toDate();
      }

      const disallowedDays = ((_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : _this$args4.disallowedDays) || [];
      const onSelectDate = (_this$args5 = this.args) === null || _this$args5 === void 0 ? void 0 : _this$args5.onSelectDate;
      const blockedDates = ((_this$args6 = this.args) === null || _this$args6 === void 0 ? void 0 : _this$args6.excludedSpecificDates) || [];

      const toDateString = dateString => new Date(dateString).toDateString();

      const blockedDatesFormatted = R.map(toDateString, blockedDates); // get the avaialble dateMin

      this.dateMin = this.getNextAvailableDate(this.dateMin, blockedDates, disallowedDays);
      let date = ((_this$args7 = this.args) === null || _this$args7 === void 0 ? void 0 : _this$args7.date) || ''; // reset date if the selected day is in the disallowed days

      const selectedDayOfWeek = this.dateService.getMoment(date).day();

      if (R.includes(selectedDayOfWeek)(disallowedDays)) {
        date = '';
      }

      if (R.includes(date)(blockedDates)) {
        date = '';
      }

      let defaultDate = moment(date).toDate();
      const isRequired = (_this$args8 = this.args) === null || _this$args8 === void 0 ? void 0 : _this$args8.isRequired;
      let setDefaultDate = isRequired;

      if (date) {
        setDefaultDate = true;
      }

      if (!date) {
        defaultDate = this.dateMin;
      }

      const datepicker = document.getElementById(`datepicker-${(_this$args9 = this.args) === null || _this$args9 === void 0 ? void 0 : _this$args9.id}`);
      const container = document.getElementById(`datepicker-container-${(_this$args10 = this.args) === null || _this$args10 === void 0 ? void 0 : _this$args10.id}`);
      const dateService = this.dateService; // Initialize the datepicker

      const picker = new Pikaday({
        format: 'YYYY-MM-DD',

        toString(date, format) {
          var _dateService$toLocalT;

          const dateString = date.toISOString();
          const localDate = (_dateService$toLocalT = dateService.toLocalTime) === null || _dateService$toLocalT === void 0 ? void 0 : _dateService$toLocalT.call(dateService, dateString);
          return dateService.formatDateForUrl(localDate, format);
        },

        bound: false,
        field: datepicker,
        container: container,
        minDate: this.dateMin,
        maxDate: this.dateMax,
        showDaysInNextAndPreviousMonths: false,
        theme: 'pikaday--default-theme',
        defaultDate,
        setDefaultDate,
        enableSelectionDaysInNextAndPreviousMonths: true,
        keyboardInput: false,
        disableDayFn: function (date) {
          // Disable specific days (e.g., Sundays)
          const day = date.getDay();
          const isDisallowedDay = R.includes(day, disallowedDays);
          const formattedDate = date.toDateString();
          const isBlockedDate = R.includes(formattedDate, blockedDatesFormatted);
          return isBlockedDate || isDisallowedDay;
        },
        onSelect: function (date) {
          if (onSelectDate) {
            return onSelectDate(date);
          }

          return;
        }
      });
      this.picker = picker;

      if (!isRequired) {
        picker.hide();
      }

      datepicker.addEventListener('click', function () {
        if (picker.isVisible()) {
          picker.hide();
        } else {
          picker.show();
        }
      });

      if (setDefaultDate) {
        onSelectDate(defaultDate);
      }
    }

    get shownDate() {
      var _this$args11, _this$dateService$for, _this$dateService2;

      let dateValue = this.dateValue || '';
      const date = (_this$args11 = this.args) === null || _this$args11 === void 0 ? void 0 : _this$args11.date;

      if (!date) {
        return '';
      }

      if (date && !dateValue) {
        var _this$dateService$toL, _this$dateService;

        dateValue = (_this$dateService$toL = (_this$dateService = this.dateService).toLocalTime) === null || _this$dateService$toL === void 0 ? void 0 : _this$dateService$toL.call(_this$dateService, date);
      }

      return (_this$dateService$for = (_this$dateService2 = this.dateService).formatDateForUrl) === null || _this$dateService$for === void 0 ? void 0 : _this$dateService$for.call(_this$dateService2, dateValue);
    }

    get dateZ() {
      const dateValue = this.dateValue;

      if (dateValue) {
        var _this$dateService$get, _this$dateService3, _this$dateService$get2;

        const dateZ = (_this$dateService$get = (_this$dateService3 = this.dateService).getMoment) === null || _this$dateService$get === void 0 ? void 0 : (_this$dateService$get2 = _this$dateService$get.call(_this$dateService3, dateValue)) === null || _this$dateService$get2 === void 0 ? void 0 : _this$dateService$get2.toISOString();
        return dateZ;
      }

      return '';
    }

    selectDate(element) {
      var _element$target;

      this.value = (element === null || element === void 0 ? void 0 : (_element$target = element.target) === null || _element$target === void 0 ? void 0 : _element$target.value) || '';
      const value = this.value;

      if (value) {
        this.debounceTask.perform(value);
      }
    }

    clearDate(id) {
      var _this$args12;

      const onSelectDate = (_this$args12 = this.args) === null || _this$args12 === void 0 ? void 0 : _this$args12.onSelectDate;
      document.getElementById(`datepicker-${id}`).value = '';
      this.value = '';

      if (this.picker) {
        this.picker.setDate(null);
      }

      return onSelectDate('');
    }

    *debounceTask(value) {
      var _this$args13, _this$args14;

      yield (0, _emberConcurrency.timeout)(this.DEBOUNCE_MS);
      const instruct = (_this$args13 = this.args) === null || _this$args13 === void 0 ? void 0 : _this$args13.instruct;
      const onSelectDate = (_this$args14 = this.args) === null || _this$args14 === void 0 ? void 0 : _this$args14.onSelectDate; // do not assign dateZ to a variable here
      // since it is a getter and will vary with dateValue

      if (instruct === 'start' && onSelectDate) {
        this.dateValue = value;
        onSelectDate({
          dateStartString: this.dateZ
        });
      } else if (instruct === 'end' && onSelectDate) {
        this.dateValue = value;
        onSelectDate({
          dateEndString: this.dateZ
        });
      } else if (onSelectDate) {
        this.dateValue = value;
        onSelectDate(this.dateZ);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateValue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateMin", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dateMax", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "picker", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isDateAllowed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "isDateAllowed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getNextAvailableDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getNextAvailableDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupCalendar", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupCalendar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "debounceTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "debounceTask"), _class.prototype)), _class));
  _exports.default = ElementsDateInputComponent;
});
define("shop/pods/components/layouts/nav-menu-nested/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7hpKh/t3",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[28,[37,4],[[30,1],[33,5]],null],[[[1,\"\\t\\t\"],[1,[28,[35,6],null,[[\"website\",\"isExpandable\",\"isSub\",\"isVertical\",\"isHorizontal\",\"translate\",\"toggle\",\"disabled\",\"item\",\"itemClassNames\",\"labelKey\",\"itemHasContentKey\",\"iconKey\",\"onSelect\",\"onNewPage\",\"onToggleNavMenu\"],[[33,7],false,[33,8],[33,9],[33,10],[33,11],[33,12],[33,13],[30,1],[33,14],[33,15],[33,16],[33,17],[33,18],[33,19],[30,2]]]]],[1,\"\\n\\n\"]],[]],[[[1,\"\\t\\t\"],[1,[28,[35,20],null,[[\"website\",\"itemClassNames\",\"translate\",\"initialIsExpanded\",\"isSub\",\"isVertical\",\"isHorizontal\",\"isFooter\",\"disabled\",\"selected\",\"toggle\",\"item\",\"labelKey\",\"itemHasContentKey\",\"iconKey\",\"subProperty\",\"onToggleNavMenu\"],[[33,7],[33,14],[33,11],[33,21],[33,8],[33,9],[33,10],[33,22],[33,13],[33,23],[33,12],[30,1],[33,15],[33,16],[33,17],[33,5],[30,2]]]]],[1,\"\\n\"]],[]]]],[1]],null]],[\"item\",\"@onToggleNavMenu\"],false,[\"each\",\"-track-array\",\"menu\",\"if\",\"isEmpty\",\"subProperty\",\"layouts/nav-menu-nested/nav-item\",\"website\",\"isSub\",\"isVertical\",\"isHorizontal\",\"translate\",\"toggle\",\"disabled\",\"itemClassNames\",\"labelKey\",\"itemHasContentKey\",\"iconKey\",\"onSelect\",\"onNewPage\",\"layouts/nav-menu-nested/nav-nested\",\"initialIsExpanded\",\"isFooter\",\"selected\"]]",
    "moduleName": "shop/pods/components/layouts/nav-menu-nested/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/items/item-variants/component", ["exports", "@ember/component", "@ember/service", "@ember/object", "ramda"], function (_exports, _component, _service, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    tagName: 'div',
    classNames: [],
    selectedImage: null,
    variantAdded: false,
    hasVariants: (0, _object.computed)('item._data.{hasVariants,variants.[]}', function () {
      if (this.get('item._data.hasVariants')) {
        return this.get('item._data.variants') || [];
      }

      return false;
    }),
    allowAddToCart: (0, _object.computed)('item.{_data.variants.@each.selectedValue,isCustomizationsSelectionCompleted}', function () {
      let allowAddToCart = false;
      const item = this.item;

      if (!item) {
        return allowAddToCart;
      }

      const variants = item.get('_data.variants') || [];
      const selected = item.get('selectedVariantOptions') || [];

      if (selected.length !== variants.length) {
        return false;
      }

      const selectedVariant = item.get('selectedVariant');

      if (!selectedVariant) {
        return false;
      }

      const isPreOrderOrActive = R.pipe(R.prop('calculatedStatusBeforeCart'), R.includes(R.__, ['active', 'preOrder']))(selectedVariant);

      if (!isPreOrderOrActive) {
        return false;
      }

      const isCustomizationsSelectionCompleted = item.get('isCustomizationsSelectionCompleted');

      if (!isCustomizationsSelectionCompleted) {
        return false;
      }

      return true;
    }),
    hasIncompleteChoices: (0, _object.computed)('allowAddToCart', function () {
      return R.not(this.allowAddToCart);
    }),
    actions: {
      resetVariantItem() {
        // variants
        const variants = this.get('item._data.variants') || [];
        R.forEach(variant => {
          variant.set('selectedValue', '');
        }, variants);
        this.set('variantAdded', false);
      },

      afterVariantAdded() {
        const goToElement = 'store-item__choice-container';
        window.$('html, body').animate({
          scrollTop: window.$(`.${goToElement}`).offset().top - 150
        }, 200);
        this.set('variantAdded', true);
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/elements/drawer/drawer-mobile/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "shop/utils/nventor"], function (_exports, _component, _object, _service, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    websiteService: (0, _service.inject)('website'),
    helpers: (0, _service.inject)(),
    router: (0, _service.inject)(),
    tagName: '',
    closeOnOverlayClick: true,
    navbarHeight: 0,
    topHeight: 25,
    startY: null,
    isSwiping: false,
    savedScrollPosition: 0,
    initialDistance: 0,
    isPinchingOut: false,

    init() {
      this._super(...arguments);

      const navbar = document.getElementsByClassName('my-navbar-header')[0];

      if (navbar) {
        (0, _object.set)(this, 'navbarHeight', navbar.offsetHeight);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      const modalCardBody = document.querySelector('.modal-card-body');

      if (modalCardBody) {
        modalCardBody.addEventListener('scroll', event => {
          if (!this.isSwiping) {
            this.set('savedScrollPosition', modalCardBody.scrollTop);
          }
        });
      }
    },

    drawerCardClassName: (0, _object.computed)('drawerCardClass', 'websiteService.isMobile', function () {
      const classNames = ['modal-card', 'cart-modal-card', 'modal-card--fullscreen', 'is-mobile'];
      const drawerCardClass = this === null || this === void 0 ? void 0 : this.drawerCardClass;

      if (drawerCardClass) {
        classNames.push(drawerCardClass);
      }

      classNames.push('modal-card--mobile');
      return classNames.join(' ');
    }),
    drawerElementStyle: (0, _object.computed)('navbarHeight', function () {
      return 'top: 0px;';
    }),
    elementStyle: (0, _object.computed)('navbarHeight', function () {
      return 'height: 100vh;';
    }),

    handleTouchStartTopOnly(event) {
      this._handleTouchStart(event);
    },

    handleTouchMoveTopOnly(event) {
      if (!this.isSwiping) {
        return;
      }

      if (this.savedScrollPosition > 0) {
        return;
      }

      this._handleTouchMove(event);
    },

    handleTouchEndTopOnly(event) {
      if (!this.isSwiping) {
        return;
      }

      if (this.savedScrollPosition > 0) {
        this.handleTouchCancel();
        return;
      }

      this._handleTouchEnd(event);
    },

    handleTouchCancelTopOnly(event) {
      this._handleTouchCancel(event);
    },

    handleTouchStart(event) {
      this._handleTouchStart(event);
    },

    handleTouchMove(event) {
      if (!this.isSwiping) {
        return;
      }

      this._handleTouchMove(event);
    },

    handleTouchEnd(event) {
      if (!this.isSwiping) {
        return;
      }

      this._handleTouchEnd(event);
    },

    handleTouchCancel(event) {
      this._handleTouchCancel(event);
    },

    _handleTouchStart(event) {
      // when zoom modal is on do not close modal on swipe down
      const skipCloseModal = this._skipCloseModal();

      if (skipCloseModal) {
        return;
      }

      const touch = event.touches[0];

      this._isPinchOutStart(event);

      this.set('startY', touch.clientY);
      this.set('isSwiping', true);
    },

    _handleTouchMove(event) {
      // when zoom modal is on do not close modal on swipe down
      this._isPinchOutMove(event);

      const skipCloseModal = this._skipCloseModal();

      if (skipCloseModal) {
        return;
      }

      const touch = event.touches[0];
      const deltaY = touch.clientY - this.startY;

      if (deltaY > 0) {
        const modalElement = document.querySelector('.modal-card--fullscreen');
        modalElement.style.transform = `translateY(${deltaY}px)`;
        modalElement.style.opacity = `${1 - deltaY / 900}`;
      }
    },

    _handleTouchEnd(event) {
      // when zoom modal is on do not close modal on swipe down
      const skipCloseModal = this._skipCloseModal();

      this._resetPinchOut();

      if (skipCloseModal) {
        return;
      }

      const touch = event.changedTouches[0];
      const deltaY = touch.clientY - this.startY;
      const modalElement = document.querySelector('.modal-card--fullscreen');
      const closeScreenHeight = window.screen.height * 0.15; // (15% screen height)

      this.set('isSwiping', false);

      if (deltaY > closeScreenHeight) {
        modalElement.style.transform = 'translateY(100%)';
        modalElement.style.opacity = '0';
        setTimeout(() => {
          this.onClose(true);
        }, 300);
      } else {
        modalElement.style.transform = 'translateY(0)';
        modalElement.style.opacity = '1';
      }
    },

    _resetPinchOut() {
      setTimeout(() => {
        this.set('isPinchingOut', false);
      }, 500);
    },

    _handleTouchCancel(event) {
      // when zoom modal is on do not close modal on swipe down
      const skipCloseModal = this._skipCloseModal();

      if (skipCloseModal) {
        return;
      }

      if (!this.isSwiping) {
        return;
      }

      const modalElement = document.querySelector('.modal-card--fullscreen');
      modalElement.style.transform = 'translateY(0)';
      modalElement.style.opacity = '1';
      this.set('isSwiping', false);
    },

    _skipCloseModal() {
      const drawerCardSelector = `.${this.drawerCardClassName.replaceAll(' ', '.')}`;
      const drawerCard = document.querySelector(drawerCardSelector);

      if (_nventor.default.isNilOrEmpty(drawerCard)) {
        return false;
      } // when zoom modal is on do not close modal on swipe down


      const carouselContainerZoomModal = drawerCard.querySelector('.carousel-container__zoom-modal');

      if (_nventor.default.isNotNilOrEmpty(carouselContainerZoomModal)) {
        return true;
      }

      if (this.isPinchingOut) {
        return true;
      }

      return false;
    },

    _calculatePinchDistances(event) {
      if (event.touches.length !== 2) {
        return null;
      }

      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const initialDistance = Math.sqrt(Math.pow(touch2.clientX - touch1.clientX, 2) + Math.pow(touch2.clientY - touch1.clientY, 2));
      return initialDistance;
    },

    _isPinchOutStart(event) {
      const initialDistance = this._calculatePinchDistances(event);

      if (initialDistance === null) {
        return;
      }

      this.set('initialDistance', initialDistance);
    },

    _isPinchOutMove(event) {
      const currentDistance = this._calculatePinchDistances(event);

      if (currentDistance === null) {
        return;
      }

      this.set('isPinchingOut', true);
    }

  }, (_applyDecoratedDescriptor(_obj, "handleTouchStartTopOnly", [_object.action], Object.getOwnPropertyDescriptor(_obj, "handleTouchStartTopOnly"), _obj), _applyDecoratedDescriptor(_obj, "handleTouchMoveTopOnly", [_object.action], Object.getOwnPropertyDescriptor(_obj, "handleTouchMoveTopOnly"), _obj), _applyDecoratedDescriptor(_obj, "handleTouchEndTopOnly", [_object.action], Object.getOwnPropertyDescriptor(_obj, "handleTouchEndTopOnly"), _obj), _applyDecoratedDescriptor(_obj, "handleTouchCancelTopOnly", [_object.action], Object.getOwnPropertyDescriptor(_obj, "handleTouchCancelTopOnly"), _obj), _applyDecoratedDescriptor(_obj, "handleTouchStart", [_object.action], Object.getOwnPropertyDescriptor(_obj, "handleTouchStart"), _obj), _applyDecoratedDescriptor(_obj, "handleTouchMove", [_object.action], Object.getOwnPropertyDescriptor(_obj, "handleTouchMove"), _obj), _applyDecoratedDescriptor(_obj, "handleTouchEnd", [_object.action], Object.getOwnPropertyDescriptor(_obj, "handleTouchEnd"), _obj), _applyDecoratedDescriptor(_obj, "handleTouchCancel", [_object.action], Object.getOwnPropertyDescriptor(_obj, "handleTouchCancel"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/components/helpers/route-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VXHfafPk",
    "block": "[[[10,0],[14,0,\"my-navbar-container\"],[12],[1,\"\\n  \"],[10,\"table\"],[14,0,\"my-navbar-container-table\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"td\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"button button--no-bg button--no-border button--tile has-text-primary has-text-weight-bold is-loading\"],[12],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"td\"],[14,0,\"has-text-right\"],[12],[1,\"\\n\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,\"\\n\"],[1,\"\\n\"],[1,\"      \"],[8,[39,0],[[16,\"isRunning\",true]],null,null],[1,\"\\n\\n\"]],[],false,[\"helpers/is-running\"]]",
    "moduleName": "shop/pods/components/helpers/route-loading/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/elements/element-toggler/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ria04QP",
    "block": "[[[11,1],[16,0,[29,[\"element-toggler-container \",[52,[33,1],\"disabled\"]]]],[4,[38,2],[[30,0],\"toggle\"],null],[12],[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[1,[28,[35,4],null,[[\"label\",\"translate\"],[[33,3],[33,5]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,6],[[16,0,[52,[33,7],\"element-toggler has-text-success\",\"element-toggler\"]]],[[\"@icon\"],[[52,[33,7],\"fas fa-toggle-on\",\"fas fa-toggle-off\"]]],null],[1,\"\\n\"],[41,[33,8],[[[1,\"    \"],[1,[28,[35,4],null,[[\"label\",\"translate\"],[[33,8],[33,5]]]]],[1,\"\\n\"]],[]],null],[41,[33,9],[[[1,\"    \"],[1,[28,[35,4],null,[[\"label\",\"translate\"],[[33,9],[33,5]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,10],[[[1,\"    \"],[1,[28,[35,4],null,[[\"label\",\"translate\"],[[33,10],[33,5]]]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"if\",\"disabled\",\"action\",\"leftLabel\",\"elements/element-label\",\"translate\",\"elements/element-icon\",\"isOn\",\"labelOn\",\"labelOff\",\"label\"]]",
    "moduleName": "shop/pods/components/elements/element-toggler/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/website/privacy-policy/route", ["exports", "@ember/routing/route", "rsvp", "shop/pods/page/model"], function (_exports, _route, _rsvp, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MemberPrivacyPolicyRoute extends _route.default {
    async model() {
      const website = this.modelFor('application').website;
      const privacyPolicy = website.getDynamicPage('privacy-policy');

      const page = _model.default.create();

      page.populate(privacyPolicy);
      return _rsvp.default.hash({
        website,
        page
      });
    }

  }

  _exports.default = MemberPrivacyPolicyRoute;
});
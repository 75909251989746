define("shop/pods/components/items/item-set-with-choices/component", ["exports", "ramda", "@ember/component", "@ember/service", "@ember/object", "shop/mixins/big", "shop/utils/nventor"], function (_exports, R, _component, _service, _object, _big, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_big.default, {
    tagName: '',
    selectedImage: null,
    hasIncompleteChoices: true,
    setAdded: false,
    router: (0, _service.inject)(),

    // fastboot: service(),
    init() {
      this.setupSetItems();

      this._super(...arguments);
    },

    setupSetItems() {
      const item = this.item;
      const overSalesLimitedQtyStatus = item._data.overSalesLimitedQtyStatus;
      const choices = this.get('item._data.choices') || [];
      R.forEach(choice => {
        const choiceDetails = R.pipe(R.pathOr([], ['_data', 'details']), R.reject(R.anyPass([R.pathEq(['_data', 'calculatedStatusBeforeCart'], 'outOfStock'), R.pathEq(['_data', 'calculatedStatusBeforeCart'], 'soldOut'), R.pathEq(['_data', 'calculatedStatusBeforeCart'], 'inactive')])))(choice);
        const hasVariantDetails = R.pipe(R.pluck('_data'), R.pluck('hasVariants'), R.flatten, R.filter(R.equals(true)), R.length, R.gt(R.__, 0))(choiceDetails);
        choiceDetails.forEach(detail => {
          let defaultDetailQty = 0;

          if (detail.hasVariants) {
            detail.set('selectedVariantChoices', []);
          }

          if (choiceDetails.length === 1) {
            if (hasVariantDetails) {
              this.newVariantChoice(detail);
            } else {
              defaultDetailQty = choice._data.qty;
              const choiceDetail = choiceDetails[0];
              const hasLimitedQty = choiceDetail._data.hasLimitedQty;

              if (hasLimitedQty && overSalesLimitedQtyStatus === 'soldOut') {
                const availableQty = parseInt(choiceDetail._data.calculatedTotalAvailableQtyBeforeCart) || 0;

                if (defaultDetailQty > availableQty) {
                  defaultDetailQty = availableQty;
                }
              }
            }
          }

          detail.set('_data.qty', defaultDetailQty);
          this.updateGroupSelectedQty(choice, detail);
        });
      }, choices);
    },

    newVariantChoice(detail) {
      if (detail._data.hasVariants) {
        const selectedVariantChoices = detail.get('selectedVariantChoices') || [];
        const emptyChoice = R.find(R.propEq('qty', 0))(selectedVariantChoices);

        if (emptyChoice) {
          selectedVariantChoices.removeObject(emptyChoice);
        }

        const data = {
          qty: 0,
          selectedOptions: {},
          selectedVariantChild: ''
        };

        const variantChoice = _object.default.create(data);

        selectedVariantChoices.pushObject(variantChoice);
        detail.set('selectedVariantChoices', selectedVariantChoices);
      }
    },

    // isSetWithChoices: computed('item._data.{isSetWithChoices,choices.[]}', function () {
    //   if (this.get('item._data.isSetWithChoices')) {
    //     return this.get('item._data.choices') || []
    //   }
    //   return false
    // }),
    updateGroupSelectedQty(choice, detail, qty, qtyUpdated = true) {
      const isChoiceCurrentlyComplete = choice.get('isGroupComplete');
      const allSelectedQtys = R.pipe(R.pathOr([], ['_data', 'details']), R.pluck('_data'), R.pluck('qty'), R.reject(R.isNil))(choice);
      const totalSelectedQty = R.reduce((acc, value) => {
        return acc + parseInt(value);
      }, 0, allSelectedQtys);
      let choiceQty = choice.get('_data.qty') || 0;
      choiceQty = parseInt(choiceQty);
      const groupRemainingQty = choiceQty - totalSelectedQty;
      choice.set('totalSelectedQty', totalSelectedQty);
      choice.set('groupRemainingQty', groupRemainingQty);
      const activeChoiceDetails = R.pipe(R.pathOr([], ['_data', 'details']), R.reject(R.anyPass([R.pathEq(['_data', 'calculatedStatusBeforeCart'], 'outOfStock'), R.pathEq(['_data', 'calculatedStatusBeforeCart'], 'soldOut'), R.pathEq(['_data', 'calculatedStatusBeforeCart'], 'inactive')])))(choice);

      if (groupRemainingQty <= 0 && qtyUpdated) {
        if (isChoiceCurrentlyComplete && activeChoiceDetails.length !== 1) {
          detail.set('noMoreChoicesMessage', 'no more choices, please decrease other item qtys');
        }
      }

      this.updateDetails(choice, groupRemainingQty);
      this.updateTotals();
      this.updateChoicesIsComplete();
    },

    updateDetails(choice, groupRemainingQty) {
      const details = R.pathOr([], ['_data', 'details'])(choice);
      const isOnSale = this.get('item._data.onSale');

      if (groupRemainingQty > 0) {
        choice.set('isGroupComplete', false);
      } else {
        choice.set('isGroupComplete', true);
      }

      R.forEach(detail => {
        const qty = parseInt(detail._data.qty) || 0;

        const currentAmountData = this._getDetailAmount(isOnSale, qty, detail);

        detail.set('amountOld', currentAmountData.amountOld);
        detail.set('amountCurrent', currentAmountData.amountCurrent);
        let itemMaxQty = qty;

        if (groupRemainingQty > 0) {
          if (qty > groupRemainingQty) {
            itemMaxQty = groupRemainingQty;
          }

          itemMaxQty = qty + groupRemainingQty;
          detail.set('noMoreChoicesMessage', '');
        }

        detail.set('itemMaxQty', itemMaxQty);
      }, details);
    },

    updateTotals() {
      const choices = this.get('item._data.choices') || [];
      const item = this.item;
      const isOnSale = this.get('item._data.onSale');
      let totalAmountOld = this.newBig(0);
      let totalAmountCurrent = this.newBig(0);
      R.forEach(choice => {
        const isSetPriceOnBundle = R.pathOr(false, ['_data', 'isSetPriceOnBundle'], choice);
        const price = R.pathOr(false, ['_data', 'price'], choice);
        const priceDiscounted = R.pathOr(false, ['_data', 'priceDiscounted'], choice);
        const details = R.pathOr([], ['_data', 'details'], choice);
        const choiceQty = R.pathOr(0, ['_data', 'qty'], choice);
        const choosenQty = R.pipe(R.pluck('_data'), R.pluck('qty'), R.sum)(details);
        R.map(detail => {
          let qty = 0;

          if (detail.get('hasVariants')) {
            R.pipe(R.propOr([], 'selectedVariantChoices'), R.map(variantChoice => {
              const qty = variantChoice.qty;
              const variantChild = variantChoice.selectedVariantChild;
              let currentAmountData = {
                amountOld: 0,
                amountCurrent: 0
              };

              if (variantChild) {
                const variantChildData = _object.default.create({
                  _data: variantChild
                });

                currentAmountData = this._getDetailAmount(isOnSale, qty, variantChildData);

                if (isSetPriceOnBundle) {
                  currentAmountData.amountOld = 0;
                  currentAmountData.amountCurrent = 0;
                }

                totalAmountOld = totalAmountOld.plus(currentAmountData.amountOld);
              }

              totalAmountCurrent = totalAmountCurrent.plus(currentAmountData.amountCurrent);
            }))(detail);
          } else {
            qty = detail.get('_data.qty');

            const currentAmountData = this._getDetailAmount(isOnSale, qty, detail);

            if (isSetPriceOnBundle) {
              currentAmountData.amountOld = 0;
              currentAmountData.amountCurrent = 0;
            }

            totalAmountOld = totalAmountOld.plus(currentAmountData.amountOld);
            totalAmountCurrent = totalAmountCurrent.plus(currentAmountData.amountCurrent);
          }

          return detail;
        })(details);
        let amountOld = 0;
        let amountCurrent = 0;

        if (isSetPriceOnBundle && R.equals(parseInt(choiceQty), parseInt(choosenQty))) {
          amountOld = price;
          amountCurrent = price;

          if (R.equals(isOnSale, 'on')) {
            amountCurrent = priceDiscounted;
          }
        }

        totalAmountOld = totalAmountOld.plus(amountOld);
        totalAmountCurrent = totalAmountCurrent.plus(amountCurrent);
      })(choices);
      this.set('totalAmountOld', totalAmountOld.toFixed(0));
      this.set('totalAmountCurrent', totalAmountCurrent.toFixed(0));
      item.set('_data.price', totalAmountOld.toFixed(0));
      item.set('_data.priceDiscounted', totalAmountCurrent.toFixed(0));
      item.set('totalAmountCurrent', totalAmountCurrent.toFixed(0));
    },

    _getDetailAmount(isOnSale, qty, detail) {
      const amountOld = this._getAmountOld(qty, detail);

      let amountCurrent = amountOld; // let isDetailOnSale = R.pathOr('off', ['_data', 'onSale'])(detail)

      if (isOnSale === 'on') {
        let price = R.pathOr(0, ['_data', 'priceDiscounted'])(detail);
        price = parseInt(price) || 0;
        amountCurrent = qty * price;
      }

      return {
        amountOld,
        amountCurrent
      };
    },

    _getAmountOld(qty, detail) {
      let price = R.pathOr(0, ['_data', 'price'])(detail);
      price = parseInt(price) || 0;
      return qty * price;
    },

    updateChoicesIsComplete() {
      if (this.get('item._data.isSoldOut') || this.get('item.isSoldOut')) {
        return true;
      }

      const choices = this.get('item._data.choices') || [];
      const hasIncompleteChoices = R.pipe(R.pluck('isGroupComplete'), R.uniq, R.includes(false))(choices);
      this.set('hasIncompleteChoices', hasIncompleteChoices);
      return hasIncompleteChoices;
    },

    isAddedSetRemoved: (0, _object.computed)('shop.validated.{limitedQtyCartItems.[],removedCartItems.[]}', function () {
      const limitedQtyCartItems = this.get('shop.validated.limitedQtyCartItems') || [];
      const removedCartItems = this.get('shop.validated.removedCartItems') || [];

      if (!_nventor.default.isNilOrEmpty(limitedQtyCartItems) || !_nventor.default.isNilOrEmpty(removedCartItems)) {
        return false;
      }

      return true;
    }),
    actions: {
      updateGroupSelectedQty(choice, child, qty, qtyUpdated) {
        this.updateGroupSelectedQty(choice, child, qty, qtyUpdated);
      },

      afterSetAdded() {
        // const goToElement = 'store-item__choice-container'
        const shop = this.shop;

        if (this.itemStatus) {
          this.itemStatus.set('isAdding', true);
          shop.set('isAdding', true);
        } // window.$('html, body').animate({
        //   scrollTop: window.$(`.${goToElement}`).offset().top - 150
        // }, 200)
        // nventor.goToClassName(goToElement, -150)


        if (this.itemStatus) {
          setTimeout(() => {
            shop.set('isAdding', false);

            if (!this.isDestroyed) {
              this.itemStatus.set('isAdding', false);
            }
          }, 800);
        }

        this.set('setAdded', true);
      },

      resetSetItem() {
        this.setupSetItems(); // this.set('setAdded', false)
        // const goToElement = 'store-item__choice-container'
        // nventor.goToClassName(goToElement, -150)
      },

      newVariantChoice() {
        return this.newVariantChoice(...arguments);
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/nav-menu-nested/nav-nested/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iNQx9psL",
    "block": "[[[1,[28,[35,0],null,[[\"website\",\"itemClassNames\",\"translate\",\"onToggle\",\"item\",\"isSub\",\"isExpandable\",\"isExpanded\",\"disabled\",\"labelKey\",\"iconKey\",\"count\"],[[33,1],[33,2],[33,3],[28,[37,4],[[30,0],\"toggleMenu\"],null],[33,5],[33,6],true,[33,7],[33,8],[33,9],[33,10],[33,11]]]]],[1,\"\\n\\n\"],[41,[33,7],[[[1,\"  \"],[1,[28,[35,13],null,[[\"parentWidth\",\"website\",\"itemClassNames\",\"initialIsExpanded\",\"isHorizontal\",\"isSub\",\"isVertical\",\"isFooter\",\"toggle\",\"translate\",\"classNames\",\"menu\",\"navItemWidth\",\"subProperty\",\"disabled\",\"labelKey\",\"iconKey\"],[[30,0,[\"element\",\"offsetWidth\"]],[33,1],[33,2],[33,14],[33,15],true,[33,16],[33,17],[33,18],[33,3],\"nav-tree__children-container nav-tree__children-container-scrollable\",[28,[37,19],[[33,5],[33,20]],null],[33,21],[33,20],[33,8],[33,9],[33,10]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"layouts/nav-menu-nested/nav-item\",\"website\",\"itemClassNames\",\"translate\",\"action\",\"item\",\"isSub\",\"isExpanded\",\"disabled\",\"labelKey\",\"iconKey\",\"count\",\"if\",\"layouts/nav-menu-nested\",\"initialIsExpanded\",\"isHorizontal\",\"isVertical\",\"isFooter\",\"toggle\",\"get\",\"subProperty\",\"navItemWidth\"]]",
    "moduleName": "shop/pods/components/layouts/nav-menu-nested/nav-nested/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/carousel-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4WydNjKy",
    "block": "[[[41,[30,0,[\"flowsService\",\"scrollingNotificationFlowsForRoute\"]],[[[1,\"  \"],[10,0],[14,0,\"carousel-banner-container\"],[12],[1,\"\\n    \"],[11,\"ul\"],[24,0,\"carousel-banner-list-container\"],[4,[38,1],[[30,0,[\"setupSliderList\"]]],null],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"did-insert\"]]",
    "moduleName": "shop/pods/components/layouts/carousel-banner/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
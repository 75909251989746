define("shop/pods/blogs/blog/route", ["exports", "@ember/routing/route", "rsvp", "@ember/service"], function (_exports, _route, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    websiteService: (0, _service.inject)('website'),
    seoService: (0, _service.inject)('seo'),

    async model(params) {
      var _blog$_data;

      const blog = await this.websiteService.get('getBlogTask').perform({
        params
      });
      this.websiteService.updateTitle(blog === null || blog === void 0 ? void 0 : (_blog$_data = blog._data) === null || _blog$_data === void 0 ? void 0 : _blog$_data.name);
      const website = this.modelFor('application').website;
      const shop = this.modelFor('application').shop;
      const isCampaign = this.modelFor('application').isCampaign;
      const blogs = this.modelFor('blogs').blogs;
      this.seoService.setupStructuredData({
        structuredData: [{
          data: blog || {},
          type: 'blog'
        }]
      });

      try {
        return _rsvp.default.hash({
          blogs,
          blog,
          params,
          website,
          shop,
          isCampaign
        });
      } catch (e) {
        this.transitionTo('/');
      }
    },

    redirect(model) {
      if (!model) {
        this.transitionTo('/');
      }
    }

  });

  _exports.default = _default;
});
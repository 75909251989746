define("shop/pods/social-login/service", ["exports", "@ember/service", "ramda", "shop/config/environment"], function (_exports, _service, R, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const LOGIN_PROVIDERS = Object.freeze({
    line: _environment.default.line.login,
    facebook: _environment.default.facebook.login
  });
  const REDIRECT_PROVIDERS = Object.freeze({
    line: _environment.default.line.afterCallbackHook,
    facebook: _environment.default.facebook.afterCallbackHook
  });
  let SocialLoginService = (_dec = (0, _service.inject)('website'), (_class = class SocialLoginService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "websiteService", _descriptor, this);
    }

    get isInfluencerRoute() {
      var _window, _window$location;

      const path = (_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.pathname;
      return R.includes('influencer')(path);
    }
    /**
     * Generate social login url
     * @param {string} socialLoginProvider - line, facebook
     * @returns {string}
     */


    generateLoginUrl(socialLoginProvider) {
      var _window2, _window2$location, _window3, _window3$location;

      const path = (_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$location = _window2.location) === null || _window2$location === void 0 ? void 0 : _window2$location.pathname;
      const origin = ((_window3 = window) === null || _window3 === void 0 ? void 0 : (_window3$location = _window3.location) === null || _window3$location === void 0 ? void 0 : _window3$location.origin) + path;
      const originEncoded = encodeURIComponent(origin);
      const params = this.websiteService.parseParamsFromWindowLocation(window.location);
      let qs = '';

      if (this.isInfluencerRoute) {
        const influencerData = {
          url: path.split(/\//gm)[2],
          token: R.prop('token', params)
        };
        qs = `&${new URLSearchParams(influencerData).toString()}`;
      }

      const providerUrl = LOGIN_PROVIDERS[socialLoginProvider];
      return `${providerUrl}?origin=${originEncoded}${qs}`;
    }
    /**
     * Generate social login redirect url
     * @param {string} socialLoginProvider - line, facebook
     * @returns {string}
     */


    redirectToOrigin(socialLoginProvider) {
      const params = this.websiteService.parseParamsFromWindowLocation(window.location);
      const hasCorrectParams = R.both(R.has('state'), R.has('code'))(params);

      if (hasCorrectParams) {
        const providerUrl = REDIRECT_PROVIDERS[socialLoginProvider];
        return `${providerUrl}${window.location.search}`;
      }

      return '';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SocialLoginService;
});
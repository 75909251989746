define("shop/utils/google-maps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = googleMaps;

  function googleMaps() {
    return true;
  }
});
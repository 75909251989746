define("shop/pods/contact-us/route", ["exports", "@ember/routing/route", "shop/pods/page/model", "rsvp", "ramda", "shop/utils/nventor"], function (_exports, _route, _model, _rsvp, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ContactUsRoute extends _route.default {
    async model(params) {
      const host = this.modelFor('application').host;
      const website = this.modelFor('application').website;
      const shop = this.modelFor('application').shop;
      const isCampaign = this.modelFor('application').isCampaign;
      const contactUs = website.getDynamicPage('contact us');

      if (_nventor.default.isNilOrEmpty(contactUs)) {
        return this.transitionTo('/not-found-404');
      }

      const homePageRedirect = R.propEq('homePageRedirect', true)(contactUs);

      if (homePageRedirect) {
        return this.transitionTo('/');
      }

      const page = _model.default.create();

      page.populate(contactUs);

      try {
        return _rsvp.default.hash({
          host,
          params,
          shop,
          website,
          isCampaign,
          page
        });
      } catch (e) {
        this.transitionTo('/');
      }
    }

  }

  _exports.default = ContactUsRoute;
});
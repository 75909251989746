define("shop/pods/components/news/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t0qwWDXa",
    "block": "[[[10,0],[14,0,\"news-page-content page-content-template\"],[12],[1,\"\\n  \"],[18,1,[[30,0]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "shop/pods/components/news/content/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
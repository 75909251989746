define("shop/pods/components/links-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j65JtUiG",
    "block": "[[[11,0],[16,0,[29,[\"flex-container-row shop-tags-menu__mobile-container \",[52,[30,0,[\"mobileOnly\"]],\"mobile-only\"],\" \",[52,[30,0,[\"websiteService\",\"isShopIndexRoute\"]],\"links-slider__hide-on-shop\",\"links-slider__top\"],\" \",[30,0,[\"classNamesWhenFaqs\"]],\" \",[30,0,[\"componentId\"]],\" links-slider\"]]],[16,5,[30,0,[\"linkSliderStyle\"]]],[17,1],[4,[38,1],[[28,[37,2],[[30,0,[\"setupTopInnerStickyTabs\"]],[30,0,[\"linkSliderHeight\"]]],null]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,0],[15,0,[30,2]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,3]],null]],null],null,[[[1,\"        \"],[11,3],[16,6,[30,4,[\"href\"]]],[16,0,[29,[\"button \",[52,[30,4,[\"isSelected\"]],\"is-selected\"]]]],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"onToggle\"]],[30,4]],null]],null],[12],[1,\"\\n          \"],[1,[30,4,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,3]],null]],null],null,[[[1,\"      \"],[11,3],[16,6,[30,5,[\"href\"]]],[16,0,[29,[\"button \",[52,[30,5,[\"isSelected\"]],\"is-selected\"]]]],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"onToggle\"]],[30,5]],null]],null],[12],[1,\"\\n        \"],[1,[30,5,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[5]],null]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@innerClassName\",\"@links\",\"link\",\"link\"],false,[\"if\",\"did-update\",\"perform\",\"each\",\"-track-array\",\"on\",\"fn\"]]",
    "moduleName": "shop/pods/components/links-slider/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
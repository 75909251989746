define("shop/pods/components/layouts/pagination-bar/component", ["exports", "ramda", "@ember/component", "@ember/service", "@ember/object", "shop/utils/nventor"], function (_exports, R, _component, _service, _object, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    filtersService: (0, _service.inject)('filters'),
    tagName: 'div',
    totalPages: (0, _object.computed)('pagesToDisplay', 'totalCount', 'count', 'currentPage', function () {
      const totalCount = this.totalCount;
      const count = this.count;
      return Math.ceil(totalCount / count);
    }),
    hasPageNumbers: (0, _object.computed)('totalPages', function () {
      const totalPages = this.totalPages || 1;

      if (totalPages > 1) {
        return true;
      }

      return false;
    }),
    pageNumbers: (0, _object.computed)('pagesToDisplay', 'totalPages', 'currentPage', function () {
      const totalPages = this.totalPages || 0;

      if (totalPages > 0) {
        const pagesToDisplay = this.pagesToDisplay || 5;
        let currentPage = parseInt(this.currentPage) || 1;
        const createPageRange = R.curry((currentPage, end, start) => {
          return R.unfold(_createPageRange(currentPage, end), start);
        });

        const _createPageRange = R.curry((currentPage, end, start) => {
          if (start > end) {
            return false;
          }

          return [createPage(currentPage, start), R.inc(start)];
        });

        const addNextPage = R.curry((currentPage, totalPages, page) => {
          if (page < totalPages) {
            return createPage(currentPage, page);
          }

          return false;
        });
        const createPage = R.curry((currentPage, page) => {
          let isCurrent = false;
          let isBtn = false;

          if (page !== '...') {
            isBtn = true;
          }

          let classNames = 'pagination-link';

          if (currentPage === page) {
            isCurrent = true;
            classNames = `${classNames} is-current`;
          }

          return {
            page,
            isCurrent,
            classNames,
            isBtn
          };
        });

        if (currentPage > totalPages) {
          currentPage = totalPages;
        }

        if (totalPages <= pagesToDisplay) {
          return createPageRange(currentPage, totalPages, 1);
        }

        const parts = [];

        if (currentPage - 2 > 1) {
          parts.push(createPage(currentPage, 1));
          parts.push(createPage(currentPage, '...'));
          const middleStart = currentPage - 2;
          parts.push(createPageRange(currentPage, currentPage, middleStart));
        } else {
          parts.push(createPageRange(currentPage, currentPage, 1));
        }

        parts.push(addNextPage(currentPage, totalPages, currentPage + 1));
        parts.push(addNextPage(currentPage, totalPages, currentPage + 2));

        if (totalPages - currentPage > 2) {
          parts.push(createPage(currentPage, '...'));
        }

        if (currentPage !== totalPages) {
          parts.push(createPage(currentPage, totalPages));
        }

        return R.pipe(R.flatten, R.reject(R.equals(false)))(parts);
      }

      return [];
    }),
    previousPage: (0, _object.computed)('currentPage', function () {
      const currentPage = parseInt(this.currentPage) || 1;

      if (currentPage > 1) {
        return currentPage - 1;
      }

      return false;
    }),
    nextPage: (0, _object.computed)('totalPages', 'currentPage', function () {
      const totalPages = this.totalPages || 0;
      const currentPage = parseInt(this.currentPage) || 1;

      if (currentPage + 1 <= totalPages) {
        return currentPage + 1;
      }

      return false;
    }),

    transitionToFn(pageParams) {
      // const linkToUrl = this.linkToUrl
      // const linkToRoute = this.linkToRoute
      const filterModel = this.pageComponent.filterModel;
      filterModel.setData('count', this.count);
      filterModel.setData('page', pageParams); // this.pageComponent.fetchTask.perform(params)

      return this.filtersService.updateFilter({
        filterModel,
        onUpdateFilter: this.pageComponent.fetchTask,
        updateUrlParams: true,
        isPaginate: true
      }); // this.set('currentPage', pageParams)
      // const paramKeys = R.keys(this.pageComponent.filterModel.params)
      // const newParamsData = R.pick(paramKeys, params) || {}
      // let newUrl = ''
      // if (this.linkToUrl) {
      //   newUrl = `${linkToUrl}?${this.filtersService.buildQueryString(newParamsData)}`
      // } else {
      //   newUrl = `${linkToRoute}?${this.filtersService.buildQueryString(newParamsData)}`
      // }
      // window.history.replaceState(newParamsData, null, newUrl)
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // })
      // if (this.linkToUrl) {
      //   this.router.transitionTo(linkToRoute, linkToUrl, { queryParams: { count: this.count, page: pageParams } })
      // } else {
      //   this.router.transitionTo(linkToRoute, { queryParams: { count: this.count, page: pageParams } })
      // }
    },

    goToTopOfContainer() {
      // scroll to top of that container
      if (!this.classNameToGo) {
        this.classNameToGo = 'page-container';
      }

      _nventor.default.goToClassName(this.classNameToGo, -150);
    },

    actions: {
      goToUrl(pageData) {
        this.goToTopOfContainer();
        const onGoToUrl = this.onGoToUrl;

        if (onGoToUrl) {
          return onGoToUrl(pageData.page);
        }

        this.transitionToFn(pageData.page);
      },

      goToPrevious() {
        this.goToTopOfContainer();
        const onPrevious = this.onPrevious;

        if (onPrevious) {
          return onPrevious(this.previousPage);
        }

        this.transitionToFn(this.previousPage);
      },

      goToNext() {
        this.goToTopOfContainer();
        const onNext = this.onNext;

        if (onNext) {
          return onNext(this.nextPage);
        }

        this.transitionToFn(this.nextPage);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "goToTopOfContainer", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToTopOfContainer"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/components/items/item-page/bulk-discount-row/component", ["exports", "ramda", "@ember/component", "@ember/service", "@ember/object"], function (_exports, R, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    description: (0, _object.computed)('rule', 'membersService.tokenData.memberLevelId', 'shop.{rewardData,_data.rewardData}', 'bulkDiscount.isApplyToAllProducts', function () {
      var _this$shop, _this$shop2, _this$shop2$_data;

      const bulkDiscount = this.get('bulkDiscount') || {};
      const isApplyToAllProducts = bulkDiscount.isApplyToAllProducts || false;
      const rewardData = (this === null || this === void 0 ? void 0 : (_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : _this$shop.rewardData) || (this === null || this === void 0 ? void 0 : (_this$shop2 = this.shop) === null || _this$shop2 === void 0 ? void 0 : (_this$shop2$_data = _this$shop2._data) === null || _this$shop2$_data === void 0 ? void 0 : _this$shop2$_data.rewardData) || {};
      const rule = this.get('rule') || {};
      const memberLevelId = this.get('membersService.tokenData.memberLevelId') || false;
      const hasMemberLevels = R.propOr(false, 'hasMemberLevels')(rule);

      if (!hasMemberLevels || R.isEmpty(rewardData)) {
        const isDefaultDescription = R.propOr(false, 'isDefaultDescription')(rule);

        if (isDefaultDescription) {
          return this.shopService.getBulkDiscountDescription({
            data: rule,
            isApplyToAllProducts
          });
        }

        return this.getCustomDescription({
          rule
        });
      }

      if (rule.memberLevelId === memberLevelId) {
        const isDefaultDescription = R.propOr(false, 'isDefaultDescription')(rule);

        if (isDefaultDescription) {
          return this.shopService.getBulkDiscountDescription({
            data: rule,
            isApplyToAllProducts
          });
        }

        return this.getCustomDescription({
          rule
        });
      }

      return '';
    }),

    getCustomDescription({
      rule
    }) {
      let customDescription = (rule === null || rule === void 0 ? void 0 : rule.description) || '';

      if (R.includes('\n', customDescription)) {
        customDescription = R.replace(/\n/g, '<br>', customDescription);
      }

      return customDescription;
    },

    isShowLineDescription: (0, _object.computed)('description', 'rule.{isShowLineDescription,status}', function () {
      const description = this.description || '';
      const rule = this.rule || {};
      const status = rule.status || 'active';
      const isShowLineDescription = R.propOr(true, 'isShowLineDescription')(rule);

      if (description && isShowLineDescription && status === 'active') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});
define("shop/pods/validator/service", ["exports", "@ember/service", "@ember/object", "shop/utils/nventor", "ramda"], function (_exports, _service, _object, _nventor, R) {
  /* global Ajv */
  'use strict';
  /**
   * @project: bigordr-shop
   * @file: service.js
   * @author Nafis Rubio
   * @since 27/08/2019
   * @copyright (C) 2019 BigOrdr
   * @description: Service
   */

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function ValidationError(data) {
    const instance = new Error('Validation Error');
    instance.data = data;
    Object.setPrototypeOf(instance, Object.getPrototypeOf(this));

    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ValidationError);
    }

    return instance;
  }

  ValidationError.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  var _default = _service.default.extend((_obj = {
    validate(schema, data) {
      this.websiteService.clearErrors();
      const ajv = new Ajv({
        allErrors: true,
        $data: true
      });
      const validator = ajv.compile(schema);
      const valid = validator(data);

      if (valid) {
        return true;
      }

      const errors = this.getErrorMessage(validator);
      throw new ValidationError(errors);
    },

    validateByType({
      type,
      key,
      value
    }) {
      const ajv = new Ajv({
        allErrors: true,
        $data: true
      });
      const schema = this.generateSchema(type, key);
      const validator = ajv.compile(schema);
      const valid = validator({
        [key]: value
      });

      if (valid) {
        return true;
      }

      const errors = this.getErrorMessage(validator);
      throw new ValidationError(errors);
    },

    getSchemaProps() {
      const props = {
        text: {
          type: 'string',
          minLength: 1,
          maxLength: 300
        },
        number: {
          type: 'number'
        },
        date: {
          type: 'string',
          pattern: '^[0-9]{4}-[0-9]{2}-[0-9]{2}$'
        },
        mobileTw: {
          telephone: {
            type: 'string',
            minLength: 10,
            maxLength: 10
          }
        },
        name: {
          type: 'string',
          minLength: 1,
          maxLength: 150
        },
        requiredEmail: {
          anyOf: [{
            type: 'string',
            format: 'email',
            minLength: 1,
            maxLength: 320
          }]
        },
        email: {
          anyOf: [{
            type: 'null'
          }, {
            type: 'string',
            maxLength: 0
          }, {
            type: 'string',
            format: 'email',
            maxLength: 320
          }]
        },
        unverifiedEmail: {
          anyOf: [{
            type: 'null'
          }, {
            type: 'string',
            maxLength: 0
          }, {
            type: 'string',
            format: 'email',
            maxLength: 320
          }]
        },
        telephone: {
          type: 'string',
          minLength: 9,
          maxLength: 30
        },
        telephoneByCountryCode: [{
          if: {
            properties: {
              countryCode: {
                const: 'TW'
              }
            }
          },
          then: {
            properties: {
              telephone: {
                type: 'string',
                minLength: 10,
                maxLength: 10
              }
            }
          },
          else: {
            properties: {
              telephone: {
                type: 'string',
                minLength: 9,
                maxLength: 30
              }
            }
          }
        }],
        taxNumber: {
          type: 'string',
          minLength: 8,
          maxLength: 8,
          if: {
            pattern: '^\\d{1,7}$'
          },
          then: {
            pattern: '^\\d{8}$'
          },
          else: {
            pattern: '^\\d*$'
          }
        },
        companyName: {
          type: 'string',
          minLength: 3,
          maxLength: 200
        },
        twEInvoiceCarrierType: {
          type: 'string'
        },
        twEInvoiceCarrierId: {
          type: 'string'
        },
        twEInvoiceCarrierId_mobile: {
          type: 'string',
          minLength: 8,
          maxLength: 8
        },
        twEInvoiceCarrierId_citizenDigitalCertificate: {
          type: 'string',
          minLength: 16,
          maxLength: 16
        },
        gender: {
          type: 'string'
        },
        otherGender: {
          type: 'string',
          maxLength: 300
        },
        birthday: {
          type: 'string'
        },
        // hasUserSetBirthday: {
        //   type: 'boolean'
        // },
        useDefaultTaxNumber: {
          type: 'boolean'
        },
        password: {
          minLength: 8,
          type: 'string'
        },
        confirmPassword: {
          const: {
            $data: '1/password'
          },
          type: 'string'
        },
        country: {
          type: 'string',
          anyOf: [{
            minLength: 2,
            maxLength: 300
          }]
        },
        countryCode: {
          type: 'string',
          anyOf: [{
            minLength: 2,
            maxLength: 300
          }]
        },
        address: {
          country: {
            type: 'string',
            anyOf: [{
              minLength: 2,
              maxLength: 300
            }]
          },
          countryCode: {
            type: 'string',
            anyOf: [{
              minLength: 2,
              maxLength: 300
            }]
          },
          street: {
            type: 'string',
            anyOf: [{
              minLength: 4,
              maxLength: 300
            }]
          },
          district: {
            type: 'string',
            anyOf: [{
              maxLength: 0
            }, {
              minLength: 2,
              maxLength: 100
            }]
          },
          region: {
            type: 'string',
            anyOf: [{
              maxLength: 0
            }, {
              minLength: 2,
              maxLength: 300
            }]
          },
          hasPostCode: {
            type: 'boolean'
          },
          postCode: {
            type: 'string',
            anyOf: [{
              minLength: 3,
              maxLength: 12
            }]
          },
          postCodeNotRequired: {
            type: 'string',
            anyOf: [{
              minLength: 0,
              maxLength: 12
            }, {
              minLength: 3,
              maxLength: 12
            }]
          }
        },
        creditCard: {
          ccNumber: {
            type: 'string',
            pattern: '^(?:(?=⦁)([⦁]{12}[0-9]{4})|(^([0-9]{4})\\s([0-9]{4})\\s([0-9]{4})\\s([0-9]{4})$))',
            maxLength: 19
          },
          ccExpiry: {
            type: 'string',
            pattern: '^(?:(?=0)([0][1-9])|([1][0-2]))\\/(?:(?=1)([1][9])|([2-3][0-9]))$',
            maxLength: 5
          },
          ccSecureCode: {
            type: 'string',
            pattern: '^(?:(?=⦁)([⦁]{3})|(^([0-9]{3})$))',
            maxLength: 3
          }
        },
        existingCreditCard: {
          ccNumber: {
            type: 'string',
            minLength: 19,
            maxLength: 19
          },
          ccExpiry: {
            type: 'string',
            pattern: '^(?:(?=0)([0][1-9])|([1][0-2]))\\/(?:(?=1)([1][9])|([2-3][0-9]))$',
            maxLength: 5
          }
        }
      };
      return props;
    },

    generateSchema(type, key) {
      if (type === 'mobile') {
        // @note: currently can only handle tw mobile
        type = 'mobileTw';
      }

      const props = this.getSchemaProps();
      let prop = props[type] || {};

      if (!prop) {
        _nventor.default.throw('invalid type supplied to schema');

        prop = props['text'];
      }

      return {
        required: [key],
        type: 'object',
        properties: {
          [key]: prop
        }
      };
    },

    getErrorMessage(validator) {
      /*
      * TODO: FUTURE ENHANCEMENTS
      * TODO: ADD MESSAGING TRANSLATION FOR EACH ERROR MESSAGE
      * */
      const returnErrorMessages = ({
        dataPath
      }) => {
        return {
          [R.drop(1)(dataPath)]: {
            message: 'invalid'
          }
        };
      };

      const errorMessages = R.pipe(R.propOr([], 'errors'), R.map(R.pipe(R.pick(['message', 'dataPath']), returnErrorMessages)), R.mergeAll)(validator);
      return {
        errors: errorMessages,
        _raw: R.prop('errors', validator)
      };
    }

  }, (_applyDecoratedDescriptor(_obj, "validateByType", [_object.action], Object.getOwnPropertyDescriptor(_obj, "validateByType"), _obj)), _obj));

  _exports.default = _default;
});
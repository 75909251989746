define("shop/pods/components/items/item-variants/variants-selectors/options-selectors/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "ramda", "shop/utils/nventor"], function (_exports, _component, _object, _tracking, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ItemsItemVariantsVariantsSelectorsOptionsSelectors = (_class = class ItemsItemVariantsVariantsSelectorsOptionsSelectors extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "disabled", _descriptor, this);

      _initializerDefineProperty(this, "selectedOptions", _descriptor2, this);

      _initializerDefineProperty(this, "isModalVisible", _descriptor3, this);
    }

    get soldOutList() {
      var _variant$_data, _variant$_data2;

      const variants = this.args.variantsProxy || [];
      const variant = this.args.variant || {};
      const currentSelectedVariantName = ((_variant$_data = variant._data) === null || _variant$_data === void 0 ? void 0 : _variant$_data.name) || '';
      const allOptions = ((_variant$_data2 = variant._data) === null || _variant$_data2 === void 0 ? void 0 : _variant$_data2.options) || [];
      const hasSelectedVariantChanged = this.args.hasSelectedVariantChanged;
      const isFinalSelection = this.args.isFinalSelection;
      const overSalesLimitedVariantChildren = this.args.overSalesLimitedVariantChildren || [];
      const overSalesLimitedQtyStatus = 'soldOut';
      return this.getListByStatus({
        variants,
        currentSelectedVariantName,
        allOptions,
        hasSelectedVariantChanged,
        isFinalSelection,
        overSalesLimitedVariantChildren,
        overSalesLimitedQtyStatus
      });
    }

    get preOrderList() {
      var _variant$_data3, _variant$_data4;

      const variants = this.args.variantsProxy || [];
      const variant = this.args.variant || {};
      const currentSelectedVariantName = ((_variant$_data3 = variant._data) === null || _variant$_data3 === void 0 ? void 0 : _variant$_data3.name) || '';
      const allOptions = ((_variant$_data4 = variant._data) === null || _variant$_data4 === void 0 ? void 0 : _variant$_data4.options) || [];
      const hasSelectedVariantChanged = this.args.hasSelectedVariantChanged;
      const isFinalSelection = this.args.isFinalSelection;
      const overSalesLimitedVariantChildren = this.args.overSalesLimitedVariantChildren || [];
      const overSalesLimitedQtyStatus = 'preOrder';
      return this.getListByStatus({
        variants,
        currentSelectedVariantName,
        allOptions,
        hasSelectedVariantChanged,
        isFinalSelection,
        overSalesLimitedVariantChildren,
        overSalesLimitedQtyStatus
      });
    }

    getListByStatus({
      variants,
      currentSelectedVariantName,
      allOptions,
      hasSelectedVariantChanged,
      isFinalSelection,
      overSalesLimitedVariantChildren,
      overSalesLimitedQtyStatus
    }) {
      if (!isFinalSelection) {
        return [];
      }

      if (R.isEmpty(overSalesLimitedVariantChildren)) {
        return [];
      }

      const overSalesLimitedVariantChildrenForStatus = R.filter(R.propEq('calculatedStatusToDisplayForAddToCart', overSalesLimitedQtyStatus))(overSalesLimitedVariantChildren);
      const allVariantChildIds = R.pluck('variantChildId')(overSalesLimitedVariantChildrenForStatus);
      const selectedOptions = R.pipe(R.filter(_variant => {
        if (currentSelectedVariantName === _variant._data.name) {
          return false;
        }

        return _variant.selectedValue;
      }), R.map(_variant => {
        const name = _variant._data.name;
        const value = _variant.selectedValue;
        return `${name}:${value}`;
      }))(variants);
      let hasSelectedOptions = true;

      if (R.isEmpty(selectedOptions) && variants.length > 1) {
        hasSelectedOptions = false;
      }

      const soldOutOptions = R.pipe(R.filter(soldOutVariantChildId => {
        if (!hasSelectedOptions) {
          return true;
        }

        return R.all(R.includes(R.__, soldOutVariantChildId))(selectedOptions);
      }), R.map(soldOutVariantChildId => {
        R.forEach(selectedOptionId => {
          soldOutVariantChildId = R.replace(selectedOptionId, '')(soldOutVariantChildId);
        })(selectedOptions);
        soldOutVariantChildId = R.pipe(R.dropWhile(R.equals(':')), R.dropLastWhile(R.equals(':')))(soldOutVariantChildId);
        return soldOutVariantChildId;
      }))(allVariantChildIds);
      return R.pipe(R.filter(option => {
        const optionVariantChildId = `${currentSelectedVariantName}:${option.name}`;
        return R.includes(optionVariantChildId, soldOutOptions);
      }), R.pluck('name'))(allOptions);
    }

    selectVariant(variant, e) {
      if (!this.disabled) {
        const selectedOption = R.pipe(R.pathOr([], ['target', 'selectedOptions']), R.head)(e);
        let selected = '';

        if (selectedOption) {
          selected = R.propOr('', 'value', selectedOption);
        }

        const variantName = R.pathOr('', ['_data', 'name'], variant);

        if (selected === variantName) {
          selected = '';
        }

        if (this.args.onSelect) {
          this.args.onSelect(variant, selected, e);
        }
      }
    }

    selectVariantForBtns(selectedOption = [], e) {
      if (!this.disabled) {
        var _variant$_data5;

        const variant = this.args.variant || {};
        const allOptions = variant === null || variant === void 0 ? void 0 : (_variant$_data5 = variant._data) === null || _variant$_data5 === void 0 ? void 0 : _variant$_data5.options;
        const selectedOptionObj = R.find(R.propEq('name', selectedOption))(allOptions);

        if (!selectedOptionObj) {
          return false;
        }

        if (variant.saveSelectedOpt === selectedOptionObj) {
          this.selectedOptions = [];
          (0, _object.set)(variant, 'saveSelectedOpt', {});
        } else {
          this.selectedOptions = [selectedOptionObj.index];
          (0, _object.set)(variant, 'saveSelectedOpt', selectedOptionObj);
        }

        if (this.args.onSelect) {
          this.args.onSelect(variant, selectedOption, e);
        }
      }
    }

    onClickCloseDialog() {
      this.isModalVisible = false;
    }

    setSelectedVariantKeyByUrlParam(element) {
      var _this$args, _variant$_data6, _this$args2;

      const variant = (_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.variant;
      const name = variant === null || variant === void 0 ? void 0 : (_variant$_data6 = variant._data) === null || _variant$_data6 === void 0 ? void 0 : _variant$_data6.name;
      const selectedVariantKeyByUrlParam = (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.selectedVariantKeyByUrlParam;

      if (selectedVariantKeyByUrlParam) {
        var _this$args3;

        const item = (this === null || this === void 0 ? void 0 : (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.item) || {};
        const selectedVariant = R.pipe(R.pathOr([], ['_data', 'variantChildren']), R.find(R.propEq('itemKey', selectedVariantKeyByUrlParam)))(item);

        if (_nventor.default.isNotNilOrEmpty(selectedVariant)) {
          const variantChildId = (selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.variantChildId) || '';
          const selectedVariantsIds = R.split(':', variantChildId);
          const nameIndex = R.findIndex(R.equals(R.__, name))(selectedVariantsIds);
          const valueIndex = nameIndex + 1;
          const selectedValue = selectedVariantsIds[valueIndex] || '';

          if (selectedValue) {
            var _this$args4;

            element.value = selectedValue;

            if ((_this$args4 = this.args) !== null && _this$args4 !== void 0 && _this$args4.onSelect) {
              this.args.onSelect(variant, selectedValue, element);
            }
          }
        }
      }
    }

    get activeList() {
      var _variant$_data7, _variant$_data8;

      const variants = this.args.variantsProxy || [];
      const variant = this.args.variant || {};
      const currentSelectedVariantName = ((_variant$_data7 = variant._data) === null || _variant$_data7 === void 0 ? void 0 : _variant$_data7.name) || '';
      const allOptions = ((_variant$_data8 = variant._data) === null || _variant$_data8 === void 0 ? void 0 : _variant$_data8.options) || []; // for triggering the get?

      const hasSelectedVariantChanged = this.args.hasSelectedVariantChanged;
      const activeVariantChildren = R.pathOr([], ['args', 'item', '_data', 'variantChildren'])(this);

      if (R.isEmpty(activeVariantChildren)) {
        return [];
      }

      const allVariantChildIds = R.pluck('variantChildId')(activeVariantChildren);
      const selectedOptions = R.pipe(R.filter(_variant => {
        if (currentSelectedVariantName === _variant._data.name) {
          return false;
        }

        return _variant.selectedValue;
      }), R.map(_variant => {
        const name = _variant._data.name;
        const value = _variant.selectedValue;
        return `${name}:${value}`;
      }))(variants);
      const soldOutOptions = R.pipe(R.filter(soldOutVariantChildId => {
        return R.all(R.includes(R.__, soldOutVariantChildId))(selectedOptions);
      }), R.map(soldOutVariantChildId => {
        R.forEach(selectedOptionId => {
          soldOutVariantChildId = R.replace(selectedOptionId, '')(soldOutVariantChildId);
        })(selectedOptions);
        soldOutVariantChildId = R.pipe(R.dropWhile(R.equals(':')), R.dropLastWhile(R.equals(':')))(soldOutVariantChildId);
        return soldOutVariantChildId;
      }))(allVariantChildIds);
      return R.pipe(R.filter(option => {
        const optionVariantChildId = `${currentSelectedVariantName}:${option.name}`;
        return R.any(R.includes(optionVariantChildId))(soldOutOptions);
      }), R.pluck('name'))(allOptions);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "disabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.disabled || false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isModalVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectVariant", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectVariant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectVariantForBtns", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectVariantForBtns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickCloseDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickCloseDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelectedVariantKeyByUrlParam", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedVariantKeyByUrlParam"), _class.prototype)), _class);
  _exports.default = ItemsItemVariantsVariantsSelectorsOptionsSelectors;
});
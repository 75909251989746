define("shop/helpers/uploadcareSrc", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import * as R from 'ramda'
  var _default = _helper.default.extend({
    /**
     * params are unnamed params passed in to the helper. order is important for params
     * hashes are named params
     * hash.defaultImage - defaultImage fileName
     * hash.images - array of fileNames
     * eg. {{helper-name param1 param2 hash1=123 hash2=345}}
     */
    compute(params, hash) {
      const baseUrl = 'https://ucarecdn.com';
      const path = params.join('');

      if (!path) {
        return '';
      }

      const src = baseUrl + '/' + path;

      if (hash !== null && hash !== void 0 && hash.allowGif && hash.mimeType === 'image/gif') {
        return `${src}/gif2video`;
      }

      return src;
    }

  });

  _exports.default = _default;
});
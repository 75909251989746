define("shop/pods/shop/reset-logout/route", ["exports", "@ember/routing/route", "shop/pods/shop/shop-route-mixin", "@ember/service", "ramda"], function (_exports, _route, _shopRouteMixin, _service, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_shopRouteMixin.default, {
    storage: (0, _service.inject)(),
    members: (0, _service.inject)(),

    redirect() {
      try {
        let shop = R.prop('shop', this.modelFor('application'));

        if (!shop) {
          shop = R.prop('shop', this.modelFor('shop'));
        }

        shop.cartSuccessClear();
        this.members.logout();
        this.transitionTo('shop');
      } catch (e) {
        window.localStorage.clear();
        this.transitionTo('shop', {
          queryParams: {
            reload: true
          }
        });
      }
    }

  });

  _exports.default = _default;
});
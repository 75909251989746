define("shop/pods/news/article/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LMxqZSpr",
    "block": "[[[8,[39,0],null,[[\"@breadcrumbModel\",\"@isNews\",\"@shop\",\"@website\"],[[30,0,[\"model\",\"article\"]],true,[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]]]],[[\"default\"],[[[[1,\"\\n\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,[[\"@article\",\"@params\",\"@shop\",\"@website\"],[[30,0,[\"model\",\"article\"]],[30,0,[\"model\",\"params\"]],[30,0,[\"model\",\"shop\"]],[30,0,[\"model\",\"website\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"website/website-container\",\"layouts/page-content\",\"news/news-article\"]]",
    "moduleName": "shop/pods/news/article/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/pods/shop/members/reset-password/route", ["exports", "@ember/routing/route", "shop/pods/shop/shop-route-mixin"], function (_exports, _route, _shopRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_shopRouteMixin.default, {
    model(params) {
      return this._model(params, {
        fetchMember: true
      });
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/elements/element-select/component", ["exports", "@ember/component", "@ember/object", "ramda"], function (_exports, _component, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',
    classNames: ['field'],
    translate: false,
    optionLabelKey: 'label',
    optionValueKey: 'value',
    hasEmpty: false,
    _options: (0, _object.computed)('options.[]', 'value', function () {
      const options = this.options || [];
      return R.map(option => {
        if (R.is(Object, option)) {
          return option;
        }

        return _object.default.create({
          value: option,
          label: option
        });
      })(options);
    }),
    hasIconsLeft: (0, _object.computed)('prefix', 'leftIcon', function () {
      return this.prefix || this.leftIcon;
    }),
    actions: {
      select(e) {
        const selectedOption = R.pipe(R.pathOr([], ['target', 'selectedOptions']), R.head)(e);
        let selected = '';

        if (selectedOption) {
          selected = R.propOr('', 'value', selectedOption);
        }

        this.set('value', selected);

        if (this.onSelect) {
          return this.onSelect(selected, e);
        }
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/layouts/back-btn/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object'
  var _default = _component.default.extend({
    tagName: '',
    // btnClassNames: computed('isTile', function () {
    //   if (this.get('isTile')) {
    //     return 'button button--tile'
    //   }
    //   return 'button'
    // }),
    actions: {
      back() {
        window.history.back();
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/shop/faqs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DZzd3Z1H",
    "block": "[[[41,[30,1,[\"shop\"]],[[[6,[39,1],null,[[\"model\",\"shop\"],[[30,1],[30,1,[\"shop\"]]]],[[\"default\"],[[[[1,\"\\n\"],[6,[39,2],null,[[\"isIndex\",\"model\",\"website\",\"page\",\"shop\",\"params\",\"onFilterTask\",\"query\"],[true,[30,1],[30,1,[\"website\"]],[30,1,[\"page\"]],[30,1,[\"shop\"]],[30,1,[\"params\"]],[30,2,[\"fetchTask\"]],[30,1,[\"params\",\"query\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,3],[[30,3,[\"hasTimer\"]],[28,[37,4],[[30,0,[\"model\",\"shop\",\"hasExpiredGracefully\"]]],null]],null],[[[1,\"      \"],[8,[39,5],null,[[\"@dateEndZ\"],[[30,0,[\"model\",\"shop\",\"_data\",\"dateEndZ\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n          \"],[8,[39,6],null,[[\"@model\"],[[30,1,[\"shop\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n\"]],[3]]]]],[1,\"\\n\"]],[2]]]]]],[]],null]],[\"@model\",\"pageComponent\",\"websiteContainer\"],false,[\"if\",\"shop/page-content\",\"website/website-container\",\"and\",\"not\",\"countdown-timer\",\"faqs\"]]",
    "moduleName": "shop/pods/shop/faqs/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("shop/helpers/count", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    /**
     * get length of values.
     * can pass in multiple arrays as params
     */
    compute(params
    /* , hash */
    ) {
      try {
        const array = params[0] || [];
        return array.length;
      } catch (e) {
        return 0;
      }
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/members/promo-code-content/component", ["exports", "ramda", "@glimmer/component", "@ember/service", "ember-concurrency-decorators", "@glimmer/tracking", "shop/utils/nventor"], function (_exports, R, _component, _service, _emberConcurrencyDecorators, _tracking, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MembersPromoCodesContentComponent = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('filters'), _dec3 = (0, _service.inject)('members'), (_class = class MembersPromoCodesContentComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _initializerDefineProperty(this, "filtersService", _descriptor3, this);

      _initializerDefineProperty(this, "membersService", _descriptor4, this);

      _initializerDefineProperty(this, "filterModel", _descriptor5, this);

      _initializerDefineProperty(this, "defaultData", _descriptor6, this);

      const params = this.args.params || {};
      params.count = 10; // only show promo codes for current shop

      const websiteData = R.pathOr({}, ['args', 'website'])(this);
      const shopData = R.pathOr({}, ['shop', '_data'])(websiteData);
      const tags = [];

      if (_nventor.default.isNotNilOrEmpty(websiteData)) {
        tags.push({
          name: shopData.companyName || '',
          label: shopData.companyName || '',
          isHeader: true,
          isNotAllowMultiple: true
        });
      }

      if (_nventor.default.isNotNilOrEmpty(shopData)) {
        tags.push({
          _key: shopData._key || '',
          name: shopData.name || '',
          podKey: shopData.podKey || '',
          url: shopData.url,
          label: shopData.name || ''
        });
      }

      const defaultData = {
        tags
      };
      this.defaultData = defaultData;
      this.filterModel = this.filtersService.setupFilters(defaultData, {
        params
      });
      this.fetchTask.perform();
    }

    *fetchTask(params = {}) {
      const website = this.args.website;
      const shop = this.args.shop;

      if (_nventor.default.isNilOrEmpty(params)) {
        const initialFilter = R.pipe(R.pathOr([], ['filterModel', '_data', 'tags']), R.reject(R.propEq('isHeader', true)), _nventor.default.safeHeadOr({}))(this);

        if (!R.isEmpty(initialFilter)) {
          var _this$filterModel, _this$filterModel$_da, _this$filterModel2, _this$filterModel2$_d;

          params = {
            page: (this === null || this === void 0 ? void 0 : (_this$filterModel = this.filterModel) === null || _this$filterModel === void 0 ? void 0 : (_this$filterModel$_da = _this$filterModel._data) === null || _this$filterModel$_da === void 0 ? void 0 : _this$filterModel$_da.page) || 1,
            count: (this === null || this === void 0 ? void 0 : (_this$filterModel2 = this.filterModel) === null || _this$filterModel2 === void 0 ? void 0 : (_this$filterModel2$_d = _this$filterModel2._data) === null || _this$filterModel2$_d === void 0 ? void 0 : _this$filterModel2$_d.count) || 10,
            prices: [],
            query: '',
            sortBy: '',
            tags: []
          };
        }
      }

      let response = {};
      response = yield this.membersService.get('getPromoCodesListTask').perform({
        website,
        shopUrl: shop.url,
        sourceKey: shop.shopKey || website.shopKey || '',
        params
      });
      this.filterModel.results = R.propOr([], 'promoCodes')(response);
      this.filterModel.hasMore = R.propOr(false, 'hasMore')(response);
      this.filterModel.totalCount = R.propOr(0, 'totalCount')(response);
      this.filterModel = this.filterModel;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filterModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "defaultData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTask"), _class.prototype)), _class));
  _exports.default = MembersPromoCodesContentComponent;
});
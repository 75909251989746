define("shop/pods/components/items/item-drawer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jIo5+ITH",
    "block": "[[[8,[39,0],null,[[\"@hasOverlay\",\"@closeOnOverlayClick\",\"@isReturnToOriginalUrl\"],[false,true,true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@isRunning\",\"@showWhenRunning\",\"@inPlace\"],[[30,0,[\"shopService\",\"getShopItemTask\",\"isRunning\"]],true,true]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"websiteService\",\"isShowDrawerForShopItemModel\"]],[30,0,[\"modifiedRoute\"]]],null],[[[1,\"      \"],[8,[39,4],null,[[\"@modifiedRoute\"],[[30,0,[\"modifiedRoute\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@shopItemRouteModel\",\"@showAsModal\"],[[30,0,[\"websiteService\",\"isShowDrawerForShopItemModel\"]],true]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"elements/drawer\",\"helpers/is-running\",\"if\",\"and\",\"elements/drawer/drawer-update-url\",\"shop/item\"]]",
    "moduleName": "shop/pods/components/items/item-drawer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
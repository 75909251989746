define("shop/pods/components/faqs/component", ["exports", "@glimmer/component", "@ember/object", "ramda"], function (_exports, _component, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FaqsComponent = (_class = class FaqsComponent extends _component.default {
    collapseToggle(e) {
      const header = e.currentTarget;
      const card = header.parentNode;
      const angleIcon = R.view(R.lensPath(['children', 1, 'children', 0, 'children', 0]))(header);
      const isUp = R.includes('up')(card.className);

      if (isUp) {
        angleIcon.className = `${angleIcon.className} fa-rotate-180`;
        card.className = R.replace('up', 'down')(card.className);
      } else {
        angleIcon.className = R.replace('fa-rotate-180', '')(angleIcon.className);
        card.className = R.replace('down', 'up')(card.className);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "collapseToggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "collapseToggle"), _class.prototype)), _class);
  _exports.default = FaqsComponent;
});
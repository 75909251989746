define("shop/pods/components/chat-btn/component", ["exports", "@ember/component", "shop/mixins/images", "@ember/object", "@ember/service", "ramda", "shop/utils/nventor"], function (_exports, _component, _images, _object, _service, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_images.default, {
    flowsService: (0, _service.inject)('flows'),
    router: (0, _service.inject)(),
    websiteService: (0, _service.inject)('website'),
    scrollService: (0, _service.inject)('scroll'),
    showChatOptions: false,

    init() {
      this._super(...arguments);

      this.setupFacebookPageData();
    },

    didInsertElement() {
      this._super(...arguments);

      this.scrollService.handleChatBtnPositioning({
        isModal: this.showAsModal
      });
      document.addEventListener('scroll', this.scrollService.handleChatBtnPositioning);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.isDestroyed) {
        return;
      }

      document.removeEventListener('scroll', this.scrollService.handleChatBtnPositioning);
    },

    setupFacebookPageData() {
      const facebookPluginEnable = this._getDefaultFacebookProp('facebookPluginEnable');

      const facebookPage = this._getDefaultFacebookProp('facebookPage');

      this.set('hasFacebookChatPlugin', facebookPluginEnable);
      this.set('facebookPage', facebookPage); // const routeName = this.get('router.currentRouteName')
      // const shopName = this.get('shop._data.name') || ''
      // const isBigOrdr = this.get('websiteService').get('isBigOrdr')

      const themeColor = this.get('website.themeData.themeActionColor'); // if (R.startsWith('bigordr', shopName) || isBigOrdr) {
      //   if (!R.startsWith('shop', routeName)) {
      //     themeColor = '#66aee4'
      //   }
      // }

      this.set('facebookThemeColor', themeColor);
    },

    _getDefaultFacebookProp(prop) {
      const websitePropVal = this.get(`website._data.${prop}`);
      const shopPropVal = this.get(`website._data.shop._data.${prop}`) || this.get(`website._data.shop.${prop}`);
      const routeName = this.get('router.currentRouteName');
      const shopName = this.get('shop._data.name') || '';
      const isBigOrdr = this.websiteService.get('isBigOrdr'); // console.log('websitePropVal', websitePropVal)
      // console.log('shopPropVal', shopPropVal)
      // console.log('routeName', routeName)
      // console.log('shopName', shopName)
      // console.log('isBigOrdr', isBigOrdr)

      if (R.startsWith('shop', routeName)) {
        if (R.startsWith('bigordr', shopName) && isBigOrdr) {
          // always return website
          return websitePropVal;
        }

        if (isBigOrdr) {
          // always return shop
          return shopPropVal;
        }
      } // either shop/website


      return this.get(`website.${prop}`);
    },

    isAtBigOrdrWebsite: (0, _object.computed)('router.currentRouteName', 'shop._data.name', function () {
      const isBigOrdr = this.websiteService.get('isBigOrdr');
      const routeName = this.get('router.currentRouteName');
      const shopName = this.get('shop._data.name') || '';

      if (R.startsWith('shop', routeName)) {
        if (R.startsWith('bigordr', shopName) && isBigOrdr) {
          return true;
        }
      }

      return false;
    }),
    onlyOnePlugin: (0, _object.computed)('hasFacebookChatPlugin', 'website.hasLineChatEnabled', 'website.flows.@each._key', 'website._data.websiteFlows', function () {
      const hasFacebookChatPlugin = this.hasFacebookChatPlugin;
      const hasLineChatEnabled = this.get('website.hasLineChatEnabled'); // @TODO: make sure when the flow is counted, check flowsService

      const websiteFlows = R.pathOr([], ['website', '_data', 'websiteFlows'])(this);
      const hasFlows = R.pipe(R.pathOr([], ['website', 'flows']), R.concat(websiteFlows), R.length, R.gt(R.__, 0))(this);
      const chatPlugins = [hasFacebookChatPlugin, hasLineChatEnabled, hasFlows];
      return R.pipe(R.reject(R.equals(false)), R.length, R.equals(1))(chatPlugins);
    }),
    onlyFacebook: (0, _object.computed)('hasFacebookChatPlugin', 'onlyOnePlugin', function () {
      if (this.onlyOnePlugin) {
        return this.hasFacebookChatPlugin;
      }

      return false;
    }),
    onlyLine: (0, _object.computed)('website.hasLineChatEnabled', 'onlyOnePlugin', function () {
      if (this.onlyOnePlugin) {
        return this.get('website.hasLineChatEnabled');
      }

      return false;
    }),
    showChatIcon: (0, _object.computed)('hasFacebookChatPlugin', 'facebookPage', 'website.hasLineChatEnabled', function () {
      var _this$website, _this$website2, _this$flowsService;

      if ((this === null || this === void 0 ? void 0 : this.hasFacebookChatPlugin) === true && _nventor.default.isNotNilOrEmpty(this === null || this === void 0 ? void 0 : this.facebookPage)) {
        return true;
      }

      if (((_this$website = this.website) === null || _this$website === void 0 ? void 0 : _this$website.hasLineChatEnabled) !== false && _nventor.default.isNotNilOrEmpty((_this$website2 = this.website) === null || _this$website2 === void 0 ? void 0 : _this$website2.hasLineChatEnabled)) {
        return true;
      }

      if (_nventor.default.isNotNilOrEmpty((_this$flowsService = this.flowsService) === null || _this$flowsService === void 0 ? void 0 : _this$flowsService.allAllowedFlowsForRoute)) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});
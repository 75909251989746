define("shop/pods/components/youtube/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eDckYuk4",
    "block": "[[[10,\"lite-youtube\"],[15,\"videoid\",[29,[[30,0,[\"videoId\"]]]]],[14,\"params\",\"rel=0\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,1],[14,0,\"lyt-visually-hidden\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@title\"],false,[\"if\"]]",
    "moduleName": "shop/pods/components/youtube/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
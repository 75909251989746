define("shop/pods/components/form-row/component", ["exports", "@ember/component", "@ember/object", "ramda"], function (_exports, _component, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // fastboot: service(),
    tagName: 'div',
    classNames: ['form-row'],
    translateSubLabel: true,
    translate: true,
    hasError: (0, _object.computed)('errors', 'field', function () {
      const errors = this.errors || {};

      if (!R.isEmpty(errors)) {
        // if (!this.get('fastboot.isFastBoot')) {
        const field = this.field;
        const found = errors[field];

        if (found) {
          if (found.message) {
            return found.message;
          }

          return found;
        } // check in errors.errors array for error (error from api)


        const context = this.field;
        let filtered = R.propOr([], 'errors', errors);

        if (context) {
          filtered = R.filter(error => {
            const errorPath = R.propOr([], 'path', error);
            const errorContext = R.join('.')(errorPath);

            if (errorContext === context) {
              return true;
            }

            return false;
          })(filtered);
        } // if (!this.get('showMultipleErrors') && filtered.length > 0) {


        if (filtered.length > 0) {
          const error = R.head(filtered);
          return error.message;
        } // } else if (filtered.length > 0) {
        //   return filtered
        // }

      }

      return '';
    })
  });

  _exports.default = _default;
});
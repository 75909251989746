define("shop/pods/components/layouts/footer-container/component", ["exports", "@ember/component", "@ember/service", "@ember/object", "ramda", "shop/utils/nventor"], function (_exports, _component, _service, _object, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    date: (0, _service.inject)(),
    googleService: (0, _service.inject)('google'),
    tagName: 'div',
    isIndex: false,
    hasCart: true,
    isCartModalVisible: false,
    isSearchBarVisible: false,
    isFiltersVisible: false,
    showNavMenu: false,
    showTagMenu: false,
    terms: '',
    // TODO: ADD TOOGLE TO ERP TO DISPLAY FOOTER
    useWebsiteFooter: (0, _object.computed)('websiteService.{isBigOrdr,isShopRoute,shop}', function () {
      if (this.get('websiteService.isBigOrdr')) {
        if (this.get('websiteService.isShopRoute')) {
          return false;
        }

        return true;
      } else {
        if (this.get('website.shop')) {
          return false;
        }
      }

      return true;
    }),
    hasShop: (0, _object.computed)('shop', 'website._data.shopKey', function () {
      if (this.shop) {
        return this.shop;
      }

      if (this.get('website._data.shopKey')) {
        return this.get('website._data.shopKey');
      }

      return false;
    }),
    socialItems: (0, _object.computed)('icons.[]', 'website', function () {
      const urls = [{
        url: this.get('website.facebookUrl'),
        icon: 'fab fa-facebook-square'
      }, {
        url: this.get('website.instagramUrl'),
        icon: 'fab fa-instagram'
      }, {
        url: this.get('website.youtubeUrl'),
        icon: 'fab fa-youtube'
      }, {
        url: this.get('website.lineId'),
        icon: 'fab fa-line'
      }];
      return R.reject(R.propEq('url', false))(urls);
    }),
    showSupportOnFooter: (0, _object.computed)('website._data.faqs.[]', function () {
      const support = [];
      const faqs = R.pathOr([], ['_data', 'faqs'])(this.website);
      const hasFaqs = R.pipe(R.isEmpty, R.not)(faqs);

      if (this.hasShop) {
        if (hasFaqs) {
          const dynamicFaqs = R.pipe(R.pathOr([], ['website', 'menu']), R.find(R.propEq('name', 'faqs')), R.mergeLeft({
            url: `shop/${this.website.shopUrl}/faqs`
          }))(this);

          if (dynamicFaqs.showOnFooter) {
            support.push(dynamicFaqs);
          }
        }

        const dynamicCheckOrderStatus = R.pipe(R.pathOr([], ['website', 'menu']), R.find(R.propEq('name', 'check order status')), R.mergeLeft({
          url: `shop/${this.website.shopUrl}/orders`
        }))(this);

        if (dynamicCheckOrderStatus.showOnFooter) {
          support.push(dynamicCheckOrderStatus);
        }

        return support;
      }

      return false;
    }),
    showOpenHours: (0, _object.computed)('website._data.openHours', function () {
      const getOnlyOpenHours = openHoursData => R.pipe(R.filter(obj => R.type(obj) === 'Object'), R.values)(openHoursData);

      const hasShopOpenHours = hours => R.pipe(R.all(R.propEq('open', 'none')), R.not)(hours);

      let openHours = this.contactInfo.openHours || R.pathOr({}, ['website', '_data', 'openHours'])(this);
      const hasOpenHours = R.pipe(getOnlyOpenHours, hasShopOpenHours)(openHours);

      if (hasOpenHours) {
        return R.reduce((acc, day) => {
          let time = R.propOr({}, day)(openHours);
          const hasBreakTime = R.propOr(false, 'hasBreakTime')(openHours);
          const openAfterBreak = time.openAfterBreak !== 'none';
          const closeAfterBreak = time.closeAfterBreak !== 'none';

          if (!openAfterBreak) {
            time.openAfterBreak = openAfterBreak;
          }

          if (!closeAfterBreak) {
            time.closeAfterBreak = closeAfterBreak;
          }

          if (!hasBreakTime) {
            time.openAfterBreak = false;
            time.closeAfterBreak = false;
          }

          const hasOpeningTime = R.pipe(R.pathEq(['open'], 'none'), R.not)(time);
          let isClosed = false;

          if (!hasOpeningTime) {
            isClosed = true;
          }

          acc.push({
            day,
            time,
            isClosed
          });
          return acc;
        }, [])(this.date.getWeekdays());
      }

      return [];
    }),
    currentWeekdaysSorted: (0, _object.computed)('showOpenHours.[]', function () {
      const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      const today = new Date();
      const currentDayIndex = today.getDay();
      const sortedWeekdays = [...weekdays.slice(currentDayIndex), ...weekdays.slice(0, currentDayIndex)];
      return sortedWeekdays;
    }),
    currentOpenHours: (0, _object.computed)('currentWeekday', 'showOpenHours.[]', function () {
      const currentWeekdaysSorted = this.currentWeekdaysSorted || [];
      const filterValues = R.take(1)(currentWeekdaysSorted);
      const openHours = this.showOpenHours || [];
      const current = R.map(day => {
        return R.find(R.propEq('day', day))(openHours);
      })(filterValues);
      return current;
    }),
    futureOpenHours: (0, _object.computed)('currentWeekday', 'showOpenHours.[]', function () {
      const currentWeekdaysSorted = this.currentWeekdaysSorted || [];
      const filterValues = R.drop(1)(currentWeekdaysSorted);
      const openHours = this.showOpenHours || [];
      const current = R.map(day => {
        return R.find(R.propEq('day', day))(openHours);
      })(filterValues);
      return current;
    }),
    contactInfo: (0, _object.computed)('website._data.useDefaultsContactInfo', 'shop._data.{useDefaultsContactInfo,tw_eInvoices}', function () {
      // bigOrdr customers:
      //  2 types of shops:
      // 1: linked to website - use shop data, then website, then settings
      // 2: not linked to website. will be displayed in bigordr.com/shop/{name}
      //      -- should never show bigordr website settings. should always be the merchants shopData settings or settings
      // use shop if any
      let shopData = R.pathOr({}, ['shop', '_data'])(this) || {};

      if (R.isEmpty(shopData) || !shopData) {
        shopData = R.pathOr({}, ['website', 'shop', '_data'])(this) || {};
      }

      const websiteData = R.pathOr({}, ['website', '_data'])(this);
      const useWebsiteData = R.pathOr(true, ['shopKey'])(websiteData);
      let taxNumber = R.propOr('', 'taxNumber')(shopData);
      let hideTaxNumber = R.propEq('hideTaxNumber', true)(shopData);

      if (_nventor.default.isNilOrEmpty(shopData)) {
        hideTaxNumber = R.propEq('hideTaxNumber', true)(websiteData);
        taxNumber = R.propOr('', 'taxNumber')(websiteData);
      } // not set in shopData,
      // get from website.shopData
      // websiteService.isBigOrdrShopRoute


      const getOpenHours = R.propOr({}, 'openHours');
      let openHours = getOpenHours(websiteData);

      if (this.websiteService.isBigOrdrShopRoute) {
        openHours = getOpenHours(shopData);
      }

      if (useWebsiteData || R.isEmpty(shopData)) {
        return {
          openHours,
          companyAddress: R.propOr('', 'companyAddress')(websiteData),
          companyName: R.propOr('', 'companyName')(websiteData),
          companyTel: R.propOr('', 'companyTel')(websiteData),
          companyEmail: R.propOr('', 'companyEmail')(websiteData),
          companyTaxNumber: taxNumber,
          hasEInvoice: !hideTaxNumber,
          addressHref: R.pipe(R.propOr('', 'companyAddress'), this.googleService.genAddressHref)(websiteData)
        };
      } // get from website not linked to any shop


      return {
        openHours,
        companyAddress: R.propOr('', 'companyAddress')(shopData),
        companyName: R.propOr('', 'companyName')(shopData),
        companyTel: R.propOr('', 'companyTel')(shopData),
        companyEmail: R.propOr('', 'companyEmail')(shopData),
        companyTaxNumber: taxNumber,
        hasEInvoice: !hideTaxNumber,
        addressHref: R.pipe(R.propOr('', 'companyAddress'), this.googleService.genAddressHref)(shopData)
      };
    }),
    onpenHoursToggleIcon: (0, _object.computed)('isExpanded', function () {
      const isExpanded = this.expandOpenHours;

      if (isExpanded) {
        return 'fas fa-angle-up fa-rotate-180';
      }

      return 'fas fa-angle-up';
    }),

    toggleOpenHours() {
      const value = this.expandOpenHours;
      this.set('expandOpenHours', !value);
    }

  }, (_applyDecoratedDescriptor(_obj, "toggleOpenHours", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleOpenHours"), _obj)), _obj));

  _exports.default = _default;
});
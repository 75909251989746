define("shop/pods/components/content-display/component", ["exports", "@ember/component", "shop/mixins/images", "@ember/service", "jquery", "ramda"], function (_exports, _component, _images, _service, _jquery, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_images.default, {
    ajax: (0, _service.inject)(),
    tagName: 'div',
    classNames: ['content-display no-margin'],

    didInsertElement() {
      this._super(...arguments);

      this.removeGifImgSrcFormTemplate();
      const isStories = this.get('isStories');

      if (isStories) {
        const _content = this.get('_content');

        const images = (0, _jquery.default)(_content).find('img');

        if (images.length > 0) {
          images.on('load', () => this.handleImageLoad());
        } else {
          this.handleImageLoad();
        }
      }
    },

    removeGifImgSrcFormTemplate() {
      const parser = new DOMParser();
      const content = this.get('content') || '';
      const virtualDocument = parser.parseFromString(`<div id='virtual-div'>${content}</div>`, 'text/html');
      const virtualDiv = virtualDocument.getElementById('virtual-div');
      const container = (0, _jquery.default)(this.element).closest('body');
      const defaultWidth = container.outerWidth();
      const images = (0, _jquery.default)(virtualDiv).find('img');
      const isStories = this.get('isStories');

      if (!isStories) {
        this.optimizeImages(defaultWidth, images);
      }

      this.set('_content', virtualDiv.innerHTML);
    },

    handleImageLoad() {
      if (this.get('onloadImg')) {
        this.get('onloadImg')();
      }
    },

    willDestroyElement() {
      const isStories = this.get('isStories');

      if (!isStories) {
        return;
      }

      const _content = this.get('_content');

      const images = (0, _jquery.default)(_content).find('img');

      if (!R.length(images)) {
        return;
      }

      images.off('load');
    }

  });

  _exports.default = _default;
});
define("shop/pods/components/helpers/tooltip-container/tooltip-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+aXK0mSI",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,3],[[33,4]],[[\"translate\"],[[33,5]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"tt\",\"label\",\"translate\"]]",
    "moduleName": "shop/pods/components/helpers/tooltip-container/tooltip-label/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
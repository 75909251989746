define("shop/pods/components/members/reset-password-page/component", ["exports", "@ember/component", "@ember/service", "ember-concurrency", "ramda"], function (_exports, _component, _service, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    success: '',
    header: '',
    isResettingPassword: false,

    init() {
      this._super(...arguments);

      this.setupTask.perform();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      this.set('hasSentEmail', false);
      const website = this.website;
      const params = this.websiteService.parseParamsFromWindowLocation(window.location);
      const paramsHasResetToken = R.prop('resetToken')(params);
      const user = website.get('user');

      if (paramsHasResetToken) {
        try {
          const resetTokenIsValid = yield this.membersService.get('verifyMemberToken').perform(website, params);

          if (resetTokenIsValid) {
            this.set('isResettingPassword', paramsHasResetToken);
          }
        } catch (e) {
          website.set('error', 'error occurred');
        }
      }

      if (user.get('_data.email')) {
        this.set('hasSentEmail', true);
      }

      this.set('user', user);
    }),
    resetPasswordTask: (0, _emberConcurrency.task)(function* () {
      try {
        const user = this.user;
        user.validate('setNewPassword', user._data);
        this.set('hasSentEmail', true);
        const website = this.website;
        const resetToken = this.isResettingPassword;
        const payload = {
          resetToken,
          password: user.get('_data.password'),
          confirmPassword: user.get('_data.confirmPassword')
        };
        yield this.membersService.get('setNewPassword').perform(website, payload); // this.handleMemberResponse(user, this.get('website'))

        if (this.websiteService.isShopRoute) {
          this.router.transitionTo('shop');
        } else {
          this.router.transitionTo('/');
        }
      } catch (e) {
        this.websiteService.setErrors(e, {
          errorMessage: 'passwords error'
        });
      }
    }),
    sendResetEmailTask: (0, _emberConcurrency.task)(function* () {
      const website = this.website;
      const websiteService = this.websiteService;

      try {
        this.set('hasSentEmail', true);
        const user = this.user;
        user.validate('email', user._data);
        const redirectUrl = window.location.href;
        const payload = R.mergeAll([{
          redirectUrl
        }, user._data]);
        yield this.membersService.get('sendResetPasswordEmailTask').perform(website, payload);

        if (website.get('alternateLoginData')) {
          if (website.get('shopKey')) {
            this.router.transitionTo('shop');
          } else {
            this.router.transitionTo('/');
          }
        } else {
          website.set('success', 'an email will be sent to you shortly if an account exists for this email address');
        }
      } catch (e) {
        const errors = R.pathOr([], ['data', 'errors'], e);

        if (!R.isEmpty(errors)) {
          websiteService.setErrors(e);
          this.set('hasSentEmail', false);
        } else {
          website.set('success', 'an email will be sent to you shortly if an account exists for this email address');
        }
      }
    }).drop(),
    actions: {
      back() {
        if (this.websiteService.isShopRoute) {
          this.router.transitionTo('shop');
        } else {
          this.router.transitionTo('/');
        }
      },

      tryAgain() {
        const websiteService = this.websiteService;
        this.set('hasSentEmail', '');
        websiteService.set('success', '');
        websiteService.clearErrors();
      }

    }
  });

  _exports.default = _default;
});
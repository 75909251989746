define("shop/pods/components/layouts/page-steps/step-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nQZkL7Mb",
    "block": "[[[11,0],[16,0,[29,[\"page-step \",[36,0]]]],[4,[38,1],[[30,0],\"goToStep\",[33,2]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"page-step__number\"],[12],[1,[34,3]],[13],[1,\" \\n  \"],[10,0],[12],[1,[28,[35,4],[[33,2]],[[\"translate\"],[[33,5]]]]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[51,[33,7]],[[[1,\"  \"],[10,0],[14,0,\"page-step page-step__arrow\"],[12],[1,\"\\n    \\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"stepClassName\",\"action\",\"step\",\"stepNumber\",\"tt\",\"translate\",\"unless\",\"isLastStep\"]]",
    "moduleName": "shop/pods/components/layouts/page-steps/step-display/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
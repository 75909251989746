define("shop/pods/shop/checkout/route", ["exports", "@ember/routing/route", "shop/pods/shop/shop-route-mixin", "ramda"], function (_exports, _route, _shopRouteMixin, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_shopRouteMixin.default, {
    model(params) {
      let shop = R.prop('shop', this.modelFor('application'));

      if (!shop) {
        shop = R.prop('shop', this.modelFor('shop'));
      }

      if (!shop.isAllowShop) {
        return this.transitionTo('shop');
      }

      return this._model(params);
    }

  });

  _exports.default = _default;
});
define("shop/mixins/checkout", ["exports", "ramda", "@ember/object/mixin", "@ember/object", "ember-concurrency", "shop/utils/nventor"], function (_exports, R, _mixin, _object, _emberConcurrency, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    setupPaymentMethod(checkoutData) {
      const shopPaymentMethods = this.get('shop._data.paymentMethods') || [];
      let defaultPaymentMethod = this.get('shop._data.paymentMethodsDefault');

      if (!defaultPaymentMethod || !R.includes(defaultPaymentMethod, shopPaymentMethods)) {
        defaultPaymentMethod = shopPaymentMethods.get('firstObject') || '';
      }

      checkoutData.set('paymentMethod', defaultPaymentMethod);
    },

    setupUser: (0, _emberConcurrency.task)(function* ({
      initiateCheckout = false
    } = {}) {
      this.set('requiredAttrs', ['name', 'email', 'telephone']); // get from storage

      const storedCheckoutData = this.storage.getValueWithShopKey('checkoutData') || {};

      const checkoutData = _object.default.create(storedCheckoutData);

      const shopPaymentMethods = this.get('shop._data.paymentMethods') || [];
      const user = this.user;

      if (user) {
        const alreadySelectedPaymentMethod = checkoutData.get('paymentMethod');

        if (!alreadySelectedPaymentMethod || shopPaymentMethods.indexOf(alreadySelectedPaymentMethod) === -1) {
          this.setupPaymentMethod(checkoutData);
        }

        if (!this.allowPickup) {
          checkoutData.set('isPickup', '');
        }

        if (!this.allowShipping) {
          checkoutData.set('shipping', '');
        }

        const pickupMethod = checkoutData.get('pickupMethod');

        if (pickupMethod) {
          checkoutData.set('pickupMethod', pickupMethod);
        }
      }

      if (checkoutData.get('checkoutAddressData')) {
        checkoutData.set('checkoutAddressData', _object.default.create(checkoutData.get('checkoutAddressData')));
      }

      this.setMembersAddress(user, checkoutData);
      this.set('checkoutData', checkoutData);
      return this.validateAddressTask.perform({
        initiateCheckout
      });
    }),

    setMembersAddress(user, checkoutData) {
      const useAlternateAddress = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.useAlternateAddress) || false;

      if (user.get('isMember') && user.get('_data.hasAddresses')) {
        const defaultAddress = user.get('defaultAddress');

        if (defaultAddress) {
          delete defaultAddress.surchargeId;
          let checkoutAddressData = checkoutData.get('checkoutAddressData') || {};

          if (!useAlternateAddress) {
            checkoutAddressData = R.mergeRight(checkoutAddressData, defaultAddress);
          }

          checkoutData.set('checkoutAddressData', checkoutAddressData);
        }
      }

      if (user.get('isGuest')) {
        const defaultAddress = user.get('defaultAddress');

        if (defaultAddress) {
          delete defaultAddress.surchargeId;
          let checkoutAddressData = checkoutData.get('checkoutAddressData') || {};

          if (!useAlternateAddress) {
            checkoutAddressData = R.mergeRight(checkoutAddressData, defaultAddress);
          }

          checkoutData.set('checkoutAddressData', _object.default.create(checkoutAddressData));
        }
      }

      if (!checkoutData.get('checkoutAddressData')) {
        checkoutData.set('checkoutAddressData', _object.default.create({
          country: '',
          countryCode: '',
          district: '',
          postCode: '',
          region: '',
          street: '',
          surchargeId: [],
          type: ''
        }));
      }

      if (!checkoutData.get('alternateAddressData')) {
        checkoutData.set('alternateAddressData', {
          name: '',
          telephone: ''
        });
      }
    },

    validateAddressTask: (0, _emberConcurrency.task)(function* ({
      initiateCheckout
    } = {}) {
      const checkoutData = this.checkoutData;
      const user = this.user;
      const shop = this.shop;
      const hasAddress = this.hasAddress;
      yield this.shopService.get('validateCartItemsTask').perform({
        shop,
        checkoutData,
        user,
        hasAddress,
        initiateCheckout,
        forceValidation: true
      });
    }),
    // to check is the user already input address or not (for every pickupMethod)
    hasUserAddressData: (0, _object.computed)('checkoutData.{pickupMethod,isConvenienceStore,checkoutAddressData.postCode,checkoutAddressData.country,checkoutAddressData.hasPostCode,checkoutAddressData.street,convenienceStoreData.storeId}', function () {
      // @TODO: this is the same as hasUserAddressDataForFreeShipping in cart content summary totals component
      const checkoutData = this.checkoutData || {};
      const pickupMethod = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.pickupMethod) || 'homeDelivery';
      const isConvenienceStore = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.isConvenienceStore) || false;

      if (pickupMethod === 'storeLocationPickup') {
        return true;
      }

      if (pickupMethod === 'homeDelivery') {
        var _checkoutData$checkou, _checkoutData$checkou2, _checkoutData$checkou3;

        const country = (checkoutData === null || checkoutData === void 0 ? void 0 : (_checkoutData$checkou = checkoutData.checkoutAddressData) === null || _checkoutData$checkou === void 0 ? void 0 : _checkoutData$checkou.country) || '';

        if (R.toUpper(country) === 'OTHER') {
          return true;
        }

        const postCode = (checkoutData === null || checkoutData === void 0 ? void 0 : (_checkoutData$checkou2 = checkoutData.checkoutAddressData) === null || _checkoutData$checkou2 === void 0 ? void 0 : _checkoutData$checkou2.postCode) || '';
        const address = (checkoutData === null || checkoutData === void 0 ? void 0 : (_checkoutData$checkou3 = checkoutData.checkoutAddressData) === null || _checkoutData$checkou3 === void 0 ? void 0 : _checkoutData$checkou3.street) || '';
        const hasPostCode = R.pathOr(true, ['checkoutAddressData', 'hasPostCode'])(checkoutData);

        if (!country) {
          return false;
        }

        if (!address) {
          return false;
        }

        if (hasPostCode && !postCode) {
          return false;
        }

        return true;
      }

      if (isConvenienceStore) {
        var _checkoutData$conveni;

        const storeId = (checkoutData === null || checkoutData === void 0 ? void 0 : (_checkoutData$conveni = checkoutData.convenienceStoreData) === null || _checkoutData$conveni === void 0 ? void 0 : _checkoutData$conveni.storeId) || '';

        if (storeId) {
          return true;
        }
      }

      return false;
    }),
    isSupportedCountryForMember: (0, _object.computed)('user.{isMember,_data.countryCode}', 'allowedCountries.[]', function () {
      if (this.get('user.isGuest')) {
        return true;
      }

      const isMember = this.get('user.isMember');
      const userCountryCode = this.get('user._data.countryCode');

      if (isMember && userCountryCode) {
        const allowedCountries = this.allowedCountries;
        const found = R.find(R.propEq('code', userCountryCode))(allowedCountries);

        if (found) {
          return true;
        }
      }

      return false;
    }),
    allowedCountries: (0, _object.computed)('website.{countries.[],shop._data.countries.[]}', function () {
      return this.get('website.shop._data.countries') || [];
    }),
    hasRegionError: (0, _object.computed)('shop.updated', 'shop.isVirtualOnly', 'shop.hasCartValidationError', 'shop.shippingFeeData.zoneNotFound', 'shop.isSupportedRegion', 'shop.isMinShippingRateReached', 'shop.checkoutData.{country,region,district,hasAdvancedAddress,useAlternateAddress}', 'checkoutData.useAlternateAddress', 'isSupportedCountryForMember', 'user._data.hasAddress', 'shopService.validateCartItemsTask.isRunning', function () {
      var _this$shop, _this$shop2, _this$shop2$shippingF;

      if ((_this$shop = this.shop) !== null && _this$shop !== void 0 && _this$shop.isVirtualOnly) {
        return false;
      }

      if ((_this$shop2 = this.shop) !== null && _this$shop2 !== void 0 && (_this$shop2$shippingF = _this$shop2.shippingFeeData) !== null && _this$shop2$shippingF !== void 0 && _this$shop2$shippingF.zoneNotFound) {
        var _this$shop3, _this$shop3$checkoutD;

        const checkoutAddressData = (_this$shop3 = this.shop) === null || _this$shop3 === void 0 ? void 0 : (_this$shop3$checkoutD = _this$shop3.checkoutData) === null || _this$shop3$checkoutD === void 0 ? void 0 : _this$shop3$checkoutD.checkoutAddressData;

        if (checkoutAddressData !== null && checkoutAddressData !== void 0 && checkoutAddressData.hasAdvancedAddress) {
          if (checkoutAddressData !== null && checkoutAddressData !== void 0 && checkoutAddressData.hasPostCode && !checkoutAddressData.postCode) {
            return false;
          }

          if (!(checkoutAddressData !== null && checkoutAddressData !== void 0 && checkoutAddressData.district)) {
            return false;
          }
        }

        const isConvenienceStore = R.pathEq(['shop', 'checkoutData', 'isConvenienceStore'], true)(this);

        if (!isConvenienceStore) {
          return true;
        }

        const hasConvenienceStoreAddress = R.pipe(R.pathOr('', ['shop', 'checkoutData', 'convenienceStoreAddress']), _nventor.default.isNotNilOrEmpty)(this);
        const validateCartItemsTaskIsRunning = this.shopService.validateCartItemsTask.isRunning;

        if (!validateCartItemsTaskIsRunning && isConvenienceStore && hasConvenienceStoreAddress) {
          return true;
        }
      }

      if (!this.user.get('_data.hasAddress')) {
        return false;
      }

      if (!this.isSupportedCountryForMember) {
        if (this.get('checkoutData.useAlternateAddress')) {
          return false;
        }

        return true;
      }

      const isSupportedRegion = this.get('shop.isSupportedRegion');
      const isMinShippingRateReached = this.get('shop.isMinShippingRateReached');

      if (isSupportedRegion && isMinShippingRateReached) {
        return false;
      } // hide region error after validating using convenience store


      const checkoutData = this.checkoutData || {};

      if (_nventor.default.isNotNilOrEmpty(checkoutData)) {
        var _checkoutData$conveni2;

        const isConvenienceStore = checkoutData.isConvenienceStore || false;
        const storeId = (checkoutData === null || checkoutData === void 0 ? void 0 : (_checkoutData$conveni2 = checkoutData.convenienceStoreData) === null || _checkoutData$conveni2 === void 0 ? void 0 : _checkoutData$conveni2.storeId) || '';

        if (isConvenienceStore && storeId) {
          return false;
        }
      }

      return true; // if (this.get('user.isGuest')) {
      //   return false
      // }
      // // if (this.get('checkoutData.useAlternateAddress') && this.get('showAlternateAddressSelector')) {
      // //   return false
      // // }
      // return true
    }),
    hasPickupAddressError: (0, _object.computed)('isPickup', 'checkoutData.pickupMethod', function () {
      var _this$checkoutData, _this$shop4, _this$shop4$_data;

      let pickupMethod = (_this$checkoutData = this.checkoutData) === null || _this$checkoutData === void 0 ? void 0 : _this$checkoutData.pickupMethod;
      const pickupMethods = ((_this$shop4 = this.shop) === null || _this$shop4 === void 0 ? void 0 : (_this$shop4$_data = _this$shop4._data) === null || _this$shop4$_data === void 0 ? void 0 : _this$shop4$_data.pickupMethods) || [];

      if (!R.any(R.propEq('value', pickupMethod))(pickupMethods)) {
        var _pickupMethods$;

        pickupMethod = pickupMethods === null || pickupMethods === void 0 ? void 0 : (_pickupMethods$ = pickupMethods[0]) === null || _pickupMethods$ === void 0 ? void 0 : _pickupMethods$.value;
      }

      if (pickupMethod !== 'storeLocationPickup') {
        return false;
      }

      if (this.isPickup) {
        return false;
      }

      return true;
    }),
    allowShipping: (0, _object.computed)('shop._data.allowShipping', function () {
      return this.get('shop._data.allowShipping');
    }),
    hasAddress: (0, _object.computed)('isPickup', 'allowShipping', function () {
      if (this.isPickup) {
        return false;
      }

      if (this.allowShipping) {
        return true;
      }

      return false;
    }),
    isPickup: (0, _object.computed)('shop._data.allowPickup', 'checkoutData.isPickup', 'pickupMethod', 'checkoutData.pickupMethod', function () {
      var _this$shop5, _this$shop5$_data;

      const allowPickup = ((_this$shop5 = this.shop) === null || _this$shop5 === void 0 ? void 0 : (_this$shop5$_data = _this$shop5._data) === null || _this$shop5$_data === void 0 ? void 0 : _this$shop5$_data.allowPickup) || false;
      const pickupMethod = this.pickupMethod || this.get('checkoutData.pickupMethod');
      const isPickup = this.get('checkoutData.isPickup');

      if (!allowPickup) {
        return false;
      }

      if (pickupMethod !== 'storeLocationPickup') {
        return false;
      }

      return isPickup;
    }),
    allowNext: (0, _object.computed)('isPickup', 'checkoutData.isPickup', 'shop.isSupportedRegion', 'hasRegionError', 'hasPickupAddressError', 'shop.updated', 'checkoutData.isConvenienceStore', 'checkoutData.convenienceStoreData', 'hasMetMinRequiredAmountForAllInstallments', 'hasInstallments', function () {
      var _this$checkoutData2, _this$shop6;

      if (this.hasInstallments && !this.hasMetMinRequiredAmountForAllInstallments) {
        return false;
      }

      if (this.isPickup || (_this$checkoutData2 = this.checkoutData) !== null && _this$checkoutData2 !== void 0 && _this$checkoutData2.isPickup) {
        return true;
      }

      if ((_this$shop6 = this.shop) !== null && _this$shop6 !== void 0 && _this$shop6.isVirtualOnly) {
        return true;
      }

      if (this.allowShipping) {
        var _this$checkoutData3;

        if ((_this$checkoutData3 = this.checkoutData) !== null && _this$checkoutData3 !== void 0 && _this$checkoutData3.isConvenienceStore) {
          const convenienceStoreData = this.checkoutData.convenienceStoreData || {};

          if (_nventor.default.isNilOrEmpty(convenienceStoreData)) {
            return false;
          }
        }

        const hasRegionError = this.hasRegionError;

        if (hasRegionError) {
          return false;
        }
      }

      const hasPickupAddressError = this.hasPickupAddressError;

      if (hasPickupAddressError) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});
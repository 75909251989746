define("shop/pods/components/members/order-history-group/order-history-row/component", ["exports", "@glimmer/component", "@ember/service", "@glimmer/tracking", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _service, _tracking, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MembersOrderHistoryGroupOrderHistoryRowComponent = (_dec = (0, _service.inject)('website'), _dec2 = (0, _service.inject)('filters'), _dec3 = (0, _service.inject)('members'), (_class = class MembersOrderHistoryGroupOrderHistoryRowComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _initializerDefineProperty(this, "filtersService", _descriptor3, this);

      _initializerDefineProperty(this, "membersService", _descriptor4, this);

      _initializerDefineProperty(this, "filterModel", _descriptor5, this);
    }

    get paymentMethod() {
      var _this$args, _this$args$order;

      const paymentMethodKey = (this === null || this === void 0 ? void 0 : (_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$order = _this$args.order) === null || _this$args$order === void 0 ? void 0 : _this$args$order.paymentMethodKey) || '';
      return `paymentMethod_${paymentMethodKey}`;
    }

    get isCancelled() {
      var _this$args2, _this$args2$order;

      const dispatchStatus = (this === null || this === void 0 ? void 0 : (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$order = _this$args2.order) === null || _this$args2$order === void 0 ? void 0 : _this$args2$order.dispatchStatus) || '';

      if (dispatchStatus === 'cancelled') {
        return true;
      }

      return false;
    }

    get tagClassNames() {
      var _this$args3, _this$args3$order, _this$args4, _this$args4$order, _this$args5, _this$args5$order;

      const dispatchStatus = (this === null || this === void 0 ? void 0 : (_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : (_this$args3$order = _this$args3.order) === null || _this$args3$order === void 0 ? void 0 : _this$args3$order.dispatchStatus) || '';
      const awaitingPayment = (this === null || this === void 0 ? void 0 : (_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : (_this$args4$order = _this$args4.order) === null || _this$args4$order === void 0 ? void 0 : _this$args4$order.awaitingPayment) || '';
      const awaitingCreditCardPayment = (this === null || this === void 0 ? void 0 : (_this$args5 = this.args) === null || _this$args5 === void 0 ? void 0 : (_this$args5$order = _this$args5.order) === null || _this$args5$order === void 0 ? void 0 : _this$args5$order.awaitingCreditCardPayment) || '';

      if (dispatchStatus === 'cancelled') {
        return 'is-danger';
      }

      if (dispatchStatus === 'dispatched') {
        return 'is-success';
      }

      if (awaitingPayment || awaitingCreditCardPayment) {
        return 'is-warning';
      }

      return '';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "membersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filterModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MembersOrderHistoryGroupOrderHistoryRowComponent;
});
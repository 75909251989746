define("shop/pods/components/payments/card/creditcard-payment-form/component", ["exports", "@ember/component", "@ember/object", "ramda"], function (_exports, _component, _object, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global creditCardType */
  var _default = _component.default.extend({
    input() {
      this._super(...arguments);

      this.formatCreditCard();
    },

    paymentMethodsForProvider: (0, _object.computed)('model._data.paymentMethods', 'shop._data.creditCardProvider', function () {
      const paymentMethods = R.path(['model', '_data', 'paymentMethods'])(this) || [];

      if (this.shop.isGmo) {
        return R.filter(R.propEq('provider', 'gmo'))(paymentMethods);
      }

      return R.filter(R.propEq('provider', 'tapPay'))(paymentMethods);
    }),
    ccCardIcon: (0, _object.computed)('creditCard.ccBrand', function () {
      const ccBrand = this.get('creditCard.ccBrand');

      if (ccBrand) {
        return ccBrand;
      }

      return 'far fa-credit-card';
    }),

    formatCreditCard() {
      const {
        ccNumber,
        ccExpiry,
        ccSecureCode
      } = this.creditCard;

      if (ccNumber) {
        this.set('creditCard.ccNumber', this._splitCcNumber(ccNumber));
        this.set('creditCard.ccBrand', this._setCcBrand(ccNumber));
      }

      if (ccExpiry) {
        this.set('creditCard.ccExpiry', this._splitCcExp(ccExpiry));
      }

      if (ccSecureCode) {
        this.set('creditCard.ccSecureCode', this._ccSecureCode(ccSecureCode));
      }
    },

    _setCcBrand(ccn) {
      const getCcBrand = R.pipe(R.head, R.propOr(null, 'type'));
      const tmp = creditCardType(ccn);
      const ccBrand = getCcBrand(tmp);

      if (ccBrand === 'visa' || ccBrand === 'mastercard' || ccBrand === 'jcb') {
        return `fab fa-cc-${ccBrand}`;
      } else {
        return 'far fa-credit-card';
      }
    },

    _splitCcNumber(ccn) {
      const overrideCcNumIfExist = R.pipe(R.when(R.propSatisfies(R.lt(R.__, 16), 'length'), () => ''), R.when(R.propSatisfies(R.gt(R.__, 16), 'length'), R.ifElse(R.test(/[1-9]$/), R.last, () => '')));
      return R.unless(R.isNil, R.ifElse(R.includes('⦁'), overrideCcNumIfExist, R.pipe(R.replace(/[^0-9]+/g, ''), R.splitEvery(4), R.join(' '))))(ccn);
    },

    _splitCcExp(ccexp) {
      return R.unless(R.isNil, R.pipe(R.replace(/[^0-9]+/g, ''), R.splitEvery(2), R.join('/')))(ccexp);
    },

    _ccSecureCode(ccsc) {
      return R.unless(R.isNil, R.replace(/[^0-9]+/g, ''))(ccsc);
    },

    actions: {
      validateInput(prop) {
        this._super(...arguments);

        const model = this.model;
        this.websiteService.clearErrors();

        try {
          const toValidate = {};
          toValidate[prop] = this.get(`creditCard.${prop}`);
          model.validate(`creditCard.${prop}`, toValidate);
        } catch (e) {
          this.websiteService.setErrors(e, {
            errorMessage: 'invalid login credentials',
            autoFocus: false
          });
        }
      }

    }
  });

  _exports.default = _default;
});
define("shop/pods/components/tap-pay/line-pay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rh536KBW",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,0],[14,0,\"has-text-small\"],[12],[1,\"\\n    \"],[1,[30,1,[\"_data\",\"companyName\"]]],[1,\"串接\"],[10,\"strong\"],[12],[1,\"LINE Pay\"],[13],[1,\"，將跳轉至\"],[10,\"strong\"],[12],[1,\"LINE Pay\"],[13],[1,\"付款頁面繼續進行您的結帳流程。\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@shop\"],false,[]]",
    "moduleName": "shop/pods/components/tap-pay/line-pay/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
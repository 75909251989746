define("shop/pods/components/chat-btn/fb-chat-btn/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "jquery", "ramda"], function (_exports, _component, _object, _service, _jquery, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    tagName: '',
    isChatVisible: false,
    showBtn: false,
    // didInsertElement () {
    //   this._super(...arguments)
    //   setTimeout(() => {
    //     this.set('showBtn', true)
    //   }, 4000)
    // },
    facebookMessengerAppLink: (0, _object.computed)('facebookPage', function () {
      const facebookPage = this.facebookPage;
      return `https://m.me/${facebookPage}`;
    }),
    btnClassName: (0, _object.computed)('websiteService.isBigOrdr', 'router.currentRouteName', 'shop._data.name', 'isActive', function () {
      const routeName = this.get('router.currentRouteName');
      const shopName = this.get('shop._data.name') || '';
      const isBigOrdr = this.websiteService.get('isBigOrdr');
      let _className = 'chat-options__btn';

      if (this.isMobile) {
        _className = _className + ' is-mobile';
      }

      if (R.startsWith('bigordr', shopName) || isBigOrdr) {
        if (!R.startsWith('shop', routeName)) {
          return _className + ' isBigOrdr';
        }
      }

      return _className;
    }),

    openFacebookDialog(e) {
      // if (self.allowClick) {
      //   self.set('allowClick', false)
      //   const btn = jQuery(e.target).closest('.chat-options__btn')
      //   const container = jQuery(e.target).closest('#fb-root').find('.fb_dialog_content')
      //   container.css('display', 'none')
      //   if (btn.css('transform') === 'none') {
      //     btn.css('transform', 'rotate(90deg)')
      //   } else {
      //     btn.css('transform', '')
      //   }
      //   if (self.get('websiteService.isMobile')) {
      const facebookMessengerAppLink = this.facebookMessengerAppLink;
      window.open(facebookMessengerAppLink, '_blank'); //   } else {
      //     const isChatVisible = self.isChatVisible
      //     if (isChatVisible) {
      //       FB.CustomerChat.showDialog()
      //       self.set('isChatVisible', false)
      //     } else {
      //       FB.CustomerChat.hideDialog()
      //       self.set('isChatVisible', true)
      //     }
      //   }
      //   container.css('display', 'none')
      //   setTimeout(() => {
      //     self.set('allowClick', true)
      //     btn.css('transform', '')
      //   }, 3000)
      // }
    }

  }, (_applyDecoratedDescriptor(_obj, "openFacebookDialog", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openFacebookDialog"), _obj)), _obj));

  _exports.default = _default;
});
define("shop/pods/components/helpers/multiline-truncate/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HelpersMultilineTruncate extends _component.default {
    get shortenedContent() {
      const content = this.args.content || '';
      const char = this.args.char || 100;
      const byLines = this.args.byLines;

      if (content.length > char && !byLines) {
        return R.take(char, content) + '...';
      }

      return content;
    }

  }

  _exports.default = HelpersMultilineTruncate;
});
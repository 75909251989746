define("shop/pods/ajax/service", ["exports", "ember-ajax/services/ajax", "@ember/service", "shop/config/environment", "@ember/object"], function (_exports, _ajax, _service, _environment, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    websiteService: (0, _service.inject)('website'),
    host: _environment.default.APP.serverUrl,
    contentType: 'application/json; charset=utf-8',
    headers: (0, _object.computed)('location.{href,pathname}', function () {
      var _this$websiteService, _this$websiteService$, _this$websiteService$2, _this$websiteService2, _this$websiteService3, _this$websiteService4, _this$websiteService5;

      const customHeaders = {
        'x-request-href': location.href,
        'x-request-path-name': location.pathname
      };
      const websiteKey = (this === null || this === void 0 ? void 0 : (_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : (_this$websiteService$ = _this$websiteService.website) === null || _this$websiteService$ === void 0 ? void 0 : (_this$websiteService$2 = _this$websiteService$._data) === null || _this$websiteService$2 === void 0 ? void 0 : _this$websiteService$2._key) || '';
      const shopKey = (this === null || this === void 0 ? void 0 : (_this$websiteService2 = this.websiteService) === null || _this$websiteService2 === void 0 ? void 0 : (_this$websiteService3 = _this$websiteService2.website) === null || _this$websiteService3 === void 0 ? void 0 : (_this$websiteService4 = _this$websiteService3.shop) === null || _this$websiteService4 === void 0 ? void 0 : (_this$websiteService5 = _this$websiteService4._data) === null || _this$websiteService5 === void 0 ? void 0 : _this$websiteService5._key) || '';

      if (websiteKey) {
        var _this$websiteService6, _this$websiteService7, _this$websiteService8;

        customHeaders['x-website'] = websiteKey;
        const websitePodKey = (this === null || this === void 0 ? void 0 : (_this$websiteService6 = this.websiteService) === null || _this$websiteService6 === void 0 ? void 0 : (_this$websiteService7 = _this$websiteService6.website) === null || _this$websiteService7 === void 0 ? void 0 : (_this$websiteService8 = _this$websiteService7._data) === null || _this$websiteService8 === void 0 ? void 0 : _this$websiteService8.podKey) || '';

        if (websitePodKey) {
          customHeaders['x-website-podkey'] = websitePodKey;
        }
      }

      if (shopKey) {
        var _this$websiteService9, _this$websiteService10, _this$websiteService11, _this$websiteService12;

        customHeaders['x-shop'] = shopKey;
        const shopPodKey = (this === null || this === void 0 ? void 0 : (_this$websiteService9 = this.websiteService) === null || _this$websiteService9 === void 0 ? void 0 : (_this$websiteService10 = _this$websiteService9.website) === null || _this$websiteService10 === void 0 ? void 0 : (_this$websiteService11 = _this$websiteService10.shop) === null || _this$websiteService11 === void 0 ? void 0 : (_this$websiteService12 = _this$websiteService11._data) === null || _this$websiteService12 === void 0 ? void 0 : _this$websiteService12.podKey) || '';

        if (shopPodKey) {
          customHeaders['x-shop-podkey'] = shopPodKey;
        }
      }

      return customHeaders;
    }).volatile()
  });

  _exports.default = _default;
});
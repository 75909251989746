define("shop/pods/components/shop/orders-query/orders-status/order-display/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "ramda", "shop/utils/nventor"], function (_exports, _component, _object, _service, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    date: (0, _service.inject)(),
    tagName: 'div',
    copyLabel: 'copy and track waybill',
    isPaidComputed: (0, _object.computed)('isPaidComputed', function () {
      return R.pathEq(['orderData', 'virtualAccount', 'status'], 'paid')(this);
    }),
    hasDispatchStatus: (0, _object.computed)('isCancelled', 'orderData.{virtualAccount.status,awaitingCreditCardPayment,awaitingPayment}', function () {
      var _orderData$virtualAcc;

      const orderData = this.orderData;

      if (this.isCancelled) {
        return true;
      }

      if (orderData !== null && orderData !== void 0 && (_orderData$virtualAcc = orderData.virtualAccount) !== null && _orderData$virtualAcc !== void 0 && _orderData$virtualAcc.status) {
        return true;
      }

      if (orderData !== null && orderData !== void 0 && orderData.awaitingCreditCardPayment) {
        return true;
      }

      if (orderData !== null && orderData !== void 0 && orderData.awaitingPayment) {
        return true;
      }

      return false;
    }),
    hasBankCode: (0, _object.computed)('orderData.paymentData.bankCode', function () {
      var _this$orderData, _this$orderData$payme;

      if (this !== null && this !== void 0 && (_this$orderData = this.orderData) !== null && _this$orderData !== void 0 && (_this$orderData$payme = _this$orderData.paymentData) !== null && _this$orderData$payme !== void 0 && _this$orderData$payme.bankCode) {
        return true;
      }

      return false;
    }),
    virtualAccountNumberComputed: (0, _object.computed)('orderData.virtualAccount.virtualAccountNumber', function () {
      const virtualAccount = R.pathOr('', ['orderData', 'virtualAccount', 'virtualAccountNumber'])(this);
      const isPaid = R.pathEq(['orderData', 'virtualAccount', 'status'], 'paid')(this);

      if (isPaid) {
        return _nventor.default.maskString(virtualAccount, {
          start: 3,
          end: 3,
          placeholder: '•',
          oneToOne: true
        });
      }

      return virtualAccount;
    }),
    isTransfer: (0, _object.computed)('orderData.paymentMethodKey', function () {
      const paymentMethodKey = this.get('orderData.paymentMethodKey');

      if (paymentMethodKey === 'cashBeforeDelivery') {
        return true;
      }

      return false;
    }),
    isDispatched: (0, _object.computed)('orderData.dispatchStatus', function () {
      const dispatchStatus = this.get('orderData.dispatchStatus');

      if (dispatchStatus === 'dispatched') {
        return true;
      }

      return false;
    }),
    isHCT: (0, _object.computed)('orderData.transporterExtension', function () {
      const transporterExtension = this.get('orderData.transporterExtension');

      if (transporterExtension === 'hct') {
        return true;
      }

      return false;
    }),
    isSevenEleven: (0, _object.computed)('orderData.{transporterExtension,convenienceStoreData}', function () {
      const orderData = (this === null || this === void 0 ? void 0 : this.orderData) || {};
      const transporterExtension = orderData === null || orderData === void 0 ? void 0 : orderData.transporterExtension;

      if (transporterExtension === 'seven-eleven') {
        return true;
      }

      const convenienceStoreData = (orderData === null || orderData === void 0 ? void 0 : orderData.convenienceStoreData) || {};
      const convenienceStoreDataBrand = convenienceStoreData === null || convenienceStoreData === void 0 ? void 0 : convenienceStoreData.brand;

      if (convenienceStoreDataBrand === 'sevenEleven') {
        return true;
      }

      return false;
    }),
    isCancelled: (0, _object.computed)('orderData.dispatchStatus', function () {
      const dispatchStatus = this.get('orderData.dispatchStatus');

      if (dispatchStatus === 'cancelled') {
        return true;
      }

      return false;
    }),
    tagClassNames: (0, _object.computed)('orderData.dispatchStatus', function () {
      const dispatchStatus = this.get('orderData.dispatchStatus');
      const awaitingPayment = this.get('orderData.awaitingPayment');
      const awaitingCreditCardPayment = this.get('orderData.awaitingCreditCardPayment');

      if (dispatchStatus === 'cancelled') {
        return 'is-danger';
      }

      if (dispatchStatus === 'dispatched') {
        return 'is-success';
      }

      if (awaitingPayment || awaitingCreditCardPayment) {
        return 'is-warning';
      }

      return '';
    }),
    orderDataDetailsSorted: (0, _object.computed)('orderData.details.[]', function () {
      const details = this.get('orderData.details') || [];
      return R.sortWith([R.ascend(R.path(['index'])), R.ascend(R.path(['childIndex'])), R.ascend(R.path(['grandChildIndex']))])(details);
    }),
    hasRecordedPointForNonMember: (0, _object.computed)('orderData.isMember', 'shop._data.rewardData.isAllowTrackRewardForNonMember', function () {
      const isMember = this.get('orderData.isMember') || false;
      const isAllowTrackRewardForNonMember = this.get('shop._data.rewardData.isAllowTrackRewardForNonMember') || false;

      if (!isMember && isAllowTrackRewardForNonMember) {
        return true;
      }

      return false;
    }),
    isShowPaymentLink: (0, _object.computed)('orderData.{recurringGroupId,paymentMethodKey,paymentDueDateZ,paymentCreditCardStatus}', function () {
      const orderData = this.orderData || {};
      const paymentMethodKey = orderData.paymentMethodKey || '';
      const recurringGroupId = orderData.recurringGroupId || '';
      let isNotYetPaid = false;

      if (paymentMethodKey === 'creditCard' && recurringGroupId) {
        const paymentCreditCardStatus = orderData.paymentCreditCardStatus || '';
        isNotYetPaid = paymentCreditCardStatus !== 'approved';
      }

      return isNotYetPaid;
    }),
    pickupMethod: (0, _object.computed)('orderData.isConvenienceStore', 'orderData.isPickup', 'orderData.transporterExtension', function () {
      const isConvenienceStore = this.get('orderData.isConvenienceStore');
      const isPickup = this.get('orderData.isPickup');
      const transporterExtension = this.get('orderData.transporterExtension');

      if (isConvenienceStore) {
        return 'convenience store pick up';
      }

      if (isPickup) {
        return 'store location pick up';
      }

      if (transporterExtension) {
        return 'home delivery';
      }

      return '';
    }),
    selectedExpectedDateZ: (0, _object.computed)('orderData.expectedDateMethod', 'orderData.expectedDispatchDateZ', 'orderData.transporterArrivalDateZ', 'orderData.isPickup', function () {
      const expectedDateMethod = this.get('orderData.expectedDateMethod');
      const expectedDispatchDateZ = this.get('orderData.expectedDispatchDateZ');
      const transporterArrivalDateZ = this.get('orderData.transporterArrivalDateZ');
      const isPickup = this.get('orderData.isPickup');

      if (isPickup) {
        return transporterArrivalDateZ;
      }

      if (expectedDateMethod === 'expectedArrivalDate') {
        return transporterArrivalDateZ;
      }

      if (expectedDateMethod === 'expectedDispatchDate') {
        return expectedDispatchDateZ;
      }
    }),

    onClickRedirectToPaymentPage() {
      const orderData = this.orderData || {};
      const externalUrl = orderData.externalUrl || '';
      const paymentMethodKey = orderData.paymentMethodKey || '';
      const recurringGroupId = orderData.recurringGroupId || '';
      const isRecurringOrderWithCreditCard = paymentMethodKey === 'creditCard' && recurringGroupId;
      const nowZ = this.date.getNowZ();
      const paymentDueDateZ = orderData.paymentDueDateZ || '';
      const afterPaymentDueDateZ = this.date.getMoment(paymentDueDateZ).add(7, 'hours').toISOString();
      const formattedAfterPaymentDueDateZ = this.date.getMoment(afterPaymentDueDateZ).format('YYYY-MM-DD hh:mm');
      const isDue = nowZ >= afterPaymentDueDateZ;
      let confirmed = true;

      if (isRecurringOrderWithCreditCard && !isDue) {
        const msg = this.intl.t('pay before payment due date', {
          date: formattedAfterPaymentDueDateZ
        });
        confirmed = window.confirm(msg);
      }

      if (confirmed) {
        this.router.transitionTo('erp.payments.card.doc', externalUrl);
      }
    },

    goToOrderStatus() {
      var _this$orderData2, _this$orderData3;

      const shopUrl = this.shopUrl;
      const email = (_this$orderData2 = this.orderData) === null || _this$orderData2 === void 0 ? void 0 : _this$orderData2.email;
      const ref = (_this$orderData3 = this.orderData) === null || _this$orderData3 === void 0 ? void 0 : _this$orderData3.ref;

      if (!shopUrl) {
        return false;
      }

      if (!email) {
        return false;
      }

      if (!ref) {
        return false;
      }

      return this.router.transitionTo('shop.orders.status', shopUrl, email, ref, {
        queryParams: {}
      });
    },

    copyAndTrack(orderData, label) {
      const waybill = (orderData === null || orderData === void 0 ? void 0 : orderData.waybill) || (orderData === null || orderData === void 0 ? void 0 : orderData.formattedWaybill);
      const el = document.createElement('textarea');
      el.value = waybill;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      (0, _object.set)(this, label, 'copied, please wait');
      setTimeout(() => {
        window.open('https://tracking.shopmore.com.tw/');
      }, 700);
      setTimeout(() => {
        (0, _object.set)(this, label, 'copy and track waybill');
      }, 5000);
    }

  }, (_applyDecoratedDescriptor(_obj, "onClickRedirectToPaymentPage", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onClickRedirectToPaymentPage"), _obj), _applyDecoratedDescriptor(_obj, "goToOrderStatus", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToOrderStatus"), _obj), _applyDecoratedDescriptor(_obj, "copyAndTrack", [_object.action], Object.getOwnPropertyDescriptor(_obj, "copyAndTrack"), _obj)), _obj));

  _exports.default = _default;
});
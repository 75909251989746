define("shop/mixins/orders-query", ["exports", "ramda", "@ember/object/mixin", "rsvp"], function (_exports, R, _mixin, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    _model(params) {
      const website = this.modelFor('application').website;
      let shop;

      if (website.get('shopKey')) {
        shop = this.modelFor('shop').shop;
      }

      if (params.errors) {
        this.set('errors', true);
      }

      return _rsvp.default.hash({
        shop,
        website,
        params
      });
    },

    _redirect(model) {
      if (R.isEmpty(model.orderData)) {
        const shop = model === null || model === void 0 ? void 0 : model.shop;
        const website = model === null || model === void 0 ? void 0 : model.website;
        const url = website === null || website === void 0 ? void 0 : website.shopUrlDisplay;

        if (shop && url) {
          this.transitionTo('shop.orders', url);
        } else {
          this.transitionTo('orders');
        }
      }
    },

    _transitionTo({
      params,
      shopUrl
    }) {
      const queryParams = {
        email: R.propOr('', 'email', params),
        ref: R.propOr('', 'ref', params),
        errors: true
      };

      if (shopUrl) {
        this.transitionTo('shop.orders', shopUrl, {
          queryParams
        });
      } else {
        this.transitionTo('orders', {
          queryParams
        });
      }
    }

  });

  _exports.default = _default;
});
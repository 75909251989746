define("shop/pods/components/elements/element-input/component", ["exports", "@ember/component", "@ember/object", "shop/mixins/translate", "shop/utils/nventor"], function (_exports, _component, _object, _translate, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_translate.default, {
    tagName: 'div',
    classNames: ['element-input', 'field'],
    classNameBindings: ['isRunning:is-loading'],
    datePickerClassName: null,
    autofocus: false,
    autocomplete: 'off',
    translate: true,
    isDatePicker: false,
    isFloat: false,
    isInt: false,
    isDropdownVisible: false,
    focusInAction: null,
    allowCalculations: true,
    isFullW: false,
    // used for when is in a dropdown/list searcher, set width to fullw of outer component
    onInput: null,
    // external action to trigger when input has changed (via keyboard)
    autoTrim: true,
    // setup () {
    //   // storing original value
    //   // this.setOriginalValue(this.get('value'))
    // },
    showRequiredError: (0, _object.computed)('value', 'isRequired', function () {
      if (this.isRequired && !this.value) {
        return true;
      }

      return false;
    }),
    _isRequired: (0, _object.computed)('isRequired', 'readonly', 'disabled', function () {
      if (this.readonly || this.disabled) {
        return false;
      }

      return this.isRequired;
    }),
    isRequiredIcon: (0, _object.computed)('value', 'isRequired', function () {
      let iconClassNames = 'fas fa-asterisk element-icon--xs';

      if (this.isRequired && !this.value) {
        iconClassNames += ' has-text-danger';
      }

      return iconClassNames;
    }),
    hasIconsLeft: (0, _object.computed)('prefix', 'leftIcon', function () {
      return this.prefix || this.leftIcon;
    }),
    hasIconsRight: (0, _object.computed)('suffix', 'leftIcon', 'hasCheckIcon', 'isRequired', function () {
      return this.suffix || this.rightIcon || this.hasCheckIcon || this.isRequired;
    }),

    focusOut(e) {
      this._super(e);

      if (this.isFloat) {
        var float = parseFloat(this.value);

        if (isNaN(float)) {
          float = 0;
        }

        this.set('value', float);
      }

      if (this.isInt) {
        var int = parseInt(this.value);

        if (isNaN(int)) {
          int = 0;
        }

        this.set('value', int);
      }

      if (this.autoTrim) {
        const trimmedValue = _nventor.default.safeTrim(this.value);

        this.set('value', trimmedValue);
      }

      if (this.onValidate) {
        this.onValidate(e, this.valueKey, this.value);
      }

      if (this.onFocusOut) {
        this.onFocusOut(this.value, this.valueKey, e);
      }
    },

    actions: {
      submit() {
        if (this.onSubmit) {
          this.onSubmit(this.value);
        }
      } // input () {
      //   if (this.onUpdate) {
      //     this.onUpdate(...arguments)
      //   }
      //   if (this.onInput) {
      //     return this.onInput(...arguments)
      //   }
      // }


    }
  });

  _exports.default = _default;
});